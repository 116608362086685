import style from "./style";
import React, {useEffect} from "react";
import styled from 'styled-components';

const Styled = styled.div`${style}`;
const DEBUG = false;

const RuleUsage = ({ item }) => {

  useEffect(() => {
    DEBUG && item && console.log(item)
  }, [item])

  return (
    <Styled>
      <div><i>Placeholder for showing where the rule has been used</i></div>
    </Styled>
  )
}

export default RuleUsage;
