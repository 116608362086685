import React from 'react';
import styled from 'styled-components';
import FlexColumn from '../../order/components/FlexColumn';
import style from './style';
import SetupItemList from '../setup-item-list';
import { columns, prototype } from './setup';
import ComponentGeneral from './component-general';
import ComponentCosts from './component-costs';
import useSetup from '../useSetup';
import ImportExportMultiple from '../components/import-export-multiple';

const Styled = styled(FlexColumn)`
  ${style}
`;

const tabs = [
  {
    label: 'General',
    component: ComponentGeneral,
  },
  {
    label: 'Costs',
    component: ComponentCosts,
  },
];

const ImportExport = props => {
  const { downloadFile, uploadFile } = useSetup();

  return (
    <ImportExportMultiple
      {...props}
      downloadFile={downloadFile}
      uploadFile={uploadFile}
      itemList={[
        { label: 'Components', typeId: 'mix-component', crn: 'all', name: 'mix-component-list' },
        { label: 'Costs', typeId: 'mix-component-cost', crn: 'all', name: 'mix-component-costs' },
      ]}
    />
  );
};

const SetupComponents = () => {
  return (
    <Styled>
      <SetupItemList
        typeId="component"
        type="Mix Component"
        columns={columns}
        prototype={prototype}
        editorType="tabs"
        tabs={tabs}
        actions={ImportExport}
      />
    </Styled>
  );
};

export default SetupComponents;
