import { css } from 'styled-components';

export default css`
  .field-with-addon {
    position: relative;
    .addon {
      position: absolute;
      top: 6px;
      right: 10px;
    }
  }

  .ant-select-auto-complete {
    width: 100%;
  }

  .label-spinner {
    display: flex;
  }
`;
