import { Calendar, Modal } from 'antd';
import cn from 'classnames';
import { DateTime } from 'luxon';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import Button from '../../../../../../components/button/Button';
import { ShortDate } from '../../../../components/localization/ShortDate';
import { Translate } from '../../../../components/localization/Translate';
import { isToday, isTomorrow, isFuture } from '../isTomorrow';


const StyledButton = styled(Button)``;

const CustomButton = ({ onChange, currentDate, timeZone }) => {
  const [modalVisible, setModalVisible] = React.useState();
  const todayMoment = React.useMemo(() => {
    return moment();
  }, []);

  const [selectedDate, setSelectedDate] = React.useState();

  const handleClick = React.useCallback(() => {
    setModalVisible(true);
  }, []);

  const onSelect = React.useCallback(moment => {
    const dt = DateTime.fromISO(moment.toISOString());


    const tzAdjustedDate = dt.setZone(timeZone, { keepLocalTime: true });

    const firstStartDateTime = tzAdjustedDate.startOf('day').toUTC().toISO();
    const lastStartDateTime = tzAdjustedDate.endOf('day').toUTC().toISO();

    setSelectedDate({ firstStartDateTime, lastStartDateTime });
  }, []);

  const handleOk = React.useCallback(() => {
    onChange(selectedDate);
    setModalVisible(false);
  }, [selectedDate, onChange]);

  const isCustom = React.useMemo(() => {
    if (!currentDate) {
      return false;
    }
    return !isFuture(currentDate) && !isTomorrow(currentDate) && !isToday(currentDate);
  }, [currentDate]);

  return (
    <>
      <StyledButton
        className={cn('today-button')}
        onClick={handleClick}
        type={isCustom ? 'primary' : undefined}
        metricId="core-order-list-filter-custom"
        data-testid="button-custom"
      >
        {isCustom ? (
          <ShortDate date={currentDate?.lastStartDateTime} />
        ) : (
          <Translate stringId="custom" defaultMessage="Custom" />
        )}
      </StyledButton>
      <Modal
        title="Custom Date"
        onOk={handleOk}
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        onClose={() => setModalVisible(false)}
        onOpen={() => setModalVisible(true)}
      >
        <Calendar fullscreen={false} onSelect={onSelect} defaultValue={todayMoment} />
      </Modal>
    </>
  );
};

export default CustomButton;
