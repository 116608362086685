import { get } from 'lodash';
import React from 'react';

export default onCopy => props => {
  const id = get(props, 'row.id');
  const crn = get(props, 'row.crn');
  return (
    <div className="id">
      <i
        className="fa-xs far fa-copy"
        onClick={e => {
          onCopy?.(crn);
          e.stopPropagation();
        }}
      />{' '}
      {id}
    </div>
  );
};
