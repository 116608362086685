import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useDashboardContext } from '../context/useDashboardContext';
import moment from 'moment';
import TrackingConfigurationForm from '../configuration/tracking-configuration-form';
import Iframe from '../components/iframe';
import { useParams } from 'react-router';
import { useModalContext } from "../../../../components/modal-context/ModalContext";
import ConfirmModal from "../../../../components/confirmation-modal/ConfirmModal";
import { useTranslateMessage } from "../../../order/components/localization/useTranslateMessage";

const MenuContext = createContext();

export const useMenuContext = () => {
  const value = useContext(MenuContext);
  if (!value) {
    throw new Error('Dashboard context must be used in ContextProvider');
  }

  return value || {};
};


export const MenuContextProvider = ({ children }) => {
  const { entityRef } = useParams();
  const { openModal } = useModalContext();
  const translateMessage = useTranslateMessage();
  const {
    setDateRange,
    openDashboardWindow,
    resetLayout,
    drawer: { setContent, toggleDrawer, setOpen },
    meta: { startDate },
  } = useDashboardContext();

  const setDate = useCallback(
    startDate => {
      setDateRange({
        startDate: moment(startDate).startOf('day'),
        endDate: moment(startDate).endOf('day'),
      });
    },
    [setDateRange]
  );

  const onAction = useCallback(
    action => {
      if (action.id === 'open_drawer') {
        let windowContent = null;
        if (action.type === 'tracking-configuration') {
          windowContent = <TrackingConfigurationForm />;
        } else if (action.type === 'iframe') {
          const src = action.getSrc(entityRef);
          windowContent = <Iframe src={src} />;
        }
        setContent(windowContent);
        setOpen();
      } else if (action.id === 'open_window') {
        openDashboardWindow(action.type, action.name);
      } else if(action.id === 'reset_layout') {
        openModal('', <ConfirmModal message={translateMessage({stringId: 'confirmTrackingLayoutReset', defaultMessage: "Are you sure you want to reset your layout?"})} customHandler={resetLayout}/>)
      }
    },
    [setContent, setOpen, openDashboardWindow, resetLayout]
  );

  return <MenuContext.Provider value={{ setDate, currentDate: startDate, onAction }}>{children}</MenuContext.Provider>;
};

export default {
  useMenuContext,
  MenuContextProvider,
};
