import React, { useMemo } from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import FieldContainer from '../../field-container/FieldContainer';
import Input from '../../form/Input';

const RequestedBy = ({ className, value }) => {
  const requestedBy = useMemo(() => {
    if (!value) return 'Unknown';

    return `${value?.name} <${value?.email}>`;
  }, [value]);

  return (
    <FieldContainer className={cn('requested-by', className)}>
      <div className="label-spinner">
        <div className="label">Requested By</div>
        <div className={cn('spinner', { spinning: false })}>
          <span className="fas fa-sync fa-spin" />
        </div>
      </div>
      <Input type="text" value={requestedBy || ''} disabled data-testid="requested-by_input" />
    </FieldContainer>
  );
};

export default styled(RequestedBy)``;
