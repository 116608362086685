import hostname from './hostname';
import queryString from 'query-string';
import { getCachedItem } from './util/cache/index';
import { checkVersion } from './views/order/version/version';
import { uniqBy } from 'lodash';

const handleFetch = async ({ method, path = '', data = {}, queryParams = {}, returnBlob }) => {
  const query = queryString.stringify(queryParams);

  const options = {
    method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-api-key': '9UCa8lvpgHciPKf6Tr26pu8LDCXjVfkMYCQDWk16',
      'x-connex-id': getCachedItem('x-connex-id'),
    },
    body: method === 'GET' ? undefined : JSON.stringify(data),
  };
  const response = await fetch(`${hostname(path)}${path}${query ? `?${query}` : ''}`, options);

  checkVersion(response.headers.get('x-connex-version'));

  if (response.ok) {
    const result = await response.json();

    if (window.location.origin.includes('localhost')) {
      if (JSON.stringify(result).length > 6000000) {
        throw new Error(`Response size too large from::${path}`);
      }
    }
    return result;
  }

  const error = await response.json();

  if (error?.response?.data?.code === 'FORBIDDEN_SESSION') {
    const preparedMessage = {
      type: 'http-error',
      response: {
        status: response.status,
        data: error,
      },
    };
    window.parent.postMessage(preparedMessage, '*');
  } else {
    if (error?.message) {
      throw { message: error?.message };
    }
    // eslint-disable-next-line no-throw-literal
    throw {
      message:
        uniqBy(error?.issues, 'Code')
          ?.map?.(issue => issue.Message)
          ?.join?.(', ') || 'An error has occurred',
      crn: error.crn,
      id: error?.id,
    };
  }

  return error;
};

function post(path, data, queryParams) {
  return handleFetch({ method: 'POST', path, data, queryParams });
}

function patch(path, data, queryParams) {
  return handleFetch({ method: 'PATCH', path, data, queryParams });
}

function get(path, queryParams, returnBlob) {
  return handleFetch({ method: 'GET', path, queryParams, returnBlob });
}

async function getIcon(path = '') {
  const response = await fetch(`${hostname(path)}${path}`, {
    method: 'GET',
    headers: {
      Accept: 'image/png',
      'Content-Type': 'image/png',
    },
  });

  return response.blob();
}

export default {
  get,
  post,
  patch,
  getIcon,
};
