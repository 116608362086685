import { DatePicker } from 'antd';
import cn from 'classnames';
import { isEmpty } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import Button from '../../../../components/button/Button';
import { Translate } from '../../../order/components/localization/Translate';

const { RangePicker } = DatePicker;

const Styled = styled.div`
  .buttons {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    grid-gap: 10px;

    .range-picker {
      display: flex;
      align-items: center;
      .spinner {
        text-align: right;
        padding-left: 10px;
        .spinner-icon {
          opacity: 0;
        }
      }
      .ant-picker-range {
        width: 100%;
        border: 0;
      }
    }
  }
  &.custom-selected {
    .range-picker {
      background-color: #177ddc;
      .ant-picker-disabled {
        background-color: #095db6;
      }
      &.loading {
        background-color: #095db6;
        .spinner-icon {
          opacity: 1;
        }
      }
    }
  }
`;

export const Selector = ({ id, label, onSelect, selectedDate, loading, metricId }) => {
  return (
    <Button
      disabled={loading && selectedDate !== id}
      className="selector"
      id={id}
      onClick={() => onSelect(id)}
      type={selectedDate === id ? 'primary' : ''}
      loading={loading && selectedDate === id}
      metricId={metricId}
    >
      {label}
    </Button>
  );
};

const QuickDates = ({ onChange, loading }) => {
  const [selectedDate, setSelectedDate] = React.useState(null);
  const [customDateRange, setCustomDateRange] = React.useState([]);

  const selectDate = React.useCallback(
    date => {
      if (['today', 'yesterday', 'currentMonth'].includes(date)) {
        setSelectedDate(date);
        setCustomDateRange([]);
      }
      onChange?.(date);
    },
    [onChange]
  );

  const previousSelected = React.useRef();
  React.useEffect(() => {
    if (selectedDate !== 'custom') {
      previousSelected.current = selectedDate;
    }
  }, [selectedDate]);

  React.useEffect(() => {
    if (!isEmpty(customDateRange)) {
      setSelectedDate('custom');
      onChange([
        customDateRange[0].utc().startOf('day').toISOString(),
        customDateRange[1].utc().endOf('day').toISOString(),
      ]);
    }
  }, [customDateRange, onChange]);

  React.useEffect(() => {
    if (selectedDate === null) {
      setSelectedDate('today');
      onChange('today');
    }
  }, [onChange, selectedDate]);

  return (
    <Styled className={cn('quick-dates', { 'custom-selected': !isEmpty(customDateRange) })}>
      <div className="buttons">
        <Selector
          loading={loading}
          id="today"
          label={<Translate stringId="today" defaultMessage="Today" />}
          onSelect={selectDate}
          selectedDate={selectedDate}
          metricId="core-invoiced-deliveries-filter-today"
        />
        <Selector
          loading={loading}
          id="yesterday"
          label={<Translate stringId="yesterday" defaultMessage="Yesterday" />}
          onSelect={selectDate}
          selectedDate={selectedDate}
          metricId="core-invoiced-deliveries-filter-yesterday"
        />
        <Selector
          loading={loading}
          id="currentMonth"
          label={<Translate stringId="thisMonth" defaultMessage="This Month" />}
          onSelect={selectDate}
          selectedDate={selectedDate}
          metricId="core-invoiced-deliveries-filter-this-month"
        />
        <div className={cn('range-picker', { loading: loading && selectedDate === 'custom' })}>
          <div className={cn('spinner')}>
            <i className="spinner-icon fal fa-spin fa-spinner-third" />
          </div>
          <RangePicker onChange={setCustomDateRange} value={customDateRange} allowClear={false} disabled={loading} />
        </div>
      </div>
    </Styled>
  );
};

export default QuickDates;
