import style from "../style";
import React from "react";
import styled from 'styled-components';
import InputNumber from "../../../components/input-number";
import InputText from "../../../components/input-text";
import InputSelect from "../../../components/input-select";
import {status} from "../options";

const View = styled.div`${style}`;

const General = ({ trackable, onChange, trackableTypes }) => {

  return (
    <View>
      <InputText id='deviceId' label='DeviceId' value={trackable?.deviceId} onChange={onChange} disabled={trackable?.deviceType === 'TRAK4'} />
      <InputText id='description' label='Description' value={trackable?.description} onChange={onChange} disabled={trackable?.deviceType === 'TRAK4'} />
      <InputText id='deviceType' label='Device Type' value={trackable?.deviceType} onChange={onChange} disabled={trackable?.deviceType === 'TRAK4'} />
      <InputText id='alias' label='Alias' value={trackable?.alias} onChange={onChange} />
      <InputSelect id='trackableTypeRef'
                   label='Trackable Type'
                   value={trackable?.trackableTypeRef}
                   onChange={onChange}
                   options={trackableTypes}
                   required
      />
      <InputNumber id='latitude' label='Latitude' value={trackable?.latitude} onChange={onChange} disabled={trackable?.deviceType === 'TRAK4'} />
      <InputNumber id='longitude' label='Longitude' value={trackable?.longitude} onChange={onChange} disabled={trackable?.deviceType === 'TRAK4'} />
      <InputSelect id="status" label="Status" value={trackable?.status} onChange={onChange} options={status} required />
    </View>
  )
}

export default General;
