import { css } from 'styled-components';

export default css`
  margin: 20px 15px;
  .quick-dates {
    margin-bottom: 20px;
  }
  .centered {
    text-align: center;
  }
  .align-right {
    text-align: right;
  }
`;
