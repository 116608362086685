import { css } from 'styled-components';

export default css`
  .order-card {
    border: 1px solid #434343;
    padding: 10px;
    margin: 10px 0;
    background-color: #2F2F2F;
    .order-info {
      margin-bottom: 10px;
      display: grid;
      grid-template-columns: 50% 50%;
      grid-template-rows: auto;
      grid-template-areas:
        'orderId project'
        'customer location';

      .order-id {
        grid-area: orderId;
      }
      .customer-name {
        grid-area: customer;
      }
      .location-name {
        grid-area: location;
        text-align: right;
      }
      .project-name {
        text-align: right;
        grid-area: project;
      }
    }
    .loads {
      table {
        width: 100%;
        th {
          background-color: #444;
          text-align: center;
          border: 1px solid #444;
          width: 33%;
        }
        td {
          border: 1px solid #444;
          text-align: center;
        }
      }
    }
  }
`;
