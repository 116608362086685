import React from 'react';
import ProductTypeGeneral from './product-type-general';

export const prototype = { id: '', name: '', status: 'ACTIVE' };

export const columns = [
  { key: 'id', name: 'ID' },
  { key: 'name', name: 'Name' },
  {
    key: 'status',
    name: 'Status',
    filterFn: (item, filterStrings) => item === filterStrings[0],
    filterComponent: ({ innerRef, ...props }) => {
      return (
        <select ref={innerRef} {...props}>
          <option value="" />
          <option value="ACTIVE">Active</option>
          <option value="INACTIVE">Inactive</option>
        </select>
      );
    },
  },
];

export const tabs = [{ label: 'General', component: ProductTypeGeneral }];
