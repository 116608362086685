import cn from 'classnames';
import { find, pick } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import InputSelect from '../../../components/input-select';
import useSetup from '../../../useSetup';
import style from './style';

const Styled = styled.div`
  ${style}
`;
const Configuration = ({ configuration, onChange }) => {
  const { getDrivers, drivers, getTrailers, trailers, busy } = useSetup();

  React.useEffect(() => {
    getDrivers();
    getTrailers();
  }, [getDrivers, getTrailers]);

  const driverOptions = React.useMemo(() => {
    if (drivers) {
      return drivers.map(driver => {
        const names = [];
        driver.firstName && names.push(driver.firstName);
        driver.lastName && names.push(driver.lastName);
        const label = names.join(' ');

        return { value: driver.crn, label };
      });
    }
    return [];
  }, [drivers]);

  const trailerOptions = React.useMemo(() => {
    return trailers.map(trailer => ({ value: trailer.crn, label: `${trailer.id} / ${trailer.name}` }));
  }, [configuration?.trailers, trailers]);

  const handleDriverChange = React.useCallback(
    (id, value) => {
      const targetDriver = find(drivers, { crn: value });
      onChange('configuration.driver', {
        ...pick(targetDriver, ['id']),
        name: `${targetDriver.firstName} ${targetDriver.lastName}`,
        driverRef: targetDriver.crn,
      });
    },
    [drivers, onChange]
  );

  const handleTrailerChange = React.useCallback(
    (id, value) => {
      const targetTrailer = find(trailers, { crn: value });
      onChange('configuration.trailers', [{ ...pick(targetTrailer, ['id', 'name']), trailerRef: targetTrailer.crn }]);
    },
    [onChange, trailers]
  );

  return (
    <Styled className={cn('configuration')}>
      <InputSelect
        id="driver"
        label="Driver"
        value={configuration?.driver?.driverRef}
        onChange={handleDriverChange}
        options={driverOptions}
        required
      />
      <InputSelect
        id="trailer"
        label="Trailer"
        value={configuration?.trailers?.[0]?.trailerRef}
        onChange={handleTrailerChange}
        options={trailerOptions}
        required
      />
    </Styled>
  );
};

export default Configuration;
