import cn from 'classnames';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import getUomAbbreviationOrDescription from '../../../util/uom';
import AntInput from '../../ant-input/AntInput';
import FieldContainer from '../../field-container/FieldContainer';
import Input from '../../form/Input';
import style from './style';

const ProductQuantity = ({ className, value, onChange, uom = '', required, disabled }) => {
  const handleChange = e => {
    onChange('quantity', e.target.value);
  };

  const uomAbbreviation = useMemo(() => {
    return getUomAbbreviationOrDescription(uom).toUpperCase();
  }, [uom]);

  return (
    <FieldContainer className={cn('quantity', className)}>
      <div className="label-spinner">
        <div className="label">Quantity
          {required && <span className="required-indicator">*</span>}
        </div>
        <div className={cn('spinner', { spinning: false })}>
          <span className="fas fa-sync fa-spin" />
        </div>
      </div>
      <Input
        inputMode="numeric"
        pattern="[0-9]*"
        value={value || ''}
        onChange={handleChange}
        data-testid="quantity_input"
        addonAfter={uomAbbreviation}
        disabled={disabled}
      />
    </FieldContainer>
  );
};

export default styled(ProductQuantity)`
  ${style}
`;
