import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
import { Translate } from '../../../localization/Translate';
import { useModelingContext } from '../ModelingContext';
import style from './style';

const Styled = styled.div`
  ${style}
`;

const ChangeInfo = () => {
  const { changeInfo } = useModelingContext();
  return (
    <Styled className={cn('change-info')}>
      <table>
        <thead>
          <tr>
            <th />
            <th>
              <Translate stringId="startDateTime" defaultMessage="Start Date/Time" />
            </th>
            <th>
              <Translate stringId="spacing" defaultMessage="Spacing" />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className="original">
            <td>
              <Translate stringId="original" defaultMessage="Original" />
            </td>
            <td>{changeInfo.originalStartDateTimeString}</td>
            <td>
              {changeInfo.originalSpacing} MIN ({changeInfo.originalQuantityRate} CY/H)
            </td>
          </tr>
          <tr>
            <td>
              <Translate stringId="adjusted" defaultMessage="Adjusted" />
            </td>
            <td className={cn('adjusted', 'startdatetime', { unchanged: !changeInfo.isStartDateTimeChanged })}>
              {changeInfo.newStartDateTimeString}
            </td>
            <td className={cn('adjusted', 'spacing', { unchanged: !changeInfo.isSpacingChanged })}>
              {changeInfo.newSpacing} MIN ({changeInfo.newQuantityRate} CY/H)
            </td>
          </tr>
        </tbody>
      </table>
    </Styled>
  );
};

export default ChangeInfo;
