import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import FlexColumn from '../../order/components/FlexColumn';
import useSetup from '../useSetup';
import { useParams } from 'react-router';
import style from './style';
import SetupTable from '../components/setup-table';
import CustomerEditor from './customer-editor';
import { columns, prototype } from './setup';
import ImportExportMultiple from '../components/import-export-multiple';

const Style = styled(FlexColumn)`
  ${style}
`;

const ImportExport = props => {
  const { downloadFile, uploadFile } = useSetup();

  return (
    <ImportExportMultiple
      {...props}
      downloadFile={downloadFile}
      uploadFile={uploadFile}
      itemList={[
        { label: 'Customers', typeId: 'customer', crn: 'all', name: 'customer-list' },
        { label: 'Contacts', typeId: 'contact', crn: 'all', name: 'contact-list' },
      ]}
    />
  );
};

const SetupCustomers = () => {
  const { entityRef } = useParams();
  const { getCustomers, customers, busy } = useSetup();
  const params = useMemo(() => ({ activeOnly: false }), []);

  useEffect(() => {
    entityRef && getCustomers(entityRef, params);
  }, [entityRef, getCustomers, params]);

  return (
    <Style>
      <SetupTable
        busy={busy}
        type="Customer"
        columns={columns}
        items={customers}
        Editor={CustomerEditor}
        prototype={prototype}
        onRefresh={() => getCustomers(entityRef, params)}
        actions={ImportExport}
      />
    </Style>
  );
};

export default SetupCustomers;
