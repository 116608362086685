import styled from 'styled-components';
import { Translate } from '../../../../order/components/localization/Translate';
import { useTranslateMessage } from '../../../../order/components/localization/useTranslateMessage';
import style from './style';
import TextAreaField from '../../../../order/components/TextAreaField';
import { useCallback, useState } from 'react';

const Style = styled.div`
  ${style}
`;

const BillingNote = ({ note, onChange }) => {
  const translateMessage = useTranslateMessage();

  const [localValue, setLocalValue] = useState(note);

  const handleOnChange = useCallback(
    (id, value) => {
      setLocalValue(value);
      onChange(id, value);
    },
    [onChange]
  );

  return (
    <Style>
      <TextAreaField
        fieldName={'billingNote'}
        value={localValue}
        onChange={handleOnChange}
        fieldLabel={translateMessage({ stringId: 'invoices.billingNote', defaultMessage: 'Billing Note' })}
      />
    </Style>
  );
};

export default BillingNote;
