import { css } from 'styled-components';

export default css`
  display: flex;
  align-items: center;

  .separator {
    width: 5px;
  }
`;
