import React, { useContext } from 'react';
import useSetup from '../useSetup';

const CostBookSetupContext = React.createContext();

export const useCostBookSetupContext = () => {
  const context = useContext(CostBookSetupContext);
  if (!context) {
    throw new Error(`useCostBookSetupContext cannot be rendered outside of the CostBookSetupContext context provider`);
  }
  return context;
};

const CostBookSetupContextProvider = ({ children }) => {
  const setup = useSetup('cost-book');
  return <CostBookSetupContext.Provider value={setup}>{children}</CostBookSetupContext.Provider>;
};

export default CostBookSetupContextProvider;
