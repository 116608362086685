import { css } from 'styled-components';

export default css`
  font-size: 12px;
  .ant-tabs {
    font-size: 12px;
    height: 100%;
    .ant-tabs-content-holder {
      height: 100%;
      .ant-tabs-tabpane {
        height: 100%;
        .order-map {
          height: 100%;
        }
      }
    }
  }
  .ant-tabs-content {
    height: 100%;
    overflow: auto;
  }
  .ant-tabs-tabpane {
    min-height: 500px;
  }

  .title {
    text-align: center;
    font-weight: bold;
    font-size: 1.2em;
    position: relative;
  }
`;
