import styled from 'styled-components';
import style from './style';
import { Checkbox } from 'antd';
import InputNumber from '../../../../../components/form/InputNumber';

const Styled = styled.tr`
  ${style}
`;

const HeaderOption = ({ type, dragRef, draggableProps, dragHandleProps, option, saveTrackingConfiguration, index }) => {
  return (
    <Styled ref={dragRef} {...draggableProps} {...dragHandleProps}>
      <td style={{ textAlign: 'center' }}>
        <Checkbox
          disabled={option.id === 'orderId'}
          checked={option.display !== false}
          onChange={e => saveTrackingConfiguration(`${type}_${index}_display`, e.target.checked)}
        />
      </td>
      <td>{option.label}</td>
      <td>
        <InputNumber
          onChange={e => saveTrackingConfiguration(`${type}_${index}_width`, Number(e.target.value))}
          id={`${type}_${option.id}_width`}
          value={option.width}
        />
      </td>
    </Styled>
  );
};

export default HeaderOption;
