import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { api } from '../api';
import { useOrderContext } from '../components/useOrderContext';

export const useSchedule = () => {
  const { entityRef } = useParams();
  const [scheduleBusy, setScheduleBusy] = useState(false);

  const {
    order: { rawOrder, getOrder, applyRawLineOrder, orderBusy },
  } = useOrderContext();

  const createOrderSchedule = useCallback(
    (orderRef, data) => {
      setScheduleBusy(true);

      return api
        .createOrderSchedule(entityRef, orderRef, data)
        .then(response => {
          applyRawLineOrder(response);

          return response;
        })
        .finally(() => {
          setScheduleBusy(false);
        });
    },
    [applyRawLineOrder, entityRef]
  );

  return {
    createOrderSchedule,
    getOrder,
    rawOrder,
    scheduleBusy: scheduleBusy || orderBusy,
  };
};
