const MATERIAL_TYPE_OPTIONS = [
  { value: 'WATER', label: 'Water' },
  { value: 'CEMENT', label: 'Cement' },
  { value: 'FLYASH', label: 'Fly Ash' },
  { value: 'SLAG', label: 'Slag' },
  { value: 'SILICAFUME', label: 'Silica Fume' },
  { value: 'SAND', label: 'Sand' },
  { value: 'COARSEAGG', label: 'Coarse Agg' },
  { value: 'ADMIX', label: 'Admixture' },
  { value: 'ADDITIVE', label: 'Additive' },
  { value: 'OTHER', label: 'Other' },
];

export default MATERIAL_TYPE_OPTIONS;
