import http from '../../../http';

const orders = '/connex/services/orders';

export const api = {
  initialize: ref => http.post(`${orders}/${ref}/master-data/initialize`, {}),
  startOrder: (ref, data) => http.post(`${orders}/${ref}/orders/start`, data),
  reserveOrderIds: (ref, data) => http.post(`${orders}/${ref}/orders/reserve-ids`, data),
  createOrder: (ref, data) => http.post(`${orders}/${ref}/orders`, data),
  updateOrder: (ref, orderRef, data) => http.patch(`${orders}/${ref}/orders/${orderRef}`, data),
  updateOrderAddress: (ref, orderRef, data) => http.patch(`${orders}/${ref}/orders/${orderRef}/address`, data),
  listStatuses: ref => http.get(`${orders}/${ref}/setup/status-code`),
  listOrders: (ref, queryParams) => http.get(`${orders}/${ref}/orders`, queryParams),
  listPageOrders: (ref, data) => http.post(`${orders}/${ref}/orders/paginated`, data),
  searchOrders: (ref, params) => http.post(`${orders}/${ref}/orders/search`, params),
  getOrder: (ref, orderRef, queryParams) => http.get(`${orders}/${ref}/orders/${orderRef}`, queryParams),
  repriceOrder: (ref, orderRef, params) => http.post(`${orders}/${ref}/orders/${orderRef}/reprice`, params),
  getOrderHistory: (ref, orderRef) => http.get(`${orders}/${ref}/orders/${orderRef}?expand=history`),
  getOrderTickets: (ref, orderRef, queryParams) => http.get(`${orders}/${ref}/orders/${orderRef}`, queryParams),
  patchOrderSchedules: (ref, orderRef, data) => http.patch(`${orders}/${ref}/orders/${orderRef}/schedules`, data),
  createOrderSchedule: (ref, orderRef, data) => http.post(`${orders}/${ref}/orders/${orderRef}/schedules`, data),
  createOrderTicket: (ref, orderRef, data) => http.post(`${orders}/${ref}/orders/${orderRef}/tickets`, data),
  getTicket: (ref, ticketRef) => http.get(`${orders}/${ref}/tickets/${ticketRef}`),
  getLocations: (ref, params) => http.get(`${orders}/${ref}/master-data/locations`, params),
  getProjects: (ref, customerRef, params) =>
    http.get(`${orders}/${ref}/master-data/customers/${customerRef}/projects`, params),
  createNote: (ref, orderRef, data) => http.post(`${orders}/${ref}/orders/${orderRef}/notes`, data),
  updateNote: (ref, orderRef, noteRef, data) =>
    http.patch(`${orders}/${ref}/orders/${orderRef}/notes/${noteRef}`, data),
  listNotes: (ref, orderRef) => http.get(`${orders}/${ref}/orders/${orderRef}/notes`),
  listOrderCarriers: (ref, orderRef, queryParams) =>
    http.get(`${orders}/${ref}/orders/${orderRef}/carriers`, queryParams),
  listOrderVehicles: (ref, orderRef, carrierRef) =>
    http.get(`${orders}/${ref}/orders/${orderRef}/vehicles?carrierRef=${carrierRef}`),
  listOrderTicketGeoLocations: (ref, orderRef, queryParams) =>
    http.get(`${orders}/${ref}/orders/${orderRef}/geo-locations`, queryParams),
  getMasterData: (ref, customerRef, locationRef, projectRef) =>
    http.get(`${orders}/${ref}/master-data/${customerRef}/${projectRef}/${locationRef}`, { activeOnly: true }),
  getOrderProducts: (ref, customerRef, locationRef, projectRef) =>
      http.get(`${orders}/${ref}/master-data/${customerRef}/${projectRef}/${locationRef}/products`, { activeOnly: true }),
  getGeoMap: (entityRef, body) => http.post(`/v2/geo/${entityRef}/embedded-map`, body),
  getRole: (entityRef, roleRef) => http.get(`${orders}/${entityRef}/master-data/roles/${roleRef}`),
  updateRole: (entityRef, roleRef, updates) =>
    http.patch(`${orders}/${entityRef}/master-data/roles/${roleRef}`, updates),
  updateSupplierStatus: (entityRef, orderRef, ticketRef, supplierStatus) =>
    http.post(`${orders}/${entityRef}/orders/${orderRef}/tickets/${ticketRef}/supplier-status`, supplierStatus),
  getOrderCostBooks: (entityRef, orderRef) => http.get(`${orders}/${entityRef}/orders/${orderRef}/cost-books`),
  reActivateTicket: (entityRef, ticketRef, supplierStatus) =>
    http.post(`${orders}/${entityRef}/tickets/${ticketRef}/re-activate`, supplierStatus),
};
