import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { uniq } from 'lodash';
import { Time } from '../../../views/order/components/localization/Time';
import { useModelingContext } from '../../../views/order/components/order-form/schedule/ModelingContext';
import { buttonClick } from '../../../util/analytics';

const Styled = styled.div`
  height: 100%;
  display: flex;
  flex: 1;
  align-items: flex-end;
  position: relative;
  .bar {
    width: 9px;
    position: relative;
    .info {
      display: none;
      position: absolute;
      left: 0;
      white-space: nowrap;
      z-index: 100;
      padding: 0 3px;
      border: 1px solid #ccc;
      background-color: #000;
      font-size: 0.9em;
      &.hover-highlight {
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-color: #fff;
        opacity: 0.3;
        z-index: 101;
        border: none;
      }
      &.y-value {
        top: -24px;
      }
      &.x-value {
        padding-top: 2px;
        line-height: 1.15em;
        font-size: 0.8em;
        bottom: -18px;
        left: 0;
      }
    }
  }

  &:hover {
    cursor: pointer;
    .bar {
      background-color: #90c4f5;
      .info {
        display: inherit;
      }
    }
  }
`;
const Space = styled.div`
  width: 1px;
`;

const statuses = {
  TARGET: '#DFC867', //'#5dd9b8',
  ON_JOB: '#DFC867',
  TEST2: '#177DDC',
  TEST1: '#DFC867',
  WILL_CALL: '#C16868',
  RED_LINE: '#C16868',
  CONFIRMED: '#40947E',
  UNKNOWN: '#40947E',
};

const Bar = ({ load, isNow, redLine, slot, date, maxValue, setHighlightedOrders, onClick }) => {
  const modelingContext = useModelingContext();

  const time = React.useMemo(() => {
    const dt = date?.startOf?.('day')?.plus?.({ minutes: slot * 5 });
    return <Time date={dt} hideTimeZone={true} />; // dt?.toFormat?.('h:mm a');
  }, [date, slot]);

  const height = React.useMemo(() => {
    return load ? `${(load?.trucks / maxValue) * 100}%` : 0;
  }, [load, maxValue]);

  const redLineBackground = React.useMemo(() => {
    const nowRed = '#C16868'; //'rgba(245,126,118,1)';
    const red = '#C16868'; //'rgba(194, 104, 104, 1)';
    const nowColor = '#40947E'; //'rgba(181,215,247,1)';
    const color = '#40947E'; //'rgba(23,125,220,1)';

    return !load?.trucks || load?.trucks <= redLine
      ? { backgroundColor: color }
      : {
          background: `linear-gradient(to top, ${color} ${(redLine / load?.trucks) * 100}%, ${isNow ? nowRed : red} ${
            (redLine / load?.trucks) * 100
          }%)`,
        };
  }, [isNow, load, redLine]);

  const background = React.useMemo(() => {
    // return { backgroundColor: statuses.CONFIRMED };

    const status = load?.inProgress?.reduce?.((acc, value) => {
      const key = value.orderRef === modelingContext?.targetOrderRef ? 'TARGET' : 'UNKNOWN';
      // const key = value?.supplierStatus || 'UNKNOWN';
      acc[key] = (acc?.[key] || 0) + 1;
      return acc;
    }, {});

    if (!status) return { backgroundColor: statuses.CONFIRMED };

    const gradient = Object.entries(statuses).reduce(
      (acc, [key, color]) => {
        if (status[key]) {
          acc.loadCount += status[key];
          const percentage = (status[key] / load?.trucks) * 100;
          acc.value.push(` ${color} ${acc.percentage}% ${acc.percentage + percentage}%`);
          acc.percentage += percentage;
        }
        return acc;
      },
      { value: [], percentage: 0, loadCount: 0 }
    );

    return {
      background: `linear-gradient(to top, ${gradient?.value?.join?.(', ')}`,
    };
  }, [load?.inProgress, load?.trucks, modelingContext?.targetOrderRef]);

  const handleHover = React.useCallback(
    orders => {
      setHighlightedOrders(orders);
    },
    [setHighlightedOrders]
  );

  const handleEndHover = React.useCallback(() => {
    setHighlightedOrders([]);
  }, [setHighlightedOrders]);

  const handleClick = () => {
    onClick(
      load,
      time,
      load?.trucks,
      date
        ?.startOf?.('day')
        ?.plus?.({ minutes: slot * 5 })
        .toISO()
    );
  };

  return (
    <Styled
      isNow={isNow}
      redLine={redLine}
      load={load}
      onMouseEnter={() => handleHover(uniq(load?.orders || []))}
      onClick={() => buttonClick(handleClick, 'core-planning-graph-bar')}
      onMouseLeave={handleEndHover}
    >
      {(modelingContext?.onJobRange?.[0] === slot || modelingContext?.onJobRange?.[1] === slot) && (
        <>
          <div
            className="bottom-caret"
            style={{
              position: 'absolute',
              bottom: '-21px',
              left: `-3px`,
              zIndex: '99',
              color: statuses.ON_JOB,
              fontSize: '1.3em',
            }}
          >
            <i className="fas fa-caret-up" />
          </div>
        </>
      )}

      {isNow && (
        <>
          <div
            className="top-caret"
            style={{
              position: 'absolute',
              top: '-18px',
              left: `-3px`,
              zIndex: '99',
              color: '#FFF',
              fontSize: '1.3em',
            }}
          >
            <i className="fas fa-caret-down" />
          </div>
          <div
            className="now-line"
            style={{
              borderRight: '1px solid #ccc',
              position: 'absolute',
              top: '0',
              bottom: '0',
              left: `2px`,
              zIndex: '99',
              color: '#FFF',
              fontSize: '1.3em',
            }}
          />
        </>
      )}
      <div
        className={cn('bar', { now: isNow })}
        style={{ height, ...(modelingContext?.targetOrderRef ? background : redLineBackground) }}
      >
        {load?.trucks && <div className="info y-value">{load?.trucks}</div>}
        {load?.trucks && <div className="info hover-highlight" />}
        <div className="info x-value">{time}</div>
        {isNow && (
          <>
            <div
              className="bottom-caret"
              style={{
                // borderRight: '1px solid white',
                position: 'absolute',
                // top: '0',
                bottom: '-21px',
                left: `-3px`,
                zIndex: '99',
                color: '#FFF',
                fontSize: '1.3em',
              }}
            >
              <i className="fas fa-caret-up" />
            </div>
          </>
        )}
      </div>
      <Space>&nbsp;</Space>
    </Styled>
  );
};

export default React.memo(Bar);
