import {css} from 'styled-components';

export default css`
  display: table;
  table-layout: fixed;
  width: 100%;

  border: 1px solid #434343;
  margin: 10px 0;

  .product-line {

    background-color: #333;
    width: 100%;
    display: flex;
    flex: 1;
    
    .product {
      padding: 5px;
      white-space: nowrap;
      text-overflow: ellipsis;
      
      overflow: hidden;
    }
    .quantity-warning {
      padding: 0;
      margin-top: auto;
      margin-bottom: auto;
      margin-left: auto;
    }
  }
  

  .other-info {
    display: flex;
    grid-gap: 15px;
    padding: 0 5px;

    .line-item-field {
      display: flex;
      grid-gap: 10px;
      flex: 1;
      align-items: center;
    }

    .hold-quantity,
    .quantity {
      justify-content: flex-end;
      flex: 1;
    }
  }
`;
