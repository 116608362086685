import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import FlexColumn from '../../order/components/FlexColumn';
import useSetup from '../useSetup';
import { useParams } from 'react-router';
import style from './style';
import SetupTable from '../components/setup-table';
import CancelReasonEditor from './cancel-reason-editor';

const prototype = {
  id: '',
  name: '',
  status: 'ACTIVE',
};

const columns = [
  {
    key: 'id',
    name: 'ID',
  },
  {
    key: 'name',
    name: 'Name',
  },
  {
    key: 'reasonType',
    name: 'Type',
    formatter: props => (props.row.reasonType === 'TAX_EXEMPT' ? 'TAX EXEMPT' : props.row.reasonType || 'TICKET'),
    filterFn: (item, filterStrings) => {
      if (!filterStrings[0]) return true;
      return item === filterStrings[0];
    },
    filterComponent: ({ innerRef, ...props }) => {
      return (
        <select ref={innerRef} {...props}>
          <option value="" />
          <option value="ORDER">Order</option>
          <option value="TICKET">Ticket</option>
          <option value="TAX_EXEMPT">Tax Exempt</option>
        </select>
      );
    },
  },
  {
    key: 'status',
    name: 'Status',
    formatter: props => props.row.status || '',
    filterFn: (item, filterStrings) => {
      if (!filterStrings[0]) return true;
      return item === filterStrings[0];
    },
    filterComponent: ({ innerRef, ...props }) => {
      return (
        <select ref={innerRef} {...props}>
          <option value="" />
          <option value="ACTIVE">Active</option>
          <option value="INACTIVE">Inactive</option>
        </select>
      );
    },
  },
];

const Styled = styled(FlexColumn)`
  ${style}
`;

const SetupCancelReasons = () => {
  const { entityRef } = useParams();
  const { getSetupItems, setupItems, busy } = useSetup('cancel-reason-code');
  const params = useMemo(() => ({ activeOnly: false }), []);

  useEffect(() => {
    entityRef && getSetupItems(params);
  }, [entityRef, getSetupItems, params]);

  return (
    <Styled>
      <SetupTable
        busy={busy}
        type="Reason Code"
        columns={columns}
        items={setupItems}
        Editor={CancelReasonEditor}
        prototype={prototype}
        onRefresh={() => getSetupItems(params)}
      />
    </Styled>
  );
};

export default SetupCancelReasons;
