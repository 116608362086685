import { useEffect } from 'react';
import useSetup from '../../useSetup';
import InputSelect from '../input-select';
import { useTranslateMessage } from '../../../order/components/localization/useTranslateMessage';

const SelectPDFTemplate = ({ id, label, value, onChange, disabled = false }) => {
  const { pdfTemplates, getPDFTemplates, busy } = useSetup();
  const translateMessage = useTranslateMessage();

  useEffect(() => {
    getPDFTemplates().then();
  }, [getPDFTemplates]);

  return (
    <InputSelect
      id={id}
      label={translateMessage({ stringId: 'locationTicketTemplate', defaultMessage: label })}
      value={value}
      options={pdfTemplates}
      onChange={onChange}
      busy={busy}
      disabled={disabled}
    />
  );
};

export default SelectPDFTemplate;
