import React from 'react';
import styled from 'styled-components';
import getUomAbbreviationOrDescription from '../../../../../util/uom';
import { Translate } from '../../localization/Translate';

const Row = styled.tr`
  .inner-table {
    max-width: 800px;
    margin-bottom: 0;
  }
`;

const HIDE_UOM_CODES = [ '/C' ];

const LoadDetailLineItem = lineItem => {
  const showUOM = !HIDE_UOM_CODES.includes(lineItem.quantity?.uomCode);
  return (
    <tr key={`line-item-${lineItem.item?.id}`}>
      <td>{lineItem.item?.itemType}</td>
      <td>{`${lineItem.item?.id} / ${lineItem.item?.description}`}</td>
      <td>{`${lineItem.quantity?.value} ${showUOM ? getUomAbbreviationOrDescription(lineItem.quantity?.uomCode) : ''}`}</td>
    </tr>
  );
};

const ShowMoreLoad = ({ ticket }) => {
  return (
    <Row key={`${ticket?.loadNumber}-more-load`}>
      <td colSpan={99}>
        <table className="inner-table">
          <thead>
            <tr>
              <th><Translate stringId="product" defaultMessage="Product" /></th>
              <th><Translate stringId="idDescription" defaultMessage="ID / Description" /></th>
              <th><Translate stringId="quantity" defaultMessage="Quantity" /></th>
            </tr>
          </thead>
          <tbody>
            {ticket?.lineItems?.map(li => LoadDetailLineItem(li))}
          </tbody>
        </table>
      </td>
    </Row>
  );
};

export default ShowMoreLoad;
