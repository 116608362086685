import cn from 'classnames';
import { get, find, isObject, set, isEmpty, kebabCase } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import FieldContainer from '../../../components/field-container/FieldContainer';
import Select from '../../../components/select';
import Input from './Input';
import { useTranslateMessage } from './localization/useTranslateMessage';

const SelectField = ({
  className,
  onChange,
  list = [],
  listBusy,
  value,
  fieldName,
  fieldLabel,
  disabled,
  required,
  valuePath,
  displayPath,
  selectFirstWhenOne,
  selectOnTabPress,
  defaultRefToSelect,
  innerRef,
  interimValue,
  readOnly,
  toggleEditor,
  addItem,
  editItem,
  open,
  tabIndex,
  datatestid,
  displaySpacing,
  error,
  fieldLabelStringId,
  values,
}) => {
  const translateMessage = useTranslateMessage();

  const label = React.useMemo(() => {
    if (fieldLabelStringId) {
      return translateMessage({ stringId: fieldLabelStringId, values, defaultMessage: fieldLabel });
    }
    return fieldLabel;
  }, [fieldLabel, fieldLabelStringId, values, translateMessage]);

  const handleChange = React.useCallback(
    val => {
      onChange(fieldName, val);
    },
    [fieldName, onChange]
  );

  const currentValue = React.useMemo(() => {
    return isObject(value) ? get(value, valuePath, '') : value || '';
  }, [value, valuePath]);

  const preparedList = React.useMemo(() => {
    // Prevents the GUID flash.
    if (interimValue && !list?.length) {
      return isEmpty(interimValue.fieldValue)
        ? []
        : [{ ...interimValue?.fieldValue, crn: interimValue?.fieldValue?.[interimValue?.valuePath || fieldName] }];
    }

    /*
      The interimValue is the value found in the order without regard for whether it's in master-data.
      We need to ensure that the interimValue, if present, remains in the list if it's not already there.
    */
    const isInList = interimValue?.fieldValue?.id
      ? find(list, { crn: interimValue?.fieldValue?.[interimValue?.valuePath || fieldName] })
      : true;

    const listWithInterimValue = isInList
      ? list
      : [
          ...(list || []),
          {
            crn: interimValue?.fieldValue?.[interimValue?.valuePath || fieldName],
            id: interimValue?.fieldValue?.id,
            name: interimValue?.fieldValue?.name,
          },
        ];

    const blankItem = set(
      set({}, valuePath, ''),
      Array.isArray(displayPath) ? displayPath[0] : displayPath,
      'Select...'
    );

    const listWithBlankEntry = [blankItem, ...(listWithInterimValue || [])];

    return required ? listWithInterimValue : listWithBlankEntry;
  }, [interimValue, list, valuePath, displayPath, required, fieldName]);

  React.useEffect(() => {
    if (list?.length === 1 && selectFirstWhenOne && !interimValue) {
      handleChange(get(list?.[0], valuePath));
    }
  }, [handleChange, interimValue, list, selectFirstWhenOne, valuePath]);

  const onBlur = React.useCallback(() => {
    if (!defaultRefToSelect) return;

    const itemExists = find(preparedList, { [valuePath]: defaultRefToSelect });

    if (itemExists && !value) {
      handleChange(defaultRefToSelect);
    }
  }, [defaultRefToSelect, handleChange, preparedList, value, valuePath]);

  const handleAddNewShortcut = React.useCallback(
    e => {
      if (addItem && e.key === '+') {
        e.preventDefault();
        e.stopPropagation();
        toggleEditor?.();
      }
    },
    [addItem, toggleEditor]
  );

  return (
    <FieldContainer className={cn(fieldName, 'field-container', className, { disabled })}>
      {label && (
        <div className="label-spinner">
          <div className="label">
            {!error ? (
              <>
                {label}
                {required && <span className="required-indicator"> *</span>}
              </>
            ) : (
              <div className="error-message">{error}</div>
            )}
          </div>
          <div className={cn('spinner', { spinning: listBusy })}>
            <span className="fas fa-sync fa-spin" />
          </div>
        </div>
      )}

      {readOnly ? (
        <Input
          readOnly
          disabled
          value={[]
            .concat(displayPath)
            .map(path => get(interimValue?.fieldValue, path))
            .filter(value => value)
            .join(' / ')}
          data-testid={datatestid || `select-${kebabCase(label?.toLowerCase?.())}`}
        />
      ) : (
        <div className={cn('field-section', { 'error-select': error })}>
          <div
            className={`field-wrapper ${addItem ? 'has-add-item' : ''}`}
            onKeyDownCapture={handleAddNewShortcut}
            data-testid={datatestid || `select-${kebabCase(label?.toLowerCase?.())}`}
          >
            <Select
              value={currentValue}
              onChange={handleChange}
              disabled={disabled}
              valuePath={valuePath}
              displayPath={displayPath}
              options={preparedList || []}
              innerRef={innerRef}
              open={open}
              onBlur={onBlur}
              selectOnTabPress={selectOnTabPress}
              tabIndex={tabIndex}
              displaySpacing={displaySpacing}
            />
          </div>
          {addItem && <div style={{ display: 'flex' }}>{addItem}</div>}
          {editItem && <div style={{ display: 'flex' }}>{editItem}</div>}
        </div>
      )}
    </FieldContainer>
  );
};

export default styled(SelectField)`
  .field-section {
    display: flex;
    grid-gap: 1px;
    .field-wrapper {
      max-width: 100%;
      flex: 1;
    }
    .field-wrapper.has-add-item {
      max-width: calc(100% - 33px);
    }
  }

  .ant-select {
    .ant-select-selector {
      .ant-select-selection-item {
        color: ${({ required, value }) => (!required && !value ? `#777` : 'inherit')};
      }
    }

    &.disabled {
      .label-spinner {
        .label {
          color: #757575;
        }
      }
    }
  }

  .error-select {
    border: 2px solid #a61d24;
    border-radius: 5px;
  }

  .error-message {
    color: #a61d24;
  }
`;
