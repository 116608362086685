import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
import Spinner from '../../../components/spinner/Spinner';
import Column from '../components/Column';
import Margin from '../components/Margin';
import Required from '../components/Required';
import Row from '../components/Row';
import { useScaleTicketingContext } from '../Context';
import Carrier from './Carrier';
import Customer from './Customer';
import Driver from './Driver';
import Location from './Location';
import Product from "./Product";
import Project from './Project';
import PurchaseOrder from './PurchaseOrder';
import Vehicle from './Vehicle';

const Styled = styled(Column)``;

const Form = () => {
  const { form, busy, locations } = useScaleTicketingContext();

  return (
    <Styled className={cn('vehicle-form')}>
      <Margin all={20}>
        <Spinner spin={busy}>
          <Required value={locations?.length}>
            <Location />
            <Required value={form?.location}>
              <Vehicle />
              <Required value={form?.vehicle}>
                <Column>
                  <Row gap={10}>
                    <Column>
                      <Driver />
                    </Column>
                    <Column>
                      <Carrier />
                    </Column>
                  </Row>
                  <Customer />
                  <Row gap={10}>
                    <Column>
                      <Project />
                    </Column>
                    <Column>
                      <PurchaseOrder />
                    </Column>
                  </Row>
                  <Product />
                </Column>
              </Required>
            </Required>
          </Required>
        </Spinner>
      </Margin>
    </Styled>
  );
};

export default Form;
