import style from '../style';
import React from 'react';
import styled from 'styled-components';
import InputSelect from '../../../components/input-select';
import InputCheckbox from '../../../components/input-checkbox';
import { integratedSystem } from '../options';

import DexaMapping from './dexa-mapping';

const View = styled.div`
  ${style}
`;

const LocationIntegration = ({ location, onChange }) => {
  return (
    <View>
      <InputSelect
        id="integratedSystem"
        label="Integrated System"
        value={location?.integratedSystem}
        onChange={onChange}
        options={integratedSystem}
        required
      />

      <DexaMapping
        id={'dexaMapping'}
        onChange={onChange}
        value={location?.dexaMapping}
        integratedSystem={location?.integratedSystem}
      />

      <InputCheckbox
        className={'option-flag'}
        value={location?.sendMixDesignEachTicket ?? false}
        id={'sendMixDesignEachTicket'}
        label={'Send Mix Design per Ticket'}
        onChange={onChange}
      />
      <InputCheckbox
        className={'option-flag'}
        value={location?.automaticallyPrintTicketPDF ?? false}
        id={'automaticallyPrintTicketPDF'}
        label={'Display Printer Prompt (Ticket Creation)'}
        onChange={onChange}
      />
      <InputCheckbox
        className={'option-flag'}
        value={location?.calcPer100WeightForBatchingSystem}
        id={'calcPer100WeightForBatchingSystem'}
        label={'Covert per 100 Weight Mix Components when Sending Load to Batch'}
        onChange={onChange}
      />
      <InputCheckbox
        className={'option-flag'}
        value={location?.executeSoundStoredProcedure}
        id={'executeSoundStoredProcedure'}
        label={'Play sound when stacking tickets'}
        onChange={onChange}
        tooltip={'Requires latest stored procedure'}
      />
    </View>
  );
};

export default LocationIntegration;
