import { Button, Dropdown, Menu } from 'antd';
import { useMemo } from 'react';

const SimpleDropDown = ({ label, options = [], size, loading, width = '100px' }) => {
  const menu = useMemo(() => {
    return (
      <Menu>
        {options.map((item, index) => (
          <Menu.Item key={index} onClick={item.onClick} metricid={item.metricId}>
            {item.label}
          </Menu.Item>
        ))}
      </Menu>
    );
  }, [options]);

  return (
    <Dropdown overlay={menu}>
      <Button size={size} loading={loading} style={{ width }}>
        {label}
      </Button>
    </Dropdown>
  );
};

export default SimpleDropDown;
