import React from 'react';
import { useIntl } from 'react-intl';
import { useAppContext } from '../../../../AppContext';
import toDateTime from './toDateTime';
// import { TimeZone } from './Timezone';

// export const Time = ({ date, hideTimeZone = false, testId, timeZone = 'America/Chicago' }) => {
//   const options = {
//     hour: '2-digit',
//     minute: '2-digit',
//     timeZone,
//   };
//
//   const dateTime = React.useMemo(() => toDateTime(date, timeZone), [date, timeZone]);
//
//   if (!date) return null;
//
//   return (
//     <span data-testid={testId}>
//       <FormattedTime value={dateTime.toJSDate()} {...options} />
//       {hideTimeZone ? (
//         ''
//       ) : (
//         <>
//           {' '}
//           (<TimeZone date={dateTime} timeZone={timeZone}/>)
//         </>
//       )}
//     </span>
//   );
// };

const time = ({ date, formatTime, timeZone, hideTimeZone, locale }) => {
  if (!date) return '';

  const options = {
    hour: '2-digit',
    minute: '2-digit',
    timeZone,
  };

  const dateTime = toDateTime(date, timeZone);

  const tz = dateTime.setLocale(locale).toFormat('ZZZZ');

  const formattedTime = formatTime(dateTime, options);

  return hideTimeZone ? formattedTime : `${formattedTime} (${tz})`;
};

export const Time = ({ date, hideTimeZone = false, testId, timeZone: timeZoneOverride, busy }) => {
  const { formatTime, formatMessage } = useIntl();
  const { timeZone: defaultTimeZone, messages, locale } = useAppContext();
  const timeZone = timeZoneOverride || defaultTimeZone;

  const formattedTime = time({ date, formatTime, formatMessage, timeZone, messages, hideTimeZone, locale });

  if (busy) {
    return (
      <div className="busy">
        <span style={{ fontSize: '0.9em' }} className="fas fa-sync fa-spin" />
      </div>
    );
  }

  return <span data-testid={testId}>{formattedTime}</span>;
};

export const useTime = () => {
  const { formatTime, formatMessage } = useIntl();
  const { timeZone: defaultTimeZone, locale } = useAppContext();

  /**
   * deprecated
   * @type {function(*, boolean=, *): string|*|string}
   */
  const getTime = React.useCallback(
    (date, hideTimeZone = false, timeZoneOverride) => {
      const timeZone = timeZoneOverride || defaultTimeZone;
      return time({ date, formatTime, formatMessage, timeZone, hideTimeZone, locale });
    },
    [formatMessage, formatTime, defaultTimeZone, locale]
  );

  const getTimeString = React.useCallback(
    ({ date, hideTimeZone = false, timeZone: timeZoneOverride }) => {
      if (!date) return '';

      const timeZone = timeZoneOverride || defaultTimeZone;

      const options = {
        hour: '2-digit',
        minute: '2-digit',
        timeZone,
      };

      const dateTime = toDateTime(date, timeZone);
      const tz = dateTime.setLocale(locale).toFormat('ZZZZ');
      const formattedTime = formatTime(dateTime, options);
      return hideTimeZone ? formattedTime : `${formattedTime} (${tz})`;
    },
    [defaultTimeZone, formatTime, locale]
  );

  return { getTime, getTimeString, Time };
};
