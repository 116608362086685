import React from 'react';
import { useMediaQuery } from 'react-responsive';

const StandardOrSmall = ({ children, breakpoint }) => {
  const smallScreen = useMediaQuery({
    query: `(max-width: ${breakpoint}px)`,
  });

  const childrenArray = React.useMemo(() => {
    const kids = React.Children.toArray(children);
    if (kids.length !== 2) {
      throw new Error('StandardOrSmall requires exactly two children.');
    }
    return kids;
  }, [children]);

  return smallScreen ? childrenArray[1] : childrenArray[0];
};

export default StandardOrSmall;
