import { css } from 'styled-components';

export default css`
  .content {
    display: flex;
  }

  input[type='time'] {
    //width: 105px;
    background-color: transparent;
    border: 1px solid #434343;
    height: 30px;
    border-radius: 3px;
    padding-left: 5px;
    font-size: 0.9rem;
    &.centered {
      text-align: center;
    }
    &:disabled {
      background-color: #141414;
    }
    &::placeholder {
      color: #666;
    }
    &:focus-visible {
      outline: none;
    }
  }

  input[type='time'] {
    &::-webkit-calendar-picker-indicator {
      cursor: pointer;
      border-radius: 4px;
      margin-right: 2px;
      filter: invert(0.6);
    }
    //width: 150px;
  }

  // input {
  //   background-color: transparent;
  //   border: 1px solid #434343;
  //   height: 30px;
  //   border-radius: 3px;
  //   padding-left: 5px;
  //   font-size: 0.9rem;
  //   &.centered {
  //     text-align: center;
  //   }
  //   &:disabled {
  //     background-color: #141414;
  //   }
  //   &::placeholder {
  //     color: #666;
  //   }
  //   &:focus-visible {
  //     outline: none;
  //   }
  // }

  .date {
    .date-parts {
      border: 1px solid #434343;
      padding-left: 5px;
      .month {
        width: 30px;
      }

      .day {
        width: 30px;
      }

      .year {
        width: 50px;
      }
    }
  }

  .start-date-time {
    display: flex;
    gap: 10px;
    .right {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      .label {
        text-align: right;
      }
    }
    &.disabled {
      .label {
        color: #757575;
      }
    }
  }
`;
