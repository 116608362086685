import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';

const Styled = styled.div`
  display: flex;
  justify-content: center;
`;
const Centered = ({ children }) => {
  return <Styled className={cn('centered')}>
    {children}
  </Styled>;
};

export default Centered;
