import React from 'react';
import { useTranslateMessage } from './useTranslateMessage';

export const useTranslateTokens = ({ fields, namespace }) => {
  const translateMessage = useTranslateMessage();

  return React.useMemo(
    () =>
      Object.keys(fields).reduce((acc, key) => {
        acc[key] = translateMessage({ stringId: namespace ? `${namespace}.${key}` : key, defaultMessage: fields[key] });
        return acc;
      }, {}),
    [namespace, fields, translateMessage]
  );
};
