import { useCallback, useState } from 'react';

export const useForm = (initialState = {}) => {
  const [form, _setForm] = useState(initialState);

  const handleChange = useCallback((field, value) => {
    _setForm(s => ({ ...s, [field]: value }));
  }, []);

  const setForm = useCallback(f => {
    _setForm(f);
  }, []);

  return { form, setForm, handleChange };
};
