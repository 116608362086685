import { DateTime } from 'luxon';
import moment from 'moment';
import { InputNumber } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useOrderContext } from '../../useOrderContext';
import EventTime from './EventTime';
import ArrivalTime from './ArrivalTime';
import { find, padStart } from 'lodash';
import { useScheduleContext } from './ScheduleContext';
import Button from '../../../../../components/button/Button';
import styled from 'styled-components';
import SelectField from '../../SelectField';
import trimDecimals from '../../../../../util/other/trim-decimals';

const Row = styled.tr`
  .more-cell {
    width: unset;
    cursor: pointer;
    text-align: center;
    user-select: none;
  }
`;

const ScheduleInputNumber = ({ resetValue, readOnly, userOverride = null, ...props }) => {
  return (
    <div className="schedule-input-number">
      <InputNumber {...props} addonAfter={readOnly ? userOverride && <span className={'fa fa-user'} /> : null} />
      {userOverride ? (
        <Button className={'schedule-reset-button'} onClick={resetValue}>
          <span className={'fa fa-undo'} />
        </Button>
      ) : null}
    </div>
  );
};

const ScheduleLine = ({ order, load, showMoreExpanded, showMoreClick, locations, loadingLocations }) => {
  const { changeScheduleLine, overrides } = useScheduleContext();

  const mergedOverrides = useMemo(() => {
    const result = {
      ...load?.overrides,
      ...overrides?.[load?.loadNumber],
    };
    return result;
  }, [load, overrides]);
  const [arriveJob, setArriveJob] = useState(null);
  const [duration, setDuration] = useState(0);
  const [tripTime, setTripTime] = useState(null);
  const [qcTime, setQcTime] = useState(0);
  const [slump, setSlump] = useState(load.slump ?? order?.lineItems[0]?.properties?.slump ?? 0);
  const [quantity, setQuantity] = useState(
    mergedOverrides?.QUANTITY && load.quantity === 0
      ? load.quantity
      : mergedOverrides?.QUANTITY ?? load.quantity ?? order?.deliverySchedule?.scheduleTimes?.loadSize ?? 0
  );
  const { start } = useOrderContext();

  const timeZone = React.useMemo(() => {
    return start?.startedOrder?.supplierParty?.timeZone || 'America/Chicago';
  }, [start?.startedOrder?.supplierParty?.timeZone]);

  useEffect(() => {
    if (load) {
      const newArriveJob = DateTime.fromISO(load?.ticketEvents?.ARRIVE_JOB).setZone(timeZone).toFormat('HH:mm');

      setQuantity(
        mergedOverrides?.QUANTITY && load.quantity === 0
          ? load.quantity
          : mergedOverrides?.QUANTITY ?? load.quantity ?? order?.deliverySchedule?.scheduleTimes?.loadSize ?? 0
      );
      setArriveJob(newArriveJob);
      setDuration(load.duration || 0);
      setQcTime(load?.overrides?.QC_TIME || 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [load, timeZone]);

  useEffect(() => {
    const hours = Math.floor(duration / 60);
    const minutes = padStart(`${duration - hours * 60}`, 2, '0');

    setTripTime(`${hours}:${minutes}`);
  }, [duration]);

  const changeQcTime = value => {
    if (value === null) {
      setQcTime(0);
      changeScheduleLine(load?.loadNumber, 'QC_TIME', null);
      return;
    }
    if (value >= 0) {
      setQcTime(value);

      changeScheduleLine(load?.loadNumber, 'QC_TIME', value);
    }
  };

  const changeSlump = value => {
    if (value === null) {
      setSlump(order?.lineItems[0]?.properties?.slump ?? 0);
      changeScheduleLine(load?.loadNumber, 'SLUMP', null);
      return;
    }
    if (value >= 0) {
      setSlump(value);

      changeScheduleLine(load?.loadNumber, 'SLUMP', value);
    }
  };

  const changeArrivalTime = value => {
    if (value) {
      const [hour, minute] = value.split(':');

      // Convert hh:mm to ISO -> YYYY-MM-DDThh:mm:00Z
      const ARRIVE_JOB = moment(load?.ticketEvents?.ARRIVE_JOB).startOf('d').hour(hour).minute(minute).toISOString();

      changeScheduleLine(load?.loadNumber, 'ARRIVE_JOB', ARRIVE_JOB);
    }

    setArriveJob(value);
  };

  const changeQuantity = useCallback(
    value => {
      if (value === null) {
        setQuantity(order?.deliverySchedule?.scheduleTimes?.loadSize);
        changeScheduleLine(load?.loadNumber, 'QUANTITY', null);
        return;
      }
      if (value >= 0) {
        setQuantity(trimDecimals(value));
        changeScheduleLine(load?.loadNumber, 'QUANTITY', trimDecimals(value));
      }
    },
    [setQuantity, changeScheduleLine, load?.loadNumber, order?.deliverySchedule?.scheduleTimes?.loadSize]
  );

  const changeLocation = useCallback(
    (_field, value) => {
      const selectedLocation = find(locations, l => l.crn === value);

      if (selectedLocation) {
        changeScheduleLine(load?.loadNumber, 'location', {
          id: selectedLocation.id,
          name: selectedLocation.name,
          locationRef: selectedLocation.crn,
        });
      }
    },
    [load?.loadNumber, locations, changeScheduleLine]
  );

  const toggleShowMore = (e, loadNumber) => {
    e.stopPropagation();
    showMoreClick(loadNumber);
  };

  const interimLocationValue = useMemo(() => {
    if (load?.overrides?.location) return load?.overrides?.location;
    return order?.location;
  }, [load?.overrides?.location, order?.location]);

  return (
    <Row key={`${load?.loadNumber}-load`}>
      <td className="more-cell" onClick={e => toggleShowMore(e, load?.loadNumber)}>
        {!showMoreExpanded && <i className="icon fal fa-chevron-right" />}
        {showMoreExpanded && <i className="icon fal fa-chevron-down" />}
      </td>
      <td>{load?.loadNumber}</td>
      <td>
        <SelectField
          list={locations}
          value={
            loadingLocations ? 'Loading...' : mergedOverrides?.location?.locationRef || order?.location?.locationRef
          }
          valuePath="crn"
          displayPath={['id', 'name']}
          listBusy={loadingLocations}
          disabled={loadingLocations}
          onChange={changeLocation}
          datatestid={`select-load-${load.loadNumber}-location`}
          interimValue={{ fieldValue: interimLocationValue, valuePath: 'locationRef' }}
        />
      </td>
      <td>
        <ScheduleInputNumber
          min={1}
          max={12}
          value={quantity}
          onChange={changeQuantity}
          userOverride={mergedOverrides?.QUANTITY}
          resetValue={() => changeQuantity(null)}
          data-testid={`input-load-${load.loadNumber}-qty`}
        />
      </td>
      <td>{load?.totalQuantity}</td>
      <td>
        <ScheduleInputNumber
          min={1}
          max={12}
          value={slump}
          userOverride={mergedOverrides?.SLUMP}
          resetValue={() => changeSlump(null)}
          onChange={changeSlump}
          data-testid={`input-load-${load.loadNumber}-slump`}
        />
      </td>
      <td>{load?.vehicleId}</td>
      <td>
        <EventTime ticketEvents={load?.ticketEvents} statusCode="PRINTED" timeZone={timeZone} />
      </td>
      <td>
        <EventTime ticketEvents={load?.ticketEvents} statusCode="LOADING_STARTED" timeZone={timeZone} />
      </td>
      <td>
        <ScheduleInputNumber
          min={0}
          max={60}
          value={qcTime}
          userOverride={mergedOverrides?.QC_TIME}
          resetValue={() => changeQcTime(null)}
          onChange={changeQcTime}
          data-testid={`input-load-${load.loadNumber}-qc`}
        />
      </td>
      <td>
        <EventTime ticketEvents={load?.ticketEvents} statusCode="TO_JOB" timeZone={timeZone} />
      </td>
      <td data-testid={`input-load-${load.loadNumber}-arrive-job`}>
        <ArrivalTime time={arriveJob} onChange={changeArrivalTime} />
      </td>
      <td>
        <EventTime ticketEvents={load?.ticketEvents} statusCode="UNLOADING" timeZone={timeZone} />
      </td>
      <td>
        <EventTime ticketEvents={load?.ticketEvents} statusCode="END_UNLOADING" timeZone={timeZone} />
      </td>
      <td>
        <EventTime ticketEvents={load?.ticketEvents} statusCode="LEAVE_JOB" timeZone={timeZone} />
      </td>
      <td>
        <EventTime ticketEvents={load?.ticketEvents} statusCode="IN_YARD" timeZone={timeZone} />
      </td>
      <td>{tripTime}</td>
    </Row>
  );
};

export default ScheduleLine;
