import { useEffect, useRef, useMemo } from 'react';
import { DatePicker } from 'antd';
import cn from 'classnames';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import { Translate } from '../../../order/components/localization/Translate';
import style from './style';
import Button from '../../../../components/button/Button';
import { useIntlContext } from '../../../../i18n';
import { useDateFormat } from '../../../../hooks/useDateFormat';

const Styled = styled.div`
  ${style}
`;

const { RangePicker } = DatePicker;

export const Selector = ({ id, label, onSelect, selected, loading, metricId }) => {
  return (
    <Button
      className="selector"
      id={id}
      onClick={() => onSelect(id)}
      metricId={metricId}
      type={selected === id ? 'primary' : ''}
    >
      {loading && selected === id && <i className="fa fa-sync fa-spin" />}
      {label}
    </Button>
  );
};

const SelectDates = ({ loading, selected, setSelected, customDateRange, updateCustomDateRange }) => {
  const { userLocale } = useIntlContext();
  const { dateFormat, antLocales } = useDateFormat(userLocale);

  const localValue = useMemo(() => {
    if (['today', 'yesterday', 'all', 'currentMonth'].includes(selected)) {
      updateCustomDateRange([]);
      return selected;
    }
    return 'custom';
  }, [selected, updateCustomDateRange]);

  const previousSelected = useRef();

  useEffect(() => {
    if (localValue !== 'custom') {
      previousSelected.current = localValue;
    }
  }, [localValue]);

  return (
    <Styled className={cn('quick-dates', { 'custom-selected': !isEmpty(customDateRange) })}>
      <table>
        <tbody>
          <tr>
            <td>
              <Selector
                loading={loading}
                id="today"
                label={<Translate stringId="invoices.today" defaultMessage="Today" />}
                onSelect={setSelected}
                selected={localValue}
                metricId="core-unbilled-deliveries-filter-today"
              />
            </td>
            <td>
              <Selector
                loading={loading}
                id="yesterday"
                label={<Translate stringId="invoices.yesterday" defaultMessage="Yesterday" />}
                onSelect={setSelected}
                selected={localValue}
                metricId="core-unbilled-deliveries-filter-yesterday"
              />
            </td>
            <td>
              <Selector
                loading={loading}
                id="currentMonth"
                label={<Translate stringId="invoices.thisMonth" defaultMessage="This Month" />}
                onSelect={setSelected}
                selected={localValue}
                metricId="core-unbilled-deliveries-filter-this-month"
              />
            </td>
            <td>
              <Selector
                loading={loading}
                id="all"
                label={<Translate stringId="invoices.all" defaultMessage="All" />}
                onSelect={setSelected}
                selected={localValue}
                metricId="core-unbilled-deliveries-filter-all"
              />
            </td>
            <td>
              <div className="range-picker">
                <div className={cn('range-picker', { loading: loading && localValue === 'custom' })}>
                  <div className={cn('spinner')}>
                    <i className="spinner-icon fal fa-spin fa-spinner-third" />
                  </div>
                  <RangePicker
                    onChange={updateCustomDateRange}
                    value={customDateRange}
                    allowClear={false}
                    disabled={loading}
                    format={dateFormat}
                    locale={antLocales[userLocale]}
                  />
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </Styled>
  );
};

export default SelectDates;
