import React from "react";
import getUomAbbreviationOrDescription from "../../util/uom";

const UOM = ({uomCode}) => {
  return (
    <span>{getUomAbbreviationOrDescription(uomCode)}</span>
  )
}

export default UOM;
