import React from 'react';
import { FormattedMessage } from 'react-intl';

export const Translate = ({ stringId, values, defaultMessage }) => {
  if(!stringId) {
    throw new Error('stringId is required')
  }

  return <FormattedMessage id={stringId} values={values} defaultMessage={defaultMessage} />;
};
