import { message } from 'antd';
import { useParams } from 'react-router';
import React, { useCallback, useEffect, useState } from 'react';
import { api as orderApi } from '../../order/api';

const useRoles = roleRef => {
  const { entityRef } = useParams();

  const [role, setRole] = useState(null);
  const [roleBusy, setRoleBusy] = useState(false);

  const [locations, setLocations] = useState(null);
  const [locationBusy, setLocationBusy] = useState(false);

  const getRole = useCallback(
    roleRef => {
      setRoleBusy(true);

      orderApi
        .getRole(entityRef, roleRef)
        .then(setRole)
        .catch(() => {})
        .finally(() => setRoleBusy(false));
    },
    [entityRef]
  );

  const updateRole = useCallback(
    (roleRef, updates) => {
      const hide = message.loading({ content: 'Saving role...', style: { marginTop: '20px' }, duration: 0 });

      setRoleBusy(true);
      orderApi
        .updateRole(entityRef, roleRef, updates)
        .then(setRole)
        .then(() => {
          hide?.();
          message.success({
            content: 'Role has been successfully updated.',
            style: { marginTop: '10px' },
            duration: 2,
          });
        })
        .catch(alert)
        .finally(() => setRoleBusy(false));
    },
    [entityRef]
  );

  const getLocations = useCallback(() => {
    setLocationBusy(true);

    orderApi
      .getLocations(entityRef)
      .then(setLocations)
      .catch(alert)
      .finally(() => setLocationBusy(false));
  }, [entityRef]);

  useEffect(() => {
    if (roleRef) {
      getRole(roleRef);
      getLocations();
    }
  }, [getLocations, getRole, roleRef]);

  const busy = React.useMemo(() => locationBusy || roleBusy, [locationBusy, roleBusy]);

  return {
    updateRole,
    role,
    locations,
    busy,
    locationBusy,
    roleBusy,
  };
};

export default useRoles;
