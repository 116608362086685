import { css } from 'styled-components';

export default css`
  display: flex;
  flex-direction: column;
  
  .ant-checkbox+span {
    user-select: none;
  }
  
  .outer {
    height: 400px;
    margin: 0 10px;
    display: flex;
    color: #999;
    justify-content: center;
    overflow-y: visible;
    position: relative;
    .mark-wrapper {
      position: relative;
      height: 100%;
      flex-grow: 1;
      min-width: 0;
      pointer-events:none;
      .mark {
        z-index: 999; 
        position: absolute;
        border-top: 1px solid #333;
        left: -10px;
        right: -10px;
        bottom: 55px;
        width: 100%;
        pointer-events:none;
      }
    }
  }

  .y-labels {
    margin-right: 10px;
    margin-top: 0;
    margin-bottom: 5px;
    display: flex;
    flex-direction: column-reverse;

    .interval {
      text-align: right;
    }
  }

  .graph-wrapper {
    display: flex;
    flex-direction: column;
    overflow-x: auto;
    overflow-y: hidden;
    flex: 1;

    padding-top: 10px;
    margin-bottom: -5px;

    .graph {
      display: flex;
      flex: 1;
      align-items: flex-end;
      grid-gap: 10px;
      position: relative;
      z-index: 1000;

      .date-label {
        color: #fff;
        display: flex; 
        align-items: center;
        justify-content: center; 
        position: absolute;
        width: 100px;
        margin-top: 20px;
        margin-left: -50px;
        font-size: 1rem;
      }

      .bar-stack {
        display: flex;
        flex-direction: column;
        margin-bottom: 15px;
        position: relative;

        .separator {
          position: absolute;
          border-left: 1px solid #333;
          z-index: 10000;
          .caret-wrapper {
            position: relative;
            .caret {
              position: relative;
              top: -13px;
              right: 5.5px;
              color: #333;
              font-size: 17px;
            }
          }
        }
       
        .bar {
          z-index: 1000;
          min-width: 15px;
          max-width: 15px;
          background-color: #40937e;
          transition: height 0.3s;

          &:first-child {
            border-top-right-radius: 2px;
            border-top-left-radius: 2px;
          }

          position: relative;
          
          &.delivered {
            background-color: #177ddc;
          }

          &.cancelled {
            background-color: #aaa;
          }
          
          &.warning {
            background-color: #dfc867;
          }

          &.success {
            background-color: #40937e;
          }

          &.danger {
            background-color: #c16868;
          }

          .label {
            position: absolute;
            bottom: -17px;
            color: #fff;
            font-size: 0.8rem;
          }
        }
      }
    }

    .x-labels {
      display: flex;
      color: #fff;
      grid-gap: 10px;

      .time {
        min-width: 15px;
        max-width: 15px;
        color: #fff;
        font-size: 0.8rem;

        &:last-child {
          min-width: 35px;
          max-width: 35px;
        }
      }
    }
  }
`;
