import { uniq } from 'lodash';
import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import cn from 'classnames';
import Spinner from '../../spinner/Spinner';
import { api } from '../../../views/order/api';
import OrderCard from './OrderCard';
import style from './style';

const Styled = styled.div`
  ${style}
`;

const listAllOrders = async (entityRef, queryParams) => {
  let { items, pageToken } = await api.listOrders(entityRef, queryParams);
  while (pageToken) {
    queryParams.pageToken = pageToken;
    const result = await api.listPageOrders(entityRef, queryParams);
    items = items.concat(result.items);
    pageToken = result.pageToken;
  }

  return items;
};

const SelectedOrders = ({ selectedItem, date }) => {
  const { entityRef } = useParams();

  const { loads } = selectedItem;

  const [busy, setBusy] = React.useState(false);
  const [orders, setOrders] = React.useState(null);

  const orderRefs = React.useMemo(() => uniq(loads?.orderRefs || []), [loads?.orderRefs]);

  React.useEffect(() => {
    if (orderRefs && date) {
      setBusy(true);
      const queryParams = {
        firstStartDateTime: date.startOf('day').toUTC().minus({ days: 1 }).toISO(),
        lastStartDateTime: date.endOf('day').toUTC().plus({ days: 1 }).toISO(),
        r: orderRefs.join(','),
      };
      listAllOrders(entityRef, queryParams)
        .then(response => {
          setOrders(
            response.map(order => ({
              ...order,
              loads: loads?.inProgress?.filter?.(load => load.orderRef === order.crn),
            }))
          );
        })
        .finally(() => {
          setBusy(false);
        });
    }
  }, [date, entityRef, loads?.inProgress, orderRefs]);

  return (
    <Styled className={cn('selected-orders')}>
      <Spinner spin={busy}>
        <div>
          {orders?.map?.(order => (
            <OrderCard item={order} />
          ))}
        </div>
      </Spinner>
    </Styled>
  );
};

export default React.memo(SelectedOrders);
