import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { api } from '../../../api';
import { pick } from 'lodash';
import { useOrderContext } from '../../useOrderContext';
import { calcLoadCountWithLoadNumbers } from './calc-load-count-with-load-numbers';
import { calcLoadCountWithTimeTrigger } from './calc-load-count-with-time-trigger';
import calculateLoadCountWithMinimumLoadChargeProuct from './calc-load-count-with-minimum-load-charge-product';

const fieldsToMerge = [
  'cityTax',
  'countyTax',
  'extendedPrice',
  'subtotalPrice',
  'totalPrice',
  'districtTax',
  'stateTax',
  'percentTax',
  'salesTax',
];

const getPriceSummaryForOrder = order => {
  const pricingValue = {
    loadCount: 0,
    ...pick(order, ['taxRate', 'priceSummary']),
    supplierParty: order?.supplierParty,
    products: order.lineItems?.map(li => {
      const ticketedProductLoadCount =
        order.deliverySchedule?.tickets?.filter(
          t => !!t.ticket?.lineItems?.find(tli => tli.item?.productRef === li.item?.productRef)
        )?.length ?? 0;

      const scheduledProductLoadCount = order.deliverySchedule?.schedule?.length ?? 0;

      let loadCount;

      if (li.loadTimeTrigger?.triggerLogic) {
        loadCount = calcLoadCountWithTimeTrigger(li.loadTimeTrigger.triggerLogic, order, li.item?.productRef);
      } else if (li?.item?.minimumLoadCharge) {
        loadCount = calculateLoadCountWithMinimumLoadChargeProuct(
          li?.item?.productRef,
          order?.minimumLoadChargeLoadsData
        );
      } else {
        loadCount = calcLoadCountWithLoadNumbers(
          ticketedProductLoadCount + scheduledProductLoadCount,
          li.properties?.loadNumbers
        );
      }

      return {
        productRef: li.item?.productRef,
        ...pick(li.item, ['id', 'name', 'type', 'uomCode', 'category', 'minimumLoadCharge']),
        quantity: li.orderedQuantity?.value,
        priceSummary: li.priceSummary,
        loadCount,
      };
    }),
  };
  return pricingValue;
};

const getPriceSummaryForOrderGroup = orderGroup => {
  const mergePriceSummary = (summary1, summary2, orderPriceSummaryFormat = false) => {
    const merged = {
      taxRate: summary1?.taxRate || summary2?.taxRate,
    };

    //The priceSummary of the order does not contain the same things of the products
    if (orderPriceSummaryFormat) {
      merged.taxPerProduct = summary1?.taxPerProduct || summary2?.taxPerProduct;
      merged.taxRates = summary1?.taxRates || summary2?.taxRates;
    } else {
      merged.unitPrice = summary1?.unitPrice;
      merged.priceSource = summary1?.priceSource;
      merged.isTaxable = summary1?.isTaxable || summary2?.isTaxable;
    }

    for (const field of fieldsToMerge) {
      merged[field] = (summary1?.[field] || 0) + (summary2?.[field] || 0);
    }

    return merged;
  };

  const priceValues = [];

  for (const linkedOrder of orderGroup.linkedOrders) {
    if (linkedOrder?.supplierStatus?.name === 'CANCELLED' && orderGroup.linkedOrders.length > 1) continue;

    priceValues.push(getPriceSummaryForOrder(linkedOrder));
  }

  const priceValue = priceValues.reduce((acc, value) => {
    if (!acc) {
      return value;
    }
    const merged = {
      loadCount: (acc.loadCount || 0) + (value.loadCount || 0),
      taxRate: acc.taxRate || value.taxRate,
      supplierParty: acc.supplierParty || value.supplierParty,
      priceSummary: mergePriceSummary(acc.priceSummary, value.priceSummary, true),
    };

    const mergedProducts = acc.products;

    for (const product of value.products) {
      const existingProduct = mergedProducts.find(p => p.productRef === product.productRef);
      if (existingProduct) {
        existingProduct.quantity += product.quantity;
        existingProduct.loadCount += product.loadCount;
        existingProduct.priceSummary = mergePriceSummary(existingProduct.priceSummary, product.priceSummary);
      } else {
        mergedProducts.push(product);
      }
    }

    merged.products = mergedProducts;

    return merged;
  }, null);

  return priceValue;
};

export const usePricing = () => {
  const { entityRef } = useParams();
  const [order, setOrder] = useState(null);
  const [pricing, setPricing] = useState(null);
  const [pricingBusy, setPricingBusy] = useState(false);
  const [costBooks, setCostBooks] = useState([]);

  const {
    order: { applyRawOrder },
  } = useOrderContext();

  const repriceOrder = useCallback(
    (orderCrn, params) => {
      setOrder(null);
      setPricing(null);
      setPricingBusy(true);

      return api
        .repriceOrder(entityRef, orderCrn, params)
        .then(o => {
          setOrder(o);
          applyRawOrder(o);
        })
        .finally(() => {
          setPricingBusy(false);
        });
    },
    [entityRef, applyRawOrder]
  );

  const getOrder = useCallback(
    orderCrn => {
      setOrder(null);
      setPricing(null);
      setPricingBusy(true);

      return api
        .getOrder(entityRef, orderCrn, { expand: 'order-group' })
        .then(setOrder)
        .finally(() => {
          setPricingBusy(false);
        });
    },
    [entityRef]
  );

  useEffect(() => {
    if (!order) return;
    const pricingValue =
      order.linkedOrders.length > 0 ? getPriceSummaryForOrderGroup(order) : getPriceSummaryForOrder(order);
    setPricing(pricingValue);
  }, [order]);

  const getOrderCostBooks = useCallback(
    async orderCrn => {
      return api.getOrderCostBooks(entityRef, orderCrn).then(setCostBooks);
    },
    [entityRef]
  );

  return {
    getOrder,
    repriceOrder,
    pricingBusy,
    order,
    pricing,
    getOrderCostBooks,
    costBooks,
  };
};
