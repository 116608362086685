import React from 'react';
import cn from 'classnames';
import style from './style';
import styled from 'styled-components';
import { kebabCase } from 'lodash';

import FieldContainer from '../../../../components/field-container/FieldContainer';
import Select from '../../../../components/form/Select';

const { Option } = Select;

const View = styled(FieldContainer)`
  ${style}
`;

const InputSelect = ({
  className,
  id,
  label,
  value,
  options = [],
  onChange,
  disabled = false,
  required = true,
  busy = false,
}) => {
  return (
    <View className={cn(id, className)}>
      <div className="label-spinner">
        <div className="label">
          {required ? `${label} *` : label}
          <div className={cn('spinner', { spinning: busy })}>
            <span className="fas fa-sync fa-spin" />
          </div>
        </div>
      </div>
      <Select
        value={value || ''}
        showSearch
        filterOption={(input, option) => option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        disabled={disabled}
        onChange={value => (onChange ? onChange(id, value) : console.log(id, value))}
        data-testid={`select-${kebabCase(label?.toLowerCase())}`}
      >
        {!required && <Option value="">Select...</Option>}
        {options?.map((option, index) => (
          <Option key={`${index}-${id}-option`} value={option.value}>
            {option.label || option.value}
          </Option>
        ))}
      </Select>
    </View>
  );
};

export default InputSelect;
