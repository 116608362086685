import React from 'react';
import Select from '../../../../components/form/Select';
import styled from 'styled-components';
import { Translate } from '../localization/Translate';

const { Option } = Select;

const Title = styled.div`
  padding: 5px;
`;

const SelectDriver = ({ driverRef = '', drivers = [], onChange, disabled }) => {
  return (
    <div>
      <Title><Translate stringId="assignedDriver" defaultMessage="Assigned Driver" /></Title>
      <Select
        id="select-driver"
        data-testid="select-assigned-driver"
        className="select-driver"
        // defaultValue={''}
        showSearch
        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        value={driverRef}
        onChange={onChange}
        disabled={disabled}
      >
        <Option value="">Select...</Option>
        {drivers?.map((v, key) => {
          const labelPieces = [];

          v.id && labelPieces.push(v.id);
          const nameValue = `${v.firstName} ${v.lastName}`.trim();
          nameValue && labelPieces.push(nameValue);

          const label = labelPieces.join(' / ');

          return (
            <Option key={`${key}-select-driver`} value={v.crn}>
              {label}
            </Option>
          );
        })}
      </Select>
    </div>
  );
};

export default SelectDriver;
