import { Cache as AwsCache, Cache } from "aws-amplify";
import { decode, encode, salt } from '../security';

const PLATFORM_01 = '050102';

export const cacheItem = (name, item, options = {}) => {
  if (name === 'x-connex-id') {
    name = PLATFORM_01;
  }

  const { 'x-api-key': apiKey } = Cache.getItem('awsConfig') ?? {};

  if (name === PLATFORM_01 && item !== null) {
    return Cache.setItem(name, encode(`${name}:${salt}:${apiKey}`, item), options);
  }

  if (typeof item === 'object') {
    const {
      location: { hostname },
    } = window;

    if (hostname?.includes('localhost') || hostname.includes('model')) {
      const s = JSON.stringify(item);

      item = `${name}:` + encode(`${name}:${salt}:${apiKey}`, s);
    }
  }

  return Cache.setItem(name, item, options);
};

export const clearCachedItem = name => {
  Cache.removeItem(name);
};

export const getCachedItem = (name, defaultValue = null) => {
  if (name === 'x-connex-id') {
    name = PLATFORM_01;
  }

  const { 'x-api-key': apiKey } = Cache.getItem('awsConfig') ?? {};
  let value = Cache.getItem(name);

  if (value !== null && name === PLATFORM_01) {
    return decode(`${name}:${salt}:${apiKey}`, value);
  }

  if (typeof value === 'string' && value?.startsWith(`${name}:`)) {
    value = JSON.parse(decode(`${name}:${salt}:${apiKey}`, value.substr(name.length + 1)));
  }

  return value ?? defaultValue;
};
