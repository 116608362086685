import style from "../style";
import React from "react";
import styled from 'styled-components';
import InputText from "../../../components/input-text";

const View = styled.div`${style}`;

const CarrierIntegration = ({ carrier, onChange }) => {
  return (
    <View>
      <InputText id={'trackItUID'} label={'TrackIt Reference Id'} value={carrier?.trackItUID} onChange={onChange} />
    </View>
  )
}

export default CarrierIntegration;
