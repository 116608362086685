import {Input as AntInput, Form, Row, Col} from 'antd';
import React from 'react';
import styled from 'styled-components';
import {get} from 'lodash';

const List = ({ fieldValue, className, field }) => {
  const {label} = field;
  const items = fieldValue || [];
  return <div className={className}>
    {label && <div className={'form-list-title'}>{label}</div>}
    {items.map((item, itemKey) => {

      return <Row key={itemKey}>
        {Array.isArray(item) ? item.map((column, columnKey) => {
          return <Col span={12} key={columnKey}>
            {column}
          </Col>
        }) : item}
      </Row>
    })}
  </div>
};

export default styled(List)`
  color: white;
  .form-list-title {
    font-size: 24px;
  }
`;
