import React from "react";
import styled from 'styled-components';

const StyledInput = styled.input`
  background-color: #333;
`;

const ArrivalTime = ({time, onChange}) => {
  return (
    <StyledInput type='time' value={time || ''} onChange={e => onChange(e.target.value)} />
  )
}

export default ArrivalTime;
