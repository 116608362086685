import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useTranslateMessage } from '../../../../order/components/localization/useTranslateMessage';
import style from './style';
import cn from 'classnames';
import VehicleList from '../../components/vehicle-list';

const VehiclesInYard = ({ className, view = {}, setTitle }) => {
  const vehicleCount = useMemo(() => {
    return view?.vehicles?.length || 0;
  }, [view]);

  const translateMessage = useTranslateMessage();

  useEffect(() => {
    setTitle(
      translateMessage({
        stringId: 'vehicleCountDeadheadVehicles',
        defaultMessage: '({vehicleCount}) Deadhead Vehicles',
        values: { vehicleCount },
      })
    );
  }, [setTitle, translateMessage, vehicleCount]);

  return (
    <div className={cn(className)}>
      <VehicleList showTimer={true} vehicles={view?.vehicles} />
    </div>
  );
};

export default styled(VehiclesInYard)`
  ${style}
`;
