import { css } from 'styled-components';

export default css`
  margin-top: 10px;
  flex-direction: row;
  display: flex;
  align-items: center;

  .warning-icon {
    flex-direction: column;
    font-size: 1.5rem;
  }
  .warning-text {
    margin-left: 20px;
    flex-direction: column;
  }
`;
