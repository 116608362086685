import http from '../../../http';

const orders = '/connex/services/orders';
const map = ref => {
  return `/v2/geo/${ref}/embedded-map`;
};

export const API = {
  initialize: (ref, data) => http.post(`${orders}/${ref}/tracking/initialize`, data),
  buildView: (ref, data) => http.post(`${orders}/${ref}/tracking/view`, data),
  ticketGeo: (ref, ticketRef) => http.get(`/v2/geo/${ref}/status-events/tickets/${ticketRef}`),
  createMapConfig: (ref, config) => http.post(map(ref), config),
  updateMapConfig: (ref, crn, config) => http.post(`${map(ref)}/${crn}`, config),
  getTicket: (ref, crn, queryParams) => http.get(`${orders}/${ref}/tracking/ticket/${crn}`, queryParams),
  updateTicketBillableNote: (ref, crn, body) => http.post(`${orders}/${ref}/tracking/ticket/${crn}/billing-note`, body),
  statusVehicle: (ref, crn, statusCode) => http.post(`${orders}/${ref}/vehicles/${crn}/status`, { statusCode }),
  updateVehicle: (ref, crn, data) => http.post(`${orders}/${ref}/vehicles/${crn}`, data),
  changeVehicleLocation: (ref, crn, locationType, locationRef) =>
    http.post(`${orders}/${ref}/vehicles/${crn}/location/${locationType}/${locationRef}`, {}),
  searchVehicleAlerts: (ref, startDate, endDate) =>
    http.post(`${orders}/${ref}/vehicle-alerts/search`, { startDate, endDate }),
  dismissVehicleAlert: (ref, alertRef) => http.post(`${orders}/${ref}/vehicle-alerts/${alertRef}/dismiss`),
  getScreenLayout: ref => http.get(`${orders}/${ref}/tracking/config/screen-layout`),
  saveScreenLayout: (ref, data) => http.post(`${orders}/${ref}/tracking/config/screen-layout`, data),
  getTrackingConfiguration: ref => http.get(`${orders}/${ref}/tracking/config/tracking-configuration`),
  saveTrackingConfiguration: (ref, data) => http.post(`${orders}/${ref}/tracking/config/tracking-configuration`, data),
};

export default API;
