import React, { useContext } from 'react';
import { useModeling } from './useModeling';

const ModelingContext = React.createContext();

export const useModelingContext = () => {
  const context = useContext(ModelingContext);

  return context;
};

const ModelingContextProvider = ({ children, ...props }) => {
  const modeling = useModeling({ ...props });

  return <ModelingContext.Provider value={{ ...modeling }}>{children}</ModelingContext.Provider>;
};

export default ModelingContextProvider;
