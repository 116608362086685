import moment from 'moment';
import { getCachedItem } from '../cache';
import recordMetrics from './recordMetrics';
import { Queue } from './Queue';

const queue = new Queue(recordMetrics, 10000);

export const recordMetric = async ({ type, data, fn = () => {}, additionalData = {}, values }) => {
  const payload = { type, data, additionalData };

  const startTime = moment();

  const result = fn();

  let endTime = moment();

  // const entity = SDK.getEntity();
  const entityRef = getCachedItem('entityRef');

  if (result && result.finally) {
    // TODO: peek into result and record given elements of the response. i.e. itemCount
    result.finally(() => {
      endTime = moment();
      const payloadWithRunTime = { ...payload, entityRef, values: { run_time: endTime.diff(startTime) } };
      queue.add(payloadWithRunTime);
    });
  } else {
    const payloadWithRunTime = { ...payload, entityRef, values: { run_time: endTime.diff(startTime), ...values } };
    queue.add(payloadWithRunTime);
  }

  return result;
};

export const callWithMetrics = (fn, metric, entityRef) => (...args) => {
  const startTime = moment();

  const result = fn(...args);

  // In case fn is a synchronous call.
  let endTime = moment();

  // Check for a promise
  if (result?.finally) {
    result.finally(() => {
      endTime = moment();
      const payload = { ...metric, entityRef, values: { run_time: endTime.diff(startTime) } };
      queue.add(payload);
    });
  } else {
    const payload = { ...metric, entityRef, values: { run_time: endTime.diff(startTime) } };
    queue.add(payload);
  }
};

export const timer = id => {
  const startTime = moment();

  return additionalData => {
    const endTime = moment();
    const runTime = endTime.diff(startTime);
    recordMetric({ type: 'timer', data: { id }, additionalData, values: { run_time: runTime } });
  };
};

export const buttonClick = (fn, id, additionalData) =>
  recordMetric({
    type: 'user-interaction',
    data: { type: 'button-click', id },
    fn,
    additionalData,
  });

export const navigateTo = data =>
  recordMetric({
    type: 'navigate-to',
    data,
  });

export const navigateFrom = (data, values) =>
  recordMetric({
    type: 'navigate-from',
    data,
    values,
  });

export const linkClick = (id, additionalData) => {
  recordMetric({
    type: 'user-interaction',
    data: { type: 'button-click', id },
    additionalData,
  });
  return true;
};

export const menuItemClick = (fn, id, additionalData) => {
  recordMetric({
    type: 'user-interaction',
    data: { type: 'menu-item-click', id },
    fn,
    additionalData,
  });
};

export const tabClick = id => {
  recordMetric({
    type: 'user-interaction',
    data: { type: 'tab-click', id },
  });
};

export const functionCall = (fn, id, additionalData) =>
  recordMetric({
    type: 'ui-process',
    data: { type: 'function-call', id },
    fn,
    additionalData,
  });

export const error = (id, additionalData) =>
  recordMetric({
    type: 'ui-error',
    data: { id },
    additionalData,
  });
