import { find, padStart } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { useAppContext } from '../../../AppContext';
import style from './style';

const Styled = styled.div`
  ${style}
`;

const sampleDate = new Date(2023, 1, 1, 15, 0, 0, 0);

const Xaxis = ({ left }) => {
  const { locale = 'en-US' } = useAppContext();

  const formatter = React.useMemo(
    () =>
      new Intl.DateTimeFormat(locale, {
        weekday: 'long',
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        fractionalSecondDigits: 3,
      }),
    [locale]
  );

  const is24Hour = React.useMemo(() => {
    const dateParts = formatter.formatToParts(sampleDate);

    const hour = find(dateParts, { type: 'hour' });

    return hour?.value === '15';
  }, [formatter]);

  const getHour = React.useCallback(
    idx => {
      const hour = idx === 0 ? 0 : idx / 12;

      if (hour === 0 || hour === 24) {
        return is24Hour ? '00:00' : '12 AM';
      }

      if (hour === 12) {
        return is24Hour ? '12:00' : '12 PM';
      }

      if (is24Hour) {
        return `${padStart(hour, 2, '0')}:00`;
      }

      if (hour < 12) {
        return `${hour} AM`;
      }

      return `${hour - 12} PM`;
    },
    [is24Hour]
  );

  return (
    <Styled className={cn('xaxis')}>
      <div className="x-axis-ticks" style={{ left: left }}>
        {Array.apply(null, Array(289)).map((x, idx) => (
          <div style={{ width: '10px' }}>{idx === 0 || idx % 12 === 0 ? '|' : "'"}</div>
        ))}
      </div>

      <div className="x-axis-labels" style={{ left: left - 2 }}>
        {Array.apply(null, Array(289)).map((x, idx) => {
          const isHourMark = idx === 0 || idx % 12 === 0;

          return (
            <div className={cn({ label: isHourMark })} style={{ width: '10px', whiteSpace: 'nowrap' }}>
              {isHourMark ? getHour(idx) : ''}
            </div>
          );
        })}
      </div>
    </Styled>
  );
};

export default Xaxis;
