import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import AuthenticatedRoutes from './AuthenticatedRoutes';
import Spinner from './components/spinner/Spinner';
import api from './http';
import { cacheItem, getCachedItem } from './util/cache/index';
import InvalidUser from './views/InvalidUser';
import UserContextProvider from './views/order/components/user-context/UserContextProvider';

const AuthCheck = () => {
  const [validUser, setValidUser] = useState(false);
  const [userRef, setUserRef] = useState(null);
  const [permissions, setPermissions] = React.useState(null);
  const [loadingBusy, setLoadingBusy] = useState(true);
  const navigate = useNavigate();
  const { entityRef } = useParams();

  useEffect(() => {
    cacheItem('entityRef', entityRef);
    const url = `/connex/services/auth/${entityRef}`;
    const token = getCachedItem('x-connex-id');

    api
      .post(url, {
        source: getCachedItem('source', 'connex'),
        token,
      })
      .then(resp => {
        if (window?.FS?.identify) {
          window.FS.identify(resp.userRef);
        }
        setUserRef(resp.userRef);
        setValidUser(resp.valid);
        setPermissions(resp.permissions);
        navigate(`${window?.location?.pathname?.[0] !== '/' ? '/' : ''}${window.location.pathname}`);
      })
      .finally(() => {
        setLoadingBusy(false);
      });
  }, [entityRef, navigate, setUserRef]);

  return (
    <Spinner spin={loadingBusy}>
      {validUser ? (
        <UserContextProvider user={{ userRef, permissions }}>
          <AuthenticatedRoutes />
        </UserContextProvider>
      ) : (
        <InvalidUser />
      )}
    </Spinner>
  );
};

export default AuthCheck;
