import cn from 'classnames';
import styled from 'styled-components';
import { useMemo } from 'react';
import Label from '../../../../../../components/label/Label';
import { Table } from 'reactstrap';
import { Number } from '../../../../components/localization/Number';
import { Translate } from '../../../../components/localization/Translate';
import { Uom } from '../../../../components/localization/Uom';

const Styled = styled.div`
  display: grid;

  .mix-info-cell {
    border-bottom: 1px solid #5e5e60;
    padding: 2px 2px 10px 2px;
  }
`;

const MixInfo = ({ primaryLineItem }) => {
  const mixInfo = useMemo(() => {
    if (primaryLineItem?.item?.itemType === 'CONCRETE') {
      return primaryLineItem?.mixInfo;
    }
    return null;
  }, [primaryLineItem]);

  if (!mixInfo) {
    return null;
  }

  return (
    <Styled>
      {mixInfo && (
        <>
          <h3>
            <Translate stringId="mixInfo" defaultMessage="Mix Info" />
          </h3>

          <Table striped responsive className="w-100">
            <thead>
              <tr>
                <th style={{ textAlign: 'center' }}>
                  <Translate stringId="targetSlump" defaultMessage="_Target Slump" />
                </th>
                <th style={{ textAlign: 'center' }}>
                  <Translate stringId="minSlump" defaultMessage="_Min Slump" />
                </th>
                <th style={{ textAlign: 'center' }}>
                  <Translate stringId="maxSlump" defaultMessage="_Max Slump" />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="mix-info-row">
                <td className={cn('mix-info-cell')} style={{ textAlign: 'center' }}>
                  <Number
                    value={primaryLineItem?.item?.specifiedProperties?.slump || mixInfo?.targetSlump}
                    ifFalsy=""
                  />
                </td>
                <td className={cn('mix-info-cell')} style={{ textAlign: 'center' }}>
                  <Number value={mixInfo?.minSlump ?? ''} ifFalsy="" />
                </td>
                <td className={cn('mix-info-cell')} style={{ textAlign: 'center' }}>
                  <Number value={mixInfo?.maxSlump ?? ''} ifFalsy="" />
                </td>
              </tr>
            </tbody>

            <thead>
              <tr>
                <th style={{ textAlign: 'center' }}>
                  <Translate stringId="maxBatchSlump" defaultMessage="Max Batch Slump" />
                </th>
                <th style={{ textAlign: 'center' }}>
                  <Translate stringId="slumpLossRate" defaultMessage="Slump Loss Rate" />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              <tr className="mix-info-row">
                <td className={cn('mix-info-cell')} style={{ textAlign: 'center' }}>
                  {mixInfo?.maxBatchSlump ?? ''}
                </td>
                <td className={cn('mix-info-cell')} style={{ textAlign: 'center' }}>
                  {mixInfo?.slumpLoss ?? ''}
                </td>
                <td className={cn('mix-info-cell')} style={{ textAlign: 'center' }}>
                  {''}
                </td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th style={{ textAlign: 'center' }}>
                  <Translate stringId="slumpType" defaultMessage="Slump Type" />
                </th>
                <th style={{ textAlign: 'center' }}>
                  <Translate stringId="overMixingTime" defaultMessage="Over Mixing Time" />
                </th>
                <th style={{ textAlign: 'center' }} />
              </tr>
            </thead>
            <tbody>
              <tr className="mix-info-row">
                <td className={cn('mix-info-cell')} style={{ textAlign: 'center' }}>
                  <Translate stringId={`slumpType_${mixInfo?.slumpType}`} defaultMessage="" />
                </td>
                <td className={cn('mix-info-cell')} style={{ textAlign: 'center' }}>
                  {mixInfo?.overMixingTime ?? ''}
                </td>
                <td className={cn('mix-info-cell')} style={{ textAlign: 'center' }}>
                  {''}
                </td>
              </tr>
            </tbody>

            <thead>
              <tr>
                <th style={{ textAlign: 'center' }}>
                  <Translate stringId="preBatchedWater" defaultMessage="PreBatched Water" />
                </th>
                <th style={{ textAlign: 'center' }}>
                  <Translate stringId="minTemp" defaultMessage="Min Temp" />
                </th>
                <th style={{ textAlign: 'center' }}>
                  <Translate stringId="maxTemp" defaultMessage="Max Temp" />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="mix-info-row">
                <td className={cn('mix-info-cell')} style={{ textAlign: 'center' }}>
                  <Uom uom={{ uomCode: mixInfo?.preBatchedWaterUomIsoCode, value: mixInfo?.preBatchedWater }} />
                </td>
                <td className={cn('mix-info-cell')} style={{ textAlign: 'center' }}>
                  <Uom uom={{ uomCode: mixInfo?.tempUomIsoCode, value: mixInfo?.minTemp }} />
                </td>
                <td className={cn('mix-info-cell')} style={{ textAlign: 'center' }}>
                  <Uom uom={{ uomCode: mixInfo?.tempUomIsoCode, value: mixInfo?.maxTemp }} />
                </td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th style={{ textAlign: 'center' }}>
                  <Translate stringId="targetAirContent" defaultMessage="Target Air Content" />
                </th>
                <th style={{ textAlign: 'center' }}>
                  <Translate stringId="minAirContent" defaultMessage="Min Air Content" />
                </th>
                <th style={{ textAlign: 'center' }}>
                  <Translate stringId="maxAirContent" defaultMessage="Max Air Content" />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="mix-info-row">
                <td className={cn('mix-info-cell')} style={{ textAlign: 'center' }}>
                  <Number value={mixInfo?.targetAirContent} ifFalsy="" />
                </td>
                <td className={cn('mix-info-cell')} style={{ textAlign: 'center' }}>
                  <Number value={mixInfo?.minAirContent} ifFalsy="" />
                </td>
                <td className={cn('mix-info-cell')} style={{ textAlign: 'center' }}>
                  <Number value={mixInfo?.maxAirContent} ifFalsy="" />
                </td>
              </tr>
            </tbody>
          </Table>
        </>
      )}
    </Styled>
  );
};

export default MixInfo;
