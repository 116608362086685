import { Input } from 'antd';
import cn from 'classnames';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useForm } from '../../../../../../../hooks/useForm';
import { api } from '../../../../../api';
import FlexColumn from '../../../../../components/FlexColumn';
import { Translate } from '../../../../../components/localization/Translate';
import { useTranslateMessage } from '../../../../../components/localization/useTranslateMessage';
import SelectField from '../../../../../components/SelectField';
import style from './style';
import { camelCase, find, kebabCase, toUpper } from 'lodash';
import Button from '../../../../../../../components/button/Button';
import { VOIDED_CANCEL_STATUSES } from '../util';

const { TextArea } = Input;

const Styled = styled.div`
  ${style}
`;

const initialState = {
  reasonCode: '',
  note: '',
  remainingQuantity: 0,
};

const CancelVoid = ({ ticket, onCancelVoid, setupItems, vehicle }) => {
  const { entityRef } = useParams();
  const { form, handleChange, setForm } = useForm(initialState);
  const [cancelVoidMode, setCancelVoidMode] = React.useState(false);
  const [updateTicketStatusBusy, setUpdateTicketStatusBusy] = React.useState(false);
  const translateMessage = useTranslateMessage();

  const handleCancelOrVoid = React.useCallback(() => {
    setCancelVoidMode(true);
  }, []);

  const mode = React.useMemo(() => {
    if (ticket?.originatorSystemType !== 'CONNEX') return null;
    if (VOIDED_CANCEL_STATUSES.includes(ticket?.supplierStatus?.statusCode)) return null;

    if (ticket?.supplierStatus?.statusCode === 'CREATED') {
      return {
        stringId: 'voidTicket',
        defaultMessage: 'Void Ticket',
        reasons: setupItems.filter(r => r.reasonType === 'TICKET'),
        statusCode: 'VOIDED',
      };
    }
    return {
      stringId: 'cancelTicket',
      defaultMessage: 'Cancel Ticket',
      reasons: setupItems,
      statusCode: 'CANCELLED',
    };
  }, [setupItems, ticket?.supplierStatus, ticket?.originatorSystemType]);

  const performStatusUpdate = React.useCallback(() => {
    setUpdateTicketStatusBusy(true);

    const supplierStatus = {
      statusCode: mode?.statusCode,
      reasonCode: form?.reasonCode,
      reason: form?.note,
      remainingQuantity: form?.remainingQuantity,
    };

    const orderRef = ticket?.dispatchOrder?.dispatchOrderRef;

    api
      .updateSupplierStatus(entityRef, orderRef, ticket?.crn, supplierStatus)
      .then(() => {
        onCancelVoid();
        setForm(initialState);
      })
      .finally(() => {
        setUpdateTicketStatusBusy(false);
      });
  }, [
    mode?.statusCode,
    form?.reasonCode,
    form?.note,
    form?.remainingQuantity,
    ticket?.dispatchOrder?.dispatchOrderRef,
    ticket?.crn,
    entityRef,
    onCancelVoid,
    setForm,
  ]);

  const handleDoNotCancelVoid = React.useCallback(() => {
    setCancelVoidMode(false);
  }, []);

  const handleNoteChange = React.useCallback(
    e => {
      handleChange('note', e.target.value);
    },
    [handleChange]
  );

  const handleRemainingQuantityChange = React.useCallback(
    e => {
      const remainingQuantity = parseFloat(e.target.value);
      handleChange('remainingQuantity', remainingQuantity);
    },
    [handleChange]
  );

  const hasLoaded = useMemo(() => {
    return find(ticket?.ticketEvents, ticketEvent => ticketEvent.statusCode === 'LOADING_COMPLETE') || null;
  }, [ticket]);

  const showRemainingQuantity = useMemo(() => {
    return hasLoaded && vehicle?.ticket?.ticketRef === ticket?.crn;
  }, [hasLoaded, vehicle, ticket]);

  return !mode ? null : (
    <Styled className={cn('cancel-void')}>
      <div className={cn('button-section', { visible: !cancelVoidMode })}>
        <button type="button" onClick={handleCancelOrVoid} disabled={cancelVoidMode}>
          <Translate stringId={mode.stringId} defaultMessage={mode.defaultMessage} />
        </button>
      </div>
      <div className={cn('details-section-wrapper', { visible: cancelVoidMode })}>
        <FlexColumn className="details-section">
          <div className="reason">
            <SelectField
              required
              onChange={handleChange}
              list={mode.reasons}
              listBusy={false}
              value={form?.reasonCode}
              fieldName="reasonCode"
              fieldLabel={translateMessage({ stringId: 'reason', defaultMessage: 'Reason' })}
              valuePath="id"
              displayPath="name"
              disabled={updateTicketStatusBusy}
            />
          </div>
          <FlexColumn className="note">
            <div className="label">
              <Translate stringId="note" defaultMessage="Note" />
            </div>
            <TextArea disabled={updateTicketStatusBusy} onChange={handleNoteChange} data-testid="input-note" />
          </FlexColumn>
          {showRemainingQuantity && (
            <FlexColumn className="remaining-quantity">
              <div className="label">Remaining Quantity</div>
              <Input type={'number'} disabled={updateTicketStatusBusy} onChange={handleRemainingQuantityChange} />
            </FlexColumn>
          )}
          <div className="void-cancel-actions">
            <Button
              data-testid={`button-do-not-${kebabCase(mode.defaultMessage?.toLowerCase?.())}`}
              type="primary"
              onClick={handleDoNotCancelVoid}
              disabled={updateTicketStatusBusy}
            >
              <Translate
                stringId={`doNot${camelCase(mode.defaultMessage).replace(/^(.)/, toUpper)}`}
                defaultMessage={`Do Not ${mode.defaultMessage}`}
              />
            </Button>
            <Button
              data-testid={`button-${kebabCase(mode.defaultMessage?.toLowerCase?.())}`}
              type="danger"
              onClick={performStatusUpdate}
              loading={updateTicketStatusBusy}
              disabled={!form?.reasonCode}
            >
              <Translate stringId={mode.stringId} defaultMessage={mode.defaultMessage} />
            </Button>
          </div>
        </FlexColumn>
      </div>
    </Styled>
  );
};

export default CancelVoid;
