import React, {useMemo} from 'react';
import styled from 'styled-components';
import style from './style';
import cn from 'classnames';
import VehicleList from '../vehicle-list';
import {useDrop} from "react-dnd";
import API from '../../../api';
import {useParams} from "react-router";
import {useDashboardContext} from "../../context/useDashboardContext";

const vehicleStatusOrder = [
  'PRINTED',
  'LOADING_STARTED',
  'LOADING_COMPLETE',
  'TO_JOB',
  'ARRIVE_JOB',
  'UNLOADING',
  'END_UNLOADING',
  'WASHING',
  'LEAVE_JOB',
];

const vehicleTrackedStatusMapping = {
  CREATED: 'PRINTED',
  DEPART_YARD: 'TO_JOB',
  END_WASHING: 'WASHING',
}

const StatusVehicleList = ({className, sortByTime, vehicles, statusCode}) => {
  const {refreshDashboard} = useDashboardContext();
  const {entityRef} = useParams();
  const [collectedProps, drop] = useDrop((...args) => {
    return {
      accept: 'vehicle',
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
      drop: (item) => {
        API.statusVehicle(entityRef, item.crn, statusCode).then(refreshDashboard);
      }
    }
  });
  return <VehicleList  drop={drop} sortByTime vehicles={vehicles} />
}

const VehicleOrderStatus = ({className, vehicles, statusCode}) => {
  const vehiclesByStatus = useMemo(() => {
    let result = [];
    for(const status of vehicleStatusOrder) {
      let vehicleArray = [];
      for(const vehicle of vehicles) {
        if(vehicleTrackedStatusMapping[vehicle.latestEvent.statusCode]) {
          vehicle.latestEvent.statusCode = vehicleTrackedStatusMapping[vehicle.latestEvent.statusCode];
        }
        if(vehicle.latestEvent.statusCode === status) {
          vehicleArray.push(vehicle);
        }
      }
      result.push(vehicleArray);
    }
    return result;
  }, [vehicles]);

  return <div className={cn(className)} data-testid={'vehicle-list'}>
    {vehiclesByStatus.map((vehicleList, key) => {
      return <StatusVehicleList sortByTime vehicles={vehicleList} key={key} statusCode={vehicleStatusOrder[key]} />
    })}
  </div>
}

export default styled(VehicleOrderStatus)`
  ${style}
`;