import React from "react";
import {FILTER_STRATEGY} from "../../../components/connex-data-grid/util";

export const prototype = {
  id: '',
  name: '',
  status: 'ACTIVE',
};

export const columns = [

  {
    key: 'name',
    name: 'Name',
    required: true,
    value: item => <span>{item.name}</span>,
  },
  {
    key: 'id',
    name: 'ID',
    required: true,
    hidden: true,
  },
  {
    key: 'status',
    name: 'Status',
    filterStrategy: FILTER_STRATEGY.EQUALS,
    filterComponent: ({ innerRef, ...props }) => {
      return (
        <select ref={innerRef} {...props}>
          <option value="" />
          <option value="ACTIVE">Active</option>
          <option value="INACTIVE">Inactive</option>
        </select>
      );
    },
  },
];
