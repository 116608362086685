import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';

const Styled = styled.div`
  display: flex;
  ${({ $gap }) => `grid-gap: ${$gap || 0}px;`}
  ${({ $spaceBetween }) => {
    if ($spaceBetween) return `justify-content: space-between;`;
  }}
`;
const Row = ({ gap, children, spaceBetween }) => {
  return (
    <Styled className={cn('row')} $gap={gap} $spaceBetween={spaceBetween}>
      {children}
    </Styled>
  );
};

export default Row;
