import { isUndefined } from 'lodash';
import React, { useMemo } from 'react';
import { useViewport } from '../../hooks/useViewport';

export const useOrdersDrawer = () => {
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const toggleDrawer = React.useCallback(isOpen => {
    if (isUndefined(isOpen)) {
      setIsDrawerOpen(s => !s);
    } else {
      setIsDrawerOpen(isOpen);
    }
  }, []);

  const viewport = useViewport();

  const drawerWidth = useMemo(() => {
    return Math.min(600, viewport.width);
  }, [viewport]);

  const [selectedOrders, setSelectedOrders] = React.useState(null);

  const showOrdersDrawer = React.useCallback(
    (date, orders) => {
      setSelectedOrders(orders);
      toggleDrawer();
    },
    [toggleDrawer]
  );

  const drawerProps = {
    width: drawerWidth,
    title: 'Orders',
    placement: 'right',
    closable: true,
    onClose: () => {
      toggleDrawer();
      setSelectedOrders(null);
    },
    visible: isDrawerOpen,
    destroyOnClose: true,
  };

  return { showOrdersDrawer, selectedOrders, drawerProps };
};
