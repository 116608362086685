import React from 'react';
import { ShortDateTime } from '../../../../order/components/localization/ShortDateTime';
import style from './style';
import styled from 'styled-components';
import { Steps } from 'antd';

const { Step } = Steps;

const Styled = styled.div`
  ${style}
`;

const BillableProgress = ({ progress, direction = 'horizontal' }) => {
  return (
    <Styled>
      <Steps size="small" direction={direction}>
        {progress?.map(step => (
          <Step
            key={step.title}
            status={step.status}
            title={step.translatedTitle}
            description={<ShortDateTime date={step.eventDateTime} showYear={false} />}
            icon={<i className={step.icon} />}
          />
        ))}
      </Steps>
    </Styled>
  );
};

export default BillableProgress;
