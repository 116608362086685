import { useEffect, useMemo, useState } from 'react';
import { useForm } from '../../../hooks/useForm';
import { useStartOrderRequest } from './useStartOrderRequest';
import { useSuppliers } from './useSuppliers';
import { filter, uniqBy } from 'lodash';

const initialState = () => ({
  supplier: {},
  job: {},
  product: {},
  quantity: '',
  startDateTime: null,
});

export const useCreateOrderRequest = () => {
  const { suppliers, getSuppliers, suppliersBusy } = useSuppliers();
  const { startOrder, response, startOrderBusy } = useStartOrderRequest();
  const { form, handleChange, setForm } = useForm(initialState());
  const [pourDescriptions, setPourDescriptions] = useState(null);
  const [disciplines, setDisciplines] = useState(null);

  useEffect(() => {
    getSuppliers();
  }, [getSuppliers]);

  useEffect(() => {
    if (form?.supplier?.crn) {
      startOrder(form?.supplier?.crn);
    }
  }, [form.supplier, startOrder]);

  useEffect(() => {
    if (suppliers?.length === 1) {
      handleChange('supplier', suppliers?.[0]);
    }
  }, [handleChange, suppliers]);

  useEffect(() => {
    if (response?.jobs?.length === 1) {
      handleChange('job', response?.jobs?.[0]);
    }
  }, [handleChange, response.jobs]);

  useEffect(() => {
    if (response?.disciplines?.length === 1) {
      handleChange('discipline', response?.disciplines?.[0]);
    }
  }, [handleChange, response.disciplines]);

  useEffect(() => {
    if (response?.pourDescriptions?.length === 1) {
      handleChange('pourDescription', response?.pourDescriptions?.[0]);
    }
  }, [handleChange, response.pourDescriptions]);

  // When supplier changes, clear form.
  useEffect(() => {
    setForm(s => {
      // keep the supplier
      const { supplier } = s;
      return { ...initialState(), supplier };
    });
  }, [form.supplier, setForm]);

  useEffect(() => {
    if (response?.settings?.useJobFiltering === true) {
      handleChange('discipline', '');
      handleChange('pourDescription', '');

      setDisciplines(
        uniqBy(
          filter(response?.pourDescriptions, {
            project: { projectRef: form?.job?.jobRef },
          })
            .map(i => {
              return {
                crn: i?.discipline?.disciplineRef,
                id: i?.discipline?.id,
                description: i?.discipline?.name,
              };
            })
            .sort((a, b) => {
              return a.id === b.id ? 0 : (a.id < b.id ? -1 : 1);
            }),
          'crn'
        )
      );
    } else {
      setDisciplines(response?.disciplines);
    }
  }, [form.job, response]);

  useEffect(() => {
    if (response?.settings?.filterDescriptions === true) {
      handleChange('pourDescription', '');

      setPourDescriptions(
        filter(response?.pourDescriptions, {
          project: { projectRef: form?.job?.jobRef },
          discipline: { disciplineRef: form?.discipline?.disciplineRef },
        })
      );
    } else {
      setPourDescriptions(response?.pourDescriptions);
    }
  }, [form.job, form.discipline, response]);

  const isValid = useMemo(() => {
    let valid = form?.supplier?.crn && form?.product?.productRef && form?.startDateTime && form?.quantity;

    if (valid && form?.job?.jobRef && response?.settings?.useJobFiltering === true) {
      valid =
        form?.discipline?.disciplineRef &&
        (response?.settings?.filterDescriptions !== true || form?.pourDescription?.pourDescriptionRef);
    }

    return valid;
  }, [form]);

  return {
    suppliers: { suppliers, getSuppliers, suppliersBusy },
    form: { form, handleChange, setForm },
    start: { startOrder, response, startOrderBusy, pourDescriptions, disciplines },
    isValid,
  };
};
