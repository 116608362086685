import { debounce } from 'lodash';
import React from 'react';
import { cacheItem, getCachedItem } from '../../util/cache';
import { useGridContext } from './context/context';

export const useColumnResize = () => {
  const { gridName, visibleColumns } = useGridContext();
  const localStorageKey = `${gridName?.toLowerCase?.()?.replace(/ /g, '-')}-column-widths`;
  const [userDefinedWidths, setUserDefinedWidths] = React.useState(
    JSON.parse(getCachedItem(localStorageKey)) || {}
  );

  const debouncedHandler = React.useMemo(
    () =>
      debounce(
        (idx, width) => {
          setUserDefinedWidths(s => {
            const newState = { ...s, [visibleColumns[idx].key]: width };
            cacheItem(localStorageKey, JSON.stringify(newState));
            return newState;
          });
        },
        200,
        { trailing: true }
      ),
    [localStorageKey, visibleColumns]
  );

  const handleColumnResize = React.useCallback(
    (...args) => {
      debouncedHandler(...args);
    },
    [debouncedHandler]
  );

  return { userDefinedWidths, handleColumnResize };
};
