import cn from 'classnames';
import { find, pick } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import Select from '../../../components/form/Select';
import Margin from '../components/Margin';
import { useScaleTicketingContext } from '../Context';
const { Option } = Select;
const Styled = styled.div`
  flex: 1;
`;

const fakeScales = [
  { id: '01', name: 'East Scale', crn: '25716087-6e67-41fc-89d3-c41235996b0b' },
  { id: '02', name: 'West Scale', crn: 'f1f71651-77d4-4ed9-a2c8-27fb02f0c95c' },
];

const ScaleList = props => {
  const { form, handleChange, scales = fakeScales } = useScaleTicketingContext();

  const handleScaleChange = React.useCallback(
    ref => {
      const targetScale = find(scales, { crn: ref });
      if (targetScale) {
        handleChange('scale', s => ({
          ...pick(targetScale, ['id', 'name']),
          scaleRef: targetScale.crn,
        }));
      } else {
        handleChange('scale', null);
      }
    },
    [handleChange, scales]
  );

  return (
    <Styled className={cn('scale-list')}>
      <Margin top={10}>
        <Select
          value={form?.scale?.scaleRef || ''}
          showSearch
          filterOption={(input, option) => option?.children?.toLowerCase?.().indexOf(input.toLowerCase?.()) >= 0}
          disabled={false}
          onChange={handleScaleChange}
        >
          <Option key="" value="">
            Select a scale...
          </Option>
          {scales?.map(scale => (
            <Option key={scale?.crn} value={scale.crn}>
              {`${scale.id} / ${scale.name}`}
            </Option>
          ))}
        </Select>
      </Margin>
    </Styled>
  );
};

export default ScaleList;
