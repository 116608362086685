import React, { useCallback, useEffect, useState } from 'react';
import InputCurrency from '../../../../components/input-currency';
import InputDate from '../../../../components/input-date';
const moment = require('moment');

const getInitValue = item => {
  if (item.outstandingBalance) {
    return {
      ...item.outstandingBalance,
      fullPaymentDate: item.outstandingBalance.fullPaymentDate ? moment(item.outstandingBalance.fullPaymentDate) : null,
    };
  }
  return { max: 0, current: 0, fullPaymentDate: null };
};

const InputOutstandingBalance = ({ item, onChange }) => {
  const [balance, setBalance] = useState(null);

  useEffect(() => {
    if (item && !balance) {
      const outstandingBalance = getInitValue(item);
      setBalance({ ...outstandingBalance, isTouched: false });
    }
  }, [item, balance]);

  const handleChange = useCallback(
    (id, value) => {
      const outstandingBalance = {
        current: balance.current,
        max: balance.max,
        fullPaymentDate: balance.fullPaymentDate,
        isTouched: true,
      };

      switch (id) {
        case 'max':
          outstandingBalance.max = value;
          break;
        case 'current':
          outstandingBalance.current = value;
          break;
        case 'fullPaymentDate':
          outstandingBalance.fullPaymentDate = value;
          break;
        default:
      }

      setBalance(outstandingBalance);
      onChange('outstandingBalance', {
        ...outstandingBalance,
        fullPaymentDate: outstandingBalance.fullPaymentDate?.toISOString() ?? null,
      });
    },
    [balance, onChange]
  );

  return (
    <table style={{ width: '100%' }}>
      <tbody>
        {balance && (
          <tr>
            <td>
              <InputCurrency
                id="current"
                value={balance.current}
                label="Outstanding Balance"
                onChange={handleChange}
                min={0}
              />
            </td>
            <td>
              <InputCurrency id="max" value={balance.max} label="Maximum Allowed" onChange={handleChange} min={0} />
            </td>
            <td>
              <InputDate
                id="fullPaymentDate"
                value={balance.fullPaymentDate}
                label="Last Full Payment"
                onChange={handleChange}
              />
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default InputOutstandingBalance;
