import { isNaN } from 'lodash';
import { DateTime } from 'luxon';
import { Time } from '../../../localization/Time';

export default ({ timeZone, deliverySchedule, loadSize, diffs, getTime }) => {
  const originalStartDateTime = DateTime.fromISO(deliverySchedule?.startDateTime);
  const originalStartDateTimeString = getTime(originalStartDateTime.setZone(timeZone));

  const newStartDateTime = originalStartDateTime.plus({ minutes: diffs?.diffLeft || 0 });
  const newStartDateTimeString = getTime(newStartDateTime.setZone(timeZone));

  const originalSpacing = deliverySchedule?.deliverySpacing?.value || 0;

  const numberOfLoads = deliverySchedule?.schedule?.length || 0;
  const newSpacing = Math.round(numberOfLoads < 2 ? 0 : diffs.diffDistance / (numberOfLoads - 1) + originalSpacing);
  const originalQuantityRate = Math.round((60 / originalSpacing) * loadSize);

  const newQuantityRate = Math.round((60 / newSpacing) * loadSize);

  return {
    originalStartDateTime,
    originalStartDateTimeString,
    newStartDateTime,
    newStartDateTimeString,
    originalSpacing,
    newSpacing: isNaN(newSpacing) ? originalSpacing : Math.round(newSpacing),
    originalQuantityRate: originalQuantityRate,
    newQuantityRate: isNaN(newQuantityRate) ? originalQuantityRate : Math.round(newQuantityRate),
    isStartDateTimeChanged: originalStartDateTimeString !== newStartDateTimeString,
    isSpacingChanged: originalSpacing !== (newSpacing || originalSpacing),
  };
};
