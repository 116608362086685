import React, { useCallback, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { api } from '../api';
import { getToday } from './components/date-filter-bar/isTomorrow';
import usePersona from './usePersona';
import { nonce } from '../../../util';

const listAllOrders = async (entityRef, queryParams) => {
    let {items, pageToken} = await api.listOrders(entityRef, queryParams);
    while (pageToken) {
        queryParams.pageToken = pageToken;
        const result = await api.listPageOrders(entityRef, queryParams);
        items = items.concat(result.items);
        pageToken = result.pageToken;
    }
    
    return items;
}

export const useOrders = (_callback, _deps) => {
  const { entityRef } = useParams();
  const [orders, setOrders] = useState([]);
  const [orderStatuses, setOrderStatuses] = useState([]);
  const [ordersBusy, setOrdersBusy] = useState(false);
  const [ordersBackgroundBusy, setOrdersBackgroundBusy] = useState(false);

  const [quickDate, setQuickDate] = useState(getToday());

  const orderRequestId = useRef(null);

  const handleQuickDateChange = useCallback(
    newQuickDate => {
      if ((quickDate && !newQuickDate) || (!quickDate && newQuickDate)) {
        setOrders([]);
      }
      setQuickDate(newQuickDate);
    },
    [quickDate]
  );

  const persona = usePersona();

  const getOrders = useCallback(
    (quickDate, background) => {
      if (!quickDate) return new Promise(resolve => resolve());

      if (background) {
        setOrdersBackgroundBusy(true);
      } else {
        setOrders([]);
        setOrdersBusy(true);
      }

      const queryParams = {
        ...quickDate,
        view: persona,
      };
      const requestId = nonce();
      orderRequestId.current = requestId;
      return listAllOrders(entityRef, queryParams).then(resp => {
          if (orderRequestId.current === requestId) {
            setOrders(resp);
          }
          return resp;
        })
        .finally(() => {
          if (orderRequestId.current === requestId) {
            setOrdersBusy(false);
            setOrdersBackgroundBusy(false);
          }
        });
    },
    [entityRef, persona]
  );

  React.useEffect(() => {
    if (quickDate === undefined) {
      setOrders([]);
    }
  }, [quickDate]);

  const getStatuses = useCallback(() => {
    return api.listStatuses(entityRef).then(resp => {
      setOrderStatuses(resp);
      return resp;
    });
  }, [entityRef]);

  const getAdvancedOrderSearch = useCallback(
    formValues => {
      return api.searchOrders(entityRef, formValues).then(result => {
        const { items } = result;
        setOrders(items);
      });
    },
    [entityRef]
  );

  return {
    getOrders,
    getAdvancedOrderSearch,
    getStatuses,
    orders,
    ordersBusy,
    ordersBackgroundBusy,
    orderStatuses,
    quickDate,
    setQuickDate,
    handleQuickDateChange,
    persona,
  };
};
