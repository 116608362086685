import style from '../style';
import React from 'react';
import styled from 'styled-components';
import InputText from '../../components/input-text';
import InputSelect from '../../components/input-select';
import { status } from './options';
import SelectUOM from '../../components/select-uom';
import SelectMaterialType from '../../components/select-material-type';
import InputNumber from '../../components/input-number';
import getUomAbbreviationOrDescription from '../../../../util/uom';
import { uomConversionFactors } from '../../../../util/uom/uom-conversions';

const View = styled.div`
  ${style}
`;

const ComponentGeneral = ({ item, onChange }) => {
  const changed = (id, value) => {
    onChange(id, value);
  };

  function onUOMBatchChanged(id, value) {
    item.weight = uomConversionFactors?.[value]?.[item?.uomCode];
    changed(id, value);
  }

  function onUOMInvChanged(id, value) {
    item.weight = uomConversionFactors?.[item?.batchUomCode]?.[value];
    changed(id, value);
  }

  return (
    <View>
      <InputText id="id" label="ID" value={item?.id} onChange={onChange} />
      <InputText id="name" label="Name" value={item?.name} onChange={onChange} />
      {/* {item && <SelectSetupItem
        label='Component Type'
        typeId='component-type'
        item={item}
        onChange={onChange} />} */}
      <SelectMaterialType
        id="materialType"
        title="Material Type"
        mtlType={item?.materialType}
        onChange={onChange}
      />
      <SelectUOM
        id="uomCode"
        title="Inventory Unit of Measure (UOM)"
        uomCode={item?.uomCode}
        onChange={onUOMInvChanged}
      />
      {item?.uomCode !== item?.batchUomCode && (
        <InputNumber
          id="weight"
          label={`Weight (${getUomAbbreviationOrDescription(item?.uomCode)} / ${getUomAbbreviationOrDescription(
            item?.batchUomCode
          )})`}
          value={item?.weight || 0}
          onChange={onChange}
        />
      )}
      <SelectUOM
        id="batchUomCode"
        title="Batch Unit of Measure (UOM)"
        uomCode={item?.batchUomCode}
        onChange={onUOMBatchChanged}
      />
      <InputSelect id="status" label="Status" value={item?.status} onChange={onChange} options={status} required />
    </View>
  );
};

export default ComponentGeneral;
