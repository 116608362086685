import React from 'react';
import styled from 'styled-components';
import Button from '../../../../components/button/Button';
import NewVersionButton from '../../order-list/components/NewVersionButton';
import Header from '../Header';
import { Translate } from '../localization/Translate';
import { useTranslateMessage } from '../localization/useTranslateMessage';
import { useOrderListContext } from '../OrderListContext';
import { useOrderContext } from '../useOrderContext';

const OrderFormHeading = ({ titleStringId, defaultTitle, actionButtons = [], className }) => {
  const translateMessage = useTranslateMessage();

  const {
    form: { form },
  } = useOrderContext();

  const title = React.useMemo(() => {
    return translateMessage({ stringId: titleStringId, defaultMessage: defaultTitle });
  }, [translateMessage, titleStringId]);

  const { navigateToOrderList } = useOrderListContext();

  const preparedTitle = React.useMemo(() => {
    return `${title}${form?.orderId ? ` (${form?.orderId})` : ''}`;
  }, [form?.orderId, title]);

  const handleBackClick = React.useCallback(() => {
    navigateToOrderList();
  }, [navigateToOrderList]);

  return (
    <Header
      className={className}
      title={preparedTitle}
      leftSide={
        <>
          <div className="back-action">
            <Button
              type="primary"
              size="small"
              onClick={handleBackClick}
              metricId="core-order-detail-back-to-orders"
              icon={<i className="icon fa fa-chevron-left" />}
              data-testid="button-back-to-orders"
            >
              <Translate stringId="backToOrders" defaultMessage="Back to Orders" />
            </Button>
          </div>
          <NewVersionButton />
        </>
      }
      rightSide={<div className="additional-actions">{actionButtons}</div>}
    />
  );
};

export default styled(OrderFormHeading)`
  .back-action {
    button {
      width: 135px;
      overflow: hidden;
      & > *:nth-child(2) {
      }
      .icon {
        margin-right: 10px;
      }
    }
  }

  .additional-actions {
    display: flex;
    grid-gap: 5px;
    justify-content: flex-end;
    button {
      min-width: 135px;
      overflow: hidden;
    }
    .ant-dropdown-button {
      button {
        width: inherit;
        min-width: 30px;
        overflow: inherit;
      }
    }
  }

  .title {
    text-align: center;
    font-weight: bold;
    font-size: 1.2em;
    flex: 1;
  }
  @media (max-width: 780px) {
    .additional-actions,
    .back-action {
      button {
        width: 25px;
        & > *:nth-child(2) {
          width: 0;
        }
        .icon {
          //margin-right: 0;
        }
      }
    }
  }
`;
