import styled from 'styled-components';

export default styled.div`
  display: flex;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-area: bottom;
  button {
    min-width: 100px;
    flex:1;
  }
`;
