import cn from 'classnames';
import { isEmpty } from 'lodash';
import { DateTime } from 'luxon';
import React, { useMemo } from "react";
import styled from 'styled-components';
import { motion } from 'framer-motion';

const Styled = styled(motion.div)`
  position: relative;
  top: -55px;
  left: 0;
  bottom: 0;
  height: 100%;
  border-right: ${({ width }) => (width ? '1px solid white' : '0')};
  z-index: 10000;
  .caret-wrapper {
    position: relative;
    .caret {
      position: absolute;
      top: -13px;
      right: -4.5px;
      color: #ccc;
      font-size: 17px;
    }
  }
`;

// TODO: get time zone
const NowMarker = ({ graphData = {}, timeZone = 'America/Chicago', now }) => {
  const indexOfCurrentHourSlot = useMemo(() => {
    const nowSlotName = now.toFormat('yyyy-LL-dd_HH_mm_ha').toLowerCase();
    const keys = Object.keys(graphData?.timeSlots || {});
    for(let i = 0; i < keys.length; i++) {
      const timeSlot = keys[i];
      if(timeSlot > nowSlotName) {
        break;
      }

      if(nowSlotName === timeSlot) {
        return i;
      }
    }
    return -1;
  }, [graphData?.timeSlots, now]);

  const markerWidth = React.useMemo(() => {
    if (isEmpty(graphData?.timeSlots)) return null;

    const dateStrings = Object.keys(graphData?.timeSlots);

    const [firstDate, firstHour] = dateStrings[0].split('_');
    const [lastDate, lastHour] = dateStrings[dateStrings.length - 1].split('_');

    const firstDateTime = DateTime.fromISO(firstDate)
      .set({ hour: firstHour })
      .setZone(timeZone, { keepLocalTime: true });
    const lastDateTime = DateTime.fromISO(lastDate)
      .set({ hour: lastHour })
      .endOf('hour')
      .setZone(timeZone, { keepLocalTime: true });

    const nowInRange = now >= firstDateTime && now <= lastDateTime;

    if (!nowInRange) return null;

    const LEFT_PADDING = 10;
    const GAP_PX = 10;
    const BAR_WIDTH = 15;

    const numberOfGaps = indexOfCurrentHourSlot + 1;

    const targetBarStart = LEFT_PADDING + GAP_PX * numberOfGaps + indexOfCurrentHourSlot * BAR_WIDTH;

    const minuteOffset = ((now.minute % 30) / 30) * BAR_WIDTH;

    return `${targetBarStart + minuteOffset}px`;
  }, [graphData, now, timeZone, indexOfCurrentHourSlot]);

  return (
    <Styled
      initial={{ opacity: 0, width: 0 }}
      animate={{ opacity: 1, width: markerWidth }}
      transition={{ duration: 0.5 }}
      className={cn('now-marker')}
      width={markerWidth}
    >
      {!isEmpty(graphData?.timeSlots) && markerWidth && (
        <motion.div
          className="caret-wrapper"
          initial={{ opacity: 0, width: 0 }}
          animate={{ opacity: 1, width: markerWidth }}
          transition={{ duration: 0.5 }}
        >
          <i className="caret fas fa-caret-down" />
        </motion.div>
      )}
    </Styled>
  );
};

export default NowMarker;