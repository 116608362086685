export default {
  UOM_VALUE_ABBR__UNKNOWN: `{value, number} `,
  UOM_VALUE_ABBR__INH: '{value, number} IN',
  UOM_VALUE_ABBR__FOT: '{value, number} FT',
  UOM_VALUE_ABBR__YRD: '{value, number} YD',
  UOM_VALUE_ABBR__MMT: '{value, number} MM',
  UOM_VALUE_ABBR__CMT: '{value, number} CM',
  UOM_VALUE_ABBR__MTR: '{value, number} M',
  UOM_VALUE_ABBR__SMI: '{value, number} MI',
  UOM_VALUE_ABBR__KMT: '{value, number} KM',
  UOM_VALUE_ABBR__INK: '{value, number} IN',
  UOM_VALUE_ABBR__FTK: '{value, number} FT2',
  UOM_VALUE_ABBR__YDK: '{value, number} YD',
  UOM_VALUE_ABBR__MIK: '{value, number} MI',
  UOM_VALUE_ABBR__ACR: '{value, number} AC',
  UOM_VALUE_ABBR__CMK: '{value, number} CM2',
  UOM_VALUE_ABBR__MTK: '{value, number} M2',
  UOM_VALUE_ABBR__KMK: '{value, number} KM2',
  UOM_VALUE_ABBR__INQ: '{value, number} IN',
  UOM_VALUE_ABBR__FTQ: '{value, number} FT3',
  UOM_VALUE_ABBR__YDQ: '{value, number} CY',
  UOM_VALUE_ABBR__MMK: '{value, number} MM2',
  UOM_VALUE_ABBR__MMQ: '{value, number} MM3',
  UOM_VALUE_ABBR__CMQ: '{value, number} CM3',
  UOM_VALUE_ABBR__MTQ: '{value, number} M3',
  UOM_VALUE_ABBR__OZA: '{value, number} OZ',
  UOM_VALUE_ABBR__PT: '{value, number} PT',
  UOM_VALUE_ABBR__QT: '{value, number} QT',
  UOM_VALUE_ABBR__GLL: '{value, number} GA',
  UOM_VALUE_ABBR__LTR: '{value, number} L',
  UOM_VALUE_ABBR__MLT: '{value, number} mL',
  UOM_VALUE_ABBR__ONZ: '{value, number} OZ',
  UOM_VALUE_ABBR__LBR: '{value, number} LB',
  UOM_VALUE_ABBR__STN: '{value, number} TN',
  UOM_VALUE_ABBR__MTN: '{value, number} MTN',
  UOM_VALUE_ABBR__GTN: '{value, number} GTN',
  UOM_VALUE_ABBR__GRM: '{value, number} G',
  UOM_VALUE_ABBR__KGM: '{value, number} KG',
  UOM_VALUE_ABBR__TNE: '{value, number} T',
  UOM_VALUE_ABBR__PS: '{value, number} PSI',
  UOM_VALUE_ABBR__MPA: '{value, number} MPa',
  UOM_VALUE_ABBR__C56: '{value, number} C56',
  'UOM_VALUE_ABBR__/NL': '{value, number} MPa',
  'UOM_VALUE_ABBR__/EA': '{value, number} EA',
  'UOM_VALUE_ABBR__/H/ + h': '{value, number} Hour',
  'UOM_VALUE_ABBR__/YQ/ + YDQ': '{value, number} CY',
  'UOM_VALUE_ABBR__/MQ/ + MTQ': '{value, number} /M3',
  'UOM_VALUE_ABBR__/MN/ + MIN': '{value, number} MIN',
  'UOM_VALUE_ABBR__/MT': '{value, number} /M',
  UOM_VALUE_ABBR__DSG: '{value, number} DSG',
  UOM_VALUE_ABBR__P1: '{value, number} %',
  'UOM_VALUE_ABBR__/C': '{value, number} /C',
  UOM_VALUE_ABBR__CEL: '{value, number} C',
  UOM_VALUE_ABBR__FAH: '{value, number} F',
  UOM_VALUE_ABBR__BLL: '{value, number} BLL',
  UOM_VALUE_ABBR__CLT: '{value, number} cL',
  UOM_VALUE_ABBR__DLT: '{value, number} dL',
  UOM_VALUE_ABBR__GLI: '{value, number} GAL',
  UOM_VALUE_ABBR__2U: '{value, number} Mg',
  UOM_VALUE_ABBR__SA: '{value, number} Sack',
  UOM_VALUE_ABBR__NL: '{value, number} LOAD',
  UOM_VALUE_ABBR__EA: '{value, number} EACH',
  UOM_VALUE_ABBR__60: '{value, number} 60',
  UOM_VALUE_ABBR__BG: '{value, number} BG',
  UOM_VALUE_ABBR__SEC: '{value, number} SEC',
  UOM_VALUE_ABBR__MIN: '{value, number} MIN',
  UOM_VALUE_ABBR__HUR: '{value, number} HR',
  UOM_VALUE_ABBR__C62: '{value, number} EA',
  UOM_VALUE_ABBR__64: '{value, number} 64',

  UOM_ABBR__UNKNOWN: 'UNKNOWN',
  UOM_ABBR__INH: 'IN',
  UOM_ABBR__FOT: 'FT',
  UOM_ABBR__YRD: 'YD',
  UOM_ABBR__MMT: 'MM',
  UOM_ABBR__CMT: 'CM',
  UOM_ABBR__MTR: 'M',
  UOM_ABBR__SMI: 'MI',
  UOM_ABBR__KMT: 'KM',
  UOM_ABBR__INK: 'IN',
  UOM_ABBR__FTK: 'FT2',
  UOM_ABBR__YDK: 'YD',
  UOM_ABBR__MIK: 'MI',
  UOM_ABBR__ACR: 'AC',
  UOM_ABBR__CMK: 'CM2',
  UOM_ABBR__MTK: 'M2',
  UOM_ABBR__KMK: 'KM2',
  UOM_ABBR__INQ: 'IN',
  UOM_ABBR__FTQ: 'FT3',
  UOM_ABBR__YDQ: 'CY',
  UOM_ABBR__MMK: 'MM2',
  UOM_ABBR__MMQ: 'MM3',
  UOM_ABBR__CMQ: 'CM3',
  UOM_ABBR__MTQ: 'M3',
  UOM_ABBR__OZA: 'OZ',
  UOM_ABBR__PT: 'PT',
  UOM_ABBR__QT: 'QT',
  UOM_ABBR__GLL: 'GA',
  UOM_ABBR__LTR: 'L',
  UOM_ABBR__MLT: 'mL',
  UOM_ABBR__ONZ: 'OZ',
  UOM_ABBR__LBR: 'LB',
  UOM_ABBR__STN: 'TN',
  UOM_ABBR__MTN: 'MTN',
  UOM_ABBR__GTN: 'GTN',
  UOM_ABBR__GRM: 'G',
  UOM_ABBR__KGM: 'KG',
  UOM_ABBR__TNE: 'T',
  UOM_ABBR__PS: 'PSI',
  UOM_ABBR__MPA: 'MPa',
  UOM_ABBR__C56: 'C56',
  'UOM_ABBR__/NL': 'MPa',
  'UOM_ABBR__/EA': 'EA',
  'UOM_ABBR__/H/ + h': 'Hour',
  'UOM_ABBR__/YQ/ + YDQ': 'CY',
  'UOM_ABBR__/MQ/ + MTQ': '/M3',
  'UOM_ABBR__/MN/ + MIN': 'MIN',
  'UOM_ABBR__/MT': '/M',
  UOM_ABBR__DSG: 'DSG',
  UOM_ABBR__P1: '%',
  'UOM_ABBR__/C': '/C',
  UOM_ABBR__CEL: 'C',
  UOM_ABBR__FAH: 'F',
  UOM_ABBR__BLL: 'BLL',
  UOM_ABBR__CLT: 'cL',
  UOM_ABBR__DLT: 'dL',
  UOM_ABBR__GLI: 'GAL',
  UOM_ABBR__2U: 'Mg',
  UOM_ABBR__SA: 'Sack',
  UOM_ABBR__NL: 'LOAD',
  UOM_ABBR__EA: 'EACH',
  UOM_ABBR__60: '60',
  UOM_ABBR__BG: 'BG',
  UOM_ABBR__SEC: 'SEC',
  UOM_ABBR__MIN: 'MIN',
  UOM_ABBR__HUR: 'HR',
  UOM_ABBR__C62: 'EA',
  UOM_ABBR__64: '64',

  UOM_TEXT__INH: 'Inches',
  UOM_TEXT__FOT: 'Feet',
  UOM_TEXT__YRD: 'Yards',
  UOM_TEXT__MMT: 'Millimeters',
  UOM_TEXT__CMT: 'Centimeters',
  UOM_TEXT__MTR: 'Meters',
  UOM_TEXT__SMI: 'Miles',
  UOM_TEXT__KMT: 'Kilometers',
  UOM_TEXT__INK: 'Square Inches',
  UOM_TEXT__FTK: 'Square Feet',
  UOM_TEXT__YDK: 'Square Yards',
  UOM_TEXT__MIK: 'Square Miles',
  UOM_TEXT__ACR: 'Acres',
  UOM_TEXT__CMK: 'Square Centimeters',
  UOM_TEXT__MTK: 'Square Meters',
  UOM_TEXT__KMK: 'Square Kilometers',
  UOM_TEXT__INQ: 'Cubic Inches',
  UOM_TEXT__FTQ: 'Cubic Feet',
  UOM_TEXT__YDQ: 'Cubic Yards',
  UOM_TEXT__MMK: 'Square Millimeters',
  UOM_TEXT__MMQ: 'Cubic Millimeters',
  UOM_TEXT__CMQ: 'Cubic Centimeters',
  UOM_TEXT__MTQ: 'Cubic Meters',
  UOM_TEXT__OZA: 'Ounces',
  UOM_TEXT__PT: 'Pints',
  UOM_TEXT__QT: 'Quarts',
  UOM_TEXT__GLL: 'Gallons',
  UOM_TEXT__LTR: 'Liters',
  UOM_TEXT__MLT: 'Milliliters',
  UOM_TEXT__ONZ: 'Ounces',
  UOM_TEXT__LBR: 'Pounds',
  UOM_TEXT__STN: 'Tons',
  UOM_TEXT__MTN: 'Metric Tons',
  UOM_TEXT__GTN: 'Gigatons',
  UOM_TEXT__GRM: 'Grams',
  UOM_TEXT__KGM: 'Kilograms',
  UOM_TEXT__TNE: 'Cubic Tons',
  UOM_TEXT__PS: 'Pounds Per Square Inch',
  UOM_TEXT__MPA: 'Megapascals',
  UOM_TEXT__C56: 'Newtons Per Square',
  'UOM_TEXT__/NL': 'Per Load',
  'UOM_TEXT__/EA': 'Per Quantity',
  'UOM_TEXT__/H/ + h': 'Per Hour',
  'UOM_TEXT__/YQ/ + YDQ': 'Per Cubic Yard',
  'UOM_TEXT__/MQ/ + MTQ': 'Per Cubic Meter',
  'UOM_TEXT__/MN/ + MIN': 'Per Minute',
  'UOM_TEXT__/MT': 'Per Meter',
  UOM_TEXT__DSG: 'Dosage',
  UOM_TEXT__P1: 'Percent',
  'UOM_TEXT__/C': 'Per 100 Weight',
  UOM_TEXT__CEL: 'Degrees Celsius',
  UOM_TEXT__FAH: 'Degrees Fahrenheit',
  UOM_TEXT__BLL: 'Barrel',
  UOM_TEXT__CLT: 'Centiliter',
  UOM_TEXT__DLT: 'Deciliter',
  UOM_TEXT__GLI: 'Imperial Gallon',
  UOM_TEXT__2U: 'Megagram',
  UOM_TEXT__SA: 'Sack',
  UOM_TEXT__NL: 'Load',
  UOM_TEXT__EA: 'Each',
  UOM_TEXT__C62: 'Each',
  UOM_TEXT__60: 'Percent Weight',
  UOM_TEXT__BG: 'Bag',
  UOM_TEXT__SEC: 'Second',
  UOM_TEXT__MIN: 'Minute',
  UOM_TEXT__HUR: 'Hour',
  UOM_TEXT__64: 'Pounds Per Square Inch, Gauge',
};
