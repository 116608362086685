import style from '../style';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import InputText from '../../../components/input-text';
import InputSelect from '../../../components/input-select';
import {
  status,
  classType,
  classSubType,
  consistenceUomOptions,
  tempUomOptions,
} from './options';
import InputNumber from '../../../components/input-number';
import InputCheckbox from '../../../components/input-checkbox';

const View = styled.div`
  ${style}
`;

const ConcreteClassGeneral = ({ item, onChange }) => {
  const [classTypeValue, setClassTypeValue] = useState(item?.classType);
  const [showMinMax, setShowMinMax] = useState(false);
  const [showNominal, setShowNominal] = useState(false);
  const [classSubTypeOptions, setClassSubTypeOptions] = useState([]);
  const [uomSelectOptions, setUomSelectOptions] = useState([]);
  const [uomSelected, setUomSelected] = useState(item?.uomCode);
  const [valueLabel, setValueLabel] = useState('');

  const showHide = () => {
    switch (classTypeValue ?? item?.classType) {
      case 'air': {
        setShowNominal(true);
        setShowMinMax(true);
        break;
      }
      case 'consistence': {
        setShowNominal(true);
        setShowMinMax(true);
        break;
      }
      case 'slumpLossRate': {
        setShowNominal(true);
        setShowMinMax(false);
        break;
      }
      case 'temperature': {
        setShowNominal(false);
        setShowMinMax(true);
        break;
      }
    }
  };

  const subTypeOptions = () => {
    const values = classSubType?.[classTypeValue] ?? [];

    if (values?.length === 0 || !values.some(i => i.value === item?.classSubType)) {
      onChange('classSubType', '');
    }

    setClassSubTypeOptions(values);
  };

  const uomSelect = () => {
    switch (classTypeValue ?? item?.classType) {
      case 'consistence':
      case 'slumpLossRate': {
        setUomSelectOptions(consistenceUomOptions());
        break;
      }
      case 'temperature': {
        setUomSelectOptions(tempUomOptions());
        break;
      }
      default:
        setUomSelectOptions([]);
    }
  };

  const setValueLabels = () => {
    if (classTypeValue === 'air' || item?.classType === 'air') {
      setValueLabel('(%)');
    } else {
      const label = uomSelected ?? item?.uomCode;
      setValueLabel(label ? `(${label})` : '');
    }
  };

  useEffect(() => {
    if (item?.classType) {
      setClassTypeValue(item.classType);
    }
  }, [item?.classType]);

  useEffect(() => {
    showHide();
    subTypeOptions();
    uomSelect();
    setValueLabels();
  }, [classTypeValue]);

  useEffect(() => {
    setValueLabels();
  }, [uomSelected]);

  return (
    <View>
      <InputText id="id" label="ID" value={item?.id} onChange={onChange} />
      <InputText id="name" label="Name" value={item?.name} onChange={onChange} />
      <InputText id="description" label="Description" value={item?.description} onChange={onChange} />
      <InputSelect
        id="classType"
        label="Class Type"
        value={classTypeValue}
        onChange={(id, value) => {
          setClassTypeValue(value);
          onChange(id, value);
        }}
        options={classType}
        required
      />
      {classSubTypeOptions?.length > 0 && (
        <InputSelect
          id="classSubType"
          label="Class Sub Type"
          value={item?.classSubType}
          onChange={onChange}
          options={classSubTypeOptions ?? []}
          required
        />
      )}
      {uomSelectOptions?.length > 0 && (
        <InputSelect
          id="uomCode"
          label="UOM Code"
          value={uomSelected ?? item?.uomCode}
          onChange={(id, value) => {
            setUomSelected(value);
            onChange(id, value);
          }}
          options={uomSelectOptions ?? []}
          required
        />
      )}
      {showNominal && (
        <InputNumber
          id="nominalValue"
          label={`Nominal Value ${valueLabel}`}
          value={item?.nominalValue}
          onChange={onChange}
        />
      )}
      {showMinMax && (
        <InputNumber
          id="minimumValue"
          label={`Minimum Value ${valueLabel}`}
          value={item?.minimumValue}
          onChange={onChange}
        />
      )}
      {showMinMax && (
        <InputNumber
          id="maximumValue"
          label={`Maximum Value ${valueLabel}`}
          value={item?.maximumValue}
          onChange={onChange}
        />
      )}
      <InputSelect id="status" label="Status" value={item?.status} onChange={onChange} options={status} required />
      <InputCheckbox id="isDefault" label="Default Settings" value={item?.isDefault ?? false} onChange={onChange} />
    </View>
  );
};

export default ConcreteClassGeneral;
