import UOM from '../../../uom';
import InputNumberBlur from '../../../../views/setup/components/input-number-blur';
import styled from 'styled-components';
import style from './style';
import roundedToCents from '../../../../util/other/rounded-to-cents';

const Styled = styled.tr`
  ${style}
`;

const MixComponent = ({
  component,
  onChange,
  onDelete,
  dragRef,
  draggableProps,
  dragHandleProps,
  localIndex,
  disableButtons,
}) => {
  return (
    <Styled ref={dragRef} {...draggableProps} {...dragHandleProps}>
      <td className="batchSequence">{(component.batchSequence || localIndex) + 1}</td>
      <td>
        {component.id} / {component.name}
        (<UOM uomCode={component.batchUomCode} />){component.deletedComponent && ' (DELETED)'}
      </td>
      <td className="total">
        <InputNumberBlur
          id={component.crn}
          value={component.quantity?.value || 0}
          onChange={onChange}
          disabled={component.deletedComponent}
        />
      </td>
      <td className="total">
        {roundedToCents(component.weight?.value)} <UOM uomCode={component.uomCode} />
      </td>
      {!disableButtons && (
        <td
          className="delete"
          onClick={() => {
            onDelete(component.crn);
          }}
        >
          <i className="icon fa fa-trash" />
        </td>
      )}
    </Styled>
  );
};

export default MixComponent;
