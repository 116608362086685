import { css } from 'styled-components';

export default css`
  background-color: #177ddc;
  border: 1px solid #177ddc;
  border-radius: 2px;
  z-index: 9999;
  width: 320px;

  &.disabled {
    border: 1px solid #444;
    background-color: #313131;
    //.button-section {
    //  transition: all 2s;
    //  background-color: #313131;
    //}
  }

  .button-section {
    transition: height 0.2s;
    overflow: hidden;
    padding: 0;
    height: 32px;

    &:not(.visible) {
      height: 0;
    }

    button {
      transition: background-color 0.2s;
      background-color: #177ddc;
      height: 32px;
      border: 0;
      cursor: pointer;
      width: 100%;
      &:disabled {
        cursor: not-allowed;
        //width: 100%;
        background-color: #313131;
        color: #6f6f6f;
      }
    }
  }
  .details-section-wrapper {
    background-color: #177ddc;
    overflow: hidden;
    transition: all 0.2s;
    height: 270px;
    min-width: 318px;
    &:not(.visible) {
      height: 0;
    }
    .details-section {
      background-color: #1f1f1f;
      border-radius: 4px;
      padding: 10px;
      height: 100%;
      grid-gap: 20px;
      
      .options {
        .ant-checkbox-wrapper {
          margin: 0;
        }

        .indent-left {
          margin-left: 25px;
        }
      }
      
      .actions {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 10px;
        position: sticky;
        bottom: 0;
        padding-bottom: 5px;
      }
    }
  }
`;
