import { css } from 'styled-components';

export default css`
  //border: 1px solid red;
  margin: 25px;
  margin-bottom: 0;
  display: flex;
  .flex-wrapper {
    position: relative;
    width: 2880px;
    overflow: auto;
  }
`;
