import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { useOrderContext } from '../../useOrderContext';
import NoteList from './note-list/NoteList';

const Notes = ({ className }) => {
  const {
    form: { form, handleChange },
  } = useOrderContext();

  return (
    <div className={cn('notes', className)}>
      <NoteList notes={form?.notes} orderRef={form?.crn} onChange={handleChange} />
    </div>
  );
};

export default styled(Notes)``;
