import { startCase } from 'lodash';
import React from 'react';
import { useParams } from 'react-router-dom';
import OrderFormHeading from '../components/order-form/OrderFormHeading';
import SimpleOrderForm from '../components/order-form/SimpleOrderForm';
import OrderContextProvider from '../components/useOrderContext';
import { useCopyOrderBehavior } from './useCopyOrderBehavior';

const CopyOrder = () => {
  const { orderRef, orderType } = useParams();

  const copyOrderBehavior = useCopyOrderBehavior(orderRef, orderType?.toUpperCase?.());

  const headingProps = React.useMemo(() => {
    return { titleStringId: `copy${startCase(orderType)}Order`, defaultTitle: `Copy ${startCase(orderType)} Order` };
  }, [orderType]);

  return (
    <OrderContextProvider value={{ ...copyOrderBehavior, orderRef }}>
      <OrderFormHeading {...headingProps} />
      <SimpleOrderForm />
    </OrderContextProvider>
  );
};

export default CopyOrder;
