export default (orderRef, projectRef, origin, destination, updateLocation) => {
  const defaultOrigin = {
    id: 'origin',
    type: 'plant',
    icon: 'default',
    color: 'red',
    orderRef,
    lng: origin?.locationAddress?.coordinates?.longitude,
    lat: origin?.locationAddress?.coordinates?.latitude,
    info: [
      {
        name: 'Plant',
        desc: origin?.name,
      },
    ],
  };

  const defaultDestination = {
    draggable: true,
    allowRouting: true,
    projectRef,
    updateLocation: updateLocation || false,
    orderRef,
    icon: 'default',
    color: 'blue',
    id: 'destination',
    type: 'destination',
    lng: destination?.coordinates?.longitude,
    lat: destination?.coordinates?.latitude,
    radius: 50,
    info: [{ name: 'Destination', desc: '' }],
  };

  if (destination) {
    defaultDestination.info = [
      {
        name: 'Destination',
        desc:
          destination?.address1 ||
          []
            .concat(destination?.city, destination?.countrySubDivision, destination?.postalCode)
            .filter(x => !!x)
            .join(', '),
      },
    ];
  }

  return {
    origin: defaultOrigin,
    destination: defaultDestination,
    waypoints: [],
    orderRef,
  };
};
