import React, { useEffect, useMemo, useState } from 'react';
import { Number } from '../../localization/Number';
import { ShortDateTime } from '../../localization/ShortDateTime';
import { Translate } from '../../localization/Translate';
import { Uom } from '../../localization/Uom';
import { useOrderContext } from '../../useOrderContext';
import orderValues from './order-values';
import CreateTicket from '../../tickets/CreateTicket';
import { useScheduleContext } from './ScheduleContext';
import Button from '../../../../../components/button/Button';
import generateEnableTicketingFlag from '../../../../../util/other/generate-enable-ticketing-flag';

const ProductSummary = ({ order }) => {
  const { onTicketed, statusCodes, statusCodeBusy } = useScheduleContext();

  const {
    order: { readOnly },
    start,
  } = useOrderContext();

  const timeZone = start?.startedOrder?.supplierParty?.timeZone || 'America/Chicago';

  const [isTicketing, setIsTicketing] = useState(false);
  const lineItem = order?.lineItems?.find(item => item.isPrimary);

  const { status, productId, productName, vehicleTypeName, slump } = orderValues(order, lineItem, timeZone);

  useEffect(() => {
    setIsTicketing(false);
  }, []);

  const canTicket = useMemo(() => {
    const orderStatus = order?.supplierStatus?.name;

    if (!orderStatus || !statusCodes) return false;

    const statusCode = statusCodes.find(status => status.name === orderStatus);

    return (
      !readOnly && order?.deliverySchedule?.schedule?.length > 0 && generateEnableTicketingFlag(statusCode, orderStatus)
    );
  }, [readOnly, statusCodes, order?.deliverySchedule?.schedule?.length, order?.supplierStatus]);

  return (
    <div>
      <table>
        <thead>
          <tr>
            <th>
              <Translate stringId="status" defaultMessage="Status" />
            </th>
            <th>
              <Translate stringId="startTime" defaultMessage="Start Time" />
            </th>
            <th>
              <Translate stringId="quantity" defaultMessage="Quantity" />
            </th>
            <th>
              <Translate stringId="product" defaultMessage="Product" />
            </th>
            <th>
              <Translate stringId="slump" defaultMessage="Slump" />
            </th>
            <th>
              <Translate stringId="vehicleType" defaultMessage="Vehicle Type" />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <Translate stringId={status} defaultMessage={status} />{' '}
            </td>
            <td>
              <ShortDateTime date={order?.deliverySchedule?.startDateTime} timeZone={timeZone} />
            </td>
            <td>
              <Uom uom={lineItem.orderedQuantity} />
            </td>
            <td>
              {productId} / {productName}
            </td>
            <td>
              <Number value={slump} decimalPlaces={2} />
            </td>
            <td>{vehicleTypeName}</td>
          </tr>
        </tbody>
      </table>
      <Button
        style={{ marginBottom: '20px' }}
        disabled={isTicketing || !canTicket}
        onClick={() => setIsTicketing(true)}
        metricId="core-order-detail-create-ticket"
        data-testid="button-create-ticket"
        loading={statusCodeBusy}
      >
        <Translate stringId="createTicket" defaultMessage="Create Ticket" />
      </Button>
      <CreateTicket
        orderRef={order?.orderRef}
        open={isTicketing}
        onTicketed={order => {
          setIsTicketing(false);
          onTicketed(order);
        }}
        onClose={() => setIsTicketing(false)}
      />
    </div>
  );
};

export default ProductSummary;
