import React, { useContext } from 'react';
import useSetup from '../useSetup';

const PourDescriptionSetupContext = React.createContext();

export const usePourDescriptionSetupContext = () => {
  const context = useContext(PourDescriptionSetupContext);
  if (!context) {
    throw new Error(`usePourDescriptionSetupContext cannot be rendered outside of the PourDescriptionSetupContext context provider`);
  }
  return context;
};

const PourDescriptionSetupContextProvider = ({ children }) => {
  const setup = useSetup('pour-description');
  return <PourDescriptionSetupContext.Provider value={setup}>{children}</PourDescriptionSetupContext.Provider>;
};

export default PourDescriptionSetupContextProvider;
