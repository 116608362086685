import { css } from 'styled-components';

export default css`
  margin-top: 25px;
  margin-right: 10px;
  padding: 5px;
  background-color: #504d4d;
  border: 1px solid black;

  .card-header {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
    margin-bottom: 6px;
  }

  .delete-button:hover {
    cursor: pointer;
  }

  .header-data {
    display: flex;
  }

  .badge {
    border-radius: 0.25rem;
    padding-left: 0.25em;
    padding-right: 0.25em;
    margin-right: 10px;
  }
`;
