import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
import { useAppContext } from '../../../AppContext';

const Header = ({ className, title, rightSide = '', leftSide = '' }) => {
  const { hideButtons, hideRightSideButtons } = useAppContext();

  return (
    <div className={cn('order-form-heading', className)}>
      {hideButtons !== 'true' && <div className="left-side">{leftSide}</div>}
      <div className="title">
        <div className="title-text">{title}</div>
      </div>
      {hideRightSideButtons !== 'true' && <div className="right-side">{rightSide}</div>}
    </div>
  );
};

export default styled(Header)`
  display: grid;
  padding: 0 10px 0 10px;
  margin: 10px 0 15px 0;
  //margin: 5px 0 0 0;

  grid-template-columns: 1fr auto 1fr;
  grid-template-areas: 'left center right';

  @media (max-width: 475px) {
    grid-template-columns: 1fr auto 1fr;
  }

  .spinner {
    position: absolute;
    left: -20px;
    top: 3px;
    font-size: 0.875rem;
    opacity: 0.6;
  }

  .title {
    text-align: center;
    font-weight: bold;
    font-size: 1.2em;
    flex: 1;
    grid-area: center;
    position: relative;
  }

  .left-side {
    text-align: left;
    grid-area: left;
  }
  .left-side {
    display: flex;
    grid-gap: 10px;
  }
  .right-side {
    text-align: right;
    grid-area: right;
  }
`;
