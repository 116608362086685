import style from '../style';
import React from 'react';
import styled from 'styled-components';
import InputText from '../../components/input-text';
import InputSelect from '../../components/input-select';
import { status, yesNo } from './options';
import InputCheckbox from '../../components/input-checkbox';
import ColorPicker from '../../../order/components/color-picker';
import generateEnableTicketingFlag from '../../../../util/other/generate-enable-ticketing-flag';

const View = styled.div`
  ${style}
`;

const OrderStatusGeneral = ({ item, onChange }) => {
  return (
    <View>
      <InputText id="id" label="ID" value={item?.id} onChange={onChange} />
      <InputText id="name" label="Name" value={item?.name} onChange={onChange} />
      <InputSelect
        id="displayInPlanningGraphs"
        label="Display In Planning Graph"
        value={item?.displayInPlanningGraphs ?? 'YES'}
        onChange={onChange}
        options={yesNo}
        required
      />
      <InputSelect id="status" label="Status" value={item?.status} onChange={onChange} options={status} required />
      <ColorPicker
        fieldLabel="Tracking Color"
        fieldName="trackingColor"
        onChange={onChange}
        value={item?.trackingColor || '#FFFF00'}
      />
      <InputCheckbox id="defaultValue" label="Default" value={item?.defaultValue ?? false} onChange={onChange} />
      <InputCheckbox
        id="enableTicketing"
        label="Enable Ticketing"
        value={generateEnableTicketingFlag(item)}
        onChange={onChange}
      />
    </View>
  );
};

export default OrderStatusGeneral;
