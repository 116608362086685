import SelectSetupItem from "../select-setup-item";
import React from "react";

const SelectComponent = ({item, onChange}) => {
  const changeComponent = (id, value) => {
    onChange('component', value)
  }

  return (
    <SelectSetupItem
      id='componentRef'
      label='Component'
      typeId='component'
      setupItemRef={item?.component?.componentRef}
      onChange={changeComponent} />
  )
}

export default SelectComponent;
