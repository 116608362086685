import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import style from './style';
import ReactDataGrid, { Row } from 'react-data-grid';

function rowKeyGetter(row) {
  return row.crn;
}

const DataGrid = ({ className, columns, rows, rowRenderer }) => {
  const [[sortColumn, sortDirection], setSort] = useState('id', 'NONE');
  const [selectedRows, setSelectedRows] = useState(() => new Set());

  const handleSort = useCallback((columnKey, direction) => {
    setSort([columnKey, direction]);
  }, []);

  const sortedRows = useMemo(() => {
    if (sortDirection === 'NONE') return rows;

    let sortedRows = [...rows];

    sortedRows = sortedRows.sort((a, b) => a[sortColumn] - b[sortColumn]);

    return sortDirection === 'DESC' ? sortedRows.reverse() : sortedRows;
  }, [rows, sortDirection, sortColumn]);

  return (
    <div className={className}>
      <ReactDataGrid
        className={cn('fill-grid')}
        rowKeyGetter={rowKeyGetter}
        columns={columns}
        rows={sortedRows}
        rowRenderer={rowRenderer}
        headerRowHeight={25}
        rowHeight={22}
        defaultColumnOptions={{
          sortable: true,
          resizable: true,
        }}
        // selectedRows={selectedRows}
        // onSelectedRowsChange={setSelectedRows}
        sortColumn={sortColumn}
        sortDirection={sortDirection}
        onSort={handleSort}
      />
    </div>
  );
};

export const GridRow = Row;

export default styled(DataGrid)`
  ${style}
`;
