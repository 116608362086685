import { Modal } from 'antd';
import cn from 'classnames';
import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';
import SelectAddress from './SelectAddress';
import BaseMap, { MAP_TYPE } from '../../../../components/sdk-map';
import Spinner from '../../../../components/spinner/Spinner';
import FlexColumn from '../FlexColumn';
import { useOrderContext } from '../useOrderContext';
import { useMapBehaviorContext } from './context/MapBehaviorContext';
import { useMapStateContext } from './context/MapStateContext';
import FormSection from './FormSection';
import ExistingOrderMapProvider from './map-existing-order/ExistingOrderMapProvider';
import NewOrderMapProvider from './map-new-order/NewOrderMapProvider';
import { useDestination } from './hooks/marker-props/useDestination';
import { useRoute } from './hooks/marker-props/useRoute';
import { useWaypoints } from './hooks/marker-props/useWaypoints';
import { useMemo } from 'react';

const Styled = styled(FlexColumn)`
  height: 100%;
`;

const OrderMap = ({ isNewOrder, forceDestinationUpdate }) => {
  const {
    waypoints,
    trucks = [],
    effectiveDestination,
    setMap,
    configBusy,
    isModalVisible,
    origin,
    readOnly,
  } = useMapStateContext();

  const { cancelAcceptance, finalizeAcceptance } = useMapBehaviorContext();

  // const Origin = useOrigin();
  const destination = useDestination();
  const route = useRoute();
  const waypointsOptions = useWaypoints();

  const parsedDestination = useMemo(() => {
    if (!forceDestinationUpdate) return destination;
    destination.canUpdate = true;
    return destination;
  }, [forceDestinationUpdate, destination]);

  return (
    <Styled className={cn('map')}>
      <ToastContainer />
      <div className="form-section-wrapper">{!readOnly && <FormSection isNewOrder={isNewOrder} />}</div>
      <BaseMap
        bounds={[].concat(route, waypoints, origin, effectiveDestination)}
        mapTypeId={effectiveDestination?.updateLocation ? MAP_TYPE.SATELLITE : MAP_TYPE.ROADMAP}
        onLoad={setMap}
        options={[origin, parsedDestination, route, ...waypointsOptions, ...(trucks || [])]}
        busy={configBusy}
      />
      <Modal
        title="Which address should be displayed on the order?"
        footer={null}
        visible={!!isModalVisible}
        onCancel={cancelAcceptance}
      >
        <SelectAddress addresses={isModalVisible} onCancel={cancelAcceptance} onSelect={finalizeAcceptance} />
      </Modal>
    </Styled>
  );
};

export default ({ readOnly, onDone, forceDestinationUpdate }) => {
  const { lists } = useOrderContext();
  const { orderId } = useParams();
  const MapProvider = React.useMemo(() => (orderId ? ExistingOrderMapProvider : NewOrderMapProvider), [orderId]);

  return (
    <Spinner spin={lists?.locations?.busy || !lists?.locations?.list?.length}>
      <MapProvider readOnly={readOnly} onDone={onDone}>
        <OrderMap isNewOrder={!orderId} forceDestinationUpdate={forceDestinationUpdate} />
      </MapProvider>
    </Spinner>
  );
};
