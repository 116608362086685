import { useMemo, useCallback } from 'react';
import styled from 'styled-components';
import { minBy, orderBy } from 'lodash';

import style from './style';
import BatchList from './components/batch-list';
import { useModalContext } from '../../../../components/modal-context/ModalContext';
import Button from '../../../../components/button/Button';
import { Translate } from '../localization/Translate';

const Style = styled.div`
  ${style}
`;

const BatchCreateResults = ({ ordersData = [], customHandler }) => {
  const { closeModal } = useModalContext();

  const ordersDataMapped = useMemo(() => {
    const mapping = {
      CREATED_SUCCESS: {
        list: [],
        displayKey: 'id',
        messageId: 'orders-created',
        defaultMessage: 'Orders created successfully:',
      },
      CREATED_NO_TAXRATE: {
        list: [],
        displayKey: 'id',
        messageId: 'orders-created-no-tax',
        defaultMessage: 'Orders created without tax rates:',
      },
      CREATED_FAILED: {
        list: [],
        displayKey: 'startDateTime',
        messageId: 'orders-failed',
        defaultMessage: 'Failed to Create the following orders:',
      },
    };

    if (!ordersData?.length) return mapping;

    ordersData?.forEach(o => {
      if (!o?.error) {
        mapping.CREATED_SUCCESS.list.push(o);
        return;
      }
      if (o?.error && o?.message === 'Unable to determine tax rates for jurisdiction') {
        mapping.CREATED_NO_TAXRATE.list.push(o);
        return;
      }
      if (o?.error) {
        mapping.CREATED_FAILED.list.push(o);
        return;
      }
    });

    Object.keys(mapping).forEach(k => {
      const m = mapping[k];
      m.list = orderBy(m.list, [m.displayKey], ['asc']);
    });

    return mapping;
  }, [ordersData]);

  const onClickHandler = useCallback(() => {
    if (!ordersDataMapped.CREATED_NO_TAXRATE.list.length && !ordersDataMapped.CREATED_SUCCESS.list.length) {
      closeModal();
      return;
    }

    const mostRecentOrder = minBy(
      [...ordersDataMapped.CREATED_NO_TAXRATE.list, ...ordersDataMapped.CREATED_SUCCESS.list],
      'startDateTime'
    );

    customHandler(mostRecentOrder);
    closeModal();
  }, [customHandler, closeModal, ordersDataMapped]);

  return (
    <Style>
      {Object.keys(ordersDataMapped)
        .filter(k => ordersDataMapped[k]?.list?.length)
        .map(k => {
          const category = ordersDataMapped[k];
          return (
            <BatchList
              key={k}
              orderList={category?.list}
              displayKey={category.displayKey}
              messageId={category?.messageId}
              defaultMessage={category?.defaultMessage}
            />
          );
        })}
      <div className="buttons-container">
        <Button type="primary" onClick={onClickHandler}>
          <Translate stringId="close" defaultMessage="Close" />
        </Button>
      </div>
    </Style>
  );
};

export default BatchCreateResults;
