import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { api } from './api';

export const useProjects = () => {
  const { entityRef } = useParams();
  const [projects, setProjects] = useState([]);
  const [projectsBusy, setProjectsBusy] = useState(false);

  const getProjects = useCallback(
    async (customerRef, params) => {
      setProjects(null);
      setProjectsBusy(true);

      const response = await api.getProjects(entityRef, customerRef, params);
      setProjects(response);
      setProjectsBusy(false);
      return response;
    },
    [entityRef]
  );

  return { projects, projectsBusy, getProjects };
};
