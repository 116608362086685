import styled from 'styled-components';
import cn from 'classnames';
import style from './style';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useContextMenuContext } from './useContextMenuContext';
import { Menu } from 'antd';
import { buttonClick } from '../../../../../util/analytics';
import { kebabCase } from 'lodash';

export const ContextMenu = ({ className }) => {
  const { onAction, menu, location, setMenu } = useContextMenuContext();
  const componentStyle = useMemo(() => {
    if (location?.x && location?.y) {
      return {
        left: `${location.x}px`,
        top: `${location.y}px`,
        position: 'absolute',
      };
    }
    return {};
  }, [location]);

  useEffect(() => {
    if (menu) {
      const handleClick = e => {
        setMenu(null);
      };
      document.addEventListener('click', handleClick);
      return () => {
        document.removeEventListener('click', handleClick);
      };
    }
  }, [menu]);

  if (!menu) {
    return null;
  }

  return (
    <div className={cn(className)} style={componentStyle}>
      <Menu>
        {menu.map((menuItem, key) => {
          const handleMenuClick = () => {
            onAction(menuItem);
          };
          return (
            <Menu.Item
              key={key}
              onClick={() => {
                buttonClick(handleMenuClick, `core-tracking-${kebabCase(menuItem.title)}`).then();
              }}
            >
              {menuItem.title}
            </Menu.Item>
          );
        })}
      </Menu>
    </div>
  );
};

export const ContextMenuTrigger = ({ children, type, item, enabled }) => {
  const { onClick } = useContextMenuTrigger(type, item, enabled);

  if (!item || enabled === false) {
    return children;
  }

  return <div onClick={onClick}>{children}</div>;
};

export const useContextMenuTrigger = (type, item, enabled) => {
  const { showMenu } = useContextMenuContext();
  const onClick = useCallback(
    e => {
      e.stopPropagation();
      showMenu(item, type, e.pageX, e.pageY);
    },
    [item, type]
  );

  return { onClick };
};

export default styled(ContextMenu)`
  ${style}
`;
