import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
import FieldContainer from '../../field-container/FieldContainer';
import Input from '../../form/Input';

const Spacing = ({ className, value, spacingUnits }) => {
  return (
    <FieldContainer className={cn('spacing', className)}>
      <div className="label-spinner">
        <div className="label">Spacing</div>
        <div className={cn('spinner', { spinning: false })}>
          <span className="fas fa-sync fa-spin" />
        </div>
      </div>
      <div className="spacing-fields">
        <div>
          <Input id="spacing-input" type="text" value={value || ''} disabled data-testid="spacing_input" />
        </div>
        <div>
          <Input value={spacingUnits} disabled id="spacing-unit-input" />
        </div>
      </div>
    </FieldContainer>
  );
};

export default styled(Spacing)`
  width: 100%;
  .spacing-fields {
    display: flex;
    min-width: 300px;
    #spacing-unit-input {
      border-left: 0;
    }

    & > *:first-child {
      flex: 1;
      input {
        border-radius: 0;
      }
    }
    & > *:nth-child(2) {
      min-width: 175px;
      .ant-select-selector {
        border-left: 0;
        border-radius: 0;
      }
    }
  }
`;
