import React from 'react';
import styled from 'styled-components';
import { Translate } from '../localization/Translate';

const colors = {
  PENDING: { backgroundColor: '#FFC108', color: '#333' },
  'ORDER REQUEST (PENDING)': { backgroundColor: '#FFC108', color: '#333' },
  'ORDER REQUEST (REJECTED)': { backgroundColor: '#DC3645', color: '#eee' },
  CONFIRMED: { backgroundColor: '#28A745', color: 'white' },
  REJECTED: { backgroundColor: '#DC3645', color: '#eee' },
  VOIDED: { backgroundColor: '#DC3645', color: '#eee' },
  CANCELLED: { backgroundColor: '#DC3645', color: '#eee' },
  HOLD: { backgroundColor: '#ff9508', color: '#eee' },
  'CREDIT HOLD': { backgroundColor: '#ff9508', color: '#eee' },
};

const StatusBadge = ({ status, className, dataTestId }) => {
  return (
    <div data-testid={dataTestId || 'status-badge'} id="status-badge" className={className}>
      {status ? <Translate stringId={status} defaultMessage={status} /> : ''}
    </div>
  );
};

export default styled(StatusBadge)`
  font-size: 0.7rem;
  line-height: 0.85rem;
  font-weight: 700;
  background-color: ${({ status }) => colors[status]?.backgroundColor};
  border: none;
  color: ${({ status }) => colors[status]?.color};
  padding: 0 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 16px;
`;
