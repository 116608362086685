import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
import { DateTime } from 'luxon';
import Row from '../components/Row';
import { useScaleTicketingContext } from '../Context';
import ScaleList from './ScaleList';
import ScaleValues from './ScaleValues';
import { useScaleIntegration } from './useScaleIntegration';

const Styled = styled.div`
  flex: 1;
`;

const LiveWeight = () => {
  const { handleChange } = useScaleTicketingContext();

  const handleScaleData = React.useCallback(
    value => {
      handleChange('capturedWeight', {
        acquiredDateTime: DateTime.now().toUTC().toISO(),
        type: 'LIVE',
        weight: { uom: 'LB', value },
      });
    },
    [handleChange]
  );

  useScaleIntegration(handleScaleData);

  return (
    <Styled className={cn('live-weight')}>
      <Row spaceBetween>
        <ScaleList />
        <ScaleValues />
      </Row>
    </Styled>
  );
};

export default LiveWeight;
