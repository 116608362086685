import { css } from 'styled-components';

export default css`
  width: 100%;
  height: 360px;
  padding: 0;
  flex: 1;
  .no-map {
    width: 100%;
    margin-top: 25px;
    text-align: center;
    font-size: 1.0em;
    border: solid 1px #696a6b;
    padding: 50px;
  }
`;
