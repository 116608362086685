import cn from 'classnames';
import { find, kebabCase } from 'lodash';
import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { buttonClick } from '../../util/analytics';
import { cacheItem, getCachedItem } from "../../util/cache";
import Multiple from './Multiple';
import Single from './Single';
import style from './style';

const Styled = styled.div`
  ${style}
`;
const DropdownButton = ({ options }) => {
    const { orderType } = useParams();

    const defaultButton = React.useMemo(() => {
        const currentOrderTypeButton = find(options, option => option.id === orderType?.toLowerCase?.());
        const lastUsedButtonId = getCachedItem('lastUsedCreateButton');
        const defaultButton = find(options, option => option.default) || options?.[0];
        const lastUsedButton = find(options, option => option.id === lastUsedButtonId);
        return currentOrderTypeButton || lastUsedButton || defaultButton;
    }, [options, orderType]);

    const [currentButton, setCurrentButton] = React.useState(defaultButton);

    const handleButtonClick = React.useCallback(() => {
        cacheItem('lastUsedCreateButton', currentButton?.id);
        buttonClick(currentButton?.onClick, currentButton.metricId);
    }, [currentButton]);

    const ButtonComponent = React.useMemo(() => {
        return options.length > 1 ? Multiple : Single;
    }, [options.length]);

    return !options.length ? null : (
        <Styled className={cn('dropdown-button')}>
            <ButtonComponent
                type="primary"
                size="small"
                onClick={handleButtonClick}
                label={currentButton.label}
                options={options}
                currentButton={currentButton}
                setCurrentButton={setCurrentButton}
            />
        </Styled>
    );
};

export default DropdownButton;
