import config_env from '../config-env.json';

import getEnvironment from './getEnvironment';

const getEnviromentConfig = key => {
  const env = getEnvironment();

  const { settings } = config_env;
  const config = settings?.[env || 'prod'];

  switch (key) {
    case 'googleMapApiKey':
      return process.env.REACT_APP_GOOGLE_API_KEY || config?.[key];
    default:
      return config?.[key];
  }
};

export default getEnviromentConfig;
