import * as mr from 'multi-integer-range';
import Logic from 'json-logic-js';

export const PER_QTY_UOM_CODES = ['/YQ/ + YDQ', '/MQ/ + MTQ'];
export const PER_100_WEIGHT_UOM_CODES = ['/C'];

const calcTicketedProductQuantities = order => {
  const productQuantities = [];
  if (order?.deliverySchedule?.tickets?.length) {
    for (const ticket of order.deliverySchedule.tickets) {
      if (!ticket.ticket?.isVoided && ticket.ticket?.lineItems?.length) {
        ticket.ticket?.lineItems.forEach(li => {
          if (li.item?.productRef && li.quantity?.value && !isNaN(+li.quantity.value)) {
            const idx = productQuantities.findIndex(p => p.productRef === li.item.productRef);
            if (idx >= 0) {
              productQuantities[idx].quantity += +li.quantity.value;
            } else {
              productQuantities.push({ productRef: li.item.productRef, quantity: +li.quantity.value });
            }
          }
        });
      }
    }
  }
  return productQuantities;
};

/**
 * Calculate the qty of each product in a specific load of the schedule
 *
 *   This function without the memoizedQuantitiesByLoad can crash the application becasue of the recursion in the loop
 *   if the targetLoadNumber is big
 *
 * @param {Object} order
 * @param {number} targetLoadNumber
 * @param {boolean} useScheduleLoadTimes
 * @param {Object<number,TicketQuantity>} memoizedQuantitiesByLoad
 * @returns {TicketQuantity}
 */
export const calcTicketQuantities = (
  order,
  targetLoadNumber = 0,
  useScheduleLoadTimes = true,
  memoizedQuantitiesByLoad = {}
) => {
  const r = {};

  // idx is the index of the targetLoadNumber in the schedule array
  const idx =
    targetLoadNumber > 0 ? order.deliverySchedule?.schedule?.findIndex(s => s.loadNumber === targetLoadNumber) : 0;

  //Next load is the targetLoadNumber load
  r.nextLoad = idx >= 0 ? order.deliverySchedule?.schedule?.[idx] : undefined;

  const previousScheduledLoadQuantities = [];
  for (let i = 0; i < idx; i++) {
    const previousLoad = order.deliverySchedule?.schedule?.[i];
    if (previousLoad) {
      const previousQuantities =
        memoizedQuantitiesByLoad[previousLoad.loadNumber] ||
        calcTicketQuantities(order, previousLoad.loadNumber, memoizedQuantitiesByLoad);
      if (previousQuantities) {
        previousScheduledLoadQuantities.push(previousQuantities);
      }
    }
  }

  if (r.nextLoad) {
    r.loadQuantity = r.nextLoad.overrides?.QUANTITY ?? r.nextLoad.quantity;

    const ticketedProducts = calcTicketedProductQuantities(order);

    r.associatedProductQuantities = {};
    for (const lineItem of order.lineItems || []) {
      if (lineItem.isPrimary === true) {
        continue;
      }

      if (lineItem.loadTimeTrigger?.triggerLogic) {
        const loadTime = useScheduleLoadTimes ? new Date(r.nextLoad.ticketEvents?.['LOADING_STARTED']) : new Date();
        const context = {
          dispatchTime: loadTime.getHours() * 3600 + loadTime.getMinutes() * 60 + loadTime.getSeconds(),
        };

        if (!Logic.apply(JSON.parse(lineItem.loadTimeTrigger.triggerLogic), context)) {
          continue;
        }
      }

      const productRef = lineItem.item?.productRef;
      if (productRef) {
        if (lineItem?.item?.minimumLoadCharge) {
          r.associatedProductQuantities[productRef] = parseFloat(
            order?.minimumLoadChargeLoadsData?.[productRef]?.[r.nextLoad.loadNumber] || '0'
          );
          continue;
        }

        const isQtyPerUnit = PER_QTY_UOM_CODES.includes(lineItem.item?.uomCode);
        const isPer100Unit = PER_100_WEIGHT_UOM_CODES.includes(lineItem.item?.uomCode);
        const cmtWeight = isPer100Unit ? lineItem.priceSummary?.cmtWeight ?? 0 : 0;
        const orderedQty =
          lineItem.orderedQuantity?.value && !isNaN(+lineItem.orderedQuantity.value)
            ? +lineItem.orderedQuantity.value
            : 0.0;

        let associatedProductQuantity = isQtyPerUnit
          ? orderedQty * r.loadQuantity
          : isPer100Unit
            ? Math.round(orderedQty * r.loadQuantity * cmtWeight / 10) / 10
          : lineItem.orderedQuantity?.type === 'DERIVED_FROM_PRIMARY_QUANTITY'
          ? r.loadQuantity
          : orderedQty;

        let containsLoadNumber = true;
        if (r.nextLoad.loadNumber && lineItem.properties?.loadNumbers) {
          const range = mr.parse(lineItem.properties.loadNumbers);
          containsLoadNumber = mr.has(range, mr.parse(`${r.nextLoad.loadNumber}`));
          if (!containsLoadNumber) {
            associatedProductQuantity = 0.0;
          }
        }

        const category = lineItem.item?.category ?? (lineItem.item?.type === 'OTHER_PRODUCT' ? 'other' : 'associated');
        if (!isQtyPerUnit && category === 'other') {
          // Category 'other' will default the quantity to the remaining amount for the order
          const ticketedQty = ticketedProducts.find(p => p.productRef === lineItem.item?.productRef)?.quantity ?? 0.0;
          let scheduledQty = 0.0;
          previousScheduledLoadQuantities
            .map(q => q.associatedProductQuantities?.[productRef] ?? 0.0)
            .forEach(qty => (scheduledQty += !isNaN(+qty) ? +qty : 0.0));
          const orderedQty =
            lineItem.orderedQuantity?.value && !isNaN(+lineItem.orderedQuantity.value)
              ? +lineItem.orderedQuantity.value
              : 0.0;

          associatedProductQuantity =
            containsLoadNumber && orderedQty > ticketedQty + scheduledQty
              ? orderedQty - ticketedQty - scheduledQty
              : 0.0;
        }

        if (r?.nextLoad?.isExtra && category === 'associated' && r.loadQuantity === 0) {
          associatedProductQuantity = 0.0;
        }

        // Manual overrides
        if (r.nextLoad.overrides?.associatedProducts?.[productRef] !== undefined) {
          associatedProductQuantity = r.nextLoad.overrides?.associatedProducts?.[productRef];
        }

        r.associatedProductQuantities[productRef] = associatedProductQuantity;
      }
    }
  }
  return r;
};
