import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { api } from '../api';

const sortByTravelTime = (a, b) => {
  const travelA = a.travelData?.durationMinutes ?? 999;
  const travelB = b.travelData?.durationMinutes ?? 999;
  return travelA - travelB;
};

export const useLocations = () => {
  const { entityRef } = useParams();
  const [locations, setLocations] = useState([]);
  const [locationsBusy, setLocationsBusy] = useState(false);

  const getLocations = useCallback(async params => {
    setLocations(null);
    setLocationsBusy(true);

    const response = (await api.getLocations(entityRef, params)).sort(sortByTravelTime);
    setLocations(response);
    setLocationsBusy(false);
    return response;
  }, [entityRef]);

  return { locations, locationsBusy, getLocations };
};
