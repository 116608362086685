import React from 'react';
import { useMapStateContext } from '../context/MapStateContext';
import useOrderMap from './initialize/useOrderMap';

export const usePollTrucks = () => {
  const { setTrucks } = useMapStateContext();
  const { getConfig } = useOrderMap();

  const intervalRef = React.useRef();

  const startPolling = React.useCallback(
    async (configRef, pollingInterval) => {
      const interval = setInterval(async () => {
        const config = await getConfig(configRef);

        setTrucks(config?.markers?.filter?.(m => m.type === 'truck'));
      }, Math.max(5000, pollingInterval));
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
      intervalRef.current = interval;
    },
    [getConfig, setTrucks]
  );

  const stopPolling = React.useCallback(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
  }, []);

  return { startPolling, stopPolling };
};
