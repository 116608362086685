const queryParams = params => {
  if (params) {
    let q;

    for (const [key, value] of Object.entries(params)) {
      const param = `${key}=${value}`;

      q = q ? `${q}&${param}` : `?${param}`
    }

    return q
  }

  return ''
}

export default queryParams;
