import React from 'react';

const DestinationInfoWindow = ({ effectiveAddress }) => {
  return (
    <div>
      <span style={{ fontWeight: 'bold' }}>Destination:</span>{' '}
      {effectiveAddress?.address1?.replace?.(/undefined/g, '')?.trim?.() ||
        []
          .concat(effectiveAddress?.city, effectiveAddress.countrySubDivision, effectiveAddress.postalCode)
          .filter(x => !!x)
          .join(', ')}
    </div>
  );
};

export default DestinationInfoWindow;
