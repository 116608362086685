import React, { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import styled from 'styled-components';
import FieldContainer from '../../../../components/field-container/FieldContainer';
import zones from '../localization/zones';
import useSetup from '../../../setup/useSetup';
import style from './style';

const Styled = styled(FieldContainer)`
  ${style}
`;

const CustomPhoneInput = ({ onChange, value, fieldName, fieldLabel, disabled, readOnly, required }) => {
  const { companyTimeZone, getCompanyTimeZone, timeZoneBusy } = useSetup();
  const { entityRef } = useParams();
  const phoneNumber = value && value.includes('-') ? `1${value.replace(/-/g, '')}` : value;

  useEffect(() => {
    getCompanyTimeZone(entityRef).then();
  }, [getCompanyTimeZone, entityRef]);

  const handleChange = useCallback(
    phoneNumber => {
      onChange(fieldName, phoneNumber);
    },
    [fieldName, onChange]
  );

  const countryCode = useMemo(() => {
    const timeZonesMap = new Map(zones.map(tz => [tz.name, tz.countryCode]));
    return companyTimeZone ? timeZonesMap.get(companyTimeZone)?.toLowerCase() : 'us';
  }, [companyTimeZone]);

  return (
    <Styled>
      <div className="label-spinner">
        <div className="label">
          {fieldLabel}
          {required && <span className="required-indicator">*</span>}
        </div>
      </div>
      <PhoneInput
        fieldName={fieldName}
        country={countryCode}
        value={timeZoneBusy ? '' : phoneNumber || ''}
        onChange={handleChange}
        disabled={disabled || timeZoneBusy}
        readOnly={readOnly}
      />
    </Styled>
  );
};

export default CustomPhoneInput;
