import style from './style';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import InputCheckbox from '../../components/input-checkbox';
import SelectExportType from './select-export-type';
import SelectTicketOptions from '../../components/select-ticket-options';
import SelectTaxLookup from '../../components/select-tax-lookup';
import InputNumber from '../../components/input-number';
import InputNumberBlur from '../../components/input-number-blur';

const View = styled.div`
  ${style}
`;

const CompanySettings = ({ item, onChange }) => {
  const deliveryCostValue = useMemo(() => {
    // If the item?.deliveryCostPerMinute is undefined is because the value has never being touched
    // so the default value is 1.5
    if (item?.deliveryCostPerMinute === undefined) return 1.5;
    // But users can manualy delete it and if they manualy delete it then the value will be null
    // and nothing should be displayed
    return item?.deliveryCostPerMinute ?? '';
  }, [item?.deliveryCostPerMinute]);

  const enableMultiplePrimaryProducts = useMemo(() => {
    // If item?.enableMultiplePrimaryProducts is undefined then we would need to return true in order to
    // display "enableMultiplePrimaryProducts" checkbox as checked
    if (item?.enableMultiplePrimaryProducts === undefined) return true;
    // But if item?.enableMultiplePrimaryProducts is not undefined we will return whether the value is truthy
    // or falsy
    return !!item?.enableMultiplePrimaryProducts;
  }, [item?.enableMultiplePrimaryProducts]);

  return (
    <View>
      <SelectTicketOptions item={item} view="company" onChange={onChange} />
      <SelectTaxLookup item={item} onChange={onChange} />
      <InputCheckbox
        id="taxPerProduct"
        label="Apply Taxes Per Product Line"
        value={item?.taxPerProduct === undefined || item?.taxPerProduct === true}
        onChange={onChange}
      />
      <InputCheckbox
        id="defaultInvoiceOptionCreatePDFs"
        label="Default Invoice Option 'Create PDFs'"
        value={item?.defaultInvoiceOptionCreatePDFs ? true : false}
        onChange={onChange}
      />
      <InputCheckbox
        id="defaultInvoiceOptionExportData"
        label="Default Invoice Option 'Export Data'"
        value={item?.defaultInvoiceOptionExportData ? true : false}
        onChange={onChange}
      />
      <InputCheckbox
        id="enableEmailToSalesForNonProjectProducts"
        label="Enable Email to Sales for Non-Project Products"
        value={item?.enableEmailToSalesForNonProjectProducts ? true : false}
        onChange={onChange}
      />
      <InputCheckbox
        id="enableMultiplePrimaryProducts"
        label="Enable Multiple Primary Product Entry"
        value={enableMultiplePrimaryProducts}
        onChange={onChange}
      />

      <InputCheckbox
        id="avoidOvershippingInTicketing"
        label="Avoid Overshipping in Ticketing"
        value={item?.avoidOvershippingInTicketing ? true : false}
        onChange={onChange}
      />
      <div className="spacing"></div>
      {item?.defaultInvoiceOptionExportData && (
        <SelectExportType
          id="defaultInvoiceOptionExportType"
          label="Default Export Type"
          value={item?.defaultInvoiceOptionExportType ?? ''}
          onChange={onChange}
        />
      )}
      <InputNumberBlur
        id="defaultSpacing"
        label="Default Spacing"
        value={item?.defaultSpacing}
        onChange={onChange}
        useDefaultOnChangeHandler={true}
        defaultValue={10}
        min={1}
      />

      <InputNumberBlur
        id="orderPourDefault"
        label="Default Time To Pour"
        value={item?.orderPourDefault}
        onChange={onChange}
        useDefaultOnChangeHandler={true}
        defaultValue={10}
        min={1}
      />

      <InputNumber
        id="defaultUnloadTime"
        label="Default Unload Time"
        min={1}
        value={item?.defaultUnloadTime}
        onChange={onChange}
      />

      <InputNumberBlur
        id="orderReturnDefault"
        label="Default Time To Return"
        value={item?.orderReturnDefault}
        onChange={onChange}
        useDefaultOnChangeHandler={true}
        defaultValue={10}
        min={1}
      />

      <InputNumber
        id="defaultWashTime"
        label="Default Wash Time"
        min={1}
        value={item?.defaultWashTime}
        onChange={onChange}
      />
      <InputNumberBlur
        id="deliveryCostPerMinute"
        label="Delivery Cost per Minute"
        value={deliveryCostValue}
        onChange={onChange}
        useDefaultOnChangeHandler={true}
      />
    </View>
  );
};

export default CompanySettings;
