import cn from 'classnames';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import Button from '../../../../../../components/button/Button';
import { Translate } from '../../../../components/localization/Translate';
import { useOrderListContext } from '../../../../components/OrderListContext';

const Styled = styled(Button)``;

const AdvancedSearchButton = ({ onChange }) => {
  const { showAdvancedSearch } = useOrderListContext();
  const handleClick = useCallback(() => {
    onChange(undefined);
  }, []);

  return (
    <Styled
      className={cn('advanced-button')}
      onClick={handleClick}
      type={showAdvancedSearch ? 'primary' : undefined}
      metricId="core-order-list-filter-advanced"
      data-testid="button-advanced"
    >
      <Translate stringId="advancedSearch" defaultMessage="Advanced Search" />
    </Styled>
  );
};

export default AdvancedSearchButton;
