import { css } from 'styled-components';

export default css`
  padding: 0px;
  margin-top: 10px;

  .label {
    text-align: center;
    font-weight: normal;
    position: relative;
  }

  .show-all {
    position: absolute;
    right: 10px;
    top: -5px;
  }

  .show-all .ant-checkbox {
    border: solid 1px #696a6b;
  }

  .show-all .title {
    margin-right: 10px;
  }

  table {
    width: 100%;
    margin-bottom: 10px;

    thead {
      tr {
        background-color: #656363;
        th {
          text-align: center;
        }
      }
    }

    tbody {
      tr {
        cursor: pointer;
        &.alt {
          background-color: #505050;
        }
        td {
          border: 1px solid #666;
          text-align: center;
          &.ticket-id {
            position: relative;
            display: flex;
            justify-content: center;
            .ticket-id-text {
              color: #56a8f5;
              text-decoration: underline;
            }
            
          }
        }
      }
    }
  }
`;
