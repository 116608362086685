import React from 'react';
import { useOrderContext } from '../../useOrderContext';
import { useMapState } from '../hooks/useMapState';

export const useNewOrderMapState = (readOnly, onDone) => {
  const state = useMapState();

  const {
    form: { handleChange },
  } = useOrderContext();

  const onAcceptance = React.useCallback(
    async address => {
      onDone();
      handleChange('address', address);
      handleChange('_waypoints', state.waypoints);
      handleChange('_destination', state.destination);
    },
    [handleChange, onDone, state.destination, state.waypoints]
  );

  return {
    ...state,
    readOnly,
    onAcceptance,
    onDone,
    isNewOrder: true,
  };
};
