import AntdConfig from 'react-awesome-query-builder/lib/config/antd';

const createConfig = fields => {
  return {
    ...AntdConfig,
    fields,
  }
};

export default createConfig;
