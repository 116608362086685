import React from "react";
import Select from "../../../../components/form/Select";
import styled from 'styled-components';
import {kebabCase} from 'lodash';
import MATERIAL_TYPE_OPTIONS from './material-types';

const { Option } = Select;

const Title = styled.div`
  padding: 5px;
`
const byLabel = (a,b) => {
  return a.label < b.label ? -1 : 1
}

const SelectMaterialType = ({ id = 'materialType', title, mtlType, onChange }) => {
  return (
    <div>
      <Title>{title || `Material Type`}</Title>
      <Select id={id}
              showSearch
              filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
              className='select-mtl-type'
              value={mtlType || ''}
              onChange={value => onChange(id, value)}
              data-testid={`select-${kebabCase((title || 'Material Type')?.toLowerCase?.())}`}>
        <Option value="">Select...</Option>
        {MATERIAL_TYPE_OPTIONS.sort(byLabel).map((option, idx) => (
          <Option label={option.label} key={`${option.value}${idx}`} value={option.value}>{option.label}</Option>
        ))}
      </Select>
    </div>
  )
}

export default SelectMaterialType;
