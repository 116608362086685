import React from 'react';
import styled from 'styled-components';
import { Input as AntInput } from 'antd';

const ColorPicker = props => {
  return <AntInput {...props} type="color" />;
};

export default styled(ColorPicker)`
  width: 100%;
`;
