export default (loads, targetOrderRef) => {
  if (!loads) return;
  const offsets = Object.entries(loads)?.reduce?.((acc, [key, value]) => {
    const isTarget = value.orderRefs.includes(targetOrderRef);
    if (!isTarget) return acc;
    acc.push(value.offset);
    return acc;
  }, []) || [0, 0];

  const firstOffset = offsets.length ? Math.min(...offsets) : 0;
  const lastOffset = offsets.length ? Math.max(...offsets) : 1440;

  return { range: [firstOffset, lastOffset], diff: lastOffset - firstOffset };
};
