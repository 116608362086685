import {css} from 'styled-components';

export default css`
  .ant-input-disabled {
    color: white;
  }
  
  .manual-label {
    margin-bottom: 7px;
  }
  
  margin-bottom: 25px;
`;
