import { css } from 'styled-components';

export default css`
  .required-indicator {
    margin-left: 5px;
  }

  &.validation-failed {
    input {
      border-color: #b62121 !important;
    }
  }
  
`;
