import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
import { Translate } from '../../../views/order/components/localization/Translate';
import { useTranslateMessage } from '../../../views/order/components/localization/useTranslateMessage';
import Button from '../../button/Button';
import Input from '../../form/Input';
import style from './style';
import { buttonClick } from '../../../util/analytics/index';
import { lowerCase } from 'lodash';

const Styled = styled.div`
  ${style}
`;
const Trucks = ({ maxTrucks, maxTrucksOverride, onChange, onReset }) => {
  const inputRef = React.useRef();

  const focus = React.useCallback(() => {
    inputRef?.current?.focus?.();
  }, []);

  const [value, setValue] = React.useState(maxTrucksOverride);
  const [isOpen, setIsOpen] = React.useState(false);

  React.useEffect(() => {
    setValue(maxTrucksOverride);
  }, [maxTrucksOverride]);

  const toggleIsOpen = React.useCallback(() => {
    setIsOpen(s => !s);
  }, []);

  const handleOkay = React.useCallback(() => {
    if (value == maxTrucks) {
      onChange(0);
      setValue('');
    } else {
      onChange(value);
    }
    toggleIsOpen();
  }, [maxTrucks, onChange, toggleIsOpen, value]);

  const handleCancel = React.useCallback(() => {
    onChange(0);
    setValue('');
    toggleIsOpen();
  }, [onChange, toggleIsOpen]);

  const handleClick = React.useCallback(() => {
    if (isOpen) {
      toggleIsOpen();
      return;
    }

    if (maxTrucksOverride) {
      onReset(0);
      setValue('');
    } else {
      toggleIsOpen();
      focus();
    }
  }, [focus, isOpen, maxTrucksOverride, onReset, toggleIsOpen]);

  const handleKeyDown = React.useCallback(
    e => {
      if (e.key === 'Enter') {
        e.preventDefault();
        e.stopPropagation();
        handleOkay();
      }
      if (e.key === 'Escape') handleCancel();
    },
    [handleCancel, handleOkay]
  );

  const translateMessage = useTranslateMessage();

  const buttonLabel = React.useMemo(() => {
    if (!maxTrucksOverride) return translateMessage({ stringId: 'override', defaultMessage: 'Override' });

    return maxTrucks
      ? translateMessage({
          stringId: 'resetWithValue',
          defaultMessage: 'Reset ({value})',
          values: { value: maxTrucks },
        })
      : translateMessage({
          stringId: 'reset',
          defaultMessage: 'Reset',
        });
  }, [maxTrucksOverride, translateMessage, maxTrucks]);

  return (
    <Styled className={cn('trucks')}>
      <div className="label">
        {maxTrucksOverride ? (
          <Translate
            stringId="trucksAvailableOverridden"
            values={{ numberOfTrucks: maxTrucksOverride }}
            defaultMessage="{numberOfTrucks, number} Trucks Available (Overridden)"
          />
        ) : (
          <Translate
            stringId="trucksAvailable"
            values={{ numberOfTrucks: maxTrucks }}
            defaultMessage="{numberOfTrucks, number} Trucks Available"
          />
        )}
      </div>
      <div className={cn('override', { isOpen })}>
        <div
          className="button-label"
          onClick={() =>
            buttonClick(
              handleClick,
              maxTrucksOverride
                ? `core-planning-${lowerCase(buttonLabel.substring(0, 5))}`
                : `core-planning-${lowerCase(buttonLabel)}`
            )
          }
        >
          {buttonLabel}
        </div>
        <div className="form" onKeyDownCapture={handleKeyDown}>
          <Input size="small" innerRef={inputRef} onChange={e => setValue(e.target.value)} value={value} />
          <Button size="small" onClick={handleOkay} metricId="core-planning-override-ok">
            <Translate stringId="ok" defaultMessage="Ok" />
          </Button>
          <Button size="small" onClick={handleCancel} metricId="core-planning-override-cancel">
            <Translate stringId="cancel" defaultMessage="Cancel" />
          </Button>
        </div>
      </div>
    </Styled>
  );
};

export default Trucks;
