import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
import { omit } from 'lodash';
import Button from '../../../../components/button/Button';
import FlexColumn from '../FlexColumn';
import InputCheckbox from '../../../setup/components/input-checkbox';
import { Translate } from '../localization/Translate';
import { useOrderContext } from '../useOrderContext';
import { useMemo } from 'react';

const Styled = styled.div`
  .additional-info {
    margin-bottom: 20px;
  }
  .grid-container {
    display: grid;
    grid-template-columns: auto auto;
  }
  .button {
    padding-top: 20px;
    text-align: right;
  }
  .checkbox {
    padding-top: 25px;
    text-align: left;
  }
  .addresses {
    gap: 10px;
    .address {
      cursor: pointer;
      .address1 {
        font-weight: bold;
      }
      border: 1px solid #434343;
      border-radius: 4px;
      padding: 10px;
      font-size: 1.1em;
      &:hover {
        border-color: #ddd;
      }
    }
  }
`;

const SelectAddress = ({ addresses = [], onCancel, onSelect }) => {
  const { order } = useOrderContext();

  const [updateProjectAddress, setUpdateProjectAddress] = React.useState(false);

  const cleanedUpAddresses = React.useMemo(() => {
    return addresses.map(address => {
      const newAddress = omit(address, 'address1');

      const address1 = address.address1?.replace?.(/undefined/g, '')?.trim?.();

      if (address1) newAddress.address1 = address1;
      return newAddress;
    });
  }, [addresses]);

  const showDefaultProject = useMemo(() => {
    return order?.order?.projectRef && order?.order?.projectRef !== 'NONE';
  }, [order?.order?.projectRef]);

  return (
    <Styled className={cn('select-address')}>
      <div className="additional-info">This will not affect the marker's location on the map.</div>
      <FlexColumn className="addresses">
        {cleanedUpAddresses.map((address, index) => (
          <div
            className="address"
            onClick={() => onSelect(address, updateProjectAddress)}
            key={`${address.address1}__${index}`}
          >
            <div className="address1">{address.address1}</div>
            <div className="city-state-zip">
              {[]
                .concat(address.city, address.countrySubDivision, address.postalCode)
                .filter(a => !!a)
                .join(', ')}
            </div>
          </div>
        ))}
      </FlexColumn>
      <div className="grid-container">
        <div className="checkbox">
          <InputCheckbox
            id="setAddresAsProjectDefault"
            label={<Translate stringId="setAddresAsProjectDefault" defaultMessage="Set Address As Project Default" />}
            onChange={() => setUpdateProjectAddress(!updateProjectAddress)}
            value={updateProjectAddress}
            disabled={!showDefaultProject}
          />
        </div>
        <div className="button">
          <Button onClick={onCancel}>Cancel</Button>
        </div>
      </div>
    </Styled>
  );
};

export default SelectAddress;
