import { css } from 'styled-components';

export default css`
  .delete {
    text-align: center;
  }

  .delete:hover {
    cursor: pointer;
  }
`;
