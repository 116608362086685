import React from 'react';

const IfElse = ({ children, ifTrue }) => {
  const childrenArray = React.useMemo(() => {
    const kids = React.Children.toArray(children);
    if (kids.length !== 2) {
      throw new Error('IfElse requires exactly two children.');
    }
    return kids;
  }, [children]);

  return ifTrue ? childrenArray[0] : childrenArray[1];
};

export default IfElse;
