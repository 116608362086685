import React, { useState } from 'react';
import styled from 'styled-components';
import style from '../style';
import Tabs from '../../../../components/tabs';
import { kebabCase } from 'lodash';

const Styled = styled.div`
  ${style}
`;

const SetupTabs = ({
  tabs,
  item,
  onChange,
  onCancel,
  disableButtons,
  usedLocations,
  locationWarningMessage,
  saveWarningMessage,
}) => {
  const { tab } = useState(tabs[0].label);
  //Block to generate data-testid's
  let testId;
  if (item?.hasOwnProperty('formulas')) testId = 'tabs-mix-design';
  else if (item?.hasOwnProperty('formulaRef')) testId = 'tabs-mix-formula';
  else testId = 'tab-items';

  return (
    <Styled className="setup-tabs">
      {tabs?.length === 1 ? (
        tabs?.[0]?.component({ tab, item, onChange, onCancel, disableButtons })
      ) : (
        <>
          <Tabs type="card" activeKey={tab} data-testid={testId}>
            {tabs?.map(tab => (
              <Tabs.TabPane tab={tab.label} key={tab.label} disabled={false}>
                {tab.component({
                  tab,
                  item,
                  onChange,
                  onCancel,
                  disableButtons,
                  usedLocations,
                  locationWarningMessage,
                  saveWarningMessage,
                })}
              </Tabs.TabPane>
            ))}
          </Tabs>
          {saveWarningMessage && saveWarningMessage.length > 0 && (
            <div className="warning-section">
              <div className="warning-icon">
                <i className="fas fa-exclamation-triangle"></i>
              </div>
              <div className="warning-text">{saveWarningMessage}</div>
            </div>
          )}
        </>
      )}
    </Styled>
  );
};

export default SetupTabs;
