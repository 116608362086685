import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
import Margin from '../components/Margin';
import { useScaleTicketingContext } from '../Context';
import LiveWeight from './LiveWeight';
import ManualWeight from './ManualWeight';
import ManualToggle from './vehicle-weights/ManualToggle';

const Styled = styled.div`
  height: 110px;
  margin: 20px;
  border: 3px solid #434343;
  padding: 10px 20px 20px 20px;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const WeightCapture = () => {
  const { form } = useScaleTicketingContext();

  return (
    <Styled className={cn('live-weight')}>
      <ManualToggle />
      <Margin top={10}>{form?.liveWeight ? <LiveWeight /> : <ManualWeight />}</Margin>
    </Styled>
  );
};

export default WeightCapture;
