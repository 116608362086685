import TrackingView from './tracking-view';
import ResyncData from './resync-data';
import VehiclesOutOfService from './vehicles-out-of-service';
import VehiclesInYard from './vehicles-in-yard';
import DeadheadVehicles from './deadhead-vehicles';
import OrdersToLoad from './orders-to-load';
import ReturningVehicles from './returning-vehicles';
import { BarGraph } from '../../../../components/bar-graph';
import withViewBuilder from '../components/withViewBuilder';
import EmbeddedMap from './embedded-map';
import VehicleAlerts from './vehicle-alerts';
import listAllVehicles from './list-all-vehicles';
import OrderRquests from './order-requests';

export default {
  'tracking-view': withViewBuilder(TrackingView),
  'list-all-vehicles': withViewBuilder(listAllVehicles),
  'vehicles-out-of-service': withViewBuilder(VehiclesOutOfService),
  'orders-to-load': withViewBuilder(OrdersToLoad),
  'vehicles-in-yard': withViewBuilder(VehiclesInYard),
  'deadhead-vehicles': withViewBuilder(DeadheadVehicles),
  'returning-vehicles': withViewBuilder(ReturningVehicles),
  'vehicle-alerts': VehicleAlerts,
  'embedded-map': EmbeddedMap,
  'resync-data': ResyncData,
  'schedule-graph': BarGraph,
  'order-requests': withViewBuilder(OrderRquests),
};
