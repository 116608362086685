import { cloneDeep, get, isEmpty, isUndefined, set } from 'lodash';
import React from 'react';
import { useIntl } from 'react-intl';

export const useTranslateObjects = () => {
  const intl = useIntl();

  const translateObjects = React.useCallback(
    (objects, options) => {
      if (!options) return null;

      const objectArray = [].concat(cloneDeep(objects));
      const optionsArray = [].concat(options);

      if (!objectArray?.length || !optionsArray?.length) return null;
      return objectArray.map(object => {
        optionsArray.forEach(option => {
          const getStringId = option?.getStringId || (value => value);
          const getPath = option?.getPath;
          const setPath = option?.setPath;
          const defaultMessagePath = option?.defaultMessagePath;

          const values = option?.valuesFrom?.reduce?.((acc, key) => {
            acc[key] = get(object, key);
            return acc;
          }, {});

          if (!isUndefined(get(object, getPath))) {
            set(
              object,
              setPath,
              intl.formatMessage(
                {
                  id: getStringId(get(object, getPath)),
                  defaultMessage: get(object, defaultMessagePath),
                },
                isEmpty(values) ? undefined : values
              )
            );
          }
        });
        return object;
      });
    },
    [intl]
  );

  return { translateObjects };
};
