import React from 'react';
import { useUserContext } from './user-context/UserContextProvider';

export const permissionsMapping = {
  showPricing: 'enable_rmx-dispatch_orders_view-order-pricing',
  editPricing: 'enable_rmx-dispatch_orders_edit-pricing',
  createOrder: 'enable_rmx-dispatch_orders_create-order',
  dispatchOrders: 'enable_rmx-dispatch_orders',
  overrideCreditHold: 'enable_rmx-dispatch_orders_override-credit-hold',
};

const Has = ({ permission, children, not, paPSOverride = true, caOverride = true }) => {
  const {
    permissions: { authorities, groups, assignedRoles },
  } = useUserContext();

  const isPaPs = React.useMemo(() => {
    return groups.includes('admin') || groups.includes('support');
  }, [groups]);

  const isCompanyAdmin = React.useMemo(() => {
    return !!assignedRoles?.['company-admin'];
  }, [assignedRoles]);

  const hasPermission = React.useMemo(() => {
    return !!authorities[permissionsMapping[permission]] || (paPSOverride && isPaPs) || (caOverride && isCompanyAdmin);
  }, [authorities, caOverride, isCompanyAdmin, isPaPs, paPSOverride, permission]);

  if (not) {
    return !hasPermission ? children : null;
  }

  return hasPermission ? children : null;
};

export default Has;
