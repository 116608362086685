import { Input as AntInput } from 'antd';
import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';

const Input = ({ children, innerRef, readOnly, className, ...props }) => {
  return <AntInput ref={innerRef} {...props} className={cn(className, 'xxx', { readOnly })} />;
};

Input.TextArea = AntInput.TextArea;
Input.Search = AntInput.Search;

export default styled(Input)`
  &&& {
    &:disabled {
      &.readOnly {
        color: hsla(0, 0%, 100%, 0.85) !important;
        background-color: transparent;
      }
    }
    &.readOnly {
      input:disabled {
        color: hsla(0, 0%, 100%, 0.85) !important;
        background-color: transparent;
      }
    }
  }
`;
