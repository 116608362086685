import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
import { Translate } from '../../../views/order/components/localization/Translate';
import style from './style';

const Styled = styled.div`
  ${style}
`;

const OrderCard = ({ item }) => {
  return (
    <Styled className={cn('order-card')}>
      <div className="order-info">
        <div className="order-id">
          <Translate stringId="orderNumber" defaultMessage="Order #" />: {item.id}
        </div>
        <div className="customer-name">
          <Translate stringId="customer" defaultMessage="Customer" />: {item.customerParty?.name}
        </div>
        <div className="location-name">
          <Translate stringId="location" defaultMessage="Location" />: {item.location?.name}
        </div>
        <div className="project-name">
          <Translate stringId="project" defaultMessage="Project" />: {item.project?.name}
        </div>
      </div>
      <div className="loads">
        <table>
          <tr>
            <th style={{ textTransform: 'uppercase' }}>
              <Translate stringId="tableHeading_loadNumber" defaultMessage="LOAD #" />
            </th>
            <th style={{ textTransform: 'uppercase' }}>
              <Translate stringId="qty" defaultMessage="QTY" />
            </th>
            <th style={{ textTransform: 'uppercase' }}>
              <Translate stringId="total" defaultMessage="TOTAL" />
            </th>
          </tr>
          {item.loads?.map?.(load => {
            return (
              <tr className="load">
                <td>{load.loadNumber}</td>
                <td>{load.quantity}</td>
                <td>{`${load.totalQuantity} / ${item?.deliverySchedule?.deliveryProgress?.orderedQuantity}`}</td>
              </tr>
            );
          })}
        </table>
      </div>
    </Styled>
  );
};

export default OrderCard;
