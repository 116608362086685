import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';
import StandardOrSmall from '../../../../StandardOrSmall';
import { useOrderContext } from '../../../../useOrderContext';
import ProductEntrySmall from './small-screen/ProductEntrySmall';
import StandardAggregateProductEntry from './standard-screen/StandardAggregateProductEntry';

const Styled = styled.div``;

const ProductEntryAggregate = ({ className = '', handleChange, primaryLineItem, otherLineItems }) => {
  const {
    form: { form },
    order: { readOnly },
    saveOrderBusy,
  } = useOrderContext();

  const disabled = React.useMemo(() => !form?.locationRef || saveOrderBusy, [form?.locationRef, saveOrderBusy]);

  const [lineItemBeingEdited, setLineItemBeingEdited] = React.useState(null);

  const initiateEdit = React.useCallback(
    id => {
      if (!disabled) {
        setLineItemBeingEdited(id);
      }
    },
    [disabled]
  );

  React.useEffect(() => {
    if (disabled) {
      setLineItemBeingEdited(null);
    }
  }, [disabled]);

  const handleEditCancel = React.useCallback(() => {
    setLineItemBeingEdited(null);
  }, []);

  const handleUpdateAggregateLineItem = React.useCallback(
    item => {
      handleChange('primaryLineItem', item);
      setLineItemBeingEdited(null);
    },
    [handleChange]
  );

  const handleUpdateOtherLineItems = React.useCallback(
    item => {
      if (item._id) {
        handleChange(
          'otherLineItems',
          otherLineItems.map(p => (p._id === item._id ? item : p))
        );
      } else {
        handleChange('otherLineItems', [...otherLineItems, { ...item, _id: uuid() }]);
      }
      setLineItemBeingEdited(null);
    },
    [handleChange, otherLineItems]
  );

  const handleDeleteRow = React.useCallback(
    id => {
      handleChange(
        'otherLineItems',
        otherLineItems.filter(item => item?._id !== id)
      );
    },
    [handleChange, otherLineItems]
  );

  const handleAddRow = React.useCallback(() => {
    handleChange('otherLineItems', [...otherLineItems, { _id: uuid() }]);
  }, [handleChange, otherLineItems]);

  return (
    <Styled className={cn('product-entry', className)}>
      <StandardOrSmall breakpoint="800">
        <StandardAggregateProductEntry
          aggregateLineItem={primaryLineItem}
          otherLineItems={otherLineItems}
          handleUpdateAggregateLineItem={handleUpdateAggregateLineItem}
          handleUpdateOtherLineItems={handleUpdateOtherLineItems}
          handleDeleteRow={handleDeleteRow}
          disabled={disabled}
          onAddRow={handleAddRow}
          readOnly={readOnly}
          tickets={form?.tickets}
        />
        <ProductEntrySmall
          lineItem={primaryLineItem}
          otherLineItems={otherLineItems}
          lineItemBeingEdited={lineItemBeingEdited}
          handleEditCancel={handleEditCancel}
          handleUpdateAggregateLineItem={handleUpdateAggregateLineItem}
          handleUpdateOtherLineItems={handleUpdateOtherLineItems}
          handleDeleteRow={handleDeleteRow}
          initiateEdit={initiateEdit}
          disabled={disabled}
          readOnly={readOnly}
          tickets={form?.tickets}
        />
      </StandardOrSmall>
    </Styled>
  );
};

export default ProductEntryAggregate;
