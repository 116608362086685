import { css } from 'styled-components';

export default css`
  margin: 10px 2px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
  grid-template-areas:
    'row_1'
    'row_2';
  overflow: auto;
  .row-1 {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-area: row_1;
    grid-gap: 20px;
    margin-top: 10px;
  }

  .row-2 {
    grid-area: row_2;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 20px;
    margin-bottom: 20px;
  }

  .primary {
    grid-area: primary;
  }
  .field-column {
    & > * {
      margin-top: 5px;
    }
  }

  .field-row {
    display: flex;
  }

  .dispatchBackgroundColor {
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
  }

  .dispatchMultipleDates {
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
  }

  .vehicle-color-fields {
    flex-direction: row;
    display: flex;
    gap: 10px;
    flex: 1;
    .label {
      margin-bottom: 0px;
    }
  }

  .creditHoldWarning {
    .warningText {
      background-color: red;
      color: white;
      padding: 5px 10px;
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 5px;
      border-radius: 4px;

      &.soft {
        background-color: #df4e39;
      }
    }
  }

  .tax-section {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-area: row_1;
    gap: 10px;
    flex: 1;
    .label {
      margin-bottom: 0px;
    }

    @media (max-width: 1300px) {
      display: block;
      > * {
        margin-bottom: 5px;
      }
    }

    .select-tax-exempt {
      grid-column: 2;
      grid-row: 2;
    }
  }
`;
