import style from "../style";
import React from "react";
import styled from 'styled-components';
import InputText from "../../components/input-text";
import Button from "../../../../components/button/Button";

const formatFeeAmount = (amount, typeId) => {
  switch (typeId) {
    case 'percentageOfPretaxTotal':
      return `${Math.round(amount * 10000) / 100}%`;
    default:
      return `${amount}`;
  }
};

const Styled = styled.div`
  ${style}
`;

const ManageFees = ({ item: paymentMethod, onChange }) => {
  const [showForm, setShowForm] = React.useState(false);
  const [feeName, setFeeName] = React.useState('');
  const [feeAmount, setFeeAmount] = React.useState('0');

  const fees = React.useMemo(() => {
    return paymentMethod?.fees ?? [];
  }, [paymentMethod]);

  const removeFee = index => {
    if (onChange) {
      const remainingFees = fees.filter((_, idx) => idx !== index);
      onChange('fees', remainingFees);
    }
  };

  const addFee = () => {
    if (onChange && feeName && feeAmount && !isNaN(+feeAmount)) {
      const appendedFees = fees.concat([
        {
          feeTypeId: 'percentageOfPretaxTotal', // only type initially
          name: feeName,
          amount: +feeAmount * 0.01,
        },
      ]);
      onChange('fees', appendedFees);
      resetForm();
    }
  };

  const resetForm = () => {
    setFeeName('');
    setFeeAmount('0');
    setShowForm(false);
  };

  const feeList = fees.map((fee, index) => {
    return (
      <div className="fee-wrapper" key={`fee${index}`}>
        <div>{fee.name}</div>
        <div>{formatFeeAmount(fee.amount, fee.feeTypeId)}</div>
        <Button onClick={() => removeFee(index)} className="no-margin" size="small">
          Remove
        </Button>
      </div>
    );
  });

  const addFeeButton = (
    <div>
      <Button onClick={() => setShowForm(true)} className="no-margin">Add Fee</Button>
    </div>
  );

  const onFormChange = (id, value) => {
    switch (id) {
      case 'fee-name':
        setFeeName(value);
        break;
      case 'fee-amount':
        setFeeAmount(value);
        break;
      default:
    }
  };

  const createFeeForm = (
    <div className="create-fee-form">
      <InputText id="fee-name" value={feeName} onChange={onFormChange} label="Name" />
      <InputText id="fee-amount" value={feeAmount} onChange={onFormChange} label="Amount (%)" />

      <Button onClick={() => resetForm()} className="form-action-button">
        Cancel
      </Button>
      <Button onClick={() => addFee()} className="form-action-button">
        Add Fee
      </Button>
    </div>
  );

  return (
    <Styled>
      <div className="manage-fees-container">
        {feeList}
        {!showForm && addFeeButton}
        {showForm && createFeeForm}
      </div>
    </Styled>
  );
};

export default ManageFees;
