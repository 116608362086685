import React from 'react';
import { Marker, Polyline } from '@react-google-maps/api';

const DevicePath = ({ path, color = 0 }) => {
  const colors = ['red', 'blue', 'green', 'orange', 'purple', 'yellow', 'brown'];

  return (
    <>
      <Polyline
        path={path}
        geodesic
        options={{
          strokeColor: colors[color],
          strokeOpacity: 0.75,
          strokeWeight: 4,
        }}
      />
    </>
  );
};

export default DevicePath;
