import { useCallback } from 'react';
import { useMapStateContext } from '../context/MapStateContext';
import { useCalculateRoute } from './routing/useCalculateRoute';
import { useFitBounds } from './useFitBounds';
import { v4 as uuid } from 'uuid';

export const useWaypoints = () => {
  const { waypoints, effectiveCoordinates, origin, allowRouting, setWaypoints } = useMapStateContext();

  const { calculateRoute } = useCalculateRoute();
  const { fitBounds } = useFitBounds();

  const deleteWaypoint = useCallback(
    async id => {
      if (allowRouting) {
        const _waypoints = waypoints.filter(m => m.sequence !== id);

        const newRoute = await calculateRoute({
          waypoints: _waypoints,
          coordinates: effectiveCoordinates,
        });

        fitBounds(origin, effectiveCoordinates, newRoute, _waypoints);

        setWaypoints(_waypoints);
      }
    },
    [allowRouting, waypoints, calculateRoute, effectiveCoordinates, fitBounds, origin, setWaypoints]
  );

  const updateWaypoint = useCallback(
    async updatedWaypoint => {
      const _waypoints = waypoints.filter(s => s.id !== updatedWaypoint.id);
      _waypoints.push(updatedWaypoint);

      const newRoute = await calculateRoute({
        waypoints: _waypoints,
        coordinates: effectiveCoordinates,
      });

      fitBounds(origin, effectiveCoordinates, newRoute, _waypoints);

      setWaypoints(_waypoints);
    },
    [waypoints, calculateRoute, effectiveCoordinates, fitBounds, origin, setWaypoints]
  );

  const addWaypoint = useCallback(
    coordinates => {
      setWaypoints(s => {
        if (s.length < 10) {
          return [
            ...s,
            {
              id: `waypoint-${uuid()}`,
              icon: 'default',
              color: 'yellow',
              lat: coordinates.lat,
              lng: coordinates.lng,
              draggable: true,
              type: 'waypoint',
              sequence: s.length + 1,
              info: [{ name: 'Waypoint', desc: s.length + 1 }],
              status: 'pending',
            },
          ];
        }
        return s;
      });
    },
    [setWaypoints]
  );

  return { deleteWaypoint, updateWaypoint, addWaypoint };
};
