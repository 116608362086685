import React from 'react';
import {FILTER_STRATEGY} from "../../../components/connex-data-grid/util";

export const prototype = {
  id: '',
  name: '',
  status: 'ACTIVE',
};

export const columns = [
  {
    key: 'id',
    name: 'ID',
  },
  {
    key: 'name',
    name: 'Name',
  },
  {
    key: 'status',
    name: 'Status',
    formatter: props => props.row.vehicleStatus?.statusCode || props.row.status || '',
    filterFn: (item, filterStrings) => {
      if (!filterStrings[0]) return true;
      return item === filterStrings[0];
    },
    filterComponent: ({ innerRef, ...props }) => {
      return (
        <select ref={innerRef} {...props}>
          <option value="" />
          <option value="ACTIVE">Active</option>
          <option value="INACTIVE">Inactive</option>
        </select>
      );
    },
  },
  {
    key: 'defaultValue',
    name: 'Default',
    getValue: item => (item?.defaultValue === true ? 'true' : ''),
  },
];
