import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { api } from './api';
import Logic from 'json-logic-js';
import moment from 'moment';
import { printPDFDocument } from '../../util/printPDFDocument';
import calculateAboveShippedAmount from '../../order-list/components/order-cards/ticket-details/util/calculate-above-shipped-amount';

export const useTicket = () => {
  const { entityRef } = useParams();

  const [order, setOrder] = useState(null);
  const [rules, setRules] = useState(null);
  const [orderBusy, setOrderBusy] = useState(false);
  const [carrierBusy, setCarrierBusy] = useState(false);
  const [vehicleBusy, setVehicleBusy] = useState(false);
  const [driversBusy, setDriversBusy] = useState(false);
  const [ticketBusy, setTicketBusy] = useState(false);
  const [rulesBusy, setRulesBusy] = useState(false);
  const [quantities, setQuantities] = useState({});
  const [associatedItems, setAssociatedItems] = useState([]);

  const getOrder = useCallback(
    (orderCrn, queryParams) => {
      setOrder(null);
      setOrderBusy(true);

      return api
        .getOrder(entityRef, orderCrn, queryParams)
        .then(setOrder)
        .finally(() => {
          setOrderBusy(false);
        });
    },
    [entityRef]
  );

  const createOrderTicket = useCallback(
    (orderRef, params) => {
      setTicketBusy(true);

      return api
        .createOrderTicket(entityRef, orderRef, params)
        .then(response => {
          if (response.Link) {
            printPDFDocument(response.Link);
          } else if (response.viewShipmentsLink) {
            window.open(response.viewShipmentsLink, '_blank', 'noreferrer');
          }
        })
        .finally(() => {
          setOrder(null);
          setTicketBusy(false);
          setQuantities({});
        });
    },
    [entityRef]
  );

  const listOrderCarriers = useCallback(
    orderRef => {
      setCarrierBusy(true);

      return api.listOrderCarriers(entityRef, orderRef).finally(() => {
        setCarrierBusy(false);
      });
    },
    [entityRef]
  );

  const listOrderVehicles = useCallback(
    (orderRef, queryParams) => {
      setVehicleBusy(true);

      return api.listOrderVehicles(entityRef, orderRef, queryParams).finally(() => {
        setVehicleBusy(false);
      });
    },
    [entityRef]
  );

  const listDrivers = useCallback(() => {
    setDriversBusy(true);
    return api.getDrivers(entityRef).finally(() => setDriversBusy(false));
  }, [entityRef]);

  const getRules = useCallback(
    triggerPoint => {
      setRulesBusy(true);

      return api
        .getRules(entityRef, triggerPoint)
        .then(setRules)
        .finally(() => {
          setRulesBusy(false);
        });
    },
    [entityRef]
  );

  const applyRules = useCallback(() => {
    const primaryProduct = order.lineItems?.find(p => p.isPrimary);

    if (primaryProduct) {
      const today = moment().toISOString();
      const quantity =
        quantities?.[primaryProduct?.item?.productRef] ?? order?.deliverySchedule?.schedule?.[0]?.quantity ?? 10;

      const context = {
        customerRef: order.customerParty?.entityRef,
        productTypeId: primaryProduct.item?.type,
        productRef: primaryProduct.item?.productRef,
        quantity,
        orderDate: order.deliverySchedule?.startDateTime?.substr(0, 10),
        dispatchDate: today.substr(0, 10),
        originId: order?.location?.id,
      };

      const associated = [];

      for (const rule of rules) {
        const { ruleType, productRef } = rule;

        if (ruleType === 'ASSOCIATED_PRODUCT') {
          if (productRef) {
            const { triggerLogic, product } = rule;
            if (!triggerLogic) {
              continue;
            }
            if (product && Logic.apply(triggerLogic, context)) {
              if (!order.lineItems?.some(p => p.item?.productRef === productRef)) {
                const { id, name, type, uomCode } = product;

                associated.push({
                  isPrimary: false,
                  isAdded: true,
                  item: {
                    productRef,
                    id,
                    name,
                    type,
                    uomCode,
                  },
                  orderedQuantity: {
                    value: rule.quantity ?? 1,
                    uomCode,
                  },
                  properties: {},
                });
              }
            }
          }
        }
      }

      if (associated.length > 0) {
        setAssociatedItems(associated);
      }
    }
  }, [rules, order, quantities]);

  useEffect(() => {
    if (order && rules && quantities) {
      applyRules();
    }
  }, [order, rules, quantities, applyRules]);

  const aboveShippedAmount = useMemo(() => {
    const primaryProduct = order?.lineItems?.find(p => p.isPrimary);
    if (!primaryProduct) return 0;
    return calculateAboveShippedAmount(order, quantities?.[primaryProduct?.item?.productRef]);
  }, [order, quantities]);

  return {
    entityRef,

    getOrder,
    order,
    orderBusy,

    createOrderTicket,
    ticketBusy,

    listOrderCarriers,
    carrierBusy,

    listOrderVehicles,
    vehicleBusy,

    listDrivers,
    driversBusy,

    getRules,
    rules,
    rulesBusy,
    applyRules,
    associatedItems,

    quantities,
    setQuantities,
    aboveShippedAmount,
  };
};
