import { DateTime } from 'luxon';

// TODO: get timezone

export const getToday = (timeZone = 'America/Chicago') => {
  const today = DateTime.now().setZone(timeZone, { keepLocalTime: true });
  return {
    firstStartDateTime: today.startOf('day').toUTC().toISO(),
    lastStartDateTime: today.endOf('day').toUTC().toISO(),
  };
};

export const getTomorrow = (timeZone = 'America/Chicago') => {
  const tomorrow = DateTime.now().setZone(timeZone, { keepLocalTime: true }).plus({ days: 1 });
  return {
    firstStartDateTime: tomorrow.startOf('day').toUTC().toISO(),
    lastStartDateTime: tomorrow.endOf('day').toUTC().toISO(),
  };
};

export const isTomorrow = (range, timeZone = 'America/Chicago') => {
  if (!range) {
    return false;
  }
  const tomorrowDate = DateTime.fromISO(getTomorrow().firstStartDateTime).setZone(timeZone);
  const firstStartDateTime = DateTime.fromISO(range.firstStartDateTime).setZone(timeZone);
  const lastStartDateTime = DateTime.fromISO(range.lastStartDateTime).setZone(timeZone);

  return (
    tomorrowDate.toISODate() === firstStartDateTime.toISODate() &&
    firstStartDateTime.toISODate() === lastStartDateTime.toISODate() &&
    !isFuture(range, timeZone)
  );
};

export const isToday = (range, timeZone = 'America/Chicago') => {
  if (!range) {
    return false;
  }

  const todayDate = DateTime.fromISO(getToday().firstStartDateTime).setZone(timeZone);
  const firstStartDateTime = DateTime.fromISO(range.firstStartDateTime).setZone(timeZone);
  const lastStartDateTime = DateTime.fromISO(range.lastStartDateTime).setZone(timeZone);

  return (
    todayDate.toISODate() === firstStartDateTime.toISODate() &&
    firstStartDateTime.toISODate() === lastStartDateTime.toISODate() &&
    !isFuture(range, timeZone)
  );
};

export const isFuture = (range, timeZone = 'America/Chicago') => {
  if (!range) {
    return false;
  }

  const firstStartDateTime = DateTime.fromISO(range.firstStartDateTime).setZone(timeZone).plus({ days: 30 });
  const lastStartDateTime = DateTime.fromISO(range.lastStartDateTime).setZone(timeZone);
  return (
    firstStartDateTime.day === lastStartDateTime.day &&
    firstStartDateTime.month === lastStartDateTime.month &&
    firstStartDateTime.year === lastStartDateTime.year
  );
};
