import React from 'react';
import { DateTime } from 'luxon';
import { Currency } from '../../../order/components/localization/Currency';
import { useShortDate } from '../../../order/components/localization/ShortDate';
import Button from '../../../../components/button/Button';
import { Translate } from '../../../order/components/localization/Translate';
import { useUserContext } from '../../../order/components/user-context/UserContextProvider';

export const useColumns = () => {
  const user = useUserContext();

  const { getShortDate } = useShortDate();

  return React.useMemo(() => {
    const columns = [
      {
        key: 'invoiceNumber',
        name: 'Invoice',
        width: 141,
        frozen: true,
        formatter: props => <div style={{ textAlign: 'center' }}>{props.row.invoiceNumber}</div>,
      },
      {
        key: 'orderId',
        name: 'Order',
        width: 115,
        frozen: true,
        formatter: props => {
          return props.row.orderId || '';
        },
      },
      {
        key: 'un-invoice',
        name: 'Un-invoice',
        resizable: false,
        frozen: true,
        export: false,
        width: 90,
        groupFormatter: ({ childRows, row }) => {
          if (row?.parentId) return '';

          const internalRow = childRows[0];
          return (
            <Button
              style={{ width: '100%' }}
              size="small"
              onClick={() => {
                internalRow?.openDrawerHandler?.();
              }}
            >
              <Translate stringId="invoices.un-invoice" defaultMessage="Un-invoice" />
            </Button>
          );
        },
        filterFn: () => true,
        formatter: () => '',
      },
      {
        key: 'ticketId',
        name: 'Ticket',
        width: 115,
      },
      {
        key: 'date',
        name: 'Date',
        width: 200,
        groupFormatter: ({ childRows, row }) => {
          try {
            // try the new way first
            return getShortDate(
              row?.parentId ? childRows?.[0]?.startDate : new Date(childRows?.[0]?.invoiceDate).toISOString()
            );
          } catch (e) {
            // ignore
          }
          // if unsuccessful, try the old way
          const startDate = DateTime.fromISO(childRows?.[0]?.startDate).toFormat('MM-dd-yyyy');
          return row?.parentId ? (startDate || '')?.slice?.(0, 10) : (childRows?.[0]?.invoiceDate || '').slice(0, 10);
        },
        formatter: props => {
          try {
            return getShortDate(props?.row?.dispatchDate);
          } catch (e) {
            const dispatchDate = DateTime.fromISO(props?.row?.dispatchDate);
            return dispatchDate.isValid ? dispatchDate.toFormat('MM-dd-yyyy') : '';
          }
        },
        exportColumnNames: ['Invoice Date', 'Order Date', 'Dispatch Date'],
        export: row => {
          const invoiceDate = (row?.invoiceDate || '').slice(0, 10);
          const orderDate = DateTime.fromISO(row.startDate)?.toFormat('MM-dd-yyyy') || '';
          const dispatchDate = DateTime.fromISO(row.dispatchDate)?.toFormat('MM-dd-yyyy') || '';

          return [invoiceDate, orderDate, dispatchDate];
        },
      },
      {
        key: 'invoiceDueDate',
        name: 'Due Date',
        groupFormatter: ({ childRows, row }) => {
          try {
            // Attempt the new way first
            const date = new Date(childRows?.[0]?.invoiceDueDate).toISOString();
            return row?.parentId ? '' : getShortDate(date);
          } catch (e) {
            //ignore
          }
          // If unsuccessful, try the old way
          return row?.parentId ? '' : childRows?.[0]?.invoiceDueDate || '';
        },
        formatter: () => '',
      },
      {
        key: 'customer',
        name: 'Customer',
        resizable: true,
        sortKey: 'customerId',
        filterKey: ['customerId', 'customerName'],
        export: row => `${row.customerId} / ${row.customerName}`,
        groupFormatter: ({ childRows, row }) => (
          <>
            {row?.parentId
              ? ''
              : childRows?.length
              ? `${childRows?.[0]?.customerId || ''}${
                  childRows?.[0]?.customerId && childRows?.[0]?.customerName ? ' / ' : ''
                }${childRows?.[0]?.customerName || ''}`
              : ''}
          </>
        ),
        formatter: () => <div />,
      },
      {
        key: 'destination',
        name: 'Destination',
        formatter: () => <div />,
        groupFormatter: ({ childRows, row }) => (row?.parentId ? childRows[0]?.destination || '' : ''),
      },
      {
        key: 'salesPerson',
        name: 'Sales Person',
        formatter: () => <div />,
        groupFormatter: ({ childRows, row }) => (row?.parentId ? childRows[0]?.salesPerson || '' : ''),
      },
      {
        key: 'subtotalPrice',
        name: 'Subtotal',
        headerClassName: 'align-right',
        formatter: props => {
          return (
            <div className="align-right">
              <Currency value={props?.row?.totalPrice - props?.row?.salesTax || 0} currency="USD" />
            </div>
          );
        },
        groupFormatter: ({ childRows, row }) => {
          const value = childRows.reduce((acc, { totalPrice, salesTax }) => acc + (totalPrice - salesTax), 0);
          return (
            <div className="align-right">
              <Currency value={value} currency="USD" />
            </div>
          );
        },
      },
      {
        key: 'cityTax',
        name: 'City Tax',
        headerClassName: 'align-right',
        formatter: props => {
          return (
            <div className="align-right">
              <Currency value={props?.row?.cityTax || 0} currency="USD" />
            </div>
          );
        },
        groupFormatter: ({ childRows, row }) => {
          const value = childRows.reduce((acc, { cityTax }) => acc + cityTax, 0);
          return (
            <div className="align-right">
              <Currency value={value} currency="USD" />
            </div>
          );
        },
      },
      {
        key: 'countyTax',
        name: 'County Tax',
        headerClassName: 'align-right',
        formatter: props => {
          return (
            <div className="align-right">
              <Currency value={props?.row?.countyTax || 0} currency="USD" />
            </div>
          );
        },
        groupFormatter: ({ childRows }) => {
          const value = childRows.reduce((acc, { countyTax }) => acc + countyTax, 0);
          return (
            <div className="align-right">
              <Currency value={value || 0} currency="USD" />
            </div>
          );
        },
      },
      {
        key: 'districtTax',
        name: 'District Tax',
        headerClassName: 'align-right',
        formatter: props => {
          return (
            <div className="align-right">
              <Currency value={props?.row?.districtTax || 0} currency="USD" />
            </div>
          );
        },
        groupFormatter: ({ childRows }) => {
          const value = childRows.reduce((acc, { districtTax }) => acc + districtTax, 0);
          return (
            <div className="align-right">
              <Currency value={value || 0} currency="USD" />
            </div>
          );
        },
      },
      {
        key: 'stateTax',
        name: 'State Tax',
        headerClassName: 'align-right',
        groupFormatter: ({ childRows }) => {
          const value = childRows.reduce((acc, { stateTax }) => acc + stateTax, 0);
          return (
            <div className="align-right">
              <Currency value={value || 0} currency="USD" />
            </div>
          );
        },
        formatter: props => {
          return (
            <div className="align-right">
              <Currency value={props?.row?.stateTax || 0} currency="USD" />
            </div>
          );
        },
      },
      {
        key: 'salesTax',
        name: 'Total Tax',
        headerClassName: 'align-right',
        groupFormatter: ({ childRows }) => {
          const value = childRows.reduce((acc, { salesTax }) => acc + salesTax || 0, 0);
          return (
            <div className="align-right">
              <Currency value={value || 0} currency="USD" />
            </div>
          );
        },
        formatter: props => {
          return (
            <div className="align-right">
              <Currency value={props?.row?.salesTax || 0} currency="USD" />
            </div>
          );
        },
      },
      {
        key: 'totalPrice',
        name: 'Total',
        headerClassName: 'align-right',
        groupFormatter: ({ childRows }) => {
          const value = childRows.reduce((acc, { totalPrice }) => acc + totalPrice || 0, 0);
          return (
            <div className="align-right">
              <Currency value={value} currency="USD" />
            </div>
          );
        },
        formatter: props => {
          return (
            <div className="align-right">
              <Currency value={props?.row?.totalPrice || 0} currency="USD" />
            </div>
          );
        },
      },
      {
        key: 'recipients',
        name: 'Email To',
        resizable: true,
        sortKey: 'recipients',
        filterKey: ['recipients'],
        export: row => `${row.recipients}`,
        groupFormatter: ({ childRows, row }) => (
          <>{row?.parentId ? '' : childRows?.length ? `${childRows?.[0]?.recipients || ''}` : ''}</>
        ),
        formatter: () => <div />,
      },
      {
        key: 'documentRef',
        name: 'Invoice (PDF)',
        filterable: false,
        width: 125,
        headerClassName: 'centered',
        export: false,
        groupFormatter: ({ childRows, row }) => {
          return row?.parentId ? (
            ''
          ) : (
            <div style={{ textAlign: 'center' }}>
              {childRows?.[0]?.documentRef && (
                <>
                  <a target="_blank" href={childRows?.[0]?.documentRef}>
                    <i className="fa fa-file-pdf" />
                  </a>
                  {childRows?.[0]?.recipients && <i className="fa fa-envelope resend-emails" title="Resend Emails" />}
                </>
              )}
            </div>
          );
        },
        formatter: props => '',
      },
      {
        key: 'exported',
        name: 'Exported',
        hidden: true,
        groupFormatter: ({ childRows, row }) => (row?.parentId ? '' : childRows[0]?.exported ? 'true' : ''),
        formatter: () => <div />,
      },
      {
        key: 'exportedDate',
        name: 'Exported Date',
        hidden: false,
        groupFormatter: ({ childRows, row }) => {
          try {
            // Attempt the new way first
            return row?.parentId ? '' : getShortDate(childRows?.[0]?.exportedDate);
          } catch (e) {
            //ignore
          }
          // If unsuccessful, try the old way
          const dt = childRows?.[0]?.exportedDate
            ? DateTime.fromISO(childRows?.[0]?.exportedDate)?.toFormat('MM-dd-yyyy')
            : '';
          return row?.parentId ? '' : dt || '';
        },
        formatter: () => <div />,
      },
    ];

    return user.hasPermissions('authorities.enable_rmx-dispatch_invoicing_un-invoice')
      ? columns
      : columns.filter(c => c.key !== 'un-invoice');
  }, [getShortDate, user]);
};
