import React from 'react';
import { useMapStateContext } from '../../context/MapStateContext';

export const useGeofence = () => {
  const { setEffectiveRadius } = useMapStateContext();

  const onGeofenceChange = React.useCallback(
    value => {
      setEffectiveRadius(value);
    },
    [setEffectiveRadius]
  );

  return { onGeofenceChange };
};
