import { set } from 'lodash';
import { useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import ErrorModal from '../../../components/error-modal/ErrorModal';
import { useModalContext } from '../../../components/modal-context/ModalContext';
import { MODES } from '../components/order-form/OrderForm';
import { useCommonOrderBehavior } from '../components/useCommonOrderBehavior';
import generateCleanedUpFormData from '../util/generate-cleaned-up-form-data';
import { isNil } from 'lodash';
import BatchCreateResults from '../components/batch-create-results';

export const useCopyOrderBehavior = (orderRef, orderType) => {
  const commonOrderBehavior = useCommonOrderBehavior(orderType);
  const {
    order: { getOrder, order, createOrder, createMultipleOrdersByDate, orderBusy },
    form: { form, setForm, auditNotes },
    masterData: { masterData },
    defaults: { defaults },
    start: { startOrder },
  } = commonOrderBehavior;

  const navigate = useNavigate();
  const { openModal } = useModalContext();

  useEffect(() => {
    // getOrder needs to finish before startOrder.
    getOrder(orderRef, { copy: true }).then(() => {
      startOrder();
    });
  }, [getOrder, orderRef, startOrder]);

  useEffect(() => {
    if (order) {
      setForm(order);
      // hash.initialize(order);
    }
  }, [order, setForm]);

  // apply defaults
  useEffect(() => {
    if (defaults) {
      setForm(
        s => ({
          ...s,
          supplierStatusRef: defaults?.supplierStatusCodes,
        }),
        true
      );
    }
  }, [masterData, defaults, setForm]);

  // If customer changes, clear dependent fields.
  useEffect(() => {
    setForm(s => {
      if (s?.customerRef && s?.customerRef !== form?.customerRef) {
        return { ...s, projectRef: null, locationRef: null, billingLocationRef: null };
      }
      return s;
    });
  }, [form?.customerRef, setForm]);

  const navigateFunction = useCallback(
    savedOrder => {
      navigate(`../../../edit/${orderType}/${savedOrder?.crn}/general`);
    },
    [navigate, orderType]
  );

  const handleCancelClick = useCallback(() => {
    navigate('../../../');
  }, [navigate]);

  const handleCreateOrder = useCallback(() => {
    const cleanedUpFormData = generateCleanedUpFormData(form, auditNotes);

    createOrder(cleanedUpFormData)
      .then(order => {
        navigateFunction(order);
      })
      .catch(e => {
        openModal('Error', <ErrorModal error={e} />);
      });
  }, [auditNotes, createOrder, form, openModal, navigateFunction]);

  const handleCreateOrdersByDate = useCallback(() => {
    const originalStartDateTime = form?.startDateTime;

    const dateList = [...form?.exdendedDatesList?.filter(d => !isNil(d))];
    dateList?.unshift(originalStartDateTime);

    const cleanedUpFormData = generateCleanedUpFormData(form, auditNotes);

    const orders = dateList.map(date => ({
      ...cleanedUpFormData,
      startDateTime: date,
      linkedOrders: cleanedUpFormData?.linkedOrders?.map(order => {
        if (order?.startDateTime !== originalStartDateTime) {
          return order;
        }
        return {
          ...order,
          startDateTime: date,
        };
      }),
    }));

    createMultipleOrdersByDate(orders)
      .then(ordersData => {
        openModal('Results', <BatchCreateResults ordersData={ordersData} customHandler={navigateFunction} />);
      })
      .catch(e => {
        openModal('Error', <ErrorModal error={e} />);
      });
  }, [auditNotes, form, openModal, createMultipleOrdersByDate, navigateFunction]);

  const handleSaveClick = useCallback(() => {
    if (form?.exdendedDatesList?.some(e => !isNil(e))) {
      handleCreateOrdersByDate();
      return;
    }
    handleCreateOrder();
  }, [form, handleCreateOrdersByDate, handleCreateOrder]);

  const copyOrderBehavior = {
    ...commonOrderBehavior,
    handleSaveClick,
    handleCancelClick,
    mode: MODES.COPY,
    saveOrderBusy: orderBusy,
    orderType,
  };

  set(copyOrderBehavior, 'order.readOnly', false);

  return copyOrderBehavior;
};
