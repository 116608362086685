import React from 'react';
import { Circle, InfoWindow } from '@react-google-maps/api';

const Geofence = ({ editable, center, radius = 50, onGeofenceChange }) => {
  const [isVisible, setIsVisible] = React.useState(false);

  const toggleIsVisible = React.useCallback(() => {
    setIsVisible(s => !s);
  }, []);

  const handleRadiusChange = React.useCallback(
    e => {
      onGeofenceChange?.(Number.parseInt(e.currentTarget.value));
    },
    [onGeofenceChange]
  );

  return (
    <>
      <Circle
        center={center}
        radius={radius}
        options={{
          strokeColor: '#FF0000',
          strokeOpacity: 0.5,
          strokeWeight: 2,
          fillColor: '#FF0000',
          fillOpacity: 0.25,
        }}
        onClick={toggleIsVisible}
      />
      {isVisible && (
        <InfoWindow position={center} options={{ disableAutoPan: true }} onCloseClick={toggleIsVisible}>
          <div style={{ color: 'black' }}>
            <>
              {(editable && (
                <>
                  <label>
                    <b>Geofence Radius:</b>
                  </label>
                  <br />
                  <input
                    style={{ marginTop: 12 }}
                    min={0}
                    step={1}
                    type="number"
                    defaultValue={radius}
                    onChange={handleRadiusChange}
                  />{' '}
                  m <br />
                </>
              )) || (
                <div>
                  <b>Geofence Radius:</b> {radius} m
                </div>
              )}
            </>
          </div>
        </InfoWindow>
      )}
    </>
  );
};

export default Geofence;
