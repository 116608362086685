import { css } from 'styled-components';

export default css`
  color: #fff;

  table {
    thead {
      tr {
        background-color: #0e4980;

        th {
          border: 1px solid #444;
          padding: 3px 25px;
          text-align: center;
        }
      }
    }

    tbody {
      tr {
        td {
          &.adjusted {
            &:not(.unchanged) {
              background-color: rgba(215, 193, 101, 0.52);
            }

            &.unchanged {
              color: #666;
            }
          }
        }

        td {
          &:first-child {
            text-align: right;
            padding-right: 10px;
          }

          border: 1px solid #444;
          padding: 3px 25px;
          text-align: center;
        }
      }
    }
  }
`;
