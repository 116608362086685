import React, { useState } from 'react';
import { InfoWindow, Marker } from '@react-google-maps/api';
import moment from 'moment';
import markerImg from './map-icons/start-marker.png';

const StartTripMarker = ({ point }) => {
  const { ID, SampleTime: date, Longitude, Latitude, Driver } = point;
  const [showInfo, setShowInfo] = useState(false);
  const href = `https://www.google.com/maps/place/${Latitude},${Longitude}`;

  return (
    <Marker
      icon={markerImg}
      key={ID}
      onClick={() => setShowInfo(!showInfo)}
      position={{
        lat: Latitude,
        lng: Longitude,
      }}
    >
      {showInfo && (
        <InfoWindow options={{ disableAutoPan: true }} onCloseClick={() => setShowInfo(!showInfo)}>
          <div style={{ color: 'black' }}>
            <div>Start Trip</div>
            <div>{moment(date).format('MM/DD/YYYY hh:mm A z')}</div>
            {Driver && <div>Driver: {Driver}</div>}
            <div>
              <a target={'_blank'} href={href}>
                Coordinates: {Latitude},{Longitude}
              </a>
            </div>
          </div>
        </InfoWindow>
      )}
    </Marker>
  );
};

export default StartTripMarker;
