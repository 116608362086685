import { find, pick } from 'lodash';
import React from 'react';
import Select from '../../../components/form/Select';
import Margin from '../components/Margin';
import { useScaleTicketingContext } from '../Context';

const { Option } = Select;

const Driver = () => {
  const { drivers, form, handleChange } = useScaleTicketingContext();

  const handleDriverChange = React.useCallback(
    ref => {
      const targetDriver = find(drivers, { crn: ref });
      if (targetDriver) {
        handleChange('vehicle', s => ({
          ...s,
          configuration: {
            ...s.configuration,
            driver: {
              ...pick(targetDriver, ['id', 'name']),
              driverRef: targetDriver.crn,
            },
          },
        }));
      }
    },
    [handleChange, drivers]
  );

  return (
    <Margin top={10}>
      <div className="field-label">Driver</div>
      <Select
        value={form?.vehicle?.configuration?.driver?.driverRef || ''}
        showSearch
        filterOption={(input, option) => option?.children?.toLowerCase?.().indexOf(input.toLowerCase?.()) >= 0}
        disabled={false}
        onChange={handleDriverChange}
      >
        {drivers?.map(driver => (
          <Option key={driver?.crn} value={driver.crn}>
            {`${driver.id} / ${driver.name}`}
          </Option>
        ))}
      </Select>
    </Margin>
  );
};

export default Driver;
