import React from 'react';
import cn from 'classnames';
import styled from 'styled-components';
import FieldContainer from '../../field-container/FieldContainer';
import Input from '../../form/Input';

const PourType = ({ className, value }) => {
  return (
    <FieldContainer className={cn('pourType', className)}>
      <div className="label-spinner">
        <div className="label">PourType</div>
        <div className={cn('spinner', { spinning: false })}>
          <span className="fas fa-sync fa-spin" />
        </div>
      </div>
      <Input type="text" value={value || ''} disabled data-testid="pourType_input" />
    </FieldContainer>
  );
};

export default styled(PourType)``;
