import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { useIntlContext } from '../../../../i18n';
import { Translate } from '../../../../views/order/components/localization/Translate';
import { useGridContext } from '../../context/context';

const Styled = styled.div`
  text-align: center;
`;

const GridCounts = props => {
  const { rows, totalRowCount } = useGridContext();

  return (
    <Styled className={cn('grid-counts')} {...props}>
      {rows?.length < totalRowCount ? (
        <Translate
          stringId="grid.displayingXofYRows"
          defaultMessage="Displaying {rowCount} of {totalRowCount, plural, =0 {# Rows} one {# Row} other {# Rows}}"
          values={{ rowCount: rows?.length, totalRowCount }}
        />
      ) : (
        <Translate
          stringId="grid.displayingNumRows"
          defaultMessage="Displaying {totalRowCount, plural, =0 {# Rows} one {# Row} other {# Rows}}"
          values={{ totalRowCount }}
        />
      )}
    </Styled>
  );
};

export default GridCounts;
