import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import Input from '../../../components/Input';
import style from './style';

const Search = ({ className, onChange, value, innerRef, dataTestId }) => {
  const handleChange = React.useCallback(
    e => {
      onChange(e.target.value);
    },
    [onChange]
  );

  return (
    <div data-testid={dataTestId || 'input-search'} className={cn('search', className)}>
      <Input.Search onChange={handleChange} value={value} ref={innerRef} />
    </div>
  );
};

export default styled(Search)`
  ${style}
`;
