import cn from 'classnames';
import { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useForm } from '../../../../../../hooks/useForm';
import { Translate } from '../../../localization/Translate';
import { useNotes } from '../../useNotes';
import CreateNote from './CreateNote';
import Note from './Note';
import style from './style';
import { useOrderContext } from '../../../useOrderContext';

const NoteList = ({ className, notes, onDone = () => {}, orderRef, onChange }) => {
  const { form, handleChange, setForm } = useForm({ noteType: 'project' });

  const { createOrderNote, creatingOrderNote, listingOrderNotes, updateOrderNote } = useNotes();

  const { userCanEditOrders } = useOrderContext();

  const [disableUpdate, setDisableUpdate] = useState(false);

  const handleSaveNote = useCallback(() => {
    createOrderNote(orderRef, form).then(response => {
      setForm({});
      onChange('notes', [response, ...notes]);
    });
  }, [createOrderNote, form, notes, onChange, orderRef, setForm]);

  const handleUpdateNote = useCallback(
    async (noteRef, note) => {
      try {
        setDisableUpdate(true);
        const response = await updateOrderNote(orderRef, noteRef, note);
        const copyNotes = [...notes];
        const index = copyNotes.findIndex(n => n.crn === noteRef);
        copyNotes[index] = response;
        onChange('notes', copyNotes);
        setDisableUpdate(false);
      } catch (error) {
        alert(error.message);
      }
    },
    [orderRef, updateOrderNote, notes, onChange]
  );

  const busy = useMemo(() => creatingOrderNote || listingOrderNotes, [creatingOrderNote, listingOrderNotes]);

  const auditNotesExcluded = useMemo(() => notes?.filter?.(n => n.noteType !== 'audit') || [], [notes]);

  return (
    <div className={cn('note-list', className)}>
      <div className="note-list-container">
        <div className="note-list-body">
          {auditNotesExcluded.map(n => (
            <Note note={n} key={`${n.crn}`} disableUpdate={disableUpdate} updateNote={handleUpdateNote} />
          ))}
          {auditNotesExcluded.length === 0 && (
            <div className="no-notes">
              <Translate stringId="noNotesOnOrder" defaultMessage="No notes found on this order." />
            </div>
          )}
        </div>
        <div className="create-note-wrapper">
          <CreateNote
            onChange={handleChange}
            value={form}
            onSaveNote={handleSaveNote}
            busy={busy}
            userCanEditOrders={userCanEditOrders}
          />
        </div>
      </div>
    </div>
  );
};

export default styled(NoteList)`
  ${style}
`;
