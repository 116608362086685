import { css } from 'styled-components';

export default css`
  .adjust-type {
    margin-bottom: 10px;
    margin-top: 10px;
    .ant-switch {
      width: 100px;
    }
  }

  .preview-button {
    margin-top: 10px;
  }
`;
