import { DateTime } from 'luxon';

const MONDAY = 1;
const TUESDAY = 2;
const WEDNESDAY = 3;
const THURSDAY = 4;
const FRIDAY = 5;
const SATURDAY = 6;
const SUNDAY = 7;

export const dateTimeStrings = (isoString, timeZone) => {
  const dt = DateTime.fromISO(isoString).setZone(timeZone);
  if (dt?.isValid) {
    return { date: dt?.toFormat('yyyy-LL-dd'), time: dt?.toFormat('HH:mm') };
  }
  return { date: '', time: '' };
};

export const getDateTimeFromStrings = (dateString, timeString, timeZone) => {
  const [year, month, day] = dateString.split('-');
  const [hour, minute] = timeString.split(':');

  if (year && month && day && hour && minute) {
    return DateTime.fromObject({ year, month, day, hour, minute })
      .setZone(timeZone, { keepLocalTime: true })
      .toUTC()
      .toISO();
  }

  return null;
};

export const handleDateTimeShortcuts = (e, dateTime, setDateTimeStrings) => {
  if (e?.code === 'Space') {
    setDateTimeStrings(DateTime.now().plus({ minutes: 15 }).toISO());
    e.preventDefault();
  }
};

export const handleDateShortcuts = (e, dateTime, setDateTimeStrings) => {
  const currentDow = DateTime.now().weekday;

  const selectedWeekday = (dateTime || DateTime.now()).weekday;
  e.preventDefault();

  switch (e?.code) {
    case 'KeyM': {
      const nextMonday =
        currentDow <= MONDAY
          ? DateTime.local().set({ weekday: MONDAY })
          : DateTime.local().set({ weekday: MONDAY }).plus({ weeks: 1 });
      setDateTimeStrings(nextMonday.toUTC().toISO());
      break;
    }
    case 'KeyT': {
      const nextTuesday =
        currentDow <= TUESDAY
          ? DateTime.local().set({ weekday: TUESDAY }).toISO()
          : DateTime.local().set({ weekday: TUESDAY }).plus({ weeks: 1 }).toISO();

      const nextThursday =
        currentDow <= THURSDAY
          ? DateTime.local().set({ weekday: THURSDAY }).toISO()
          : DateTime.local().set({ weekday: THURSDAY }).plus({ weeks: 1 }).toISO();

      if (selectedWeekday <= MONDAY || selectedWeekday >= THURSDAY) {
        setDateTimeStrings(nextTuesday);
      } else if (selectedWeekday <= WEDNESDAY) {
        setDateTimeStrings(nextThursday);
      }
      break;
    }
    case 'KeyW': {
      const nextWednesday =
        currentDow <= WEDNESDAY
          ? DateTime.local().set({ weekday: WEDNESDAY }).toISO()
          : DateTime.local().set({ weekday: WEDNESDAY }).plus({ weeks: 1 }).toISO();

      setDateTimeStrings(nextWednesday);
      break;
    }
    case 'KeyF': {
      const nextFriday =
        currentDow <= FRIDAY
          ? DateTime.local().set({ weekday: FRIDAY }).toISO()
          : DateTime.local().set({ weekday: FRIDAY }).plus({ weeks: 1 });
      setDateTimeStrings(nextFriday);
      break;
    }
    case 'KeyS': {
      const nextSaturday =
        currentDow <= SATURDAY
          ? DateTime.local().set({ weekday: SATURDAY }).toISO()
          : DateTime.local().set({ weekday: SATURDAY }).plus({ weeks: 1 }).toISO();

      const nextSunday = DateTime.local().set({ weekday: SUNDAY }).toISO();

      if (selectedWeekday < SATURDAY || selectedWeekday === SUNDAY) {
        setDateTimeStrings(nextSaturday);
      } else if (selectedWeekday === SATURDAY) {
        setDateTimeStrings(nextSunday);
      }
      break;
    }
    default: {
    }
  }
};
