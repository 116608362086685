module.exports = {
  title: 'Windows',
  titleStringId: 'windows',
  items: [
    {
      title: 'Tracking',
      titleStringId: 'tracking',
      id: 'open_window',
      type: 'tracking-view',
      name: 'Tracking Window',
      nameStringId: 'tracking_window_name_trackingWindow',
    },
    {
      title: 'List All Vehicles',
      titleStringId: 'listAllVehicles',
      id: 'open_window',
      type: 'list-all-vehicles',
      name: 'List All Vehicles',
      nameStringId: 'tracking_window_name_listAllVehicles',
    },
    {
      title: 'Orders To Load',
      titleStringId: 'ordersToLoad',
      id: 'open_window',
      type: 'orders-to-load',
      name: 'Orders To Load',
      license: 'orders',
      nameStringId: 'tracking_window_name_ordersToLoad',
    },
    {
      title: 'Map',
      titleStringId: 'map',
      id: 'open_window',
      type: 'embedded-map',
      name: 'Map',
      nameStringId: 'tracking_window_name_map',
    },
    {
      title: 'Vehicles In Yard',
      titleStringId: 'vehiclesInYard',
      id: 'open_window',
      type: 'vehicles-in-yard',
      name: 'Vehicles In Yard',
      nameStringId: 'tracking_window_name_vehiclesInYard',
    },
    {
      title: 'Deadhead Vehicles',
      titleStringId: 'deadheadVehicles',
      id: 'open_window',
      type: 'deadhead-vehicles',
      name: 'Deadhead Vehicles',
      license: 'orders',
      nameStringId: 'tracking_window_name_deadheadVehicles',
    },
    {
      title: 'Returning Vehicles',
      titleStringId: 'returningVehicles',
      id: 'open_window',
      type: 'returning-vehicles',
      name: 'Returning Vehicles',
      license: 'orders',
      nameStringId: 'tracking_window_name_returningVehicles',
    },
    {
      title: 'Vehicles Out of Service',
      titleStringId: 'vehiclesOutOfService',
      id: 'open_window',
      type: 'vehicles-out-of-service',
      name: 'Vehicles Out of Service',
      nameStringId: 'tracking_window_name_vehiclesOutOfService',
    },
    {
      title: 'Alerts',
      titleStringId: 'alerts',
      id: 'open_window',
      type: 'vehicle-alerts',
      name: 'Alerts',
      nameStringId: 'tracking_window_name_alerts',
    },
    {
      title: 'Order Requests',
      titleStringId: 'orderRequests',
      id: 'open_window',
      type: 'order-requests',
      name: 'Order Requests',
      nameStringId: 'tracking_window_name_orderRequests',
    },
  ],
};
