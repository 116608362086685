import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
import Margin from '../components/Margin';
import Row from '../components/Row';
import { useScaleTicketingContext } from '../Context';
import CheckIn from './CheckIn';
import CheckOut from './CheckOut';
import TareIn from './TareIn';
import Ticket from './Ticket';
import { useScaleIntegration } from './useScaleIntegration';

const Styled = styled.div``;

const Actions = props => {
  return (
    <Styled className={cn('actions')}>
      <Margin left={20} right={20}>
        <Row>
          <Ticket />
        </Row>
        <Row spaceBetween>
          <TareIn />
          <CheckIn />
          <CheckOut />
        </Row>
      </Margin>
    </Styled>
  );
};

export default Actions;
