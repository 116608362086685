import React from 'react';
import cn from 'classnames';
import style from './style';
import styled from 'styled-components';
import Input from '../../../../components/form/InputNumber';
import FieldContainer from '../../../../components/field-container/FieldContainer';
import { kebabCase } from 'lodash';

const View = styled(FieldContainer)`
  ${style}
`;

const InputNumber = ({
  className,
  id,
  label,
  value,
  min = 1,
  disabled = false,
  onChange,
  addonAfter = null,
  addonBefore = null,
}) => {
  return (
    <View className={cn(id, className)}>
      <div className="label-spinner">
        <div className="label">{label}</div>
      </div>
      <Input
        type="number"
        min={min}
        value={value ?? ''}
        disabled={disabled}
        onChange={e => (onChange ? onChange(id, e.target?.value) : console.log(id, e.target?.value))}
        data-testid={`input-${kebabCase(label?.toLowerCase())}`}
        addonAfter={addonAfter}
        addonBefore={addonBefore}
      />
    </View>
  );
};

export default InputNumber;
