import React, { useEffect, useState } from 'react';
import Select from '../../../../components/form/Select';
import styled from 'styled-components';
import { useOrder } from '../../view-or-edit-order/useOrder';
import { Translate } from '../localization/Translate';

const { Option } = Select;

const Title = styled.div`
  padding: 5px;
`;

const SelectCarrier = ({ orderRef, carrierRef, onChange, disabled }) => {
  const [carriers, setCarriers] = useState(null);

  const { listOrderCarriers } = useOrder();

  useEffect(() => {
    orderRef &&
      listOrderCarriers(orderRef, { activeOnly: true })
        .then(carrierData => {
          setCarriers(carrierData);
          if (carrierData.length === 1) {
            onChange(carrierData[0]?.crn);
          }
        })
        .catch(alert);
  }, [orderRef, onChange, listOrderCarriers]);

  return (
    <div>
      <Title>
        <Translate stringId="assignedCarrier" defaultMessage="Assigned Carrier" /> *
      </Title>
      <Select
        id="select-carrier"
        data-testid="select-assigned-carrier"
        className="select-carrier"
        defaultValue={''}
        value={carrierRef}
        showSearch
        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        onChange={onChange}
        disabled={disabled}
      >
        <Option value="">Select...</Option>
        {carriers?.map((c, key) => {
          const labelPieces = [];

          c.id && labelPieces.push(c.id);
          c.name && labelPieces.push(c.name);

          const label = labelPieces.join(' / ');

          return (
            <Option key={`${key}-select-carrier`} value={c.crn}>
              {label}
            </Option>
          );
        })}
      </Select>
    </div>
  );
};

export default SelectCarrier;
