import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Select from '../../../../components/form/Select';
import { Translate } from '../../../order/components/localization/Translate';
import { useTranslateObjects } from '../../../order/components/localization/translate-objects/useTranslateObjects';
import style from './style';
import { useBillingPreview } from './useBillingPreview';

const TYPES = {
  UNBILLED: 'unbilled',
  INVOICED: 'invoiced',
  EXPORT: 'invoice-export',
};

const OPTIONS = [
  { value: TYPES.UNBILLED, label: 'Unbilled Deliveries' },
  { value: TYPES.INVOICED, label: 'Invoiced Deliveries' },
  { value: TYPES.EXPORT, label: 'Export History' },
];

const ViewContext = React.createContext();

export const useViewContext = () => {
  const context = useContext(ViewContext);
  if (!context) {
    throw new Error(`useViewContext cannot be rendered outside of the ViewContext context provider`);
  }
  return context;
};

const Styled = styled.div`
  ${style}
`;

const SelectView = () => {
  const { translateObjects } = useTranslateObjects();
  const navigate = useNavigate();
  const [selectedView, setSelectedView] = React.useState('unbilled');
  const { banner, button } = useBillingPreview(selectedView);

  const handleChange = React.useCallback(value => {
    setSelectedView(value);
  }, []);

  React.useEffect(() => {
    navigate(selectedView);
  }, [navigate, selectedView]);

  const options = React.useMemo(() => {
    return translateObjects(OPTIONS, {
      getStringId: value => `invoices.option_${value}`,
      getPath: 'value',
      setPath: 'label',
      defaultMessagePath: 'label',
    });
  }, [translateObjects]);

  return (
    <Styled>
      <ViewContext.Provider value={{}}>
        <div className="view-selection">
          <Select
            label={'Select View'}
            id={'select-source'}
            value={selectedView}
            placeholder={'Select View...'}
            onChange={handleChange}
            options={options}
          />
        </div>
        <div className="title">
          <Translate stringId={`invoices.heading_${selectedView}`} />
          {banner && <div style={{ marginTop: '20px' }}>{banner}</div>}
        </div>
        <div className="preview-selection">{button}</div>
      </ViewContext.Provider>
    </Styled>
  );
};

export default SelectView;
