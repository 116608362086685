import { cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';
import ImportExport from '../../components/import-export/ImportExport';
import InputNumberBlur from '../../components/input-number-blur';
import { useCostBookSetupContext } from '../costBookSetupContext';
import style from './style';
import InputText from '../../components/input-text';
import InputCheckbox from '../../components/input-checkbox';
import roundedToCents from '../../../../util/other/rounded-to-cents';
import { isNil } from 'lodash';

const Price = ({ product, price, onChange }) => {
  return (
    <tr>
      <td>
        {product.id} / {product.name}
      </td>
      <td>{product.type}</td>
      <td>
        <InputNumberBlur id={product.crn} value={price ?? ''} onChange={onChange} />
      </td>
      <td>{product.uomCode}</td>
    </tr>
  );
};

const Styled = styled.div`
  ${style}
`;

const CostBookPrices = ({ item, onChange, onCancel }) => {
  const [filter, setFilter] = useState(null);
  const [onlyShowPriced, setOnlyShowPriced] = useState(false);
  const [list, setList] = useState(null);
  const { entityRef } = useParams();
  const { getProducts, products, uploadFile, downloadFile } = useCostBookSetupContext();

  useEffect(() => {
    getProducts();
  }, [entityRef, getProducts]);

  const getPrice = React.useCallback(
    productRef => {
      const { price } = item?.prices?.find(p => p.productRef === productRef) || { price: { value: '' } };

      return price.value ?? '';
    },
    [item?.prices]
  );

  useEffect(() => {
    if (products) {
      setList(
        products.filter(p => {
          const { id, name } = p;

          if (onlyShowPriced && (isNil(getPrice(p.crn)) || getPrice(p.crn) === '')) {
            return false;
          }
          return !filter || `${id} / ${name}`.toLowerCase().includes(filter.toLowerCase());
        })
      );
    }
  }, [products, filter, onlyShowPriced, getPrice]);

  const changePrice = React.useCallback(
    (id, value) => {
      const prices = cloneDeep(item?.prices || []).filter(p => p.productRef !== id);
      const product = products.find(p => p.crn === id);

      if (product) {
        const fValue = parseFloat(`${value}`);

        if (!isNaN(fValue)) {
          const price = {
            id: product.id,
            productRef: id,
            price: {
              currencyCode: 'USD',
              uomCode: product.uomCode,
              value: roundedToCents(fValue),
            },
          };

          prices.push(price);
        }

        onChange && onChange('prices', prices);
      }
    },
    [onChange, products, item?.prices]
  );

  return (
    <Styled className="cost-book-prices">
      <div>
        <InputText
          id="list-filter"
          value={filter}
          onChange={(id, value) => setFilter(value)}
          addonAfter={
            <span>
              <i className={'fa fa-search'} />
            </span>
          }
        />
      </div>
      <div>
        <InputCheckbox
          className={'only-show-priced-checkbox'}
          id="onlyShowPriced"
          value={onlyShowPriced}
          onChange={(id, value) => setOnlyShowPriced(value)}
          label={'Only Show Priced Products'}
          // addonAfter={<span><i className={'fa fa-search'}/></span>}
        />
      </div>
      {item?.crn && (
        <ImportExport item={item} onFinish={onCancel} uploadFile={uploadFile} downloadFile={downloadFile} />
      )}
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th style={{ width: '54%' }}>Product</th>
              <th style={{ width: '21%' }}>Type</th>
              <th style={{ width: '15%' }}>Price</th>
              <th style={{ width: '10%' }}>UOM</th>
            </tr>
          </thead>
          <tbody>
            {list?.map(product => (
              <Price key={product.crn} product={product} price={getPrice(product.crn)} onChange={changePrice} />
            ))}
          </tbody>
        </table>
      </div>
    </Styled>
  );
};

export default CostBookPrices;
