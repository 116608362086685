import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import Centered from '../components/Centered';
import Column from '../components/Column';
import Margin from '../components/Margin';
import Actions from './Actions';
import Image from './Image';
import WeightCapture from './WeightCapture';
import VehicleWeights from './vehicle-weights/VehicleWeights';

const Styled = styled(Column)``;

const Weights = props => {
  return (
    <Styled className={cn('weights')}>
      <Margin all={20}>
        <Column>
          <Centered>
            <Image />
          </Centered>
          <VehicleWeights />
          <WeightCapture />
          <Actions />
        </Column>
      </Margin>
    </Styled>
  );
};

export default Weights;
