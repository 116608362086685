import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';

import Button from '../../../../../../components/button/Button';
import { Translate } from '../../../../components/localization/Translate';
import { getToday, isToday } from '../isTomorrow';

const StyledButton = styled(Button)``;

const TodayButton = ({ onChange, currentDate }) => {
  // TODO: get timezone
  const timeZone = 'America/Chicago';

  const handleClick = React.useCallback(() => {
    const todayRange = getToday(timeZone);
    onChange(todayRange);
  }, [onChange]);

  const isCurrentDateToday = React.useMemo(() => {
    return isToday(currentDate);
  }, [currentDate]);

  return (
    <StyledButton
      className={cn('today-button')}
      onClick={handleClick}
      type={isCurrentDateToday ? 'primary' : undefined}
      metricId="core-order-list-filter-today"
      data-testid='button-today'
    >
      <Translate stringId="today" defaultMessage="Today" />
    </StyledButton>
  );
};

export default TodayButton;
