import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import InputSelect from '../../../components/input-select';
import InputText from '../../../components/input-text';
import style from './style';
import CustomPhoneInput from '../../../../order/components/phone-input/PhoneInput';

const Styled = styled.div`
  ${style}
`;

const status = [{ value: 'ACTIVE' }, { value: 'INACTIVE' }];

const General = ({ driver, onChange }) => {
  return (
    <Styled className={cn('general')}>
      <InputText id="id" label="ID" value={driver?.id} onChange={onChange} />
      <InputText id="firstName" label="First Name" value={driver?.firstName} onChange={onChange} />
      <InputText id="lastName" label="Last Name" value={driver?.lastName} onChange={onChange} />
      <CustomPhoneInput fieldName="phone" fieldLabel="Phone" value={driver?.phone} onChange={onChange} />
      <CustomPhoneInput fieldName="mobile" fieldLabel="Mobile" value={driver?.mobile} onChange={onChange} />
      <InputText id="email" label="Email" value={driver?.email} onChange={onChange} />
      <InputSelect id="status" label="Status" value={driver?.status} onChange={onChange} options={status} required />
    </Styled>
  );
};

export default General;
