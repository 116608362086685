import {FILTER_STRATEGY} from "../../../components/connex-data-grid/util";
import React from "react";

export const prototype = {
  ruleType: 'ASSOCIATED_PRODUCT',
  id: '',
  name: '',
  status: 'ACTIVE',
};

export const ruleTypes = [
  { value: 'ASSOCIATED_PRODUCT', label: 'Associated Product' },
]

export const columns = [
  {
    key: 'ruleType',
    name: 'Rule Type',
  },
  {
    key: 'triggerPoint',
    name: 'Applied When',
  },
  {
    key: 'name',
    name: 'Name',
  },
  {
    key: 'status',
    name: 'Status',
    filterStrategy: FILTER_STRATEGY.EQUALS,
    filterComponent: ({ innerRef, ...props }) => {
      return (
          <select ref={innerRef} {...props}>
            <option value="" />
            <option value="ACTIVE">Active</option>
            <option value="INACTIVE">Inactive</option>
          </select>
      );
    },

  },
];
