import React from 'react';
import TaxRateCard from './tax-rate-card';
import Button from '../../../../../components/button/Button';

const LocationTaxRates = ({ location, onReset }) => {
  const handleResetClick = React.useCallback(() => {
    onReset();
  }, [onReset]);

  const taxRates = location?.taxRates ?? [];
  return (
    <div>
      {taxRates.length > 0 && taxRates.map((taxRate, idx) => <TaxRateCard key={`taxRate${idx}`} taxRate={taxRate} />)}
      {taxRates.length <= 0 && <div>No Tax Rate Data</div>}
      <Button onClick={handleResetClick}>Reset Tax Rates</Button>
    </div>
  );
};

export default LocationTaxRates;
