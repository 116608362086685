import styled from 'styled-components';
import FlexColumn from '../../order/components/FlexColumn';
import style from './style';
import SetupItemList from '../setup-item-list';
import { columns, prototype, tabs } from './setup';

const Styled = styled(FlexColumn)`
  ${style}
`;

const SetupProductTypes = () => {
  return (
    <Styled>
      <SetupItemList
        typeId="product-type"
        type="Product Type"
        editorType="tabs"
        tabs={tabs}
        columns={columns}
        prototype={prototype}
      />
    </Styled>
  );
};

export default SetupProductTypes;
