import { css } from 'styled-components';

export default css`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: #000000;

  display: flex;
  flex-direction: column;
  flex: 1;
  
  .back-action {
    margin: 12px;
  }

  .flex-column {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 0;
    min-width: 0;
  }

  .complete {
    font-size: 3em;
    margin-left: 8px;
    margin-right: 6px;
  }

  :focus-visible {
    outline: none;
  }

  .wrapper {
    background-color: #504848;
    height: 68px;
    transition: height 0.2s;
    border-radius: 4px;
    margin: 10px 10px;
    overflow: hidden;
  }

  .layout {
    display: flex;
    line-height: 0;
    padding: 10px;
    align-items: center;

    .right {
      line-height: 1rem;
      display: flex;
      padding-left: 0;
      font-size: 0.875rem;
      flex: 1;

      .summary-info {
        flex: 1;
      }

      .buttons {
        display: flex;
        grid-gap: 10px;

        .btn-label {
          margin-left: 10px;
        }
      }

      .status-badge {
        text-align: right;
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        position: relative;

        .ticket-number {
          margin-bottom: 3px;
        }

        .toggle {
          flex: 1;
          //height: 100%;
          display: flex;
          align-items: flex-end;
          justify-content: flex-end;
          //opacity: 0.5;
          position: absolute;
          bottom: -6px;
          font-size: 1.1rem;
          //color: white;
          width: 50px;
        }
      }

      & > * {
        padding: 0 10px;
      }

      .heading {
        font-weight: bold;
      }
    }
  }

  .detail {
    overflow-y: auto;
    padding-bottom: 30px;
    .loads {
      margin: 0 5px;
    }
    .order-tracker {
      height: 360px;
    }
  }

  .ant-tabs {
    overflow: visible !important;
  }
`;
