import { css } from 'styled-components';

export default css`
  margin-top: 15px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  flex: 1;
  .title {
    width: 100%;
    text-align: center;
    font-weight: bold;
    font-size: 1.2em;

    span {
      margin-left: 15px;
    }

    .fa-ellipsis-v {
      float: right;
    }
  }
  .connex-data-grid {
    .right-justified {
      text-align: right;
    }
  }

  &&& {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
`;
