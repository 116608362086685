import React from 'react';
import cn from 'classnames';
import style from './style';
import styled from 'styled-components';
import { kebabCase } from 'lodash';

import FieldContainer from '../../../../components/field-container/FieldContainer';
import Select from '../../../../components/form/Select';

const { Option } = Select;

const View = styled(FieldContainer)`
  ${style}
`;

const InputSelectMultiple = ({
  className,
  id,
  label,
  value,
  options = [],
  onChange,
  displayPath = ['label'],
  valuePath = 'value',
  disabled = false,
  required = true,
  onBlur = null,
}) => {
  return (
    <View className={cn(id, className)}>
      <div className="label-spinner">
        <div className="label">{label}</div>
      </div>
      <Select
        mode="multiple"
        value={value || []}
        showSearch
        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        disabled={disabled}
        onChange={value => (onChange ? onChange(id, value) : console.log(id, value))}
        data-testid={`select-${kebabCase(label?.toLowerCase())}`}
        onBlur={onBlur}
      >
        {!required && <Option value="">Select...</Option>}
        {options?.map((option, index) => (
          <Option key={`${index}-${id}-option`} value={option[valuePath]}>
            {displayPath.map(l => option[l]).join(' / ') || option[valuePath]}
          </Option>
        ))}
      </Select>
    </View>
  );
};

export default InputSelectMultiple;
