export const prototype = {
  id: '',
  name: '',
  status: 'ACTIVE',
};

export const columns = [
  {
    key: 'name',
    name: 'Description',
  },
  {
    key: 'project.name',
    name: 'Job',
  },
  {
    key: 'discipline.name',
    name: 'Discipline',
  },
  {
    key: 'status',
    name: 'Status',
    filterFn: (item, filterStrings) => item === filterStrings[0],
    filterComponent: ({ innerRef, ...props }) => {
      return (
        <select ref={innerRef} {...props}>
          <option value="" />
          <option value="ACTIVE">Active</option>
          <option value="INACTIVE">Inactive</option>
        </select>
      );
    },
  },
];
