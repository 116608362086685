import React from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';
import cn from 'classnames';
import FlexColumn from "../../order/components/FlexColumn";
import SetupTable from '../components/setup-table';
import useSetup from '../useSetup';
import style from './style';
import { columns, prototype } from './setup';
import TrailerEditor from './trailer-editor/TrailerEditor';

const Styled = styled(FlexColumn)`
  ${style}
`;

const SetupTrailers = () => {
  const { entityRef } = useParams();
  const { getTrailers, trailers, busy } = useSetup();
  const params = { activeOnly: false };

  React.useEffect(() => {
    if (entityRef) getTrailers(params);
  }, [entityRef, getTrailers]);

  return (
    <Styled className={cn('trailers')}>
      <SetupTable
        busy={busy}
        type="Trailer"
        columns={columns}
        items={trailers}
        Editor={TrailerEditor}
        prototype={prototype}
        onRefresh={() => getTrailers(params)}
      />
    </Styled>
  );
};

export default SetupTrailers;
