import React from 'react';
import MapStateContextProvider from '../context/MapStateContext';
import { useNewOrderMapState } from './useNewOrderMapState';
import NewOrderBehaviorContextProvider from './NewOrderBehaviorContextProvider';

const NewOrderMapProvider = ({ children, onDone }) => {
  const state = useNewOrderMapState(false, onDone);

  return (
    <MapStateContextProvider value={state}>
      <NewOrderBehaviorContextProvider>{children}</NewOrderBehaviorContextProvider>
    </MapStateContextProvider>
  );
};

export default NewOrderMapProvider;
