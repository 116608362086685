import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { set } from 'lodash';
import ErrorModal from '../../../../components/error-modal/ErrorModal';
import { useModalContext } from '../../../../components/modal-context/ModalContext';
import { MODES } from '../../components/order-form/OrderForm';
import { useCommonOrderBehavior } from '../../components/useCommonOrderBehavior';
import generateCleanedUpFormData from '../../util/generate-cleaned-up-form-data';
import { useState } from 'react';

export const useEditOrderBehavior = orderType => {
  const commonOrderBehavior = useCommonOrderBehavior(orderType);
  const { entityRef } = useParams();
  const [projectChanged, setProjectChanged] = useState(false);

  const {
    order: { getOrder, order, rawOrder, updateOrder, orderBusy },
    start: { startOrder },
    form: { form, setForm, auditNotes },
    defaults: { defaults },
  } = commonOrderBehavior;

  const { openModal } = useModalContext();
  const { orderId } = useParams();
  const navigate = useNavigate();

  // const hash = useHash();
  // const isDirty = useMemo(() => hash.hasChanged(form), [form, hash]);

  useEffect(() => {
    getOrder(orderId, { expand: 'tickets,order-group' });
  }, [getOrder, orderId]);

  useEffect(() => {
    startOrder();
  }, [startOrder]);

  useEffect(() => {
    if (order) {
      setForm(order, true);
      // hash.initialize(order);
    }
  }, [order, setForm]);

  useEffect(() => {
    if (projectChanged || !rawOrder?.project?.projectRef || !form?.projectRef) return;
    if (rawOrder?.project?.projectRef !== form?.projectRef) {
      setProjectChanged(true);
    }
  }, [rawOrder?.project?.projectRef, form?.projectRef, projectChanged]);

  // Apply defaults only after the first load of the master data
  // And only if the project was changed
  useEffect(() => {
    if (defaults && projectChanged) {
      setForm(
        s => ({
          ...s,
          address: defaults?.address,
          salesPersonRef: defaults?.salesPersonRef,
          taxRateRef: defaults?.taxRateRef,
          driverInstructions: defaults?.driverInstructions,
          purchaseOrder: defaults?.purchaseOrder,
          excludeZoneCharges: defaults?.excludeZoneCharges,
        }),
        true
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaults, setForm]);

  const handleCancelClick = useCallback(() => {
    navigate(`/${entityRef}/cx`);
  }, [entityRef, navigate]);

  const handleSaveClick = useCallback(() => {
    const cleanedUpFormData = generateCleanedUpFormData(form, auditNotes);

    return updateOrder(cleanedUpFormData)
      .then(() => {
        setProjectChanged(false);
      })
      .catch(e => {
        openModal('Error', <ErrorModal error={e} />);
      });
  }, [form, auditNotes, updateOrder, openModal]);

  const readOnly = useMemo(() => {
    return !rawOrder || rawOrder?.originatorSystemType !== 'CONNEX';
  }, [rawOrder]);

  const editOrderBehavior = {
    ...commonOrderBehavior,
    mode: MODES.EDIT,
    handleSaveClick,
    handleCancelClick,
    saveOrderBusy: orderBusy,
    orderType,
  };

  set(editOrderBehavior, 'order.readOnly', readOnly);

  return editOrderBehavior;
};
