import { css } from 'styled-components';

export default css`
  .no-notes {
    margin-top: 2rem;
    margin-bottom: 2rem;

    text-align: center;
    font-size: 1.2em;
    opacity: 0.8;
  }
  .note-list-container {
    .note-list-header {
      text-align: center;
      font-weight: bold;
      font-size: 1.2em;
    }
    .note-list-body {
      flex: 1;
      overflow: auto;
      .note {
        border: 1px solid #444;
        margin: 10px 5px;
        background-color: #252525;
        .note-user {
          display: flex;
          background-color: #353535;
          .name {
            font-weight: bold;
            margin-left: 10px;
            opacity: 0.8;
          }
          .email {
          }
        }
        .note-message {
          font-size: 1.3em;
          color: #fff;
          padding: 3px 10px 10px 10px;
        }
        .note-timestamp {
          opacity: 0.5;
          padding: 0 10px;
        }
        .note-footer-container {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .note-actions-container {
          display: flex;
          gap: 5px;
          padding: 5px;
        }
      }
    }
    .create-note-wrapper {
      position: sticky;
      bottom: 0;
      background-color: black;
      padding-top: 5px;
      .create-note {
        border: 1px solid #444;
        margin: 10px 5px;
        background-color: #252525;
        padding: 10px;
        align-self: flex-start;
        .label {
        }

        .note-text {
          font-size: 16px !important;
        }

        .buttons {
          text-align: right;
          margin-top: 10px;
          display: flex;
          & > button {
            flex: 1;
            &:not(:last-child) {
              margin-right: 5px;
            }
          }
        }
      }
    }
    .note-list-buttons {
      padding: 10px;
      button {
        width: 100%;
      }
    }
  }
`;
