import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Button from '../../../../components/button/Button';
import ErrorModal from '../../../../components/error-modal/ErrorModal';
import EmailCopies from '../../../../components/field-components/editable/EmailCopies';
import PourCardNumber from '../../../../components/field-components/editable/PourCardNumber';
import RequestedBy from '../../../../components/field-components/editable/RequestedBy';
import TicketInfo from '../../../../components/field-components/editable/TicketInfo';
import Buttons from '../../../../components/layout/Buttons';
import Column from '../../../../components/layout/Column';
import Fields from '../../../../components/layout/Fields';
import Layout from '../../../../components/layout/Layout';
import { useModalContext } from '../../../../components/modal-context/ModalContext';
import Spinner from '../../../../components/spinner/Spinner';
import style from '../style';
import Contact from '../../../../components/field-components/editable/Contact';
import Description from '../../../../components/field-components/editable/Description';
import Directions from '../../../../components/field-components/editable/Directions';
import Discipline from '../../../../components/field-components/editable/Discipline';
import Job from '../../../../components/field-components/editable/Job';
import Notes from '../../../../components/field-components/read-only/Notes';
import PourType from '../../../../components/field-components/editable/PourType';
import Product from '../../../../components/field-components/editable/Product';
import ProductQuantity from '../../../../components/field-components/editable/ProductQuantity';
import Slump from '../../../../components/field-components/editable/Slump';
import Spacing from '../../../../components/field-components/editable/Spacing';
import StartDateTime from '../../../../components/field-components/editable/StartDateTime';
import Supplier from '../../../../components/field-components/read-only/Supplier';
import { useUpdateOrderRequest } from './useUpdateOrderRequest';
import PourDescription from '../../../../components/field-components/editable/PourDescription';

const EditOrder = ({ order: { order, orderBusy }, form: { form, handleChange }, isDirty, isValid }) => {
  const navigate = useNavigate();
  const { openModal } = useModalContext();

  const {
    updateOrderRequest: { updateOrder, updateOrderBusy },
    lists: { lists, listsBusy, disciplines, pourDescriptions },
    handleJobChange,
    handleDisciplineChange,
  } = useUpdateOrderRequest(order, form, handleChange);

  const handleSaveClick = useCallback(() => {
    return updateOrder(form)
      .then(() => {
        navigate('..');
      })
      .catch(e => {
        openModal('Error', <ErrorModal error={e} />);
      });
  }, [updateOrder, form, navigate]);

  const useJobFilters = useCallback(() => {
    return lists?.settings?.useJobFiltering === true;
  }, [lists]);

  const useDescFilters = useCallback(() => {
    return lists?.settings?.filterDescriptions === true;
  }, [lists]);

  return (
    <div className="edit-order">
      <div className="title">Edit Order Request {form?.number ? `(${form.number})` : ''}</div>

      <Spinner spin={orderBusy || listsBusy}>
        {order && (
          <Layout>
            <Fields>
              <Column>
                <Product required value={form?.product} onChange={handleChange} products={lists?.products} />
                <ProductQuantity required value={form?.quantity} uom={form?.product?.uomCode} onChange={handleChange} />
                <Job value={form?.job} onChange={handleJobChange} jobs={lists?.jobs} />
                <Supplier value={lists?.supplierParty?.name} />
                {disciplines && (
                  <Discipline
                    value={form?.discipline}
                    onChange={handleDisciplineChange}
                    disciplines={disciplines}
                    required={lists?.settings?.useJobFiltering && form?.job?.jobRef}
                  />
                )}
                {useDescFilters() && pourDescriptions && (
                  <PourDescription
                    value={form?.pourDescription}
                    onChange={handleChange}
                    pourDescriptions={pourDescriptions}
                    required={form?.job?.jobRef}
                  />
                )}
                {!useDescFilters() && <Description value={form?.description} onChange={handleChange} />}
                <Contact value={form?.contact} onChange={handleChange} />
                <RequestedBy value={form?.requestedBy} />
              </Column>
              <Column>
                <StartDateTime required value={form?.startDateTime} onChange={handleChange} />
                <Slump value={form?.slump} onChange={handleChange} />
                <Spacing value={form?.spacing} onChange={handleChange} spacingUnits={form?.spacingUnits} />
                <PourType value={form?.pourType} onChange={handleChange} />
                <PourCardNumber value={form?.pourCardNumber} onChange={handleChange} />
                <Directions value={form?.directions} onChange={handleChange} />
                <EmailCopies value={form?.emailCopies} onChange={handleChange} contacts={lists?.contacts} />
                <TicketInfo tickets={order.tickets} meta={order.ticketsMeta} />
              </Column>
            </Fields>
            <div style={{ gridArea: 'notes' }}>
              <Notes value={form?.notes} onChange={handleChange} orderRef={order.crn} />
            </div>
            <Buttons>
              <Button metricId="edit-order-cancel" onClick={() => navigate('..')}>
                Go Back
              </Button>
              <Button
                metricId="edit-order-save"
                onClick={handleSaveClick}
                loading={updateOrderBusy}
                disabled={!isValid || !isDirty}
              >
                Save
              </Button>
            </Buttons>
          </Layout>
        )}
      </Spinner>
    </div>
  );
};

export default styled(EditOrder)`
  ${style}
`;
