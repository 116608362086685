import { find } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import FieldContainer from '../../field-container/FieldContainer';
import Select from '../../form/Select';
import style from './style';

const PourDescription = ({ className, pourDescriptions, onChange, value, disabled, required }) => {
  const handleChange = val => {
    const targetDiscipline = find(pourDescriptions, s => s.crn === val);

    const { crn, ...crnRemoved } = targetDiscipline;
    onChange('pourDescription', { ...crnRemoved, pourDescriptionRef: crn });
  };

  const { Option } = Select;

  return (
    <FieldContainer className={cn('pourDescription', className)}>
      <div className="label-spinner">
        <div className="label">{`Pour Description ${required ? '*' : ''}`}</div>
        <div className={cn('spinner', { spinning: false })}>
          <span className="fas fa-sync fa-spin" />
        </div>
      </div>

      <Select value={value?.pourDescriptionRef || ''} onChange={handleChange} disabled={disabled}>
        <option value="">Select a pour description...</option>
        {pourDescriptions?.map(c => (
          <Option value={c.crn} key={c.crn}>
            {c.id} / {c.description}
          </Option>
        ))}
      </Select>
    </FieldContainer>
  );
};

export default styled(PourDescription)`
  ${style}
`;
