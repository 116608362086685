import { Drawer, Slider } from 'antd';
import cn from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { DateTime } from 'luxon';

import FlexColumn from '../../views/order/components/FlexColumn';
import { Time } from '../../views/order/components/localization/Time';
import { Translate } from '../../views/order/components/localization/Translate';
import { useModelingContext } from '../../views/order/components/order-form/schedule/ModelingContext';
import { useOrderContext } from '../../views/order/components/useOrderContext';
import SelectedOrders from './drawer/SelectedOrders';
import findMedianOrderTime from './findMedianOrderTime';
import GraphBody from './graph-body/GraphBody';
import HighlightedOrders from './HighlightedOrders';
import style from './style';
import Trucks from './trucks/Trucks';
import { useOrdersDrawer } from './useOrdersDrawer';
import Xaxis from './x-axis/Xaxis';
import Yaxis from './y-axis/Yaxis';

const Styled = styled.div`
  ${style}
`;

const getIntervals = max => {
  const oneHundred = {
    5: [1, 2, 3, 4, 5],
    10: [5, 10],
    12: [4, 8, 12],
    15: [5, 10, 15],
    16: [4, 8, 12, 16],
    20: [5, 10, 15, 20],
    25: [5, 10, 15, 20, 25],
    30: [10, 20, 30],
    40: [10, 20, 30, 40],
    50: [10, 20, 30, 40, 50],
    60: [20, 40, 60],
    75: [25, 50, 75],
    80: [20, 40, 60, 80],
    100: [25, 50, 75, 100],
  };

  const overOneHundred = {
    150: [50, 100, 150],
    200: [50, 100, 150, 200],
    250: [50, 100, 150, 200, 250],
    300: [100, 200, 300],
    400: [100, 200, 300, 400],
    500: [100, 200, 300, 400, 500],
    600: [200, 400, 600],
    750: [250, 500, 750],
    800: [200, 400, 600, 800],
    900: [300, 600, 900],
    1000: [250, 500, 750, 1000],
  };

  const calculateTemplate = max => {
    const roundedUp = Math.pow(10, Math.ceil(Math.log10(max)));

    const multiplier = roundedUp / 1000;

    const template = Object.entries(overOneHundred).reduce((acc, [key, value]) => {
      acc[key * multiplier] = value.map(v => v * multiplier);
      return acc;
    }, {});

    return template;
  };

  const template = max => {
    return max <= 100 ? oneHundred : calculateTemplate(max);
  };

  const intervals = Object.entries(template(max)).reduce((acc, [key, value]) => {
    if (max <= key && !acc) {
      acc = value;
    }
    return acc;
  }, null);

  return intervals;
};

const PlanningGraph = props => {
  const orderContext = useOrderContext();
  const order = orderContext?.order?.rawOrder;

  const { date, loads, demand } = props;

  const modelingContext = useModelingContext();

  const contentRef = React.useRef();

  const left = contentRef?.current?.offsetLeft;

  const intervals = getIntervals(loads?.max);

  const namedOffset = React.useMemo(() => (date?.isLuxonDateTime ? date.toFormat('ZZZZ') : null), [date]);

  const [highlightedOrders, setHighlightedOrders] = React.useState([]);

  const { showOrdersDrawer, drawerProps } = useOrdersDrawer();

  const [selectedItem, setSelectedItem] = React.useState({});

  const handleGraphClick = React.useCallback(
    (loads, time, numberOfTrucks, isoDateTime) => {
      if (loads) {
        setSelectedItem({ loads, time, timeZone: demand?.timeZone, numberOfTrucks, isoDateTime });
        showOrdersDrawer();
      }
    },
    [demand?.timeZone, showOrdersDrawer]
  );

  const scrollRef = React.useRef();

  const isDateToday = React.useMemo(() => {
    if (!date) return false;
    const today = DateTime.now().setZone(demand?.timeZone).startOf('day');

    return today.equals(date);
  }, [date, demand?.timeZone]);

  React.useEffect(() => {
    const visibleWidth = scrollRef?.current?.offsetWidth;

    const nowPosition = () => {
      const nowDateTime = DateTime.now().setZone(demand?.timeZone);
      const hour = nowDateTime.hour;
      const minute = Math.floor(nowDateTime.minute / 5) * 5;

      return (hour * 12 + minute / 5) * 10;
    };

    if (modelingContext?.targetOrderRef && order) {
      const midTime = findMedianOrderTime({ timeZone: demand?.timeZone, order });

      const hour = midTime.hour;
      const minute = Math.floor(midTime.minute / 5) * 5;

      const positionOfInterest = (hour * 12 + minute / 5) * 10;
      const desiredCenteredPosition = positionOfInterest - visibleWidth / 2;
      scrollRef.current.scrollLeft = desiredCenteredPosition;
    } else if (isDateToday && visibleWidth) {
      const positionOfInterest = nowPosition();
      const desiredCenteredPosition = positionOfInterest - visibleWidth / 2;
      scrollRef.current.scrollLeft = desiredCenteredPosition;
    }
  }, [isDateToday, order, demand?.timeZone, modelingContext?.targetOrderRef]);

  return (
    <Styled className={cn('planning-graph')}>
      <FlexColumn>
        <Trucks
          maxTrucks={props.maxTrucks}
          onChange={props.handleMaxTrucksOverride}
          onReset={props.handleResetMaxTrucksOverride}
          maxTrucksOverride={props.maxTrucksOverride || ''}
        />
        <div style={{ display: 'flex' }}>
          <Yaxis loads={loads} intervals={intervals} namedOffset={namedOffset} />
          <FlexColumn className="flex-wrapper" innerRef={scrollRef}>
            <GraphBody
              innerRef={contentRef}
              loads={loads}
              intervals={intervals}
              redLine={props.maxTrucksOverride || props.maxTrucks}
              date={date}
              timeZone={demand?.timeZone}
              setHighlightedOrders={setHighlightedOrders}
              ordersDetail={handleGraphClick}
              showNow={isDateToday}
            />
            <Xaxis left={left} />
            {modelingContext?.targetOrderRef && (
              <div className="slider" style={{ width: '2880px', marginLeft: '12px', marginBottom: '20px' }}>
                <Slider
                  range={{ draggableTrack: true }}
                  value={modelingContext?.sliderValue}
                  onChange={modelingContext?.handleSliderChange}
                  // step={5}
                  min={0}
                  max={1440}
                  tooltipVisible={false}
                />
              </div>
            )}
          </FlexColumn>
        </div>
        <HighlightedOrders highlightedOrders={highlightedOrders} />
      </FlexColumn>
      <Drawer
        {...drawerProps}
        title={
          selectedItem ? (
            <>
              <Time date={selectedItem.isoDateTime} /> -
              <Translate
                stringId="numberOfTrucks"
                values={{ numberOfTrucks: selectedItem.numberOfTrucks }}
                defaultMessage="_{numberOfTrucks} Trucks"
              />
            </>
          ) : (
            <Translate stringId="loading" defaultMessage="'Loading...'" />
          )
        }
      >
        <SelectedOrders selectedItem={selectedItem} loads={loads} date={date} />
      </Drawer>
    </Styled>
  );
};

export default PlanningGraph;
