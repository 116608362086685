import React, { useEffect } from 'react';
import styled from 'styled-components';
import FlexColumn from '../../order/components/FlexColumn';
import useSetup from '../useSetup';
import { useParams } from 'react-router';
import style from './style';
import SetupTable from '../components/setup-table';
import ProjectEditor from './project-editor';
import ImportExport from '../components/import-export/ImportExport';

const prototype = {
  id: '',
  name: '',
  status: 'ACTIVE',
};

const View = styled(FlexColumn)`
  ${style}
`;

const columns = [
  {
    key: 'id',
    name: 'ID',
  },
  {
    key: 'name',
    name: 'Name',
  },
  {
    key: 'address',
    name: 'Address',
  },
  {
    key: 'customerParty.name',
    name: 'Customer',
  },
  {
    key: 'mapped',
    name: 'Mapped',
    formatter: props => (props.row.hasCoordinates ? 'Yes' : 'No'),
  },
  {
    key: 'pricing',
    name: 'Pricing',
  },
  {
    key: 'status',
    name: 'Status',
    filterFn: (item, filterStrings) => item === filterStrings[0],
    filterComponent: ({ innerRef, ...props }) => {
      return (
        <select ref={innerRef} {...props}>
          <option value="" />
          <option value="ACTIVE">Active</option>
          <option value="INACTIVE">Inactive</option>
        </select>
      );
    },
  },
];

const importExport = props => {
  const { downloadFile, uploadFile } = useSetup();

  return (
    <ImportExport
      {...props}
      downloadFile={downloadFile}
      uploadFile={uploadFile}
      item={{ typeId: 'project', crn: 'all', name: 'project-list' }}
    />
  );
};

const SetupProjects = ({ label = 'Project', suppliedColumns, showPricing = true }) => {
  const { entityRef } = useParams();
  const { getProjects, projects, busy } = useSetup();
  const params = { activeOnly: false };

  useEffect(() => {
    if (!entityRef) return;
    getProjects(entityRef, params);
  }, [entityRef, getProjects]);

  return (
    <View className="setup-projects">
      <SetupTable
        busy={busy}
        type={label}
        columns={suppliedColumns ?? columns}
        items={projects}
        Editor={ProjectEditor}
        prototype={prototype}
        onRefresh={() => getProjects(entityRef, params)}
        editorProps={{ showPricing: showPricing }}
        actions={importExport}
      />
    </View>
  );
};

export default SetupProjects;
