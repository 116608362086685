import React, { useEffect } from 'react';
import styled from 'styled-components';
import FlexColumn from '../../order/components/FlexColumn';
import useSetup from '../useSetup';
import { useParams } from 'react-router';
import style from './style';
import SetupTable from '../components/setup-table';
import TrackableEditor from './trackable-editor';
import {FILTER_STRATEGY} from "../../../components/connex-data-grid/util";

const prototype = {
  deviceId: '',
  alias: '',
  deviceType: '',
  description: '',
  entityRef: '',
};

const columns = [
  {
    key: 'deviceId',
    name: 'Device ID',
  },
  {
    key: 'alias',
    name: 'Alias',
  },
  {
    key: 'deviceType',
    name: 'Device Type',
  },
  {
    key: 'description',
    name: 'Description',
  },
  {
    key: 'status',
    name: 'Status',
    filterStrategy: FILTER_STRATEGY.EQUALS,
    filterComponent: ({ innerRef, ...props }) => {
      return (
        <select ref={innerRef} {...props}>
          <option value="" />
          <option value="ACTIVE">Active</option>
          <option value="INACTIVE">Inactive</option>
        </select>
      );
    },
  },
];

const Styled = styled(FlexColumn)`
  ${style}
`;

const SetupTrackables = () => {
  const { entityRef } = useParams();
  const { getTrackables, trackables, busy } = useSetup();
  const params = { activeOnly: false };

  useEffect(() => {
    entityRef && getTrackables(params);
  }, [entityRef, getTrackables]);

  return (
    <Styled>
      <SetupTable
        busy={busy}
        type="Trackable"
        columns={columns}
        items={trackables}
        Editor={TrackableEditor}
        prototype={prototype}
        onRefresh={() => getTrackables(params)}
      />
    </Styled>
  );
};

export default SetupTrackables;
