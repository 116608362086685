import { css } from 'styled-components';

export default css`
  width: 100%;
  padding: 5px;
  border-top: solid 1px #353535;  
  border-bottom: solid 1px #353535;  
  
  table, tbody, tr {
    width: 100%;
  }
  
  td {
    width: 50%;
  }
`;
