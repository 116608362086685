import {Drawer} from 'antd';
import React, {useMemo} from 'react';
import styled from 'styled-components';
import {useViewport} from "../../../../hooks/useViewport";
import TicketEntry from "./TicketEntry";

const CreateTicket = ({ className, onClose, onTicketed, open, orderRef }) => {
  const viewport = useViewport();

  const drawerWidth = useMemo(() => {
    return Math.min(480, viewport.width);
  }, [viewport]);

  return (
    <div className={className}>
      <StyledDrawer
        width={drawerWidth}
        title="Create Ticket"
        placement="right"
        closable
        onClose={onClose}
        visible={open}
      >
        <TicketEntry open={open} orderRef={orderRef} onTicketed={onTicketed} onSave={onClose}/>
      </StyledDrawer>
    </div>
  );
};

const StyledDrawer = styled(Drawer)`
  .ant-drawer-body {
    display: flex;
    flex-direction: column;
  }
`;

export default styled(CreateTicket)`
  & > *:not(:first-child) {
    margin-top: 20px;
  }
  .field-container {
    border: 1px solid #434343;
    padding: 10px;
    cursor: pointer;
    .label-container {
      display: flex;
      .label {
        flex: 1;
      }
    }
  }
`;
