import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { Translate } from '../../../../order/components/localization/Translate';
import style from './style';
import { cloneDeep, kebabCase, set } from 'lodash';

import GeneralSettings from './general-settings';
import ColorSelectors from './color-selectors';
import LocationSelection from './location-selection';
import { useDashboardContext } from '../../context/useDashboardContext';
import { setValue } from '../../../../../util/setValue';
import Button from '../../../../../components/button/Button';
import { buttonClick } from '../../../../../util/analytics/index';

const configurationForms = {
  'location-selection': {
    component: LocationSelection,
    icon: 'home',
  },
  // 'statuses': {
  //   component: VehicleStatuses,
  //   icon: 'truck',
  // }
  'color-selectors': {
    component: ColorSelectors,
    icon: 'palette',
  },
  'general-settings': {
    component: GeneralSettings,
    icon: 'cog',
  },
};

const TrackingConfigurationForm = ({ className }) => {
  const {
    currentTrackingConfiguration,
    drawer: { toggleDrawer, setContent },
    saveCurrentTrackingConfiguration,
    licenses,
  } = useDashboardContext();

  const [selectedForm, setSelectedForm] = useState(Object.keys(configurationForms)[0]);
  const FormComponent = configurationForms[selectedForm]?.component;

  const [localTrackingConfiguration, setLocalTrackingConfiguration] = useState(currentTrackingConfiguration);

  const closeDrawer = useCallback(() => {
    toggleDrawer();
    setTimeout(() => {
      setContent(null);
    }, 10);
  }, [toggleDrawer, setContent]);

  const sortTrackingHeaders = useCallback(
    (column, headers) => {
      const copy = cloneDeep(localTrackingConfiguration);
      const sortedHeaders = headers.sort((a, b) => a.idx - b.idx);
      set(copy, `columns.${column}`, sortedHeaders);
      setLocalTrackingConfiguration(copy);
    },
    [localTrackingConfiguration, setLocalTrackingConfiguration]
  );

  const saveTrackingConfiguration = useCallback(
    (field, value) => {
      const copy = cloneDeep(localTrackingConfiguration);
      if (field.startsWith('columns')) {
        const [key, column, index, property] = field.split('_');
        copy[key][column][index] = { ...copy[key][column][index], [property]: value };
        setLocalTrackingConfiguration(copy);
      } else {
        const newValue = setValue(copy, field, value);
        setLocalTrackingConfiguration(newValue);
      }
    },
    [localTrackingConfiguration, setLocalTrackingConfiguration]
  );

  return (
    <div className={cn(className)}>
      <div className={'form-selector-list'}>
        {Object.keys(configurationForms).map(formKey => {
          const formConfig = configurationForms[formKey];
          const handleClick = () => {
            setSelectedForm(formKey);
          };
          return (
            <div
              key={formKey}
              className={cn('form-icon-container', { active: formKey === selectedForm })}
              onClick={() => buttonClick(handleClick, `core-tracking-tracking-configuration-${kebabCase(formKey)}`)}
            >
              <span className={`fas fa-${formConfig.icon} form-icon`} />
            </div>
          );
        })}
      </div>
      <div className={'form-component-container'}>
        {FormComponent && (
          <FormComponent
            trackingConfiguration={localTrackingConfiguration}
            saveTrackingConfiguration={saveTrackingConfiguration}
            sortTrackingHeaders={sortTrackingHeaders}
            licenses={licenses}
          />
        )}
      </div>
      <div className={'form-button-group'}>
        <div>
          <Button
            onClick={() => {
              saveCurrentTrackingConfiguration(localTrackingConfiguration);
              closeDrawer();
            }}
            metricId="core-tracking-tracking-configuration-save"
          >
            <Translate stringId="save" defaultMessage="Save" />
          </Button>
          <Button
            onClick={() => {
              closeDrawer();
            }}
            metricId="core-tracking-tracking-configuration-cancel"
          >
            <Translate stringId="cancel" defaultMessage="Cancel" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default styled(TrackingConfigurationForm)`
  ${style}

  .form-selector-list {
    flex-direction: column;
  }

  .form-component-container {
    width: 100%;
    flex-direction: column;
  }

  .form-button-group {
    position: absolute;
    bottom: 0;
  }

  .form-icon-container {
    height: 40px;
    width: 40px;
    margin: 5px;
    background: darkred;
    border-radius: 5px;
    cursor: pointer;

    text-align: center;
    .form-icon {
      display: inline-block;
      font-size: 24px;
      line-height: 40px;
      color: white;
      vertical-align: bottom;
    }

    &.active {
      border: 2px goldenrod solid;
      .form-icon {
        line-height: 36px;
      }
    }
  }
`;
