import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';
import cn from 'classnames';
import Button from '../../../../components/button/Button';
import Buttons from '../../../../components/layout/Buttons';
import Spinner from '../../../../components/spinner/Spinner';
import useSetup from '../../useSetup';
import General from './general/General';
import style from './style';

const Styled = styled.div`
  ${style}
`;
const ButtonBar = ({ onCancel, onSave, busy }) => {
  return (
    <Buttons>
      <Button id="btn-cancel" disabled={busy} onClick={onCancel}>
        Cancel
      </Button>
      <Button id="btn-save" loading={busy} disabled={busy} onClick={onSave}>
        Save
      </Button>
    </Buttons>
  );
};

const TrailerEditor = ({ item, onCancel, onSave }) => {
  const { updateTrailer, createTrailer, trailer, getTrailer, setTrailer, itemBusy, error } = useSetup();
  const { entityRef } = useParams();

  useEffect(() => {
    if (item) {
      if (item.crn) {
        getTrailer(entityRef, item.crn).catch(alert);
      } else {
        setTrailer(item);
      }
    }
  }, [entityRef, getTrailer, item, setTrailer]);

  const onChange = React.useCallback(
    (id, value) => {
      setTrailer({
        ...trailer,
        [id]: value,
      });
    },
    [setTrailer, trailer]
  );

  const onSaveTrailer = React.useCallback(() => {
    if (trailer.crn) {
      updateTrailer(trailer).then(() => onSave(true));
    } else {
      createTrailer(trailer).then(() => onSave(true));
    }
  }, [createTrailer, onSave, trailer, updateTrailer]);

  return (
    <Styled className={cn('trailer-editor')}>
      <Spinner spin={itemBusy}>
        <General trailer={trailer} itemBusy={itemBusy} onChange={onChange} error={error} />
        <ButtonBar onCancel={onCancel} onSave={onSaveTrailer} busy={itemBusy} />
      </Spinner>
    </Styled>
  );
};

export default TrailerEditor;
