import style from "./style";
import React, {useEffect, useState} from "react";
import styled from 'styled-components';
import Button from "../../../../components/button/Button";
import useSetup from "../../useSetup";
import Buttons from "../../../../components/layout/Buttons";
import Spinner from "../../../../components/spinner/Spinner";
import General from "./general";

const ButtonBar = ({ onCancel, onSave, busy }) => {
  return (
    <Buttons>
      <Button id='btn-cancel' disabled={busy} onClick={onCancel}>Cancel</Button>
      <Button id='btn-save' loading={busy} disabled={busy} onClick={onSave}>Save</Button>
    </Buttons>
  )
}

const Styled = styled.div`${style}`;

const TrackableEditor = ({ item, onSave, onCancel }) => {
  const { saveTrackable, trackable, getTrackable, setTrackable, getTrackableTypes, trackableTypes, itemBusy } = useSetup();
  const [trackableTypeList, setTrackableTypeList ] = useState(null);
  useEffect(() => {
    if (item) {
      if (item.crn) {
        getTrackable(item.crn);
        getTrackableTypes();
      } else {
        setTrackable(item);
        getTrackableTypes();
      }
    }
  }, [item]);

  const onChange = (id, value) => {
    setTrackable({
      ...trackable,
      [id]: value,
    });
  };

  useEffect(() => {
    setTrackableTypeList(trackableTypes.map(type => {
      return { value: type.crn, label: type.name };
    }));
  }, [trackableTypes])
  const onSaveItem = () => {
    saveTrackable().then(() => onSave(true));
  };

  return (
    <Styled>
      <Spinner spin={!trackable && itemBusy}>
        <General trackable={trackable} onChange={onChange} trackableTypes={trackableTypeList} />
        <ButtonBar onSave={onSaveItem} onCancel={onCancel} busy={itemBusy} />
      </Spinner>
    </Styled>
  );
};

export default TrackableEditor;
