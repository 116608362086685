import { useState, useCallback, useEffect } from 'react';

import BaseMap from '../../../../../components/sdk-map';
import LocationMarker from '../../../setup-locations/address-map/LocationMarker';

const getCoordinates = item => {
  const { address } = item || {};

  if (address?.coordinates?.latitude && address?.coordinates?.longitude) {
    return {
      lat: address?.coordinates?.latitude,
      lng: address?.coordinates?.longitude,
    };
  }

  return {};
};

const ProjectMap = ({ project, onChange, isNew }) => {
  const [center, setCenter] = useState(null);

  useEffect(() => {
    if (project) {
      const { lng, lat } = getCoordinates(project);

      if (lng && lat) {
        setCenter({
          id: project.crn,
          lat,
          lng,
        });
      }
    }
  }, [project]);

  const handleChange = useCallback(
    point => {
      if (point.lat && point.lng) {
        setCenter(point);
      }

      const address = {
        ...project.address,
        coordinates: {
          latitude: point.lat,
          longitude: point.lng,
          custom: point.custom,
        },
      };
      project && onChange?.('address', address);
    },
    [project, onChange]
  );

  if (center)
    return (
      <BaseMap zoom={16} center={center}>
        <LocationMarker point={center} onChange={handleChange} />
      </BaseMap>
    );

  return (
    <div style={{ height: '200px', textAlign: 'center', margin: '20px' }}>
      {isNew ? 'Please save the record to view on a Map' : 'The map cannot be displayed.  Please verify the address.'}
    </div>
  );
};

export default ProjectMap;
