import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import api from '../../../../api';

export const useNotes = () => {
  const { entityRef } = useParams();

  const [creatingOrderNote, setCreatingOrderNote] = useState(false);
  const [orderNote, setOrderNote] = useState();

  const [listingOrderNotes, setListingOrderNotes] = useState(false);
  const [orderNoteList, setOrderNoteList] = useState();

  const createOrderNote = useCallback(
    (orderRef, note) => {
      setCreatingOrderNote(true);
      return api
        .createNote({ entityRef, orderRef, note: { message: note } })
        .then(setOrderNote)
        .finally(() => {
          setCreatingOrderNote(false);
        });
    },
    [entityRef]
  );

  const listOrderNotes = useCallback(
    orderRef => {
      setListingOrderNotes(true);
      return api
        .listNotes({ entityRef, orderRef })
        .then(response => {
          setOrderNoteList(response);
          return response;
        })
        .finally(() => {
          setListingOrderNotes(false);
        });
    },
    [entityRef]
  );

  return {
    createOrderNote,
    creatingOrderNote,
    orderNote,
    listOrderNotes,
    listingOrderNotes,
    orderNoteList,
  };
};
