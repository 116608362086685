import useSetup from '../../views/setup/useSetup';
import { useCallback, useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import style from './style';
import MixComponent from './components/MixComponent';
import InputSelect from '../../views/setup/components/input-select';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import roundedToCents from '../../util/other/rounded-to-cents';

const Styled = styled.div`
  ${style}
`;

const ComponentEditor = ({ item, onChange, disableButtons }) => {
  const { getSetupItems, setupItems: components, busy } = useSetup('component');
  const [sortedFlag, setSortedFlag] = useState(false);

  useEffect(() => {
    if (!sortedFlag && item?.length && item[0].batchSequence !== undefined) {
      const temp = [...item];
      onChange(temp.sort((a, b) => a.batchSequence - b.batchSequence));
      setSortedFlag(true);
    }
  }, [item, sortedFlag, setSortedFlag, onChange]);

  useEffect(() => {
    getSetupItems().then();
  }, [getSetupItems]);

  useEffect(() => {
    if (item) {
      for (const c of item) {
        if (!c.weight) {
          c.weight = c.quantity;
        }
      }
    }
  }, [item]);

  const onChangeMix = useCallback(
    item => {
      onChange(item.map((component, index) => ({ ...component, batchSequence: index })));
    },
    [onChange]
  );

  const addComponent = useCallback(
    (_, value) => {
      const component = components.find(c => c.crn === value);
      if (!component) return;

      const quantity = {
        uomCode: component.batchUomCode,
        value: parseFloat('0'),
      };
      const conversion = parseFloat(`${component.weight || '1'}`);
      const factor = quantity.uomCode === component.uomCode ? 1 : conversion;
      const weight = {
        value: quantity.uomCode === component.uomCode ? quantity.value : quantity.value * conversion,
        uomCode: component.uomCode,
        factor: factor,
      };

      const temp = item ? [...item] : [];
      temp.push({
        id: component.id,
        componentRef: value,
        name: component.name,
        quantity,
        weight,
      });
      onChangeMix(temp);
    },
    [item, components, onChangeMix]
  );

  const deleteComponent = useCallback(
    value => {
      if (!item) return;
      onChangeMix(item.filter(component => component.componentRef !== value));
    },
    [onChangeMix, item]
  );

  const conversion = (component, qty) => {
    const quantity = {
      uomCode: component?.batchUomCode,
      value: roundedToCents(`${qty || '0'}`),
    };

    const conversion = parseFloat(`${component?.weight || '1'}`);
    const factor = quantity.uomCode === component?.uomCode ? 1 : conversion;
    const weight = {
      value: quantity.uomCode === component?.uomCode ? quantity.value : roundedToCents(quantity.value * conversion),
      uomCode: component?.uomCode,
      factor: factor,
    };

    return {
      quantity: quantity,
      weight: weight,
    };
  };

  const changeQuantity = useCallback(
    (id, value) => {
      const component = components.find(c => c.crn === id);

      if (!component) return;

      const tempItem = [...item];

      const selectedComponent = tempItem.find(c => c.componentRef === id);

      if (!selectedComponent) return;

      const convertedValues = conversion(component, value);
      selectedComponent.quantity = convertedValues.quantity;
      selectedComponent.weight = convertedValues.weight;

      onChangeMix(tempItem);
    },
    [onChangeMix, item, components]
  );

  const showComponents = useMemo(() => {
    if (!item || busy) return [];

    return item.map(component => {
      const setupComponent = components?.find(c => c.crn === component.componentRef);
      const convertedValues = conversion(setupComponent, component?.quantity?.value);

      return {
        crn: setupComponent?.crn || component?.componentRef,
        id: setupComponent?.id || component?.id,
        name: setupComponent?.name || component?.name,
        batchUomCode: setupComponent?.batchUomCode,
        uomCode: setupComponent?.uomCode,
        quantity: convertedValues?.quantity,
        weight: convertedValues?.weight,
        deletedComponent: setupComponent ? false : true,
        batchSequence: component?.batchSequence,
      };
    });
  }, [item, components, busy]);

  const selectOptions = useMemo(() => {
    if (!components.length) return [];

    return components.reduce((result, component) => {
      const exists = item?.find(c => c.componentRef === component.crn);

      if (exists) return result;

      result.push({
        label: `${component.id} / ${component.name}`,
        value: component.crn,
      });

      return result;
    }, []);
  }, [item, components]);

  const onDragEnd = useCallback(
    ({ source, destination }) => {
      if (!destination) {
        return;
      }
      const result = [...item];
      const [removed] = result.splice(source.index, 1);
      result.splice(destination.index, 0, removed);
      onChangeMix(result);
    },
    [item, onChangeMix]
  );

  return (
    <Styled>
      <div>Constituents of {item?.name}</div>
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th style={{ width: '5%' }}>Batch Sequence</th>
              <th style={{ width: '40%' }}>Component</th>
              <th style={{ width: '30%' }}>Quantity</th>
              <th style={{ width: '20%' }}>Consumed Inventory</th>
              {!disableButtons && <th style={{ width: '5%' }}></th>}
            </tr>
          </thead>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="mix-editor-droppable">
              {provided => (
                <tbody {...provided.droppableProps} ref={provided.innerRef}>
                  {showComponents.map((component, index) => (
                    <Draggable key={component.crn} draggableId={component.crn} index={index}>
                      {provided => (
                        <MixComponent
                          dragRef={provided.innerRef}
                          draggableProps={provided.draggableProps}
                          dragHandleProps={provided.dragHandleProps}
                          key={component.crn}
                          component={component}
                          onChange={changeQuantity}
                          onDelete={deleteComponent}
                          localIndex={index}
                          disableButtons={disableButtons}
                        />
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </tbody>
              )}
            </Droppable>
          </DragDropContext>
        </table>
      </div>
      <div className="separator" />
      {!disableButtons && (
        <InputSelect
          id="componentField"
          label="Add Component:"
          options={selectOptions}
          disabled={!selectOptions.length}
          onChange={addComponent}
        />
      )}
      <div className="separator" />
    </Styled>
  );
};

export default ComponentEditor;
