import React, { useEffect, useState } from 'react';
import BaseMap from '../../../../components/sdk-map';
import LocationMarker from './LocationMarker';

const getCoordinates = item => {
  const { address } = item || {};

  if (address?.coordinates?.latitude && address?.coordinates?.longitude) {
    return {
      lat: address?.coordinates?.latitude,
      lng: address?.coordinates?.longitude,
    };
  }

  return {};
};

const AddressMap = ({ item, onChange, isNew }) => {
  const [center, setCenter] = useState(null);

  useEffect(() => {
    if (item) {
      const { lng, lat } = getCoordinates(item);

      if (lng && lat) {
        setCenter({
          id: item.crn,
          lat,
          lng,
        });
      }
    }
  }, [item]);

  const handleChange = React.useCallback(
    point => {
      if (point.lat && point.lng) {
        setCenter(point);
      }

      const address = {
        ...item.address,
        coordinates: {
          latitude: point.lat,
          longitude: point.lng,
          custom: point.custom,
        },
      };
      item && onChange?.('address', address);
    },
    [item, onChange]
  );

  if (center)
    return (
      <BaseMap zoom={16} center={center}>
        <LocationMarker point={center} onChange={handleChange} />
      </BaseMap>
    );

  return (
    <div style={{ height: '200px', textAlign: 'center', margin: '20px' }}>
      {isNew ? 'Please save the record to view on a Map' : 'The map cannot be displayed.  Please verify the address.'}
    </div>
  );
};

export default AddressMap;
