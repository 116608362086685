import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import Column from './components/Column';
import Row from './components/Row';
import ScaleTicketingContextProvider from './Context';
import Header from './header/Header';
import style from './style';
import Form from './vehicle-form/Form';
import Weights from './weights/Weights';

const Styled = styled(Column)`
  ${style}
`;

const ScaleTicketing = () => {
  return (
    <ScaleTicketingContextProvider>
      <Styled className={cn('scale-ticketing')}>
        <Column>
          <Header />
          <Column>
            <Row>
              <Form />
              <Weights />
            </Row>
          </Column>
        </Column>
      </Styled>
    </ScaleTicketingContextProvider>
  );
};

export default ScaleTicketing;
