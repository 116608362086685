import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { getCachedItem } from '../../../../util/cache';
import API from '../../api';
import TrackingConfigurationModel from './models/trackingConfiguration';

const useTrackingConfigurations = () => {
  const { entityRef } = useParams();

  // eslint-disable-next-line no-unused-vars
  const [trackingConfigurations, setTrackingConfigurations] = useState();
  const [currentTrackingConfiguration, setCurrentTrackingConfiguration] = useState();

  const restoreHeaders = (configColumns, defaultColumns) => {
    const indexFound = configColumns.findIndex(c => c.id === 'vehicleSpacing' && c.field === 'averageVehicleSpacing');
    const vehicleSpacingCol = defaultColumns.find(c => c.id === 'vehicleSpacing');

    if (indexFound >= 0) {
      configColumns[indexFound] = { ...vehicleSpacingCol, idx: indexFound };
    }

    for (let col of defaultColumns) {
      if (configColumns.findIndex(item => item.id === col.id) < 0) {
        configColumns.push(col);
      }
    }

    //We should Only display columns that are in the defaultColumns
    return configColumns.filter(col => defaultColumns.findIndex(c => c.id === col.id) >= 0);
  };

  useEffect(() => {
    const run = async () => {
      const savedConfig = await API.getTrackingConfiguration(entityRef);

      if (savedConfig) {
        if (savedConfig?.config?.columns?.order) {
          savedConfig.config.columns.order = restoreHeaders(
            savedConfig?.config?.columns?.order,
            TrackingConfigurationModel.columns.order
          );
        } else {
          savedConfig.config.columns.order = TrackingConfigurationModel.columns.order;
        }

        //This set will cause the record to be saved in the db, because of the next useEffect
        setCurrentTrackingConfiguration(savedConfig.config);
        return;
      }

      let result = getCachedItem(`${entityRef}#LAST_TRACKING_CONFIGURATION`);

      if (result && result !== 'undefined') {
        const parsedResult = JSON.parse(result) || {};

        if ('c43f7e58-91e7-52fb-8f66-8c77fbc9cf5e' in (parsedResult.selectedLocations || {})) {
          delete parsedResult.selectedLocations['c43f7e58-91e7-52fb-8f66-8c77fbc9cf5e'];
        }
        const trackingConfiguration = { ...parsedResult, columns: TrackingConfigurationModel.columns };

        API.saveTrackingConfiguration(entityRef, { config: trackingConfiguration }).then();

        setCurrentTrackingConfiguration(trackingConfiguration);
      } else {
        API.saveTrackingConfiguration(entityRef, { config: TrackingConfigurationModel }).then();

        setCurrentTrackingConfiguration(TrackingConfigurationModel);
      }
    };

    if (entityRef) {
      run().then();
    }
  }, [entityRef]);

  useEffect(() => {
    if (currentTrackingConfiguration) {
      API.saveTrackingConfiguration(entityRef, { config: currentTrackingConfiguration }).then();
    }
  }, [entityRef, currentTrackingConfiguration]);

  const setLocationsSelected = useCallback(
    newValues => {
      setCurrentTrackingConfiguration(existingConfiguration => {
        const result = {
          ...existingConfiguration,
          selectedLocations: {
            ...(existingConfiguration?.selectedLocations || {}),
            ...newValues,
          },
        };

        return result;
      });
    },
    [setCurrentTrackingConfiguration]
  );

  return {
    trackingConfigurations,
    currentTrackingConfiguration,
    setLocationsSelected,
    setCurrentTrackingConfiguration,
  };
};

export default useTrackingConfigurations;
