import { useCallback, useEffect, useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { cacheItem, clearCachedItem } from '../../../util/cache';
import { useUserContext } from '../components/user-context/UserContextProvider';
import { onNewVersion } from './version';

export const X_CONNEX_VERSION_KEY = 'x-connex-version';

export const useVersion = () => {
  const [versionBusy, setVersionBusy] = useState(true);
  const [clientVersion, setClientVersion] = useState(null);
  const [latestVersion, setLatestVersion] = useState(null);
  const [newVersionAvailable, setNewVersionAvailable] = useState(null);
  const [hidden, setHidden] = useState(false);

  const handleNewVersion = useCallback(version => {
    setNewVersionAvailable(true);
    setLatestVersion(version);
    setHidden(false);
  }, []);

  // Temporarily perform version-checking only for those in the 'ui-test' group.
  const user = useUserContext();
  const isUiTester = (user?.permissions?.groups || []).includes('ui-test');

  useEffect(() => {
    if (isUiTester) {
      fetch(`/version.json?cb=${uuid()}`)
        .then(r => r.json())
        .then(response => {
          if (response.version) {
            setClientVersion(response.version);
            onNewVersion({ version: response.version, callback: handleNewVersion });
            cacheItem(X_CONNEX_VERSION_KEY, response.version);
          } else {
            setClientVersion(null);
            clearCachedItem(X_CONNEX_VERSION_KEY);
          }
        })
        .catch(() => {
          // Swallow the error.
          console.warn('version.json not found.');
          setClientVersion(null);
          clearCachedItem(X_CONNEX_VERSION_KEY);
        })
        .finally(() => {
          setVersionBusy(false);
        });
    }
  }, [handleNewVersion, isUiTester]);

  const hideVersionModal = useCallback(() => {
    setHidden(true);
  }, []);

  const unhideVersionModal = useCallback(() => {
    setHidden(false);
  }, []);

  const versionModalVisible = useMemo(() => {
    return !hidden && newVersionAvailable;
  }, [hidden, newVersionAvailable]);

  return {
    versionBusy,
    clientVersion,
    handleNewVersion,
    newVersionAvailable,
    hideVersionModal,
    unhideVersionModal,
    latestVersion,
    versionModalVisible,
  };
};
