import style from '../style';
import React, { useCallback } from 'react';
import styled from 'styled-components';
import { message, Popconfirm } from 'antd';
import InputText from '../../../components/input-text';
import InputSelect from '../../../components/input-select';
import { status } from '../options';
import SelectSetupItem from '../../../components/select-setup-item';
import SelectCarrier from '../../../components/select-carrier';
import SelectLocation from '../../../components/select-location';
import Button from '../../../../../components/button/Button';

const View = styled.div`
  ${style}
`;

const General = ({ vehicle, onChange }) => {
  const handleResetVehicle = useCallback(() => {
    onChange('ticket', null);
    message.success('The vehicle has been reset successfully!');
  }, [onChange]);

  return (
    <View>
      <InputText id="id" label="ID" value={vehicle?.id} onChange={onChange} required />
      <InputText id="name" label="Name" value={vehicle?.name} onChange={onChange} required />
      <SelectCarrier item={vehicle} onChange={onChange} required />
      <SelectSetupItem label="Vehicle Type" typeId="vehicle-type" item={vehicle} onChange={onChange} />
      <InputSelect id="status" label="Status" value={vehicle?.status} onChange={onChange} options={status} required />
      <SelectLocation label="Home Location" typeId="location" id={'homeLocation'} item={vehicle} onChange={onChange} />
      <SelectLocation
        label="From Location"
        typeId="location"
        id={'fromLocation'}
        item={vehicle}
        readOnly
        onChange={onChange}
      />
      <SelectLocation
        label="To Location"
        typeId="location"
        id={'toLocation'}
        item={vehicle}
        readOnly
        onChange={onChange}
      />
      <Popconfirm
        placement="top"
        title="Are you sure you want to reset this vehicle? If you choose 'Yes', please remember to click 'Save' before closing the Vehicle Details"
        onConfirm={handleResetVehicle}
        okText="Yes"
        cancelText="No"
      >
        <Button id="resetVehicle">Reset Vehicle</Button>
      </Popconfirm>
    </View>
  );
};

export default General;
