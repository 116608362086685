import {css} from 'styled-components';

export default css`
 

  .row-actions {
    display: flex;
    justify-content: space-evenly;

    i {
      cursor: pointer;
    }
  }


  .table-header {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    margin-bottom: 5px;

    button {
      font-size: 0.8rem;

      .icon {
        margin-right: 10px;
      }
    }
  }
`;
