import { Translate } from '../../views/order/components/localization/Translate';
import Button from '../button/Button';
import Radio from '../radio';
import SelectPlant from './SelectPlant';
import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import cn from 'classnames';
import { buttonClick } from '../../util/analytics/index';
import { DatePicker } from 'antd';
import { useIntlContext } from '../../i18n';
import { useDateFormat } from '../../hooks/useDateFormat';

const Increment = ({ increment, onClick }) => {
  const style = {
    cursor: 'pointer',
    fontSize: '1.5em',
  };
  return (
    <Button onClick={() => onClick(increment)}>
      <span className={`fa fa-chevron-${increment < 0 ? 'left' : 'right'}`} />
    </Button>
  );
};

const SelectDate = ({ onChange, date }) => {
  const { userLocale } = useIntlContext();
  const { dateFormat, antLocales } = useDateFormat(userLocale);

  const incrementDay = n => {
    onChange(moment(date).add(n, 'd').format('YYYY-MM-DD'));
  };

  return (
    <div>
      <div>
        <Translate stringId="selectDate" defaultMessage="Select Date" />
      </div>
      <Increment increment={-1} onClick={incrementDay} />
      <DatePicker
        onChange={e => onChange(moment(e.toDate()).format('YYYY-MM-DD'))}
        value={moment(date)}
        format={dateFormat}
        locale={antLocales[userLocale]}
      />
      <Increment increment={1} onClick={incrementDay} />
    </div>
  );
};

const Styled = styled.div`
  padding: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  .controls {
    flex: 1;
    &.hidden {
      display: none;
    }
    @media (min-width: 475px) {
      display: grid;
      grid-template-columns: auto 1fr auto;
      grid-gap: 20px;
      align-items: flex-end;
    }
  }
  .refresh-button {
    margin-left: 10px;
  }
`;

const Controls = ({
  onChangeDate,
  onChangeLocation,
  onChangeGraphType,
  location,
  locations,
  date,
  hideControls,
  onSubmit,
  onRefresh,
  loading,
  storeSelectedLocations,
}) => {
  return (
    <Styled>
      <div className={cn('controls', { hidden: hideControls })}>
        <SelectDate onChange={onChangeDate} date={date} />
        <SelectPlant
          onChange={onChangeLocation}
          selectedLocations={location}
          locationList={locations}
          storeSelectedLocations={storeSelectedLocations}
        />
        <Radio.Group
          label={<Translate stringId="graphType" defaultMessage="'Graph Type'" />}
          onChange={e =>
            buttonClick(
              onChangeGraphType && onChangeGraphType(e.target.value),
              `core-planning-graph-type-${e.target.value}`
            )
          }
          defaultValue={'overall'}
        >
          <Radio.Button value={'overall'}>
            <Translate stringId="overall" defaultMessage="Overall" />
          </Radio.Button>
          <Radio.Button value={'loading'}>
            <Translate stringId="loadingButtonLabel" defaultMessage="Loading" />
          </Radio.Button>
        </Radio.Group>
      </div>
      <div className="refresh-button">
        <div>
          <Translate stringId="refresh" defaultMessage="Refresh" />
        </div>
        <Button
          onClick={() => {
            onRefresh(date, location);
          }}
          disabled={loading}
        >
          <i className="fa fa-sync" />
        </Button>
      </div>
      {onSubmit && (
        <Button onClick={onSubmit} metricId="core-order-detail-save-modeling">
          <Translate stringId="submit" defaultMessage="Submit" />
        </Button>
      )}
    </Styled>
  );
};

export default Controls;
