/* global google */
import React from 'react';
import { useMapStateContext } from '../context/MapStateContext';

export const useMap = () => {
  const { map } = useMapStateContext();

  const fitBounds = React.useCallback(
    (...args) => {
      if (!map) return;

      const bounds = [].concat(...args);
      if (bounds?.length) {
        const latLngBounds = new google.maps.LatLngBounds();
        bounds.forEach(point => {
          if (point?.lat && point?.lng) {
            latLngBounds.extend({ lat: point.lat, lng: point.lng });
          }
        });
        map.fitBounds(latLngBounds);
      }
    },
    [map]
  );

  const zoomTo = React.useCallback(
    e => {
      const coordinates = e.latLng.toJSON();
      map?.setCenter(coordinates);
      map?.setZoom(17);
    },
    [map]
  );

  return { zoomTo, fitBounds };
};
