import style from './style';
import React from 'react';
import styled from 'styled-components';

const View = styled.div`
  ${style}
`;

const taxRateAsText = rate => {
  if (rate === undefined) {
    return '';
  }
  let percentage = Math.round(10000 * rate) / 100;
  return `${percentage}%`;
};

const TaxRateCard = ({ taxRate }) => {
  return (
    <View className={'tax-rate-card'}>
      <table>
        <thead>
          <tr>
            <th className={'blank-cell'}></th>
            <th>Jurisdiction</th>
            <th>Tax Code</th>
            <th>Sales</th>
            <th>Use</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>District</td>
            <td>{taxRate.jurisdiction?.zip}</td>
            <td>{taxRate.district?.taxCode}</td>
            <td>{taxRateAsText(taxRate.district?.sales)}</td>
            <td>{taxRateAsText(taxRate.district?.use)}</td>
          </tr>
          <tr>
            <td>City</td>
            <td>{taxRate.jurisdiction?.city}</td>
            <td>{taxRate.city?.taxCode}</td>
            <td>{taxRateAsText(taxRate.city?.sales)}</td>
            <td>{taxRateAsText(taxRate.city?.use)}</td>
          </tr>
          <tr>
            <td>County</td>
            <td>{taxRate.jurisdiction?.county}</td>
            <td>{taxRate.county?.taxCode}</td>
            <td>{taxRateAsText(taxRate.county?.sales)}</td>
            <td>{taxRateAsText(taxRate.county?.use)}</td>
          </tr>
          <tr>
            <td>State</td>
            <td>{taxRate.jurisdiction?.state}</td>
            <td>{taxRate.state?.taxCode}</td>
            <td>{taxRateAsText(taxRate.state?.sales)}</td>
            <td>{taxRateAsText(taxRate.state?.use)}</td>
          </tr>
          <tr>
            <td>Country</td>
            <td>{taxRate.jurisdiction?.country}</td>
            <td>{taxRate.country?.taxCode}</td>
            <td>{taxRateAsText(taxRate.country?.sales)}</td>
            <td>{taxRateAsText(taxRate.country?.use)}</td>
          </tr>
          <tr>
            <td>Total</td>
            <td></td>
            <td></td>
            <td>{taxRateAsText(taxRate.total?.sales)}</td>
            <td>{taxRateAsText(taxRate.total?.use)}</td>
          </tr>
        </tbody>
      </table>
      <div>Taxable Service: {taxRate.taxable?.service}</div>
      <div>Taxable Freight: {taxRate.taxable?.freight}</div>
      <div>Location: {taxRate.taxLocation}</div>
    </View>
  );
};

export default TaxRateCard;
