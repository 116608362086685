import React from 'react';
import styled from 'styled-components';
import style from '../style';
import InputNumber from '../../../components/input-number';
import InputSelect from '../../../components/input-select';
import { uomValues } from '../../../../../util/uom/uom-codes-assurance';

const View = styled.div`
  ${style}
`;

const ReturnedOnVehicle = ({ label, field, item, onChange, uomCodes }) => {
  const handleChange = React.useCallback((path, value) => {
    onChange(`${field}.${path}`, value);
  }, []);

  return (
    <View>
      <InputNumber id="value" label={`${label} Value`} value={item?.value} onChange={handleChange} />
      <InputSelect
        id="uomCode"
        label={`${label} Unit of Measure`}
        value={item?.uomCode}
        onChange={handleChange}
        options={uomValues(uomCodes)}
        required
      />
    </View>
  );
};

export default ReturnedOnVehicle;
