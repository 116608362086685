import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import api from '../../../api';
import { useParams } from 'react-router-dom';
import InputSelect from '../../../components/input-select';
import InputCheckbox from '../../../components/input-checkbox';
import { find } from 'lodash';

const View = styled.div`
  .dexa-selector {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex: 1;
    .mock-batching-checkbox {
      display: flex;
      flex: 0 0 1;
      flex-direction: column;
      display: flex;
      align-items: center;
    }
  }
`;

const DexaMapping = ({ value, id, onChange, integratedSystem }) => {
  const [dexaOptions, setDexaOptions] = useState();
  const [dexaResults, setDexaResults] = useState();
  const [connections, setConnections] = useState(null);
  const [selectedDexaCrn, setSelectedDexaCrn] = useState();
  const [isLocationPlantsLoading, setIsLocationPlantsLoading] = useState(false);
  const [locationOptions, setLocationOptions] = useState();
  const [plantOptions, setPlantOptions] = useState();
  const { entityRef } = useParams();

  const handleConnectionNameChange = useCallback(
    (fieldId, fieldValue) => {
      let ulink = null;

      if (!fieldValue.startsWith('ULINK')) {
        onChange(id, { ...value, [fieldId]: fieldValue, ulink });
        return;
      }

      const ulinkServer = fieldValue.split(' ')?.[1];
      ulink =
        ulinkServer &&
        dexaResults &&
        find(dexaResults, d => d.crn === value?.dexaId)?.ulinks?.find(u => u.server === ulinkServer);

      if (!ulink) {
        onChange(id, { ...value, [fieldId]: fieldValue, ulink });
        return;
      }

      const { server, port, pollingIntervalMilliseconds } = ulink;
      onChange(id, {
        ...value,
        [fieldId]: null,
        ulink: {
          server,
          port,
          pollingIntervalMilliseconds,
        },
      });
      return;
    },
    [value, onChange, id, dexaResults]
  );

  const handleDexaChange = useCallback(
    (fieldId, fieldValue) => {
      onChange(id, { ...value, [fieldId]: fieldValue, cbLocationCode: null, cbPlantCode: null });
    },
    [value, onChange, id]
  );

  const handleChange = useCallback(
    (fieldId, fieldValue) => {
      onChange(id, { ...value, [fieldId]: fieldValue });
    },
    [value, onChange, id]
  );

  useEffect(() => {
    api.getDexas(entityRef).then(result => {
      setDexaOptions(result.items.map(v => ({ value: v.crn, label: v.description })));
      setDexaResults(result.items);
    });
  }, [entityRef]);

  useEffect(() => {
    //update connection settings.
    const selectedDexa = find(dexaResults, d => d.crn === value?.dexaId);
    console.log(dexaResults, selectedDexa, value);
    const newConnections = [];
    if (selectedDexa?.connections?.length > 0) {
      newConnections.push(...selectedDexa?.connections?.map(c => ({ value: c.name })));
    } else {
      newConnections.push({
        value: 'APEX',
      });
    }
    if (selectedDexa?.ulinks?.length > 0) {
      newConnections.push(...selectedDexa?.ulinks?.map(c => ({ value: `ULINK ${c.server}` })));
    }
    setConnections(newConnections);

    if (value?.dexaId && (integratedSystem === 'COMMAND_BATCH' || integratedSystem === 'CONNEX') && value.dexaId !== selectedDexaCrn) {
      setIsLocationPlantsLoading(true);
      api
        .getDexaLocationPlants(entityRef, value.dexaId)
        .then(result => {
          setLocationOptions(
            result.locations
              .map(x => {
                const [_, locationId] = x.id?.split('_') ?? [];
                if (!locationId) {
                  return null;
                }
                return {
                  value: locationId,
                  label: `${locationId} / ${x.location?.description ?? x.location?.other_code}`,
                };
              })
              .filter(x => x)
          );
          setPlantOptions(
            result.plants
              .map(x => {
                const [_, plantId] = x.id?.split('_') ?? [];
                if (!plantId) {
                  return null;
                }
                return {
                  value: plantId,
                  label: `${plantId} / ${x.plant?.description ?? x.plant?.other_code}`,
                };
              })
              .filter(x => x)
          );
        })
        .finally(() => setIsLocationPlantsLoading(false));
    }

    if (value?.dexaId !== selectedDexaCrn) {
      setSelectedDexaCrn(value?.dexaId);
    }
  }, [dexaResults, value]);

  return (
    <View>
      <div className={'dexa-selector'}>
        <InputSelect
          options={dexaOptions}
          value={value?.dexaId}
          id={'dexaId'}
          onChange={handleDexaChange}
          label={'Dexa'}
        />

        <InputCheckbox
          className={'mock-batching-checkbox'}
          value={value?.useMockBatching}
          id={'useMockBatching'}
          label={'Use Mock Batching'}
          onChange={handleChange}
          metricId={'dexa-use-mock-batching'}
        />
      </div>
      <div className={'dexa-selector'}>
        <InputSelect
          options={connections || []}
          value={value?.connectionName || (value?.ulink && `ULINK ${value.ulink.server}`)}
          id={'connectionName'}
          onChange={handleConnectionNameChange}
          label={'Connection'}
          disabled={connections === null}
        />
        <InputCheckbox
          className={'mock-batching-checkbox'}
          value={value?.useMixSyncing}
          id={'useMixSyncing'}
          label={'Use Mix Syncing'}
          onChange={handleChange}
          metricId={'dexa-use-mix-syncing'}
        />
      </div>

      {(integratedSystem === 'COMMAND_BATCH' || integratedSystem === 'CONNEX') && (
        <>
          <InputSelect
            options={locationOptions}
            value={value?.cbLocationCode}
            id={'cbLocationCode'}
            onChange={handleChange}
            label={'COMMANDbatch Location'}
            required={false}
            disabled={!value?.dexaId}
            busy={isLocationPlantsLoading}
          />
          <InputSelect
            options={plantOptions}
            value={value?.cbPlantCode}
            id={'cbPlantCode'}
            onChange={handleChange}
            label={'COMMANDbatch Plant'}
            required={false}
            disabled={!value?.dexaId}
            busy={isLocationPlantsLoading}
          />
        </>
      )}
    </View>
  );
};

export default DexaMapping;
