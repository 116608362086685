
export const status = [
  { value: 'ACTIVE' },
  { value: 'INACTIVE' },
];

export const direction = [
  { value: 'BOTH' },
  { value: 'INBOUND' },
  { value: 'OUTBOUND' },
]

export const uoms = [
  {value: 'LB', label: 'POUNDS'},
  {value: 'KG', label: 'KILOGRAMS'},
]
