import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { Translate } from '../../../../order/components/localization/Translate';
import { useTranslateMessage } from '../../../../order/components/localization/useTranslateMessage';
import style from './style';
import cn from 'classnames';
import DataLine from '../../components/data-line';
import { get } from 'lodash';
import { ContextMenuTrigger } from '../../dashboard-menu/context-menu';
import { useDashboardContext } from '../../context/useDashboardContext';

const columnFields = ['id', 'nextLoadScheduledTime', 'location.id', 'customerParty.id'];

const OrdersToLoad = ({ className, view = {}, setTitle }) => {
  const { openTicketDrawer } = useDashboardContext();

  useEffect(() => {
    setTitle(<Translate stringId="ordersToLoad" defaultMessage="Orders to Load" />);
  }, [setTitle]);

  const loadsList = useMemo(() => {
    const result = (view?.loads || []).slice();
    result.sort((a, b) => {
      return get(a, 'nextLoadScheduledTime') < get(b, 'nextLoadScheduledTime') ? -1 : 1;
    });
    return result;
  }, [view?.loads]);

  const translateMessage = useTranslateMessage();

  const columnHeaders = React.useMemo(() => {
    return [
      { stringId: 'orderId', defaultMessage: 'Order ID' },
      { stringId: 'timer', defaultMessage: 'Timer' },
      { stringId: 'location', defaultMessage: 'Location' },
      { stringId: 'customer', defaultMessage: 'Customer' },
    ].map(column => {
      return translateMessage(column);
    });
  }, [translateMessage]);

  return (
    <div className={cn(className)}>
      <DataLine type={'order'} header columns={columnHeaders.map(value => ({ value }))} />
      {loadsList.map((load, key) => {
        const columns = columnFields.map((field, key) => {
          let value = get(load, field);

          if (field === 'location.id') {
            const locationName = get(load, 'location.name');
            if (locationName) {
              value = `${value} (${locationName})`;
            }
          }

          if (field === 'customerParty.id') {
            const locationName = get(load, 'customerParty.name');
            if (locationName) {
              value = `${value} (${locationName})`;
            }
          }

          return {
            configuration: {
              type: key === 1 && 'timer',
              countingDown: true,
            },
            value,
          };
        });

        return (
          <ContextMenuTrigger key={load.crn} type={'load'} item={load}>
            <DataLine
              dropType={'vehicle'}
              backgroundColor={load.tracking?.backgroundColor}
              onDrop={vehicle => {
                openTicketDrawer(
                  load.crn,
                  vehicle?.carrierRef || vehicle?.carrierParty?.carrierRef,
                  vehicle.crn,
                  vehicle?.configuration?.driver?.driverRef
                );
              }}
              type={'order'}
              columns={columns}
            />
          </ContextMenuTrigger>
        );
      })}
    </div>
  );
};

export default styled(OrdersToLoad)`
  ${style}
`;
