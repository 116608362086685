import style from '../style';
import React from 'react';
import styled from 'styled-components';
import InputText from '../../../components/input-text';
import InputSelect from '../../../components/input-select';
import { iconList, statusList } from '../options';
import FormError from '../../../components/form-error';

const View = styled.div`
  ${style}
`;

const General = ({ trackableType, onChange, error }) => {
  const isDevelop = window.location.origin.includes('develop') || window.location.origin.includes('localhost');
  const origin = isDevelop ? 'https://geo-service-develop.commandalkon.io' : 'https://geo-service.commandalkon.io';
  const baseUrl = origin + '/assets/map-icons';

  return (
    <View>
      <FormError error={error} />
      <InputText id="id" label="ID" value={trackableType?.id} onChange={onChange} />
      <InputText id="name" label="Name" value={trackableType?.name} onChange={onChange} />
      <InputSelect id="status" label="Status" value={trackableType?.status} onChange={onChange} options={statusList} />
      <InputSelect id="icon" label="Icon" value={trackableType?.icon} onChange={onChange} options={iconList} required />
      {trackableType?.icon && (
        <div style={{ textAlign: 'center', margin: 25 }}>
          <img src={`${baseUrl}/${trackableType.icon}-white.png`} alt={trackableType.icon} style={{ width: 50 }} />
        </div>
      )}
    </View>
  );
};

export default General;
