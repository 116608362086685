import cn from 'classnames';
import { DateTime } from 'luxon';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import FieldContainer from '../../../../components/field-container/FieldContainer';
import { useIntlContext } from '../../../../i18n';
import Input from '../Input';
import { Translate } from '../localization/Translate';
import Date from './Date';
import { dateTimeStrings, getDateTimeFromStrings, handleDateShortcuts, handleDateTimeShortcuts } from './util';
import style from './style';
import Time from './Time';

const DateTimePicker = props => {
  const { id, className, required, disabled, initialValue, value, onChange, timeZone, innerRef, readOnly } = props;

  const stringValues = React.useMemo(() => {
    return dateTimeStrings(initialValue, timeZone);
  }, [initialValue, timeZone]);

  const [dateString, setDateString] = React.useState(stringValues.date);
  const [timeString, setTimeString] = React.useState(stringValues.time);
  const [dateTime, setDateTime] = React.useState(null);
  const [tzAbbreviation, setTzAbbreviation] = React.useState('');
  const [dow, setDow] = React.useState('');

  const updateDateTimeValue = React.useCallback(
    (dateValue, timeValue, sendUpdate = true) => {
      const isoString = getDateTimeFromStrings(dateValue, timeValue, timeZone);
      if (isoString && onChange && sendUpdate) {
        onChange(id ?? 'startDateTime', isoString);
      }
    },
    [onChange, timeZone, id]
  );

  const setDateTimeStrings = React.useCallback(
    (isoString, sendUpdate = true) => {
      const strings = dateTimeStrings(isoString, timeZone);
      setDateString(strings.date);
      setTimeString(strings.time);
      updateDateTimeValue(strings.date, strings.time, sendUpdate);
    },
    [timeZone, updateDateTimeValue]
  );

  useEffect(() => {
    if (value) {
      setDateTimeStrings(value, false);
    }
  }, [value, setDateTimeStrings]);

  const handleCaptureDateTime = React.useCallback(
    e => {
      if (!readOnly) handleDateTimeShortcuts(e, dateTime, setDateTimeStrings);
    },
    [dateTime, readOnly, setDateTimeStrings]
  );

  const handleCaptureDate = React.useCallback(
    e => {
      if (!readOnly) handleDateShortcuts(e, dateTime, setDateTimeStrings);
    },
    [dateTime, readOnly, setDateTimeStrings]
  );

  const handleDateChange = React.useCallback(
    dateStr => {
      setDateString(dateStr);
      updateDateTimeValue(dateStr, timeString, true, true);
    },
    [timeString, updateDateTimeValue]
  );

  const handleTimeChange = React.useCallback(
    timeStr => {
      setTimeString(timeStr);
      updateDateTimeValue(dateString, timeStr, true, true);
    },
    [dateString, updateDateTimeValue]
  );
  const { userLocale } = useIntlContext();
  React.useEffect(() => {
    try {
      const isoString = getDateTimeFromStrings(dateString, timeString, timeZone);
      if (!isoString) return;
      const dt = DateTime.fromISO(isoString).setZone(timeZone).setLocale(userLocale);
      setTzAbbreviation(dt.toFormat('ZZZZ')?.toUpperCase?.());
      setDow(dt.toFormat('cccc'));
      setDateTime(dt);
    } catch (e) {}
  }, [timeZone, tzAbbreviation, dateString, timeString, userLocale]);

  return (
    <div className={cn('date-time-picker', className)}>
      <FieldContainer
        className={cn('start-date-time', className, { disabled })}
        onKeyDownCapture={handleCaptureDateTime}
      >
        <div className="left" data-testid="input-start-date">
          <div className="label">
            <Translate
              stringId="startDateTimeWithTimeZone"
              defaultMessage="Start Date/Time{timeZone}"
              values={{ timeZone: tzAbbreviation ? ` (${tzAbbreviation})` : '' }}
            />
            {required && <span className="required-indicator">*</span>}
          </div>
          <Date value={dateString} onChange={handleDateChange} disabled={readOnly || disabled} innerRef={innerRef} />
        </div>
        <div className="right" data-testid="input-start-time">
          <div className="label">{dow}</div>
          {readOnly ? (
            <Input readOnly disabled value={timeString} type="time" />
          ) : (
            <Time value={timeString} onChange={handleTimeChange} disabled={disabled} />
          )}
        </div>
      </FieldContainer>
    </div>
  );
};

export default styled(DateTimePicker)`
  ${style}
`;
