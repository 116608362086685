import React from 'react';
import { useParams } from 'react-router-dom';
import { useMapStateContext } from '../context/MapStateContext';
import { useCalculateRoute } from './routing/useCalculateRoute';
import updateDestination from './util/updateDestination';

export const useUpdateDestinationMarker = () => {
  const { entityRef } = useParams();
  const { calculateRoute } = useCalculateRoute();
  const { setUpdatingDestination, setDestination, waypoints } = useMapStateContext();

  const updateDestinationMarker = React.useCallback(
    async ({ marker }) => {
      const coordinates = {
        lat: marker.lat,
        lng: marker.lng,
        radius: marker.radius,
      };
      setUpdatingDestination(true);

      await updateDestination({ orderRef: marker.orderRef, coordinates, entityRef });

      setDestination(marker);
      setUpdatingDestination(false);

      calculateRoute({ waypoints });
    },
    [calculateRoute, entityRef, setDestination, setUpdatingDestination, waypoints]
  );

  return { updateDestinationMarker };
};
