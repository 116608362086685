import { useMapBehaviorContext } from '../../context/MapBehaviorContext';
import { useMapStateContext } from '../../context/MapStateContext';

export const useRoute = () => {
  const { addWaypoint } = useMapBehaviorContext();
  const { disabled, allowRouting, canUpdate, route, calculatingRoute, updatingDestination } = useMapStateContext();

  return {
    addWaypoint,
    disabled,
    allowRouting,
    canUpdate,
    route,
    calculatingRoute,
    updatingDestination,
    id: 'route',
    type: 'route',
  };
};
