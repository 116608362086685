import { css } from 'styled-components';

export default css`
  .fields {
    display: flex;
    grid-gap: 10px;
    .small {
      max-width: 60px;
      text-align: center;
    }
  }
`;
