import React, { useState, useCallback, useEffect, useRef, useMemo } from 'react';
import FlexLayout from 'flexlayout-react';
import 'flexlayout-react/style/dark.css';
import DashboardWindows from './windows';
import DashboardMenu from './dashboard-menu';
import { get } from 'lodash';
import { DashboardContextProvider, useDashboardContext } from './context/useDashboardContext';
import styled from 'styled-components';
import cn from 'classnames';
import WindowWrapper from './components/window-wrapper';
import { ContextMenuContextProvider } from './dashboard-menu/context-menu/useContextMenuContext';
import ContextMenu from './dashboard-menu/context-menu';
import { HTML5Backend } from 'react-dnd-html5-backend';
import API from '../api';

import { DndProvider } from 'react-dnd';

const { Layout } = FlexLayout;

const opts = {
  enableMouseEvents: true,
};

const ScreenLayout = () => {
  const { setWindowLayoutRef, layoutModel, entityRef } = useDashboardContext();
  const [titleLookup, setTitleLookup] = useState({});

  const layoutModelObject = useMemo(() => {
    return FlexLayout.Model.fromJson(layoutModel);
  }, [layoutModel]);

  const titleFactory = useCallback(
    node => {
      const id = get(node, '_attributes.id');
      if (id) {
        const name = titleLookup[id];
        return name || node.getName();
      }
      return 'Loading';
    },
    [titleLookup]
  );

  const setTitle = React.useCallback((title, nodeAttributesId) => {
    setTitleLookup(existingValue => {
      const result = { ...existingValue };
      result[nodeAttributesId] = title;
      return result;
    });
  }, []);

  const nodeFactory = useCallback(node => {
    const component = node.getComponent();

    const WindowComponent = DashboardWindows[component];

    if (WindowComponent) {
      return (
        <WindowWrapper title={titleLookup[node._attributes.id]} windowType={component}>
          <WindowComponent setTitle={setTitle} node={node} />
        </WindowWrapper>
      );
    }
    return <div></div>;
  }, []);

  const saveLastLayout = useCallback(
    config => {
      if (config) {
        // cacheItem(`DASHBOARD_LAYOUT#${entityRef}`, JSON.stringify(config))
        API.saveScreenLayout(entityRef, { config }).then();
        // setLayoutModel(config);
      }
    },
    [entityRef]
  );

  return (
    <div className={'dashboard-container'}>
      <DashboardMenu />
      <div className={'dashboard-contents'}>
        <Layout
          titleFactory={titleFactory}
          factory={nodeFactory}
          onModelChange={model => {
            console.log('model', model.toJson());
            saveLastLayout(model.toJson());
          }}
          ref={a => setWindowLayoutRef(a)}
          model={layoutModelObject}
        />
      </div>
    </div>
  );
};

const Dashboard = ({ className }) => {
  return (
    <div className={cn(className)}>
      <DashboardContextProvider>
        <ContextMenuContextProvider>
          <DndProvider backend={HTML5Backend} options={opts}>
            <ScreenLayout />
            <ContextMenu />
          </DndProvider>
        </ContextMenuContextProvider>
      </DashboardContextProvider>
    </div>
  );
};

export default styled(Dashboard)`
  height: 100vh;
  overflow: hidden;
  background: black;
  display: flex;
  flex: 1;
  .dashboard-container {
    height: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
  }
  .dashboard-contents {
    flex-grow: 1;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    position: relative;
  }
`;
