import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import queryString from 'query-string';
import moment from 'moment';
import { cloneDeep } from 'lodash';
import { QUICK_DATES } from '../../../components/quick-dates/QuickDates';
import { api } from '../api';

export const useOrders = () => {
  const { entityRef } = useParams();
  const [orders, setOrders] = useState([]);
  const [ordersBusy, setOrdersBusy] = useState(false);
  const [quickDate, setQuickDate] = useState(QUICK_DATES.TODAY);

  const handleQuickDateChange = useCallback(quickDate => {
    setQuickDate(quickDate);
  }, []);

  const [modalVisible, setModalVisible] = useState(false);

  const getOrders = useCallback(
    quickDate => {
      if (!quickDate) return;

      setOrders([]);
      setOrdersBusy(true);

      const queryParams = `?${queryString.stringify(quickDate.getRange(quickDate.date), { encode: false })}`;

      return api
        .listOrderRequests(entityRef, queryParams)
        .then(resp => {
          setOrders(resp);
          return resp;
        })
        .finally(() => {
          setOrdersBusy(false);
        });
    },
    [entityRef]
  );

  const datePickerProps = useMemo(() => {
    return {
      visible: modalVisible,
      onCancel: () => setModalVisible(false),
      onClose: () => setModalVisible(false),
      onOk: customDate => {
        console.log('***', customDate);
        QUICK_DATES.CUSTOM.date = customDate;
        setQuickDate(QUICK_DATES.CUSTOM);
        setModalVisible(false);
      },
      onOpen: () => setModalVisible(true),
    };
  }, [modalVisible]);

  return { getOrders, orders, ordersBusy, quickDate, handleQuickDateChange, datePickerProps };
};
