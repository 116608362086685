/**
 * This function calculates the qty of a product
 *
 * @param  {string} supplierRef
 * @param  {ProductLoadQuantities} existingOrder
 * @returns {number}
 */
const calculateLoadCountWithMinimumLoadChargeProuct = (productRef, minimumLoadChargeLoadsData) => {
  let total = 0;

  if (!minimumLoadChargeLoadsData?.[productRef]) return total;

  Object.keys(minimumLoadChargeLoadsData?.[productRef]).forEach(load => {
    if (minimumLoadChargeLoadsData?.[productRef]?.[load] > 0) {
      total++;
    }
  });

  return total;
};

export default calculateLoadCountWithMinimumLoadChargeProuct;
