import style from '../style';
import React, { useCallback } from 'react';
import styled from 'styled-components';
import ContactListEditor from '../../components/contact-list-editor/ContactListEditor';
import useValidateField from '../../../../hooks/useValidateField';

const View = styled.div`
  ${style}
`;

const CompanyGeneral = ({ item, onChange }) => {
  const { validateField, validationErrors } = useValidateField();

  const handleChange = useCallback(
    (id, value) => {
      validateField(id, value);
      onChange(id, value);
    },
    [validateField, onChange]
  );
  return (
    <View>
      <ContactListEditor
        onChange={handleChange}
        item={item}
        typeId="company-editor"
        validationErrors={validationErrors}
      />
    </View>
  );
};

export default CompanyGeneral;
