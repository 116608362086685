import React from 'react';
import styled from 'styled-components';
import Input from '../../../../components/form/Input';
import Margin from '../../components/Margin';
import { useScaleTicketingContext } from '../../Context';

const Styled = styled(Margin)`
  line-height: 1em;

  .field-label {
    font-size: 0.8rem;
  }

  .value {
    background-color: transparent;
    font-weight: bold;
    font-size: 1.3rem;
    user-select: none;
    margin-top: 10px;
  }
`;
const MaxGvw = () => {
  const { form } = useScaleTicketingContext();

  return (
    <Styled top={10}>
      <div className="field-label">Max GVW</div>
      <div className="value">{form?.vehicle?.maxGrossVehicleWeight || '-----'}</div>
    </Styled>
  );
};

export default MaxGvw;
