import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import Spinner from '../../../../../../components/spinner/Spinner';
import api from '../../../../../tracking/api';
import { api as ticketApi } from '../../../../../order/components/tickets/api';
import DynamicForm from '../../../../../tracking/dashboard/components/dynamic-form';
import IfSeller from '../../../../components/IfSeller';
import { useShortDate } from '../../../../components/localization/ShortDate';
import { Time, useTime } from '../../../../components/localization/Time';
import { Translate } from '../../../../components/localization/Translate';
import { Uom } from '../../../../components/localization/Uom';
import { useTranslateMessage } from '../../../../components/localization/useTranslateMessage';
import FadeInDiv from '../../FadeInDiv';
import CancelVoid from './cancel-void/CancelVoid';
import style from './style';
import cn from 'classnames';
import ticketForm from './ticket-form';
import usePersona from '../../../usePersona';
import Button from '../../../../../../components/button/Button';
import { motion } from 'framer-motion';
import MixInfo from './MixInfo';
import { find } from 'lodash';
import useSetup from '../../../../../setup/useSetup';
import { Switch } from 'antd';
import BatchData from './BatchData';
import TextAreaField from '../../../../components/TextAreaField';
import getUomAbbreviationOrDescription from '../../../../../../util/uom';
import ReActivateTicket from './re-activate-ticket';
import { useUserContext } from '../../../../components/user-context/UserContextProvider';
import calculateAboveShippedAmount from './util/calculate-above-shipped-amount';

const TicketDetails = ({ className, parameters = {}, onCancelVoid, setupItems, supplierRef, orderRef }) => {
  const user = useUserContext();

  const { entityRef } = useParams();
  const persona = usePersona();
  const { getVehicle, vehicle } = useSetup();
  const [ticket, setTicket] = useState(null);
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [tempBillingNote, setTempBillingNote] = useState('');

  const translateMessage = useTranslateMessage();

  useEffect(() => {
    const run = async () => {
      try {
        setLoading(true);
        const orderCrn = orderRef || parameters?.orderRef;
        const [ticketResponse, orderResponse] = await Promise.all([
          api.getTicket(entityRef, parameters.ticketRef, {
            supplierRef: persona === 'buyer' ? supplierRef : undefined,
            orderRef: persona === 'buyer' ? orderRef : undefined,
            checkIfBilled: true,
          }),
          orderCrn ? ticketApi.getOrder(entityRef, orderCrn, {}) : null,
        ]);
        setTicket(ticketResponse);
        setOrder(orderResponse);
      } catch (e) {
        console.log('e', e);
      } finally {
        setLoading(false);
      }
    };
    if (parameters?.ticketRef) {
      run().then();
    }
  }, [entityRef, parameters.ticketRef, persona, supplierRef, orderRef]);

  useEffect(() => {
    if (ticket?.sk?.vehicleRef) {
      getVehicle(ticket?.sk?.vehicleRef).then();
    }
  }, [ticket?.sk?.vehicleRef, getVehicle]);

  useEffect(() => {
    if (!ticket) return;
    setTempBillingNote(ticket.billingNote);
  }, [ticket]);

  const [showDetails, setShowDetails] = useState(true);

  const { getShortDate } = useShortDate();
  const { getTime } = useTime();

  const formattedTicket = useMemo(() => {
    if (ticket) {
      // eslint-disable-next-line prefer-template
      const address2 = ticket?.destination?.address?.address2 ? ticket?.destination?.address?.address2 + '\n' : '';
      const { customerStatus = {} } = ticket;
      const { statusDateTime } = customerStatus;

      const waterAdded = ticket?.waterAdded?.quantity
        ? `${ticket.waterAdded.quantity} ${getUomAbbreviationOrDescription(ticket.waterAdded.uomCode)}`
        : '0';

      const numberOfVoidedTickets =
        order?.deliverySchedule?.tickets?.reduce((count, t) => (t?.ticket?.isVoided ? count + 1 : count), 0) || 0;
      const loadNumber = ticket?.loadNumber - numberOfVoidedTickets;
      const accumulatedLoad = loadNumber === 0 ? 1 : loadNumber;
      const totalOfLoads =
        (order?.deliverySchedule?.schedule.at(-1)?.loadNumber ||
          order?.deliverySchedule?.tickets?.reduce((count, t) => (!t?.ticket?.isVoided ? count + 1 : count), 0)) -
        numberOfVoidedTickets;

      const displayLabels = {
        products: <Translate stringId="products" defaultMessage="Products" />,
        waterAdded: (
          <Translate stringId="waterAdded" defaultMessage="Water Added: {waterAdded}" values={{ waterAdded }} />
        ),
        ticketEvents: <Translate stringId="ticketEvents" defaultMessage="Ticket Events" />,
        statusChangeTime: `${getShortDate(statusDateTime)} ${getTime(statusDateTime)}`?.trim?.(),
        testResultStatus: <Translate stringId="notAssessed" defaultMessage="Not Assessed" />,
        dispatchDateTimeDisplay: `${getShortDate(ticket?.dispatchDateTime)} ${getTime(
          ticket?.dispatchDateTime
        )}`?.trim?.(),
        formattedLineItems: (ticket?.lineItems || []).map(l => [
          `${l?.item?.id} / ${l?.item?.description}`,
          <Uom uom={{ uomCode: l?.quantity?.uomCode, value: l?.quantity?.value }} />,
        ]),
        formattedTicketEvents: (ticket?.ticketEvents || []).map(l => [
          <Translate stringId={l?.statusCode} defaultMessage={l?.statusCode} />,
          <Time date={l.eventDateTime} />,
        ]),

        // totalShipped: `Load #${ticket?.dispatchOrder?.totalTicketed?.loadNumber} ${ticket?.dispatchOrder?.totalTicketed?.value} / ${ticket?.dispatchOrder?.totalOrdered?.value} ${ticket?.dispatchOrder?.totalOrdered?.uomCode}`,
        totalShipped: (
          <Translate stringId="ticketNumber" defaultMessage="Ticket #{value}" values={{ value: ticket?.id }} />
        ),
        carrier: `${ticket?.carrierParty?.id} / ${ticket?.carrierParty?.name}`,
        driver: `${ticket?.driver?.id} / ${ticket?.driver?.lastName}`,
        origin: `${ticket?.origin?.id} / ${ticket?.origin?.description}`,
        customer: `${ticket?.customerParty?.id} / ${ticket?.customerParty?.name}`,
        supplier: `${ticket?.supplierParty?.id ? `${ticket?.supplierParty?.id} / ` : ''}${ticket?.supplierParty?.name}`,
        project: `${ticket?.supplierSalesOrder?.id} / ${ticket?.supplierSalesOrder?.name}`,
        projectContractNumber: `${
          ticket?.supplierSalesOrder?.dotContractId || ticket?.supplierSalesOrder?.contractNumber || ''
        }`, //fall through for compatibility with existing tickets
        destinationAddress: `${ticket?.destination?.address?.address1 || ''}\n${address2 || ''}${
          ticket?.destination?.address?.city || ''
        }, ${ticket?.destination?.address?.countrySubDivision || ''}  ${
          ticket?.destination?.address?.postalCode || ''
        }  ${ticket?.destination?.address?.countryCode || ''}`,
        statusCode: `${
          ticket.supplierStatus.statusCode === 'VOIDED'
            ? translateMessage({ stringId: 'VOIDED', defaultMessage: 'VOIDED' })
            : translateMessage({ stringId: customerStatus.statusCode, defaultMessage: customerStatus.statusCode })
        }`,
        statusCodeTooltip:
          ticket.supplierStatus.statusCode === 'VOIDED'
            ? `${ticket.supplierStatus?.reasonCode}${
                ticket?.supplierStatus?.reason && ` -- ${ticket?.supplierStatus?.reason}`
              }`
            : null,
        billingNote: ticket?.billingNote,
        accumulatedLoad: ticket?.isVoided ? (
          ''
        ) : (
          <Translate
            stringId="accumulatedLoad"
            defaultMessage="Load {accumulated}/{total}"
            values={{
              accumulated: accumulatedLoad,
              total: totalOfLoads,
            }}
          />
        ),
      };
      ticket.displayLabels = displayLabels;
    }
    return ticket;
  }, [ticket, getShortDate, getTime, translateMessage, order]);

  const primaryLineItem = useMemo(() => {
    return find(ticket?.lineItems, l => l.isPrimary === true) || ticket?.lineItems?.[0];
  }, [ticket]);

  const handleUpdateTicketBillingNote = useCallback(async () => {
    if (!entityRef || !ticket) return;

    try {
      setLoading(true);
      await api.updateTicketBillableNote(entityRef, ticket.crn, { billingNote: tempBillingNote });
      setTicket({ ...ticket, billingNote: tempBillingNote });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [entityRef, ticket, tempBillingNote]);

  const onChangeBillingNote = useCallback(value => {
    setTempBillingNote(value);
  }, []);

  const showReactivateTicket = useMemo(() => {
    if (!user.hasPermissions('authorities.enable_rmx-dispatch_ticketing_re-activate-tickets')) return false;
    if (ticket?.isBilled) return false;
    if (!ticket?.isVoided) return false;
    const loadingCompleteEvent = ticket?.ticketEvents?.find(tE => tE?.statusCode === 'LOADING_COMPLETE');
    if (!loadingCompleteEvent) return false;
    return true;
  }, [ticket, user]);

  const aboveShippedAmount = useMemo(() => {
    if (!order || !ticket?.isVoided) return 0;
    const primaryProduct = ticket?.lineItems?.find(p => p.isPrimary);

    return calculateAboveShippedAmount(order, primaryProduct?.quantity?.value);
  }, [order, ticket]);

  return (
    <Spinner spin={loading}>
      <FadeInDiv className={cn(className)}>
        <div className="ticket-header">
          <motion.div className="left back-action">
            <Button
              type="primary"
              size="small"
              onClick={onCancelVoid}
              metricId="core-ticket-detail-back-to-order"
              icon={<i className="icon fa fa-chevron-left" />}
              data-testid="button-back-to-order"
            >
              <Translate stringId="backToOrder" defaultMessage="Back to Order" />
            </Button>
          </motion.div>
          {ticket?.production && (
            <Switch
              style={{ marginLeft: 'auto' }}
              checkedChildren={<Translate stringId="details" defaultMessage="Details" />}
              unCheckedChildren={<Translate stringId="batchWeights" defaultMessage="Batch Weights" />}
              size={'default'}
              defaultChecked
              onChange={checked => setShowDetails(checked)}
            />
          )}
        </div>
        <div className={'ticket-form'}>
          {ticket && !loading ? (
            showDetails === true ? (
              <>
                <DynamicForm item={formattedTicket} form={ticketForm} defaultColumnNumber={12} />
                <MixInfo primaryLineItem={primaryLineItem} />
                <TextAreaField
                  className="text-area"
                  fieldName={'billingNote'}
                  value={tempBillingNote}
                  onChange={(_, newValue) => onChangeBillingNote(newValue)}
                  fieldLabel=<Translate stringId="billingNote" defaultMessage="Billing Note" />
                />
                <div className="buttons-container">
                  <Button type="primary" size="small" onClick={onCancelVoid}>
                    <Translate stringId="cancel" defaultMessage="Cancel" />
                  </Button>
                  <Button type="primary" size="small" onClick={handleUpdateTicketBillingNote}>
                    <Translate stringId="save" defaultMessage="Save" />
                  </Button>
                </div>
              </>
            ) : (
              <BatchData
                consumptions={ticket?.production?.consumptions || []}
                primaryProduct={ticket?.production}
                primaryLineItem={primaryLineItem}
              />
            )
          ) : (
            <div>Ticket details are not available at this time</div>
          )}
        </div>
        <div className="buttons">
          <IfSeller>
            {showReactivateTicket && (
              <ReActivateTicket ticket={ticket} onReactivateTicket={onCancelVoid} disabled={aboveShippedAmount > 0} />
            )}
            <CancelVoid ticket={ticket} onCancelVoid={onCancelVoid} setupItems={setupItems} vehicle={vehicle} />
          </IfSeller>
        </div>
      </FadeInDiv>
    </Spinner>
  );
};

export default styled(TicketDetails)`
  ${style}
`;
