import { set } from 'lodash';
import { useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import ErrorModal from '../../../components/error-modal/ErrorModal';
import { useModalContext } from '../../../components/modal-context/ModalContext';
import { MODES } from '../components/order-form/OrderForm';
import { useCommonOrderBehavior } from '../components/useCommonOrderBehavior';
import generateCleanedUpFormData from '../util/generate-cleaned-up-form-data';
import useOrderRequestMetaData from './useOrderRequestMetaData';

export const useOrderRequestBehavior = (orderRequestRef, orderType) => {
  const commonOrderBehavior = useCommonOrderBehavior(orderType, orderRequestRef);
  const {
    order: { getOrderRequest, order, approveOrderRequest, rejectOrderRequest, orderBusy, orderRequestStatus },
    form: { form, setForm, auditNotes },
    masterData: { masterData },
    defaults: { defaults },
    start: { startOrder },
  } = commonOrderBehavior;

  const navigate = useNavigate();
  const { openModal } = useModalContext();

  const { orderRequestMetaData, getOrderRequestMetaData } = useOrderRequestMetaData();

  useEffect(() => {
    // getOrder needs to finish before startOrder.
    getOrderRequest(orderRequestRef).then(() => {
      startOrder();
    });
  }, [getOrderRequest, orderRequestRef, startOrder]);

  useEffect(() => {
    getOrderRequestMetaData(orderRequestRef);
  }, [orderRequestRef, getOrderRequestMetaData]);

  useEffect(() => {
    if (order) {
      setForm(order);
      // hash.initialize(order);
    }
  }, [order, setForm]);

  // apply defaults
  useEffect(() => {
    if (defaults) {
      setForm(
        s => ({
          ...s,
          supplierStatusRef: defaults?.supplierStatusCodes,
        }),
        true
      );
    }
  }, [masterData, defaults, setForm]);

  // If customer changes, clear dependent fields.
  useEffect(() => {
    setForm(s => {
      if (s?.customerRef && s?.customerRef !== form?.customerRef) {
        return { ...s, projectRef: null, locationRef: null, billingLocationRef: null };
      }
      return s;
    });
  }, [form?.customerRef, setForm]);

  const handleCancelClick = useCallback(() => {
    rejectOrderRequest(orderRequestRef)
      .then(() => {
        navigate('../../');
      })
      .catch(e => {
        openModal('Error', <ErrorModal error={e} />);
      });
  }, [navigate, openModal, orderRequestRef, rejectOrderRequest]);

  const handleSaveClick = useCallback(() => {
    const cleanedUpFormData = generateCleanedUpFormData(form, auditNotes);
    approveOrderRequest(cleanedUpFormData, orderRequestRef)
      .then(order => {
        navigate(`../../../edit/${orderType}/${order?.crn}/general`);
      })
      .catch(e => {
        openModal('Error', <ErrorModal error={e} />);
      });
  }, [auditNotes, approveOrderRequest, form, navigate, openModal, orderType, orderRequestRef]);

  const orderRquestBehavior = {
    ...commonOrderBehavior,
    handleSaveClick,
    handleCancelClick,
    mode: MODES.REVIEW,
    saveOrderBusy: orderBusy,
    orderType,
    orderRequestStatus,
    orderRequestRef,
    orderRequestMetaData,
  };

  set(orderRquestBehavior, 'order.readOnly', false);
  return orderRquestBehavior;
};
