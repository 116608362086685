import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';
import FlexColumn from '../../order/components/FlexColumn';
import SetupTable from '../components/setup-table';
import useSetup from '../useSetup';
import CarrierEditor from './carrier-editor';
import style from './style';
import { FILTER_STRATEGY } from '../../../components/connex-data-grid/util';

const prototype = {
  id: '',
  name: '',
  status: 'ACTIVE',
};

const columns = [
  { key: 'id', name: 'ID' },
  { key: 'name', name: 'Name', formatter: props => props.row.name ?? '' },
  {
    key: 'status',
    name: 'Status',
    filterStrategy: FILTER_STRATEGY.EQUALS,
    filterComponent: ({ innerRef, ...props }) => {
      return (
        <select ref={innerRef} {...props}>
          <option value="" />
          <option value="ACTIVE">Active</option>
          <option value="INACTIVE">Inactive</option>
        </select>
      );
    },
  },
];

const Styled = styled(FlexColumn)`
  ${style}
`;

const SetupCarriers = () => {
  const { entityRef } = useParams();
  const { getCarriers, carriers, busy } = useSetup();
  const params = useMemo(() => ({ activeOnly: false }), []);

  useEffect(() => {
    entityRef && getCarriers(params);
  }, [entityRef, getCarriers, params]);

  return (
    <Styled className="setup-carriers">
      <SetupTable
        busy={busy}
        type="Carrier"
        columns={columns}
        items={carriers}
        Editor={CarrierEditor}
        prototype={prototype}
        onRefresh={() => getCarriers(params)}
      />
    </Styled>
  );
};

export default SetupCarriers;
