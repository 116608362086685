import cn from 'classnames';
import React, { useCallback } from 'react';
import styled from 'styled-components';
import Button from '../../../../../../components/button/Button';
import Input from '../../../../../../components/form/Input';
import { Translate } from '../../../localization/Translate';

const { TextArea } = Input;

const Styled = styled.div`
  .ant-select {
    width: 100%;
  }
  .section-label {
    margin-bottom: 10px;
    font-size: 1rem;
    font-weight: 700;
  }

  .field-label {
    margin-top: 10px;
  }
`;

const CreateNote = ({ className, onChange, value, onSaveNote, busy, userCanEditOrders }) => {
  const handleCancelClick = useCallback(() => {
    onChange('message', '');
  }, [onChange]);

  return (
    <Styled className={cn('create-note', className)}>
      <div className="section-label">
        <Translate stringId="addANote" defaultMessage="Add a Note" />
      </div>
      <TextArea
        className="note-text"
        value={value.message}
        onChange={e => {
          onChange('message', e.target.value);
        }}
        disabled={busy || !userCanEditOrders}
        data-testid="create_note"
      />

      <div className="buttons">
        <Button
          disabled={!value?.message?.length || busy}
          onClick={handleCancelClick}
          metricId="core-order-detail-notes-cancel"
        >
          <Translate stringId="cancel" defaultMessage="Cancel" />
        </Button>
        <Button
          className={cn('btn', { 'btn-success': value?.message?.length })}
          onClick={onSaveNote}
          disabled={!value?.message?.length || busy}
          loading={busy}
          metricId="core-order-detail-notes-save"
        >
          <Translate stringId="save" defaultMessage="Save" />
        </Button>
      </div>
    </Styled>
  );
};

export default CreateNote;
