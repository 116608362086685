import { css } from 'styled-components';

export default css`
  width: 100%;

  .table-container {
    height: 480px;
    overflow-y: auto;
    
    table {
      width: 100%;
    }

    thead th {
      background-color: #0e4980;
      padding: 5px;
      border: solid 1px #3a3a3a;
    }

    thead th:first-child {
      width: 50%;
    }
    
    tbody td {
      padding: 3px;
      border: solid 1px #333;
    }    
  }

  .field-container {
    margin: 0;
  }
`;
