import {css} from 'styled-components';
import getGradient from "../../utils/get-gradient";

export default css`
  background: ${props => getGradient(props.theme.vehicleLineBackground)};
  color: ${props => props.theme.vehicleLineForeground};
  height: 20px;  
  max-height: 16px;
  flex-flow: column wrap;
  //margin-right: 2px;
  //margin-left: 2px;
  width: 100%;
  //margin-bottom: 2px;
  display: flex;
  flex-direction: row;
  flex: 1;
  color: black;
  user-select: none;
  flex-wrap: nowrap;
  border-left: 1px solid;
  border-right: 1px solid;
  border-bottom: 1px solid;
  .vehicle-alert-icon {
    color: red;
  }
  
  .vehicle-column {
    line-height: 1.25em;
    white-space: nowrap;
    height: 100%;
    flex: 1;
    flex-direction: column;
  }
`
