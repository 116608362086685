import {useEffect, useState} from "react";
import calculateLoads from "./calculate-loads";

const useCalculateLoads = (demand, startTime, targetOrderRef, setOnJobRange, startStatus = 'PRINTED', endStatus = 'IN_YARD') => {
  const [loadsValue, setLoadsValue] = useState(null);

  useEffect(() => {

    if(!(startTime && demand)) {
      return null;
    }

    const newLoadsValue = calculateLoads(demand, startTime, targetOrderRef, setOnJobRange, startStatus, endStatus);

    setLoadsValue(newLoadsValue);
  }, [startTime, demand, startStatus, endStatus, targetOrderRef])

  return loadsValue;
};

export default useCalculateLoads;
