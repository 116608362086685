import styled from 'styled-components';
import cn from 'classnames';
import style from './style';
import TrackingDatePicker from './trackingDatePicker';
import React from 'react';
import { MenuContextProvider } from './useMenuContext';
import MenuCollection from './menuCollection';
import { useDashboardContext } from '../context/useDashboardContext';
import VehicleAlertIcon from './vehicle-alert-icon';

const DashboardMenu = ({ className }) => {
  const { licenses } = useDashboardContext();

  return (
    <div className={cn(className)}>
      <MenuContextProvider>
        <MenuCollection />
        {licenses.orders && <TrackingDatePicker />}
        <MenuCollection menus={[require('./menus/reset')]}/>
        <div className={'right-area'}>
          <VehicleAlertIcon />
        </div>
      </MenuContextProvider>
    </div>
  );
};

export default styled(DashboardMenu)`
  ${style}
`;
