import styled from 'styled-components';
import cn from 'classnames';

const Spinner = ({ className, spin, children }) => {
  return spin ? (
    <div className={cn('spinner', 'spinning', className)} data-testid="busySpinner">
      <i className="fas fa-spin fa-circle-notch " />
    </div>
  ) : (
    children || null
  );
};

export default styled(Spinner)`
  &.spinner {
    min-height: 1rem;
    min-width: 1rem;
    text-align: center;
    margin-top: 10%;
    .fas {
      font-size: 3em;
      opacity: 0.3;
    }
  }
`;
