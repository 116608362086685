import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
import Button from '../../../../../components/button/Button';
import { Translate } from '../../localization/Translate';
import { useModelingContext } from './ModelingContext';

const Styled = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
`;

const Buttons = () => {
  const { handleSubmit, handleReset, onCancel, changeInfo } = useModelingContext();
  const disabled = React.useMemo(
    () => !changeInfo?.isSpacingChanged && !changeInfo?.isStartDateTimeChanged,
    [changeInfo?.isSpacingChanged, changeInfo?.isStartDateTimeChanged]
  );
  return (
    <Styled className={cn('buttons')}>
      <Button onClick={onCancel} metricId="core-order-detail-modeling-cancel" data-testid='button-cancel'>
        <Translate stringId="cancel" defaultMessage="Cancel" />
      </Button>
      <Button onClick={handleReset} disabled={disabled} metricId="core-order-detail-modeling-reset" data-testid='button-reset'>
        <Translate stringId="reset" defaultMessage="Reset" />
      </Button>
      <Button onClick={handleSubmit} disabled={disabled} metricId="core-order-detail-modeling-submit" data-testid='button-submit'>
        <Translate stringId="submit" defaultMessage="Submit" />
      </Button>
    </Styled>
  );
};

export default Buttons;
