import style from '../style';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import InputText from '../../../components/input-text';
import InputSelect from '../../../components/input-select';
import { status, taxableStatus, uom } from '../options';
import SelectMix from '../../../components/select-mix';
import SelectComponent from '../../../components/select-component';
import InputCheckbox from '../../../components/input-checkbox';
import InputNumberBlur from '../../../components/input-number-blur';

const View = styled.div`
  ${style}
`;

const ProductGeneral = ({ product, onChange, productTypes, hasMinimumLoadCharge }) => {
  const [types, setTypes] = useState(null);

  useEffect(() => {
    productTypes &&
      setTypes(
        productTypes.map(s => {
          return {
            value: s.id,
            label: s.name,
          };
        })
      );
  }, [productTypes]);

  const onChangeProductType = useCallback(
    (_, value) => {
      const completeProductType = productTypes?.find(pt => pt?.id === value);
      //minimumLoadCharge products should have EA as uom
      if (completeProductType?.minimumLoadCharge) {
        onChange('uomCode', 'EA');
      }
      onChange('type', value);
    },
    [productTypes, onChange]
  );

  return (
    <View>
      {types && (
        <InputSelect
          id="type"
          label="Type"
          value={product?.type}
          onChange={onChangeProductType}
          options={types}
          required
        />
      )}

      {product?.type === 'CONCRETE' && <SelectMix item={product} onChange={onChange} />}
      {product?.type === 'COMPONENT' && <SelectComponent item={product} onChange={onChange} />}

      <InputText id="id" label="ID" value={product?.id} onChange={onChange} />
      <InputText id="name" label="Name" value={product?.name} onChange={onChange} />
      <InputSelect
        id="uomCode"
        label="Unit of Measure"
        value={product?.uomCode}
        onChange={onChange}
        options={uom}
        required
        disabled={hasMinimumLoadCharge}
      />
      <InputSelect
        id="taxable"
        label="Taxable"
        value={product?.taxable ?? 'YES'}
        onChange={onChange}
        options={taxableStatus}
        required
      />
      <InputSelect id="status" label="Status" value={product?.status} onChange={onChange} options={status} required />
      <InputNumberBlur
        id="unitPrice"
        label="Default Price"
        value={product?.unitPrice ?? ''}
        onChange={onChange}
        useDefaultOnChangeHandler={true}
      />
      <InputCheckbox
        id="availableForOrder"
        label="Available for Order Requests"
        onChange={onChange}
        value={product?.availableForOrder}
      />
    </View>
  );
};

export default ProductGeneral;
