import http from '../../../http';

const orders = '/connex/services/orders';

export const api = {
  listProducts: (ref, supplierRef) => http.get(`${orders}/${ref}/suppliers/${supplierRef}/products`),
  startOrderRequest: (ref, supplierRef, data) => http.post(`${orders}/${ref}/suppliers/${supplierRef}/order-requests/start`, data),
  createOrderRequest: (ref, supplierRef, data) => http.post(`${orders}/${ref}/suppliers/${supplierRef}/order-requests`, data),
  updateOrderRequest: (ref, supplierRef, data) => http.patch(`${orders}/${ref}/suppliers/${supplierRef}/order-requests`, data),
  listSuppliers: ref => http.get(`${orders}/${ref}/suppliers`),
  listOrderRequests: (ref, queryParams = '') => http.get(`${orders}/${ref}/order-requests${queryParams}`),
  getOrderRequest: (ref, orderRef) => http.get(`${orders}/${ref}/order-requests/${orderRef}`),
  createOrderTickets: (ref, orderID, data) => http.post(`${orders}/${ref}/order-requests/${orderID}/:dispatchOrder/tickets`, data),
  createOrderNote: (ref, orderRef, data) => http.post(`${orders}/${ref}/order-requests/${orderRef}/notes`, data),
  listOrderNotes: (ref, orderRef) => http.get(`${orders}/${ref}/order-requests/${orderRef}/notes`),
  deleteOrderNote: (ref, orderRef, crn) => http.delete(`${orders}/${ref}/order-requests/${orderRef}/notes/${crn}`),
  updateOrderNote: (ref, orderRef, crn, data) => http.patch(`${orders}/${ref}/order-requests/${orderRef}/notes/${crn}`, data),
  getOrderNote: (ref, orderRef, crn) => http.get(`${orders}/${ref}/order-requests/${orderRef}/notes/${crn}`),
};
