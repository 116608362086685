import style from '../style';
import React from 'react';
import styled from 'styled-components';
import InputText from '../../../components/input-text';
import InputSelect from '../../../components/input-select';
import MaxBatchSize from '../../../components/max-batch-size';
import { status } from './options';
import SyncStatusBadge from "../../SyncStatusBadge";

const Styled = styled.div`
  ${style}
`;

const FormulaGeneral = ({ item: formula, onChange, saveWarningMessage }) => {
  return (
    <Styled>
      <InputText id="name" label="Name" value={formula?.name} onChange={onChange} />
      <InputSelect
        id="status"
        label="Status"
        value={formula?.status}
        onChange={onChange}
        options={status}
        disabled={saveWarningMessage && saveWarningMessage.length > 0}
        required
      />
      <InputText id="jobMixFormulaId" label="Job Mix Formula Id" value={formula?.jobMixFormulaId} onChange={onChange} />
      <MaxBatchSize item={formula} onChange={onChange} />
      <SyncStatusBadge ticket={formula}></SyncStatusBadge>
    </Styled>
  );
};

export default FormulaGeneral;
