import { DateTime } from 'luxon';

export default (demand, startTime, targetOrderRef, setOnJobRange, startStatus = 'PRINTED', endStatus = 'IN_YARD') => {
  if (!(startTime && demand)) {
    return null;
  }

  const endOfDay = startTime.endOf('day');

  let time = startTime.startOf('day');

  const loads = [];
  const filteredDeliveries = demand?.deliveries?.filter(delivery => delivery?.supplierStatus !== 'CANCELLED');

  if (targetOrderRef && setOnJobRange) {
    const firstAndLast = filteredDeliveries.reduce((acc, load) => {
      if (load.orderRef === targetOrderRef) {
        const ARRIVE_JOB = DateTime.fromISO(load.ARRIVE_JOB).setZone(demand?.timeZone);
        const LEAVE_JOB = DateTime.fromISO(load.LEAVE_JOB).setZone(demand?.timeZone);

        if (!acc.firstArriveJob || ARRIVE_JOB < acc.firstArriveJob) {
          acc.firstArriveJob = ARRIVE_JOB;
        }

        if (!acc.lastLeaveJob || LEAVE_JOB > acc.lastLeaveJob) {
          acc.lastLeaveJob = LEAVE_JOB;
        }
      }
      return acc;
    }, {});

    if (firstAndLast?.firstArriveJob && firstAndLast?.lastLeaveJob) {
      const startOfDay = firstAndLast?.firstArriveJob.startOf('day');
      const arriveJobMinutes = firstAndLast?.firstArriveJob.diff(startOfDay, 'minutes').minutes;
      const firstSlot = Math.floor(arriveJobMinutes / 5);

      const leaveJobMinutes = firstAndLast?.lastLeaveJob.diff(startOfDay, 'minutes').minutes;
      const lastSlot = Math.floor(leaveJobMinutes / 5);

      setOnJobRange([firstSlot, lastSlot]);
    }
  }

  while (time < endOfDay) {
    const inProgress = filteredDeliveries?.filter(
      delivery => time >= DateTime.fromISO(delivery[startStatus]) && time <= DateTime.fromISO(delivery[endStatus])
    );

    const offset = time.diff(startTime, 'minutes').minutes;

    if (inProgress.length > 0) {
      loads.push({
        inProgress,
        trucks: inProgress.length,
        orders: inProgress.map(delivery => delivery.orderId),
        orderRefs: inProgress.map(delivery => delivery.orderRef),
        time: time.toISO(),
        offset,
      });
    }

    time = time.plus({ minutes: 5 });
  }

  const newLoadsValue = loads.sort((a, b) => (a.time < b.time ? -1 : 1));
  return newLoadsValue;
};
