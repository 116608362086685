import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
import Button from '../../../components/button/Button';
import { useScaleTicketingContext } from '../Context';

const Styled = styled.div``;
const TareIn = props => {
  const { form, handleChange, updateVehicleConfiguration } = useScaleTicketingContext();

  const handleClick = React.useCallback(() => {
    handleChange('vehicle', s => {
      const newState = {
        ...s,
        configuration: {
          ...s.configuration,
          tare: form?.capturedWeight,
        },
      };
      updateVehicleConfiguration(newState);
      return newState;
    });
  }, [form?.capturedWeight, handleChange, updateVehicleConfiguration]);

  return (
    <Styled className={cn('tare-in')}>
      <Button size="large" disabled={!form?.capturedWeight?.weight?.value} onClick={handleClick}>
        Tare In
      </Button>
    </Styled>
  );
};

export default TareIn;
