import { css } from 'styled-components';

export default css`
  width: 100%;

  .ant-btn {
    margin-top: 10px;
  }
  .warning-section {
    flex-direction: row;
    display: flex;
    margin-top: 15px;
  }
  .warning-icon {
    flex-direction: column;
    color: #eed202;
    font-size: 1.5rem;
  }
  .warning-text {
    margin-left: 20px;
    flex-direction: column;
  }
`;
