import React, { useEffect, useState } from 'react';
import Select from '../../../../components/form/Select';
import styled from 'styled-components';
import useSetup from '../../../setup/useSetup';
import { Translate } from '../localization/Translate';

const { Option } = Select;

const Title = styled.div`
  padding: 5px;
`;

const WarningDiv = styled.div`
  background-color: #df4e39;
  color: white;
  padding: 4px 8px;
  margin: 5px 0;
  border-radius: 4px;
`;

const SelectLocation = ({ orderRef, locationRef, onChange, disabled }) => {
  const { getLocations, locations, getDexas, dexas } = useSetup();
  const [integrationWarning, setIntegrationWarning] = useState('');

  useEffect(() => {
    orderRef && getDexas().catch(alert) && getLocations().catch(alert);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderRef]);

  useEffect(() => {
    if (locationRef && locations && dexas?.items) {
      const location = locations.find(l => l.crn === locationRef);
      if (location?.integratedSystem === 'CONNEX' && location.dexaMapping?.connectionName === 'COMMAND_BATCH') {
        const dexa = dexas.items.find(d => d.crn === location.dexaMapping.dexaId);
        if (dexa) {
          if (dexa.isOnline) {
            const connection = dexa.connections.find(c => c.name === location.dexaMapping.connectionName);
            setIntegrationWarning(!connection ? 'connectionNotFound' : '');
          } else {
            setIntegrationWarning('dexaOffline');
          }
        } else {
          setIntegrationWarning('dexaNotFound');
        }
        return;
      }
    }
    setIntegrationWarning('');
  }, [locationRef, locations, dexas]);

  return (
    <div>
      <Title><Translate stringId="shippingLocation" defaultMessage="Shipping Location" /> *</Title>
      <Select
        id="select-location"
        data-testid="select-shipping-location"
        className="select-location"
        defaultValue={''}
        value={locationRef}
        onChange={onChange}
        disabled={disabled}
      >
        <Option value="">Select...</Option>
        {locations?.map((c, key) => (
          <Option key={`${key}-select-location`} value={c.crn}>
            {c.id} / {c.name}
          </Option>
        ))}
      </Select>
      {integrationWarning && <WarningDiv><Translate stringId={integrationWarning} /> </WarningDiv>}
    </div>
  );
};

export default SelectLocation;
