import style from '../style';
import React from 'react';
import styled from 'styled-components';
import { status } from './options';
import InputText from '../../components/input-text';
import InputSelect from '../../components/input-select';
import SelectSetupItem from '../../components/select-setup-item';

const Styled = styled.div`
  ${style}
`;

const PourDescriptionGeneral = ({ item: pour, onChange }) => {
  return (
    <Styled>
      <InputText id="name" label="Description" value={pour?.name} onChange={onChange} />
      <SelectSetupItem label="Associated Job" typeId="project" item={pour} onChange={onChange} />
      <SelectSetupItem label="Associated Discipline" typeId="discipline" item={pour} onChange={onChange} />
      <InputSelect id="status" label="Status" value={pour?.status} onChange={onChange} options={status} required />
    </Styled>
  );
};

export default PourDescriptionGeneral;
