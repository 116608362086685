import style from '../style';
import React from 'react';
import styled from 'styled-components';
import { status, category } from './options';
import InputText from '../../components/input-text';
import InputSelect from '../../components/input-select';
import InputCheckbox from '../../components/input-checkbox';

const Styled = styled.div`
  ${style}
`;

const ProductTypeGeneral = ({ item: productType, onChange }) => {
  const defaultCategory = React.useMemo(
    () => (productType.id === 'OTHER_PRODUCT' ? 'other' : 'associated'),
    [productType]
  );

  const categoryDescription = React.useMemo(() => {
    const option = category.find(o => o.value === (productType?.category ?? defaultCategory));
    return option ? option.desc : '';
  }, [productType, defaultCategory]);

  return (
    <Styled>
      <InputText id="id" label="ID" value={productType?.id} onChange={onChange} />
      <InputText id="name" label="Name" value={productType?.name} onChange={onChange} />
      <InputSelect
        id="status"
        label="Status"
        value={productType?.status}
        onChange={onChange}
        options={status}
        required
      />
      <InputSelect
        id="category"
        label="Ticket Qty Calculation Method"
        value={productType?.category ?? defaultCategory}
        onChange={onChange}
        options={category}
        required
      />
      <div className="description">{categoryDescription}</div>
      <InputCheckbox
        id="minimumLoadCharge"
        label="Minimum Load Charge"
        value={productType?.minimumLoadCharge ?? false}
        onChange={onChange}
      />
    </Styled>
  );
};

export default ProductTypeGeneral;
