import React, { useEffect, useState } from 'react';
import { Builder, Query, Utils as QbUtils } from 'react-awesome-query-builder';
import RenderResult from './RenderResult';
import styled from 'styled-components';
import style from './style';

const queryValue = { id: QbUtils.uuid(), type: 'group' };

const renderBuilder = props => (
  <div className="query-builder-container" style={{ padding: '10px' }}>
    <div className="query-builder qb-lite">
      <Builder
        {...props}
        shouldCreateEmptyGroup={true}
        locale={{
          moment: 'en_US',
          antd: 'en_US',
          material: 'en_US',
        }}
      />
    </div>
  </div>
);

const Styled = styled.div`
  ${style}
`;

const RuleEditor = ({ id, config, trigger = queryValue, onChange }) => {
  const [debugResult] = useState(false);
  const [tree, setTree] = useState(null);

  useEffect(() => {
    config && setTree(QbUtils.checkTree(QbUtils.loadTree(trigger), config));
  }, [config, trigger]);

  useEffect(() => {
    // tree && console.log(QbUtils.getTree(tree))
  }, [tree]);

  const ruleChange = immutableTree => {
    setTree(immutableTree);

    const trigger = JSON.stringify(immutableTree);
    const logic = JSON.stringify(QbUtils.jsonLogicFormat(immutableTree, config).logic);

    onChange && onChange(id, { trigger, logic });
  };

  return (
    <Styled>
      {tree && <Query {...config} value={tree} onChange={ruleChange} renderBuilder={renderBuilder} />}
      {debugResult && <RenderResult tree={tree} config={config} />}
    </Styled>
  );
};

export default RuleEditor;
