import { DateTime } from 'luxon';
import React from 'react';
import { ShortDate } from '../../../order/components/localization/ShortDate';
import { Time } from '../../../order/components/localization/Time';
import { Translate } from '../../../order/components/localization/Translate';
import { useTranslateMessage } from '../../../order/components/localization/useTranslateMessage';
import BillableRenderer from './BillableRenderer';
import MoneyRenderer from './MoneyRenderer';
import { Button } from 'antd';

export const useColumns = () => {
  const translateMessage = useTranslateMessage();

  return React.useMemo(() => {
    return [
      {
        key: 'ticketId',
        name: 'Ticket',
        resizable: true,
        frozen: true,
      },
      {
        key: 'billableDetailsButton',
        name: 'Details',
        resizable: false,
        frozen: true,
        width: 70,
        groupFormatter: ({ childRows }) => {
          const row = childRows[0];
          return (
            <Button
              style={{ width: '100%' }}
              size="small"
              onClick={() => {
                row?.openDrawerHandler?.();
              }}
            >
              <Translate stringId="invoices.open" defaultMessage="Open" />
            </Button>
          );
        },
        filterFn: () => true,
        formatter: () => '',
      },
      {
        key: 'isBillable',
        name: 'Billable',
        resizable: true,
        filterable: true,
        width: 80,
        filterFn: (item, filterStrings) => {
          if (!filterStrings[0]) return true;
          return item ? filterStrings[0] === 'true' : filterStrings[0] === 'false';
        },
        filterComponent: ({ innerRef, ...props }) => {
          return (
            <select ref={innerRef} {...props}>
              <option value="" />
              <option value="true">{translateMessage({ stringId: 'true', defaultMessage: 'True' })}</option>
              <option value="false">{translateMessage({ stringId: 'false', defaultMessage: 'False' })}</option>
            </select>
          );
        },
        groupFormatter: ({ childRows }) => <BillableRenderer row={childRows[0]} />,
        formatter: () => '',
      },
      {
        key: 'dispatchDateTime',
        name: 'Date',
        resizable: true,
        filterFn: (item, filterStrings) => {
          if (!filterStrings[0]) return true;
          const formattedItem = DateTime.fromISO(item).toFormat('MM-dd-yyyy');
          return formattedItem.includes(filterStrings[0]);
        },
        groupFormatter: ({ childRows }) => (
          <div className="cell">
            <ShortDate date={childRows[0].dispatchDateTime} />
          </div>
        ),
        formatter: () => '',
      },
      {
        key: 'dispatchTime',
        name: 'Time',
        resizable: true,
        hidden: true,
        sortKey: 'dispatchDateTime',
        groupFormatter: ({ childRows }) => (
          <div className="cell">
            <Time date={childRows[0].dispatchDateTime} hideTimeZone />
          </div>
        ),
        formatter: () => '',
      },
      {
        key: 'orderId',
        name: 'Order',
        resizable: true,
        groupFormatter: ({ childRows }) => childRows[0].orderId || '',
        formatter: () => '',
      },
      {
        key: 'project',
        name: 'Project',
        resizable: true,
        sortKey: 'projectId',
        filterKey: ['projectId', 'projectName'],
        groupFormatter: ({ childRows }) => {
          const row = childRows[0];
          return row ? (
            <div>{`${row.projectId || ''}${row.projectId && row.projectName ? ' / ' : ''}${
              row.projectName || ''
            }`}</div>
          ) : (
            ''
          );
        },
        formatter: () => '',
      },
      {
        key: 'projectId',
        name: 'Project ID',
        resizable: true,
        hidden: true,
        filterKey: ['projectId', 'projectName'],
        groupFormatter: ({ childRows }) => childRows[0].projectId || '',
        formatter: () => '',
      },
      {
        key: 'customer',
        name: 'Customer',
        resizable: true,
        sortKey: 'customerId',
        filterKey: ['customerId', 'customerName'],
        groupFormatter: ({ childRows }) => {
          const row = childRows[0];
          return row
            ? `${row.customerId || ''}${row.customerId && row.customerName ? ' / ' : ''}${row.customerName || ''}`
            : '';
        },
        formatter: () => '',
      },
      {
        key: 'customerId',
        name: 'Customer ID',
        resizable: true,
        hidden: true,
        filterKey: ['customerId', 'customerName'],
        groupFormatter: ({ childRows }) => childRows[0].customerId || '',
        formatter: () => '',
      },
      {
        key: 'location',
        name: 'Location',
        resizable: true,
        sortKey: 'locationId',
        filterKey: ['locationId', 'locationName'],
        groupFormatter: ({ childRows }) => {
          const row = childRows[0];
          return row
            ? `${row.locationId || ''}${row.locationId && row.locationName ? ' / ' : ''}${row.locationName || ''}`
            : '';
        },
        formatter: () => '',
      },
      {
        key: 'locationId',
        name: 'Location ID',
        resizable: true,
        hidden: true,
        filterKey: ['locationId', 'locationName'],
        groupFormatter: ({ childRows }) => childRows[0].locationId || '',
        formatter: () => '',
      },
      {
        key: 'vehicleTypeId',
        name: 'Vehicle Type ID',
        resizable: true,
        hidden: true,
        groupFormatter: ({ childRows }) => childRows[0].vehicleTypeId || '',
        formatter: () => '',
      },
      {
        key: 'vehicleTypeName',
        name: 'Vehicle Type',
        resizable: true,
        groupFormatter: ({ childRows }) => childRows[0].vehicleTypeName || '',
        formatter: () => '',
      },
      {
        key: 'statusCode',
        name: 'Status Code',
        resizable: true,
        hidden: true,
        groupFormatter: ({ childRows }) => childRows[0].statusCode || '',
        formatter: () => '',
      },
      {
        key: 'product',
        name: 'Product',
        resizable: true,
        sortKey: 'productId',
        filterKey: ['productId', 'productName'],
        groupFormatter: ({ childRows }) => {
          const row = childRows[0];
          return row
            ? `${row.ticketProductId || ''}${row.ticketProductId && row.ticketProductName ? ' / ' : ''}${
                row.ticketProductName || ''
              }`
            : '';
        },
        formatter: props =>
          `${props.row.productId || ''}${props.row.productId && props.row.productName ? ' / ' : ''}${
            props.row.productName || ''
          }`,
      },
      {
        key: 'productId',
        name: 'Product ID',
        resizable: true,
        filterKey: ['productId', 'productName'],
        hidden: true,
        groupFormatter: ({ childRows }) => childRows[0].ticketProductId || '',
      },
      {
        key: 'destination',
        name: 'Destination',
        resizable: true,
        hidden: true,
        groupFormatter: ({ childRows }) => childRows[0].destination || '',
        formatter: () => '',
      },
      {
        key: 'extendedPrice',
        name: 'Sub-Total',
        resizable: true,
        hidden: true,
        headerClassName: 'right-justified',
        groupFormatter: ({ childRows }) => <MoneyRenderer value={childRows[0].ticketExtendedPrice || 0} />,
        formatter: props => <MoneyRenderer value={props.row.extendedPrice} />,
      },
      {
        key: 'salesTax',
        name: 'Sales Tax',
        resizable: true,
        hidden: true,
        headerClassName: 'right-justified',
        groupFormatter: ({ childRows }) => <MoneyRenderer value={childRows[0].ticketSalesTax || 0} />,
        formatter: props => <MoneyRenderer value={props.row.salesTax} />,
      },
      {
        key: 'totalPrice',
        name: 'Total',
        resizable: true,
        hidden: true,
        headerClassName: 'right-justified',
        groupFormatter: ({ childRows }) => <MoneyRenderer value={childRows[0].ticketTotalPrice || 0} />,
        formatter: props => <MoneyRenderer value={props.row.totalPrice} />,
      },
      {
        key: 'orderedQuantity',
        name: 'Ordered Quantity',
        resizable: true,
        hidden: true,
        groupFormatter: ({ childRows }) => childRows[0].orderedQuantity || 0,
        formatter: () => '',
      },
      {
        key: 'quantity',
        name: 'Quantity',
        resizable: true,
        hidden: true,
        groupFormatter: ({ childRows }) => childRows[0]?.lineItems?.find(p => p?.isPrimary).quantity || 0,
      },
      {
        key: 'totalQuantity',
        name: 'Accumulated Delivered Quantity',
        width: 250,
        resizable: true,
        hidden: true,
        groupFormatter: ({ childRows }) => childRows[0].totalQuantity || 0,
        formatter: () => '',
      },
      {
        key: 'priceSource',
        name: 'Pricing Source',
        resizable: true,
        hidden: true,
      },
      {
        key: 'billingNote',
        name: 'Billing Note',
        resizable: true,
        hidden: true,
        filterComponent: ({ innerRef, ...props }) => {
          return (
            <select ref={innerRef} {...props}>
              <option value="" />
              <option value="Yes">{translateMessage({ stringId: 'yes', defaultMessage: 'Yes' })}</option>
              <option value="No">{translateMessage({ stringId: 'no', defaultMessage: 'No' })}</option>
            </select>
          );
        },
        filterFn: (item, filterStrings) => {
          if (!filterStrings[0]) return true;
          const compare = item ? 'YES' : 'NO';
          return compare.includes(filterStrings[0]?.toUpperCase());
        },
        groupFormatter: ({ childRows }) =>
          childRows[0].billingNote
            ? translateMessage({ stringId: 'yes', defaultMessage: 'Yes' })
            : translateMessage({ stringId: 'no', defaultMessage: 'No' }),
        formatter: () => '',
      },
    ];
  }, []);
};
