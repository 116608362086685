import uom from './uom';

export default {
  ...uom,

  numberOfOrders: '{orderCount, plural, =0 {0 Orders} one {1 Order} other {{orderCount} Orders}}',
  selectLoadingPlaceholder: 'Loading...',
  filterByStatus: 'Filter By Status',
  CY: 'CY',
  today: 'Today',
  tomorrow: 'Tomorrow',
  future: 'Future',
  custom: 'Custom',
  cancelled: 'Cancelled',
  success: 'Scheduled',
  delivered: 'Delivered',
  warning: 'Late',
  danger: '1+ Hr Late',
  total: 'Total',
  advancedSearch: 'Advanced Search',

  date: 'Date',
  change: 'Change',
  userName: 'User Name',
  fields: 'Fields',
  oldValue: 'Old Value',
  newValue: 'New Value',
  reason: 'Reason',
  changesToOrder: 'Additions and Changes to Order',
  audit: 'Audit',
  scheduled: 'Scheduled',

  CREATED: 'Ticketed',
  PRINTED: 'Printed',
  LOADING: 'Loading',
  LOADING_STARTED: 'Loading',
  LOADING_COMPLETE: 'Loaded',
  TO_JOB: 'In Transit',
  ARRIVE_JOB: 'On Job',
  UNLOADING: 'Unloading',
  END_UNLOAD: 'Unloaded',
  END_UNLOADING: 'Unloaded',
  WASHING: 'Washing',
  LEAVE_JOB: 'Returning',
  ARRIVE_PLANT: 'Arrive Plant',
  IN_YARD: 'In Yard',

  statusCounts: {
    'ORDER REQUEST (PENDING)': 'ORDER REQUEST (PENDING): {count, number}',
    'ORDER REQUEST (REJECTED)': 'ORDER REQUEST (REJECTED): {count, number}',
    CANCELLED: 'CANCELLED: {count, number}',
    CONFIRMED: 'CONFIRMED: {count, number}',
    'CREDIT HOLD': 'CREDIT HOLD: {count, number}',
    COMPLETED: 'COMPLETED: {count, number}',
    HOLD: 'HOLD: {count, number}',
    'NO TICKETING': 'NO TICKETING: {count, number}',
    NORMAL: 'NORMAL: {count, number}',
    PENDING: 'PENDING: {count, number}',
    PROCESSING: 'PROCESSING: {count, number}',
    'WEATHER PERMIT': 'WEATHER PERMIT: {count, number}',
    'WILL CALL': 'WILL CALL: {count, number}',
  },

  projectHardCreditHold: 'Credit Hold: This project is on credit hold. No new orders are allowed.',
  customerHardCreditHold: 'Credit Hold: This customer is on credit hold. No new orders are allowed.',
  projectSoftCreditHold: 'Credit Warning: This project is on credit hold but new orders are still allowed.',
  customerSoftCreditHold: 'Credit Warning: This customer is on credit hold but new orders are still allowed.',

  dexaNotFound: 'Could not find specified DEXA configuration. Ticket will not be sent to batch.',
  dexaOffline: 'DEXA connection is not online. Ticket will not be sent to batch.',
  connectionNotFound: 'Could not find specified DEXA connection. Ticket will not be sent to batch.',

  taxRate__address: 'Delivery Address',
  taxRate__customer: 'Customer Address',
  taxRate__project: 'Project Address',
  taxRate__location: 'Plant Address',
  taxRate__exempt: 'Tax Exempt',
  taxRate__dispatchLocation: 'Dispatch Location Address',

  invoices: {
    heading_unbilled: 'Unbilled Delivery Register',
    heading_invoiced: 'Invoiced Delivery Register',
    'heading_invoice-export': 'Invoice Export History',

    default_isDelivered: 'Delivered',
    primary_isDelivered: 'Is Delivered',
    danger_isDelivered: 'Is Not Delivered',

    default_isBillable: 'Billable',
    primary_isBillable: 'Is Billable',
    danger_isBillable: 'Is Not Billable',

    default_isPriced: 'Priced',
    primary_isPriced: 'Is Priced',
    danger_isPriced: 'Is Not Priced',

    default_isTaxed: 'Taxed',
    primary_isTaxed: 'Is Taxed',
    danger_isTaxed: 'Is Not Taxed',

    default_hasNonTaxable: 'Non-Taxable',
    primary_hasNonTaxable: 'Has Non-Taxable',
    danger_hasNonTaxable: 'Does Not Have Non-Taxable',

    default_hasVehicle: 'Vehicle',
    primary_hasVehicle: 'Has Vehicle',
    danger_hasVehicle: 'Does Not Have Vehicle',

    default_hasPartialAddress: 'Partial Address',
    primary_hasPartialAddress: 'Has Partial Address',
    danger_hasPartialAddress: 'Does Not Have Partial Address',

    default_hasCustomer: 'Customer',
    primary_hasCustomer: 'Has Customer',
    danger_hasCustomer: 'Does Not Have Customer',

    default_hasProject: 'Project',
    primary_hasProject: 'Has Project',
    danger_hasProject: 'Does Not Have Project',

    default_isMultiLine: 'Multi-Product',
    primary_isMultiLine: 'Is Multi-Product',
    danger_isMultiLine: 'Is Not Multi-Product',
  },
  dispatch: 'Dispatch',
  windows: 'Windows',
  trackingConfiguration: 'Tracking Configuration',
  resetTrackingLayout: 'Reset Layout',
};
