import { get, padStart } from 'lodash';
import { DateTime } from 'luxon';
import React from 'react';
import getUomAbbreviationOrDescription from '../../../util/uom';
import StatusBadge from '../components/status-badge/StatusBadge';
import orderIdCell from './OrderIdCell';

export default (onCopy, timeZone) => [
  {
    key: 'id',
    name: 'Number',
    width: '5%',
    minWidth: 80,
    resizable: true,
    formatter: orderIdCell(onCopy),
  },
  {
    key: 'startDateTime',
    name: 'Start Date/Time',
    // width: '20%',
    width: 170,
    resizable: true,
    formatter: props => {
      const isoString = get(props, 'row.deliverySchedule.startDateTime');
      const dateTime = DateTime.fromISO(isoString).setZone(timeZone);
      return isoString ? (
        <div className="startDateTime">{`${dateTime.toFormat('D')} ${padStart(
          dateTime.toFormat('t ZZZZ'),
          12,
          '0'
        )}`}</div>
      ) : (
        ''
      );
    },
  },
  {
    key: 'product',
    name: 'Product',
    minWidth: 180,
    resizable: true,
    formatter: props => {
      const product = get(props, 'row.lineItems[0].item', '');
      return <div className="product">{`${product.id} / ${product.name}`}</div>;
    },
  },
  {
    key: 'quantity',
    name: 'Quantity',
    width: 80,
    resizable: true,
    formatter: props => {
      const quantity = get(props, 'row.lineItems[0].orderedQuantity.value', '');
      const uom = get(props, 'row.lineItems[0].orderedQuantity.uomCode', '');

      return (
        <div className="quantity" style={{ textAlign: 'right' }}>{`${quantity} ${getUomAbbreviationOrDescription(
          uom
        )}`}</div>
      );
    },
  },
  {
    key: 'status',
    name: 'Status',
    width: 100,
    resizable: true,
    formatter: props => <StatusBadge status={get(props, 'row.supplierStatus.name')} />,
  },
  {
    key: 'customerParty',
    name: 'Customer',
    minWidth: 90,
    resizable: true,
    formatter: props => {
      const id = get(props, 'row.customerParty.id');
      const name = get(props, 'row.customerParty.name');
      return <div className="customerParty">{`${id} / ${name}`}</div>;
    },
  },

  {
    key: 'project',
    name: 'Project',
    minWidth: 90,
    resizable: true,
    formatter: props => {
      const id = get(props, 'row.project.id', '');
      const name = get(props, 'row.project.name', '');
      return <div className="project">{`${id} / ${name}`}</div>;
    },
  },

  {
    key: 'location',
    name: 'Plant',
    minWidth: 90,
    resizable: true,
    formatter: props => {
      const id = get(props, 'row.location.id');
      const name = get(props, 'row.location.name');
      return <div className="location">{`${id} / ${name}`}</div>;
    },
  },
];
