import * as mr from 'multi-integer-range';

export const calcLoadCountWithLoadNumbers = (loadCount, loadNumbers) => {
  const max = +loadCount;
  if (isNaN(max) || !max) {
    return 0;
  }

  if (loadNumbers?.trim().length) {
    const loads = mr.flatten(mr.parse(loadNumbers));

    return loads.filter(l => l > 0 && l <= max).length;
  }
  return max;
};
