import React from "react";
import cn from 'classnames';
import style from './style';
import styled from 'styled-components';
import { kebabCase } from 'lodash';

import Input from "../../../../components/form/Input";
import FieldContainer from "../../../../components/field-container/FieldContainer";

const View = styled(FieldContainer)`${style}`;

const InputText = ({ className, id, label, value, addonAfter, onChange, disabled = false, innerRef, required }) => {
  const handleOnChange = e => {
    const { id, value } = e.target;
 
    if (!id) {
      return;
    }
 
    return onChange ? onChange(id, value, required) : console.log(id, value);
  };

  return (
    <View className={cn(id, className)}>
      {label && <div className="label-spinner">
        <div className="label">{`${label}${required ? ' *' : ''}`}</div>
      </div>}
      <Input
        id={id}
        type="text"
        value={value || ''}
        disabled={disabled}
        innerRef={innerRef}
        addonAfter={addonAfter}
        onChange={handleOnChange}
        required={required}
        data-testid={`input-${kebabCase(label?.toLowerCase?.())}`}
      />
    </View>
  );
};

export default InputText;
