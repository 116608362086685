import { useMemo } from 'react';
import { groupBy, isArray } from 'lodash';
import OrderCard from '../order-cards/OrderCard';
import OrderCardGroup from './components/order-card-group';

const OrderCardContainer = ({ orders, handleClick, columns, onOpen }) => {
  // In the orders array we can receive order requests with multiple primary products
  // which are transalted into multiple orders, but since those orders do not have an id
  // we are groping them in a card so that users know that those to orders belong to a
  // multi primary order
  const multiplePrimaryProductsOrdersGrouped = useMemo(() => {
    if (!orders?.length) return [];

    const result = orders?.filter(o => o.id || !o.orderGroupRef);
    const mppGroups = groupBy(
      orders.filter(o => !o.id && o.orderGroupRef),
      'orderGroupRef'
    );
    Object.keys(mppGroups).forEach(gKey => {
      if (!gKey || gKey === 'undefined') return;
      result.push(mppGroups[gKey]?.length > 1 ? mppGroups[gKey] : mppGroups[gKey][0]);
    });
    return result.sort((a, b) => {
      const aDate = isArray(a) ? a?.[0]?.deliverySchedule?.startDateTime : a?.deliverySchedule?.startDateTime;
      const bDate = isArray(b) ? b?.[0]?.deliverySchedule?.startDateTime : b?.deliverySchedule?.startDateTime;
      if (aDate > bDate) return 1;
      if (aDate < bDate) return -1;
      return 0;
    });
  }, [orders]);

  return (
    <>
      {multiplePrimaryProductsOrdersGrouped?.map?.((value, index) => {
        if (!isArray(value)) {
          return (
            <OrderCard
              key={value?.crn}
              onClick={handleClick}
              order={value}
              columns={columns}
              onOpen={() => onOpen(value.crn)}
            />
          );
        }
        return <OrderCardGroup orders={value} onClick={handleClick} columns={columns} onOpen={onOpen} key={index} />;
      })}
    </>
  );
};

export default OrderCardContainer;
