import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import API from "../../../../api";
import {useParams} from "react-router";
import TicketEventsMap from "./ticket-events-map";
import cn from "classnames";

const Input = ({ className, fieldValue, field }) => {

  const {entityRef} = useParams();

  const [statusEvents, setStatusEvents] = useState(null);
  const [locationEvents, setLocationEvents] = useState(null);
  const [markers, setMarkers] = useState(null);

  useEffect(() => {
    API.ticketGeo(entityRef, fieldValue).then(geoData => {
      if (geoData) {
        setMarkers(geoData.markers);
        setLocationEvents(geoData.locationEvents);
        setStatusEvents(geoData.statusEvents);
      }
    })
  }, [entityRef, fieldValue])

  return <div className={cn(className)}>
    <TicketEventsMap markers={markers} locationEvents={locationEvents} statusEvents={statusEvents} />
  </div>;
};

export default styled(Input)`
  &&& {
    
  }
`;
