import { useEffect, useMemo } from 'react';
import useSetup from '../../useSetup';
import InputSelect from '../input-select';
import { useTranslateMessage } from '../../../order/components/localization/useTranslateMessage';

const SelectDispatchLocation = ({ id, label, value, onChange, disabled = false }) => {
  const { getSetupItems, setupItems: locations, busy } = useSetup('location');
  const translateMessage = useTranslateMessage();

  useEffect(() => {
    getSetupItems().then();
  }, [getSetupItems]);

  const dispatchLocations = useMemo(() => {
    return locations
      ?.filter(location => location.isDispatchLocation)
      ?.map(l => ({ label: `${l.id} / ${l.name}`, value: l.crn }));
  }, [locations]);

  return (
    <InputSelect
      id={id}
      label={translateMessage({ stringId: 'dispatchLocation', defaultMessage: label })}
      value={value}
      options={dispatchLocations}
      onChange={onChange}
      busy={busy}
      disabled={disabled}
    />
  );
};

export default SelectDispatchLocation;
