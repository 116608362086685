import { DatePicker } from 'antd';
import React, { useEffect, useState } from 'react';
import { useIntlContext } from '../../../../../i18n';
import { useOrderListContext } from '../../../components/OrderListContext';
import moment from 'moment';
import { DateTime } from 'luxon';
import styled from 'styled-components';
import cn from 'classnames';
import Button from '../../../../../components/button/Button';
import { useDateFormat } from '../../../../../hooks/useDateFormat';

const OrdersDatePicker = ({ className, timeZone }) => {
  const { quickDate, setQuickDate } = useOrderListContext();
  const { userLocale } = useIntlContext();
  const { dateFormat, antLocales } = useDateFormat(userLocale);
  const [currentDateValue, setCurrentDateValue] = useState(quickDate);
  const opts = { zone: 'utc' };

  useEffect(() => {
    setCurrentDateValue(existingValue => {
      if (existingValue === quickDate) {
        return existingValue;
      }
      return quickDate;
    });
  }, [setCurrentDateValue, quickDate]);

  const changeDay = amount => {
    const result = DateTime.fromISO(moment(quickDate?.firstStartDateTime).add(amount, 'day').toISOString(), opts);
    const tzAdjustedDate = result.setZone(timeZone, { keepLocalTime: true });

    const firstStartDateTime = tzAdjustedDate.startOf('day').toUTC().toISO();
    const lastStartDateTime = tzAdjustedDate.endOf('day').toUTC().toISO();

    setQuickDate({ firstStartDateTime, lastStartDateTime });
  };

  return (
    <div className={cn(className)}>
      <Button onClick={() => changeDay(-1)} metricId="core-tracking-date-back">
        <span className={'fa fa-chevron-left'} />
      </Button>
      <DatePicker
        onChange={m => {
          const dt = DateTime.fromISO(m.toISOString(), opts);
          const tzAdjustedDate = dt.setZone(timeZone, { keepLocalTime: true });

          const firstStartDateTime = tzAdjustedDate.startOf('day').toUTC().toISO();
          const lastStartDateTime = tzAdjustedDate.endOf('day').toUTC().toISO();
          setQuickDate({ firstStartDateTime, lastStartDateTime });
        }}
        value={moment(quickDate?.firstStartDateTime).utc()}
        format={dateFormat}
        locale={antLocales[userLocale]}
      />
      <Button onClick={() => changeDay(1)} metricId="core-tracking-date-forward">
        <span className={'fa fa-chevron-right'} />
      </Button>
    </div>
  );
};

export default styled(OrdersDatePicker)`
  flex: 1;
  flex-direction: column;
`;
