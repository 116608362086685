import React from 'react';
import { useMapStateContext } from '../../context/MapStateContext';
import prepareRoute from '../util/prepareRoute';
import { useCalculateRoute } from './useCalculateRoute';

export const useRouting = () => {
  const { setRoute, updatingDestination, destination } = useMapStateContext();
  const { calculateRoute } = useCalculateRoute();

  React.useEffect(() => {
    if (!updatingDestination && destination?.route?.Legs?.length) {
      setRoute(prepareRoute(destination?.route?.Legs));
    }
  }, [destination?.route?.Legs, setRoute, updatingDestination]);

  return { calculateRoute };
};
