import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { startCase } from 'lodash';
import OrderFormHeading from '../components/order-form/OrderFormHeading';
import SimpleOrderForm from '../components/order-form/SimpleOrderForm';
import OrderContextProvider from '../components/useOrderContext';
import style from './style';
import { useCreateOrderBehavior } from './useCreateOrderBehavior';

const CreateOrder = () => {
  const { orderType } = useParams();
  const createOrderBehavior = useCreateOrderBehavior(orderType?.toUpperCase?.());

  const headingProps = React.useMemo(() => {
    return { titleStringId: `create${startCase(orderType)}Order`, defaultTitle: `Create ${startCase(orderType)} Order` }
  }, [orderType]);

  return (
    <OrderContextProvider value={createOrderBehavior}>
      <OrderFormHeading {...headingProps} />
      <SimpleOrderForm />
    </OrderContextProvider>
  );
};

export default styled(CreateOrder)`
  ${style}
`;
