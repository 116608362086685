import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { api } from '../api';

export const useSuppliers = () => {
  const { entityRef } = useParams();
  const [suppliers, setSuppliers] = useState([]);
  const [suppliersBusy, setSuppliersBusy] = useState(false);

  const getSuppliers = useCallback(async () => {
    setSuppliers(null);
    setSuppliersBusy(true);

    const response = await api.listSuppliers(entityRef);
    setSuppliers(response);
    setSuppliersBusy(false);
  }, [entityRef]);

  return { suppliers, suppliersBusy, getSuppliers };
};
