import queryString from 'query-string';
import React from 'react';
import { useParams } from 'react-router';
import { useAppContext } from '../../../../AppContext';
import Button from '../../../../components/button/Button';
import { Translate } from '../../../order/components/localization/Translate';
import useSetup from '../../../setup/useSetup';
import moment from 'moment';
import { useIntlContext } from '../../../../i18n';

const dateFormats = {
  'en-US': 'MM/DD/YYYY hh:mm A',
  es: 'DD/MM/YYYY hh:mm A',
  'en-AU': 'DD/MM/YYYY hh:mm A',
};

export const useBillingPreview = selectedView => {
  const { v4Tokens } = useAppContext();
  const { entityRef } = useParams();
  const { companyLicenses, getCompanyLicenses } = useSetup();
  const { userLocale } = useIntlContext();

  React.useEffect(() => {
    getCompanyLicenses(entityRef).then();
  }, [getCompanyLicenses, entityRef]);

  const isPreviewButtonVisible = React.useMemo(() => {
    return !!v4Tokens?.lt && !!v4Tokens?.aet;
  }, [v4Tokens]);

  const v4BillingDate = React.useMemo(() => {
    const v4Billing = companyLicenses?.find(l => l.id === 'v4-billing-enabled');
    const date = v4Billing?.logInfo?.date;
    return date ? moment(date) : null;
  }, [companyLicenses]);

  const isGoToCommandCloudButtonVisible = React.useMemo(() => {
    const now = moment();
    return v4BillingDate && now.isAfter(v4BillingDate);
  }, [v4BillingDate]);

  const handlePreviewClick = React.useCallback(() => {
    const getHostname = () => {
      const hostname = window.location.hostname;
      if (hostname.includes('connex-orders-develop') || hostname.includes('localhost')) {
        return 'connex.develop.commandalkon.io';
      }

      return 'connex.us.commandalkon.io';
    };

    const views = {
      unbilled: `${entityRef}/tabs/billables`,
      invoiced: `${entityRef}/tabs/invoiced`,
    };

    const queryParams = queryString.stringify({ token: v4Tokens.lt, aet: v4Tokens.aet });

    const url = `https://${getHostname()}/app/billing-exp/${views[selectedView]}${
      queryParams ? `?${queryParams}` : ''
    }`;

    window.open(url, '_blank');
  }, [entityRef, v4Tokens.lt, v4Tokens.aet, selectedView]);

  return React.useMemo(() => {
    if (!isPreviewButtonVisible && !isGoToCommandCloudButtonVisible) {
      return { Button: null, Banner: null };
    }

    const stringId = isGoToCommandCloudButtonVisible ? 'invoices.goToCommandCloudBilling' : 'previewInBilling2';
    const defaultMessage = isGoToCommandCloudButtonVisible ? 'Go to Command Cloud Billing' : 'Preview New Billing';
    const formattedV4BillingDate = moment(v4BillingDate).format(dateFormats[userLocale]);
    const bannerDefaultMessage = `Beginning ${formattedV4BillingDate} future Billing activities occur in Command Cloud Billing. No new billables will be generated in this platform.`;

    const button = (
      <Button type="primary" onClick={handlePreviewClick}>
        <Translate stringId={stringId} defaultMessage={defaultMessage} />
      </Button>
    );

    const banner = (
      <span className="banner" style={{ backgroundColor: '#E04E39', color: 'white', padding: '5px 10px' }}>
        <Translate
          stringId="invoices.goToCommandCloudBillingBanner"
          defaultMessage={bannerDefaultMessage}
          values={{ date: formattedV4BillingDate }}
        />
      </span>
    );
    return { button: button, banner: banner };
  }, [handlePreviewClick, isPreviewButtonVisible, isGoToCommandCloudButtonVisible, v4BillingDate, userLocale]);
};
