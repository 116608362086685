import React from 'react';
import { useIntl } from 'react-intl';

export const useTranslateMessage = () => {
  const intl = useIntl();

  const translateMessage = React.useCallback(
    ({ stringId, values, defaultMessage }) => {
      try {
        return intl.formatMessage({ id: stringId, defaultMessage }, values);
      } catch (e) {
        return '';
      }
    },
    [intl]
  );

  return translateMessage;
};
