import style from "../style";
import React from "react";
import styled from 'styled-components';
import InputText from "../../components/input-text";
import InputSelect from "../../components/input-select";
import {status} from './options';

const View = styled.div`${style}`;

const CompanyGeneral = ({ item, onChange }) => {

  return (
    <View>
      <InputText id='id' label='ID' value={item?.id} onChange={onChange}/>
      <InputText id='name' label='Name' value={item?.name} onChange={onChange}/>
      <InputSelect id='status'
                   label='Status'
                   value={item?.status}
                   onChange={onChange}
                   options={status}
                   required
      />
    </View>
  )
}

export default CompanyGeneral;
