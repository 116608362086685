import Button from '../../../../../components/button/Button';
import React from 'react';
import styled from 'styled-components';
import { Translate } from '../../../components/localization/Translate';
import { useOrderListContext } from '../../../components/OrderListContext';

const Styled = styled.div`
  width: 100%;
  margin-top: 25px;
  .search-button {
    width: 100%;
  }
`;

const SearchButton = ({ formValue }) => {
  const { getAdvancedOrderSearch } = useOrderListContext();
  return (
    <Styled>
      <Button className={'search-button'} type={'primary'} onClick={() => getAdvancedOrderSearch(formValue)}>
        <Translate stringId="search" defaultMessage="Search" />
      </Button>
    </Styled>
  );
};

export default SearchButton;
