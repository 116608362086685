import { useCallback, useRef, useState } from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';
import { Translate } from '../../../../localization/Translate';

import style from './style';
import Button from '../../../../../../../components/button/Button';
import Spinner from '../../../../../../../components/spinner/Spinner';
import ProjectAttachment from '../../../../../../setup/setup-projects/project-editor/project-attachments/components/project-attachment';
import useAttachments from '../../../../../../../hooks/useAttachments';
import { useOrderContext } from '../../../../useOrderContext';
import { Modal } from 'antd';
import { cloneDeep } from 'lodash';

const ACCEPT_TYPE = 'image/x-png,image/jpeg,application/pdf';

const Styled = styled.div`
  ${style}
`;

const AttachmentsList = ({ initialAttachments, orderRef, onChange }) => {
  const { entityRef, tab } = useParams();

  const [attachments, setAttachments] = useState(initialAttachments);

  const { masterData: { masterDataBusy } = {}, userCanEditOrders } = useOrderContext();

  const [preview, setPreview] = useState(null);
  const [file, setFile] = useState(null);
  const [selectedAttachment, setSelectedAttachment] = useState(null);

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const {
    loading: loadingOrderAttachments,
    getAttachments: getOrderAttachments,
    uploadAttachment,
    itemBusy,
    deleteAttachment,
  } = useAttachments('order');

  const fileInputRef = useRef();
  const uploadButtonRef = useRef();

  const handleUpload = useCallback(() => {
    fileInputRef?.current?.click?.();
    uploadButtonRef?.current?.blur();
  }, []);

  const handleInitiateAttachmentUpload = useCallback(event => {
    const file = event?.target?.files?.[0];
    if (!file) return;

    if (!file.type.startsWith('image/') && !file.type.startsWith('application/pdf')) {
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      setPreview(reader.result);
      setFile(file);
      event.target.value = null;
    };
    reader.readAsDataURL(file);
  }, []);

  const handleUploadFile = useCallback(async () => {
    await uploadAttachment(
      entityRef,
      orderRef,
      {
        duration: 10,
        name: file.name.split('.')[0],
        contentType: file.type,
      },
      file
    );

    setPreview(null);

    const clonedAttachments = cloneDeep(attachments);
    clonedAttachments.orderAttachments = await getOrderAttachments(entityRef, orderRef);

    setAttachments(clonedAttachments);
    onChange('attachments', clonedAttachments);
  }, [entityRef, orderRef, uploadAttachment, file, getOrderAttachments, attachments, onChange]);

  const handleInitiateAttachmentDelete = useCallback(attachmentRef => {
    setSelectedAttachment(attachmentRef);
    setShowDeleteModal(true);
  }, []);

  const handleDeleteAttachment = useCallback(async () => {
    await deleteAttachment(entityRef, orderRef, selectedAttachment);

    setShowDeleteModal(false);

    const clonedAttachments = cloneDeep(attachments);

    clonedAttachments.orderAttachments.items = clonedAttachments?.orderAttachments?.items?.filter(
      a => a?.meta?.crn !== selectedAttachment
    );

    clonedAttachments.orderAttachments.itemCount = clonedAttachments?.orderAttachments?.items?.length ?? '';

    setAttachments(clonedAttachments);
    onChange('attachments', clonedAttachments);
  }, [selectedAttachment, orderRef, deleteAttachment, entityRef, attachments, onChange]);

  const handleClosePreviewModal = useCallback(() => {
    setPreview(null);
    uploadButtonRef?.current?.blur();
  }, []);

  const handleCloseDeleteModal = useCallback(() => {
    setShowDeleteModal(null);
  }, [setShowDeleteModal]);

  if (!masterDataBusy && !orderRef)
    return (
      <Styled>
        <div className="new-project-message">Couldn't load order attachments</div>
      </Styled>
    );

  return (
    <>
      <Modal
        visible={preview !== null}
        closable={false}
        width={1000}
        destroyOnClose={true}
        footer={[
          <Button key="back" onClick={handleClosePreviewModal} disabled={itemBusy}>
            <Translate stringId="cancel" defaultMessage="Cancel" />
          </Button>,
          <Button key="submit" type="primary" onClick={handleUploadFile} disabled={itemBusy} loading={itemBusy}>
            <Translate stringId="upload" defaultMessage="Upload" />
          </Button>,
        ]}
      >
        {(preview || '').startsWith('data:application/pdf') ? (
          <iframe
            title="project-attachment-preview"
            src={preview}
            className="file-preview"
            style={{ width: '100%', height: '600px' }}
          />
        ) : (
          <img src={preview} alt="Preview" style={{ width: '100%' }} />
        )}
      </Modal>

      <Modal
        visible={showDeleteModal}
        closable={false}
        destroyOnClose={true}
        footer={[
          <Button key="back" onClick={handleCloseDeleteModal} disabled={itemBusy}>
            <Translate stringId="cancel" defaultMessage="Cancel" />
          </Button>,
          <Button key="delete" type="primary" onClick={handleDeleteAttachment} disabled={itemBusy} loading={itemBusy}>
            <Translate stringId="delete" defaultMessage="Delete" />
          </Button>,
        ]}
      >
        <p>
          <Translate
            stringId="confirmDeleteAttachment"
            defaultMessage="Are you sure you want to delete the attachment?"
          />
        </p>
      </Modal>

      <Styled>
        <Spinner spin={tab !== 'attachments' || masterDataBusy || loadingOrderAttachments}>
          <div className="upload-button-container ">
            <Button innerRef={uploadButtonRef} onClick={handleUpload} disabled={!userCanEditOrders}>
              <i className="upload-icon font-lg fa fa-upload" />
              <Translate stringId="clickToUpload" defaultMessage="Click to Upload" />
            </Button>
          </div>
          <input
            hidden
            accept={ACCEPT_TYPE}
            autoComplete="off"
            type="file"
            multiple={false}
            ref={fileInputRef}
            onChange={handleInitiateAttachmentUpload}
          />
          {attachments?.projectAttachments?.items &&
            attachments?.projectAttachments?.items?.map((attachment, index) => (
              <ProjectAttachment
                key={attachment?.meta?.crn}
                attachmentCrn={attachment?.meta?.crn}
                contentType={attachment?.meta?.contentType}
                createDate={attachment?.meta?.createDate}
                link={attachment.link}
                index={index}
                badge={attachment?.meta?.type}
                badgeColor="#dc3545"
              />
            ))}
          {attachments?.orderAttachments?.items &&
            attachments?.orderAttachments?.items?.map((attachment, index) => (
              <ProjectAttachment
                key={attachment?.meta?.crn}
                attachmentCrn={attachment?.meta?.crn}
                contentType={attachment?.meta?.contentType}
                createDate={attachment?.meta?.createDate}
                link={attachment.link}
                index={index}
                badge={attachment?.meta?.type}
                badgeColor="#ffc107"
                onDelete={handleInitiateAttachmentDelete}
              />
            ))}
        </Spinner>
      </Styled>
    </>
  );
};

export default AttachmentsList;
