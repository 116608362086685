import { omit } from 'lodash';

const flattenBillables = (billables, openDrawerHandler) => {
  const products = [];

  const keysToOmmit = ['quantity', 'productId', 'productName', 'totalPrice', 'extendedPrice', 'salesTax'];

  billables?.forEach(billable => {
    billable?.lineItems?.forEach((product, index) => {
      const row = omit(billable, keysToOmmit);

      row.ticketQuantity = billable['quantity'];
      row.ticketProductId = billable['productId'];
      row.ticketProductName = billable['productName'];
      row.ticketTotalPrice = billable['totalPrice'];
      row.ticketExtendedPrice = billable['extendedPrice'];
      row.ticketSalesTax = billable['salesTax'];

      // Without a unique id for each row the grid will crash
      row.localId = `${index}-${billable['ticketId']}`;

      //Add the fuction to open the drawer
      if (billable['crn']) {
        row.openDrawerHandler = () => openDrawerHandler({ crn: billable['crn'] });
      }

      products.push({ ...row, ...product });
    });
  });

  return products;
};

export default flattenBillables;
