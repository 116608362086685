import {css} from 'styled-components';

export default css`
  width: 100%;
  height: 100%;
  padding: 10px;
  background-color: #000000;
  
  .title {
    width: 100%;
    font-size: 1.5em;
    text-align: center;
    font-weight: bold;
  }
`;
