import { css } from 'styled-components';

export default css`
  & > *:not(:first-child) {
    margin-top: 10px;
  }

  .label {
    font-weight: bold;
    background-color: #434343;
    padding: 0 5px;
  }

  .slump,
  .hold-quantity {
    .field-container {
      border: 1px solid #434343;
      border-top: 0;
    }
  }
  .quantity-cleanup {
    display: flex;

    & > * {
      flex: 1;
    }

    & > .hold-quantity,
    & > .quantity {
      border: 1px solid #434343;
      border-top: 0;
    }

    .cleanup {
      display: flex;
      flex-direction: column;

      .label {
        text-align: center;
        width: 100%;
      }

      .wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid #434343;
        border-right: 1px solid #434343;
      }

      text-align: center;
      align-items: center;
    }
  }

  .actions {
    margin-top: 15px;

    & > * {
      margin-top: 15px;
      &:first-child {
        display: flex;
        grid-gap: 10px;
      }
    }

    button {
      width: 100%;
      &.accept {
        color: #fff;
        background-color: #349c34;
        &:disabled {
          background-color: #313131;
          color: #6f6f6f;
        }
      }
    }

    .bottom {
      display: flex;
      align-items: flex-end;
      flex: 1;
    }
  }
`;
