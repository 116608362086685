import React from 'react';
import { useIntl } from 'react-intl';
import { useAppContext } from '../../../../AppContext';
import toDateTime from './toDateTime';

const shortDate = ({ date, formatDate, timeZone, withDayOfWeek = false, showYear = true }) => {
  if (!date) return '';

  const dateTime = toDateTime(date, timeZone);

  const options = {
    year: showYear ? 'numeric' : undefined,
    month: '2-digit',
    day: '2-digit',
    timeZone,
    weekday: withDayOfWeek ? 'short' : undefined,
  };
  return formatDate(dateTime, options);
};

export const ShortDate = ({ date, withDayOfWeek = false, timeZone: timeZoneOverride, showYear }) => {
  const { formatDate } = useIntl();
  const { timeZone: defaultTimeZone } = useAppContext();

  const timeZone = timeZoneOverride || defaultTimeZone;

  const tz = React.useMemo(() => timeZoneOverride || timeZone, [timeZone, timeZoneOverride]);

  return shortDate({ date, formatDate, timeZone: tz, withDayOfWeek, showYear });
};

export const useShortDate = (options = {}) => {
  const { timeZone: timeZoneOverride } = options;
  const { formatDate } = useIntl();
  const { timeZone: defaultTimeZone } = useAppContext();

  const timeZone = React.useMemo(() => timeZoneOverride || defaultTimeZone, [defaultTimeZone, timeZoneOverride]);

  /**
   * deprecated
   * @type {function(*, boolean=): string|*}
   */
  const getShortDate = React.useCallback(
    (date, withDayOfWeek = false) => {
      return shortDate({ date, formatDate, timeZone: timeZone, withDayOfWeek });
    },
    [formatDate, timeZone]
  );

  const getShortDateString = React.useCallback(
    ({ date, withDayOfWeek = false, timeZone: timeZoneOverride, showYear }) => {
      return shortDate({ date, formatDate, timeZone: timeZoneOverride || timeZone, withDayOfWeek, showYear });
    },
    [formatDate, timeZone]
  );

  return { getShortDate, getShortDateString, ShortDate };
};
