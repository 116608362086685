import React, { useCallback, useMemo, useState } from 'react';
import cn from 'classnames';
import moment from 'moment';
import styled from 'styled-components';
import { useViewport } from '../../../hooks/useViewport';
import getUomAbbreviationOrDescription from '../../../util/uom';
import FieldContainer from '../../field-container/FieldContainer';
import { Drawer } from 'antd';
import TicketList from './TicketList';

const TicketInfo = ({ className, tickets = [], meta }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const closeDrawer = useCallback(() => {
    setDrawerOpen(false);
  }, []);

  const openDrawer = useCallback(() => {
    if ((meta?.count || 0) > 0) {
      setDrawerOpen(true);
    }
  }, []);

  const viewport = useViewport();

  const drawerWidth = useMemo(() => {
    return Math.min(500, viewport.width);
  }, [viewport]);

  return (
    <FieldContainer className={cn('ticket-info', className)}>
      <div className="label-spinner">
        <div className="label">Ticket Info</div>
        <div className={cn('spinner', { spinning: false })}>
          <span className="fas fa-sync fa-spin" />
        </div>
      </div>
      <div className="summary-info" onClick={openDrawer}>
        <div className="ticket-count">Ticket Count: {meta?.count || 0}</div>
        <div className="load-summary">
          {meta?.totalDeliveredQuantity} / {meta.totalOrderedQuantity}
        </div>
        {(meta?.count || 0) > 0 && (
          <div className="detail-trigger">
            Details <span className="chevron fas fa-chevron-right" />
          </div>
        )}
      </div>
      <Drawer
        width={drawerWidth}
        title="Ticket Details"
        placement="right"
        closable
        onClose={closeDrawer}
        visible={drawerOpen}
      >
        <TicketList tickets={tickets} />
      </Drawer>
    </FieldContainer>
  );
};

export default styled(TicketInfo)`
  .summary-info {
    cursor: ${({ meta }) => ((meta?.count || 0) > 0 ? 'pointer' : 'default')};
    display: flex;
    border: 1px solid #434343;
    padding: 4px 11px;

    .load-summary {
      margin-left: 20px;
      flex: 1;
    }
    .detail-trigger {
      .chevron {
        margin-left: 10px;
      }
    }
  }
`;
