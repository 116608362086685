import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
import FieldContainer from '../../field-container/FieldContainer';
import Input from '../../form/Input';
import style from './style';

const Job = ({ className, value, disabled }) => {
  return (
    <FieldContainer className={cn('job', className)}>
      <div className="label-spinner">
        <div className="label">Job</div>
        <div className={cn('spinner', { spinning: false })}>
          <span className="fas fa-sync fa-spin" />
        </div>
      </div>

      <Input type="text" value={value?.description || ''} disabled={disabled} readOnly />
    </FieldContainer>
  );
};

export default styled(Job)`
  ${style}
`;
