import React from "react";
import style from './style';
import styled from 'styled-components';
import Button from "../../../components/button/Button";
import {useNavigate} from "react-router-dom";
import { kebabCase } from 'lodash';

const Styled = styled.div`${style}`;

const plural = type => {
  return type?.endsWith('s') ? `${type}es` : `${type}s`
}

const SetupHeader = ({ title, type, onAdd, showAdd = true }) => {
  const navigate = useNavigate();

  return (
    <Styled>
      <div className='header-title'>
        <Button onClick={() => navigate('..')}
                icon={<i className="icon fa fa-caret-left" />}
                type='primary'
                size='small'
                className='back'
                metricId='core-setup-back-to-setup'
        >Back to Setup</Button>
        {title ? title : `Setup ${plural(type)}`}
      </div>
      {showAdd && <Button onClick={() => onAdd()}
              icon={<i className="icon fa fa-plus" />}
              type='primary'
              size='small'
              className='action pull-right'
              metricId= {`core-setup-add-${kebabCase(type)}`}
      >Add {type}</Button>}
    </Styled>
  )
}

export default SetupHeader;

