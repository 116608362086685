import PieChart, { SEVERITY } from '../../../../components/pie-chart/PieChart';
import React, { useEffect, useState } from 'react';
import inTransit from '../in-transit';
import moment from 'moment';
import { isCancelledOrder } from '../util';

const OrderProgressIcon = ({ order }) => {
  const [complete, setComplete] = useState(0);
  const [cancelled, setCancelled] = useState(false);
  const [delivering, setDelivering] = useState(false);
  const [progress, setProgress] = useState(SEVERITY.SUCCESS);
  // const complete = useMemo(() => {
  //
  // }, [order])

  useEffect(() => {
    if (order) {
      if (isCancelledOrder(order?.supplierStatus?.name)) {
        setCancelled(true);
        return;
      }

      let scheduledLoads = order?.deliverySchedule?.schedule || [];

      const numTickets = order?.deliverySchedule?.tickets?.filter(t => t.ticket.isVoided !== true)?.length || 0;

      scheduledLoads = scheduledLoads.filter(s => s.loadNumber > numTickets);

      const numScheduled = scheduledLoads?.length || 0;

      if (numTickets > 0) {
        setComplete((numTickets / (numTickets + numScheduled)) * 100);

        setDelivering(inTransit(order?.deliverySchedule?.tickets || []));
      }
      if (numScheduled < 1) {
        setProgress(SEVERITY.SUCCESS);
      } else {
        const now = moment();
        const nextScheduledLoad = moment(order?.deliverySchedule?.schedule[0].ticketEvents?.ARRIVE_JOB);
        const minutesToArrive = nextScheduledLoad.diff(now, 'm');

        if (minutesToArrive > 0) {
          setProgress(SEVERITY.SUCCESS);
        } else {
          setProgress(Math.abs(minutesToArrive) > 60 ? SEVERITY.DANGER : SEVERITY.WARNING);
        }
      }
    }
  }, [order]);

  if (cancelled) {
    return <i className={`fas fa-ban complete`} />;
  }

  const icon = !delivering ? 'fa-clipboard-check' : 'fa-clipboard-list-check';

  return (
    (complete < 100 && <PieChart size="45" percentage={complete} severity={progress} />) || (
      <i className={`fad ${icon} complete`} />
    )
  );
};

export default OrderProgressIcon;
