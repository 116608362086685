import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
import { useOrderListContext } from '../../../components/OrderListContext';
import { CustomButton, FutureButton, TodayButton, TomorrowButton } from './buttons';
import style from './style';
import AdvancedSearch from "./buttons/AdvancedSearch";

const Styled = styled.div`
  ${style}
`;

const QuickDates = props => {
  const { quickDate, setQuickDate, entity } = useOrderListContext();
  const { timeZone } = props;

  const handleDateChange = React.useCallback(
    date => {
      setQuickDate(date);
    },
    [setQuickDate]
  );

  return (
    <Styled className={cn('quick-dates')}>
      <TodayButton onChange={handleDateChange} currentDate={quickDate} />
      <TomorrowButton onChange={handleDateChange} currentDate={quickDate} />
      <FutureButton onChange={handleDateChange} currentDate={quickDate} />
      <CustomButton onChange={handleDateChange} currentDate={quickDate} timeZone={timeZone} />
      <AdvancedSearch onChange={handleDateChange} currentDate={quickDate}  />
    </Styled>
  );
};

export default QuickDates;
