import React from 'react';
import { useParams } from 'react-router-dom';
import { useOrderContext } from '../../useOrderContext';
import API from '../api';
import { useMapState } from '../hooks/useMapState';
import useSetup from '../../../../setup/useSetup';

const updateRoute = ({ waypoints, entityRef, orderRef }) => {
  const body = {
    waypointArray: waypoints?.map?.(waypoint => [waypoint.lng, waypoint.lat]),
  };
  return API.updateRoute({ entityRef, orderRef, body });
};

export const useExistingOrderMapState = (readOnly, onDone) => {
  const state = useMapState(readOnly);

  const { order } = useOrderContext();
  const { entityRef } = useParams();

  const {
    form: { handleChange },
  } = useOrderContext();

  const [updatingOrderAddress, setUpdatingOrderAddress] = React.useState(false);
  const { updateProject, project, getProject } = useSetup();

  React.useEffect(() => {
    if (order?.order?.projectRef && order?.order?.projectRef !== 'NONE') {
      getProject(entityRef, order?.order?.projectRef);
    }
  }, [entityRef, order?.order?.projectRef, getProject]);

  const onAcceptance = React.useCallback(
    async (address, updateProjectAddress = false) => {
      if (!readOnly) {
        setUpdatingOrderAddress(true);

        // This updateOrderAddress endpoint support multi primary orders, so even if a sub order crn is passed here
        // The order group and all the sub orders are going to get updated
        const response = await order.updateOrderAddress(entityRef, order?.order?.crn, address);

        const promiseArray = [];
        //This updateRoute is from ruckit so we need to pass it the sub orders directly
        order?.order?.linkedOrders?.forEach(lo => {
          // updateOrderAddress needs to finish before updateRoute is called. Otherwise, the waypoints (at least the new ones) will get wiped out.
          promiseArray.push(updateRoute({ waypoints: state.waypoints, entityRef, orderRef: lo.orderRef }));
        });
        const updatedRoutes = await Promise.all(promiseArray);

        setUpdatingOrderAddress(false);
        handleChange('address', address);

        if (updatedRoutes.every(r => r)) {
          onDone();
        }

        if (updateProjectAddress) {
          updateProject(entityRef, project.crn, { ...project, address });
        }
        return response;
      }
      onDone();
    },
    [entityRef, handleChange, onDone, order, readOnly, state.waypoints, project, updateProject]
  );

  return {
    ...state,
    configBusy: state.configBusy || updatingOrderAddress,
    onDone,
    onAcceptance,
  };
};
