import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { Button } from 'reactstrap';
// import { AppHeader } from '@coreui/react';
// import commandLogo from '../assets/connex-logo-dark-theme.svg';

const handleRefresh = () => {
  window.location.reload();
};

const MandatoryRefresh = ({ className }) => {
  return (
    <div className={cn(className)}>
      {/*<AppHeader fixed className="app-header">*/}
        <div className="w-100 align-content-center">
          {/*<img src={commandLogo} className="login-logo-header-sm" alt="CONNEX" />*/}
        </div>
      {/*</AppHeader>*/}

      <div className="content">
        <div className="title">A new version of CONNEX is available.</div>
        <div className="message">To proceed, you must reload the app.</div>
        <div className="buttons">
          <Button onClick={handleRefresh}>Reload</Button>
        </div>
      </div>
    </div>
  );
};

export default styled(MandatoryRefresh)`
  height: 100vh;
  background-color: #000;
  .app-header {
    padding: 10px;
    background-color: #2c2c2d;

    img {
      width: 139px;
    }
  }

  .content {
    text-align: center;
    padding-top: 20%;
    color: #fff;
    .title {
      font-weight: bold;
      font-size: 1.2em;
    }
    .message {
    }
    .buttons {
      margin: 20px;
      button {
        width: 125px;
        height: 37px;
        font-size: medium;

        padding: 0.25rem 0.5rem;
        line-height: 1.5;
        border-radius: 0.2rem;

        color: #ffffff;
        background-color: #424344;
        border: 1px solid #424344;

        display: inline-block;
        font-weight: 400;
        text-align: center;
        vertical-align: middle;
        user-select: none;
      }
    }
  }
`;
