import { find, omit } from 'lodash';
import React from 'react';
import Select from '../../../components/form/Select';
import Margin from '../components/Margin';
import { useScaleTicketingContext } from '../Context';

const { Option } = Select;

const Vehicle = () => {
  const { vehicles, form, handleChange } = useScaleTicketingContext();

  const handleVehicleChange = React.useCallback(
    ref => {
      const targetVehicle = find(vehicles, { crn: ref });
      if (targetVehicle) {
        handleChange('vehicle', targetVehicle);
      }
    },
    [handleChange, vehicles]
  );

  return (
    <Margin top={10}>
      <div className="field-label">Vehicle</div>
      <Select
        value={form?.vehicle?.crn || ''}
        showSearch
        filterOption={(input, option) => {
          return option?.children?.toLowerCase?.().indexOf(input.toLowerCase?.()) >= 0;
        }}
        disabled={false}
        onChange={handleVehicleChange}
      >
        {vehicles?.map(vehicle => (
          <Option key={vehicle?.crn} value={vehicle.crn}>
            {`${vehicle.id} / ${vehicle.name}`}
          </Option>
        ))}
      </Select>
    </Margin>
  );
};

export default Vehicle;
