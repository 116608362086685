import http from '../../../../http';
import { groupBy, pick } from 'lodash';

const orders = '/connex/services/orders';

const listMixes = async (entityRef, params) => {
  const items = [];
  let pageToken = null;
  do {
    const result = await http.post(`${orders}/${entityRef}/setup/mix/search`, { pageToken }, params);
    items.push(...result.items);
    pageToken = result.pageToken;
  } while (pageToken);

  return items;
};

const listFormulas = async (entityRef, params) => {
  return http.get(`${orders}/${entityRef}/setup/formula`, params);
};

const listMixDesigns = async (entityRef, params) => {
  const mixes = await listMixes(entityRef, params);
  const formulas = await listFormulas(entityRef, params);

  const grouped = groupBy(formulas, 'mix.mixRef') || {};

  for (const mix of mixes) {
    if (mix.formulas) {
      continue;
    }

    const formulas = grouped[mix.crn];

    if (formulas) {
      mix.formulas = formulas.map(f => pick(f, ['components', 'locations']));
    } else {
      mix.formulas = [];
    }
  }

  return mixes;
};

export default listMixDesigns;
