import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { isEmpty } from 'lodash';
import { Alert } from 'antd';

const Styled = styled.div`
  text-overflow: ellipsis;
`;
const ValidationMessage = ({ validationErrors, id }) => {
  const errorMessage = validationErrors?.[id];
  return isEmpty(errorMessage) ? null : (
    <Styled className={cn('validation-message')}>
      <Alert showIcon message={errorMessage} type="error" />
    </Styled>
  );
};

export default ValidationMessage;
