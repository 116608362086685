
const ticketEventLabels = {
  CREATED: 'Ticketed',
  PRINTED: 'Printed',
  LOADING: 'Loading',
  LOADING_STARTED: 'Loading',
  LOADING_COMPLETE: 'Loaded',
  TO_JOB: 'In Transit',
  ARRIVE_JOB: 'On Job',
  UNLOADING: 'Unloading',
  END_UNLOAD: 'Unloaded',
  END_UNLOADING: 'Unloaded',
  WASHING: 'Washing',
  LEAVE_JOB: 'Returning',
  ARRIVE_PLANT: 'Arrive Plant',
  IN_YARD: 'In Yard',
};

export default ticketEventLabels;
