import React from 'react';
import styled from 'styled-components';
import InputAddress from '../../../components/input-address';
import InputSelect from '../../../components/input-select';
import InputText from '../../../components/input-text';
import SelectSalesPerson from '../../../components/select-sales-person';
import SelectCreditCode from '../../../components/select-credit-code';
import InputOutstandingBalance from './outstanding-balance';
import { status, taxStatus, categoryStatus } from '../options';
import style from '../style';
import ValidationMessage from './ValidationMessage';
import InputSelectMultiple from '../../../components/input-select-multiple';
import InputCheckbox from '../../../components/input-checkbox';
import { useTaxExemptReasons } from '../../../../../hooks/useTaxExemptReasons';
import { useTranslateMessage } from '../../../../order/components/localization/useTranslateMessage';

const View = styled.div`
  ${style}
`;

const CustomerGeneral = ({ customer, onChange, validationErrors = {} }) => {
  const idFieldRef = React.useRef();
  const { taxExemptReasons, taxExemptReasonsBusy, taxExemptReasonRequired } = useTaxExemptReasons();
  const translateMessage = useTranslateMessage();

  React.useEffect(() => {
    setTimeout(() => {
      idFieldRef?.current?.focus?.();
    }, 400);
  }, []);

  return (
    <View>
      <ValidationMessage validationErrors={validationErrors} id="id" />
      <InputText
        id="id"
        label="ID"
        value={customer?.id}
        onChange={onChange}
        innerRef={idFieldRef}
        disabled={!!customer?.crn}
      />
      <InputText id="name" label="Name" value={customer?.name} onChange={onChange} />
      <InputAddress id="address" item={customer} onChange={onChange} />
      <SelectSalesPerson id="salesPersonRef" item={customer} onChange={onChange} />
      <InputSelectMultiple
        id="categories"
        label="Categories"
        value={customer?.categories || []}
        onChange={onChange}
        options={categoryStatus}
        required
      />
      <div className="form-group two-columns-container">
        <SelectCreditCode id="creditCodeRef" className="left" item={customer} onChange={onChange} />
        <InputCheckbox
          id="requirePurchaseOrder"
          className="right"
          label="Require Purchase Order"
          onChange={onChange}
          value={customer?.requirePurchaseOrder}
        />
      </div>
      <InputOutstandingBalance item={customer} onChange={onChange} />
      <InputSelect
        id="taxStatus"
        label="Tax Status"
        value={customer?.taxStatus ?? 'TAXABLE'}
        onChange={onChange}
        options={taxStatus}
        required
      />
      {customer?.taxStatus === 'EXEMPT' && taxExemptReasonRequired && (
        <InputSelect
          id="taxExemptReasonRef"
          label={translateMessage({ stringId: 'taxExemptReason', defaultMessage: 'Tax-Exempt Reason' })}
          value={customer?.taxExemptReasonRef}
          options={taxExemptReasons}
          onChange={onChange}
          required
          busy={taxExemptReasonsBusy}
        />
      )}
      <InputSelect id="status" label="Status" value={customer?.status} onChange={onChange} options={status} required />
    </View>
  );
};

export default CustomerGeneral;
