import React from 'react';
import Select from '../../../../components/form/Select';
import styled from 'styled-components';
import { Translate } from '../localization/Translate';

const { Option } = Select;

const Title = styled.div`
  padding: 5px;
`;

const SelectVehicle = ({ vehicleRef, vehicles = [], onChange, disabled }) => {
  return (
    <div>
      <Title>
        <Translate stringId="assignedVehicle" defaultMessage="Assigned Vehicle" /> *
      </Title>
      <Select
        id="select-vehicle"
        data-testid="select-assigned-vehicle"
        className="select-vehicle"
        defaultValue={''}
        showSearch
        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        value={vehicleRef}
        onChange={onChange}
        disabled={disabled}
      >
        <Option value="">Select...</Option>
        {vehicles?.map((v, key) => {
          const labelPieces = [];

          v.id && labelPieces.push(v.id);
          v.name && labelPieces.push(v.name);

          const label = labelPieces.join(' / ');

          return (
            <Option key={`${key}-select-vehicle`} value={v.crn}>
              {label}
            </Option>
          );
        })}
      </Select>
    </div>
  );
};

export default SelectVehicle;
