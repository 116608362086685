import style from "../style";
import React, {useMemo} from "react";
import styled from 'styled-components';
import InputText from "../../../components/input-text";
import InputNumber from "../../../components/input-number";

import InputSelect from "../../../components/input-select";
import {INTERFACE_METHODS, INTERFACE_READ_TYPE} from './options';
import {find} from "lodash";

const View = styled.div`${style}`;

const ScaleInterface = ({ scale, dexaList, functions, onChange }) => {
  const dexaOptions = useMemo(() => {
    return dexaList.map(dexa => ({
      label: dexa.displayLabel,
      value: dexa.crn,
    }))
  }, [dexaList]);

  const functionOptions = useMemo(() => {
    return (functions || []).map(f => ({
      label: f.description,
      value: f.crn,
    }))
  }, [functions]);

  return (
    <View>
      <InputSelect id='dexa'
                   label='DExA'
                   value={scale?.dexa?.dexaRef}
                   onChange={(id, value) => {
                     const item = find(dexaOptions, o => o.value === value) || null;
                     onChange(id, {name: item.label, dexaRef: item.value});
                   }}
                   options={dexaOptions}
                   required
      />

      <InputSelect id='interfaceType'
                   label='Interface Method'
                   value={scale?.interfaceType}
                   onChange={onChange}
                   options={INTERFACE_METHODS}
                   required
      />

      <InputText label={'Hostname'} id={'hostname'} disabled={scale?.interfaceType !== 'ETHERNET'} onChange={onChange} value={scale?.hostname} />
      <InputNumber label={'Port'} id={'port'} disabled={scale?.interfaceType !== 'ETHERNET'} onChange={onChange} value={scale?.port} />

      <InputText id={'comPort'} label={'COM Port'} disabled={scale?.interfaceType !== 'SERIAL'} onChange={onChange} value={scale?.comPort} />

      <InputText id={'startByteValue'} label={'Start Byte Value'} onChange={onChange} value={scale?.startByteValue} />
      <InputText id={'readLength'} label={'Read Length'} onChange={onChange} value={scale?.readLength} />

      <InputSelect id='interfaceReadType'
                   label='Interface Read Type'
                   value={scale?.interfaceReadType}
                   onChange={onChange}
                   options={INTERFACE_READ_TYPE}
                   required
      />

      <InputSelect id='parseFunctionRef'
                   label='Data Parse Function'
                   value={scale?.parseFunctionRef}
                   onChange={onChange}
                   options={functionOptions}
                   required
      />

    </View>
  )
}

export default ScaleInterface;
