import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { useNotes } from '../../../../views/order-request/view-or-edit-order/edit-order/useNotes';
import Button from '../../../button/Button';
import style from './style';
import Note from './Note';
import CreateNote from './CreateNote';

const NoteList = ({ className, notes: initialNotes, onDone, orderRef, onChange }) => {
  const [notes, setNotes] = useState(initialNotes);
  const [note, setNote] = useState('');
  const { createOrderNote, creatingOrderNote, listOrderNotes, listingOrderNotes } = useNotes();

  const handleChange = useCallback(value => {
    setNote(value);
  }, []);

  const handleSaveNote = useCallback(() => {
    createOrderNote(orderRef, note).then(response => {
      setNote('');
      listOrderNotes(orderRef).then(response => {
        setNotes(response);
        onChange('notes', response);
      });
    });
  }, [createOrderNote, listOrderNotes, note, onChange, orderRef]);

  const handleDoneClick = React.useCallback(() => {
    onDone(notes);
  }, [notes, onDone]);

  const busy = useMemo(() => creatingOrderNote || listingOrderNotes, [creatingOrderNote, listingOrderNotes]);

  return (
    <div className={cn('note-list', className)}>
      <div className="note-list-container">
        <div className="note-list-body">
          {notes.map(n => (
            <Note note={n} key={`${n.crn}`} />
          ))}

          {notes.length === 0 && <div className="no-notes">No notes found on this order request.</div>}
        </div>

        <CreateNote onChange={handleChange} value={note} onSaveNote={handleSaveNote} busy={busy} />

        <div className="note-list-buttons">
          <Button onClick={handleDoneClick} disabled={busy}>
            Done
          </Button>
        </div>
      </div>
    </div>
  );
};

export default styled(NoteList)`
  ${style}
`;
