import React, { createContext, useContext, useEffect, useState } from 'react';
import Spinner from '../../../components/spinner/Spinner';
import { useUserContext } from '../components/user-context/UserContextProvider';
import { useVersion } from './useVersion';
import MandatoryRefresh from './MandatoryRefresh';
import { onChunkFailure } from './SafeImport';
import VersionModal from './VersionModal';

export const VersionContext = createContext();

const handleRefresh = () => {
  window.location.reload();
};

export const useVersionContext = () => {
  const context = useContext(VersionContext);

  if (!context) {
    throw new Error('useVersionContext cannot be called outside of the VersionContext context provider');
  }

  return context;
};

const VersionCheck = ({ children }) => {
  const [chunkFailure, setChunkFailure] = useState(false);
  const version = useVersion();

  // Temporarily perform version-checking only for those in the 'ui-test' group.
  const user = useUserContext();
  const isUiTester = (user?.permissions?.groups || []).includes('ui-test');

  useEffect(() => {
    onChunkFailure(() => {
      setChunkFailure(true);
    });
  }, [version.clientVersion, version.handleNewVersion]);

  if (chunkFailure) {
    return <MandatoryRefresh />;
  }

  // Don't render the App until the attempt to obtain the version is complete.
  return (
    <VersionContext.Provider value={{ version }}>
      {isUiTester ? (
        <>
          <VersionModal version={version} onRefresh={handleRefresh} />
          <Spinner spin={version.versionBusy}>{children}</Spinner>
        </>
      ) : (
        children
      )}
    </VersionContext.Provider>
  );
};

export default VersionCheck;
