import { css } from 'styled-components';

export default css`
  display: flex;
  .label {
    margin-right: 20px;
  }
  .override {
    display: flex;
    cursor: pointer;
    transition: width 0.3s;
    width: 120px;
    &.isOpen {
      width: 320px;
    }
    .button-label {
      padding: 0 5px;
      text-align: center;
      border: 1px solid #444444;
      width: 120px;
    }
    .form {
      display: flex;
      flex: 1;
      width: 200px;
      overflow: hidden;
      input {
        width: 35px;
      }
    }
  }
`;
