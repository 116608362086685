import styled from 'styled-components';
import style from './style';

const Styled = styled.div`
  ${style}
`;

const FlexItem = ({ title, text }) => {
  return (
    <Styled>
      <div className="title">{title}</div>
      <div className="text-value">{text}</div>
    </Styled>
  );
};

export default FlexItem;
