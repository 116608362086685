import { Drawer } from 'antd';
import Button from '../../../../components/button/Button';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { useViewport } from '../../../../hooks/useViewport';
import { Translate } from '../../../order/components/localization/Translate';
import CreateInvoice from './create-invoice';
import style from './style';

const Styled = styled.div`
  ${style}
`;

const InvoiceFromSelected = ({ exportRows, sort, filters, refreshList }) => {
  const [items, setItems] = useState(null);

  const viewport = useViewport();

  const drawerWidth = useMemo(() => {
    return Math.min(800, viewport.width);
  }, [viewport]);

  const handleClick = () => {
    // setItems(rows.map(row => ({ billableRef: row.crn })))
    setItems(exportRows);
  };

  const closeDrawer = () => {
    setItems(null);
    refreshList();
  };

  return (
    <Styled>
      <Button size="small" onClick={handleClick} metricId="core-unbilled-deliveries-review">
        <Translate stringId="invoices.review" defaultMessage="Review" /> <i className="fal fa-angle-double-right" />{' '}
      </Button>
      <Drawer
        width={drawerWidth}
        title={`Selected ${exportRows?.length} Unbilled Deliver${exportRows?.length > 1 ? 'ies' : 'y'}`}
        placement="right"
        closable
        onClose={closeDrawer}
        visible={items}
        destroyOnClose
      >
        <CreateInvoice items={items} filters={filters} />
      </Drawer>
    </Styled>
  );
};

export default InvoiceFromSelected;
