import {css} from 'styled-components';

export default css`
  width: 100%;
  
  table, tr {
    width: 100%;
  }
  
  td:first-child {
    width: 50%;
  }
`;
