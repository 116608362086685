import { Drawer, Collapse } from 'antd';
import cn from 'classnames';
import React from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';
import Button from '../../../../../components/button/Button';
import Buttons from '../../../../../components/layout/Buttons';
import { Translate } from '../../localization/Translate';
import ModelingGraph from './ModelingGraph';
import Products from './Products';
import ScheduleContextProvider, { useScheduleContext } from './ScheduleContext';
import style from './style';
import { useOrderContext } from '../../useOrderContext';
import addOrderGroupDataToLinkedOrder from '../../../util/add-order-group-data-to-linked-order';

const { Panel } = Collapse;

const Styled = styled.div`
  ${style}
`;

const Schedule = ({ className, userCanEditOrders }) => {
  const { scheduleBusy, order, reset, dirty, createSchedule, schedule, drawerOpen, closeDrawer, drawerWidth } =
    useScheduleContext();

  const { orderId } = useParams();

  const canUpdateSchedule = order?.originatorSystemType === 'CONNEX';

  return (
    <Styled className={cn('schedule', className)}>
      <Products />
      {canUpdateSchedule && (
        <Buttons>
          <Button onClick={() => reset(orderId)} disabled={scheduleBusy || !dirty} metricId="core-order-detail-reset">
            <Translate stringId="reset" defaultMessage="Reset" />
          </Button>
          <Button
            onClick={createSchedule}
            loading={scheduleBusy}
            disabled={scheduleBusy || !userCanEditOrders}
            metricId="core-order-detail-create-update-schedule"
          >
            {schedule?.length ? (
              <Translate stringId="updateSchedule" defaultMessage="Update Schedule" />
            ) : (
              <Translate stringId="createSchedule" defaultMessage="Create Schedule" />
            )}
          </Button>
        </Buttons>
      )}
      <Drawer
        className={cn('preferences-drawer')}
        visible={drawerOpen}
        onClose={closeDrawer}
        title={
          <Translate
            stringId="title_orderModeling"
            defaultMessage={'_Order Modeling - {orderId} - {locationName}'}
            values={{ orderId: order.id, locationName: order?.location?.name }}
          />
        }
        width={drawerWidth}
        destroyOnClose
      >
        <ModelingGraph
          targetOrderRef={order.crn ?? order.orderRef}
          targetStartDateTime={order?.deliverySchedule?.startDateTime}
          targetLocation={order?.location}
          onCancel={closeDrawer}
        />
      </Drawer>
    </Styled>
  );
};

const StyledMultiSchedules = styled.div`
  .panel-header {
    margin-left: 20px;
  }
`;

export default props => {
  const {
    order: { rawOrder },
    userCanEditOrders,
  } = useOrderContext();

  const linkedOrders = rawOrder?.linkedOrders?.filter?.(o => o?.orderRef);

  if (linkedOrders?.length > 1) {
    return (
      <StyledMultiSchedules>
        <Collapse defaultActiveKey={linkedOrders.map(lo => lo.orderRef)}>
          {linkedOrders.map((order, index) => {
            return (
              <Panel
                header={
                  <div className="panel-header">
                    <Translate
                      stringId="orderLineNumber"
                      defaultMessage="Order Line #{num, number}"
                      values={{ num: index + 1 }}
                    />
                  </div>
                }
                key={order.orderRef}
              >
                <ScheduleContextProvider linkedOrder={addOrderGroupDataToLinkedOrder(rawOrder, order)}>
                  <Schedule {...props} userCanEditOrders={userCanEditOrders} />
                </ScheduleContextProvider>
              </Panel>
            );
          })}
        </Collapse>
      </StyledMultiSchedules>
    );
  }
  if (!linkedOrders?.[0]) {
    return null;
  }
  return (
    <ScheduleContextProvider linkedOrder={addOrderGroupDataToLinkedOrder(rawOrder, linkedOrders?.[0])}>
      <Schedule {...props}  userCanEditOrders={userCanEditOrders} />
    </ScheduleContextProvider>
  );
};
