import { toLower } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { Number } from '../../../components/localization/Number';
import { Translate } from '../../../components/localization/Translate';
import { SEVERITY } from '../../../components/pie-chart/PieChart';

const Styled = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-right: 20px;
  grid-gap: 10px;
  .grid-container {
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    grid-gap: 10px;
    padding: 10px;
  }
  .grid-container > div {
    text-align: center;
    font-size: 0.8rem;
  }
  .total {
    grid-column: 1 / span 5;
    font-size: 0.8rem;
  }
  .item {
    display: flex;
    align-items: center;
    .label {
      font-size: 0.8rem;
    }
    .box {
      height: 10px;
      width: 10px;
      margin-right: 5px;
      &.delivered {
        background-color: #177ddc;
      }

      &.cancelled {
        background-color: #aaa;
      }

      &.warning {
        background-color: #dfc867;
      }

      &.success {
        background-color: #40937e;
      }

      &.danger {
        background-color: #c16868;
      }
    }
  }
`;

const statuses = [
  {
    key: SEVERITY.CANCELLED,
    label: 'Cancelled',
  },
  {
    key: SEVERITY.SUCCESS,
    label: 'Scheduled',
  },
  {
    key: SEVERITY.DELIVERED,
    label: 'Delivered',
  },
  {
    key: SEVERITY.WARNING,
    label: 'Late',
  },
  {
    key: SEVERITY.DANGER,
    label: '1+ hr Late',
  },
  {
    key: SEVERITY.TOTAL,
    label: 'Total',
  },
];

const roundQuantity = quantity => {
  return Math.round(quantity * 100) / 100;
};

const Legend = ({ visible, quantities, uomLabel = 'CY' }) => {
  if (!visible) return null;
  return (
    <Styled className={cn('legend')}>
      <div className="grid-container">
        {statuses.map(status => (
          <>
            {status.label !== 'Total' ? (
              <div className={'item'} style={{ flexDirection: 'column' }} key={status.key}>
                <div className={'item'}>
                  <div className={`box ${toLower(status.key)}`} />
                  <div className="label">
                    <Translate stringId={status.key} />
                  </div>
                </div>
                <div className="label">
                  <Number value={roundQuantity(quantities[status.key])} decimalPlaces={2} />{' '}
                  <Translate stringId={uomLabel} />
                </div>
              </div>
            ) : (
              <div className="total">
                <Translate stringId={status.label} defaultMessage="Total" />{' '}
                <Number value={roundQuantity(quantities[status.key])} decimalPlaces={2} />{' '}
                <Translate stringId={uomLabel} />
              </div>
            )}
          </>
        ))}
      </div>
    </Styled>
  );
};

export default Legend;
