import _ from 'lodash';
import React, { useCallback, useState } from 'react';
import BaseMarker from '../markers/BaseMarker';

const WaypointMarker = ({ ID, data, updateWaypointMarker, deleteWaypoint, canUpdate }) => {
  const [movedMarker, setMovedMarker] = useState(null);
  const baseMarker = React.useRef();

  const updateMarker = useCallback(
    e => {
      baseMarker?.current?.showInfo?.();
      const newMarker = _.cloneDeep(data);
      const coords = JSON.stringify(e.latLng.toJSON(), null, 2);
      const newCords = JSON.parse(coords);
      const lat = newCords.lat;
      const lng = newCords.lng;
      newMarker.lat = lat;
      newMarker.lng = lng;
      if (newMarker.status === 'pending') {
        newMarker.color = 'green';
        newMarker.status = 'confirmed';
      }
      updateWaypointMarker(newMarker);
      setMovedMarker(newMarker);
    },
    [data, updateWaypointMarker]
  );

  const icon = movedMarker?.icon || data.icon || 'default';
  const iconColor = movedMarker?.color || data.color || 'blue';
  const buttonStyle = { marginTop: 10, width: 200 };

  const registerCallback = React.useCallback(value => {
    baseMarker.current = value;
  }, []);

  return (
    <BaseMarker
      draggable={(canUpdate && ((movedMarker && movedMarker.draggable) || (!movedMarker && data.draggable))) || false}
      onDragEnd={updateMarker}
      key={ID}
      position={{
        lat: (movedMarker && movedMarker.lat) || data.lat,
        lng: (movedMarker && movedMarker.lng) || data.lng,
      }}
      onReady={registerCallback}
      icon={{ url: `/assets/map-icons/${icon}-${iconColor}.png`, scaledSize: { width: 50, height: 69 } }}
    >
      <>
        <div style={{ color: 'black' }}>
          {data.info &&
            data.info.map(info => {
              return (
                <div key={info.name}>
                  <b>{info.name}:</b> {info.desc}
                </div>
              );
            })}
          {canUpdate && (
            <button onClick={() => deleteWaypoint(data.sequence)} style={buttonStyle}>
              Delete Waypoint
            </button>
          )}
        </div>
      </>
    </BaseMarker>
  );
};

export default WaypointMarker;
