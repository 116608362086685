export default {
  // The current version of this JSON is version 2
  name: 'Configuration 1',
  selectedLocations: {},
  columns: {
    location: [
      {
        field: 'id',
        id: 'id',
        label: 'Location ID',
        width: 50,
      },
      {
        field: 'name',
        id: 'name',
        label: 'Location Name',
        width: 50,
      },
      {
        field: 'ticketCount',
        id: 'ticketCount',
        label: 'Ticketed Loads',
        width: 50,
      },
      {
        field: 'loadCount',
        id: 'loadCount',
        label: 'Loads Count',
        width: 50,
      },
      {
        field: 'shippedQuantity',
        id: 'shippedQuantity',
        label: 'Shipped Quantity',
        width: 50,
      },
      {
        field: 'orderedQuantity',
        id: 'orderedQuantity',
        label: 'Ordered Quantity',
        width: 50,
      },
    ],
    order: [
      {
        idx: 0,
        field: 'id',
        id: 'orderId',
        label: 'Order ID',
        width: 50,
        display: true,
      },
      {
        idx: 1,
        field: 'customerParty.id,customerParty.name',
        label: 'Customer',
        id: 'customerParty',
        width: 50,
        display: true,
      },
      {
        idx: 2,
        field: 'destination.description',
        id: 'address',
        label: 'Address',
        width: 50,
        display: true,
      },
      {
        idx: 3,
        field: 'primaryProduct.item.id,primaryProduct.item.name',
        id: 'product',
        label: 'Primary Product',
        width: 50,
        display: true,
      },
      {
        idx: 4,
        field: 'supplierStatus.name',
        id: 'supplierStatus',
        label: 'Status',
        background: 'supplierStatus.trackingColor',

        width: 50,
      },
      {
        idx: 5,
        field: 'primaryKey',
        id: 'deliverySchedule.startDateTime',
        label: 'Scheduled Start Time',
        type: 'date',
        format: 'HH:mm A',
        width: 50,
        display: true,
      },
      {
        idx: 6,
        field: 'deliverySchedule.scheduleTimes.spacing',
        id: 'vehicleSpacing',
        label: 'Vehicle Spacing',
        width: 50,
        display: true,
      },
      {
        idx: 7,
        field: 'ticketCount',
        id: 'ticketCount',
        label: 'Ticketed Loads',
        defaultValue: 0,
        width: 50,
        display: true,
      },
      {
        idx: 8,
        field: 'loadCount',
        id: 'loadCount',
        label: 'Load Count',
        width: 50,
        display: true,
      },
      {
        idx: 9,
        field: 'shippedQuantity',
        id: 'shippedQuantity',
        label: 'Shipped Quantity',
        width: 50,
        display: true,
      },
      {
        idx: 10,
        field: 'orderedQuantity',
        id: 'orderedQuantity',
        label: 'Ordered Quantity',
        width: 50,
        display: true,
      },
      /*{ Commented out at request of Gary Bisel. Columns currently have incorrect data.
        field: 'ticketCalculations.earliestPrintedTime',
        display: false,
        format: 'HH:mm A',
        width: 50,
        id: 'firstLoadTime',
        label: 'First Load Printed',
        type: 'date',
        idx: 11,
      },
      {
        field: 'ticketCalculations.latestPrintedTime',
        display: false,
        format: 'HH:mm A',
        width: 50,
        id: 'lastLoadTime',
        label: 'Last Load Printed',
        type: 'date',
        idx: 12
      },*/
      {
        field: 'sk.orderedQuantity',
        display: true,
        id: 'totalOrderedQuantity',
        label: 'Total Ordered Quantity',
        width: 50,
        idx: 11,
      },
      {
        field: 'sk.shippedQuantity',
        display: true,
        id: 'totalShippedQuantity',
        label: 'Total Shipped Quantity',
        width: 50,
        idx: 12,
      },
      {
        field: 'nextLoadScheduledTime',
        display: true,
        format: 'HH:mm A',
        id: 'nextLoadScheduledTime',
        type: 'date',
        label: 'Next Load Time',
        width: 50,
        idx: 13,
      },
    ],
    vehicle: [
      {
        field: 'vehicleId',
        label: null,
      },
      {
        type: 'timer',
        field: 'vehicleTimer',
        label: null,
      },
      {
        field: 'vehicleRelevantLocation', // This will change values based on the vehicle's status
        label: null,
      },
    ],
  },
  trackingStatuses: [
    {
      statusCode: '',
    },
  ],
  orderLeadTime: 12,
};
