import {css} from 'styled-components';

export default css`
  width: 100%;
  height: 30px;
  display: flex;
  flex-direction: row;
  background: #333333;
  .right-area {
    display: flex;
    flex: 1;
  }
`;