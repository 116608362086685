import React, {useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';
import style from './style';
import cn from 'classnames';
import api from '../../../api';
import {useDashboardContext} from "../../context/useDashboardContext";
import ticketForm from './ticket-form';
import DynamicForm from '../dynamic-form';
import moment from 'moment';
import {Button} from "antd";
import useSetup from "../../../../setup/useSetup";
import TicketDetails from '../../../../order/order-list/components/order-cards/ticket-details';

const SingleTicketDetails = (props) => {
  const {entityRef} = useDashboardContext();
  const { getSetupItems, setupItems } = useSetup('cancel-reason-code');

  useEffect(() => {
    getSetupItems().then();
  }, [entityRef]);

  return <TicketDetails {...props} setupItems={setupItems} />
}

export default styled(SingleTicketDetails)`
  ${style}
`;