import cn from 'classnames';
import { DateTime } from 'luxon';
import React, {useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';
import { BarGraph } from '../../../../../components/bar-graph';
import useCalculateLoads from '../../../../../components/bar-graph/use-calculate-loads';
import { usePlanning } from '../../../../../components/bar-graph/usePlanning';
import Spinner from '../../../../../components/spinner/Spinner';
import { cacheItem, getCachedItem, clearCachedItem } from '../../../../../util/cache';
import Buttons from './Buttons';
import ChangeInfo from './change-info/ChangeInfo';
import { timer } from '../../../../../util/analytics';
import ModelingContextProvider, { useModelingContext } from './ModelingContext';

const Styled = styled.div`
  .controls {
    display: grid;
    grid-gap: 10px;
    justify-content: center;
    grid-template-rows: auto;
    grid-template-areas:
      'table'
      'buttons';
    .buttons {
      grid-area: buttons;
    }
    .change-info {
      grid-area: table;
    }
  }
`;

const ModelingGraph = () => {
  const modelingContext = useModelingContext();

  return (
    <Styled className={cn('modeling-graph')}>
      <BarGraph {...modelingContext} />
      <div className="controls">
        <ChangeInfo />
        <Buttons />
      </div>
    </Styled>
  );
};

export default ({ targetOrderRef, targetStartDateTime, targetLocation, onCancel }) => {
  const { getDemand, demand, loading } = usePlanning();
  const [onJobRange, setOnJobRange] = React.useState(null);
  const [startTime, setStartTime] = useState();

  const startDate = useMemo(() => {
    return DateTime.fromISO(targetStartDateTime).toFormat('yyyy-MM-dd');
  }, [targetStartDateTime]);
  const [modeledLoads, setModeledLoads] = useState([]);
  const calculatedLoads = useCalculateLoads(demand, startTime, targetOrderRef, setOnJobRange);

  const loads = useMemo(() => {
    if(modeledLoads?.max > 0) {
      return modeledLoads;
    }
    return calculatedLoads?.reduce?.(
      (acc, load) => {
        acc.loads[load.offset / 5] = load;
        acc.max = Math.max(acc.max, load.trucks);
        return acc;
      },
      { max: 0, loads: {} }
    );
  }, [calculatedLoads, modeledLoads])


  useEffect(() => {
    startDate && targetLocation?.locationRef && getDemand(startDate, [targetLocation?.locationRef]);
  }, [startDate, getDemand, targetLocation]);

  useEffect(() => {
    if (demand) {
      const time = DateTime.fromFormat(startDate, 'yyyy-MM-dd')
        .toUTC()
        .startOf('day')
        .setZone(demand.timeZone, { keepLocalTime: true });

      setStartTime(time);
    }
  }, [demand, startDate]);

  const getInitialValue = () => {
    const value = getCachedItem('maxTrucksOverride');
    if (value) {
      return JSON.parse(value);
    }
    return 0;
  };

  const [maxTrucksOverride, setMaxTrucksOverride] = React.useState(getInitialValue());

  const handleResetMaxTrucksOverride = React.useCallback(() => {
    setMaxTrucksOverride('');
    clearCachedItem('maxTrucksOverride');
  }, []);

  const handleMaxTrucksOverride = React.useCallback(value => {
    if (value) {
      cacheItem('maxTrucksOverride', value);
      setMaxTrucksOverride(value);
    }
  }, []);

  React.useEffect(() => {
    return timer('core-order-detail-modeling-session');
  }, []);

  return (
    <Spinner spin={!loads?.loads}>
      <ModelingContextProvider
        targetOrderRef={targetOrderRef}
        targetStartDateTime={targetStartDateTime}
        targetLocation={targetLocation}
        onCancel={onCancel}
        demand={demand}
        loading={loading}
        startDate={startDate}
        loads={loads}
        onJobRange={onJobRange}
        setOnJobRange={setOnJobRange}
        startTime={startTime}
        setLoads={setModeledLoads}
        maxTrucksOverride={maxTrucksOverride}
        handleMaxTrucksOverride={handleMaxTrucksOverride}
        handleResetMaxTrucksOverride={handleResetMaxTrucksOverride}
      >
        <ModelingGraph />
      </ModelingContextProvider>
    </Spinner>
  );
};
