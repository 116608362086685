import {css} from 'styled-components';

export default css`
  width: 100%;

  .manage-fees-container {
    margin: 10px;

    .fee-wrapper {
      display: flex;
      margin-bottom: 5px;

      div {
        margin-right: 10px;
      }
    }
  }

  .create-fee-form {
    margin: 10px;
  }

  .form-action-button {
    margin-right: 10px;
  }

  .no-margin {
    margin: 0;
  }
`;
