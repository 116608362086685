
const json = {
  global: {
    tabSetTabLocation: 'bottom'
  },
  borders: [
    {
      type: 'border',
      location: 'bottom',
    },
    {
      type: 'border',
      location: 'left',
    },
    {
      type: 'border',
      location: 'right',
    },
  ],
  layout: {
    "type": "row",
    "weight": 100,
    "children": [
      {
        "type": "row",
        "weight": 100,
        "children": [
          {
            "type": "tabset",
            "weight": 50,
            "selected": 0,
            "children": [
              {
                "type": "tab",
                "name": "Tracking Window",
                "component": "tracking-view",
              }
            ]
          },
        ],
      },
    ],
  }
};
export default json;