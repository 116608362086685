import React from 'react';
import BaseMarker from '../../../../components/sdk-map/markers/BaseMarker';
import markerImg from './map-icons/blue-marker.png';

const LocationMarker = ({ point, address, onChange }) => {
  const href = `https://www.google.com/maps/place/${point?.lat},${point?.lng}`;

  const handleDragEnd = React.useCallback(
    e => {
      onChange({ ...point, lat: e.latLng.lat(), lng: e.latLng.lng(), custom: true });
    },
    [onChange, point]
  );

  return (
    <BaseMarker icon={markerImg} key={point?.id} position={point} draggable={!!onChange} onDragEnd={handleDragEnd}>
      <>
        <div style={{ color: 'black' }}>
          {address && <div>Address</div>}
          <div>
            <a target={'_blank'} href={href}>
              Coordinates: {point?.lat},{point?.lng}
            </a>
          </div>
        </div>
      </>
    </BaseMarker>
  );
};

export default LocationMarker;
