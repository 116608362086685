import React from 'react';
import styled from 'styled-components';
import InputText from '../../../components/input-text';
import style from '../style';

const View = styled.div`
  ${style}
`;

const Weights = ({ vehicle, onChange }) => {
  return (
    <View>
      <InputText
        id="maxGrossVehicleWeight"
        label="Max GVW"
        value={vehicle?.maxGrossVehicleWeight}
        onChange={onChange}
      />
      <InputText
        id="targetGrossVehicleWeight"
        label="Target Gross"
        value={vehicle?.targetGrossVehicleWeight}
        onChange={onChange}
      />
      <InputText
        id="targetNetVehicleWeight"
        label="Target Net"
        value={vehicle?.targetNetVehicleWeight}
        onChange={onChange}
      />
    </View>
  );
};

export default Weights;
