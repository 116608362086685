export const getSelectedItemValue = (options, valuePath) => {
  if (options?.length) {
    const antDropDowns = document.querySelectorAll('div.ant-select-dropdown');

    if (antDropDowns?.length) {
      for (const antDropDown of antDropDowns) {
        if (antDropDown.className.indexOf('ant-select-dropdown-hidden') >= 0) {
          continue;
        }
        const activeOptions = antDropDown.querySelectorAll('.ant-select-item-option-active');

        if (activeOptions.length) {
          for (let propName in activeOptions[0]) {
            if (propName.startsWith('__reactFiber$')) {
              const key = activeOptions[0][propName].key;
              const delimiterIdx = key.indexOf('__');
              const value = delimiterIdx ? key.substring(0, delimiterIdx) : key;

              for (const option of options) {
                if (option[valuePath] === value) {
                  const result = option[valuePath];
                  return result;
                }
              }
            }
          }
        }
      }
    }
  }

  return null;
};

export default getSelectedItemValue;
