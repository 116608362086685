import React, { createContext, useCallback, useContext, useState } from 'react';
import { useTranslateMessage } from '../../../../order/components/localization/useTranslateMessage';
import { useDashboardContext } from '../../context/useDashboardContext';
import TicketDetails from '../../components/single-ticket-details';
import { get } from 'lodash';
import Iframe from '../../components/iframe';
import { useParams } from 'react-router';
import VehicleDetails from '../../components/vehicle-details';
import TicketEntry from '../../../../order/components/tickets/TicketEntry';
import API from '../../../api';
import { Button } from 'antd';
import { useModalContext } from '../../../../../components/modal-context/ModalContext';
import { Translate } from '../../../../../components/localization/Translate';

const MenuContext = createContext();

export const useContextMenuContext = () => {
  const value = useContext(MenuContext);
  if (!value) {
    throw new Error('Dashboard context must be used in ContextProvider');
  }

  return value || {};
};

const _menus = translateMessage => ({
  order: [
    {
      id: 'open_modal',
      type: 'iframe',
      getSrc: (entityRef, item, productType = 'CONCRETE') => {
        return `${window.location.origin}/${entityRef}/cx/edit/${productType}/${item.crn}/general?embedded=true&hideButtons=true`;
      },
      visible: (item, licenses) => {
        return licenses?.orders;
      },
      title: translateMessage({ stringId: 'orderDetails', defaultMessage: 'Order Details' }),
    },
  ],
  orderRequest: [
    {
      id: 'open_modal',
      type: 'order-request',
      params: {
        orderRef: 'crn',
      },
      getSrc: (entityRef, item) => {
        return `${window.location.origin}/${entityRef}/cx/order-request/concrete/${item?.crn}?embedded=true&hideRightSideButtons=true`;
      },
      // visible: item => {
      //   return item?.status !== orderRequestStatuses.accepted || item?.status === orderRequestStatuses.pending;
      // },
      title: translateMessage({ stringId: 'reviewOrderRequest', defaultMessage: 'Review Order Request' }),
    },
  ],
  load: [
    {
      id: 'open_drawer',
      type: 'ticket-order',
      params: {
        orderRef: 'crn',
      },
      title: translateMessage({ stringId: 'ticketOrder', defaultMessage: 'Ticket Order' }),
    },
  ],
  vehicle: [
    {
      id: 'open_drawer',
      type: 'ticket-details',
      params: {
        ticketRef: 'latestTicket.crn,ticket.ticketRef',
        orderRef: 'ticket.dispatchOrderRef',
      },
      visible: (vehicle, licenses) => {
        return vehicle?.latestTicket?.crn || vehicle?.ticket?.ticketRef;
      },
      title: translateMessage({ stringId: 'ticketDetails', defaultMessage: 'Ticket Details' }),
    },
    {
      id: 'open_drawer',
      type: 'vehicle-details',
      params: {
        vehicleRef: 'crn',
      },
      title: translateMessage({ stringId: 'vehicleDetails', defaultMessage: 'Vehicle Details' }),
    },
  ],
  alert: [
    {
      id: 'open_drawer',
      type: 'ticket-details',
      params: {
        ticketRef: 'ticket.ticketRef',
      },
      visible: (alert, _licenses) => {
        return alert?.ticket?.ticketRef;
      },
      title: translateMessage({ stringId: 'ticketDetails', defaultMessage: 'Ticket Details' }),
    },
    {
      id: 'dismiss_alert',
      type: 'dismiss-alert',
      params: {
        alertRef: 'crn',
      },
      title: translateMessage({ stringId: 'dismissAlert', defaultMessage: 'Dismiss Alert' }),
    },
  ],
});

export const ContextMenuContextProvider = ({ children }) => {
  const { entityRef } = useParams();
  const { licenses, refreshDashboard, retrieveAlerts } = useDashboardContext();
  const [item, setItem] = useState();
  const [menu, setMenu] = useState();
  const [location, setLocation] = useState({});
  const translateMessage = useTranslateMessage();

  const { openCustomSizeModal, closeModal } = useModalContext();

  const menus = React.useMemo(() => {
    return _menus(translateMessage);
  }, [translateMessage]);

  const {
    openDashboardWindow,
    drawer: { setContent, toggleDrawer, setOpen },
  } = useDashboardContext();

  const showMenu = useCallback(
    (item, type, x, y) => {
      setItem(item);
      const filteredMenu = [];
      if (!menus[type]) {
        return;
      }
      for (const menuItem of menus[type]) {
        let shouldShow = true;
        if (menuItem.visible) {
          shouldShow = menuItem.visible(item, licenses);
        }
        if (shouldShow) {
          filteredMenu.push(menuItem);
        }
      }
      setMenu(filteredMenu.length > 0 ? filteredMenu : null);
      setLocation({ x, y });
    },
    [menus, licenses]
  );

  const onAction = useCallback(
    action => {
      let parameters = null;
      if (action.params) {
        parameters = {};
        Object.keys(action.params).forEach(key => {
          const splitKeys = action.params[key].split(',');
          let value = null;
          for (const keyValue of splitKeys) {
            value = get(item, keyValue);
            if (value) {
              break;
            }
          }
          parameters[key] = value;
        });
      }

      if (action.id === 'open_drawer') {
        let windowContent = null;

        if (action.type === 'ticket-details') {
          windowContent = (
            <TicketDetails
              parameters={parameters}
              onCancelVoid={() => {
                toggleDrawer();
              }}
            />
          );
        } else if (action.type === 'iframe') {
          const src = action.getSrc(entityRef, item);
          windowContent = <Iframe src={src} />;
        } else if (action.type === 'ticket-order') {
          windowContent = (
            <TicketEntry
              open={true}
              orderRef={item.crn}
              onTicketed={() => {
                toggleDrawer();
                refreshDashboard();
              }}
            />
          );
        } else if (action.type === 'vehicle-details') {
          windowContent = (
            <VehicleDetails
              open={true}
              vehicleRef={item.crn}
              onSave={() => {
                toggleDrawer();
                refreshDashboard();
              }}
            />
          );
        }
        if (windowContent) {
          setContent(windowContent);
          setOpen();
        }
      } else if (action.id === 'open_modal') {
        const src = action.getSrc(entityRef, item);
        openCustomSizeModal({
          component: <Iframe src={src} />,
          width: 1500,
          bodyStyle: { height: '800px', display: 'flex' },
          footer: (
            <Button type="primary" onClick={() => closeModal()}>
              <Translate defaultMessage="Close" stringId="close" />
            </Button>
          ),
        });
      } else if (action.id === 'open_window') {
        openDashboardWindow(action.type, action.name);
      } else if (action.id === 'dismiss_alert') {
        if (parameters?.alertRef) {
          API.dismissVehicleAlert(entityRef, parameters?.alertRef).then(() => {
            return retrieveAlerts();
          });
        }
      }
    },
    [
      setContent,
      setOpen,
      openDashboardWindow,
      item,
      entityRef,
      refreshDashboard,
      closeModal,
      openCustomSizeModal,
      retrieveAlerts,
      toggleDrawer,
    ]
  );

  return (
    <MenuContext.Provider
      value={{
        setItem,
        showMenu,
        setMenu,
        menu,
        location,
        onAction,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
};

export default {
  useContextMenuContext,
  ContextMenuContextProvider,
};
