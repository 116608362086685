import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useTranslateMessage } from '../../../../order/components/localization/useTranslateMessage';
import style from './style';
import cn from 'classnames';
import VehicleList from '../../components/vehicle-list';
import { useDrop } from 'react-dnd';
import API from '../../../api';
import { useParams } from 'react-router';
import { useDashboardContext } from '../../context/useDashboardContext';
import { DateTime } from 'luxon';

const VehiclesInYard = ({ className, view = {}, setTitle }) => {
  const { refreshDashboard } = useDashboardContext();
  const { entityRef } = useParams();
  const vehicleCount = useMemo(() => {
    return view?.vehicles?.length || 0;
  }, [view]);

  const [collectedProps, drop] = useDrop((...args) => {
    return {
      accept: 'vehicle',
      collect: monitor => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
      drop: item => {
        API.statusVehicle(entityRef, item.crn, 'IN_YARD').then(refreshDashboard);
      },
    };
  });

  const translateMessage = useTranslateMessage();

  useEffect(() => {
    setTitle(
      translateMessage({
        stringId: 'vehicleCountInYard',
        defaultMessage: '({vehicleCount}) In Yard',
        values: { vehicleCount },
      })
    );
  }, [setTitle, translateMessage, vehicleCount]);

  return (
    <div ref={drop} className={cn(className)}>
      <VehicleList sortByTime sortByLocation showTimer={true} vehicles={view?.vehicles} />
    </div>
  );
};

export default styled(VehiclesInYard)`
  ${style}
`;
