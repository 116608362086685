const generateCleanedUpFormData = (form, auditNotes) => {
  return {
    ...form,
    linkedOrders: form?.linkedOrders?.map(lo => {
      return {
        ...lo,
        otherLineItems: lo.otherLineItems.filter(lineItem => !!lineItem?.item?.productRef),
      };
    }),
    auditNotes,
    exdendedDatesList: undefined,
  };
};

export default generateCleanedUpFormData;
