import { Polyline } from '@react-google-maps/api';
import React from 'react';

const Route = ({ disabled, allowRouting, canUpdate, route, calculatingRoute, updatingDestination, addWaypoint }) => {
  const handleClick = React.useCallback(
    e => {
      if (canUpdate && !disabled && allowRouting) {
        const coords = JSON.stringify(e.latLng.toJSON(), null, 2);
        const newCords = JSON.parse(coords);
        const lat = newCords.lat;
        const lng = newCords.lng;
        const coordinates = {
          lat,
          lng,
        };
        addWaypoint(coordinates);
      }
    },
    [addWaypoint, allowRouting, canUpdate, disabled]
  );

  if (!route?.length) return null;

  return (
    <Polyline
      onClick={handleClick}
      path={route}
      geodesic
      options={{
        strokeColor: calculatingRoute || updatingDestination ? '#EFD31D' : '#1d47ef',
        strokeOpacity: calculatingRoute || updatingDestination ? 0.45 : 0.75,
        strokeWeight: 8,
      }}
    />
  );
};

export default Route;
