import { find, upperFirst } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { DateTime } from 'luxon';
import Centered from '../../components/Centered';
import Margin from '../../components/Margin';
import { useScaleTicketingContext } from '../../Context';

const Styled = styled.div`
  margin-top: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  line-height: 1em;

  .field-label {
    font-size: 0.8rem;
  }

  .value {
    background-color: transparent;
    font-weight: bold;
    font-size: 1.3rem;
    margin-top: 10px;
    margin-bottom: 5px;
  }

  .tare-when {
    line-height: 1.2rem;
    opacity: 0.7;
    font-size: 0.8em;
  }
`;

const LastTare = () => {
  const { form } = useScaleTicketingContext();

  const tare = React.useMemo(() => {
    const value = form?.vehicle?.configuration?.tare?.weight?.value;

    return value || '-----';
  }, [form?.vehicle?.configuration?.tare?.weight?.value]);

  const relativeDate = React.useMemo(() => {
    return DateTime.fromISO(form?.vehicle?.configuration?.tare?.acquiredDateTime).toRelativeCalendar();
  }, [form?.vehicle?.configuration?.tare?.acquiredDateTime]);

  return (
    <Styled>
      <div className="field-label">Last Tare</div>
      <div className="value">{tare}</div>
      <div className="tare-when">{relativeDate ? `${upperFirst(relativeDate)}` : ''}</div>
    </Styled>
  );
};

export default LastTare;
