import { AutoComplete } from 'antd';
import { cloneDeep, omit } from 'lodash';
import React from 'react';
import { searchForNearbyAddresses } from '../../views/order/components/order-map/hooks/useGeoStuff';
import styled from 'styled-components';
import style from './Style';

const Styled = styled.div`
  ${style}
`;

const { Option } = AutoComplete;

const cleanedUpAddresses = addresses => {
  return addresses.map(address => {
    const newAddress = omit(address, 'address1');

    const address1 = address.address1?.replace?.(/undefined/g, '')?.trim?.();

    if (address1) newAddress.address1 = address1;
    return newAddress;
  });
};

const AddressAutoComplete = ({
  id,
  lat,
  lng,
  fieldLabel,
  disabled,
  value,
  formAddress,
  onChange,
  addonAfter,
  readOnly,
  style,
  required,
}) => {
  const [_value, setValue] = React.useState(value);
  const [addresses, setAddresses] = React.useState([]);

  React.useEffect(() => {
    setValue(value);
  }, [value]);

  const handleSearch = React.useCallback(
    async searchText => {
      if (searchText?.length > 5) {
        const addresses = await searchForNearbyAddresses({ lat, lng, searchText });
        setAddresses(cleanedUpAddresses(addresses));
      }
    },
    [lat, lng]
  );

  const handleChange = React.useCallback(
    value => {
      if (formAddress) {
        const retValue = cloneDeep(formAddress);
        retValue.address1 = value || '';
        setValue(value);
        onChange('address', retValue);
      } else {
        onChange('address', { [id]: value });
      }
    },
    [formAddress, onChange, id]
  );

  const handleSelect = React.useCallback(
    valueString => {
      const value = JSON.parse(valueString);
      setValue(value.address1 || '');
      value.postalCode = value.postalCode?.substr?.(0, 5);
      onChange('address', value);
    },
    [onChange]
  );

  return (
    <Styled style={style}>
      <div className="label-spinner">
        <div className="label">{fieldLabel}</div>
        {required && <span className="required-indicator">*</span>}
      </div>
      <div className="field-with-addon">
        <AutoComplete
          disabled={disabled}
          onSearch={handleSearch}
          onSelect={handleSelect}
          onChange={handleChange}
          value={_value}
        >
          {addresses.map(address => (
            <Option
              key={`${address.address1}__${address?.coordinates?.latitude}__${address?.coordinates?.longitude}`}
              value={JSON.stringify(address)}
            >
              {[]
                .concat(address.address1, address.city, address.countrySubDivision, address.postalCode)
                .filter(a => !!a)
                .join(', ')}
            </Option>
          ))}
        </AutoComplete>
        <div className="addon">{addonAfter}</div>
      </div>
    </Styled>
  );
};

export default AddressAutoComplete;
