import React from 'react';
import styled from 'styled-components';
import {Tooltip} from 'antd';
import {get} from 'lodash';

const Label = ({ className, item, fieldValue, field }) => {
  const {icon, tooltip} = field;

  const tooltipValue = (tooltip && item) ? get(item, tooltip) : null;

  if(tooltipValue) {
    return <div className={className} style={field?.style}>
      <Tooltip title={tooltipValue}>
        {icon && <span className={icon} />} {fieldValue}
      </Tooltip>
    </div>;
  }

  return <div className={className} style={field?.style}>
    {icon && <span className={icon} />} {fieldValue}
  </div>;
};

export default styled(Label)`
  color: white;
`;
