import { isEmpty, isNil } from 'lodash';
import React from 'react';
import { useIntl } from 'react-intl';
import { Translate } from './Translate';

export const Uom = ({ uom, abbr = true, testId }) => {
  if (isEmpty(uom)) return '';

  if (isNil(uom?.value) && !isNil(uom?.uomCode)) {
    return (
      <Translate
        stringId={`UOM_${abbr ? 'ABBR' : 'TEXT'}__${uom?.uomCode}`}
        values={{ value: Number(uom?.value) }}
        data-testid={testId}
      />
    );
  }

  if (isNil(uom?.uomCode)) return '';

  return (
    <Translate
      stringId={`UOM_VALUE_${abbr ? 'ABBR' : 'TEXT'}__${uom?.uomCode || 'UNKNOWN'}`}
      values={{ value: Number(uom?.value) }}
      data-testid={testId}
    />
  );
};

export const useUom = () => {
  const { formatMessage } = useIntl();

  const getUom = React.useCallback(
    quantityObject => {
      return formatMessage({ id: quantityObject.uomCode }, { value: quantityObject.value });
    },
    [formatMessage]
  );

  return { getUom, Uom };
};
