import React from 'react';
import cn from 'classnames';
import style from './style';
import styled from 'styled-components';
import InputText from '../input-text';
import SelectCountry from '../select-country';

const Styled = styled.div`
  ${style}
`;

const InputAddress = ({ className, id: addressId, item, onChange }) => {
  const addressChanged = (id, value) => {
    const address = {
      ...(item[addressId] ?? {}),
      [id]: value,
    };

    onChange(addressId, address);
  };

  const a = item?.[addressId] || {};

  return (
    <Styled className={cn(addressId, className)}>
      <InputText id="address1" label="Address 1" value={a.address1 || ''} onChange={addressChanged} />
      <InputText id="address2" label="Address 2" value={a.address2 || ''} onChange={addressChanged} />
      <InputText id="city" label="City" value={a.city || ''} onChange={addressChanged} />
      <table>
        <tbody>
          <tr>
            <td>
              <InputText
                id="countrySubDivision"
                label="State / Province / Region"
                value={a.countrySubDivision || ''}
                onChange={addressChanged}
              />
            </td>
            <td>
              <InputText id="postalCode" label="Postal Code" value={a.postalCode || ''} onChange={addressChanged} />
            </td>
          </tr>
        </tbody>
      </table>
      <SelectCountry id="countryCode" label="Country" value={a.countryCode || ''} onChange={addressChanged} />
    </Styled>
  );
};

export default InputAddress;
