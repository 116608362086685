import styled from 'styled-components';

export default styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto;
  gap: 20px;
  grid-template-areas:
    'main'
    'notes'
    'bottom';
  margin-top: 10px;
`;
