import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
import FieldContainer from '../../../components/field-container/FieldContainer';
import Input from './Input';
import MaskedInput from 'antd-mask-input';
import { get, kebabCase } from 'lodash';

import { useOrderContext } from './useOrderContext';

const Styled = styled(FieldContainer)`
  &.hasAddonAfter {
    input.ant-input {
      border-right-width: 0;
    }
  }

  .label-spinner {
    display: flex;
    .label {
      flex: 1;
    }
  }
  &.invalid {
    input {
      border: 1px solid red;
    }
  }
  &.disabled {
    .label-spinner {
      .label {
        color: #757575;
      }
    }
  }

  .spinner-row{
    display: flex;
    flex-direction: row;
  }

  .label-spinner .label { 
    display: flex;
    flex-direction: row;
    gap: 5px
  }
`;

const InputField = ({
  className,
  onChange,
  value,
  fieldName,
  fieldLabel,
  disabled,
  required,
  mask,
  addonAfter,
  readOnly,
  placeholder,
  busy,
  style,
}) => {
  const {
    form: { validatedFields },
    highlightInvalidFields,
  } = useOrderContext();

  const handleChange = React.useCallback(
    e => {
      onChange(fieldName, e.target.value);
    },
    [fieldName, onChange]
  );

  const props = React.useMemo(
    () => ({ value: value || '', onChange: handleChange, disabled, mask, addonAfter, placeholder }),
    [addonAfter, disabled, handleChange, mask, placeholder, value]
  );

  const editableComponent = React.useMemo(
    () => (mask ? <MaskedInput {...props} /> : <Input {...props} />),
    [mask, props]
  );

  const readOnlyComponent = React.useMemo(
    () => <Input readOnly disabled addonAfter={addonAfter} value={value} />,
    [addonAfter, value]
  );

  return (
    <Styled
      style={style}
      className={cn(fieldName.replace(/\./gi, '_'), 'field-container', className, {
        disabled,
        invalid: get(validatedFields, fieldName) === false && highlightInvalidFields,
        hasAddonAfter: !!addonAfter,
      })}
      data-testid={`input-${kebabCase(fieldLabel?.toLowerCase?.())}`}
    >
      <div className="label-spinner">
        <div className="label">
          <div className="spinner-row">
            {fieldLabel}
            <div className={cn('spinner', { spinning: busy })}>
              <span className="fas fa-sync fa-spin" />
            </div>
          </div>
          <div>{required && <span className="required-indicator">*</span>}</div>
        </div>
      </div>

      {readOnly ? readOnlyComponent : editableComponent}
    </Styled>
  );
};

export default InputField;
