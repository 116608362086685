import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
import FieldContainer from '../../field-container/FieldContainer';
import Input from '../../form/Input';

const EmailCopies = ({ className, value }) => {
  return (
    <FieldContainer className={cn('email-copies', className)}>
      <div className="label-spinner">
        <div className="label">Email Copies To</div>
        <div className={cn('spinner', { spinning: false })}>
          <span className="fas fa-sync fa-spin" />
        </div>
      </div>

      <Input mode="multiple" value={value.map(c => c.name).join(', ')} disabled />
    </FieldContainer>
  );
};

export default styled(EmailCopies)``;
