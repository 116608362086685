import {css} from 'styled-components';

export default css`
width: 100%;
margin-bottom: 10px;

table, tbody, tr {
  width: 100%;
}

td {
  padding: 5px;
  width: 20%;
}

.ant-btn {
  height: 30px;
  width: 100%;
  
  .label {
    font-size: 0.9em;
  }
  
  .count {
    font-size: 1.4em;
    font-weight: bold;
  }
}

i {
  margin-left: 10px;
  
  &:hover {
    cursor: pointer;
    color: #3c9ae8;
  }
}

&&& {    
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
`;
