import { omit } from 'lodash';
import { DateTime } from 'luxon';

const cleanupLineItem = lineItem => ({
  item: omit(lineItem?.item, 'locations'),
  orderedQuantity: lineItem?.orderedQuantity,
  properties: lineItem?.properties,
});

export const transformToPostData = (entityRef, form) => {
  const orderType = form?.linkedOrders?.[0]?.primaryLineItem?.item?.type;
  const linkedOrders = (form?.linkedOrders || []).map(order => {
    const primaryLineItem = cleanupLineItem(order?.primaryLineItem);
    const otherLineItems = order.otherLineItems;

    order.lineItems = [primaryLineItem, ...otherLineItems];
    const startDateTime = DateTime.fromISO(order?.startDateTime || form?.startDateTime)
      .toUTC()
      .toISO();
    return {
      ...order,
      location: {
        locationRef: order?.locationRef || form?.locationRef,
        dispatchLocationRef: form?.dispatchLocationRef || order?.dispatchLocationRef,
      },
      supplierStatus: { supplierStatusRef: order?.supplierStatusRef || form?.supplierStatusRef },
      deliverySchedule: {
        startDateTime: startDateTime,
        finishDateTime: startDateTime,
      },
      primaryLineItem: undefined,
      otherLineItems: undefined,
      minimumLoadChargeLoadsData: undefined,
    };
  });

  const result = {
    version: '3.0',
    crn: form?.crn,
    id: form?.orderId,
    setOrderId: form?.setOrderId,
    contacts: form?.contact
      ? [
          { type: 'general', phoneNumber: form?.phoneNumber },
          {
            type: 'general',
            contactRef: form?.contact?.id,
            firstName: form?.contact?.firstName,
            lastName: form?.contact?.lastName,
            phoneNumber: form?.contact?.phone,
            email: form?.contact?.email,
          },
        ]
      : [{ type: 'general', phoneNumber: form?.phoneNumber }],
    customerParty: {
      entityRef: form?.customerRef,
      ...(form?.overriddenCustomer && { overriddenCustomer: form?.overriddenCustomer }),
    },
    distance: form?.distance,
    deliverySchedule: {
      startDateTime: DateTime.fromISO(form?.startDateTime).toUTC().toISO(),
      finishDateTime: DateTime.fromISO(form?.startDateTime).toUTC().toISO(),
    },
    destination: {
      address: form?.address,
    },
    excludeZoneCharges: form?.excludeZoneCharges,
    // lineItems: [primaryLineItem, ...otherLineItems],
    // otherLineItems,
    linkedOrders,
    location: { locationRef: form?.locationRef, dispatchLocationRef: form?.dispatchLocationRef },
    notes: [{ message: form?.projectNote }].filter(n => n.message),
    auditNotes: form?.auditNotes,
    driverInstructions: form.driverInstructions,
    billingNote: form.billingNote,
    paymentMethod: { paymentMethodRef: form?.paymentMethodRef },
    project: { projectRef: form?.projectRef },
    purchaseOrder: form?.purchaseOrder,
    salesPerson: { profileRef: form?.salesPersonRef },
    shippingMethod: { shippingMethodRef: form?.shippingMethodRef },
    supplierParty: {
      entityRef,
      name: form?.supplier?.name,
      costBookRef: form?.supplier?.costBookRef,
      costBookOverride: form?.supplier?.costBookOverride,
      manualOverride: form?.supplier?.manualOverride,
      resolvedCostBookRef: form?.supplier?.resolvedCostBookRef,
      timeZone: form?.supplier?.timeZone,
    },
    supplierStatus: { supplierStatusRef: form?.supplierStatusRef },
    tracking: {
      backgroundColor: form?.dispatchBackgroundColor,
    },
    orderCopiedFromOrder: form?.orderCopiedFromOrder,
    orderType,
    taxRate: { taxRateRef: form?.taxRateRef },
    vehicleType: { vehicleTypeRef: form?.vehicleTypeRef },
    workType: { workTypeRef: form?.workTypeRef },
    taxExemptReasonRef: form?.taxExemptReasonRef,
  };

  return result;
};
