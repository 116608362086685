import style from './style';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import useSetup from '../../../useSetup';
import { Checkbox } from '../../../../../components/checkbox';

import Spinner from '../../../../../components/spinner/Spinner';
import LocationRow from './components/location-row';
import roundedToCents from '../../../../../util/other/rounded-to-cents';

const Styled = styled.div`
  ${style}
`;

const ProductLocations = ({ item: product, onChange, busy }) => {
  const { getSetupItems: getLocations, setupItems: locations, busy: LoadingLocations } = useSetup('location');

  const { productCosts, getProductCosts, busy: loadingCosts } = useSetup();

  useEffect(() => {
    getLocations();
  }, [getLocations]);

  useEffect(() => {
    if (product?.type !== 'CONCRETE') return;
    getProductCosts(product?.crn);
  }, [getProductCosts, product?.crn, product?.type]);

  const onPlant = (locationRef, checked) => {
    const locations = {
      ...(product.locations || {}),
      [locationRef]: checked,
    };

    onChange('locations', locationRef === 'all' ? { all: checked } : locations);
  };

  const onCost = (locationRef, value) => {
    const formatted = parseFloat(value);

    const costs = {
      ...(product.costs || {}),
      [locationRef]: isNaN(formatted) ? null : roundedToCents(formatted),
    };

    onChange('costs', costs);
  };

  if (busy || LoadingLocations || !product) return <Spinner spin={true} />;

  return (
    <Styled>
      <div>{product?.name} Location Availability</div>
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th style={{ width: '40%' }}>Plant</th>
              <th style={{ width: '10%' }}>Available</th>
              {product?.type === 'CONCRETE' && (
                <th style={{ width: '20%' }}>{loadingCosts ? <Spinner spin={true} /> : 'Calculated Component Cost'}</th>
              )}

              <th style={{ width: '20%' }}>Override Cost</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>All Locations</td>
              <td>
                <Checkbox
                  id={'all-plants'}
                  onChange={e => onPlant('all', e.target.checked)}
                  checked={product?.locations?.all === true}
                />
              </td>
              {product?.type === 'CONCRETE' && <td></td>}
              <td></td>
            </tr>
            {locations?.map(location => (
              <LocationRow
                location={location}
                product={product}
                productCosts={productCosts}
                onPlant={onPlant}
                onCost={onCost}
                key={location.crn}
              />
            ))}
          </tbody>
        </table>
      </div>
    </Styled>
  );
};

export default ProductLocations;
