import {css} from 'styled-components';

export default css`
  display: flex;
  height: 100%;
  color: white;
  margin: 5px;
  flex-direction: row;
  .ant-checkbox-wrapper {
    color: white;
  }
`
