import React, { useContext, useState, useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { find } from 'lodash';
import { useAppContext } from '../AppContext';
import { cacheItem, getCachedItem } from '../util/cache';
import { consolidateMessages } from './util/consolidateMessages';
import { messages as defaultGlobalMessages } from './messages-global';
import orderMessages from '../views/order/i18n';
// import { moduleDefinitions } from '../core/modules';
// import { appDefinitions } from '../core/apps';
// import { themeDefinitions } from '../themes';

export const SUPPORTED_LANGUAGES = [
  { id: 'en-US', label: 'languages.english-us' },
  { id: 'es', label: 'languages.spanish' },
  { id: 'en-AU', label: 'languages.english-au' },
];

export const IntlContext = React.createContext();

export const useIntlContext = () => {
  const context = useContext(IntlContext);
  if (!context) {
    throw new Error('useIntlContext cannot be called outside of the IntlContext context provider');
  }
  return context;
};

const appMessages = { order: orderMessages };

const cachedLanguageId = getCachedItem('cx.language');

const supportedCachedLanguageId = () => {
  const known =
    cachedLanguageId && find(SUPPORTED_LANGUAGES, l => l.id.toLowerCase() === cachedLanguageId.toLowerCase());

  return known ? known.id : null;
};

const browserLanguageId = (navigator.languages && navigator.languages[0]) || navigator.language || 'en-US';

const supportedBrowserLanguageId = () => {
  const known = find(SUPPORTED_LANGUAGES, l => l.id.toLowerCase() === browserLanguageId.toLowerCase()) || 'en-US';
  return known ? known.id : null;
};

export const Internationalization = ({ children, globalMessages = defaultGlobalMessages }) => {
  const { locale } = useAppContext();

  const [userLocale, setUserLocale] = useState(locale || supportedCachedLanguageId() || supportedBrowserLanguageId());

  const [messages, setMessages] = useState(
    consolidateMessages({ globalMessages, appMessages })[supportedBrowserLanguageId()]
  );

  useEffect(() => {
    if (userLocale) {
      cacheItem('cx.language', userLocale);
    }
  }, [userLocale]);

  useEffect(() => {
    setMessages(consolidateMessages({ globalMessages, appMessages })[userLocale]);
  }, [userLocale, globalMessages]);

  const handleIntlError = React.useCallback(
    error => {
      if (error?.code === 'MISSING_TRANSLATION') {
        if (!error?.descriptor?.defaultMessage || userLocale !== 'en-US') {
          console.warn(`Missing message: "${error?.descriptor?.id}"`);
        }
        return;
      }
      console.error(error.message);
    },
    [userLocale]
  );

  return (
    <IntlContext.Provider value={{ userLocale: userLocale || 'en-US', setUserLocale, messages }}>
      <IntlProvider locale={userLocale || 'en-US'} messages={messages} onError={handleIntlError}>
        {children}
      </IntlProvider>
    </IntlContext.Provider>
  );
};
