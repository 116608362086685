import { DateTime } from 'luxon';
import calculateLoads from '../../../../../../components/bar-graph/calculate-loads';

export default ({ targetOrderRef, diffLeft, diffDistance, demand, setLoads, startTime, setOnJobRange }) => {
  const targetLoads = demand?.deliveries?.filter?.(load => load.orderRef === targetOrderRef);

  const spacingDiff = diffDistance && targetLoads?.length > 1 ? diffDistance / (targetLoads.length - 1) : 0;

  const adjusted = demand?.deliveries?.map?.((load, idx) => {
    if (load.orderRef === targetOrderRef) {
      const cumulativeSpacingDiff = load.loadNumber > 1 ? (load.loadNumber - 1) * spacingDiff : 0;
      const startTimeDiff = diffLeft || 0;
      const diff = cumulativeSpacingDiff + startTimeDiff;

      return {
        ...load,
        ARRIVE_JOB: DateTime.fromISO(load.ARRIVE_JOB).plus({ minutes: diff }),
        END_UNLOADING: DateTime.fromISO(load.END_UNLOADING).plus({ minutes: diff }),
        IN_YARD: DateTime.fromISO(load.IN_YARD).plus({ minutes: diff }),
        LEAVE_JOB: DateTime.fromISO(load.LEAVE_JOB).plus({ minutes: diff }),
        LOADING_STARTED: DateTime.fromISO(load.LOADING_STARTED).plus({ minutes: diff }),
        PRINTED: DateTime.fromISO(load.PRINTED).plus({ minutes: diff }),
        TO_JOB: DateTime.fromISO(load.TO_JOB).plus({ minutes: diff }),
        UNLOADING: DateTime.fromISO(load.UNLOADING).plus({ minutes: diff }),
      };
    }
    return load;
  });

  if (adjusted) {

    const calculatedLoads = calculateLoads(
      { ...demand, deliveries: adjusted },
      startTime,
      targetOrderRef,
      setOnJobRange
    );

    const loads = calculatedLoads?.reduce?.(
      (acc, load) => {
        acc.loads[load.offset / 5] = load;
        acc.max = Math.max(acc.max, load.trucks);
        return acc;
      },
      { max: 0, loads: {} }
    );

    setLoads(loads);
  }
};
