import { css } from 'styled-components';

export default css`
  margin-left: 10px;
  .inputs {
    display: flex;
    gap: 10px;
  }

  .container {
    margin-top: 25px;
  }

  .table-container {
    height: 450px;
    overflow-y: auto;
    border: solid 1px #333;
  }

  .field-container {
    margin: 0;
  }

  .separator {
    height: 5px;
  }

  table {
    width: 100%;
  }

  thead th {
    background-color: #0e4980;
    padding: 5px;
    border: solid 1px #3a3a3a;
  }
`;
