import React, { useEffect } from 'react';
import styled from 'styled-components';
import FlexColumn from '../../order/components/FlexColumn';
import useSetup from '../useSetup';
import { useParams } from 'react-router';
import style from './style';
import SetupTable from '../components/setup-table';
import Editor from './editor';
import {FILTER_STRATEGY} from "../../../components/connex-data-grid/util";

const prototype = {
  id: '',
  name: '',
  status: '',
  icon: '',
};

const columns = [
  {
    key: 'id',
    name: 'ID',
  },
  {
    key: 'name',
    name: 'Name',
  },
  {
    key: 'icon',
    name: 'Icon',
  },
  {
    key: 'status',
    name: 'Status',
    filterStrategy: FILTER_STRATEGY.EQUALS,
    filterComponent: ({ innerRef, ...props }) => {
      return (
        <select ref={innerRef} {...props}>
          <option value="" />
          <option value="ACTIVE">Active</option>
          <option value="INACTIVE">Inactive</option>
        </select>
      );
    },
  },
];

const Styled = styled(FlexColumn)`
  ${style}
`;

const SetupTrackableTypes = () => {
  const { entityRef } = useParams();
  const { getTrackableTypes, trackableTypes, busy } = useSetup();
  const params = { activeOnly: false };

  useEffect(() => {
    entityRef && getTrackableTypes(params);
  }, [entityRef, getTrackableTypes]);

  return (
    <Styled>
      <SetupTable
        busy={busy}
        type="Trackable Type"
        columns={columns}
        items={trackableTypes}
        Editor={Editor}
        prototype={prototype}
        onRefresh={() => getTrackableTypes(params)}
      />
    </Styled>
  );
};

export default SetupTrackableTypes;
