import React from 'react';
import { Translate } from '../../../localization/Translate';
import { useOrderContext } from '../../../useOrderContext';
import Button from '../../../../../../components/button/Button';
import OrderLineProductEntry from './order-line-product-entry';
import { useOrderLinesContext } from './useOrderLinesContext';

const OrderLinesProductEntry = () => {
  const {
    form: { form },
    saveOrderBusy,
    start,
    order: { readOnly },
  } = useOrderContext();
  const { addOrderLine } = useOrderLinesContext();
  const disabled = React.useMemo(() => !form?.locationRef || saveOrderBusy, [form?.locationRef, saveOrderBusy]);

  const enableMultiplePrimaryProducts = start?.startedOrder?.license?.enableMultiplePrimaryProducts;

  return (
    <>
      {form?.linkedOrders?.map?.((linkedOrder, index) => {
        return (
          <OrderLineProductEntry
            primaryLineItem={linkedOrder.primaryLineItem}
            otherLineItems={linkedOrder.otherLineItems}
            key={linkedOrder.orderRef || index}
            orderRef={linkedOrder.orderRef}
            index={index}
            linkedOrder={linkedOrder}
          />
        );
      })}
      {enableMultiplePrimaryProducts && !readOnly && (
        <Button
          size="small"
          type="primary"
          onClick={addOrderLine}
          disabled={disabled}
          metricId={'core-order-detail-add-product'}
        >
          <i className="icon fa fa-plus" />
          <Translate stringId="addPrimaryProduct" defaultMessage="Add Primary Product" />
        </Button>
      )}
    </>
  );
};

export default OrderLinesProductEntry;
