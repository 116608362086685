import { css } from 'styled-components';

export default css`
  width: 100%;
  padding: 5px;
  table {
    width: 100%;
    thead {
      tr {
        background-color: #333;
        th {
          border: 1px solid #666;
          padding: 3px 10px;
        }
      }
    }
    tbody {
      td {
        padding: 3px 10px;
        border: 1px solid #666;

        &.open,
        &.close {
          .wrapper {
            display: flex;
            align-items: center;
            .time {
              flex: 1;
            }
            .fa-clone {
              float: right;
              cursor: pointer;
            }
          }
        }
        button {
          margin-top: 0;
        }

        input {
          background-color: transparent;
          border: none;
          color: #fff;
        }

        input[type='time']::-webkit-calendar-picker-indicator {
          filter: invert(100%);
        }
      }
    }
  }
`;
