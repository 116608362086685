import { useCallback, useRef } from 'react';

import Buttons from '../../../../components/layout/Buttons';
import Button from '../../../../components/button/Button';
import { Translate } from '../../../order/components/localization/Translate';

const CommandBar = ({ onCancel, onSave, busy, saving, typeId, disabled, disableSaving }) => {
  const buttonRef = useRef(null);

  const handleSave = useCallback(() => {
    buttonRef.current.blur();
    onSave();
  }, [onSave]);

  return (
    <Buttons>
      <Button id="btn-cancel" disabled={busy} onClick={onCancel} metricId={`core-setup-${typeId}-editor-cancel`}>
        <Translate stringId="cancel" defaultMessage="Cancel" />
      </Button>
      <Button
        id="btn-save"
        loading={saving}
        disabled={busy || disabled || disableSaving}
        onClick={handleSave}
        metricId={`core-setup-${typeId}-editor-save`}
        innerRef={buttonRef}
      >
        <Translate stringId="save" defaultMessage="Save" />
      </Button>
    </Buttons>
  );
};

export default CommandBar;
