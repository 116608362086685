import { message } from 'antd';
import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import Button from '../../../../components/button/Button';
import Buttons from '../../../../components/layout/Buttons';
import style from './style';

const Styled = styled.div`
  ${style}
`;

const ACCEPT_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

const ImportExport = ({ item, onFinish, uploadFile, downloadFile }) => {
  const [uploading, setUploading] = React.useState(false);
  const [downloading, setDownloading] = React.useState(false);

  const { typeId, crn: itemRef, name } = item ?? {};
  const fileInputRef = React.useRef();

  const handleUpload = React.useCallback(() => {
    fileInputRef?.current?.click?.();
  }, []);

  const handleDownload = React.useCallback(() => {
    setDownloading(true);
    downloadFile(typeId, itemRef, name).finally(() => {
      setDownloading(false);
    });
  }, [downloadFile, itemRef, name, typeId]);

  const executeFileUpload = React.useCallback(
    file => {
      setUploading(true);
      uploadFile(typeId, itemRef, file)
        .then(() => {
          message.success({
            content: 'The workbook was uploaded successfully.',
            style: { marginTop: '20px' },
            duration: 2,
          });
          onFinish();
        })
        .finally(() => {
          setUploading(false);
        });
    },
    [itemRef, onFinish, typeId, uploadFile]
  );

  const handleInitiateFileUpload = React.useCallback(
    event => {
      const file = event?.target?.files?.[0];
      if (file) {
        executeFileUpload(file);
        event.target.value = '';
      }
    },
    [executeFileUpload]
  );

  return (
    <Styled className={cn('import-export')}>
      <Buttons>
        <Button
          id="btn-upload"
          size="small"
          onClick={handleUpload}
          loading={uploading}
          metricId={`core-setup-${typeId}-upload`}
        >
          Import
        </Button>
        <Button
          id="btn-download"
          size="small"
          onClick={handleDownload}
          loading={downloading}
          metricId={`core-setup-${typeId}-download`}
        >
          Export
        </Button>
        <input
          hidden
          accept={ACCEPT_TYPE}
          autoComplete="off"
          type="file"
          onChange={handleInitiateFileUpload}
          multiple={false}
          ref={fileInputRef}
        />
      </Buttons>
    </Styled>
  );
};

export default ImportExport;
