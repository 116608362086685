import React, {useCallback, useMemo, useState} from 'react';
import {Drawer as DrawerComponent} from 'antd';

export const useDrawer = nav => {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [content, setContent] = useState(null);
  const toggleDrawer = useCallback(() => {
    setDrawerVisible(s => {
      const visibleValue = !s;

      if(visibleValue === false) {
        setTimeout(() => {
          setContent(null)
        }, 200)
      }

      return visibleValue;
    });
  }, [drawerVisible]);

  const setDrawerOpen = useCallback((visibleValue = true) => {
    setDrawerVisible(visibleValue);
    if(visibleValue === false) {
      setTimeout(() => {
        setContent(null)
      }, 200)
    }
  }, [setDrawerVisible, setContent])

  const Drawer = useMemo(() => {
    return <DrawerComponent closable={false} onClose={() => {
      setDrawerOpen(false);
    }} width={500} visible={drawerVisible}>{content}</DrawerComponent>
  }, [content, drawerVisible, setDrawerOpen])

  return {
    Drawer,
    toggle: toggleDrawer,
    visible: drawerVisible,
    setOpen: setDrawerOpen,
    setContent,
  };
};
