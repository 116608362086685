import React, { useContext } from 'react';
import api from './api';
import { getCachedItem } from './util/cache';

const AppContext = React.createContext();

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error(`useAppContext cannot be rendered outside of the AppContext context provider`);
  }
  return context;
};

export const AppContextProvider = ({ data, children }) => {
  const locale = getCachedItem('locale', data?.locale);
  const timeZone = getCachedItem('timeZone', data?.timeZone);

  return <AppContext.Provider value={{ ...data, locale, timeZone }}>{children}</AppContext.Provider>;
};
