import React from 'react';
import {FILTER_STRATEGY} from "../../../components/connex-data-grid/util";

export const prototype = {
  id: '',
  name: '',
  status: 'ACTIVE',
};

export const columns = [
  {
    key: 'id',
    name: 'ID',
  },
  {
    key: 'name',
    name: 'Name',
  },
  {
    key: 'displayInPlanningGraphs',
    name: 'Display When Planning',
    formatter: props => props.row.displayInPlanningGraphs ?? 'YES',
  },
  {
    key: 'status',
    name: 'Status',
    filterStrategy: FILTER_STRATEGY.EQUALS,
    filterComponent: ({ innerRef, ...props }) => {
      return (
          <select ref={innerRef} {...props}>
            <option value="" />
            <option value="ACTIVE">Active</option>
            <option value="INACTIVE">Inactive</option>
          </select>
      );
    },
  },
  {
    key: 'defaultValue',
    name: 'Default',
    getValue: item => (item?.defaultValue === true ? 'true' : ''),
  },
];
