import { isEmpty } from 'lodash';
import { DateTime } from 'luxon';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import useDeepCompareEffect from 'use-deep-compare-effect';
import canonicalJson from '../../../../../util/canonicalJson';
import { Date as CxDate } from '../../../components/localization/Date';
import { Number } from '../../../components/localization/Number';
import { Translate } from '../../../components/localization/Translate';
import formatData from './formatData';
import IntervalLines from './IntervalLines';
import Legend from './Legend';
import NowMarker from './NowMarker';
import style from './style';
import { useOrderListContext } from '../../../components/OrderListContext';
import InputCheckbox from '../../../../setup/components/input-checkbox';
import { isFuture, isToday } from '../date-filter-bar/isTomorrow';
import { SEVERITY } from '../../../components/pie-chart/PieChart';
import useSetup from '../../../../setup/useSetup';
import uomCodes from '../../../../../util/uom/uom-codes';

const View = styled.div`
  ${style}
`;

const getDateTime = timeZone => {
  const now = DateTime.now().setZone(timeZone);

  const minutesMod = now.minute % 30;

  return now.minus({
    minutes: minutesMod,
  });
};

const getLocalStorageItem = key => {
  const option = localStorage.getItem(key);
  if (option) {
    return JSON.parse(option);
  }
  return null;
};

const BAR_ORDER = [SEVERITY.DELIVERED, SEVERITY.SUCCESS, SEVERITY.WARNING, SEVERITY.DANGER, SEVERITY.CANCELLED];

const convertKeyToDate = key => {
  // expected format: 2023-08-22
  const dateParts = key.split('-');

  return new Date(dateParts[0], dateParts[1], dateParts[2], 15, 0, 0, 0);
};

const OrdersGraph = ({ orders, timeZone = 'America/Chicago' }) => {
  const { quickDate } = useOrderListContext();

  const [trucksGraph, setTrucksGraph] = React.useState(() => {
    return getLocalStorageItem('trucksGraph') || false;
  });

  const { setupItems, getSetupItems } = useSetup('entity-setup');

  const uom = useMemo(() => {
    return uomCodes.find(uom => uom.isoCode === setupItems?.[0]?.uom_settings?.uom_concrete_volume)?.display || 'CY';
  }, [setupItems]);

  const graphData = React.useMemo(() => {
    if (!quickDate) {
      return null;
    }
    const formattedData = formatData(orders, quickDate.firstStartDateTime, quickDate.lastStartDateTime, trucksGraph);
    return formattedData;
  }, [orders, quickDate, trucksGraph]);

  const [now, setNow] = React.useState(getDateTime(timeZone));

  const onChange = e => {
    setTrucksGraph(!trucksGraph);
  };

  useDeepCompareEffect(() => {
    setNow(getDateTime(timeZone));
    const interval = setInterval(() => {
      setNow(getDateTime(timeZone));
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, [orders, timeZone]);

  React.useEffect(() => {
    const trucksGraphOpt = getLocalStorageItem('trucksGraph');
    if (trucksGraphOpt) {
      setTrucksGraph(trucksGraphOpt);
    }
  }, []);

  React.useEffect(() => {
    localStorage.setItem('trucksGraph', JSON.stringify(trucksGraph));
  }, [trucksGraph]);

  React.useEffect(() => {
    getSetupItems().then();
  }, [getSetupItems]);

  return !graphData ? null : (
    <View>
      <div style={{ marginLeft: '10px' }}>
        <InputCheckbox
          id="trucksGraph"
          label={<Translate stringId="trucksGraph" defaultMessage="Trucks Graph" />}
          onChange={onChange}
          value={trucksGraph}
        />
      </div>
      <Legend
        visible={!isEmpty(graphData?.timeSlots)}
        quantities={graphData.quantities}
        future={isFuture(quickDate)}
        uomLabel={uom}
      />
      <div className="outer">
        <div className="y-labels">
          {graphData?.intervals?.map?.((interval, idx) => (
            <div
              className="interval"
              key={`interval_${interval.value}`}
              style={{
                height: idx === 0 ? '35px' : `${(graphData.interval / graphData.max) * 100 - 18.5}%`,
                marginBottom: '45px',
              }}
            >
              {interval.value}
            </div>
          ))}
        </div>
        <div className="graph-wrapper">
          <div className="graph">
            <div style={{ display: 'flex', justifyContent: 'center', flex: '1', flexDirection: 'column' }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'flex-end',
                  gridGap: '10px',
                  marginRight: '20px',
                  position: 'relative',
                }}
              >
                <div
                  style={{
                    width: '10px',
                    height: `${graphData?.intervals?.[graphData?.intervals?.length - 1]?.height + 15}px`,
                  }}
                >
                  <NowMarker graphData={graphData} now={now} />
                </div>
                {Object.entries(JSON.parse(canonicalJson(graphData.timeSlots))).map(([key, value], idx) => {
                  const [date, hours, minutes, time] = key.split('_');
                  const height = (value.total / graphData.max) * 100;
                  const heightFactor = 290;
                  const timeLabel = `${hours}:${minutes}`;
                  let rowHeight = 0;
                  for (const severity of Object.values(SEVERITY)) {
                    if (value[severity]) {
                      rowHeight += (value[severity] / graphData.max) * heightFactor;
                    }
                  }

                  return (
                    <div className="bar-stack" key={key}>
                      {BAR_ORDER.map(severity => {
                        if (value[severity]) {
                          return (
                            <div
                              style={{
                                height: height ? `${(value[severity] / graphData.max) * heightFactor}px` : '1px',
                              }}
                              className={`bar ${severity}`}
                              key={severity}
                            />
                          );
                        }
                        return null;
                      })}

                      {timeLabel === '12am' || timeLabel === '00:00' ? (
                        <div
                          style={{
                            height: `${graphData?.intervals?.[graphData?.intervals?.length - 1]?.height}px`,
                            top: `${
                              -1 * graphData?.intervals?.[graphData?.intervals?.length - 1]?.height + rowHeight
                            }px`,
                          }}
                          className="separator"
                        >
                          <div className="caret-wrapper">
                            <i className="caret fas fa-caret-down" />
                          </div>
                        </div>
                      ) : null}

                      {value.total === 0 && (
                        <div
                          style={{
                            height: '1px',
                            backgroundColor: '#CCC',
                          }}
                          className="bar"
                        />
                      )}
                      <div className="x-labels" style={{ height: '40px', width: '15px' }}>
                        {
                          <>
                            {idx % 2 === 0 && <div className="label">{timeLabel}</div>}
                            {hours === '12' && idx % 2 === 0 && isFuture(quickDate) && (
                              <div>
                                <div className="date-label">
                                  <CxDate date={convertKeyToDate(date)} />
                                </div>
                                <div className="date-label" style={{ marginTop: '-320px', fontSize: '0.8rem' }}>
                                  Total <Number value={graphData?.totalsPerDate[date] ?? 0} decimalPlaces={1} />{' '}
                                  <Translate stringId={uom} />
                                </div>
                              </div>
                            )}
                          </>
                        }
                      </div>
                    </div>
                  );
                })}
                <div style={{ width: '10px' }}>&nbsp;</div>
              </div>
              <IntervalLines graphData={graphData} />
            </div>
          </div>
        </div>
      </div>
    </View>
  );
};

export default OrdersGraph;
