import styled from 'styled-components';
import style from './style';
import cn from 'classnames';
import { useCallback, useMemo } from 'react';
import { get } from 'lodash';
import ContactLine from './ContactLine';
import Button from '../../../../components/button/Button';
import { v4 as uuid } from 'uuid';
import ValidationMessage from '../../setup-customers/customer-editor/customer-general/ValidationMessage';

const Styled = styled.div`
  ${style}
`;

const ContactListEditor = ({
  className,
  id: contactId = 'contacts',
  item,
  onChange,
  typeId,
  validationErrors = {},
}) => {
  const contactList = useMemo(() => {
    return get(item, contactId) || [];
  }, [contactId, item]);

  const itemUpdated = useCallback(
    (key, value) => {
      const updatedList = contactList.map((existingValue, index) => {
        if (key === index) {
          return value;
        }
        return existingValue;
      });
      onChange(contactId, updatedList);
    },
    [contactList, contactId, onChange]
  );
  const addItem = useCallback(() => {
    const newList = [...contactList];
    newList.push({ id: uuid() });
    onChange(contactId, newList);
  }, [contactList, onChange, contactId]);

  const removeItem = useCallback(
    key => {
      let newList = [...contactList];
      newList = newList.filter((_item, index) => index !== key);
      onChange(contactId, newList);
    },
    [contactId, contactList, onChange]
  );

  return (
    <Styled className={cn(className, contactId)}>
      <ValidationMessage validationErrors={validationErrors} id={contactId} />
      <span className={'table-header'}>
        <Button
          className={'add-contact-btn'}
          size={'small'}
          type={'primary'}
          metricId={`core-setup-${typeId}-add-contact`}
          onClick={addItem}
        >
          + Contact
        </Button>
      </span>
      {contactList.map((contactItem, key) => {
        return (
          <ContactLine
            key={key}
            item={contactItem}
            typeId={typeId}
            onChange={value => {
              itemUpdated(key, value);
            }}
            onAction={actionName => {
              if (actionName === 'remove_item') {
                removeItem(key);
              }
            }}
          />
        );
      })}
    </Styled>
  );
};

export default ContactListEditor;
