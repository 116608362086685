import cn from 'classnames';
import { find } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import FieldContainer from '../../field-container/FieldContainer';
import Input from '../../form/Input';
import Select from '../../form/Select';
import style from './style';

const { Option } = Select;

const Product = ({ className, products, onChange, value, disabled, required }) => {
  const handleChange = val => {
    const targetProduct = find(products, s => s.productRef === val);

    const { crn, ...crnRemoved } = targetProduct;
    onChange('product', { ...crnRemoved, productRef: crn });
  };

  return (
    <FieldContainer className={cn('product', className)}>
      <div className="label-spinner">
        <div className="label">
          Product
          {required && <span className="required-indicator">*</span>}
        </div>
        <div className={cn('spinner', { spinning: false })}>
          <span className="fas fa-sync fa-spin" />
        </div>
      </div>

      <Select value={value?.productRef || ''} onChange={handleChange} disabled={disabled}>
        <Option value="">Select a product...</Option>
        {products?.map(c => (
          <Option value={c.crn} key={c.crn}>
            {c.id} / {c.description}
          </Option>
        ))}
      </Select>
    </FieldContainer>
  );
};

export default styled(Product)`
  ${style}
`;
