import style from './style';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import SelectConcreteType from '../../../components/select-concrete-type';
import useSetup from '../../../useSetup';

const Styled = styled.div`
  ${style}
`;

const ProductConcreteClasses = ({ item: product, onChange }) => {
  const { getSetupItems, setupItems } = useSetup('concrete-class');
  const [options, setOptions] = useState([]);

  useEffect(() => {
    getSetupItems();
  }, []);

  useEffect(() => {
    setupItems &&
      setOptions(
        setupItems.map(s => {
          return {
            value: s.crn,
            label: s.name,
            type: s.classType,
          };
        })
      );
  }, [setupItems]);

  return (
    <Styled>
      <SelectConcreteType item={product} type="consistence" label="Consistence" onChange={onChange} options={options} />
      <SelectConcreteType item={product} type="temperature" label="Temperature" onChange={onChange} options={options} />
      <SelectConcreteType item={product} type="slumpLossRate" label="Slump Loss Rate" onChange={onChange} options={options} />
      <SelectConcreteType item={product} type="air" label="Air" onChange={onChange} options={options} />
    </Styled>
  );
};

export default ProductConcreteClasses;
