import style from './style';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import useSetup from '../../../useSetup';
import { Checkbox } from '../../../../../components/checkbox';

const Styled = styled.div`
  ${style}
`;

const FormulaLocations = ({ item: formula, onChange, usedLocations, locationWarningMessage }) => {
  const { getSetupItems: getLocations, setupItems: locations } = useSetup('location');

  const [hadAllSelected, setHadAllSelected] = useState(null);

  useEffect(() => {
    if (hadAllSelected === null) {
      setHadAllSelected(formula?.locations?.all === true);
    }
  }, [formula?.locations?.all, hadAllSelected]);

  useEffect(() => {
    getLocations();
  }, []);

  const onPlant = (locationRef, checked) => {
    const locations = {
      ...(formula.locations || {}),
      [locationRef]: checked,
    };

    onChange('locations', locationRef === 'all' ? { all: checked } : locations);
  };

  return (
    <Styled>
      <div>{formula?.name} Location Availability</div>
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th style={{ width: '80%' }}>Plant</th>
              <th style={{ width: '20%' }}>Available</th>
            </tr>
          </thead>
          <tbody>
            {hadAllSelected && (
              <tr>
                <td>All Locations</td>
                <td>
                  <Checkbox
                    id={'all-plants'}
                    onChange={e => onPlant('all', e.target.checked)}
                    checked={formula?.locations?.all === true}
                  />
                </td>
              </tr>
            )}
            {locations?.map(p => (
              <tr key={p.crn}>
                <td>
                  {p.id} / {p.name}
                </td>
                <td>
                  <Checkbox
                    id={p.crn}
                    onChange={e => onPlant(p.crn, e.target.checked)}
                    checked={formula?.locations?.[p.crn] === true}
                    disabled={usedLocations.includes(p.crn) && !formula?.locations?.[p.crn]}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {locationWarningMessage && locationWarningMessage.length > 0 && (
        <div className="warning-section">
          <div className="warning-icon">
            <i className="fas fa-exclamation-triangle"></i>
          </div>
          <div className="warning-text">{locationWarningMessage}</div>
        </div>
      )}
    </Styled>
  );
};

export default FormulaLocations;
