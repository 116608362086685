import cn from 'classnames';
import React from 'react';
import { isNumber } from 'lodash';

import styled from 'styled-components';
import Input from '../../../../components/form/Input';
import Select from '../../../../components/form/Select';

const { Option } = Select;

const Styled = styled.div`
  margin-top: 10px;
  .form-fields {
    display: flex;
    grid-gap: 10px;
  }
`;

const TYPE = {
  FIXED: 'FIXED',
  DERIVED_FROM_PRIMARY_QUANTITY: 'DERIVED_FROM_PRIMARY_QUANTITY',
};

const options = [
  { value: TYPE.FIXED, label: 'Fixed Value' },
  { value: TYPE.DERIVED_FROM_PRIMARY_QUANTITY, label: 'Primary Product Quantity' },
];

const QuantityToAdd = ({ value = { type: 'FIXED', value: '1' }, onChange, disabled }) => {
  const [internal, setInternal] = React.useState(value?.type ? value : { type: 'FIXED', value });

  React.useEffect(() => {
    onChange('quantity', internal);
  }, [internal, onChange]);

  const handleValueTypeChange = React.useCallback(
    type => {
      if (type === TYPE.FIXED) {
        setInternal({ type, value: isNumber(value?.value) ? value?.value : '1' });
      } else {
        setInternal({ type });
      }
    },
    [value]
  );

  const handleQuantityChange = React.useCallback(e => {
    setInternal(s => ({ ...s, value: e.target.value }));
  }, []);

  return (
    <Styled className={cn('quantity-to-add')}>
      <div className="label">Product Quantity to Add</div>

      <div className="form-fields">
        <Select value={value?.type || 'FIXED'} onChange={handleValueTypeChange} disabled={disabled}>
          {options.map(o => (
            <Option key={o.value} value={o.value}>
              {o.label}
            </Option>
          ))}
        </Select>

        {(!value?.type || value?.type === TYPE.FIXED) && (
          <Input type="text" value={value?.value || ''} onChange={handleQuantityChange} disabled={disabled} />
        )}
      </div>
    </Styled>
  );
};

export default QuantityToAdd;
