import { useCallback, useEffect, useState } from 'react';
import { useStartOrderRequest } from '../../create-order/useStartOrderRequest';
import { useHash } from './useHash';
import { useUpdateOrder } from './useUpdateOrder';
import { filter, uniqBy } from 'lodash';

export const useUpdateOrderRequest = (order, form, handleChange) => {
  // const { form, handleChange, setForm } = useForm({});
  const { updateOrder, updateOrderBusy } = useUpdateOrder();
  // const { getOrder, order, orderBusy } = useOrder();
  const { startOrder, response, startOrderBusy } = useStartOrderRequest();
  const hash = useHash();
  const [pourDescriptions, setPourDescriptions] = useState(null);
  const [disciplines, setDisciplines] = useState(null);

  // useEffect(() => {
  //   getOrder(orderId);
  // }, [getOrder]);

  // useEffect(() => {
  //   if(order){
  //     setForm(order || {});
  //     hash.initialize(order);
  //   }
  // }, [order]);

  useEffect(() => {
    if (form?.supplier?.crn) {
      startOrder(form?.supplier?.crn);
    }
  }, [form.supplier, startOrder]);

  const handleJobChange = useCallback(
    (...args) => {
      handleChange(...args);

      handleChange('discipline', '');
      handleChange('pourDescription', '');
    },
    [handleChange]
  );

  const handleDisciplineChange = useCallback(
    (...args) => {
      handleChange(...args);

      handleChange('pourDescription', '');
    },
    [handleChange]
  );

  useEffect(() => {
    if (response?.settings?.useJobFiltering === true) {
      setDisciplines(
        uniqBy(
          filter(response?.pourDescriptions, {
            project: { projectRef: form?.job?.jobRef },
          })
            .map(i => {
              return {
                crn: i?.discipline?.disciplineRef,
                id: i?.discipline?.id,
                description: i?.discipline?.name,
              };
            })
            .sort((a, b) => {
              return a.id === b.id ? 0 : (a.id < b.id ? -1 : 1);
            }),
          'crn'
        )
      );
    } else {
      setDisciplines(response?.disciplines);
    }
  }, [form.job, response.pourDescriptions]);

  useEffect(() => {
    if (response?.settings?.filterDescriptions === true) {
      setPourDescriptions(
        filter(response?.pourDescriptions, {
          project: { projectRef: form?.job?.jobRef },
          discipline: { disciplineRef: form?.discipline?.disciplineRef },
        })
      );
    } else {
      setPourDescriptions(response?.pourDescriptions);
    }
  }, [form.job, form.discipline, response.pourDescriptions]);

  // const isValid = useMemo(
  //   () => form?.supplier?.crn && form?.product?.productRef && form?.startDateTime && form?.quantity,
  //   [form]
  // );

  // const isDirty = useMemo(() => hash.hasChanged(form), [form, hash]);

  return {
    // order: { getOrder, order, orderBusy },
    // form: { form, handleChange, setForm },
    updateOrderRequest: { updateOrder, updateOrderBusy },
    lists: { lists: response, listsBusy: startOrderBusy, pourDescriptions, disciplines },
    handleJobChange,
    handleDisciplineChange,
    // isValid,
    // isDirty,
  };
};
