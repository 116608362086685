import {css} from 'styled-components';

export default css`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  
  p {
    font-style: italic;
  }
  
  h3 {
    margin-top: 20px;
  }
  
  table tr:first-child {
    font-weight: bold;
    font-size: 1.2em;
  }
  
  td {
    padding: 5px
  }
`;
