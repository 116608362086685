import { Drawer } from 'antd';
import React, { useState } from 'react';
import styled from 'styled-components';
import { ConnexDataGrid } from '../../../../components/connex-data-grid/ConnexDataGrid';
import Spinner from '../../../../components/spinner/Spinner';
import FlexColumn from '../../../order/components/FlexColumn';
import SetupHeader from '../../setup-header';
import style from './style';

const View = styled(FlexColumn)`
  ${style}
`;

const SetupTable = ({
  typeId,
  type,
  busy,
  columns,
  items,
  Editor,
  onRefresh,
  prototype,
  customers,
  editorProps,
  showAdd = true,
  actions,
}) => {
  const [editing, setEditing] = useState(null);

  const editorParams = {
    typeId,
    ...editorProps,
  };

  const customerEditorProps = () => {
    return type === 'Customer'
      ? {
          list: items,
        }
      : {};
  };

  const onSave = refresh => {
    setEditing(null);
    refresh && onRefresh?.();
  };

  const onCancel = () => {
    setEditing(null);
  };

  const onAdd = () => {
    setEditing(prototype);
  };

  const handleRowClick = React.useCallback(row => {
    setEditing(row);
  }, []);

  return (
    <View className="setup-table-wrapper">
      <SetupHeader type={type} onAdd={onAdd} showAdd={showAdd} />
      <Spinner spin={busy}>
        <ConnexDataGrid
          showFiltersByDefault
          gridName={`Setup ${type}`}
          columns={columns}
          rows={items}
          rowKeyPath="crn"
          onRowClick={handleRowClick}
          defaultFilters={{ status: 'ACTIVE' }}
          allowExport={true}
          filterable
          rowClickable
          autoSizeColumns
          actions={actions}
          onFinish={onCancel}
        />
      </Spinner>
      <Drawer
        width={600}
        title={`${type} Editor`}
        placement="right"
        closable
        onClose={() => setEditing(null)}
        visible={editing}
        destroyOnClose
      >
        <Editor
          item={editing}
          onSave={onSave}
          onCancel={onCancel}
          params={editorParams}
          customers={customers}
          {...customerEditorProps()}
        />
      </Drawer>
    </View>
  );
};

export default SetupTable;
