import React from 'react';
import { useScaleTicketingContext } from '../Context';

export const useScaleIntegration = cb => {
  const { form } = useScaleTicketingContext();
  const listenerRef = React.useRef();

  const stopScaleListener = React.useCallback(() => {
    if (listenerRef.current) {
      clearInterval(listenerRef.current);
      listenerRef.current = null;
    }
  }, []);

  const startScaleListener = React.useCallback(() => {
    if (form?.scale) {
      const min = 71450;
      const max = 71550;
      const interval = setInterval(() => {
        const lbs = Math.round(Math.random() * (max - min) + min);
        cb(lbs);
      }, 1000);

      listenerRef.current = interval;
    }
  }, [cb, form?.scale]);

  React.useEffect(() => {
    if (form?.scale) {
      stopScaleListener();
      startScaleListener();
    }
    return () => {
      stopScaleListener();
      cb(null);
    };
  }, [cb, form?.scale, startScaleListener, stopScaleListener]);
};
