import { css } from 'styled-components';

export default css`
  .label-spinner {
    display: flex;
    .label {
      flex: 1;
    }
  }

  &.invalid {
    input {
      border: 1px solid red;
    }
  }

  &.disabled {
    .label-spinner {
      .label {
        color: #757575;
      }
    }
  }

  .react-tel-input {
    display: flex;
    min-width: 0;
    flex: 1;
    flex-shrink: 1;
    .form-control {
      background-color: inherit !important;
      color: #fff !important;
      border: 1px solid #39393a !important;
      height: 32px !important;
      border-radius: 2px !important;
      width: 100%;
    }

    .form-control:hover {
      border: 1px solid #177ddc !important;
    }

    .form-control:disabled {
      background-color: #181818 !important;
      border: 1px solid #39393a !important;
      color: #606060 !important;
    }

    .selected-flag {
      color: #252525 !important;
      &.open {
        background-color: #252525 !important;
      }
    }

    .selected-flag:hover {
      background-color: #252525 !important;
      color: #252525 !important;
    }

    .selected-flag:disabled {
      cursor: not-allowed !important;
    }

    .flag-dropdown {
      background-color: inherit !important;
      border: 1px solid #39393a !important;

      &.open {
        background-color: #252525 !important;
      }
    }

    .flag-dropdown:disabled {
      cursor: not-allowed !important;
    }

    .country-list {
      background-color: #252525 !important;
      color: #fff !important;

      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #131313;
        border-radius: 4px;
        height: 30px;
      }

      &::-webkit-scrollbar-track {
        background-color: #252525;
      }

      .country {
        padding: 8px;

        &:hover {
          background-color: #393939;
        }

        &.active {
          background-color: #00182d99; /* Change the color for the selected item */
        }

        &.highlight {
          background-color: #393939;
        }
      }
    }
  }
`;
