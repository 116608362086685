import { css } from 'styled-components';

export default css`
  background-color: #a61d24;
  border: 3px solid #a61d24;
  border-radius: 4px;
  .button-section {
    background-color: #a61d24;
    transition: height 0.2s;
    overflow: hidden;
    height: 31px;
    &:not(.visible) {
      height: 0;
    }
    button {
      background-color: #a61d24;
      border: 0;
      margin: 2px;
      cursor: pointer;
      letter-spacing: 2px;

      &:disabled {
        cursor: not-allowed;
        width: 100%;
      }
    }
  }
  .details-section-wrapper {
    background-color: #a61d24;
    overflow: hidden;
    transition: height 0.2s;
    height: 270px;
    &:not(.visible) {
      height: 0;
    }
    .details-section {
      background-color: #1f1f1f;
      border-radius: 4px;
      padding: 10px;
      height: 100%;
      grid-gap: 20px;
      .note {
        textarea {
          flex: 1;
          width: 100%;
        }
      }
      .void-cancel-actions {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));
        grid-gap: 10px;
        position: sticky;
        bottom: 0;
      }
    }
  }
`;
