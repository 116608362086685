import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import FieldContainer from '../../field-container/FieldContainer';
import Input from '../../form/Input';
import style from './style';

const PourCardNumber = ({ className, value}) => {
  return (
    <FieldContainer className={cn('pour-card-number', className)}>
      <div className="label-spinner">
        <div className="label">Pour Card Number</div>
        <div className={cn('spinner', { spinning: false })}>
          <span className="fas fa-sync fa-spin" />
        </div>
      </div>
      <Input
        type="text"
        value={value || ''}
        disabled
        data-testid="pourCardNumber_input"
      />
    </FieldContainer>
  );
};

export default styled(PourCardNumber)`
    ${style}
`;
