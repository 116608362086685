import style from './style';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import useSetup from '../../useSetup';
import { useParams } from 'react-router';
import InputNumberBlur from '../../components/input-number-blur';
import { cloneDeep } from 'lodash';
import UOM from '../../../../components/uom';
import roundedToCents from '../../../../util/other/rounded-to-cents';

const Cost = ({ uomCode, location, price, onChange }) => {
  return (
    <tr>
      <td>
        {location.id} / {location.name}
      </td>
      <td>
        <InputNumberBlur id={location.crn} value={price ?? ''} onChange={onChange} />
      </td>
      <td>
        <UOM uomCode={uomCode} />
      </td>
    </tr>
  );
};

const Styled = styled.div`
  ${style}
`;

const ComponentCosts = ({ item, onChange }) => {
  const { entityRef } = useParams();
  const { getLocations, locations } = useSetup();

  useEffect(() => {
    getLocations(entityRef);
  }, [entityRef, getLocations]);

  const changeCost = (id, value) => {
    const prices = cloneDeep(item['costs'] || []).filter(p => p.locationRef !== id);
    const location = locations.find(p => p.crn === id);

    const formatted = parseFloat(value);

    if (location) {
      const price = {
        id: location.id,
        locationRef: id,
        price: {
          currencyCode: 'USD',
          uomCode: item.uomCode,
          value: isNaN(formatted) ? null : roundedToCents(formatted),
        },
      };

      prices.push(price);

      onChange && onChange('costs', prices);
    }
  };

  return (
    <Styled>
      <div>Costs by Location For {item?.name}</div>
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>Location</th>
              <th>Cost</th>
              <th>UOM</th>
            </tr>
          </thead>
          <tbody>
            {locations?.map(location => (
              <Cost
                key={location.crn}
                uomCode={item.uomCode}
                location={location}
                price={item?.costs?.find(p => p.locationRef === location.crn)?.price?.value}
                onChange={changeCost}
              />
            ))}
          </tbody>
        </table>
      </div>
    </Styled>
  );
};

export default ComponentCosts;
