import React, {useCallback, useState} from 'react';
import {useParams} from 'react-router-dom';
import {api} from "../../../api";

export const useTickets = showToast => {
  const { entityRef } = useParams();
  const [ticket, setTicket] = useState(false);
  const [ticketBusy, setTicketBusy] = useState(false);

  const getTicket = useCallback(
    ticketRef => {
      setTicket(null);
      setTicketBusy(true);

      return api
        .getTicket(entityRef, ticketRef)
        .then(response => {
          setTicket(response);

          return response;
        })
        .finally(() => {
          setTicketBusy(false);
        });
    },
    [entityRef]
  );

  return {
    getTicket,
    ticket,
    ticketBusy,
  };
};
