import React from 'react';
import { FILTER_STRATEGY } from '../../../components/connex-data-grid/util';

export const prototype = {
  id: '',
  name: '',
  phone: '',
  mobile: '',
  email: '',
  status: 'ACTIVE',
};

export const columns = [
  { key: 'id', name: 'ID' },
  { key: 'firstName', name: 'First Name' },
  { key: 'lastName', name: 'Last Name' },
  { key: 'phone', name: 'Phone Number' },
  { key: 'mobile', name: 'Mobile' },
  { key: 'email', name: 'Email' },
  {
    key: 'status',
    name: 'Status',
    filterStrategy: FILTER_STRATEGY.EQUALS,
    filterComponent: ({ innerRef, ...props }) => {
      return (
        <select ref={innerRef} {...props}>
          <option value="" />
          <option value="ACTIVE">Active</option>
          <option value="INACTIVE">Inactive</option>
        </select>
      );
    },
  },
];
