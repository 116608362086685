import { throttle } from 'lodash';
import { DateTime } from 'luxon';
import React from 'react';
import { useTime } from '../../localization/Time';
import { useScheduleContext } from './ScheduleContext';
import { calculateChanges, getInitialSliderValue, recalculateLoads } from './util';

export const useModeling = props => {
  const { order, scheduleTimes } = useScheduleContext();

  const { targetOrderRef, demand, loads, startTime, setLoads, setOnJobRange } = props;

  const [diffs, setDiffs] = React.useState({});

  const initialSliderValue = React.useRef(getInitialSliderValue(loads?.loads, targetOrderRef));

  const [initialStartDateTime, setInitialStartDateTime] = React.useState();
  const [initialRange, setInitialOffset] = React.useState(initialSliderValue.current?.range);
  const [sliderValue, setSliderValue] = React.useState(initialSliderValue.current.range);

  const throttledRecalculateLoads = React.useMemo(() => {
    return throttle(recalculateLoads, 200);
  }, []);

  React.useEffect(() => {
    const filteredLoads = Object.entries(loads?.loads)
      ?.filter?.(([key, load]) => load.orderRefs.includes(targetOrderRef))
      .map(([key, load]) => load);
    const targetOrders = filteredLoads?.reduce?.((acc, value) => {
      acc = acc.concat(value.inProgress.filter(order => order.orderRef === targetOrderRef));
      return acc;
    }, []);
    const startDateTime = targetOrders?.[0]?.startDateTime;
    setInitialStartDateTime(DateTime.fromISO(startDateTime).setZone(demand?.timeZone));
  }, [loads, demand?.timeZone, targetOrderRef]);

  const handleSliderChange = React.useCallback(
    range => {
      const diff = range[0] - initialRange[0];
      const diffLeft = diff;
      const diffRight = range[1] - initialRange[1];
      const diffDistance = diffRight - diffLeft;

      setDiffs({ diffLeft, diffRight, diffDistance });

      throttledRecalculateLoads({
        targetOrderRef,
        diffLeft,
        diffDistance,
        demand,
        setLoads,
        setOnJobRange,
        startTime,
      });

      setSliderValue(range);
    },
    [
      initialRange,
      demand,
      order?.deliverySchedule,
      scheduleTimes.loadSize,
      throttledRecalculateLoads,
      targetOrderRef,
      setLoads,
      setOnJobRange,
      startTime,
    ]
  );

  const { changeTimes, createSchedule, closeDrawer } = useScheduleContext();

  const { getTime } = useTime();

  const [changeInfo, setChangeInfo] = React.useState(
    calculateChanges({
      timeZone: demand?.timeZone,
      diffs,
      deliverySchedule: order?.deliverySchedule,
      loadSize: scheduleTimes.loadSize,
      getTime,
    })
  );

  React.useEffect(() => {
    setChangeInfo(
      calculateChanges({
        timeZone: demand?.timeZone,
        diffs,
        deliverySchedule: order?.deliverySchedule,
        loadSize: scheduleTimes.loadSize,
        getTime,
      })
    );
  }, [demand?.timeZone, diffs, getTime, order?.deliverySchedule, scheduleTimes.loadSize]);

  const handleSubmit = React.useCallback(() => {
    changeTimes('spacing', changeInfo?.newSpacing);
    changeTimes('quantityRate', changeInfo?.newQuantityRate);
    createSchedule(changeInfo.newStartDateTime.toUTC().toISO());
    closeDrawer();
  }, [
    changeInfo?.newQuantityRate,
    changeInfo?.newSpacing,
    changeInfo.newStartDateTime,
    changeTimes,
    closeDrawer,
    createSchedule,
  ]);

  const handleReset = React.useCallback(() => {
    handleSliderChange(initialSliderValue.current.range);
  }, [handleSliderChange]);

  return {
    handleSliderChange,
    sliderValue,
    handleSubmit,
    handleReset,
    diffs,
    changeInfo,
    ...props,
  };
};
