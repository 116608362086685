import React from 'react';
import styled from 'styled-components';
import FlexColumn from '../../order/components/FlexColumn';
import style from './style';
import SetupItemList from '../setup-item-list';
import { columns, prototype } from './setup';
import CompanyGeneral from './company-general';
import CompanyPrices from './company-prices';
import CompanyContacts from './company-contacts';
import CompanySettings from './company-settings';
import CompanyUomSettings from './company-uom-settings';

const Styled = styled(FlexColumn)`
  ${style}
`;

const tabs = [
  {
    label: 'General',
    component: CompanyGeneral,
  },
  {
    label: 'Prices',
    component: CompanyPrices,
  },
  {
    label: 'Contacts',
    component: CompanyContacts,
  },
  {
    label: 'Settings',
    component: CompanySettings,
  },
  {
    label: 'UOM',
    component: CompanyUomSettings,
  },
];

const SetupCompany = () => {
  return (
    <Styled>
      <SetupItemList
        typeId="entity-setup"
        type="Company"
        title="Setup Company"
        columns={columns}
        prototype={prototype}
        editorType="tabs"
        tabs={tabs}
        showAdd={false}
      />
    </Styled>
  );
};

export default SetupCompany;
