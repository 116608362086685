import React from "react";
import {useMasterData} from "../../order/create-order/useMasterData";
import Spinner from "../../../components/spinner/Spinner";
import Button from "../../../components/button/Button";
import style from './style';
import styled from 'styled-components';

const Styled = styled.div`${style}`;

const Initialized = ({initialized}) => {
  return (
    <div>
      <h3>The Following Items Were Added To Company Setup</h3>
      <table>
        <tbody>
        <tr>
          <td>Item Type</td>
          <td>ID</td>
          <td>Name</td>
        </tr>

        {initialized?.map(item => (
          <tr key={`${item.pk}::${item.crn}`}>
            <td>{item.typeId}</td>
            <td>{item.id}</td>
            <td>{item.name}</td>
          </tr>
        ))}
        </tbody>
      </table>
    </div>
  )
}

const SetupInitialization = () => {
  const { initialize, initializeBusy, initialized } = useMasterData();

  return (
    <Styled>
      <Spinner spin={initializeBusy}>
        <h3>Initialize Company Setup</h3>
        <p>This operation will set up all required setup items that have not yet been configured.</p>
        <Button onClick={initialize} metricId='core-setup-initialize-company-setup'>Initialize Company Setup</Button>
        {initialized && <Initialized initialized={initialized} />}
      </Spinner>
    </Styled>

  )
}

export default SetupInitialization;
