import { find, isEmpty, omit } from 'lodash';
import style from './style';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import Button from '../../../../components/button/Button';
import useSetup from '../../useSetup';
import { useParams } from 'react-router';
import Buttons from '../../../../components/layout/Buttons';
import ProjectGeneral from './project-general';
import Tabs from '../../../../components/tabs';
import Spinner from '../../../../components/spinner/Spinner';
import SelectPriceBook from '../../components/select-price-book';
import SelectPricingOptions from '../../components/select-pricing-options';
import SelectTicketOptions from '../../components/select-ticket-options';
import FormError from '../../components/form-error';
import ProjectMap from './project-map';
import FlexColumn from '../../../order/components/FlexColumn';
import ProjectAttachments from './project-attachments';

const View = styled(FlexColumn)`
  ${style}
`;
const ButtonBar = ({ onCancel, onSave, busy, validationErrors }) => {
  return (
    <Buttons>
      <Button
        id="btn-cancel"
        disabled={busy || validationErrors}
        onClick={onCancel}
        metricId="core-setup-project-editor-cancel"
      >
        Cancel
      </Button>
      <Button
        id="btn-save"
        loading={busy}
        disabled={busy || validationErrors}
        onClick={onSave}
        metricId="core-setup-project-editor-save"
      >
        Save
      </Button>
    </Buttons>
  );
};

const ProjectEditor = ({ item, onSave, onCancel, list = [], params = { showPricing: true } }) => {
  const {
    createProject,
    updateProject,
    project,
    getProject,
    setProject,
    busy,
    error,
    setError,
    customers,
    getCustomers,
    companyLicenses,
    getCompanyLicenses,
  } = useSetup();
  const { entityRef } = useParams();
  const { tab } = useState('general');
  const [validationErrors, setValidationErrors] = React.useState(null);

  const handleInputRequired = (id, value, isRequired) => {
    if (!id) {
      return;
    }
 
    if (isRequired && value.length === 0) {
      setValidationErrors(s => {
        return {
          ...s,
          [id]: `Field is required`,
        };
      });
    } else {
      setValidationErrors(s => {
        const newState = omit(s, id);
        return isEmpty(newState) ? null : newState;
      });
    }
  };

  useEffect(() => {
    if (item) {
      if (item.crn) {
        getProject(entityRef, item.crn);
      } else {
        setProject(item);
      }
    }
  }, [entityRef, getProject, item, setProject]);

  useEffect(() => {
    getCustomers().then();
  }, [entityRef, getCustomers]);

  useEffect(() => {
    getCompanyLicenses(entityRef).then();
  }, [getCompanyLicenses, entityRef]);

  const customersMap = useMemo(() => {
    return new Map(customers?.map(c => [c.crn, c]));
  }, [customers]);

  const hasLocationZonesLicense = useMemo(() => {
    return !!companyLicenses.find(l => l.id === 'rmx-location-zones');
  }, [companyLicenses]);

  const validateField = React.useCallback(
    (id, value) => {
      if (!list?.length) return;

      if (id === 'id') {
        const existingProjectWithId = find(list, { id: value });
        if (existingProjectWithId) {
          setValidationErrors(s => {
            return {
              ...s,
              [id]: `ID already in use by ${existingProjectWithId.name}`,
            };
          });
        } else {
          setValidationErrors(s => {
            const newState = omit(s, id);
            return isEmpty(newState) ? null : newState;
          });
        }
      }
    },
    [list]
  );

  const onChange = (id, value, isRequired)=> {
    handleInputRequired(id, value, isRequired);
    validateField(id, value);
    setProject(project => ({
      ...project,
      [id]: value,
    }));
  };

  const onSaveProject = React.useCallback(async () => {
    if (validationErrors) return;

    try {
      if (project.crn) {
        await updateProject(entityRef, project.crn, project);
        onSave(true);
      } else {
        const response = await createProject(entityRef, project);
        onSave(true, response);
      }
    } catch (error) {
      console.log(error);
    }
  }, [createProject, entityRef, onSave, project, updateProject, validationErrors]);

  const attachmentsCount = useMemo(() => {
    return project?.attachments?.items?.length ? ` (${project?.attachments?.items?.length})` : '';
  }, [project?.attachments]);

  return (
    <View>
      <FormError error={error} />
      <Spinner spin={!project && busy}>
        <Tabs type="card" activeKey={tab}>
          <Tabs.TabPane tab="General" key="general" disabled={false}>
            <ProjectGeneral
              project={project}
              onChange={onChange}
              validationErrors={validationErrors}
              customers={customersMap}
              busy={busy}
            />
          </Tabs.TabPane>
          {params?.showPricing === true && (
            <Tabs.TabPane tab="Pricing" key="pricing" disabled={false}>
              {project && (
                <SelectPricingOptions
                  item={project}
                  view="project"
                  onChange={onChange}
                  hasLocationZonesLicense={hasLocationZonesLicense}
                />
              )}
              {project && <SelectPriceBook item={project} onChange={onChange} allowEmpty={true} />}
            </Tabs.TabPane>
          )}
          <Tabs.TabPane tab="Settings" key="settings" disabled={false}>
            {project && <SelectTicketOptions item={project} view="project" onChange={onChange} />}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Map" key="map" disabled={false}>
            <ProjectMap project={project} onChange={onChange} isNew={!project?.crn} />
          </Tabs.TabPane>
          <Tabs.TabPane tab={`Attachments${attachmentsCount}`} key="attachments" disabled={false}>
            <ProjectAttachments project={project} onChange={onChange} isNew={!project?.crn} setError={setError} />
          </Tabs.TabPane>
          {/*<Tabs.TabPane tab="Contacts" key="contacts" disabled={false}>*/}
          {/*  {project && <ContactListEditor item={project} onChange={onChange} />}*/}
          {/*</Tabs.TabPane>*/}
        </Tabs>
        <ButtonBar onSave={onSaveProject} onCancel={onCancel} busy={busy} validationErrors={validationErrors} />
      </Spinner>
    </View>
  );
};

export default ProjectEditor;
