import { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import Button from '../../../../components/button/Button';
import InputSelectMultiple from '../../components/input-select-multiple';
import useSetup from '../../useSetup';
import style from './style';
import { useParams } from 'react-router-dom';
import useSynchronizeMixes from './hooks/useSynchronizeMixes';

const Styled = styled.div`
  ${style}
`;

const MixSynchronize = ({ onCancel }) => {
  const { entityRef } = useParams();

  const { locations, getLocations, busy: loadingLocations, dexas, getDexas } = useSetup();
  const { getSetupItems: getMixes, setupItems: mixes, busy: loadingMixes } = useSetup('mix');
  const { loading, synchronizeMixes, error } = useSynchronizeMixes();

  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedMixes, setSelectedMixes] = useState([]);

  const params = useMemo(() => ({ activeOnly: false }), []);

  useEffect(() => {
    getLocations(entityRef, params);
    getMixes(params);
    getDexas().catch();
  }, [entityRef, getLocations, getMixes, params, getDexas]);

  const handleChangeLocations = useCallback(
    (_, value) => {
      setSelectedLocations(value);
    },
    [setSelectedLocations]
  );

  const handleChangeMixes = useCallback(
    (_, value) => {
      setSelectedMixes(value);
    },
    [setSelectedMixes]
  );

  const handleSave = useCallback(() => {
    synchronizeMixes(entityRef, selectedLocations, selectedMixes, onCancel);
  }, [synchronizeMixes, selectedLocations, selectedMixes, onCancel, entityRef]);

  const locationOptions = useMemo(() => {
    if (!locations?.length || loadingLocations || !dexas?.items) return [];

    const dexasMap = new Map(dexas.items.map(d => [d.crn, d]));

    return locations
      .filter(location => {
        const dexa = dexasMap.get(location?.dexaMapping?.dexaId);
        return location?.dexaMapping?.useMixSyncing && dexa?.connections?.length > 0;
      })
      .map(location => ({
        value: location.crn,
        label: `${location.id} / ${location.name}`,
      }));
  }, [locations, loadingLocations, dexas?.items]);

  const mixesOptions = useMemo(() => {
    if (!mixes?.length || loadingMixes) return [];

    return mixes.map(mix => ({
      value: mix.crn,
      label: `${mix.id} / ${mix.name}`,
    }));
  }, [mixes, loadingMixes]);

  return (
    <Styled>
      {error && <div className="error">{error}</div>}
      <InputSelectMultiple
        className="spacing"
        required
        label="Select Locations:"
        id="LocationsSelect"
        value={selectedLocations}
        disabled={loadingLocations}
        options={locationOptions}
        onChange={handleChangeLocations}
      />
      <InputSelectMultiple
        className="spacing"
        required
        label="Select Mixes:"
        id="MixesSelect"
        value={selectedMixes}
        disabled={loadingMixes}
        options={mixesOptions}
        onChange={handleChangeMixes}
      />
      <div className="footer spacing">
        <Button
          disabled={!selectedLocations.length || !selectedMixes.length || loading}
          loading={loadingLocations || loadingMixes || loading}
          onClick={handleSave}
        >
          Save
        </Button>
      </div>
    </Styled>
  );
};

export default MixSynchronize;
