import React, { useContext } from 'react';

const MapBehaviorContext = React.createContext();

export const useMapBehaviorContext = () => {
  const context = useContext(MapBehaviorContext);
  if (!context) {
    throw new Error(`useMapContext cannot be rendered outside of the MapContext context provider`);
  }
  return context;
};

const MapContextProvider = ({ value, children }) => {
  return <MapBehaviorContext.Provider value={value}>{children}</MapBehaviorContext.Provider>;
};

export default MapContextProvider;
