import React from 'react';
import cn from 'classnames';
import style from './style';
import styled from 'styled-components';
import FieldContainer from '../../../../components/field-container/FieldContainer';
import { DatePicker } from '../../../../components/date-picker';

const View = styled(FieldContainer)`
  ${style}
`;

const InputDate = ({ disabled, className, id, label, value, minDate, maxDate, onChange }) => {
  return (
    <View className={cn(id, className)}>
      <div className="label-spinner">
        <div className="label">{label}</div>
      </div>
      <DatePicker
        disabled={disabled}
        onChange={e => onChange(id, e)}
        value={value}
        minDate={minDate}
        maxDate={maxDate}
        type="date"
      />
    </View>
  );
};

export default InputDate;
