import useSetup from '../../useSetup';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import getUomAbbreviationOrDescription from '../../../../util/uom';
import MoneyRenderer from '../money-renderer';

const Styled = styled.div`
  table,
  tbody,
  tr {
    width: 100%;
  }

  table {
    margin-top: 10px;
  }
`;

const PriceBookProducts = ({ priceBookRef, typeId, itemRef }) => {
  const { getProducts, products } = useSetup();

  useEffect(() => {
    if (priceBookRef && priceBookRef !== '') {
      // if we get a typeId and itemRef, we need to search and apply the price adjustments.
      getProducts({ priceBookRef, typeId, itemRef });
    }
  }, [priceBookRef, getProducts, typeId, itemRef]);

  return (
    <Styled>
      {products && <span>{products?.length} Products In Selected Price Book</span>}
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Description</th>
            <th>UOM</th>
            <th>Price</th>
            <th>Taxable</th>
          </tr>
        </thead>
        <tbody>
          {products?.map(p => (
            <tr key={`product-${p.crn}`}>
              <td>{p.id}</td>
              <td>{p.name}</td>
              <td>{getUomAbbreviationOrDescription(p.uomCode)}</td>
              <td>
                <MoneyRenderer value={p.unitPrice} />
              </td>
              <td>{p.isTaxable ? 'Yes' : 'No'}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </Styled>
  );
};

export default PriceBookProducts;
