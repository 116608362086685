import cn from 'classnames';
import { DateTime } from 'luxon';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import Button from '../../../../../components/button/Button';
import { Translate } from '../../localization/Translate';
import { useOrderContext } from '../../useOrderContext';
import { useScheduleContext } from './ScheduleContext';
import ScheduleHeader from './ScheduleHeader';
import ScheduleLine from './ScheduleLine';
import ShowMoreScheduled from './ShowMoreScheduled';
import useSetup from '../../../../setup/useSetup';
import { useParams } from 'react-router';
import { calcTicketQuantities } from '../../tickets/calcTicketQuantities';

const Title = styled.div`
  font-size: 1.2em;
  margin-bottom: 3px;
`;

const ProductSchedule = ({ order, className, schedule, scheduleTimes }) => {
  const { entityRef } = useParams();
  const { openDrawer } = useScheduleContext();
  const { lists } = useOrderContext();

  const [canTicket, setCanTicket] = useState(false);
  const [showMoreData, setShowMoreData] = useState({});

  const { getProduct, product: primaryProduct, busy: loadingPrimaryProduct } = useSetup();

  useEffect(() => {
    if (order) {
      const statusCode = order?.supplierStatus?.statusCode || order?.supplierStatus?.name;

      setCanTicket(statusCode !== 'PENDING' && statusCode !== 'CANCELLED' && statusCode !== 'CANCELED');
    }
  }, [order]);

  const handleGetPrimaryProduct = useCallback(
    async primaryProductRef => {
      try {
        await getProduct(entityRef, primaryProductRef);
      } catch (error) {
        console.log(error);
      }
    },
    [entityRef, getProduct]
  );

  useEffect(() => {
    const existsPrimaryProduct = order?.lineItems?.find(p => p.isPrimary);
    if (!existsPrimaryProduct?.item?.productRef) return;
    handleGetPrimaryProduct(existsPrimaryProduct.item.productRef);
  }, [order?.lineItems, handleGetPrimaryProduct]);

  const {
    supplierParty: { timeZone },
  } = useOrderContext();

  const onToggleShowMore = loadNumber => {
    const data = { ...showMoreData };
    data[loadNumber] = { show: !showMoreData[loadNumber]?.show };
    setShowMoreData(data);
  };

  const filteredLocations = useMemo(() => {
    if (!lists?.locations?.list) return [];

    if (!primaryProduct?.locations || primaryProduct.locations.all) return lists?.locations?.list;

    // Users when updating the order they can select a location that the primary product selected might not be available in.
    // So we always need to include the order location in the list.
    return lists?.locations?.list.filter(
      location => primaryProduct.locations[location.crn] || order?.location?.locationRef === location.crn
    );
  }, [primaryProduct, lists, order?.location?.locationRef]);

  const loadQuantities = useMemo(() => {
    const allLoadQuantities = {};

    const smallestLoad = Math.min(...schedule.map(load => load.loadNumber));

    for (let i = smallestLoad; i < smallestLoad + schedule.length; i++) {
      const load = schedule.find(l => l.loadNumber === i);
      const loadQuantities = calcTicketQuantities(order, load.loadNumber, true, allLoadQuantities);
      allLoadQuantities[i] = loadQuantities;
    }

    return allLoadQuantities;
  }, [schedule, order]);

  const formattedTimeZone = React.useMemo(() => {
    return DateTime.fromISO(order?.deliverySchedule?.startDateTime).setZone(timeZone).toFormat('ZZZZ');
  }, [order?.deliverySchedule?.startDateTime, timeZone]);

  return (
    <div className={cn('product-schedule', className)}>
      <Title>
        <Translate
          stringId="scheduledLoads"
          defaultMessage="Scheduled Loads ({timeZone})"
          values={{ timeZone: formattedTimeZone }}
        />
        <Button size="small" onClick={openDrawer} metricId="core-order-detail-modeling" data-testid="button-modeling">
          <Translate stringId="modeling" defaultMessage="Modeling" />
        </Button>
      </Title>
      <table>
        <ScheduleHeader canTicket={canTicket} />
        <tbody>
          {schedule?.map(load => (
            <React.Fragment key={`${load.loadNumber}-load`}>
              <ScheduleLine
                key={`${load.loadNumber}-load`}
                canTicket={canTicket}
                order={order}
                load={load}
                showMoreExpanded={showMoreData[load.loadNumber]?.show ?? false}
                showMoreClick={onToggleShowMore}
                scheduleTimes={scheduleTimes}
                loadingLocations={loadingPrimaryProduct || lists?.locations?.busy}
                locations={filteredLocations}
              />
              {showMoreData[load.loadNumber]?.show && (
                <ShowMoreScheduled order={order} load={load} quantities={loadQuantities[load.loadNumber]} />
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ProductSchedule;
