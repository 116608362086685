import React from 'react';

export const prototype = {
  id: '',
  name: '',
  status: 'ACTIVE',
};

export const columns = [
  {
    key: 'id',
    name: 'ID',
  },
  {
    key: 'name',
    name: 'Name',
  },
  {
    key: 'address',
    name: 'Address',
  },
  {
    key: 'mapped',
    name: 'Mapped',
    getValue: item => (item.hasCoordinates ? 'Yes' : 'No'),
    filterComponent: ({ innerRef, ...props }) => {
      return (
        <select ref={innerRef} {...props}>
          <option value="" />
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </select>
      );
    },
  },
  {
    key: 'pricing',
    name: 'Pricing',
  },
  {
    key: 'status',
    name: 'Status',
    filterFn: (item, filterStrings) => item === filterStrings[0],
    filterComponent: ({ innerRef, ...props }) => {
      return (
        <select ref={innerRef} {...props}>
          <option value="" />
          <option value="ACTIVE">Active</option>
          <option value="INACTIVE">Inactive</option>
        </select>
      );
    },
  },
];
