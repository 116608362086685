import { InputNumber } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Select from '../../../../../components/form/Select';
import styled from 'styled-components';
import { Translate } from '../../localization/Translate';
import { useTranslateMessage } from '../../localization/useTranslateMessage';
import { useScheduleContext } from './ScheduleContext';

const { Option } = Select;

const TH = styled.th`
  width: calc(100% / 11);

  .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    & > div {
      font-size: 12px;
      min-width: 90px
    }
  }
`;

const Styled = styled.div`
  .trucks-needed {
    border-style: none;
    padding-left: 20px;
  }

  .trucks-needed .title {
    text-align: left;
  }

  .destination-times {
    padding: 0%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
  }
`;

const PlanningTimes = ({ order, scheduleTimes }) => {
  const isAggregateOrder = order.orderType === 'AGGREGATE';

  const {
    quantityRate = 60,
    spacing = 10,
    loadSize = !isAggregateOrder ? 10 : 25,
    unload = 10,
    travel = 30,
    wash = !isAggregateOrder ? 10 : 0,
    waitToLoad = 10,
    load = 10,
    waitToLeave = 10,
    waitToUnload = 10,
    waitToReturn = 10,
  } = scheduleTimes || {};

  const { changeTimes, changeStrategy, changeAvailableTrucks, updateLoadSize, updateSpacing, updateQuantityRate } =
    useScheduleContext();
  const translateMessage = useTranslateMessage();
  const trucksNeeded = order?.deliverySchedule?.model?.maxTrucks;
  const [strategy, setStrategy] = useState(order?.deliverySchedule?.strategy);
  const [availableTrucks, setAvailableTrucks] = useState(trucksNeeded);
  const hasRoute = !!order?.deliverySchedule?.route?.durationMinutes;

  const travelTime = React.useMemo(() => {
    return translateMessage({
      stringId: 'estimatedTravelTime',
      defaultMessage: 'Estimated travel time is {duration}',
      values: { duration: order?.deliverySchedule?.route?.duration },
    });
  }, [translateMessage, order?.deliverySchedule?.route?.duration]);

  useEffect(() => {
    if (strategy) {
      changeStrategy('STRATEGY', strategy);
    }
  }, [changeStrategy, strategy]);

  const handleUnitChange = useCallback(val => {
    setStrategy(val);
  }, []);

  const handleTrucksChange = useCallback(
    val => {
      setAvailableTrucks(val);
      changeAvailableTrucks('TRUCKS', val);
    },
    [changeAvailableTrucks]
  );

  const parsedSpacing = useMemo(() => {
    if (order && order?.originatorSystemType !== 'CONNEX') return order?.deliverySchedule?.deliverySpacing?.value;
    return spacing;
  }, [spacing, order]);

  return (
    <Styled>
      <table>
        <tbody>
          <tr style={{ textAlign: 'left' }}>
            <td style={{ borderStyle: 'none', width: '225px' }}>
              <div className="title" style={{ textAlign: 'left' }}>
                <Translate stringId="schedulingStrategy" defaultMessage="Scheduling Strategy" />
              </div>
              <Select
                id="select-strategy"
                className="select-spacing"
                defaultValue={'MIN'}
                value={strategy}
                onChange={handleUnitChange}
              >
                <Option value="MIN">
                  <Translate stringId="truckSpacing" defaultMessage="Truck Spacing" />
                </Option>
                <Option value="YDQ">
                  <Translate stringId="quantityPerHour" defaultMessage="Qty per Hour" />
                </Option>
                <Option value="RTT">
                  <Translate stringId="roundTrip" defaultMessage="Round Trip" />
                </Option>
                {/*<Option value="MAX">Maximum Trucks Available</Option>*/}
              </Select>
            </td>
            {(trucksNeeded || strategy === 'RTT') && (
              <td className="trucks-needed">
                <div className="title">
                  <Translate stringId="trucksNeeded" defaultMessage="Trucks Needed" />
                </div>
                <div>
                  <InputNumber
                    value={strategy !== 'RTT' ? trucksNeeded : availableTrucks}
                    min={1}
                    max={99}
                    disabled={strategy !== 'RTT'}
                    onChange={handleTrucksChange}
                  />
                </div>
              </td>
            )}
          </tr>
        </tbody>
      </table>

      <table className="planning-times">
        <thead>
          <tr>
            <th colSpan={4}>
              <Translate stringId="order1" defaultMessage="Order" />
            </th>
            <th colSpan={3}>
              <Translate
                stringId="locationTimesMinutes"
                defaultMessage="{name} Times (minutes)"
                values={{ name: order?.location?.name }}
              />
            </th>
            <th colSpan={2}>
              <Translate stringId="destinationTimesMinutes" defaultMessage="Destination Times (minutes)" />
            </th>
          </tr>
          <tr>
            <TH>
              <Translate stringId="loadSize" defaultMessage="Load Size" />
            </TH>
            {!isAggregateOrder && (
              <TH>
                <Translate stringId="spacingMin" defaultMessage="Spacing (min)" />
              </TH>
            )}
            <TH>
              <Translate stringId="quantityPerHour" defaultMessage="Qty / Hour" />
            </TH>
            <TH>
              <Translate stringId="travel" defaultMessage="Travel" />
              {hasRoute && (
                <span title={travelTime} style={{ marginLeft: '5px' }}>
                  <i className="fa fa-map" />
                </span>
              )}
            </TH>

            <TH>
              <Translate stringId="waitToLoad" defaultMessage="Wait to Load" />
            </TH>
            <TH>
              <Translate stringId="load" defaultMessage="Load" />
            </TH>
            <TH>
              <Translate stringId="waitToLeave" defaultMessage="Wait to Leave" />
            </TH>

            {/* <TH>Wait to Unload</TH> */}
            <TH>
              <div className="title">
                <div>
                  <Translate stringId="timeToPour" defaultMessage="Time to Pour" />
                </div>

                <div>
                  <Translate stringId="unload" defaultMessage="Unload" />
                </div>
              </div>
            </TH>

            <TH>
              <div className="title">
                <div>
                  <Translate stringId="timeToReturn" defaultMessage="Time To Return" />
                </div>
                <div>{!isAggregateOrder && <Translate stringId="wash" defaultMessage="Wash" />}</div>
              </div>
            </TH>
            {/* <TH>Wait to Return</TH> */}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <InputNumber
                min={0.01}
                max={!isAggregateOrder ? 20 : 50}
                defaultValue={loadSize}
                onChange={value => updateLoadSize(value)}
                data-testid={`input-load-size`}
              />
            </td>

            {!isAggregateOrder && (
              <td>
                <InputNumber
                  disabled={strategy !== 'MIN' && strategy !== 'RTT'}
                  min={1}
                  max={960}
                  value={parsedSpacing}
                  onChange={value => updateSpacing(value)}
                  data-testid={`input-spacing`}
                />
              </td>
            )}
            <td>
              <InputNumber
                disabled={strategy !== 'YDQ'}
                min={1}
                max={480}
                value={quantityRate}
                onChange={value => updateQuantityRate(value)}
                data-testid={`label-qty-hour`}
              />
            </td>
            <td>
              <InputNumber
                data-testid={`input-travel`}
                min={1}
                max={90}
                value={travel}
                onChange={value => changeTimes('travel', value)}
              />
            </td>
            <td>
              <InputNumber
                min={1}
                max={60}
                defaultValue={waitToLoad}
                onChange={value => changeTimes('waitToLoad', value)}
                data-testid={`input-wait-to-load`}
              />
            </td>
            <td>
              <InputNumber
                data-testid={`input-load`}
                min={1}
                max={60}
                defaultValue={load}
                onChange={value => changeTimes('load', value)}
              />
            </td>
            <td>
              <InputNumber
                min={1}
                max={60}
                defaultValue={waitToLeave}
                onChange={value => changeTimes('waitToLeave', value)}
                data-testid={`input-wait-to-leave`}
              />
            </td>
            <td>
              <div className="destination-times">
                <InputNumber
                  data-testid={`input-wait-to-unload`}
                  min={1}
                  max={60}
                  defaultValue={waitToUnload}
                  onChange={value => changeTimes('waitToUnload', value)}
                />

                <InputNumber
                  data-testid={`input-to-unload`}
                  min={1}
                  max={360}
                  defaultValue={unload}
                  onChange={value => changeTimes('unload', value)}
                />
              </div>
            </td>
            <td>
              <div className="destination-times">
                <InputNumber
                  data-testid={`input-wait-to-return`}
                  min={1}
                  max={60}
                  defaultValue={waitToReturn}
                  onChange={value => changeTimes('waitToReturn', value)}
                />
                {!isAggregateOrder && (
                  <InputNumber
                    data-testid={`input-wash`}
                    min={1}
                    max={60}
                    defaultValue={wash}
                    onChange={value => changeTimes('wash', value)}
                  />
                )}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </Styled>
  );
};

export default PlanningTimes;
