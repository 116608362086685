import React, { useContext } from 'react';
import { get } from 'lodash';

const UserContext = React.createContext();

export const useUserContext = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error(`useUserContext cannot be rendered outside of the UserContext context provider`);
  }
  return context;
};

const UserContextProvider = ({ children, user }) => {
  const hasPermissions = React.useCallback(
    (permissionOrPermissions, mustHaveAll) => {
      const isPlatformAdmin = user?.permissions?.groups?.includes?.('admin');
      const isPlatformSupport = user?.permissions?.groups?.includes?.('support');
      const isCompanyAdmin = !!user?.permissions?.assignedRoles?.['company-admin'];

      const strategy = mustHaveAll ? 'every' : 'some';

      const requiredPermissions = [].concat(permissionOrPermissions);

      const permissions = requiredPermissions.map(permission => {
        return (
          isPlatformSupport || isPlatformAdmin || isCompanyAdmin || get(user?.permissions, permission, false) === true
        );
      });

      return permissions[strategy](x => x === true);
    },
    [user]
  );

  const hasPermissionNoAdmin = React.useCallback(
    (permissionOrPermissions, mustHaveAll) => {

      const strategy = mustHaveAll ? 'every' : 'some';

      const requiredPermissions = [].concat(permissionOrPermissions);

      const permissions = requiredPermissions.map(permission => {
        return (
          get(user?.permissions, permission, false) === true
        );
      });

      return permissions[strategy](x => x === true);
    },
    [user]
  );

  return <UserContext.Provider value={{ ...user, hasPermissions, hasPermissionNoAdmin }}>{children}</UserContext.Provider>;
};

export default UserContextProvider;
