import { css } from 'styled-components';

export default css`
  width: 100%;

  .field-container {
    margin-top: 10px;
  }

  .spacing {
    height: 10px;
  }
`;
