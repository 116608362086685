import React, { useMemo } from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { sortBy } from 'lodash';
import FieldContainer from '../../field-container/FieldContainer';
import Input from '../../form/Input';

const { TextArea } = Input;

const MostRecentNote = ({ className, notes }) => {
  const mostRecentNote = useMemo(() => {
    if (!notes?.length) return null;

    const sorted = sortBy(notes, [n => n.timestamp]);
    return sorted.reverse()[0];
  }, [notes]);

  return !mostRecentNote ? null : (
    <div className={cn('most-recent-note', className)}>
      <div className="label-spinner">
        <div className="label">Most Recent Note</div>
      </div>
      <TextArea value={mostRecentNote.message} readOnly data-testid="most-recent-note_textarea" />
    </div>
  );
};

export default styled(MostRecentNote)``;
