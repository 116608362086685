import { Tooltip } from 'antd';
import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import SupplierStatusTooltip from '../../../order-list/components/order-cards/ticketed/SupplierStatusTooltip';
import StatusIndicator from '../../../order-list/components/order-cards/loads/StatusIndicator';

const Styled = styled.div`
  display: flex;
  position: relative;
  .ticket-id {
    color: #3c93e2;
    cursor: pointer;
  }
  .icon {
    position: absolute;
    right: 5px;
    .voided,
    .cancelled {
      color: #f77e7e;
    }

    .accepted {
      color: #8ce695;
    }

    .rejected {
      color: #f77e7e;
    }
  }
`;
const TicketIdAndStatus = ({ load }) => {
  return (
    <Styled className={cn('ticket-id-and-status')}>
      <div className="ticket-id">{load?.ticket?.id}</div>
      <div style={{ padding: '10px', marginLeft: '45px', position: 'absolute' }}>
        <StatusIndicator load={load} />
      </div>
    </Styled>
  );
};

export default TicketIdAndStatus;
