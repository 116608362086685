import React, { useEffect, useMemo } from 'react';
import cn from 'classnames';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import Spinner from '../../../components/spinner/Spinner';
import { useForm } from '../../../hooks/useForm';
import { useHash } from './edit-order/useHash';
import style from './style';
import { useOrder } from './useOrder';
import ViewOrder from './view-order/ViewOrder';
import EditOrder from './edit-order/EditOrder';

const ViewOrEditOrder = ({ className }) => {

  // TODO: when allowEdit flag is present on the orderList, all this behavior can
  // move back into the useUpdateOrderRequest hook.

  const { orderId } = useParams();

  const order = useOrder();
  const form = useForm({});

  useEffect(() => {
    order.getOrder(orderId);
  }, [order.getOrder]);

  useEffect(() => {
    if (order.order) {
      form.setForm(order.order);
      hash.initialize(order.order);
    }
  }, [order.order]);

  const allowEdit = useMemo(() => order?.order?.allowEdit, [order.order]);

  const hash = useHash();
  const isDirty = useMemo(() => hash.hasChanged(form.form), [form.form, hash]);

  const isValid = useMemo(() => {
    const f = form.form;
    return f?.supplier?.crn && f?.product?.productRef && f?.startDateTime && f?.quantity;
  }, [form.form]);

  return (
    <div className={cn(className)} style={{ padding: '10px' }}>
      <Spinner spin={order.orderBusy}>
        {allowEdit ? (
          <EditOrder order={order} form={form} isDirty={isDirty} isValid={isValid} />
        ) : (
          <ViewOrder order={order} form={form} />
        )}
      </Spinner>
    </div>
  );
};

export default styled(ViewOrEditOrder)`
  ${style}
`;
