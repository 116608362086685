import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
import { Date } from '../../../components/localization/Date';
import { Time } from '../../../components/localization/Time';
import { useOrderListContext } from '../../../components/OrderListContext';
import { isFuture } from '../date-filter-bar/isTomorrow';

const Styled = styled.div`
  display: flex;
  grid-gap: 10px;
  @media (max-width: 450px) {
    flex-direction: column;
    grid-gap: 0;
  }
  .container {
    display: grid;
    grid-gap: 2px;
  }
`;

const OrderDateTime = ({ isoDateTime, timeZone, dataTestId, showYear, hideTimeZone }) => {
  const { quickDate } = useOrderListContext();

  return (
    <Styled data-testid={dataTestId || 'date-time'} className={cn('date-time')}>
      <div className="container">
        <div className="time">
          <Time date={isoDateTime} timeZone={timeZone} hideTimeZone={hideTimeZone} testId={dataTestId} />
        </div>
        {isFuture(quickDate) && (
          <div className="date">
            <Date date={isoDateTime} showYear={showYear} />
          </div>
        )}
      </div>
    </Styled>
  );
};

export default OrderDateTime;
