import { css } from 'styled-components';

export default css`
  width: 100%;

  .ml-2 {
    margin-left: 30px;
  }

  .field-container {
    margin-top: 5px;
  }

  .ant-btn {
    margin-top: 10px;
  }
`;
