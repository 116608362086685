import styled from 'styled-components';

import style from './style';

const Styled = styled.div`
  ${style}
`;

const ChargeWarning = ({ message }) => {
  return (
    <Styled>
      <div className={'warning-icon'}>
        <i className={'fas fa-exclamation-triangle'} style={{ color: '#eed202' }}></i>
      </div>
      <div className={'warning-text'}>{message}</div>
    </Styled>
  );
};

export default ChargeWarning;
