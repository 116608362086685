import { css } from 'styled-components';

export default css`
  width: 100%;

  .ant-btn {
    margin-top: 10px;
  }

  .form-group-label {
    margin-bottom: 5px;
  }

  .form-group {
    margin-bottom: 10px;
  }
`;
