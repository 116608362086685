import {css} from 'styled-components';
import getGradient from "../../utils/get-gradient";

export default css`
  height: 20px;
  color: black;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  display: flex;
  flex-direction: row;
  flex: 1;
  text-overflow: ellipsis;
  width: 100%;
  min-width: 0;
  min-height: 0;

  .tracking-line {
    display: flex;
    flex-direction: row;
    flex: 1;
    width: 100%;
  }


  .data-column {
    white-space: nowrap;
    min-height: 0;
    min-width: 0;
    height: 100%;
    border-left: 2px #000000 solid;
    border-left-width: 2px;
    border-color: black;
    display: flex;
    padding: 0;
    flex-direction: column;

    span {
      margin-left: 2px;
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
  
  .wrapped {
    flex: 1;
  }

  .data-column:first-child {
    border-left: 0;
  }

  .collapse-container {
    text-align: center;
  }

  .timer-late {
    height: 100%;
    background: ${() => getGradient('red')};
    color: white;
  }
`
