import {css} from 'styled-components';

export default css`

width: 300px;

&&& {    
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
`;
