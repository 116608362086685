import style from './style';
import React from 'react';
import InputQuantity from '../input-quantity';
import SelectUOM from '../select-uom';
import styled from 'styled-components';

const View = styled.div`
  ${style}
`;

const MaxBatchSize = ({ item, onChange }) => {
  return (
    <View>
      <table>
        <tbody>
          <tr>
            <td>
              <div style={{ marginTop: '6px' }}>
                <InputQuantity
                  id="maxBatchSize"
                  label="Maximum Batch Size"
                  min={0}
                  value={item?.maxBatchSize}
                  onChange={onChange}
                />
              </div>
            </td>
            <td style={{ width: '310px', padding: '3px' }}>
              <SelectUOM
                id="maxBatchSizeUOM"
                title="Unit of Measure"
                uomCode={item?.maxBatchSizeUOM}
                onChange={onChange}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </View>
  );
};

export default MaxBatchSize;
