import Image from './Image';
import Input from './Input';
import Label from './Label';
import List from './List';
import Map from './Map';
import React from 'react';
import TextArea from "./TextArea";

const Fields = {
  image: Image,
  input: Input,
  label: Label,
  list: List,
  'text-area': TextArea,
  map: Map,
}

const FormField = ({item, field, fieldValue }) => {
  const {type, value} = field;

  const FieldComponent = Fields[type] || Input;

  return <FieldComponent item={item} fieldValue={fieldValue} field={field} />;
};

export default FormField;
