import React, {useCallback, useEffect, useMemo} from "react";
import useSetup from "../../useSetup";
import Select from "../../../../components/form/Select";
import styled from "styled-components";
import {find} from "lodash";

const Title = styled.div`
  margin-bottom: 3px;
`;

const {Option} = Select;

const setupItemName = 'carrier';
const setupItemRefName = 'carrierRef';

const SelectCarrier = ({item, onChange, required}) => {
  const {getCarriers, carriers, busy} = useSetup();

  const value = item?.carrierParty?.carrierRef || null;

  const changeCarrier = useCallback((value) => {
    const carrierParty = find(carriers, c => c.crn === value) || null;
    if(carrierParty) {
      onChange('carrierParty', {
        carrierRef: carrierParty.crn,
        id: carrierParty.id,
        name: carrierParty.name,
      })
    } else {
      onChange('carrierParty', null);
    }

  }, [onChange, carriers]);

  useEffect(() => {
    if(!busy && item) {
      if(carriers.length > 0 && !item?.carrierParty?.carrierRef) {
        const defaultCarrier = find(carriers, c => c.companyFleet === true);
        if(defaultCarrier?.crn){
          changeCarrier(defaultCarrier.crn);
        }
      }
    }
  }, [busy, item, changeCarrier])

  useEffect(() => {
    getCarriers().catch(console.error);
  }, []);

  const handleSearch = useCallback(value => {

  }, []);

  return (
    <div>
      <Title>{'Carrier' + (required ? ' *' : '')}</Title>
      <Select
        data-testid={`select-carrier`}
        id={setupItemRefName}
        value={value}
        onChange={changeCarrier}
        onSearch={handleSearch}
        showSearch
        optionFilterProp="children"
        filterOption={(input, option) =>
          option?.children?.toLowerCase?.().indexOf?.(input?.toLowerCase?.()) >= 0
        }
        filterSort={(optionA, optionB) =>
          optionA?.children?.toLowerCase?.()?.localeCompare?.(optionB?.children?.toLowerCase?.())
        }
      >
        {carriers?.map(item => {
          let label = item.name;

          const values = [];
          item.id && values.push(item.id);
          item.name && values.push(item.name);
          label = values.join(' / ');

          return (
            <Option key={item.crn} value={item.crn}>
              {label}
            </Option>
          );
        })}
      </Select>
    </div>
  );


}

export default SelectCarrier;
