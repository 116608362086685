import React from 'react';
import { useParams } from 'react-router-dom';
import { useOrderContext } from '../../../useOrderContext';
import { useMapStateContext } from '../../context/MapStateContext';
import { usePollTrucks } from '../usePollTrucks';
import { useFitBounds } from '../useFitBounds';
import useOrderMap from './useOrderMap';
import _initializeExistingOrder from './initializeExistingOrder';
import _initializeNewOrder from './initializeNewOrder';
import { useReverseGeocode } from './useReverseGeocode';

export const useInitialize = ({ initialForm }) => {
  const form = React.useRef(initialForm);

  const { initializeEntryMap, initializeTrackingMap } = useOrderMap();
  const { lists } = useOrderContext();

  const {
    setConfig,
    setTrucks,
    setInitializing,
    setGeocodingAddress,
    setEffectiveCoordinates,
    setEffectiveAddress,
    setOrigin,
    setDestination,
    setWaypoints,
    setRoute,
    map,
    isNewOrder,
    readOnly,
  } = useMapStateContext();

  const { orderId } = useParams();
  const { fitBounds } = useFitBounds();
  const { startPolling, stopPolling } = usePollTrucks();
  const reverseGeocode = useReverseGeocode();

  React.useEffect(() => {
    return () => {
      stopPolling();
    };
  }, [stopPolling]);

  const _initialize = React.useMemo(() => {
    return isNewOrder ? _initializeNewOrder : _initializeExistingOrder;
  }, [isNewOrder]);

  const initialize = React.useCallback(async () => {
    _initialize({
      readOnly,
      startPolling,
      initializeTrackingMap,
      initializeEntryMap,
      orderId,
      setInitializing,
      setGeocodingAddress,
      setEffectiveAddress,
      setTrucks,
      setEffectiveCoordinates,
      setOrigin,
      setDestination,
      setWaypoints,
      setRoute,
      fitBounds,
      setConfig,
      reverseGeocode,
      form: form.current,
      lists,
      map,
    });
  }, [
    _initialize,
    readOnly,
    startPolling,
    initializeTrackingMap,
    initializeEntryMap,
    orderId,
    setInitializing,
    setGeocodingAddress,
    setEffectiveAddress,
    setTrucks,
    setEffectiveCoordinates,
    setOrigin,
    setDestination,
    setWaypoints,
    setRoute,
    fitBounds,
    setConfig,
    reverseGeocode,
    lists,
    map,
  ]);

  React.useEffect(() => {
    if (map) {
      initialize();
    }
  }, [initialize, map]);

  return { initialize };
};
