import cn from 'classnames';
import ProductCard from './ProductCard';
import React, { useMemo } from 'react';
import { useScheduleContext } from './ScheduleContext';

const Products = ({ className }) => {
  const { order } = useScheduleContext();

  const primaryLineItem = useMemo(() => {
    return order?.lineItems?.find(i => i.isPrimary === true);
  }, [order?.lineItems]);

  return (
    <div className={cn('product-schedule', className)}>
      <ProductCard order={order} lineItem={primaryLineItem} />
    </div>
  );
};

export default Products;
