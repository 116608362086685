import React from 'react';

const OriginInfoWindow = ({ origin }) => {
  return (
    <div>
      <span style={{ fontWeight: 'bold' }}>Plant:</span> <span>{origin?.info?.[0]?.desc}</span>
    </div>
  );
};

export default OriginInfoWindow;
