import { cloneDeep, isNil } from 'lodash';
import { useMemo } from 'react';
import styled from 'styled-components';
import { InputNumber } from 'antd';
import { Translate } from '../../localization/Translate';
import { PER_QTY_UOM_CODES, PER_100_WEIGHT_UOM_CODES } from '../../tickets/calcTicketQuantities';
import { useScheduleContext } from './ScheduleContext';

const Row = styled.tr`
  .inner-table {
    max-width: 800px;
    margin-bottom: 0;
  }
`;

const ShowMoreScheduled = ({ order, load, quantities }) => {
  const { overrides, changeScheduleLine } = useScheduleContext();

  const handleQtyChange = (productRef, value) => {
    const associatedProducts = overrides?.[load.loadNumber]?.associatedProducts || {};
    associatedProducts[productRef] = value;
    changeScheduleLine(load.loadNumber, 'associatedProducts', associatedProducts);
  };

  const overridedQuantities = useMemo(() => {
    const quantitiesClone = cloneDeep(quantities);
    Object.keys(quantitiesClone?.associatedProductQuantities || {}).forEach(productId => {
      if (!isNil(overrides?.[load.loadNumber]?.associatedProducts?.[productId])) {
        quantitiesClone.associatedProductQuantities[productId] =
          overrides?.[load.loadNumber]?.associatedProducts?.[productId];
      }
    });
    return quantitiesClone;
  }, [quantities, load.loadNumber, overrides]);

  return (
    <Row key={`${load.loadNumber}-scheduled-load`}>
      <td colSpan={99}>
        <table className="inner-table">
          <thead>
            <tr>
              <th>
                <Translate stringId="product" defaultMessage="Product" />
              </th>
              <th>
                <Translate stringId="idDescription" defaultMessage="ID / Description" />
              </th>
              <th>
                <Translate stringId="quantity" defaultMessage="Quantity" />
              </th>
              <th>
                <Translate stringId="uom" defaultMessage="UOM" />
              </th>
            </tr>
          </thead>
          <tbody>
            {order.lineItems
              .filter(li => li.isPrimary)
              .map(li => (
                <tr key={`line-item-${li.item?.id}`}>
                  <td>{li.item?.type}</td>
                  <td>
                    {li.item?.id} / {li.item?.name}
                  </td>
                  <td>{overridedQuantities?.loadQuantity ?? 0.0}</td>
                  <td>
                    <Translate stringId={`UOM_ABBR__${li.item?.uomCode || 'UNKNOWN'}`} />
                  </td>
                </tr>
              ))}
            {
              // Minimum Load charge products with zero qty should not be displayed
              order.lineItems
                .filter(
                  li =>
                    !li.isPrimary &&
                    (!li?.item?.minimumLoadCharge ||
                      overridedQuantities?.associatedProductQuantities?.[li.item?.productRef] > 0)
                )
                .map(li => (
                  <tr key={`line-item-${li.item?.id}`}>
                    <td>{li.item?.type}</td>
                    <td>
                      {li.item?.id} / {li.item?.name}
                    </td>
                    <td>
                      <InputNumber
                        min={0}
                        value={overridedQuantities?.associatedProductQuantities?.[li.item?.productRef] ?? 0.0}
                        onChange={val => handleQtyChange(li.item?.productRef, val)}
                        disabled={li?.item?.minimumLoadCharge || PER_100_WEIGHT_UOM_CODES.includes(li.item?.uomCode)}
                      />
                    </td>
                    <td>
                      {!PER_QTY_UOM_CODES.includes(li.item?.uomCode) && !PER_100_WEIGHT_UOM_CODES.includes(li.item?.uomCode) && (
                        <Translate stringId={`UOM_ABBR__${li.item?.uomCode || 'UNKNOWN'}`} />
                      )}
                    </td>
                  </tr>
                ))
            }
          </tbody>
        </table>
      </td>
    </Row>
  );
};

export default ShowMoreScheduled;
