import React, { useEffect, useState } from 'react';
import InputSelect from '../input-select';

const SelectConcreteType = ({ item, type, label, options, onChange }) => {
  const changeValue = (id, value) => {
    onChange(id, value);
  };

  const [values, setValues] = useState([]);

  useEffect(() => {
    setValues([
      { value: '', label: `Select one...` },
      ...(options
        ?.filter(s => s.type === type)
        ?.map(i => {
          return i;
        }) ?? []),
    ]);
  }, [options]);

  return (
    <InputSelect id={`${type}Ref`} label={label} value={item?.[`${type}Ref`]} onChange={changeValue} options={values} />
  );
};

export default SelectConcreteType;
