import { Input as AntInput, Form } from 'antd';
import React from 'react';
import styled from 'styled-components';

const FormItem = Form.Item;
const AntTextArea = AntInput.TextArea;

const TextArea = ({ className, fieldValue, field }) => {
  const {label, value} = field;
  return <FormItem className={className} label={label}>
    <AntTextArea data-testid={value.substring(value.indexOf('.') + 1)} style={{resize: 'none'}} rows={field.rows || 2} value={fieldValue} disabled={true} size={false} />
  </FormItem>;
};

export default styled(TextArea)`
  &&& {
    .ant-input[disabled] {
      background-color: #424344;
      color: white;
      border: 1px solid #58595B !important;
    }
  }
`;
