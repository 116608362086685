import {css} from 'styled-components';

export default css`
  padding: 10px;
  overflow: hidden;

  table {
    width: 100%;

    thead {
      tr {
        background-color: #333;

        th {
          border: 1px solid #666;
          padding: 3px 10px;
        }
      }
    }

    tbody {
      tr {
        td {
          padding: 3px 10px;
          border: 1px solid #666;
        }
      }
    }
  }
`;
