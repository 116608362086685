import moment from 'moment';
import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { api } from '../../api';

const transformToPostData = form => {
  return {
    version: '2.0',
    crn: form.crn,
    job: {
      jobRef: form?.job?.jobRef,
      id: form?.job?.id,
      description: form?.job?.description,
    },
    contact: form.contact,
    notes: form.notes,
    description: form.description,
    directions: form.directions,
    orderDiscipline:
      form?.discipline && form?.discipline !== ''
        ? {
            disciplineRef: form?.discipline?.disciplineRef,
            id: form?.discipline?.id,
            description: form?.discipline?.description,
          }
        : undefined,
    pourDescription:
      form?.pourDescription && form?.pourDescription !== ''
        ? {
            pourDescriptionRef: form?.pourDescription?.pourDescriptionRef,
            description: form?.pourDescription?.description,
          }
        : undefined,
    deliverySchedule: {
      startDateTime: moment(form.startDateTime).local().toISOString(),
      finishDateTime: moment(form.startDateTime).local().toISOString(),
      deliverySpacing: form.spacing,
      deliverySpacingUnits: form.spacingUnits,
    },
    lineItems: [
      {
        item: {
          productRef: form.product.productRef,
        },
        orderedQuantity: {
          value: form.quantity,
          uomCode: form.product.uomCode,
        },
        properties: {
          slump: form.slump,
          pourType: form.pourType,
        },
      },
    ],
    copies: form?.emailCopies,
    pourCardNumber: form?.pourCardNumber,
  };
};
export const useUpdateOrder = () => {
  const { entityRef } = useParams();
  const [updatedOrder, setUpdatedOrder] = useState();
  const [updateOrderBusy, setUpdateOrderBusy] = useState(false);

  const updateOrder = useCallback(
    formData => {
      const postData = transformToPostData(formData);

      setUpdatedOrder(null);
      setUpdateOrderBusy(true);

      return api
        .updateOrderRequest(entityRef, formData.supplier.crn, postData)
        .then(response => {
          setUpdatedOrder(response);
          return response;
        })
        .finally(() => {
          setUpdateOrderBusy(false);
        });
    },
    [entityRef]
  );

  return { updateOrder, updateOrderBusy, updatedOrder };
};
