import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { Checkbox } from '../../../../components/checkbox';
import Spinner from '../../../../components/spinner/Spinner';

const Styled = styled.div``;
const Plants = ({ locations, plants, onChange, locationBusy }) => {
  return (
    <Styled className={cn('plants-section')}>
      <div className="plants-header">
        <div className="label">Select Plants That Are Available To This Role</div>
      </div>

      <div className="plants">
        <Spinner spin={locationBusy}>
          <table>
            <tbody>
              <tr>
                <td>
                  <Checkbox
                    id={'all-plants'}
                    onChange={e => onChange('all', e.target.checked)}
                    checked={plants?.all === true}
                  />
                </td>
                <td>All Plants (Overrides anything checked below. New plants will be automatically visible.)</td>
              </tr>
              {locations?.map?.(l => (
                <tr key={l.crn}>
                  <td>
                    <Checkbox
                      id={l.id}
                      onChange={e => onChange(l.crn, e.target.checked)}
                      checked={plants?.[l.crn] === true}
                    />
                  </td>
                  <td>
                    <div>
                      {l.id} / {l.name}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Spinner>
      </div>
    </Styled>
  );
};

export default Plants;
