import React, { useMemo } from "react";
import styled from 'styled-components';
import style from './style';

const IntervalLines = ({ graphData }) => {
  const intervals = useMemo(() => {
    if(!graphData?.intervals) {
      return [];
    }

    const result = [];
    for(let i = graphData?.intervals.length - 1; i >= 0; i--) {
      result.push(graphData?.intervals[i]);
    }
    return result;
  }, [graphData?.intervals]);
  return <div className={'mark-wrapper'}>
    {intervals?.map?.(interval => {
      return <div key={`interval_${interval.value}`} className="mark" style={{ height: `${interval.height}px` }} />;
    })}
  </div>
};

export default styled(IntervalLines)`
  ${style}
`;
