import { useCallback, useMemo, useRef } from 'react';
import styled from 'styled-components';
import style from './style';
import ChargeRow from './components/charge-row/index.js';
import Button from '../../../../components/button/Button';
import { isNil } from 'lodash';
import ChargeWarning from './components/charge-warning';
import { Translate } from '../../../../components/localization/Translate'
import { useTranslateMessage } from '../../../../components/localization/useTranslateMessage'

const Styled = styled.div`
  ${style}
`;

const checkIfLocationHasZoneCharges = (hasZoneCharges, zoneChargeData) => {
  if (!hasZoneCharges) return false;
  if (!zoneChargeData?.charges?.length) return false;
  return !zoneChargeData?.charges?.every(c => isNil(c?.loadSize) && isNil(c?.charge));
};

const LocationZoneCharge = ({ zoneChargeData, onChange }) => {
  const clearAndSortChargesBtnRef = useRef(null);
  const addChargeRowBtnRef = useRef(null);
  const translateMessage = useTranslateMessage();

  const updateZoneChargeData = useCallback(
    (id, value) => {
      const tempzoneChargeData = zoneChargeData ? { ...zoneChargeData } : {};

      tempzoneChargeData[id] = value;

      onChange('zoneChargeData', tempzoneChargeData);
    },
    [zoneChargeData, onChange]
  );

  const addChargeRow = useCallback(() => {
    const tempCharges = zoneChargeData?.charges || [];
    tempCharges.push({ distance: null, charge: null });
    updateZoneChargeData('charges', [...tempCharges]);
    addChargeRowBtnRef.current.blur();
  }, [zoneChargeData, updateZoneChargeData]);

  const deleteChargeRow = useCallback(
    index => {
      const tempCharges = zoneChargeData?.charges;
      tempCharges.splice(index, 1);
      updateZoneChargeData('charges', [...tempCharges]);
    },
    [zoneChargeData, updateZoneChargeData]
  );

  const updateChargeRow = useCallback(
    (index, key, value) => {
      const tempCharges = zoneChargeData?.charges;
      tempCharges[index][key] = value;
      updateZoneChargeData('charges', [...tempCharges]);
    },
    [zoneChargeData, updateZoneChargeData]
  );

  const clearAndSortCharges = useCallback(() => {
    if (!zoneChargeData?.charges) return;

    const loadSizeSet = new Set();

    const tempCharges = [];

    zoneChargeData?.charges.forEach(c => {
      if (isNil(c.distance) || isNil(c.charge) || loadSizeSet.has(c?.distance)) return;

      loadSizeSet.add(c?.distance);
      tempCharges.push(c);
    });

    updateZoneChargeData(
      'charges',
      tempCharges.sort((a, b) => a.distance - b.distance)
    );

    clearAndSortChargesBtnRef.current.blur();
  }, [zoneChargeData, updateZoneChargeData]);

  const chargeWarning = useMemo(() => {
    let showWarning = false;

    const loadSizeSet = new Set();
    zoneChargeData?.charges?.forEach(c => {
      if (isNil(c?.distance)) return;
      if (loadSizeSet.has(c?.distance)) {
        showWarning = true;
      } else {
        loadSizeSet.add(c?.distance);
      }
    });

    if (!!zoneChargeData?.charges?.find(c => isNil(c?.distance) || isNil(c?.charge))) {
      showWarning = true;
    }

    return showWarning
      ? translateMessage({defaultMessage: 'Charge rows with empty values or duplicated load size rows are going to be deleted when saving', stringId: 'chargeWarning'})
      : null;
  }, [zoneChargeData]);

  const emptyWarning = useMemo(() => {
   return checkIfLocationHasZoneCharges(true, zoneChargeData) ? null : translateMessage({defaultMessage: 'Charges table must have a valid row', stringId: 'emptyCharges'});
  }, [zoneChargeData]);

  return (
    <Styled>
    <h4 className="section-text"><Translate defaultMessage="Zone Charges" stringId="zoneCharges"/></h4>
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th style={{ width: '45%' }}><Translate defaultMessage="Zone Range (miles)" stringId="zoneRange"/></th>
              <th style={{ width: '45%' }}><Translate defaultMessage="Charge" stringId="charge"/></th>
              <th style={{ width: '10%' }}></th>
            </tr>
          </thead>
          <tbody>
            {zoneChargeData?.charges?.map((charge, index) => (
              <ChargeRow
                key={index}
                index={index}
                chargeValues={charge}
                onChange={updateChargeRow}
                onDelete={deleteChargeRow}
              />
            ))}
          </tbody>
        </table>
      </div>
      <div className="buttons-container">
        <Button innerRef={clearAndSortChargesBtnRef} onClick={clearAndSortCharges}>
          <Translate defaultMessage="Clear and Sort" stringId="clearAndSort"/>
        </Button>
        <Button innerRef={addChargeRowBtnRef} onClick={addChargeRow}>
          <Translate defaultMessage="Add Charge Row" stringId="addCharge"/>
        </Button>
      </div>
      {chargeWarning && <ChargeWarning message={chargeWarning} />}
      {emptyWarning && <ChargeWarning message={emptyWarning} />}
    </Styled>
  );
};

export default LocationZoneCharge;
