import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import FlexColumn from '../../order/components/FlexColumn';
import useSetup from '../useSetup';
import { useParams } from 'react-router';
import style from './style';
import SetupTable from '../components/setup-table';
import VehicleEditor from './vehicle-editor';
import moment from 'moment';
import { FILTER_STRATEGY } from '../../../components/connex-data-grid/util';
import ImportExport from '../components/import-export/ImportExport';

const prototype = {
  id: '',
  name: '',
  status: 'ACTIVE',
};

const columns = [
  {
    key: 'carrierParty.name',
    name: 'Carrier',
  },
  {
    key: 'id',
    name: 'ID',
    required: true,
  },
  {
    key: 'name',
    name: 'Name',
    required: true,
  },
  {
    key: 'vehicleType.name',
    name: 'Type',
  },
  {
    key: 'vehicleStatus',
    name: 'Vehicle Status',
    getValue: item => item.vehicleStatus?.statusCode || item.status || '',
  },
  {
    key: 'vehicleStatus.eventDateTime',
    name: 'Status Time',
    getValue: row =>
      row.vehicleStatus?.eventDateTime
        ? moment(row.vehicleStatus?.eventDateTime).format('L hh:mm A')
        : moment(row.modifyDate).format('L hh:mm A'),
  },
  {
    key: 'status',
    name: 'Status',
    filterStrategy: FILTER_STRATEGY.EQUALS,
    filterComponent: ({ innerRef, ...props }) => {
      return (
        <select ref={innerRef} {...props}>
          <option value="" />
          <option value="ACTIVE">Active</option>
          <option value="INACTIVE">Inactive</option>
        </select>
      );
    },
  },
];

const ImportExportWrapped = props => {
  const { downloadFile, uploadFile } = useSetup();

  return (
    <ImportExport
      {...props}
      downloadFile={downloadFile}
      uploadFile={uploadFile}
      item={{ typeId: 'vehicle', crn: 'all', name: 'vehicles-list' }}
    />
  );
};

const Styled = styled(FlexColumn)`
  ${style}
`;

const SetupVehicles = () => {
  const { entityRef } = useParams();
  const { getVehicles, vehicles, busy } = useSetup();
  const params = useMemo(() => ({ activeOnly: false }), []);

  useEffect(() => {
    entityRef && getVehicles(params);
  }, [entityRef, getVehicles, params]);

  return (
    <Styled>
      <SetupTable
        busy={busy}
        type="Vehicle"
        columns={columns}
        items={vehicles}
        Editor={VehicleEditor}
        prototype={prototype}
        onRefresh={() => getVehicles(params)}
        actions={ImportExportWrapped}
      />
    </Styled>
  );
};

export default SetupVehicles;
