import { sortBy } from 'lodash';
import { DateTime } from 'luxon';

export default ({ timeZone, order }) => {

  const loads = order.deliverySchedule.schedule.map(schedule => ({
    loadNumber: schedule.loadNumber,
    ...schedule.ticketEvents,
  }));

  const sorted = sortBy(loads, 'loadNumber');

  const firstPrinted = sorted[0]?.PRINTED;
  const lastInYard = sorted[sorted.length - 1]?.IN_YARD;

  const first = DateTime.fromISO(firstPrinted);
  const last = DateTime.fromISO(lastInYard);

  const difference = last.diff(first, 'minutes')?.toObject();

  const midDifferenceInMinutes = Math.round(difference.minutes / 2);

  const midTime = first.plus({ minutes: midDifferenceInMinutes }).setZone(timeZone);

  return midTime;
};
