import cn from 'classnames';
import { groupBy } from 'lodash';
import { useState, useMemo, useCallback } from 'react';
import styled from 'styled-components';
import { ConnexDataGrid } from '../../../components/connex-data-grid/components';
import { useTranslateObjects } from '../../order/components/localization/translate-objects/useTranslateObjects';
import FlexColumn from '../components/FlexColumn';
import { useColumns } from './column-definition/column-def-grouped';
import flattenInvoices from './flattenInvoices';
import QuickDates from './quick-dates/QuickDates';
import style from './style';
import { useInvoices } from './useInvoices';
import ExportToCsv from '../../../components/export/ExportToCsv';
import UnInvoice from './un-invoice';
import { isEmpty } from 'lodash';

const Styled = styled(FlexColumn)`
  ${style}
`;

const InvoicedList = props => {
  const { invoices, busy, listInvoices } = useInvoices();
  const [expandedGroupIds, setExpandedGroupIds] = useState(new Set([]));

  const [selected, setSelected] = useState(null);

  const [selectedDate, setSelectedDate] = useState(null);
  const [customDateRange, setCustomDateRange] = useState([]);

  const handleChange = useCallback(
    startDate => {
      listInvoices(startDate);
    },
    [listInvoices]
  );

  const handleRowClick = useCallback(
    row => {
      setSelected(invoices?.find(i => i.crn === row.invoiceRef));
    },
    [invoices]
  );

  const rows = useMemo(() => flattenInvoices(invoices, handleRowClick), [invoices, handleRowClick]);

  const exportCsv = props => <ExportToCsv {...props} exportFileName="invoiced-orders" />;

  const { translateObjects } = useTranslateObjects();

  const columns = useColumns();

  const translatedColumns = useMemo(() => {
    return translateObjects(columns, {
      getStringId: value => `invoices.columnHeaders.${value}`,
      getPath: 'key',
      setPath: 'name',
      defaultMessagePath: 'name',
    });
  }, [columns, translateObjects]);

  const refreshInvoicesByDate = useCallback(() => {
    if (!isEmpty(customDateRange)) {
      setSelectedDate('custom');
      listInvoices([
        customDateRange[0].utc().startOf('day').toISOString(),
        customDateRange[1].utc().endOf('day').toISOString(),
      ]);
      return;
    }
    listInvoices(selectedDate);
  }, [selectedDate, listInvoices, customDateRange]);

  return (
    <Styled className={cn('invoiced-list')} {...props}>
      <QuickDates
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        customDateRange={customDateRange}
        setCustomDateRange={setCustomDateRange}
        onChange={handleChange}
        loading={busy}
      />
      <ConnexDataGrid
        showFiltersByDefault
        loading={busy}
        gridName="Invoiced Deliveries List"
        columns={translatedColumns}
        rows={rows}
        rowKeyPath="rowKey"
        filterable
        autoSizeColumns
        rowGrouper={groupBy}
        groupBy={['invoiceNumber', 'orderId']}
        expandedGroupIds={expandedGroupIds}
        onExpandedGroupIdsChange={setExpandedGroupIds}
        actions={exportCsv}
      />
      <UnInvoice
        invoice={selected}
        onClose={() => {
          setSelected(null);
        }}
        refreshList={refreshInvoicesByDate}
      />
    </Styled>
  );
};

export default InvoicedList;
