import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
import Column from '../components/Column';
import Row from '../components/Row';
import { useScaleTicketingContext } from '../Context';

const colors = {
  UNDETERMINED: '#FDBA39;',
  OK: '#8fd250',
  OVERWEIGHT: '#D8222F;',
};

const Styled = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex: 2;
  text-align: center;
  .weight-value {
    color: ${({ $weightStatus }) => colors[$weightStatus]};
    font-size: 1.6rem;
    font-weight: bold;
    line-height: 1em;
  }
`;

const ScaleValues = () => {
  const { form, weightStatus } = useScaleTicketingContext();

  const displayValues = React.useMemo(() => {
    if (!form?.capturedWeight?.weight?.value) {
      return;
    }

    const tons = (parseInt(form?.capturedWeight?.weight?.value) / 2000).toFixed(2);

    return [
      { uom: 'LB', value: form?.capturedWeight?.weight?.value },
      { uom: 'TN', value: tons },
    ];
  }, [form?.capturedWeight?.weight?.value]);

  return (
    <Styled className={cn('scale-values')} $weightStatus={weightStatus}>
      {(displayValues || [{ value: '-----' }, { value: '-----' }])?.map?.(value => (
        <Column>
          <div className="field-label">{value.uom}</div>
          <div className="weight-value">{value.value}</div>
        </Column>
      ))}
    </Styled>
  );
};

export default ScaleValues;
