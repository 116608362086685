import React from 'react';
import style from './style';
import styled from 'styled-components';
import { Row, Col } from 'antd';
import { useNavigate } from 'react-router-dom';

const Styled = styled.div`
  ${style}
`;

const types = [
  {
    label: 'Jobs',
    path: 'jobs',
    icon: 'tasks',
  },
  {
    label: 'Pour Descriptions',
    path: 'pour-descriptions',
    icon: 'truck-loading',
  },
  {
    label: 'Disciplines',
    path: 'disciplines',
    icon: 'th-large',
  },
];

const Type = ({ label, path, icon }) => {
  const navigate = useNavigate();

  return (
    <Col className="type" md={4} onClick={() => navigate(path)}>
      <div>
        <i className={`icon fad fa-${icon}`} />
      </div>
      <div className="label">{label}</div>
    </Col>
  );
};

const SetupBuyerTypes = () => {
  return (
    <Styled>
      <Row className="types">
        {types
          ?.sort((a, b) => (a.label < b.label ? -1 : 1))
          .map(t => (
            <Type key={t.path} label={t.label} path={t.path} icon={t.icon} />
          ))}
      </Row>
    </Styled>
  );
};

export default SetupBuyerTypes;
