const matches = (filters, param, b) => {
  return !filters[param] ||
    (filters[param] === 'off') ||
    (filters[param] === 'true' && b[param]) ||
    (filters[param] === 'false' && !b[param])
}
const fields = [
  'isBillable', 'isDelivered', 'isTaxed', 'isPriced', 'isMultiLine',
  'isPartiallyTaxed', 'hasCustomer', 'hasProject', 'hasVehicle', 'hasPartialAddress',
  'hasNonTaxable',
];

const matchingBillables = (b, filters) => {

  for (const field of fields) {
    if (!matches(filters, field, b)) {
      return false
    }
  }

  return true
}

export default matchingBillables;
