import style from './style';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Button from '../../../../components/button/Button';
import useSetup from '../../useSetup';
import Buttons from '../../../../components/layout/Buttons';
import Spinner from '../../../../components/spinner/Spinner';
import General from './concrete-class-general';
import Tabs from '../../../../components/tabs';
import { useParams } from 'react-router-dom';

const ButtonBar = ({ onCancel, onSave, busy }) => {
  return (
    <Buttons>
      <Button id="btn-cancel" disabled={busy} onClick={onCancel}>
        Cancel
      </Button>
      <Button id="btn-save" loading={busy} disabled={busy} onClick={onSave}>
        Save
      </Button>
    </Buttons>
  );
};

const Styled = styled.div`
  ${style}
`;

const ConcreteClassEditor = ({ item, onSave, onCancel }) => {
  const { entityRef } = useParams();

  const { getSetupItem, setupItem, setSetupItem, saveSetupItem, itemBusy } = useSetup('concrete-class');

  const { tab } = useState('general');

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (item) {
      if (item.crn) {
        getSetupItem(item.crn).then();
      } else {
        setSetupItem(item);
      }
    }
  }, [item]);

  useEffect(() => {
    setLoading(true);
    const run = async () => {
      setLoading(false);
    };
    run().then();
  }, [entityRef]);

  const onChange = (id, value) => {
    setSetupItem({
      ...setupItem,
      [id]: value,
    });
  };

  const onSaveItem = () => {
    saveSetupItem().then(() => onSave(true));
  };

  return (
    <Styled>
      <Spinner spin={loading || (!setupItem && itemBusy)}>
        <Tabs type="card" activeKey={tab}>
          <Tabs.TabPane tab="General" key="general" disabled={false}>
            <General item={setupItem} onChange={onChange} />
          </Tabs.TabPane>
        </Tabs>

        <ButtonBar onSave={onSaveItem} onCancel={onCancel} busy={itemBusy} />
      </Spinner>
    </Styled>
  );
};

export default ConcreteClassEditor;
