import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import Fields from '../Fields';
import style from './style';

const Styled = styled.div`
  ${style}
`;
const FieldsConcrete = props => {
  return (
    <Styled className={cn('general-concrete')} {...props}>
      <Fields />
    </Styled>
  );
};

export default FieldsConcrete;
