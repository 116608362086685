import {groupBy} from 'lodash';

const summary = (acc, b) => {
  acc.extendedPrice += parseFloat(b.extendedPrice ?? '0');
  acc.salesTax += parseFloat(b.salesTax ?? '0');
  acc.totalPrice += parseFloat(b.totalPrice ?? '0');

  return acc
}

const summarizeProjects = (billables, customerRef) => {
  const projects = [];
  const customerBillables = billables.filter(b => b.customerRef === customerRef);

  for (const [project, items] of Object.entries(groupBy(customerBillables, `projectRef`))) {
    projects.push({
      project,
      projectRef: items[0]['projectRef'],
      id: items[0]['projectId'],
      name: items[0]['projectName'],
      billables: items,
      totalProjects: 1,
      totalItems: items.length,
      isBillable: items.every(item => !!item.isBillable),
      summary: items
        .reduce(summary,
          {
            extendedPrice: 0,
            salesTax: 0,
            totalPrice: 0,
          }),
    })
  }

  return projects.sort((a, b) => a.summary?.totalPrice < b.summary?.totalPrice ? 1 : -1)
}

const summarizeBillables = billables => {
  const customers = [];

  for (const [customerRef, items] of Object.entries(groupBy(billables, `customerRef`))) {
    const projects = summarizeProjects(billables, customerRef);

    customers.push({
      customerRef,
      id: items[0]['customerId'],
      name: items[0]['customerName'],
      billables: items,
      totalItems: items.length,
      projects,
      isBillable: items.every(item => !!item.isBillable),
      summary: items
        .reduce(summary,
          {
            extendedPrice: 0,
            salesTax: 0,
            totalPrice: 0,
          }),
    })
  }

  return {
    customers: customers.sort((a, b) => a.summary?.totalPrice < b.summary?.totalPrice ? 1 : -1),
    summary: billables
      .reduce(summary,
        {
          extendedPrice: 0,
          salesTax: 0,
          totalPrice: 0,
        }),
  }
}

export default summarizeBillables;
