import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import style from './style';
import SelectMultiplePriceBooks from '../../components/select-multiple-price-books';
import InputCheckbox from '../../components/input-checkbox';
import SelectSetupItem from '../../components/select-setup-item';
import { useParams } from 'react-router';
import { Switch } from 'antd';
import InputNumber from '../../components/input-number';
import SelectCustomer from '../../components/select-customer';
import SelectProject from '../../components/select-project';
import { status } from '../../setup-mix/mix-formulas/formula-general/options';
import InputSelect from '../../components/input-select';
import InputText from '../../components/input-text';
import InputDate from '../../components/input-date';
import moment from 'moment';
import useSetup from '../../useSetup';
import InputSelectMultiple from '../../components/input-select-multiple';
import { Modal } from 'antd';

const Styled = styled.div`
  ${style}
`;

const Title = styled.div`
  margin-bottom: 3px;
`;

const CostBookAdjustmentGeneral = ({ item, onChange }) => {
  const { entityRef } = useParams();
  const { getSetupItems, setupItems, companyLicenses, getCompanyLicenses } = useSetup('product-type');
  const [types, setTypes] = useState(null);

  useEffect(() => {
    getSetupItems().catch(console.error);
    getCompanyLicenses(entityRef);
  }, [getSetupItems, getCompanyLicenses, entityRef]);

  useEffect(() => {
    setupItems &&
      setTypes(
        setupItems.map(s => {
          return {
            value: s.id,
            label: s.name,
          };
        })
      );
  }, [setupItems]);

  const isMultiCompanyEnabled = React.useMemo(
    () => companyLicenses?.find(l => l.id === 'multi-company'),
    [companyLicenses]
  );

  useEffect(() => {
    isMultiCompanyEnabled && onChange('allPriceBooks', false);
  }, [isMultiCompanyEnabled, onChange]);

  const handleBlur = useCallback(() => {
    if (item?.priceBooks?.length > 0) {
      Modal.confirm({
        title: 'Warning',
        content: <p>Is this the correct Price Book for your Company?</p>,
        onCancel: () => {
          onChange('priceBooks', null);
        },
      });
    }
  }, [item?.priceBooks?.length, onChange]);

  const allDisabled = item?.effectiveDate && item.effectiveDate < moment().startOf('day').toISOString();

  return (
    <Styled>
      <InputText disabled={allDisabled} id="id" label="ID" value={item?.id} onChange={onChange} required />
      <InputDate
        id="effectiveDate"
        label="Effective Date"
        disabled={allDisabled}
        minDate={moment().subtract(1, 'day').endOf('day').toISOString()}
        value={moment(item?.effectiveDate) || moment()}
        onChange={(id, value) => onChange(id, moment(value).startOf('day').toISOString())}
      />
      {!isMultiCompanyEnabled && (
        <InputCheckbox
          label={'All Active Price Books'}
          value={isMultiCompanyEnabled ? false : item?.allPriceBooks}
          id={'allPriceBooks'}
          onChange={onChange}
          disabled={allDisabled}
        />
      )}
      <SelectMultiplePriceBooks
        label={'Price Books'}
        value={item?.priceBooks}
        id={'priceBooks'}
        onChange={onChange}
        disabled={allDisabled || (item?.allPriceBooks && !isMultiCompanyEnabled)}
        onBlur={handleBlur}
      />
      <InputCheckbox
        label={'All Product Types'}
        value={item?.allProductTypes}
        id={'allProductTypes'}
        onChange={onChange}
        disabled={allDisabled}
      />
      {types && (
        <InputSelectMultiple
          id="productTypes"
          label="Product Types"
          value={item?.productTypes}
          onChange={onChange}
          options={types}
          disabled={allDisabled || item?.allProductTypes}
        />
      )}

      <div className={'adjust-type'}>
        <Title>Adjustment Type</Title>
        <Switch
          style={{ marginLeft: 'auto' }}
          checkedChildren={'Flat'}
          unCheckedChildren={'Percent'}
          size={'default'}
          checked={item?.adjustType === 'FLAT'}
          onChange={checked => onChange('adjustType', checked ? 'FLAT' : 'PERCENT')}
          disabled={allDisabled}
        />
      </div>

      <InputNumber
        value={item?.adjustValue}
        onChange={onChange}
        id={'adjustValue'}
        label={'Adjust Value'}
        addonAfter={<span className={`fa fa-${item?.adjustType === 'FLAT' ? 'dollar-sign' : 'percent'}`} />}
        disabled={allDisabled}
      />
      <SelectCustomer item={item} onChange={onChange} disabled={allDisabled} />
      <SelectProject
        item={item}
        customerRef={item?.customerParty?.entityRef}
        onChange={onChange}
        disabled={allDisabled}
      />
      <InputSelect
        id="status"
        label="Status"
        value={item?.status}
        onChange={onChange}
        options={status}
        required
        disabled={allDisabled}
      />
    </Styled>
  );
};

export default CostBookAdjustmentGeneral;
