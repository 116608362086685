import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useShortDateTimeString } from '../../../../order/components/localization/ShortDateTime';
import { Translate } from '../../../../order/components/localization/Translate';
import { useTranslateMessage } from '../../../../order/components/localization/useTranslateMessage';
import style from './style';
import useSetup from '../../../../setup/useSetup';
import InputText from '../../../../setup/components/input-text';
import { useOrder } from '../../../../order/view-or-edit-order/useOrder';
import InputNumber from '../../../../setup/components/input-number';
import { DateTime } from 'luxon';
import API from '../../../api';
import { useParams } from 'react-router-dom';
import Button from '../../../../../components/button/Button';
import { useDashboardContext } from '../../context/useDashboardContext';
import Buttons from '../../../../../components/layout/Buttons';
import InputSelect from '../../../../setup/components/input-select';
import { find, pick } from 'lodash';
import SelectLocation from '../../../../setup/components/select-location';

const mapLocationLabel = location => {
  const result = [];

  location?.id && result.push(location.id);
  location?.name && result.push(location.name);

  return result.join(' / ');
};

const now = () => {
  return DateTime.utc().toISO();
};

const newVehicleData = (updateValue, vehicle, primaryUOM) => {
  let newData = {};
  if (updateValue.remainingQuantity) {
    newData = {
      ...newData,
      remainingQuantity: {
        value: parseFloat(updateValue?.remainingQuantity),
        uomCode: primaryUOM,
      },
    };
  }
  if (updateValue.driver) {
    newData = {
      ...newData,
      configuration: {
        ...vehicle.configuration,
        driver: pick(updateValue.driver, ['driverRef', 'firstName', 'lastName', 'id']),
      },
    };
  }
  if (updateValue.homeLocation) {
    newData = { ...newData, homeLocation: pick(updateValue.homeLocation, ['locationRef', 'id']) };
  }
  if (updateValue.toLocation) {
    newData = { ...newData, toLocation: pick(updateValue.toLocation, ['locationRef', 'id']) };
    if (vehicle?.fromLocation?.locationRef) {
      if (newData.toLocation.locationRef !== vehicle?.fromLocation?.locationRef && (!vehicle?.ticket || vehicle?.vehicleStatus?.statusCode === 'IN_YARD')) {
        newData.vehicleStatus = { eventDateTime: now(), statusCode: 'DEADHEAD' };
      }
      if (
        newData.toLocation.locationRef === vehicle?.fromLocation?.locationRef &&
        vehicle.toLocation.locationRef !== vehicle?.fromLocation?.locationRef &&
        (vehicle?.vehicleStatus?.statusCode === 'DEADHEAD' || !vehicle?.ticket)
      ) {
        newData.vehicleStatus = { eventDateTime: now(), statusCode: 'IN_YARD' };
      }
    }
  }
  return newData;
};

const ButtonBar = ({ onCancel, onSave, showSave = true, busy, saveDisabled }) => {
  return (
    <Buttons>
      <Button id="btn-cancel" disabled={busy} onClick={onCancel} metricId="core-tracking-update-vehicle-cancel">
        <Translate stringId="cancel" defaultMessage="Cancel" />
      </Button>
      {showSave && (
        <Button
          id="btn-save"
          loading={busy}
          disabled={busy || saveDisabled}
          onClick={onSave}
          metricId="core-tracking-update-vehicle-save"
        >
          <Translate stringId="save" defaultMessage="Save" />
        </Button>
      )}
    </Buttons>
  );
};

const VehicleDetails = ({ vehicleRef, className, onSave }) => {
  const { getVehicle, getDrivers, getLocation, vehicle, drivers, location } = useSetup();
  const { getOrder, rawOrder } = useOrder();
  const { entityRef } = useParams();

  const [updateValue, setUpdateValue] = useState({});
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const onChange = useCallback((id, value) => {
    setButtonDisabled(false);
    setUpdateValue(existing => ({ ...existing, [id]: value }));
  }, []);

  const {
    drawer: { toggleDrawer, setContent },
  } = useDashboardContext();

  const closeDrawer = useCallback(() => {
    toggleDrawer();
    setTimeout(() => {
      setContent(null);
    }, 10);
  }, [toggleDrawer, setContent]);

  useEffect(() => {
    getVehicle(vehicleRef).then();
  }, [getVehicle, vehicleRef]);

  useEffect(() => {
    if (vehicle?.ticket?.dispatchOrderRef) {
      getOrder(vehicle?.ticket?.dispatchOrderRef).then();
    }
  }, [vehicle?.ticket]);

  useEffect(() => {
    getDrivers();
  }, [getDrivers]);

  const driverOptions = React.useMemo(() => {
    if (drivers) {
      return drivers.map(driver => {
        const driverInfo = [];
        driver.id && driverInfo.push(driver.id);
        driver.firstName && driver.lastName && driverInfo.push(`${driver.firstName} ${driver.lastName}`);
        const label = driverInfo.join(' / ');

        return { value: driver.crn, label };
      });
    }
    return [];
  }, [drivers]);

  const handleDriverChange = React.useCallback(
    (id, value) => {
      const targetDriver = find(drivers, { crn: value });
      onChange(id, {
        ...pick(targetDriver, ['firstName', 'lastName', 'id']),
        driverRef: targetDriver.crn,
      });
    },
    [drivers, onChange]
  );

  useEffect(() => {
    getLocation(entityRef, vehicle?.fromLocation?.locationRef);
  }, [vehicle?.fromLocation?.locationRef, entityRef, getLocation]);

  const primaryUOM = useMemo(() => {
    return rawOrder?.lineItems?.[0]?.item?.uomCode || 'YDQ';
  }, [rawOrder]);

  const translateMessage = useTranslateMessage();

  const labels = React.useMemo(() => {
    return {
      vehicleId: translateMessage({ stringId: 'vehicleId', defaultMessage: 'Vehicle ID' }),
      driver: translateMessage({ stringId: 'driver', defaultMessage: 'Driver' }),
      orderDate: translateMessage({ stringId: 'orderDate', defaultMessage: 'Order Date' }),
      orderId: translateMessage({ stringId: 'orderId', defaultMessage: 'Order ID' }),
      ticketNumber: translateMessage({ stringId: 'ticketNumberLabel', defaultMessage: 'Ticket Number' }),
      fromLocation: translateMessage({ stringId: 'fromLocation', defaultMessage: 'From Location' }),
      toLocation: translateMessage({ stringId: 'toLocation', defaultMessage: 'To Location' }),
      homeLocation: translateMessage({ stringId: 'homeLocation', defaultMessage: 'Home Location' }),
      returnedWater: translateMessage({ stringId: 'returnedWater', defaultMessage: 'Returned Water' }),
      returnedConcrete: translateMessage({ stringId: 'returnedConcrete', defaultMessage: 'Returned Concrete' }),
    };
  }, [translateMessage]);

  const { getShortDateTimeString } = useShortDateTimeString();

  return (
    <div className={className}>
      <InputText disabled={true} label={labels.vehicleId} value={vehicle?.id} />
      <InputSelect
        id="driver"
        label={labels.driver}
        value={updateValue?.driver?.driverRef || vehicle?.configuration?.driver?.driverRef}
        onChange={handleDriverChange}
        options={driverOptions}
        required
      />
      <InputText
        disabled={true}
        label={labels.orderDate}
        value={getShortDateTimeString({
          date: rawOrder?.deliverySchedule?.startDateTime,
          timeZone: rawOrder?.supplierParty?.timeZone,
        })}
      />
      <InputText disabled={true} label={labels.orderId} value={rawOrder?.id} />
      <InputText disabled={true} label={labels.ticketNumber} value={vehicle?.ticket?.id} />
      <InputText disabled={true} label={labels.fromLocation} value={mapLocationLabel(location)} />

      <SelectLocation
        label={labels.toLocation}
        typeId="location"
        id={'toLocation'}
        item={updateValue.toLocation ? updateValue : vehicle}
        onChange={onChange}
        includeDispatchLocations={false}
      />
      <SelectLocation
        label={labels.homeLocation}
        typeId="location"
        id={'homeLocation'}
        item={updateValue.homeLocation ? updateValue : vehicle}
        onChange={onChange}
        includeDispatchLocations={false}
      />
      <InputText disabled={true} label={labels.returnedWater} value={vehicle?.waterOnTruck?.value} />

      <InputNumber
        className={'returned-quantity-input'}
        label={labels.returnedConcrete}
        id={'remainingQuantity'}
        onChange={onChange}
        value={updateValue?.remainingQuantity || vehicle?.remainingQuantity?.value}
      />

      <ButtonBar
        onCancel={closeDrawer}
        onSave={() => {
          API.updateVehicle(entityRef, vehicle.crn, newVehicleData(updateValue, vehicle, primaryUOM)).then(closeDrawer);
        }}
        saveDisabled={buttonDisabled}
      />
    </div>
  );
};

export default styled(VehicleDetails)`
  ${style}
`;
