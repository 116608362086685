import React from 'react';
import styled from 'styled-components';
import { Input } from 'antd';

const AntInput = props => {
  return <Input {...props} />;
};

export default styled(AntInput)`
  &&& {
    .ant-input-group {
      & > .ant-input:first-child {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
      .ant-input-group-addon {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
    .ant-input.ant-input-disabled {
      background-color: #424344 !important;
      color: #ffffff !important;
      opacity: 0.7 !important;
    }
  }
`;
