const isValidLine = line => {
  if (line?.properties?.slump && typeof line?.properties?.slump === 'string') {
    //cover case where slump defaults in
    line.properties.slump = Number(line?.properties?.slump);
  }
  return (
    line?.item?.productRef &&
    line?.orderedQuantity?.value &&
    (line?.item?.type === 'CONCRETE') ==
      (!Number.isNaN(line?.properties?.slump) && typeof line?.properties?.slump === 'number')
  );
};

const isEmptyLine = line => {
  return !(
    line?.item?.productRef ||
    line?.orderedQuantity?.value ||
    line?.properties?.slump ||
    line?.properties?.cleanup
  );
};

export const lineItemsValid = lineItems => {
  if (!lineItems?.length) return false;

  const nonEmptyLines = lineItems.filter(i => !isEmptyLine(i));

  const validLines = nonEmptyLines.filter(i => isValidLine(i));

  return validLines?.length > 0 && nonEmptyLines?.length === validLines?.length;
};

export const linkedOrdersValid = linkedOrders => {
  if (!linkedOrders?.length) return false;

  for (const linkedOrder of linkedOrders) {
    const { primaryLineItem, otherLineItems } = linkedOrder;
    if (!lineItemsValid([primaryLineItem, ...otherLineItems])) {
      return false;
    }
  }

  return true;
};
