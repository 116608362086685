import React from 'react';
import { useUserContext } from '../user-context/UserContextProvider';

export const permissionsMapping = {
  showPricing: 'enable_rmx-dispatch_orders_view-order-pricing',
  listPriceOnly: 'enable_rmx-dispatch_orders_list-prices-only',
  editPricing: 'enable_rmx-dispatch_orders_edit-pricing',
};

const hasEditPricing = (authorities, supplierParty) => {
  const editPricing = !!authorities[permissionsMapping['editPricing']];
  const hideCustomPricing = !!authorities[permissionsMapping['listPriceOnly']];
  const isDefaultCostBook = !supplierParty?.costBookOverride;
  const isManualOverride = !!supplierParty?.manualOverride;

  return editPricing && (!hideCustomPricing || isDefaultCostBook || isManualOverride);
};

const hasShowPricing = (authorities, supplierParty) => {
  const showPricing = !!authorities[permissionsMapping['showPricing']];
  const hideCustomPricing = !!authorities[permissionsMapping['listPriceOnly']];
  const isDefaultCostBook = !supplierParty?.costBookOverride;

  return showPricing && (!hideCustomPricing || isDefaultCostBook);
};

const HasPricingPermission = ({ permission, supplierParty, children, not, paPSOverride = true, caOverride = true }) => {
  const {
    permissions: { authorities, groups, assignedRoles },
  } = useUserContext();

  const isPaPs = React.useMemo(() => {
    return groups.includes('admin') || groups.includes('support');
  }, [groups]);

  const isCompanyAdmin = React.useMemo(() => {
    return !!assignedRoles?.['company-admin'];
  }, [assignedRoles]);

  const hasPermission = React.useMemo(() => {
    let granted = false;
    switch (permission) {
      case 'view':
        granted = hasShowPricing(authorities, supplierParty);
        break;
      case 'edit':
        granted = hasEditPricing(authorities, supplierParty);
        break;
      default:
    }
    return granted || (paPSOverride && isPaPs) || (caOverride && isCompanyAdmin);
  }, [authorities, caOverride, isCompanyAdmin, isPaPs, paPSOverride, permission, supplierParty]);

  if (not) {
    return !hasPermission ? children : null;
  }

  return hasPermission ? children : null;
};

export default HasPricingPermission;
