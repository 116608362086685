import cn from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import getUomAbbreviationOrDescription from '../../../../../../util/uom';
import styled from 'styled-components';
import { Translate } from '../../../localization/Translate';
import { Uom } from '../../../localization/Uom';
import { useTranslateMessage } from '../../../localization/useTranslateMessage';
import style from './style';
import Button from '../../../../../../components/button/Button';
import SelectField from '../../../SelectField';

const Styled = styled.div`
  ${style}
`;

const SaveTicketButton = ({
  disabled,
  onSave,
  preBatchWater,
  remainingQuantity,
  selectedVehicle,
  loading,
  params,
  mixIdValues,
}) => {
  const [expandedButton, setExpandedButton] = useState(false);
  const [selectValue, setSelectValue] = useState(null);

  useEffect(() => {
    if (expandedButton === true && preBatchWater <= 0 && remainingQuantity <= 0) {
      setExpandedButton(false);
    }
  }, [expandedButton, setExpandedButton, preBatchWater, remainingQuantity]);

  const handleSelectValue = useCallback((_, value) => {
    setSelectValue(value);
  }, []);

  const handleDoNotCancelVoid = useCallback(() => {
    setExpandedButton(false);
  }, []);

  const handleCreateTicket = useCallback(() => {
    if (preBatchWater <= 0 && remainingQuantity <= 0) {
      onSave(params);
      return;
    }
    setExpandedButton(true);
  }, [preBatchWater, remainingQuantity, onSave, params]);

  const handleSave = useCallback(() => {
    const tempParams = { ...params };
    if (selectValue === 'Yes') {
      tempParams.reshippedTicket = {
        ...selectedVehicle.ticket,
        reshippedQuantity: selectedVehicle.remainingQuantity,
      };
      tempParams.preBatchedWater = selectedVehicle?.waterOnTruck;
    }

    onSave(tempParams);
    setExpandedButton(false);
  }, [selectValue, setExpandedButton, params, selectedVehicle, onSave]);

  const translateMessage = useTranslateMessage();
  const yesNoList = React.useMemo(() => {
    return [
      { value: 'Yes', label: translateMessage({ stringId: 'yes', defaultMessage: 'Yes' }) },
      { value: 'No', label: translateMessage({ stringId: 'no', defaultMessage: 'No' }) },
    ];
  }, [translateMessage]);

  return (
    <Styled className={cn('cancel-void')}>
      <div className={cn('button-section', { visible: !expandedButton })}>
        <button type="button" onClick={handleCreateTicket} disabled={disabled}>
          {loading ? (
            <i className="fas fa-spinner-third fa-spin" style={{ marginRight: '15px' }} />
          ) : (
            <Translate stringId="save" defaultMessage="Save" />
          )}
        </button>
      </div>
      <div className={cn('details-section-wrapper', { visible: expandedButton })}>
        <div className="details-section">
          {selectedVehicle?.waterOnTruck?.value && (
            <span style={{ flexDirection: 'row', display: 'flex' }}>
              <div className={'warning-icon'}>
                <i className="fas fa-exclamation-triangle"></i>
              </div>
              <div style={{ marginLeft: '5px' }}>
                Vehicle has{' '}
                <Uom
                  uom={{ value: selectedVehicle?.waterOnTruck?.value, uomCode: selectedVehicle?.waterOnTruck?.uomCode }}
                />{' '}
                water remaining.
              </div>
            </span>
          )}
          {remainingQuantity > 0 && (
            <span style={{ flexDirection: 'row', display: 'flex', marginBottom: '10px' }}>
              <div className={'warning-icon'}>
                <i className="fas fa-exclamation-triangle"></i>
              </div>
              <div style={{ marginLeft: '5px' }}>
                Vehicle has{' '}
                <Uom uom={{ value: remainingQuantity, uomCode: selectedVehicle?.remainingQuantity?.uomCode }} />{' '}
                remaining.
              </div>
            </span>
          )}
          {!mixIdValues.matches && (
            <div style={{ flexDirection: 'column', display: 'flex' }}>
              <div style={{ flexDirection: 'row', display: 'flex' }}>
                <div className={'warning-icon'}>
                  <i className="fas fa-exclamation-triangle"></i>
                </div>
                <div style={{ marginLeft: '5px' }}>
                  <Translate
                    stringId="productIdMismatch"
                    values={{ ticketProductId: mixIdValues.ticketItemId, orderProductId: mixIdValues.orderItemId }}
                    defaultMessage="Previous ticket's product ID does not match order's product ID. {ticketProductId} vs. {orderProductId}"
                  />
                </div>
              </div>
            </div>
          )}
          <br />
          <SelectField
            required
            list={yesNoList}
            value={selectValue}
            fieldName="selectValue"
            valuePath="value"
            displayPath="label"
            fieldLabel="Use remaining quantity from previous load?"
            fieldLabelStringId="useRemainingQuantity"
            onChange={handleSelectValue}
          />
          <div className="flex-container">
            <Button type="button" onClick={handleDoNotCancelVoid} style={{ marginRight: '20px' }}>
              <Translate stringId="cancel" defaultMessage="Cancel" />
            </Button>
            <Button type="button" onClick={handleSave} disabled={!selectValue}>
              <Translate stringId="save" defaultMessage="Save" />
            </Button>
          </div>
        </div>
      </div>
    </Styled>
  );
};

export default SaveTicketButton;
