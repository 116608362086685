import { css } from 'styled-components';

export default css`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
  .no-notes {
    margin-top: 10%;
    text-align: center;
    font-size: 1.2em;
    opacity: 0.8;
  }
  .note-list-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: auto;
    .note-list-header {
      text-align: center;
      font-weight: bold;
      font-size: 1.2em;
    }
    .note-list-body {
      flex: 1;
      overflow: auto;
      .note {
        border: 1px solid #444;
        margin: 10px 5px;
        background-color: #252525;
        .note-user {
          display: flex;
          background-color: #353535;
          .name {
            font-weight: bold;
            margin-left: 10px;
            opacity: 0.8;
          }
          .email {
          }
        }
        .note-message {
          font-size: 1.3em;
          color: #fff;
          padding: 10px;
        }
        .note-timestamp {
          opacity: 0.5;
          padding: 0 10px;
        }
      }
    }
    .create-note {
      border: 1px solid #444;
      margin: 10px 5px;
      background-color: #252525;
      padding: 10px;
      .label {
      }

      .note-text {
        font-size: 16px !important;
      }

      .buttons {
        text-align: right;
        margin-top: 10px;
        display: flex;
        & > button {
          flex: 1;
          &:not(:last-child) {
            margin-right: 5px;
          }
        }
      }
    }
    .note-list-buttons {
      padding: 10px;
      button {
        width: 100%;
      }
    }
  }
`;
