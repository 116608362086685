import cn from 'classnames';
import React, { useCallback } from 'react';
import styled from 'styled-components';
import FieldContainer from '../../field-container/FieldContainer';
import Input from '../../form/Input';

const Directions = ({ className, disabled, value, onChange }) => {
  const handleChange = useCallback(
    e => {
      onChange('directions', e.target.value);
    },
    [onChange]
  );

  return (
    <FieldContainer className={cn('directions', className)}>
      <div className="label-spinner">
        <div className="label">Directions</div>
        <div className={cn('spinner', { spinning: false })}>
          <span className="fas fa-sync fa-spin" />
        </div>
      </div>
      <Input value={value || ''} onChange={handleChange} disabled={disabled} data-testid="directions_input" />
    </FieldContainer>
  );
};

export default styled(Directions)``;
