import style from './style';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import Button from '../../../../components/button/Button';
import useSetup from '../../useSetup';
import Buttons from '../../../../components/layout/Buttons';
import Spinner from '../../../../components/spinner/Spinner';
import General from './general';

const ButtonBar = ({ onCancel, onSave, busy }) => {
  return (
    <Buttons>
      <Button id="btn-cancel" disabled={busy} onClick={onCancel}>
        Cancel
      </Button>
      <Button id="btn-save" loading={busy} disabled={busy} onClick={onSave}>
        Save
      </Button>
    </Buttons>
  );
};

const Styled = styled.div`
  ${style}
`;

const Editor = ({ item, onSave, onCancel }) => {
  const { saveTrackableType, trackableType, getTrackableType, setTrackableType, itemBusy, error } = useSetup();

  useEffect(() => {
    if (item) {
      if (item.crn) {
        getTrackableType(item.crn);
      } else {
        setTrackableType(item);
      }
    }
  }, [item]);

  const onChange = (id, value) => {
    setTrackableType({
      ...trackableType,
      [id]: value,
    });
  };

  const onSaveItem = () => {
    saveTrackableType().then(() => onSave(true));
  };

  return (
    <Styled>
      <Spinner spin={!trackableType && itemBusy}>
        <General trackableType={trackableType} onChange={onChange} error={error} />
        <ButtonBar onSave={onSaveItem} onCancel={onCancel} busy={itemBusy} />
      </Spinner>
    </Styled>
  );
};

export default Editor;
