// https://github.com/mirkokiefer/canonical-json/blob/master/index2.js

const isObject = a => {
  return Object.prototype.toString.call(a) === '[object Object]';
};

const copyObjectWithSortedKeys = object => {
  if (isObject(object)) {
    const newObj = {};
    const keysSorted = Object.keys(object).sort();
    let key;
    for (let i = 0, len = keysSorted.length; i < len; i++) {
      key = keysSorted[i];
      newObj[key] = copyObjectWithSortedKeys(object[key]);
    }
    return newObj;
  }

  if (Array.isArray(object)) {
    return object.map(copyObjectWithSortedKeys);
  }

  return object;
};

export default object => {
  return JSON.stringify(copyObjectWithSortedKeys(object));
};
