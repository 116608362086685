import styled from 'styled-components';
import { Modal } from 'antd';

import style from './style';
import Button from '../../../../components/button/Button';
import { Translate } from '../../../order/components/localization/Translate';
import { useCallback } from 'react';
import useUnInvoice from './hooks/useUnInvoice';
import { useParams } from 'react-router';

const Styled = styled.div`
  ${style}
`;

const UnInvoice = ({ invoice, onClose, refreshList }) => {
  const { entityRef } = useParams();

  const { loading, executeUnInvoice } = useUnInvoice();

  const handleUnInvoice = useCallback(async () => {
    await executeUnInvoice(entityRef, invoice?.crn);
    onClose();
    refreshList();
  }, [onClose, refreshList, executeUnInvoice, invoice?.crn, entityRef]);

  return (
    <Styled>
      <Modal
        visible={invoice}
        placement="right"
        closable={false}
        destroyOnClose
        footer={[
          <Button key="back" onClick={onClose} disabled={loading}>
            <Translate stringId="cancel" defaultMessage="Cancel" />
          </Button>,
          <Button key="submit" type="primary" onClick={handleUnInvoice} disabled={loading} loading={loading}>
            <Translate stringId="invoices.un-invoice" defaultMessage="Un-invoice" />
          </Button>,
        ]}
      >
        <div>
          <Translate
            stringId="unInvoiceFinalConfirmation"
            defaultMessage="Are you sure you want to uninvoice #{value}?"
            values={{ value: invoice?.invoiceNumber }}
          />
        </div>
      </Modal>
    </Styled>
  );
};

export default UnInvoice;
