import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import useSetup from '../views/setup/useSetup';

export const useTaxExemptReasons = () => {
  const { entityRef } = useParams();
  const { setupItems, getSetupItems, itemBusy } = useSetup('cancel-reason-code');

  useEffect(() => {
    entityRef && getSetupItems({ activeOnly: true });
  }, [entityRef, getSetupItems]);

  const options = useMemo(() => {
    return (
      setupItems?.filter?.(item => item?.reasonType === 'TAX_EXEMPT').map(r => ({ label: r.name, value: r.crn })) || []
    );
  }, [setupItems]);

  return {
    taxExemptReasons: options,
    taxExemptReasonRequired: options.length > 0,
    taxExemptReasonsBusy: itemBusy,
  };
};
