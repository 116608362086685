import { useMemo } from 'react';
import { isNil } from 'lodash';

import InputNumberBlur from '../../../../../components/input-number-blur';
import { Checkbox } from '../../../../../../../components/checkbox';

const LocationRow = ({ location, product, productCosts, onPlant, onCost }) => {
  const noPricedComponent = useMemo(() => {
    if (isNil(productCosts?.[location.crn])) return '';

    return productCosts[location.crn].missingComponentCosts ? '*' : '';
  }, [productCosts, location.crn]);

  return (
    <tr key={location.crn}>
      <td>
        {location.id} / {location.name} {noPricedComponent}
      </td>
      <td>
        <Checkbox
          id={location.crn}
          onChange={e => onPlant(location.crn, e.target.checked)}
          checked={product?.locations?.[location.crn] === true}
        />
      </td>
      {product?.type === 'CONCRETE' && <td>{productCosts?.[location.crn]?.cost}</td>}
      <td>
        <InputNumberBlur id={location.crn} onChange={onCost} value={product?.costs?.[location.crn] ?? ''} />
      </td>
    </tr>
  );
};

export default LocationRow;
