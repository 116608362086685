import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';
import cn from 'classnames';
import Button from '../../../../components/button/Button';
import Buttons from '../../../../components/layout/Buttons';
import Spinner from '../../../../components/spinner/Spinner';
import useSetup from '../../useSetup';
import General from './general/General';
import style from './style';
import FormError from '../../components/form-error';

const Styled = styled.div`
  ${style}
`;
const ButtonBar = ({ onCancel, onSave, busy }) => {
  return (
    <Buttons>
      <Button id="btn-cancel" disabled={busy} onClick={onCancel}>
        Cancel
      </Button>
      <Button id="btn-save" loading={busy} disabled={busy} onClick={onSave}>
        Save
      </Button>
    </Buttons>
  );
};

const DriverEditor = ({ item, onCancel, onSave }) => {
  const { updateDriver, createDriver, driver, getDriver, setDriver, itemBusy, error } = useSetup();
  const { entityRef } = useParams();

  useEffect(() => {
    if (item) {
      if (item.crn) {
        getDriver(item.crn).catch(alert);
      } else {
        setDriver(item);
      }
    }
  }, [entityRef, getDriver, item, setDriver]);

  const onChange = React.useCallback(
    (id, value) => {
      setDriver({
        ...driver,
        [id]: value,
      });
    },
    [setDriver, driver]
  );

  const onSaveDriver = React.useCallback(() => {
    if (driver.crn) {
      updateDriver(driver)
        .then(() => onSave(true))
        .catch(() => {});
    } else {
      createDriver(driver)
        .then(() => onSave(true))
        .catch(() => {});
    }
  }, [createDriver, onSave, driver, updateDriver]);

  return (
    <Styled className={cn('driver-editor')}>
      <FormError error={error} />
      <Spinner spin={!driver && itemBusy}>
        <General driver={driver} itemBusy={itemBusy} onChange={onChange} />
        <ButtonBar onCancel={onCancel} onSave={onSaveDriver} busy={itemBusy} />
      </Spinner>
    </Styled>
  );
};

export default DriverEditor;
