import { css as styled } from 'styled-components';

export default styled`
  &&& {
    table {
      width: 100%;
      table-layout: fixed;
      margin-bottom: 20px;
      &.disabled {
        th {
          .label {
            color: #777;
          }
        }
        td {
          .row-actions {
            i {
              cursor: default;
            }
          }
        }
      }
      thead {
        tr {
          background-color: #333;
          th {
            padding: 0 5px;
            &.product {
              width: 35%;
              overflow: hidden;
            }

            &.quantity {
              width: 13%;
            }

            &.hold-quantity {
              width: 100px;
            }

            &.slump {
              width: 85px;
              input {
                width: 81px;
              }
            }
            &.cleanup {
              width: 80px;
              text-align: center;
            }

            &.extended-price,
            &.sales-tax,
            &.total-price {
              text-align: right;
            }
            &.actions-column {
              width: 40px;
            }
          }
        }
      }
      tbody {
        tr {
          font-size: 0.875rem;

          &.total-row {
            font-size: 1rem;
            background-color: #333;
            td {
              border: none;
              font-weight: bold;
              &.total-label {
                text-align: right;
                text-transform: uppercase;
              }
            }
            .button-cell {
              text-align: right;
              padding-right: 0;
              button {
                margin-top: 10px;
              }
            }
          }

          &.StandardRow {
            td {
              height: 30px;
              padding: 0;
              .row-actions {
                display: none;
              }
            }
          }

          td {
            border: 1px solid #434343;
            &.right-align {
              text-align: right;
              padding-right: 10px;
            }
            .row-actions {
              display: flex;
              justify-content: space-evenly;
              i {
                cursor: pointer;
              }
            }
          }
        }
      }
    }

    .table-header {
      display: flex;
      justify-content: flex-end;
      margin-top: 10px;
      margin-bottom: 5px;
      gap: 10px;
      button {
        font-size: 0.8rem;
        .icon {
          margin-right: 10px;
        }
        margin-top: auto;
        margin-bottom: auto;
      }
      .order-line-header {
        margin-top: auto;
        margin-bottom: auto;
        margin-left: 10px;
        justify-self: flex-start;
        font-size: 24px;
        margin-right: auto;
      }
    }
  }
`;
