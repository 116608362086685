import { css } from 'styled-components';

export default css`
  td {
    padding: 3px;
    border: solid 1px #333;
  }

  .total {
    text-align: right;
  }

  .batchSequence {
    text-align: center;
  }

  .delete {
    text-align: center;
  }

  .delete:hover {
    cursor: pointer;
  }
`;
