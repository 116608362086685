import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Button from '../../../../components/button/Button';
import TicketInfo from '../../../../components/field-components/editable/TicketInfo';

import Buttons from '../../../../components/layout/Buttons';
import Column from '../../../../components/layout/Column';
import Fields from '../../../../components/layout/Fields';
import Layout from '../../../../components/layout/Layout';

import Spinner from '../../../../components/spinner/Spinner';
import style from '../style';

import Contact from '../../../../components/field-components/read-only/Contact';
import Description from '../../../../components/field-components/read-only/Description';

import Directions from '../../../../components/field-components/read-only/Directions';
import Discipline from '../../../../components/field-components/read-only/Discipline';
import EmailCopies from '../../../../components/field-components/read-only/EmailCopies';

import Job from '../../../../components/field-components/read-only/Job';
import Notes from '../../../../components/field-components/read-only/Notes';
import PourCardNumber from '../../../../components/field-components/read-only/PourCardNumber';
import PourType from '../../../../components/field-components/read-only/PourType';
import Product from '../../../../components/field-components/read-only/Product';
import ProductQuantity from '../../../../components/field-components/read-only/ProductQuantity';
import RequestedBy from '../../../../components/field-components/read-only/RequestedBy';
import Slump from '../../../../components/field-components/read-only/Slump';
import Spacing from '../../../../components/field-components/read-only/Spacing';
import StartDateTime from '../../../../components/field-components/read-only/StartDateTime';
import Supplier from '../../../../components/field-components/read-only/Supplier';
import PourDescription from '../../../../components/field-components/read-only/PourDescription';

const ViewOrder = ({ order: { order, orderBusy }, form: { form } }) => {
  const navigate = useNavigate();

  return (
    <div className="view-order">
      <div className="title">View Order Request {form?.number ? `(${form.number})` : ''}</div>

      <Spinner spin={orderBusy}>
        {order && (
          <Layout>
            <Fields>
              <Column>
                <Product value={form?.product} disabled />
                <ProductQuantity value={form?.quantity} uom={form?.product?.uomCode} disabled />
                <Job value={form?.job} disabled />
                <Supplier value={form?.supplier?.name} disabled />
                <Discipline value={form?.discipline} disabled />
                <PourDescription value={form?.pourDescription} disabled />
                <Description value={form?.description} disabled />
                <Contact value={form?.contact} disabled />
                <RequestedBy value={form?.requestedBy} />
              </Column>
              <Column>
                <StartDateTime value={form?.startDateTime} disabled />
                <Slump value={form?.slump} disabled />
                <Spacing value={form?.spacing} spacingUnits={form?.spacingUnits} disabled />
                <PourType value={form?.pourType} disabled />
                <PourCardNumber value={form?.pourCardNumber} disabled />
                <Directions value={form?.directions} disabled />
                <EmailCopies value={form?.emailCopies} disabled />
                <TicketInfo tickets={order.tickets} meta={order.ticketsMeta} />
              </Column>
            </Fields>
            <div style={{ gridArea: 'notes' }}>
              <Notes value={form?.notes} />
            </div>
            <Buttons>
              <Button metricId="view-order-cancel" onClick={() => navigate('..')}>
                Go Back
              </Button>
              <Button metricId="view-order-done" onClick={() => navigate('..')}>
                Done
              </Button>
            </Buttons>
          </Layout>
        )}
      </Spinner>
    </div>
  );
};

export default styled(ViewOrder)`
  ${style}
`;
