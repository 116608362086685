import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
import Actions from './components/actions/Actions';
import ClearFiltersButton from './components/clear-filters-button/ClearFiltersButton';
import FilterSwitch from './components/filter-switch/FilterSwitch';
import GridCounts from './components/grid-counts/GridCounts';
import PreferencesIcon from './components/preferences/PreferencesDrawerTrigger';
import ExportButton from './components/export-button';
import ExpandRows from './components/expand-rows';

const Header = styled.div`
  background-color: #222222;
  padding: 5px;
  height: 35px;
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  align-items: center;
  .actions {
    display: flex;
    justify-content: flex-end;
    //text-align: right;
    grid-gap: 5px;
    align-items: center;
    .preferences-icon {
      width: 20px;
      text-align: center;
      cursor: pointer;
    }
  }
  .left-actions {
    display: flex;
    gap: 15px;
  }
`;

const DefaultHeader = props => {
  return (
    <Header className={cn('default-header')} {...props}>
      <div className="left-actions">
        {props.displayExpandAll && (
          <ExpandRows handleExpandAll={props?.handleExpandAll} handleHideAll={props?.handleHideAll} />
        )}
        <FilterSwitch />
      </div>
      <GridCounts />
      <div className="actions">
        {props.allowExport && <ExportButton />}
        <ClearFiltersButton />
        <Actions />
        <PreferencesIcon />
      </div>
    </Header>
  );
};

export default DefaultHeader;
