import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import FlexColumn from '../../order/components/FlexColumn';
import style from './style';
import SetupItemList from '../setup-item-list';
import { columns, prototype } from './setup';
import MixGeneral from './mix-general';
import MixComponents from './mix-components';
import useSetup from '../useSetup';
import ImportExport from '../components/import-export/ImportExport';
import MixFormulas from './mix-formulas';
import Button from '../../../components/button/Button';
import { Drawer } from 'antd';
import MixSynchronize from './mix-synchronize';
import SetupTabs from '../setup-item-editor/setup-tabs';
import MixLocationCopy from './mix-location-copy';

const Styled = styled(FlexColumn)`
  ${style}
`;

export const tabs = [
  {
    label: 'General',
    component: MixGeneral,
  },
  {
    label: 'Components',
    component: MixComponents,
  },
  {
    label: 'Custom Location Designs',
    component: MixFormulas,
  },
];

const extraTabs = [
  {
    label: 'Synchronize Mix Designs',
    component: MixSynchronize,
  },
  {
    label: 'Copy Location Mixes',
    component: MixLocationCopy,
  },
];

const ImportExportWrapped = props => {
  const { downloadFile, uploadFile } = useSetup();

  return (
    <ImportExport
      {...props}
      downloadFile={downloadFile}
      uploadFile={uploadFile}
      item={{ typeId: 'mix-design', crn: 'all', name: 'mix-design-list' }}
    />
  );
};

const SetupMix = () => {
  const [showDrawer, setShowDrawer] = useState(false);

  const openDrawer = useCallback(() => {
    setShowDrawer(true);
  }, [setShowDrawer]);

  const closeDrawer = useCallback(() => {
    setShowDrawer(false);
  }, [setShowDrawer]);

  const OpenDrawer = useCallback(() => {
    return (
      <Button key="SetupMix-OpenDrawer" size="small" onClick={openDrawer}>
        Manage Mix Designs
      </Button>
    );
  }, [openDrawer]);

  return (
    <>
      <Styled className="setup-mix">
        <SetupItemList
          typeId="mix"
          type="Mix Design"
          columns={columns}
          prototype={prototype}
          editorType="tabs"
          tabs={tabs}
          actions={[OpenDrawer, ImportExportWrapped]}
        />
      </Styled>
      <Drawer
        width={600}
        title="Manage Mix Designs"
        placement="right"
        closable
        onClose={closeDrawer}
        visible={showDrawer}
        destroyOnClose
      >
        <SetupTabs tabs={extraTabs} onCancel={closeDrawer} />
      </Drawer>
    </>
  );
};

export default SetupMix;
