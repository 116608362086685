import { Translate } from '../../../../components/localization/Translate';

const ticketForm = [
  [
    {
      type: 'label',
      value: 'displayLabels.totalShipped',
      icon: 'fa fa-clipboard',
      span: 6,
    },
    {
      icon: 'fa fa-flask',
      type: 'label',
      value: 'displayLabels.testResultStatus',
      span: 6,
    },
    {
      type: 'label',
      value: 'displayLabels.statusCode',
      tooltip: 'displayLabels.statusCodeTooltip',
      span: 6,
    },
    {
      type: 'label',
      value: 'displayLabels.accumulatedLoad',
      span: 4,
    },
  ],
  [
    {
      type: 'space',
      height: 18,
    },
  ],
  // [
  //   {
  //     type: 'input',
  //     value: 'id',
  //     label: 'Ticket Number',
  //   },
  //   {
  //     type: 'input',
  //     value: 'dispatchOrder.id',
  //     label: 'Dispatch Order',
  //   },
  //   {
  //     type: 'input',
  //     value: 'displayLabels.dispatchDateTimeDisplay',
  //     label: 'Dispatch Date / Time',
  //   },
  //   {
  //     type: 'input',
  //     value: 'purchaseOrder',
  //     label: 'Purchase Order',
  //   },
  // ],
  [
    {
      type: 'label',
      value: 'displayLabels.products',
      span: 24,
      style: { fontWeight: 'bold' },
    },
  ],
  [
    {
      type: 'list',
      value: 'displayLabels.formattedLineItems',
      span: 24,
    },
  ],
  [{ type: 'space', height: 18 }],
  [
    {
      type: 'label',
      value: 'displayLabels.waterAdded',
      span: 24,
    },
  ],
  [
    {
      type: 'divider',
    },
  ],
  [
    {
      type: 'input',
      value: 'displayLabels.origin',
      label: <Translate stringId="originPlant" defaultMessage="Origin Plant" />,
    },
    {
      type: 'input',
      value: 'containers[0].vehicle.id',
      label: <Translate stringId="vehicle" defaultMessage="Vehicle" />,
    },
    {
      type: 'input',
      value: 'displayLabels.carrier',
      label: <Translate stringId="carrier" defaultMessage="Carrier" />,
    },
    {
      type: 'input',
      value: 'displayLabels.driver',
      label: <Translate stringId="driver" defaultMessage="Driver" />,
    },
  ],
  [
    {
      type: 'text-area',
      value: 'displayLabels.destinationAddress',
      label: <Translate stringId="destinationAddress" defaultMessage="Destination Address" />,
      rows: 3,
      span: 24,
    },
  ],
  [
    {
      type: 'input',
      value: 'displayLabels.project',
      label: <Translate stringId="project" defaultMessage="Project" />,
    },
    {
      type: 'input',
      value: 'displayLabels.supplier',
      label: <Translate stringId="supplier" defaultMessage="Supplier" />,
    },
    {
      type: 'input',
      value: 'invoice.id',
      label: <Translate stringId="invoiceCode" defaultMessage="Invoice Code" />,
    },
    {
      type: 'input',
      value: 'displayLabels.projectContractNumber',
      label: <Translate stringId="contractNumber" defaultMessage="DOT Contract Number" />,
      span: 12,
    },
  ],
  [
    {
      type: 'divider',
    },
  ],
  [
    {
      type: 'label',
      value: 'displayLabels.ticketEvents',
      span: 24,
      style: { fontWeight: 'bold' },
    },
  ],
  [
    {
      type: 'list',
      value: 'displayLabels.formattedTicketEvents',
      span: 24,
    },
  ],
  [
    {
      type: 'divider',
    },
  ],
  [
    {
      type: 'input',
      value: 'displayLabels.customer',
      label: <Translate stringId="customer" defaultMessage="Customer" />,
      span: 24,
    },
  ],
  [
    {
      type: 'input',
      value: 'displayLabels.statusChangeTime',
      label: <Translate stringId="statusChangeTime" defaultMessage="Status Change Time" />,
      visible: (value, _ticket) => value,
    },
    {
      type: 'input',
      value: 'customerStatus.reason',
      label: <Translate stringId="customerComments" defaultMessage="Customer Comments" />,
      visible: (value, _ticket) => value,
    },
  ],
  [
    {
      type: 'map',
      value: 'crn',
      label: <Translate stringId="map" defaultMessage="Map" />,
      visible: (value, _ticket) => value,
      span: 24,
    },
  ],
];

export default ticketForm;
