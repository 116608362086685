import {css} from 'styled-components';

export default css`
  width: 100%;
  height: 100%;
  padding: 25px;
  text-align: center;
  
  .types {
    width: 100%;
  }
  
  .type {
    //width: 200px;
    margin: 10px;

    &:hover {
      cursor: pointer;
      color: #0e4980;
    }
  }
  
  .icon {
    font-size: 1.5em;
  }

  .label {
    font-size: 1.1em;
  }
`;
