export default legs => {
  const points = [];
  legs?.forEach?.(leg => {
    if (leg?.Geometry?.LineString?.length) {
      const steps = leg.Geometry.LineString;
      steps.forEach(step => {
        points.push({ lat: step[1], lng: step[0] });
      });
    }
  });
  return points;
};
