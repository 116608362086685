import { Drawer } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';
import { ConnexDataGrid } from '../../../components/connex-data-grid/ConnexDataGrid';
import Spinner from '../../../components/spinner/Spinner';
import FlexColumn from '../../order/components/FlexColumn';
import SetupHeader from '../setup-header';
import SetupItemEditor from '../setup-item-editor';
import useSetup from '../useSetup';
import style from './style';

const Styled = styled(FlexColumn)`
  ${style}
`;

const SetupItemList = ({
  title,
  typeId,
  type,
  columns,
  prototype,
  editorType = 'default',
  tabs,
  drawerWidth = 700,
  actions,
  removeEditorButtons,
  showAdd = true,
}) => {
  const { entityRef } = useParams();
  const { getSetupItems, setupItems, busy } = useSetup(typeId);
  const [editing, setEditing] = useState(null);
  const params = useMemo(
    () => ({
      activeOnly: false,
    }),
    []
  );

  const editorParams = {
    typeId,
    editorType,
    tabs,
  };

  useEffect(() => {
    entityRef && getSetupItems(params);
  }, [entityRef, getSetupItems, params]);

  const onSave = refresh => {
    setEditing(null);

    refresh && getSetupItems(params);
  };

  const onCancel = () => {
    setEditing(null);
  };

  const onAdd = () => {
    setEditing(prototype);
  };

  const handleRowClick = React.useCallback(row => {
    setEditing(row);
  }, []);

  return (
    <Styled className="setup-item-list">
      <SetupHeader title={title} type={type} onAdd={onAdd} showAdd={showAdd} />
      <Spinner spin={busy}>
        <div className="setup-table">
          <ConnexDataGrid
            showFiltersByDefault
            gridName={`Setup ${type}`}
            columns={columns}
            rows={setupItems}
            rowKeyPath="crn"
            onRowClick={handleRowClick}
            filterable
            rowClickable
            autoSizeColumns
            actions={actions}
            allowExport={true}
            onFinish={onCancel}
          />
        </div>
      </Spinner>
      <Drawer
        width={drawerWidth}
        title={`${type} Editor - ${editing?.name || editing?.id}`}
        placement="right"
        closable
        onClose={() => setEditing(null)}
        visible={editing}
        destroyOnClose
      >
        <SetupItemEditor
          removeButtons={removeEditorButtons === true || removeEditorButtons?.(editing)}
          item={editing}
          onSave={onSave}
          onCancel={onCancel}
          params={editorParams}
          columns={columns}
        />
      </Drawer>
    </Styled>
  );
};

export default SetupItemList;
