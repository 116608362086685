import { Drawer as AntDrawer } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useViewport } from '../../../../../hooks/useViewport';

const StyledDrawer = styled(AntDrawer)`
  .ant-drawer-body {
    display: flex;
    flex-direction: column;
  }
`;

export const useDrawer = width => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const closeDrawer = useCallback(() => {
    setDrawerOpen(false);
  }, []);

  const openDrawer = useCallback(() => {
    setDrawerOpen(true);
  }, []);

  const viewport = useViewport();

  const drawerWidth = useMemo(() => {
    return Math.min(width, viewport.width);
  }, [viewport.width, width]);

  return {
    drawerOpen,
    openDrawer,
    closeDrawer,
    drawerWidth,
  };
};
