import { useOrderListContext } from '../../../components/OrderListContext';
import { ConnexDataGrid } from '../../../../../components/connex-data-grid/components';
import React, { useCallback } from 'react';
import { useColumns } from './columnDef';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const Styled = styled.div`
  margin: 20px;
  display: flex;
  flex: 1;
  flex-direction: column;
  width: calc(100% - 40px);
  &&& {
    .rdg {
      margin: 0;
    }

    .filtered-data-grid {
      margin: 0;
    }
  }
`;

const AdvancedSearchResults = () => {
  const { orders } = useOrderListContext();
  const columns = useColumns();
  const navigate = useNavigate();
  const handleRowClick = useCallback(row => {
    const orderType = row.sk?.type || 'CONCRETE';
    navigate(`edit/${orderType}/${row?.crn}/general`);
  }, []);

  if (orders.length === 0) {
    return null;
  }

  return (
    <Styled>
      <ConnexDataGrid
        showFiltersByDefault
        gridName={`Advanced Order Search`}
        columns={columns}
        rows={orders}
        rowKeyPath="crn"
        defaultFilters={{}}
        onRowClick={handleRowClick}
        allowExport={true}
        rowClickable
        autoSizeColumns
        filterable
      />
    </Styled>
  );
};

export default AdvancedSearchResults;
