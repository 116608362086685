import styled from 'styled-components';

const Overlay = styled.div`
  display: ${({ $busy }) => ($busy ? 'inherit' : 'none')};
  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
    background-color: #fff;
    opacity: 0.4;
    height: 100%;
  }

  .spinner {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: #333;
    font-size: 4rem;
  }
`;

export default Overlay;
