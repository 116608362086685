import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
import LastTare from './LastTare';
import ManualToggle from './ManualToggle';
import MaxGvw from './MaxGvw';

const Styled = styled.div`
  display: flex;
  justify-content: space-around;
  height: 70px;
`;

const VehicleWeights = props => {
  return (
    <Styled className={cn('vehicle-weights')}>
      {/*<Quanity />*/}
      <MaxGvw />
      <LastTare />
    </Styled>
  );
};

export default VehicleWeights;
