import cn from 'classnames';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import styled from 'styled-components';
import { Internationalization } from '../../i18n';
import FlexColumn from './components/FlexColumn';
import OrderListContextProvider from './components/OrderListContext';
import CopyOrder from './copy-order/CopyOrder';
import CreateOrder from './create-order/CreateOrder';
import OrderList from './order-list/OrderList';
import VersionCheck from './version/VersionCheck';
import ViewOrEditOrder from './view-or-edit-order';
import OrderRequest from './dispatch-order-request/OrderRequest';

const ConnexOrder = ({ className }) => {
  return (
    <VersionCheck>
      <FlexColumn className={cn('ConnexOrder', className)}>
        <Internationalization>
          <OrderListContextProvider>
            <Routes>
              <Route path="/new/:orderType" element={<CreateOrder />} />
              <Route path="/copy/:orderType/:orderRef" element={<CopyOrder />} />
              <Route path="/order-request/:orderType/:orderRef" element={<OrderRequest />} />
              <Route path="/edit/:orderType/:orderId/:tab" element={<ViewOrEditOrder />} />
              <Route path="/" element={<OrderList />} />
            </Routes>
          </OrderListContextProvider>
        </Internationalization>
      </FlexColumn>
    </VersionCheck>
  );
};

export default styled(ConnexOrder)`
  height: 100%;
`;
