import style from "../style";
import React from "react";
import styled from 'styled-components';
import {status} from './options';
import InputText from "../../components/input-text";
import InputSelect from "../../components/input-select";
import SelectSetupItem from "../../components/select-setup-item";

const Styled = styled.div`${style}`;

const WorkTypeGeneral = ({ item: workType, onChange }) => {

  return (
    <Styled>
      <InputText id='id' label='ID' value={workType?.id} onChange={onChange}/>
      <InputText id='name' label='Name' value={workType?.name} onChange={onChange}/>
      <SelectSetupItem
        label='Associated Product Type'
        typeId='product-type'
        item={workType}
        onChange={onChange}/>
      <InputSelect id='status'
                   label='Status'
                   value={workType?.status}
                   onChange={onChange}
                   options={status}
                   required
      />
    </Styled>
  )
}

export default WorkTypeGeneral;
