import React from 'react';
import Select from '../../../../components/form/Select';
import styled from 'styled-components';
import PrintWeightOptions from './print-weights-options';

const { Option } = Select;

const Title = styled.div`
  margin: 0;
  font-size: 12px;
  font-weight: bold;
`;

const Label = styled.div`
  padding: 5px;
  margin-top: 5px;
`;

const Styled = styled.div`
  margin-bottom: 15px;
`;

const SelectTicketOptions = ({ view = '', item, onChange }) => {
  let printPaperTickets = item?.ticketOptions?.printPaperTickets || 'yes';
  let printWeights = item?.ticketOptions?.printWeights || '';

  const printWeightOptions = PrintWeightOptions.filter(
    option => option.view === undefined || option.view === 'all' || option.view === view
  );

  const printTicketsChanged = value => {
    printPaperTickets = value;
    onChange && onChange('ticketOptions', getTicketOptions());
  };

  const printWeightsChanged = value => {
    printWeights = value;
    onChange && onChange('ticketOptions', getTicketOptions());
  };

  const getTicketOptions = () => {
    return { printWeights: printWeights, printPaperTickets: printPaperTickets };
  };

  return (
    <Styled>
      <Title>Ticket Options</Title>

      <Label>Print Paper Tickets</Label>
      <Select id="printPaperTickets" value={printPaperTickets} onChange={printTicketsChanged}>
        <Option key={'pTicket1'} value={'yes'}>
          Yes
        </Option>
        <Option key={'pTicket0'} value={'no'}>
          No
        </Option>
      </Select>

      <Label>Print Weights</Label>
      <Select id="printWeights" value={printWeights} onChange={printWeightsChanged}>
        <Option value="">Select...</Option>
        {printWeightOptions?.map((item, idx) => (
          <Option key={item.value + idx} value={item.value}>
            {item.label}
          </Option>
        ))}
      </Select>
    </Styled>
  );
};

export default SelectTicketOptions;
