import { css } from 'styled-components';

export default css`
  table {
    margin-top: 10px;
    width: 100%;
  }

  thead th {
    background-color: #0e4980;
    padding: 5px;
    border: solid 1px #3a3a3a;
  }

  tbody td {
    padding: 6px;
    border: solid 1px #333;
  }

  .button-container {
    margin-top: 10px;
    display: flex;
    justify-content: right;
  }

  .row-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .icon-extra-padding {
    padding-left: 10px;
    padding-right: 10px;
  }
`;
