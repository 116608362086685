import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import CurrencyFormat from 'react-currency-format';

const Styled = styled.div`
  text-align: right;
  padding-right: 5px;
`;

const MoneyRenderer = ({ value }) => {
  const [money, setMoney] = useState(null);

  useEffect(() => {
    const c = parseFloat(`${value ?? '0'}`);

    value && setMoney( isNaN(c) ? 0 : c)
  }, [value])

  return (
    <Styled className={cn('cell')}>
      {(money && <CurrencyFormat
        value={money ?? 0.00}
        displayType={'text'}
        thousandSeparator={true}
        prefix={''}
        decimalScale={2}
        fixedDecimalScale={true}
      />) || '0.00'}
    </Styled>
  )
};

export default MoneyRenderer;
