import SelectSetupItem from '../select-setup-item';
import React from 'react';

const SelectCreditCode = ({ item, onChange }) => {
  const changeCreditCode = (_, value) => {
    onChange('creditCodeRef', value);
  };

  const hasPreselectedCustomer = !!item?.creditCode;

  return (
    <SelectSetupItem
      label="Credit Code"
      typeId="credit-code"
      item={item}
      setupItemRef={item?.creditCodeRef}
      onChange={changeCreditCode}
      hasDefaultItem={hasPreselectedCustomer}
      onlyReturnRef={true}
    />
  );
};

export default SelectCreditCode;
