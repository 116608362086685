import http from '../../../../../http';

const orders = '/connex/services/orders';

export const api = {
  getOrder: (ref, orderRef, queryParams) => http.get(`${orders}/${ref}/orders/${orderRef}`, queryParams),

  createOrderTicket: (ref, orderRef, data) => http.post(`${orders}/${ref}/orders/${orderRef}/tickets`, data),

  getTicket: (ref, ticketRef) => http.get(`${orders}/${ref}/tickets/${ticketRef}`),
  getLocations: (ref, params) => http.get(`${orders}/${ref}/master-data/locations`, params),
  getDrivers: (ref, params) => http.get(`${orders}/${ref}/setup/driver`, params),

  listOrderCarriers: (ref, orderRef) => http.get(`${orders}/${ref}/orders/${orderRef}/carriers`),
  listOrderVehicles: (ref, orderRef, queryParams) =>
    http.get(`${orders}/${ref}/orders/${orderRef}/vehicles`, queryParams),

  getRules: (ref, triggerPoint) => http.get(`${orders}/${ref}/master-data/rules`, { triggerPoint }),
};
