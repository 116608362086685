import React from 'react';
import cn from 'classnames';
import moment from 'moment';
import styled from 'styled-components';
import getUomAbbreviationOrDescription from '../../../util/uom';

const TicketList = ({ className, tickets }) => {
  return (
    <div className={cn(className)}>
      {Object.keys(tickets).map(dispatch => {
        return (
          <div className="dispatch">
            <div className="dispatch-number">Dispatch {dispatch}</div>
            {tickets[dispatch].map(ticket => {
              return (
                // <div style={{ border: '1px solid #434343', padding: '10px', marginTop: '20px' }}>
                <div className="ticket-detail">
                  <div className="ticket-number">Ticket # {ticket?.ticketNumber?.toUpperCase()}</div>
                  <div className="dispatch-date">{moment(ticket.dispatchDate).format('LLL')}</div>

                  <div className="load-number">Load # {ticket.loadNumber}</div>

                  <div className="load-amounts">
                    {ticket.deliveredQty} / {ticket.orderedQty}{' '}
                    {getUomAbbreviationOrDescription(ticket.uomCode).toUpperCase()}
                  </div>
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default styled(TicketList)`
  .dispatch {
    &:not(:first-child) {
      margin-top: 15px;  
    }
    
    border: 1px solid #434343;
    padding: 10px;
    background-color: #282828;    
    .dispatch-number{}
    .ticket-detail {
      margin-left: 20px;
      border: 1px solid #434343;
      padding: 10px;
      margin-top: 20px;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(175px, 1fr));
    }
    .dispatch-date {
      text-align: right;
    }
    .load-amounts {
      text-align: right;
    }
  }
`;
