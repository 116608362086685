const fieldNames = {
  id: 'ID',
  name: 'Name',
  status: 'Status',
  carrierParty: 'Carrier',
  reasonType: 'Reason Type',
};

const requiredFields = {
  vehicle: ['id', 'name', 'status', 'carrierParty'],
  cancelReason: ['id', 'name', 'status', 'reasonType'],
};

const getErrorMessage = fieldId => {
  const fieldName = fieldNames[fieldId];
  return fieldName ? `Missing required field ${fieldName}` : `Missing required field '${fieldId}'`;
};

const isValid = (item, typeId) => {
  if (typeId) {
    const fields = requiredFields[typeId];
    if (fields?.length) {
      for (let i = 0; i < fields.length; i++) {
        const fieldId = fields[i];
        if (!item[fieldId]) {
          return getErrorMessage(fieldId);
        }
      }
    }
  }
  return null;
};

export default isValid;
