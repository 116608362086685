import React from 'react';
import styled from 'styled-components';
import getUomAbbreviationOrDescription from '../../../../../../../../util/uom';
import style from './style';

const LineItem = ({ lineItem, onClick }) => {
  return (
    <div
      className="line-item"
      onClick={() => {
        onClick(lineItem);
      }}
      key={lineItem._id}
    >
      <div className="product">
        {lineItem?.item?.id} / {lineItem?.item?.name}
      </div>

      <div className="other-info">
        <div className="line-item-field quantity">
          <div className="heading">Quantity:</div>
          <div className="value">
            {lineItem?.orderedQuantity?.value} {getUomAbbreviationOrDescription(lineItem?.orderedQuantity?.uomCode, 5)}
            {lineItem?.properties?.cleanup ? ' + Cleanup' : ''}
          </div>
        </div>
      </div>

      <div className="other-info">
        {lineItem?.properties?.holdQuantity ? (
          <div className="line-item-field hold-quantity">
            <div className="heading">Hold Quantity</div>
            <div className="value">{lineItem?.properties?.holdQuantity}</div>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default styled(LineItem)`
  ${style}
`;
