import { Drawer } from 'antd';
import SetupItemForm from '../../setup-item-editor/form';
import { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import FlexColumn from '../../../order/components/FlexColumn';
import style from './style';
import Button from '../../../../components/button/Button';
import { v4 as uuid } from 'uuid';
import FormulaGeneral from './formula-general';
import MixComponents from '../mix-components';
import FormulaLocations from './formula-locations';
import { find } from 'lodash';
import useSetup from '../../useSetup';
import FormError from '../../components/form-error';
import { activeStatus } from './formula-general/options';
import { cloneDeep } from 'lodash';
import SyncStatusBadge from "../SyncStatusBadge";

const Styled = styled(FlexColumn)`
  ${style}
`;

const tabs = [
  {
    label: 'General',
    component: FormulaGeneral,
  },
  {
    label: 'Components',
    component: MixComponents,
  },
  {
    label: 'Locations',
    component: FormulaLocations,
  },
];

const FormulaDetails = ({ formula, onSelect, onRemove, locations, disableButtons }) => {
  const locationDisplay = useMemo(() => {
    if (!formula?.locations) {
      return 'NONE';
    }
    if (formula?.locations?.all) {
      return 'ALL';
    }

    const locationIds = Object.entries(formula?.locations)
      .map(([key, value]) => {
        if (value) {
          const location = find(locations, { crn: key });
          if (location) {
            return location.id;
          }
        }
        return null;
      })
      .filter(l => l)
      .join(', ');

    return locationIds || 'NONE';
  }, [formula?.locations, locations]);
  return (
    <div className={'formula-card'} onClick={onSelect}>
      <div>Name: {formula.name} <SyncStatusBadge ticket={formula}></SyncStatusBadge></div>
      <div>Locations: {locationDisplay}</div>
      <div>Status: {formula.status}</div>
      {!disableButtons && (
        <Button
          metricId={'mix-formula-remove'}
          onClick={e => {
            e.stopPropagation();
            onRemove();
          }}
        >
          <span className={'fa fa-minus'} style={{ marginRight: '0.5rem' }} />
          Remove Custom Design
        </Button>
      )}
    </div>
  );
};

const MixFormulas = ({ item, onChange, drawerWidth = 500, disableButtons }) => {
  const { locations, getLocations } = useSetup();

  const [editingItem, setEditingItem] = useState(null);
  const [editingItemIndex, setEditingItemIndex] = useState(null);

  const [usedLocations, setUsedLocations] = useState([]);

  const [locationWarningMessage, setLocationWarningMessage] = useState('');

  const [saveWarningMessage, setSaveWarningMessage] = useState('');

  const [disableSaving, setDisableSaving] = useState(false);

  const [error, setError] = useState(null);

  useEffect(() => {
    getLocations().then();
  }, [getLocations]);

  useEffect(() => {
    let locations = [];
    let intersection = [];
    let itemLocations = [];

    if (editingItem) {
      for (let i = 0; i < item?.formulas.length; i++) {
        const f = item.formulas[i];
        if (f.status === 'ACTIVE' && f.locations && i !== editingItemIndex) {
          for (const [key, value] of Object.entries(f.locations)) {
            locations = value ? [...locations, key] : locations;
          }
        }
      }
      setUsedLocations(locations);
      if (editingItem.locations) {
        for (const [key, value] of Object.entries(editingItem.locations)) {
          itemLocations = value ? [...itemLocations, key] : itemLocations;
        }
        intersection = itemLocations.filter(l => locations.includes(l));
      }
    }
    locations.length > 0
      ? setLocationWarningMessage(
          'Some locations are unavailable to select as they are already in use on another location specific mix design record.'
        )
      : setLocationWarningMessage('');

    if (intersection.length > 0) {
      setDisableSaving(true);
      setSaveWarningMessage(
        'There can be only one custom location design per plant. There is already an active mix design for one of the locations selected here.'
      );
    } else {
      setDisableSaving(false);
      setSaveWarningMessage('');
    }
  }, [editingItem, editingItemIndex, item]);

  const saveFormula = useCallback(
    index => {
      const hasActiveLocations = Object.keys(editingItem?.locations || {})?.some(l => editingItem?.locations?.[l]);

      if (!hasActiveLocations) {
        setError('No Locations Selected.');
        return;
      }

      setError(null);

      let found = false;

      const formulas = item.formulas.map((f, ind) => {
        if (index === ind) {
          found = true;
          return editingItem;
        }
        return f;
      });

      if (!found) {
        formulas.push(editingItem);
      }

      onChange('formulas', formulas);
      setEditingItem(null);
      setEditingItemIndex(null);
      setUsedLocations([]);
    },
    [onChange, editingItem, item]
  );

  const removeFormula = useCallback(
    index => {
      const formulas = item.formulas.filter((_f, ind) => ind !== index);
      onChange('formulas', formulas);
    },
    [item, onChange]
  );

  const newFormula = useCallback(() => {
    setEditingItem({ formulaRef: uuid(), status: activeStatus });
  }, []);

  return (
    <>
      <Styled>
        {item.formulas &&
          item.formulas.map((f, ind) => (
            <FormulaDetails
              locations={locations}
              onRemove={() => removeFormula(ind)}
              onSelect={() => {
                setEditingItem(cloneDeep(f));
                setEditingItemIndex(ind);
              }}
              key={`${f.id}-${f.name}-${ind}`}
              formula={f}
              disableButtons={disableButtons}
            />
          ))}
        {!disableButtons && (
          <Button metricId={'add-new-formula'} onClick={newFormula}>
            <span className={'fa fa-plus'} style={{ marginRight: '1rem' }} /> Add Custom Design
          </Button>
        )}
      </Styled>
      <Drawer
        onClose={() => {
          setEditingItem(null);
          setEditingItemIndex(null);
          setError(null);
        }}
        destroyOnClose={true}
        visible={!!editingItem}
        width={drawerWidth}
      >
        <>
          <FormError error={error} style={{ marginTop: '3px' }} />
          <SetupItemForm
            typeId={'formula'}
            editorType={'tabs'}
            tabs={tabs}
            setupItem={editingItem}
            onChange={(field, value) =>
              setEditingItem(existingEditingItem => ({ ...existingEditingItem, [field]: value }))
            }
            onSaveItem={() => saveFormula(editingItemIndex)}
            onCancel={() => {
              setEditingItem(null);
              setError(null);
            }}
            validated={true}
            disableButtons={disableButtons}
            removeButtons={disableButtons}
            usedLocations={usedLocations}
            locationWarningMessage={locationWarningMessage}
            saveWarningMessage={saveWarningMessage}
            disableSaving={disableSaving}
          />
        </>
      </Drawer>
    </>
  );
};

export default MixFormulas;
