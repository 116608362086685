import { find, pick } from 'lodash';
import React from 'react';
import Select from '../../../components/form/Select';
import Margin from '../components/Margin';
import { useScaleTicketingContext } from '../Context';

const { Option } = Select;

const Carrier = () => {
  const { carriers, form, handleChange } = useScaleTicketingContext();

  const handleCarrierChange = React.useCallback(
    ref => {
      const targetCarrier = find(carriers, { crn: ref });
      if (targetCarrier) {
        handleChange('vehicle', s => ({
          ...s,
          configuration: {
            ...s.configuration,
            carrier: {
              ...pick(targetCarrier, ['id', 'name']),
              carrierRef: targetCarrier.crn,
            },
          },
        }));
      }
    },
    [handleChange, carriers]
  );

  return (
    <Margin top={10}>
      <div className="field-label">Carrier</div>
      <Select
        value={form?.vehicle?.configuration?.carrier?.carrierRef || ''}
        showSearch
        filterOption={(input, option) => option?.children?.toLowerCase?.().indexOf(input.toLowerCase?.()) >= 0}
        disabled={false}
        onChange={handleCarrierChange}
      >
        {carriers?.map(carrier => (
          <Option key={carrier?.crn} value={carrier.crn}>
            {`${carrier.id} / ${carrier.name}`}
          </Option>
        ))}
      </Select>
    </Margin>
  );
};

export default Carrier;
