import { get, padStart } from 'lodash';
import { DateTime } from 'luxon';
import getUomAbbreviationOrDescription from '../../../../../util/uom';
import StatusBadge from '../../../components/status-badge/StatusBadge';
import React, { useMemo } from 'react';
import { useIntlContext } from '../../../../../i18n';

const formatId = (...items) => {
  return items.filter(i => i).join(' / ');
};

const getDateFormat = locale => {
  const date = new Date();
  const dateFormat = Intl.DateTimeFormat(locale, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }).formatToParts(date);

  let formattedDate = '';
  for (const part of dateFormat) {
    switch (part.type) {
      case 'day':
        formattedDate += 'dd';
        break;
      case 'month':
        formattedDate += 'MM';
        break;
      case 'year':
        formattedDate += 'yyyy';
        break;
      default:
        formattedDate += part.value;
    }
  }
  return formattedDate;
};

export const useColumns = () => {
  const { userLocale } = useIntlContext();

  return useMemo(() => {
    return [
      {
        key: 'sk.orderId',
        name: 'Number',
      },
      {
        key: 'primaryKey',
        name: 'Start Date/Time',
        formatter: ({ row }) => {
          const isoString = row?.primaryKey;
          if (!isoString) {
            return null;
          }
          const dateTime = DateTime.fromISO(isoString);
          return isoString ? (
            <div className="startDateTime">{`${dateTime.toFormat(getDateFormat(userLocale))} ${padStart(
              dateTime.toFormat('t ZZZZ'),
              12,
              '0'
            )}`}</div>
          ) : (
            ''
          );
        },
      },
      {
        key: 'sk.productId',
        filterKey: ['sk.productId', 'sk.productName'],
        name: 'Product',
        formatter: ({ row }) => {
          const primaryLineItem = row?.sk?.lineItems?.[0];
          return formatId(primaryLineItem?.productId, primaryLineItem?.productName);
        },
      },
      {
        key: 'sk.shippedQuantity',
        name: 'Quantity',
        formatter: ({ row }) => {
          return `${row?.sk?.shippedQuantity || '0'} / ${row?.sk?.orderedQuantity} ${getUomAbbreviationOrDescription(
            row?.sk?.uomCode
          )}`;
        },
      },
      {
        key: 'sk.supplierStatusName',
        name: 'Status',
        formatter: props => <StatusBadge status={get(props, 'row.sk.supplierStatusName')} />,
      },
      {
        key: 'sk.customerId',
        filterKey: ['sk.customerId', 'sk.customerName'],
        name: 'Customer',
        formatter: props => {
          const id = get(props, 'row.sk.customerId');
          const name = get(props, 'row.sk.customerName');
          return formatId(id, name);
        },
      },
      {
        key: 'sk.projectId',
        filterKey: ['sk.projectId', 'sk.projectName'],
        name: 'Project',
        formatter: props => {
          const id = get(props, 'row.sk.projectId', '');
          const name = get(props, 'row.sk.projectName', '');
          return formatId(id, name);
        },
      },
      {
        key: 'sk.locationId',
        filterKey: ['sk.locationId', 'sk.locationName'],
        name: 'Plant',
        formatter: props => {
          const id = get(props, 'row.sk.locationId');
          const name = get(props, 'row.sk.locationName');
          return formatId(id, name);
        },
      },
      {
        key: 'sk.purchaseOrder',
        name: 'Purchase Order',
        formatter: props => {
          return get(props, 'row.sk.purchaseOrder', '');
        },
      },
    ];
  }, [userLocale]);
};
