import { css } from 'styled-components';

export default css`
  display: grid;
  grid-template-columns: auto 1fr;

  .buttons {
    position: relative;
    padding-left: 10px;
    .invoice-button {
      position: absolute;
      right: 0;
    }
  }
`;
