const uomCodes = [
  {
    isoCode: 'INH',
    description: 'Inches',
    display: 'IN',
  },
  {
    isoCode: 'FOT',
    description: 'Feet',
    display: 'FT',
  },
  {
    isoCode: 'YRD',
    description: 'Yards',
    display: 'YD',
  },
  {
    isoCode: 'MMT',
    description: 'Millimeters',
    display: 'MM',
  },
  {
    isoCode: 'CMT',
    description: 'Centimeters',
    display: 'CM',
  },
  {
    isoCode: 'MTR',
    description: 'Meters',
    display: 'M',
  },
  {
    isoCode: 'SMI',
    description: 'Miles',
    display: 'MI',
  },
  {
    isoCode: 'KMT',
    description: 'Kilometers',
    display: 'KM',
  },
  {
    isoCode: 'INK',
    description: 'Square Inches',
    display: 'SQ IN',
  },
  {
    isoCode: 'FTK',
    description: 'Square Feet',
    display: 'FT2',
  },
  {
    isoCode: 'YDK',
    description: 'Square Yards',
    display: 'SQ YD',
  },
  {
    isoCode: 'MIK',
    description: 'Square Miles',
    display: 'SQ MI',
  },
  {
    isoCode: 'ACR',
    description: 'Acres',
    display: 'AC',
  },
  {
    isoCode: 'MMK',
    description: 'Square Millimeters',
    display: 'MM2',
  },
  {
    isoCode: 'CMK',
    description: 'Square Centimeters',
    display: 'CM2',
  },
  {
    isoCode: 'MTK',
    description: 'Square Meters',
    display: 'M2',
  },
  {
    isoCode: 'KMK',
    description: 'Square Kilometers',
    display: 'KM2',
  },
  {
    isoCode: 'INQ',
    description: 'Cubic Inches',
    display: 'CU IN',
  },
  {
    isoCode: 'FTQ',
    description: 'Cubic Feet',
    display: 'FT3',
  },
  {
    isoCode: 'YDQ',
    description: 'Cubic Yards',
    display: 'CY',
  },
  {
    isoCode: 'MMQ',
    description: 'Cubic Millimeters',
    display: 'MM3',
  },
  {
    isoCode: 'CMQ',
    description: 'Cubic Centimeters',
    display: 'CM3',
  },
  {
    isoCode: 'MTQ',
    description: 'Cubic Meters',
    display: 'M3',
  },
  {
    isoCode: 'OZA',
    description: 'Ounces',
    display: 'FL OZ',
  },
  {
    isoCode: 'PT',
    description: 'Pints',
    display: 'PT',
  },
  {
    isoCode: 'QT',
    description: 'Quarts',
    display: 'QT',
  },
  {
    isoCode: 'GLL',
    description: 'Gallons',
    display: 'GA',
  },
  {
    isoCode: 'LTR',
    description: 'Liters',
    display: 'L',
  },
  {
    isoCode: 'MLT',
    description: 'Milliliters',
    display: 'mL',
  },
  {
    isoCode: 'ONZ',
    description: 'Ounces',
    display: 'OZ',
  },
  {
    isoCode: 'LBR',
    description: 'Pounds',
    display: 'LB',
  },
  {
    isoCode: 'STN',
    description: 'Tons',
    display: 'TN',
  },
  {
    isoCode: 'MTN',
    description: 'Metric Tons',
  },
  {
    isoCode: 'GTN',
    description: 'Gigatons',
  },
  {
    isoCode: 'GRM',
    description: 'Grams',
    display: 'G',
  },
  {
    isoCode: 'KGM',
    description: 'Kilograms',
    display: 'KG',
  },
  {
    isoCode: 'TNE',
    description: 'Cubic Tons',
    display: 'T',
  },
  {
    isoCode: 'PS',
    description: 'Pounds Per Square Inch',
    display: 'PSI',
  },
  {
    isoCode: 'MPA',
    description: 'Megapascals',
    display: 'MPa',
  },
  {
    isoCode: 'C56',
    description: 'Newtons Per Square',
  },
  {
    isoCode: '/NL',
    description: 'Per Load',
    display: '/L',
  },
  {
    isoCode: '/EA',
    description: 'Per Quantity',
    display: '/EA',
  },
  {
    isoCode: '/H/ + h',
    description: 'Per Hour',
    display: '/Hour',
  },
  {
    isoCode: '/YQ/ + YDQ',
    description: 'Per Cubic Yard',
    display: '/CY',
  },
  {
    isoCode: '/MQ/ + MTQ',
    description: 'Per Cubic Meter',
    display: '/M3',
  },
  {
    isoCode: '/MN/ + MIN',
    description: 'Per Minute',
    display: '/MIN',
  },
  {
    isoCode: '/MT',
    description: 'Per Meter',
    display: '/M',
  },
  {
    isoCode: 'DSG',
    description: 'Dosage',
  },
  {
    isoCode: 'P1',
    description: 'Percent',
    display: '%',
  },
  {
    isoCode: '/C',
    description: 'Per 100 Weight',
    display: '/C',
  },
  {
    isoCode: 'CEL',
    description: 'Degrees Celsius',
    display: 'C',
  },
  {
    isoCode: 'FAH',
    description: 'Degrees Fahrenheit',
    display: 'F',
  },
  {
    isoCode: 'BLL',
    description: 'Barrel',
  },
  {
    isoCode: 'CLT',
    description: 'Centiliter',
    display: 'cL',
  },
  {
    isoCode: 'DLT',
    description: 'Deciliter',
    display: 'dL',
  },
  {
    isoCode: 'GLI',
    description: 'Imperial Gallon',
    display: 'GAL',
  },
  {
    isoCode: '2U',
    description: 'Megagram',
    display: 'Mg',
  },
  {
    isoCode: 'SA',
    description: 'Sack',
    display: 'Sack',
  },
  {
    isoCode: 'NL',
    description: 'Load',
  },
  {
    isoCode: 'EA',
    description: 'Each',
    display: 'EA',
  },
  {
    isoCode: '60',
    description: 'Percent Weight',
  },
  {
    isoCode: 'BG',
    description: 'Bag',
  },
  {
    isoCode: 'SEC',
    description: 'Second',
    display: 'SEC',
  },
  {
    isoCode: 'MIN',
    description: 'Minute',
    display: 'MIN',
  },
  {
    isoCode: 'HUR',
    description: 'Hour',
    display: 'HR',
  },
  {
    isoCode: '64',
    description: 'Pounds Per Square Inch, Gauge',
  },
  {
    isoCode: 'PS',
    description: 'Pounds Per Square Inch, Absolute',
  },
  {
    isoCode: '64',
    description: 'Specific',
  },
];

export default uomCodes;
