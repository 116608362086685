import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import moment from 'moment';

const Timestamp = ({ className, timestamp }) => {
  return <div className={cn('note-timestamp', className)}>{moment(timestamp).format('LLLL')}</div>;
};

export default styled(Timestamp)``;
