import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';

const Styled = styled.div`
  height: 250px;
  width: 350px;
  background-color: #555;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 60px;
  i {
    opacity: 20%;
  }
`;
const Image = props => {
  return (
    <Styled className={cn('image')}>
      <i className="fal fa-camera" />
    </Styled>
  );
};

export default Image;
