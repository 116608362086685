import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';

const Styled = styled.div`
  position: absolute;
  bottom: 0;
  height: 2px;
  background-color: #fff;
`;
const ProgressBar = ({ progressPercentage }) => {
  return (
    <Styled
      className={cn('progress-bar')}
      style={{
        width: `${progressPercentage}%`,
        transition: progressPercentage > 0 ? 'width 0.3s, opacity 1s' : '',
        opacity: progressPercentage === 100 ? '0' : '1',
      }}
    />
  );
};

export default ProgressBar;
