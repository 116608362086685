import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { startCase } from 'lodash';
import style from './style';

const Styled = styled.div`
  ${style}
`;
const SupplierStatusTooltip = ({ status }) => {
  return (
    <Styled className={cn('status-tooltip')}>
      <div className="text">Status: {startCase(status.statusCode.toLowerCase())}</div>
      <div className="reason-code">Reason Code: {status.reasonCode}</div>
      <div className="reason">Note: {status.reason}</div>
    </Styled>
  );
};

export default SupplierStatusTooltip;
