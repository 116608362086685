import Destination from './Destination';
import Origin from './Origin';
import Route from './Route';
import Truck from './Truck';
import Waypoint from './Waypoint';

export default {
  plant: Origin,
  destination: Destination,
  route: Route,
  waypoint: Waypoint,
  truck: Truck,
};
