import { css } from 'styled-components';

export default css`
  width: 100%;

  table {
    width: 100%;
  }

  thead th {
    background-color: #0e4980;
    padding: 5px;
    border: solid 1px #666;
  }

  tbody tr {
    cursor: pointer;

    &:hover {
      background-color: #3a3a3a;
    }
  }

  tbody td {
    padding: 5px;
    border: solid 1px #666;
  }

  .setup-table {
    width: 100%;
    flex: 1;
  }
`;
