import React from 'react';
import { useTranslateMessage } from '../../../../../order/components/localization/useTranslateMessage';
import InputNumber from '../../../../../setup/components/input-number';
import styled from 'styled-components';
import style from './style';
import InputSelect from '../../../../../setup/components/input-select';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import HeaderOption from '../../../components/tracking-header-option';

const Styled = styled.div`
  ${style}
`;

const GeneralSettings = ({ saveTrackingConfiguration, sortTrackingHeaders, trackingConfiguration, licenses }) => {
  const translateMessage = useTranslateMessage();

  const orderLeadTimeLabel = React.useMemo(() => {
    return translateMessage({ stringId: 'orderLeadTime', defaultMessage: 'Order Lead Time' });
  }, [translateMessage]);

  const orderLeadTimeUnitLabel = React.useMemo(() => {
    return translateMessage({ stringId: 'orderLeadTimeUnit', defaultMessage: 'Unit' });
  }, [translateMessage]);

  const unitList = React.useMemo(() => {
    return [
      { value: 'hours', label: translateMessage({ stringId: 'hours', defaultMessage: 'Hours' }) },
      { value: 'minutes', label: translateMessage({ stringId: 'minutes', defaultMessage: 'Minutes' }) },
    ];
  }, [translateMessage]);

  const [orderHeaderOptions, setOrderHeaderOptions] = React.useState(trackingConfiguration?.columns?.order);

  React.useEffect(() => {
    setOrderHeaderOptions(trackingConfiguration?.columns?.order);
  }, [trackingConfiguration?.columns?.order]);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    const updatedIndexes = result.map((el, index) => {
      return { ...el, idx: index };
    });
    return updatedIndexes;
  };

  const onDragEnd = React.useCallback(
    ({ source, destination }) => {
      if (!destination) {
        return;
      }
      const reorderedOptions = reorder(orderHeaderOptions, source.index, destination.index);
      setOrderHeaderOptions(reorderedOptions);
      sortTrackingHeaders('order', reorderedOptions);
    },
    [orderHeaderOptions, sortTrackingHeaders]
  );

  return (
    <Styled>
      <div className="inputs">
        <InputNumber
          label={orderLeadTimeLabel}
          onChange={saveTrackingConfiguration}
          id={'orderLeadTime'}
          value={trackingConfiguration.orderLeadTime ?? 12}
        />
        <InputSelect
          id={'orderLeadTimeUnit'}
          value={trackingConfiguration.orderLeadTimeUnit || 'hours'}
          label={orderLeadTimeUnitLabel}
          onChange={saveTrackingConfiguration}
          options={unitList}
        />
      </div>
      {licenses?.orders && (
        <div className="container">
          <h3>Order Headings to Display</h3>
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th style={{ width: '5%' }}>Show</th>
                  <th style={{ width: '70%' }}>Name</th>
                  <th style={{ width: '25%' }}>Width</th>
                </tr>
              </thead>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="header-option-droppable">
                  {provided => (
                    <tbody {...provided.droppableProps} ref={provided.innerRef}>
                      {orderHeaderOptions.map((option, index) => (
                        <Draggable key={option.id} draggableId={option.id} index={index}>
                          {provided => (
                            <HeaderOption
                              type={'columns_order'}
                              dragRef={provided.innerRef}
                              draggableProps={provided.draggableProps}
                              dragHandleProps={provided.dragHandleProps}
                              option={option}
                              saveTrackingConfiguration={saveTrackingConfiguration}
                              index={index}
                            />
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </tbody>
                  )}
                </Droppable>
              </DragDropContext>
            </table>
          </div>
        </div>
      )}
    </Styled>
  );
};

export default GeneralSettings;
