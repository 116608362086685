import React, { useState } from "react";
import { InfoWindow, Marker } from "@react-google-maps/api";
import moment from "moment";
import ticketEventLabels from '../../ticket-event-labels';

const icons = {
  CONCRETE: 'CONCRETE',
  AGGREGATE: 'AGGREGATE',
  ASPHALT: 'ASPHALT',
  OTHER_PRODUCT: 'OTHER_PRODUCT',
};

const TicketMarker = ({ order, ticket }) => {
  const [showInfo, setShowInfo] = useState(false);
  const productType = order?.lineItems?.[0]?.item?.type;
  const icon = icons[productType] || icons['OTHER_PRODUCT'];

  return (
    <Marker
      key={ticket?.ticketRef}
      icon={{ url: `/assets/map/${icon}.png`, scaledSize: { width: 50, height: 75 } }}
      onClick={() => setShowInfo(!showInfo)}
      position={{
        lat: ticket?.lat,
        lng: ticket?.lng,
      }}
    >
      {ticket && showInfo && (
        <InfoWindow
          options={{ disableAutoPan: true }}
          onCloseClick={() => setShowInfo(!showInfo)}
        >
          <div style={{ color: "black" }}>
            <div><b>Ticket #{ticket?.id}</b></div>
            <div>Dispatch Date: {moment(ticket?.dispatchDateTime).format("MM/DD hh:mm A z")}</div>
            <div>Vehicle: {ticket?.vehicle?.id} / {ticket?.vehicle?.description}</div>
            <div>Last Status: {ticketEventLabels[ticket?.latestEventStatus] || ticket?.latestEventStatus}</div>
            <div>Last Seen: {moment(ticket?.lastReportedTime).format('MM/DD hh:mm A z')}</div>
          </div>
        </InfoWindow>
      )}
    </Marker>
  );
};

export default TicketMarker;
