import React from 'react';
import styled from 'styled-components';
import { DatePicker as AntDatePicker } from 'antd';

const DateTimePicker = props => {
  return <AntDatePicker {...props} showTime allowClear={false} format="MM/DD/YYYY - h:mm A" />;
};

export default styled(DateTimePicker)`
  width: 100%;
`;
