import { useEffect, useRef } from 'react';
import { nonce } from '../../../../util';

const useRecursiveTimeout = (callback, delay = 5000) => {
  const ref = useRef();
  const loadedRef = useRef(true);

  useEffect(() => {
    ref.current = callback;
  }, [callback]);

  useEffect(() => {
    let timer = null;
    const tick = () => {
      if (!loadedRef.current) {
        return;
      }
      let failed = false;
      timer = null;

      const ret = ref.current();

      const nextDelay = delay;
      if (!ret) {
        timer = setTimeout(tick, nextDelay);
      } else if (ret.constructor === Promise) {
        ret
          .then(() => {
            timer = setTimeout(tick, nextDelay);
          })
          .catch(e => {
            console.log(e);
            failed = true;
          });
        if (failed) {
          timer = setTimeout(tick, nextDelay);
        }
      }
    };
    timer = setTimeout(tick, delay);

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [delay]);

  useEffect(() => {
    // console.log(`LOADED: ${sessionRef.current}`)
    return () => {
      // console.log(`UN-LOADED: ${sessionRef.current}`)
      loadedRef.current = false;
    };
  }, []);
};

export default useRecursiveTimeout;
