import React from 'react';
import { GoogleMap, LoadScriptNext } from '@react-google-maps/api';
import getEnviromentConfig from '../../../../../../../util/getEnviromentConfig';

const Map = ({ center, height = '50vh', mapTypeId = 'terrain', width = '50vw', zoom, children }) => {
  const containerStyle = {
    width,
    height,
  };

  const loadScriptDependencies = {
    language: 'eng',
    // region: 'us',
    libraries: ['places'],
    googleMapsApiKey: getEnviromentConfig('googleMapApiKey'),
  };

  return (
    <LoadScriptNext {...loadScriptDependencies}>
      <GoogleMap zoom={zoom} center={center} mapTypeId={mapTypeId} mapContainerStyle={containerStyle}>
        {children}
      </GoogleMap>
    </LoadScriptNext>
  );
};

export default Map;
