import style from './style';
import React from 'react';
import styled from 'styled-components';
import SelectPriceBook from '../../components/select-price-book';
import SelectPricingOptions from '../../components/select-pricing-options';
import InputPercentage from '../../components/input-percentage';

const Styled = styled.div`
  ${style}
`;

const CompanyPrices = ({ item, onChange }) => {
  return (
    <Styled>
      {item && <SelectPricingOptions item={item} view="company" onChange={onChange} />}
      {item && (
        <InputPercentage id="taxPercentage" label="Tax Percentage" value={item?.taxPercentage} onChange={onChange} />
      )}
      {item && <SelectPriceBook item={item} onChange={onChange} />}
    </Styled>
  );
};

export default CompanyPrices;
