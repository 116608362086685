import { css } from 'styled-components';

export default css`
  height: 100vh;
  display: flex;
  flex-direction: column;
  flex: 1;

  .horizontal {
    display: flex;
    align-items: center;
    grid-gap: 10px;
  }

  .header {
    position: relative;
    display: flex;
    justify-content: center;
    margin: 10px 0 15px 0;
    .title {
      text-align: center;
      font-weight: bold;
      font-size: 1.2em;
    }
    .additional-actions {
      position: absolute;
      right: 0;
      top: 0;
      button {
      }
    }
  }
  @media (max-width: 475px) {
    .additional-actions,
    .left-side,
    .back-action {
      button {
        width: inherit;
        & > *:nth-child(2) {
          display: none;
        }
        .icon {
          margin-right: 0;
        }
      }
    }
  }

  .order-list-body {
    position: relative;

    .quick-dates {
      margin: 20px 10px 0 10px;
    }

    .search-group-by {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      grid-gap: 10px;
      margin: 10px;
    }
  }

  .rdg {
    width: 100%;
    margin: 10px;
    .grid-row {
      font-size: 0.9em;

      .product,
      .customerParty,
      .project,
      .location {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  &&& {
    .search-section {
      padding: 20px 30px;
      background-color: #2b2626;
      position: relative;

      .icon {
        font-size: 1.1rem;
        position: absolute;
        top: 28px;
        right: 40px;
      }
      input {
        &:focus-visible,
        &:active {
          outline: none;
        }
        border: none;
        border-radius: 4px;
        width: 100%;
        background-color: #1d1616;
        height: 34px;
        padding: 5px;
      }
    }
  }
`;
