import React, {useMemo} from 'react';
import styled from 'styled-components';
import style from './style';
import cn from 'classnames';
import {get, some} from 'lodash';
import TimerColumn from "../timer-column";
import {ContextMenuTrigger, useContextMenuTrigger} from "../../dashboard-menu/context-menu";
import moment from "moment";
import {useDashboardContext, getContrastYIQ} from "../../context/useDashboardContext";
import {useContextMenuContext} from "../../dashboard-menu/context-menu/useContextMenuContext";
import getGradient from '../../utils/get-gradient';

const toPlantStatuses = [
  'ARRIVE_PLANT',
  'WASHING',
  'LEAVE_JOB',
  'IN_YARD',
  'DEADHEAD',
]

const VehicleToken = ({className, innerRef = null, showTimer, vehicle, showContextMenu = true, ...props}) => {
  const {licenses, alerts} = useDashboardContext();

  let timeValue = vehicle?.latestEvent?.eventDateTime;
  if(vehicle.timeAllowedInStatus) {
    timeValue = moment(vehicle?.latestEvent?.eventDateTime).add(vehicle.timeAllowedInStatus, 'minutes').toISOString();
  }

  let locationId = get(vehicle, 'location.id', get(vehicle, 'ticket.originId'));
  if(licenses.orders) {
    if(toPlantStatuses.indexOf(vehicle?.vehicleStatus?.statusCode) >= 0) {
      locationId = vehicle?.toLocation?.id;
    } else {
      locationId = vehicle?.fromLocation?.id;
    }
    if(!locationId) {
      locationId = vehicle?.homeLocation?.id;
    }
  }

  const hasAlert = useMemo(() => {
    if(licenses.assurance) {
      return some(alerts, a => a.vehicle?.vehicleRef === vehicle.crn && a.active === true)
    }
    return false;
  }, [licenses.assurance, alerts, vehicle.crn]);

  const {onClick} = useContextMenuTrigger('vehicle', vehicle, showContextMenu);

  const customStyle = vehicle.locationColor
    ? {
        background: getGradient(vehicle.locationColor),
        color: getContrastYIQ(vehicle.locationColor),
      }
    : {};

  return (
    <div ref={innerRef} style={customStyle} className={cn(className, 'vehicle-token')} onClick={onClick} data-testid={'vehicle-details'}>
      <div className={cn('vehicle-column', 'vehicle-id')}>
        {vehicle.id}
        {vehicle.ticket?.waterAdded && <span className={'fa fa-water'} />}
      </div>
      <div className={cn('vehicle-column', 'time-in-status')}>
        {showTimer ? <TimerColumn column={{
          value: timeValue,
        }} /> : '???'}
      </div>
      {(licenses.assurance && hasAlert) && <div className={cn('vehicle-alert-icon')}>
        <span className={'fa fa-bell-exclamation'} />
      </div>}
      <div className={cn('vehicle-column', 'vehicle-location')}>
        P {locationId}
      </div>
    </div>
  );
}

export default styled(VehicleToken)`
  ${style}
`;
