import React, { useCallback } from 'react';
import cn from 'classnames';
import countries from '../../../../components/time-zone/countries/countries';
import style from './style';
import styled from 'styled-components';
import FieldContainer from '../../../../components/field-container/FieldContainer';
import { Translate } from '../../../order/components/localization/Translate';
import InputSelect from '../input-select';

const View = styled(FieldContainer)`
  ${style}
`;

const SelectCountry = ({ className, id, label, value, onChange, disabled = false, required, orderScreen = false }) => {
  const countryOptions = countries.map(country => ({
    value: country.countryCode,
    label: country.countryName,
  }));

  const onChangeCountry = useCallback(
    (id, val) => {
      onChange(id, val);
    },
    [onChange]
  );

  return (
    <View className={cn(id, className)}>
      {label && (
        <div className="label-spinner">
          <div className={orderScreen ? 'label-order-screen' : 'label'}>
            <Translate stringId="country" defaultMessage="Country" />
            {`${required ? ' *' : ''}`}
          </div>
        </div>
      )}
      <InputSelect
        id={id}
        value={value || ''}
        disabled={disabled}
        onChange={onChangeCountry}
        optionFilterProp="children"
        options={countryOptions}
        required={false}
        label={''}
      />
    </View>
  );
};

export default SelectCountry;
