import React, { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { Time } from '../../localization/Time';
import TicketIdAndStatus from './TicketIdAndStatus';
import { useTickets } from './useTickets';
import styled from 'styled-components';
import ShowMoreLoad from './ShowMoreLoad';

// const COMPLETED = ['IN_YARD'];

const Row = styled.tr`
  td {
    width: 7%;
  }

  .busy {
    width: 100%;
    text-align: center;
  }

  .more-cell {
    width: unset;
    cursor: pointer;
    text-align: center;
    user-select: none;
  }
`;

const Busy = () => {
  return (
    <div className="busy">
      <span style={{ fontSize: '0.9em' }} className="fas fa-sync fa-spin" />
    </div>
  );
};

const Timex = ({ ticketBusy, ticket, eventCode, timeZone }) => {
  if (ticketBusy) {
    return (
      <div className="busy">
        <span style={{ fontSize: '0.9em' }} className="fas fa-sync fa-spin" />
      </div>
    );
  }

  const time = ticket?.ticketEvents?.[eventCode]?.eventDateTime;

  return <div>
    {(time && DateTime.fromISO(time).setZone(timeZone).toFormat('h:mm a')) || ''}</div>;
};

const TripProgress = ({ load, timeZone, openTicketDrawer }) => {
  const [showMoreExpanded, setShowMoreExpanded] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const [duration, setDuration] = useState(null);

  const { getTicket, ticket, ticketBusy } = useTickets();

  useEffect(() => {
    load && setInProgress(true);
  }, [load]);

  useEffect(() => {
    if (inProgress && load) {
      getTicket(load.ticket?.deliveryRef || load.ticket?.ticketRef).catch(alert);
    }
  }, [load, inProgress]);

  useEffect(() => {
    const { CYCLE_TIME } = ticket?.ticketEvents || {};

    if (CYCLE_TIME) {
      const { isBefore, hours, minutes } = CYCLE_TIME;

      setDuration(`${isBefore ? 'T-' : ''}${hours}:${minutes}`);
    }
  }, [ticket]);

  const toggleShowMore = e => {
    e.stopPropagation();
    setShowMoreExpanded(existingShowMoreExpanded => !existingShowMoreExpanded);
  };

  return (
    <>
      <Row key={load?.loadNumber + '-load'} onClick={() => openTicketDrawer(load?.ticket?.ticketRef)}>
        <td className="more-cell" onClick={e => toggleShowMore(e)}>
          {ticketBusy ? (
            <Busy />
          ) : showMoreExpanded ? (
            <i className="icon fal fa-chevron-down" />
          ) : (
            <i className="icon fal fa-chevron-right" />
          )}
        </td>
        <td>{load?.loadNumber}</td>
        <td>{load?.quantity}</td>
        <td>
          <TicketIdAndStatus load={ticket ? { ticket } : load} />
        </td>
        <td>{load?.ticket?.vehicle?.id}</td>
        <td>
          <Time date={ticket?.ticketEvents?.CREATED?.eventDateTime} hideTimeZone={true} timeZone={timeZone} busy={ticketBusy} />
        </td>

        <td>
          <Time date={ticket?.ticketEvents?.PRINTED?.eventDateTime} hideTimeZone={true} timeZone={timeZone} busy={ticketBusy} />
        </td>
        <td>
          <Time date={ticket?.ticketEvents?.LOADING_STARTED?.eventDateTime} hideTimeZone={true} timeZone={timeZone} busy={ticketBusy} />
        </td>
        <td>
          <Time date={ticket?.ticketEvents?.LOADING_COMPLETE?.eventDateTime} hideTimeZone={true} timeZone={timeZone} busy={ticketBusy} />
        </td>

        <td>
          <Time date={ticket?.ticketEvents?.TO_JOB?.eventDateTime} hideTimeZone={true} timeZone={timeZone} busy={ticketBusy} />
        </td>
        <td>
          <Time date={ticket?.ticketEvents?.ARRIVE_JOB?.eventDateTime} hideTimeZone={true} timeZone={timeZone} busy={ticketBusy} />
        </td>
        <td>
          <Time date={ticket?.ticketEvents?.UNLOADING?.eventDateTime} hideTimeZone={true} timeZone={timeZone} busy={ticketBusy} />
        </td>
        <td>
          <Time date={ticket?.ticketEvents?.END_UNLOADING?.eventDateTime} hideTimeZone={true} timeZone={timeZone} busy={ticketBusy} />
        </td>

        <td>
          <Time date={ticket?.ticketEvents?.LEAVE_JOB?.eventDateTime} hideTimeZone={true} timeZone={timeZone} busy={ticketBusy} />
        </td>
        <td>
          <Time date={ticket?.ticketEvents?.ARRIVE_PLANT?.eventDateTime || ticket?.ticketEvents?.IN_YARD?.eventDateTime} hideTimeZone={true} timeZone={timeZone} busy={ticketBusy} />
        </td>

        <td>{duration}</td>
      </Row>
      {showMoreExpanded && ticket && !ticketBusy && <ShowMoreLoad ticket={ticket} />}
    </>
  );
};

export default TripProgress;
