import TextArea from 'antd/es/input/TextArea';
import styled from 'styled-components';
import cn from 'classnames';
import React, { useCallback } from 'react';
import FieldContainer from '../../field-container/FieldContainer';
import Input from '../../form/Input';

const Directions = ({ className, value }) => {
  return (
    <FieldContainer className={cn('directions', className)}>
      <div className="label-spinner">
        <div className="label">Directions</div>
        <div className={cn('spinner', { spinning: false })}>
          <span className="fas fa-sync fa-spin" />
        </div>
      </div>
      <Input value={value || ''} disabled data-testid="directions_input" />
    </FieldContainer>
  );
};

export default styled(Directions)``;
