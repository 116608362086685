import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import cn from 'classnames';
import { useTime } from '../../../../order/components/localization/Time';
import style from './style';
import TimerColumn from '../timer-column';
import { DateTime } from 'luxon';
import { ContextMenuTrigger } from '../../dashboard-menu/context-menu';
import { useDrop } from 'react-dnd';
import { get } from 'lodash';
import { getContrastYIQ } from '../../context/useDashboardContext';
import getGradient from '../../utils/get-gradient';

const DataLine = ({
  isCollapsible,
  item,
  className,
  columns,
  toggleCollapse,
  type,
  header,
  isCollapsed,
  resizable,
}) => {
  const { getTimeString } = useTime();

  return (
    <div className={className}>
      <div className={cn('tracking-line', `${type}-tracking-line`)}>
        {isCollapsible && (
          <div className={'collapse-container'} key={'collapsible'} onClick={toggleCollapse} style={{ width: '20px' }}>
            {!header && <span className={`fa fa-caret-${isCollapsed ? 'right' : 'down'}`} />}
          </div>
        )}
        {columns?.map((column, key) => {
          let value = <span>{column.value}</span>;

          if (column?.configuration?.type === 'timer') {
            value = <TimerColumn column={column} />;
          } else if (column?.options?.type === 'date') {
            value = <span>{getTimeString({ date: column.value, timeZone: item?.supplierParty?.timeZone })}</span>;
          }

          let testid = null;
          if (column?.options) {
            testid = `${type}-${column.options.id}`;
          }

          let style = {};

          if (column.background) {
            style = {
              background: getGradient(column.background),
              color: getContrastYIQ(column.background),
            };
          }

          style.width = `${column?.width || column?.options?.width}%`;

          return (
            <div
              className={`data-column ${type === 'order' && resizable ? '' : 'wrapped'}`}
              style={style}
              key={key}
              data-testid={testid}
            >
              {value}
            </div>
          );
        })}
      </div>
    </div>
  );
};

const ThemedDataLine = styled(DataLine)`
  display: flex;
  flex: 1;
  flex-direction: row;
  width: 100%;

  &&& {
    .order-tracking-line {
      background: ${props => getGradient(props.theme.overrideBackground || props.theme.orderLineBackground)};
      color: ${props => props.theme.overrideForeground || props.theme.orderLineForeground};
    }

    .location-tracking-line {
      background: ${props => getGradient(props.theme.overrideBackground || props.theme.locationLineBackground)};
      color: ${props => props.theme.overrideForeground || props.theme.locationLineForeground};
    }
  }
`;

const StyledDataLine = ({
  className,
  dropType = '',
  backgroundColor = null,
  onDrop,
  type,
  columns,
  offset = 0,
  children,
  header,
  item,
  resizable,
}) => {
  const isCollapsible = type === 'location';

  const [isCollapsed, setIsCollapsed] = useState(false);
  const [collectedProps, drop] = useDrop((...args) => {
    return {
      accept: dropType,
      collect: monitor => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop() && get(item, 'canTicket', true),
      }),
      drop: item => {
        onDrop && onDrop(item);
      },
    };
  });
  const toggleCollapse = useCallback(() => {
    setIsCollapsed(value => !value);
  }, [setIsCollapsed]);

  const theme = useMemo(() => {
    if (backgroundColor) {
      return {
        overrideBackground: backgroundColor || null,
        overrideForeground: (backgroundColor && getContrastYIQ(backgroundColor)) || null,
      };
    }
    return {};
  }, [backgroundColor]);

  return (
    <ThemeProvider theme={theme}>
      <ContextMenuTrigger type={type} item={item}>
        <div className={cn(className)} ref={drop} style={{ marginLeft: offset }}>
          <ThemedDataLine
            item={item}
            isCollapsible={isCollapsible}
            columns={columns}
            toggleCollapse={toggleCollapse}
            type={type}
            header={header}
            isCollapsed={isCollapsed}
            resizable={resizable}
          />
        </div>
      </ContextMenuTrigger>
      {!isCollapsed && children}
    </ThemeProvider>
  );
};

export default styled(StyledDataLine)`
  ${style}
`;
