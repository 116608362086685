import React from 'react';
import styled from 'styled-components';
import style from './style';
import cn from 'classnames';

const Iframe = ({ className, src }) => {
  return <iframe className={cn(className)} src={src} />;
};

export default styled(Iframe)`
  ${style}
`;
