
export const iconList = [
  { value: 'belly' },
  { value: 'boom' },
  { value: 'box' },
  { value: 'car' },
  { value: 'container' },
  { value: 'conveyor' },
  { value: 'dozer' },
  { value: 'dump' },
  { value: 'excavator' },
  { value: 'flat' },
  { value: 'front' },
  { value: 'loader' },
  { value: 'paver' },
  { value: 'pump' },
  { value: 'rear' },
  { value: 'scraper' },
  { value: 'tanker' },
  { value: 'trailer' },
  { value: 'truck' }
];

export const statusList = [
  { value: 'ACTIVE' },
  { value: 'INACTIVE' },
];
