import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import style from './style';
import Input from "../../../order/components/Input";

const Styled = styled.div`${style}`;

const InputSearch = ({ className, onChange, value, innerRef }) => {
  const handleChange = React.useCallback(
    e => {
      onChange(e.target.value);
    },
    [onChange]
  );

  return (
    <Styled className={cn('search', className)}>
      <Input.Search className='input-search' onChange={handleChange} value={value} ref={innerRef} />
    </Styled>
  );
};

export default InputSearch;
