import React from 'react';
import cn from 'classnames';
import User from './User';
import Message from './Message';
import Timestamp from './Timestamp';

const Note = ({ className, note }) => {
  return (
    <div className={cn('note', className)}>
      <User user={note.user} />
      <Message message={note.message} />
      <Timestamp timestamp={note.timestamp} />
    </div>
  );
};

export default Note;
