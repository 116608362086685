export const setValue = (item, field, value) => {
  const splitFields = field.split('.');

  if(!item) {
    item = {};
  }
  let currentLevel = item;
  for (let i = 0; i < splitFields.length; i++) {
    const fieldName = splitFields[i];

    if(currentLevel[fieldName] === undefined) {
      currentLevel[fieldName] = {};
    }
    if(i !== splitFields.length - 1) {
      currentLevel = currentLevel[fieldName];
    } else {
      //set value at the end
      currentLevel[fieldName] = value;
    }
  }

  return item;
}

export default setValue;