import { Switch } from 'antd';
import cn from 'classnames';
import { cloneDeep, find, kebabCase } from 'lodash';
import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import styled from 'styled-components';
import FlexColumn from '../../../../views/invoice/components/FlexColumn';
import { Translate } from '../../../../views/order/components/localization/Translate';
import { useGridContext } from '../../context/context';
import Button from '../../../button/Button';

const Styled = styled(FlexColumn)`
  overflow: hidden;
  grid-gap: 10px;
  .column-actions {
    margin-top: 10px;
    display: flex;
    .left {
      display: flex;
      grid-gap: 10px;

      flex: 1;
    }
    .right {
      text-align: right;
    }
  }
  .visible-columns,
  .hidden-columns {
    border: 1px solid #434343;
    //flex: 1;
    padding: 10px;
    overflow: auto;
    &.isDraggingOver {
      //background-color: #282828;
    }
    .column-header {
    }
  }

  .toggle-all {
    display: flex;
    grid-gap: 10px;
    justify-content: flex-end;
  }

  .column-card {
    height: 45px;
    border: 1px solid #434343;
    display: flex;
    align-items: center;
    background-color: #282828;
    margin-bottom: 10px;

    &.isDragging {
      border: 1px solid #666;
      background-color: #434343;
    }

    &.hidden {
      background-color: transparent;
      color: #888;
    }

    .card-content {
      flex: 1;
      padding: 10px;
      display: flex;

      .column-name {
        flex: 1;
      }

      .attributes {
        margin-right: 20px;
        .hidden-checkbox {
          margin-right: 5px;
        }
      }
    }

    .handle {
      font-size: 1.2em;
      padding-right: 10px;
      color: white;
    }
  }
`;

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const ColumnPreferences = ({ onDone }) => {
  const { columns, saveColumnPreferences, originalColumnDefinitions, gridName } = useGridContext();

  const originalItems = React.useRef(columns);

  const [items, setItems] = React.useState(cloneDeep(originalItems.current));

  const onDragEnd = React.useCallback(
    ({ source, destination }) => {
      if (!destination) {
        return;
      }

      // Since the frozen items are not added in the dragable whe need to ignore
      // those at the moment of reordering
      const itemsFrozen = items.filter(i => i.frozen);
      const reorderedItems = reorder(
        items.filter(i => !i.frozen),
        source.index,
        destination.index
      );
      setItems([...itemsFrozen, ...reorderedItems]);
    },
    [items]
  );

  const handleHide = React.useCallback(({ checked, columnKey }) => {
    setItems(s => {
      const state = [...s];
      const target = find(state, { key: columnKey });
      if (target) {
        target.hidden = checked;
      }
      return state;
    });
  }, []);

  const handleSave = React.useCallback(() => {
    saveColumnPreferences(items);
    originalItems.current = cloneDeep(items);
    onDone?.();
  }, [items, onDone, saveColumnPreferences]);

  const handleUndo = React.useCallback(() => {
    setItems(cloneDeep(originalItems.current));
  }, []);

  const handleFactoryReset = React.useCallback(() => {
    setItems(originalColumnDefinitions);
  }, [originalColumnDefinitions]);

  const allHidden = React.useMemo(() => {
    return items.filter(i => !i.frozen).every(c => c.hidden);
  }, [items]);

  const allVisible = React.useMemo(() => {
    return items.every(c => !c.hidden);
  }, [items]);

  const hideAll = React.useCallback(() => {
    setItems(s => s.map(i => (i.frozen ? i : { ...i, hidden: true })));
  }, []);

  const showAll = React.useCallback(() => {
    setItems(s => s.map(i => ({ ...i, hidden: false })));
  }, []);

  return (
    <Styled>
      <div className="toggle-all">
        <Button
          size="small"
          onClick={showAll}
          disabled={allVisible}
          metricId={gridName ? `${kebabCase(gridName)}-preferences-show-all` : undefined}
        >
          <Translate stringId="grid.showAll" defaultMessage="Show All" />
        </Button>
        <Button
          size="small"
          onClick={hideAll}
          disabled={allHidden}
          metricId={gridName ? `${kebabCase(gridName)}-preferences-hide-all` : undefined}
        >
          <Translate stringId="grid.hideAll" defaultMessage="Hide All" />
        </Button>
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="columns">
          {(provided, snapshot) => (
            <div
              className={cn('visible-columns', { isDraggingOver: snapshot.isDraggingOver })}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {items
                .filter(i => !i.frozen)
                .map((column, index) => {
                  return (
                    <Draggable key={column.key} draggableId={column.key} index={index}>
                      {(provided, snapshot) => (
                        <div
                          className={cn('column-card', { isDragging: snapshot.isDragging, hidden: column.hidden })}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          style={provided.draggableProps.style}
                        >
                          <div className="card-content">
                            <div className="column-name">{column.name}</div>
                            <div className="attributes">
                              <Switch
                                className="hidden-checkbox"
                                checkedChildren={<Translate stringId="grid.visible" defaultMessage="Visible" />}
                                unCheckedChildren={<Translate stringId="grid.hidden" defaultMessage="Hidden" />}
                                onClick={checked => {
                                  handleHide({ checked: !checked, columnKey: column.key });
                                }}
                                checked={!column.hidden}
                              />
                            </div>
                          </div>
                          <div className="handle" {...provided.dragHandleProps}>
                            <i className="fal fa-bars" />
                          </div>
                        </div>
                      )}
                    </Draggable>
                  );
                })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <div className="column-actions">
        <div className="left">
          <Button
            type="primary"
            onClick={handleSave}
            disabled={allHidden}
            metricId={gridName ? `${kebabCase(gridName)}-preferences-save` : undefined}
          >
            <Translate stringId="grid.save" defaultMessage="Save" />
          </Button>
          <Button
            onClick={handleUndo}
            metricId={gridName ? `${kebabCase(gridName)}-preferences-undo-changes` : undefined}
          >
            <Translate stringId="grid.undoChanges" defaultMessage="Undo Changes" />
          </Button>
        </div>
        <div className="right">
          <Button
            type="danger"
            onClick={handleFactoryReset}
            metricId={gridName ? `${kebabCase(gridName)}-preferences-reset-columns` : undefined}
          >
            <Translate stringId="grid.resetColumns" defaultMessage="Reset Columns" />
          </Button>
        </div>
      </div>
    </Styled>
  );
};

export default ColumnPreferences;
