import { Calendar, Modal } from 'antd';
import cn from 'classnames';
import moment from 'moment';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { Translate } from '../../views/order/components/localization/Translate';
import Button from '../button/Button';
import style from './style';

export const QUICK_DATES = {
  TODAY: {
    label: 'Today',
    stringId: 'today',
    getRange: () => {
      return {
        firstStartDateTime: moment().startOf('day').toISOString(),
        lastStartDateTime: moment().endOf('day').toISOString(),
      };
    },
  },
  TOMORROW: {
    label: 'Tomorrow',
    stringId: 'tomorrow',
    getRange: () => {
      return {
        firstStartDateTime: moment().add(1, 'day').startOf('day').toISOString(),
        lastStartDateTime: moment().add(1, 'day').endOf('day').toISOString(),
      };
    },
  },
  FUTURE: {
    label: 'Future',
    stringId: 'future',
    getRange: () => {
      return {
        firstStartDateTime: moment().add(1, 'day').startOf('day').toISOString(),
        lastStartDateTime: moment('2999-12-31').endOf('day').toISOString(),
      };
    },
  },
  CUSTOM: {
    label: 'Custom',
    stringId: 'custom',
    getRange: customMoment => {
      return {
        firstStartDateTime: moment(customMoment).startOf('day').toISOString(),
        lastStartDateTime: moment(customMoment).endOf('day').toISOString(),
      };
    },
  },
};

const QuickDates = ({ className, onChange, value, datePickerProps }) => {
  const [currentDate, setCurrentDate] = useState(moment());

  const handleSelection = useCallback(quickDate => {
    onChange(quickDate);
  }, []);

  const handleCustom = useCallback(() => {
    datePickerProps.onOpen();
  }, []);

  const handleOk = useCallback(() => {
    handleSelection(QUICK_DATES.CUSTOM);
    datePickerProps.onClose();
  }, [currentDate]);

  const onSelect = useCallback(selectedDate => {
    setCurrentDate(selectedDate);
    QUICK_DATES.CUSTOM.date = selectedDate;
  }, []);

  return (
    <div className={cn('quick-dates', className)}>
      <Button
        onClick={() => handleSelection(QUICK_DATES.TODAY)}
        className={cn({ ['ant-btn-primary']: value === QUICK_DATES.TODAY })}
      >
        <Translate stringId={QUICK_DATES.TODAY.stringId} defaultMessage="Today" />
      </Button>
      <Button
        onClick={() => handleSelection(QUICK_DATES.TOMORROW)}
        className={cn({ ['ant-btn-primary']: value === QUICK_DATES.TOMORROW })}
      >
        <Translate stringId={QUICK_DATES.TOMORROW.stringId} defaultMessage="Tomorrorw" />
      </Button>
      <Button
        onClick={() => handleSelection(QUICK_DATES.FUTURE)}
        className={cn({ ['ant-btn-primary']: value === QUICK_DATES.FUTURE })}
      >
        <Translate stringId={QUICK_DATES.FUTURE.stringId} defaultMessage="Future" />
      </Button>
      <Button
        onClick={() => handleCustom(QUICK_DATES.CUSTOM)}
        className={cn({ ['ant-btn-primary']: value === QUICK_DATES.CUSTOM })}
      >
        {QUICK_DATES.CUSTOM.date ? (
          QUICK_DATES.CUSTOM.date.format('MM/DD/YYYY')
        ) : (
          <Translate stringId={QUICK_DATES.CUSTOM.label} defaultMessage="Custom" />
        )}
      </Button>

      <Modal title="Custom Date" {...datePickerProps} onOk={handleOk}>
        <Calendar fullscreen={false} onSelect={onSelect} value={currentDate} />
      </Modal>
    </div>
  );
};

export default styled(QuickDates)`
  ${style}
`;
