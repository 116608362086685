import { message, notification } from 'antd';
import cn from 'classnames';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Checkbox } from '../../../../../../components/checkbox';
import { Radio } from '../../../../../../components/radio';
import Select from '../../../../../../components/form/Select';
import FlexColumn from '../../../../../order/components/FlexColumn';
import ProgressBar from './ProgressBar';
import style from './style';
import Button from '../../../../../../components/button/Button';
import useSetup from '../../../../../setup/useSetup';
import { exportTypes } from './options';

const Styled = styled.div`
  ${style}
`;

const InvoiceButton = ({ selectedCount, onInvoice, handleChange, form, busy, progress, onExport }) => {
  const { getSetupItems, setupItems } = useSetup('entity-setup');
  const [isOpen, setIsOpen] = React.useState(false);
  const [exportType, setExportType] = React.useState('preview');

  useEffect(() => {
    getSetupItems();
  }, [getSetupItems]);

  const initiateConfirmation = React.useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleCancel = React.useCallback(() => {
    setIsOpen(false);
  }, []);

  const validateForm = () => {
    if (form?.preview) {
      return true;
    } else if (form?.exportData || form?.createPdfs) {
      return !form?.exportData || form?.exportType;
    }
    return false;
  };

  const invoiceLabel = React.useMemo(() => {
    let label;

    if (exportType === 'preview') {
      label = 'Preview';
    } else {
      label = 'Invoice';
    }

    return label + (selectedCount ? ` (${selectedCount})` : '');
  }, [exportType, selectedCount]);

  React.useEffect(() => {
    if (selectedCount === 0) {
      setIsOpen(false);
    }
  }, [selectedCount]);

  const handleEmailChange = React.useCallback(
    e => {
      handleChange('sendEmail', e.target.checked);
    },
    [handleChange]
  );

  const handleExportTypeChange = React.useCallback(
    exportType => {
      handleChange('exportType', exportType);
    },
    [handleChange]
  );

  const handleExportDataChange = React.useCallback(
    e => {
      handleChange('exportData', e.target.checked);
    },
    [handleChange]
  );

  const handleCreatePDFsChange = React.useCallback(
    e => {
      handleChange('createPdfs', e.target.checked);
      if (!e.target.checked) {
        handleChange('sendEmail', false);
      }
    },
    [handleChange]
  );

  const handlePreviewChange = React.useCallback(
    e => {
      setExportType(e.target.value);
      const previewOnly = e.target.value === 'preview';
      handleChange('preview', previewOnly);

      // Get default values from company settings
      const setup = !previewOnly ? setupItems?.[0] : undefined;
      const defaultCreatePDFs =
        setup?.defaultInvoiceOptionCreatePDFs === undefined || setup?.defaultInvoiceOptionCreatePDFs === true;
      const defaultExportData = setup?.defaultInvoiceOptionExportData ? true : false;
      const defaultExportDataType =
        defaultExportData && setup?.defaultInvoiceOptionExportType ? setup.defaultInvoiceOptionExportType : '';

      // Reset form with default values
      handleChange('createPdfs', !previewOnly && defaultCreatePDFs);
      handleChange('exportData', defaultExportData);
      handleChange('exportType', defaultExportDataType);
    },
    [handleChange, setupItems]
  );

  const [progressPercentage, setProgressPercentage] = React.useState(0);

  const handleInvoice = React.useCallback(() => {
    setIsOpen(false);
    onInvoice();
  }, [onInvoice]);

  React.useEffect(() => {
    if (!busy) return;
    const completedCount = Object.entries(progress).reduce((acc, [key, value]) => {
      if (value !== null) {
        acc += 1;
      }
      return acc;
    }, 0);

    const percentage = (completedCount / selectedCount) * 100;

    setProgressPercentage(percentage);
    if (percentage === 100) {
      setTimeout(() => {
        setProgressPercentage(0);
      }, 1200);
    }
  }, [busy, progress, selectedCount]);

  React.useEffect(() => {
    if (progressPercentage === 100) {
      const customerNamesWithError = Object.values(progress).filter(p => p.error);

      if (customerNamesWithError.length) {
        notification.error({
          message: `${form.preview ? 'Preview' : 'Invoicing'} Error`,
          description: (
            <div>
              Could not generate PDFs for the following:
              <ul>
                {customerNamesWithError.map(c => (
                  <li>{c.error}</li>
                ))}
              </ul>
            </div>
          ),
          duration: 0,
        });
      } else {
        message.success('PDF generation completed successfully.');

        if (form.preview !== true && form.exportData === true) {
          onExport();
        }
      }
    }
  }, [form.exportData, form.preview, onExport, progress, progressPercentage]);

  const isValid = validateForm();
  return (
    <Styled className={cn('invoice-button', { disabled: !selectedCount })}>
      <div
        className={cn('button-section', { visible: !isOpen, disabled: !selectedCount })}
        style={{ position: 'relative' }}
      >
        <Button
          type="button"
          onClick={initiateConfirmation}
          disabled={isOpen || !selectedCount}
          loading={busy}
          metricId="core-unbilled-deliveries-review-preview-invoice"
        >
          {invoiceLabel}
        </Button>
        <ProgressBar progressPercentage={progressPercentage} />
      </div>
      <div className={cn('details-section-wrapper', { visible: isOpen })}>
        <FlexColumn className="details-section">
          <div className="instructions">Select “Preview Only” to review invoices prior to sending to customers.</div>
          <div className="options" style={{ flex: '1' }}>
            <Radio.Group onChange={handlePreviewChange} defaultValue={exportType}>
              <Radio.Button value={'preview'}>Preview Only</Radio.Button>
              <Radio.Button value={'invoice'}>Invoice Options</Radio.Button>
            </Radio.Group>

            {exportType === 'invoice' && (
              <div>
                <Checkbox
                  checked={form?.createPdfs}
                  label="Create PDFs"
                  onChange={handleCreatePDFsChange}
                  metricId="core-unbilled-deliveries-review-create-pdfs"
                />
                <Checkbox
                  checked={form?.createPdfs && form?.sendEmail}
                  label="Email Invoices to Customers"
                  disabled={!form?.createPdfs}
                  className="indent-left"
                  onChange={handleEmailChange}
                  metricId="core-unbilled-deliveries-review-email-invoices-to-customers"
                />
                <Checkbox
                  checked={form?.exportData}
                  label="Export Data"
                  onChange={handleExportDataChange}
                  metricId="core-unbilled-deliveries-review-export-data"
                />
                <Select
                  id="exportType"
                  value={form?.exportType || ''}
                  disabled={!form?.exportData}
                  onChange={handleExportTypeChange}
                >
                  <Select.Option value="">Select...</Select.Option>
                  {exportTypes?.map((exportType, idx) => (
                    <Select.Option key={`${exportType.value}${idx}`} value={exportType.value}>
                      {exportType.label}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            )}
          </div>
          <div className="actions">
            <Button
              type="default"
              ghost
              onClick={handleCancel}
              metricId="core-unbilled-deliveries-review-cancel"
              disabled={busy}
            >{`Cancel`}</Button>
            <Button
              type="primary"
              onClick={handleInvoice}
              disabled={!isValid}
              loading={busy}
              metricId="core-unbilled-deliveries-review-invoice-preview"
            >
              {invoiceLabel}
            </Button>
          </div>
        </FlexColumn>
      </div>
    </Styled>
  );
};

export default InvoiceButton;
