import moment from 'moment';
import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { api } from '../api';

const transformToPostData = form => {
  return {
    version: '2.0',
    job: {
      jobRef: form?.job?.jobRef,
      id: form?.job?.id,
      description: form?.job?.description,
    },
    contact: form.contact,
    notes: [{ message: form.note }],
    description: form.description,
    directions: form.directions,
    orderDiscipline: {
      disciplineRef: form?.discipline?.disciplineRef,
      id: form?.discipline?.id,
      description: form?.discipline?.description,
    },
    pourDescription: {
      pourDescriptionRef: form?.pourDescription?.pourDescriptionRef,
      description: form?.pourDescription?.description,
    },
    deliverySchedule: {
      startDateTime: moment(form.startDateTime).local().toISOString(),
      finishDateTime: moment(form.startDateTime).local().toISOString(),
      deliverySpacing: form.spacing,
      deliverySpacingUnits: form.spacingUnits,
    },
    lineItems: [
      {
        item: {
          productRef: form.product.productRef,
        },
        orderedQuantity: {
          value: form.quantity,
          uomCode: form.product.uomCode,
        },
        properties: {
          slump: form.slump,
          pourType: form.pourType,
        },
      },
    ],
    copies: form.emailCopies,
    pourCardNumber: form.pourCardNumber,
  };
};

export const useCreateOrder = () => {
  const { entityRef } = useParams();
  const [response, setResponse] = useState();
  const [createOrderBusy, setCreateOrderBusy] = useState(false);

  const createOrder = useCallback(
    formData => {
      const postData = transformToPostData(formData);

      setResponse(null);
      setCreateOrderBusy(true);

      return api
        .createOrderRequest(entityRef, formData.supplier.crn, postData)
        .then(resp => {
          setResponse(resp);
          return resp;
        })
        .finally(() => {
          setCreateOrderBusy(false);
        });
    },
    [entityRef]
  );

  return { createOrder, createOrderBusy };
};
