import React from 'react';
import BaseMarker from '../markers/BaseMarker';
import DestinationInfoWindow from './DestinationInfoWindow';

const Destination = ({
  effectiveAddress,
  handleDestinationMarkerDrag,
  zoomTo,
  canUpdate,
  departureTime,
  effectiveDestination,
  configBusy,
  effectiveRadius,
  onGeofenceChange,
}) => {
  const baseMarker = React.useRef();
  const draggable = canUpdate && !configBusy;
  const onChange = e => handleDestinationMarkerDrag(e, departureTime);
  const destination = effectiveDestination;

  const icon = destination?.icon || 'default';
  const iconColor = destination?.color || 'blue';
  const lng = destination?.lng;
  const lat = destination?.lat;

  const handleDragEnd = React.useCallback(
    e => {
      baseMarker?.current?.showInfo?.();
      onChange(e);
    },
    [onChange]
  );

  const handleDoubleClick = React.useCallback(
    e => {
      zoomTo(e);
    },
    [zoomTo]
  );

  const registerCallback = React.useCallback(value => {
    baseMarker.current = value;
  }, []);

  if (!lat || !lng) return null;

  return (
    <>
      <BaseMarker
        draggable={draggable}
        onDragEnd={handleDragEnd}
        key={destination?.id}
        onReady={registerCallback}
        onDblClick={handleDoubleClick}
        icon={{ url: `/assets/map-icons/${icon}-${iconColor}.png`, scaledSize: { width: 50, height: 69 } }}
        position={{ lat, lng }}
        geofenceRadius={effectiveRadius}
        onGeofenceChange={onGeofenceChange}
      >
        <DestinationInfoWindow effectiveAddress={effectiveAddress} />
      </BaseMarker>
    </>
  );
};

export default Destination;
