import { message } from 'antd';
import { useState, useCallback, useRef, useMemo } from 'react';
import styled from 'styled-components';
import style from './style';

import SimpleDropDown from '../../../../components/simple-dropdown';

const Styled = styled.div`
  ${style}
`;

const ACCEPT_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

const ImportExportMultiple = ({ itemList, onFinish, uploadFile, downloadFile }) => {
  const [uploading, setUploading] = useState(false);
  const [downloading, setDownloading] = useState(false);

  const [optionSelected, setOptionSelected] = useState(null);

  const fileInputRef = useRef();

  const handleUpload = useCallback(item => {
    return () => {
      setOptionSelected(item);
      fileInputRef?.current?.click?.();
    };
  }, []);

  const handleDownload = useCallback(
    (typeId, crn, name) => {
      setDownloading(true);
      downloadFile(typeId, crn, name).finally(() => {
        setDownloading(false);
      });
    },
    [downloadFile]
  );

  const executeFileUpload = useCallback(
    file => {
      setUploading(true);
      uploadFile(optionSelected.typeId, optionSelected.crn, file)
        .then(() => {
          message.success({
            content: 'The workbook was uploaded successfully.',
            style: { marginTop: '20px' },
            duration: 2,
          });
          onFinish();
        })
        .finally(() => {
          setUploading(false);
        });
    },
    [onFinish, uploadFile, optionSelected]
  );

  const handleInitiateFileUpload = useCallback(
    event => {
      const file = event?.target?.files?.[0];
      if (file) {
        executeFileUpload(file);
        event.target.value = '';
      }
    },
    [executeFileUpload]
  );

  const importDropdownData = useMemo(() => {
    return itemList.map(i => ({
      onClick: handleUpload(i),
      metricId: `core-setup-${i.typeId}-upload`,
      ...i,
    }));
  }, [itemList, handleUpload]);

  const exportDropdownData = useMemo(() => {
    return itemList.map(i => ({
      onClick: () => handleDownload(i.typeId, i.crn, i.name),
      metricId: `core-setup-${i.typeId}-upload`,
      ...i,
    }));
  }, [itemList, handleDownload]);

  return (
    <Styled>
      <SimpleDropDown label="Import" size="small" loading={uploading} options={importDropdownData} />
      <div className="separator" />
      <SimpleDropDown label="Export" size="small" loading={downloading} options={exportDropdownData} />
      <input
        hidden
        accept={ACCEPT_TYPE}
        autoComplete="off"
        type="file"
        onChange={handleInitiateFileUpload}
        multiple={false}
        ref={fileInputRef}
      />
    </Styled>
  );
};

export default ImportExportMultiple;
