import { Drawer } from 'antd';
import React, { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import cn from 'classnames';
import useSetup from '../../../../../setup/useSetup';
import { api } from '../../../../api';
import { Number } from '../../../../components/localization/Number';
import { Time } from '../../../../components/localization/Time';
import { Translate } from '../../../../components/localization/Translate';
import { useDrawer } from '../../../../components/order-form/general/useDrawer';
import { useOrderListContext } from '../../../../components/OrderListContext';
import StatusIndicator from './StatusIndicator';
import style from './style';
import { DateTime } from 'luxon';
import { orderBy } from 'lodash';
import { Checkbox } from '../../../../../../components/checkbox';
import inTransit from '../in-transit';
import TicketDetails from '../ticket-details';
import filterScheduledLoads from '../../../../util/filterScheduledLoads';

const Styled = styled.div`
  ${style}
`;

const StyledLoads = styled.div`
  color: #3c93e2;
  cursor: pointer;

  @media (max-width: 750px) {
    &.VOIDED,
    &.CANCELED,
    &.CANCELLED {
      color: #f77e7e;
      text-decoration: line-through;
    }
  }
`;

const Loads = ({ scheduled = [], ticketed = [], plant, timeZone, orderRef, supplierRef, orderId }) => {
  const [loads, setLoads] = React.useState(
    orderBy(filterScheduledLoads(scheduled, ticketed).concat(ticketed), ['loadNumber'], ['asc'])
  );

  const { entityRef } = useParams();
  const { getSetupItems, setupItems } = useSetup('cancel-reason-code');
  const { persona } = useOrderListContext();

  React.useEffect(() => {
    if (!orderId) return;
    api
      .getOrderTickets(entityRef, orderRef, {
        supplierRef: persona === 'buyer' ? supplierRef : undefined,
        expand: 'tickets',
      })
      .then(order => {
        const scheduled = order?.deliverySchedule?.schedule || [];
        const ticketed = order?.deliverySchedule?.tickets || [];
        const sorted = orderBy(filterScheduledLoads(scheduled, ticketed).concat(ticketed), ['loadNumber'], ['asc']);
        setLoads(sorted);
      });
  }, [entityRef, orderRef, persona, supplierRef, orderId]);

  const ticketRef = useRef();

  const [showAll, setShowAll] = useState(true);

  const { drawerOpen, openDrawer, closeDrawer, drawerWidth } = useDrawer(575);

  const filter = load => {
    return showAll || inTransit(load);
  };

  const dateTime = isoString => {
    try {
      const dateTime = isoString && DateTime.fromISO(isoString).setZone(timeZone);
      return isoString ? `${dateTime?.toFormat('hh:mm a ZZZZ')}` : '';
    } catch (e) {
      return '';
    }
  };

  const showTicketDetails = React.useCallback(
    ref => {
      ticketRef.current = ref;
      openDrawer();
    },
    [openDrawer]
  );

  const handleCloseDrawer = React.useCallback(() => {
    ticketRef.current = null;
    closeDrawer();
  }, [closeDrawer]);

  React.useEffect(() => {
    getSetupItems();
  }, [getSetupItems]);

  return (
    <Styled>
      <div className="label">
        <span>&nbsp;</span>
        <span className="show-all">
          <span className="title">
            <Translate stringId="showAll" defaultMessage="Show All" />
          </span>
          <Checkbox
            dataTestId="check-show-all-loads"
            checked={showAll}
            onChange={() => {
              setShowAll(s => !s);
            }}
          />
        </span>
      </div>
      <table>
        <thead>
          <tr>
            <th>
              <Translate stringId="load" defaultMessage="Load" />
            </th>
            <th>
              <Translate stringId="arriveJob" defaultMessage="Arrive Job" />
            </th>
            <th>
              <Translate stringId="plant" defaultMessage="Plant" />
            </th>
            <th>
              <Translate stringId="vehicle" defaultMessage="Vehicle" />
            </th>
            <th>
              <Translate stringId="quantity" defaultMessage="Quantity" />
            </th>
            <th>
              <Translate stringId="status" defaultMessage="Status" />
            </th>
          </tr>
        </thead>
        <tbody>
          {loads?.filter(filter).map((load, idx) => {
            const loadNumber = idx + 1;
            return (
              <tr
                key={`load?.ticket?.ticketRef_${idx}`}
                className={cn({ alt: idx % 2 === 1 })}
                onClick={() => (load?.ticket?.id ? showTicketDetails(load?.ticket?.ticketRef) : '')}
                data-testid={`load-${loadNumber}`}
              >
                <td>
                  {load?.ticket?.id ? (
                    <div className="ticket-id">
                      <StyledLoads className={load?.ticket?.supplierStatus?.statusCode}>
                        {load?.ticket?.id || ' '}
                      </StyledLoads>
                      <StatusIndicator load={load} />
                    </div>
                  ) : (
                    load.loadNumber
                  )}
                </td>
                <td data-testid={`load-${loadNumber}-arrive-job`}>
                  <Time
                    date={load?.ticketEvents?.ARRIVE_JOB?.eventDateTime || load?.ticketEvents?.ARRIVE_JOB}
                    timeZone={timeZone}
                  />
                  {!load?.ticketEvents?.ARRIVE_JOB?.eventDateTime ? '*' : ''}
                </td>
                <td data-testid={`load-${loadNumber}-plant`}>
                  {load.ticket?.origin?.id || load?.overrides?.location?.id || plant?.id}
                </td>
                <td data-testid={`load-${loadNumber}-vehicle`}>{load?.ticket?.vehicle?.id || load?.vehicleId}</td>
                <td data-testid={`load-${loadNumber}-quantity`}>
                  <Number value={load.quantity} decimalPlaces={2} />
                </td>
                <td data-testid={`load-${loadNumber}-status`}>
                  <Translate stringId={load.ticket?.latestEventStatus || 'scheduled'} />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <StyledDrawer
        placement="right"
        width={drawerWidth}
        closable
        onClose={handleCloseDrawer}
        visible={drawerOpen}
        destroyOnClose
        headerStyle={{ display: 'none' }}
      >
        <TicketDetails
          parameters={{ ticketRef: ticketRef.current }}
          onCancelVoid={closeDrawer}
          setupItems={setupItems}
          supplierRef={supplierRef}
          orderRef={orderRef}
        />
      </StyledDrawer>
    </Styled>
  );
};

export default Loads;

const StyledDrawer = styled(Drawer)`
  .ant-drawer-body {
    display: flex;
    flex-direction: column;
  }
`;
