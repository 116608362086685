import cn from 'classnames';
import { isEmpty } from 'lodash';
import React from 'react';
// import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import Button from '../../../../components/button/Button';
import Select from '../../../../components/form/Select';
import { useMapBehaviorContext } from './context/MapBehaviorContext';
import { useMapStateContext } from './context/MapStateContext';
// import api from './api';

const { Option } = Select;

const Styled = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 10px;
  margin-bottom: 20px;
  .fields {
    flex: 1;
  }

  .actions {
    display: flex;
    gap: 10px;
  }
`;

const FormSection = ({ isNewOrder }) => {
  // const { orderId: orderRef, entityRef } = useParams();
  const { chosenAddress, initialDestination, addresses, accepting, onDone, configBusy } = useMapStateContext();

  const { initiateAcceptance, handleAddressChange, searchForNearbyAddresses } = useMapBehaviorContext();

  // const handleSetAsProjectDefault = React.useCallback(() => {
  //   const body = {};
  //   return api.setProjectRoute({ entityRef, orderRef, body });
  // }, [entityRef, orderRef]);

  const handleCancel = React.useCallback(() => {
    onDone();
  }, [onDone]);

  return (
    <Styled className={cn('form-section')}>
      <div className="fields">
        <Select
          disabled={configBusy}
          value={(chosenAddress && JSON.stringify(chosenAddress)) || undefined}
          onChange={handleAddressChange}
          onSearch={searchForNearbyAddresses}
          filterOption={false}
          placeholder={
            !isEmpty(initialDestination)
              ? []
                  .concat(
                    initialDestination.address1,
                    initialDestination.city,
                    initialDestination.countrySubDivision,
                    initialDestination.postalCode
                  )
                  .filter(x => !!x)
                  .join(', ')
              : 'Enter an address...'
          }
          showSearch
        >
          {addresses.map(a => {
            return (
              <Option
                key={`${a.address1}, ${a.city}, ${a.countrySubDivision}, ${a.postalCode?.substr(0, 5)}`}
                value={JSON.stringify(a)}
              >
                {a.address1}, {a.city}, {a.countrySubDivision}, {a.postalCode?.substr(0, 5)}
              </Option>
            );
          })}
        </Select>
      </div>
      <div className="actions">
        <Button onClick={initiateAcceptance} type="primary" loading={accepting} disabled={configBusy}>
          Accept
        </Button>
        <Button onClick={handleCancel} disabled={configBusy}>
          Cancel
        </Button>
        {/* {!isNewOrder && <Button onClick={handleSetAsProjectDefault} disabled={configBusy}>Set Route as Project Default</Button>} */}
      </div>
    </Styled>
  );
};

export default FormSection;
