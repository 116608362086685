import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Translate } from '../../../../views/order/components/localization/Translate';

import { useDashboardContext } from '../context/useDashboardContext';

const Styled = styled.div`
  display: flex;
  flex: 1;
  border-radius: 10px;
  border-width: 1px;
  color: white;
  background-color: red;
  border-color: darkred;
  align-self: center;
  max-width: 85px;
  padding: 3px;
  margin: 3px;
  height: 25px;
  align-items: center;
  user-select: none;
  justify-content: center;
  cursor: pointer;
`;

const VehicleAlertIcon = ({ className }) => {
  const { alerts, openDashboardWindow } = useDashboardContext();

  const alertCount = useMemo(() => {
    return alerts?.filter(a => a.active === true).length || 0;
  }, [alerts]);

  if (alertCount === 0) {
    return null;
  }
  return (
    <Styled onClick={() => openDashboardWindow('vehicle-alerts', 'Alerts', false)}>
      <Translate stringId="alertCountAlerts" defaultMessage="({alertCount}) Alerts" values={{ alertCount }} />
    </Styled>
  );
};

export default VehicleAlertIcon;
