import React from 'react';
import http from '../../../../../http';
import { useMapStateContext } from '../context/MapStateContext';

export const searchForNearbyAddresses = async ({ lat, lng, searchText }) => {
  if (searchText?.length > 5) {
    return http.get(`/connex/services/orders/geo/addresses?text=${searchText}&latitude=${lat}&longitude=${lng}`);
  }
};

export const useGeoStuff = () => {
  const { origin, setAddresses } = useMapStateContext();

  const _searchForNearbyAddresses = React.useCallback(
    searchText => {
      if (searchText?.length > 5) {
        searchForNearbyAddresses({ lat: origin?.lat, lng: origin?.lng, searchText })
          .then(response => {
            if (response) {
              setAddresses(response?.filter?.(a => !a.address1.includes('undefined')));
            }
          })
          .catch(console.error);
      }
    },
    [origin?.lat, origin?.lng, setAddresses]
  );

  return { searchForNearbyAddresses: _searchForNearbyAddresses };
};
