import { css } from 'styled-components';

export default css`
  .upload-button-container {
    display: flex;
    justify-content: center;
  }

  .upload-icon {
    margin-right: 10px;
  }

  .new-project-message {
    height: 200px;
    text-align: center;
    margin: 20px;
  }
`;
