import React from 'react';
import { find } from 'lodash';
import styled from 'styled-components';
import Button from '../../../components/button/Button';
import { Translate } from './localization/Translate';
import MinSize from './MinSize';
import { useOrderListContext } from './OrderListContext';
import { useUserContext } from './user-context/UserContextProvider';

const Styled = styled(Button)`
  &&& {
    @media (max-width: 780px) {
      width: 25px;
      min-width: 0;
      & > *:nth-child(2) {
        width: 0;
      }
      .icon {
        //margin-right: 0;
      }
    }
  }
`;

const CopyOrderButton = ({ order, metricId, ...props }) => {
  const { navigateToCopyOrder } = useOrderListContext();
  const { hasPermissions } = useUserContext();

  const orderType = React.useMemo(() => {
    const primaryLineItem = find(order?.lineItems, { isPrimary: true });
    return primaryLineItem?.item?.type?.toLowerCase();
  }, [order]);

  const handleCopyClick = React.useCallback(() => {
    if (order?.crn) {
      navigateToCopyOrder(order?.crn);
    }
  }, [navigateToCopyOrder, order?.crn]);

  const requirementsMet = React.useMemo(() => {
    return order?.originatorSystemType === 'CONNEX' && hasPermissions(`createOrder.${orderType}`);
  }, [order?.originatorSystemType, hasPermissions, orderType]);

  return requirementsMet ? (
    <Styled size="small" type="primary" onClick={handleCopyClick} metricId={metricId} {...props}>
      <i className="icon fas fa-copy" />
      <MinSize breakpoint={780}>
        <span className="btn-label"><Translate stringId="copyOrder" defaultMessage="Copy Order" /></span>
      </MinSize>
    </Styled>
  ) : null;
};

export default CopyOrderButton;
