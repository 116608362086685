import React from 'react';
import styled from 'styled-components';
import EditOrder from './edit-order/EditOrder';
import style from './style';

const ViewOrEditOrder = ({ className }) => {
  return <EditOrder />;
};

export default styled(ViewOrEditOrder)`
  ${style}
`;
