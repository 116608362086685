import React from 'react';
import Select from '../../../../components/form/Select';
import styled from 'styled-components';
import TaxLookupOptions from './tax-lookup-options';

const { Option } = Select;

const Label = styled.div`
  padding: 5px;
  margin-top: 5px;
`;

const Styled = styled.div`
  margin-bottom: 15px;
`;

const SelectTaxLookup = ({ item, onChange }) => {
  const valueChange = value => {
    onChange('taxLookupProvider', value);
  };

  return (
    <Styled>
      <Label>Tax Lookup Provider</Label>
      <Select id="taxLookupProvider" value={item.taxLookupProvider ?? TaxLookupOptions[0].value} onChange={valueChange}>
        {TaxLookupOptions?.map((item, idx) => (
          <Option key={item.value + idx} value={item.value}>
            {item.label}
          </Option>
        ))}
      </Select>
    </Styled>
  );
};

export default SelectTaxLookup;
