import cn from 'classnames';
import { get } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useTranslateMessage } from '../../../../order/components/localization/useTranslateMessage';
import DataLine from '../../components/data-line';
import { ContextMenuTrigger } from '../../dashboard-menu/context-menu';
import style from './style';
import { useShortDateTimeString } from '../../../../order/components/localization/ShortDateTime';

const columnFields = [
  'customer.id',
  'project.id',
  'destination.address.address1',
  'startTime',
  'primaryProduct.id',
  'primaryProduct.qty',
  'status',
];

const OrderRquests = ({ className, view = {}, setTitle }) => {
  const translateMessage = useTranslateMessage();

  const { getShortDateTimeString } = useShortDateTimeString();

  useEffect(() => {
    setTitle(
      translateMessage({
        stringId: 'orderRequests',
        defaultMessage: 'Order Requests',
      })
    );
  }, [setTitle, translateMessage]);

  const orderList = useMemo(() => {
    const result = (view?.orders || []).slice();
    return result;
  }, [view?.orders]);

  const columnHeaders = React.useMemo(() => {
    return [
      { stringId: 'createTime', defaultMessage: 'Customer ID/Name' },
      { stringId: 'projectIdName', defaultMessage: 'Project ID/Name' },
      { stringId: 'deliveryStreetAddress', defaultMessage: 'Delivery Street Address' },
      { stringId: 'scheduledStartTime', defaultMessage: 'Scheduled Start Time' },
      { stringId: 'primaryProductID', defaultMessage: 'Primary Product ID' },
      { stringId: 'primaryProductQty', defaultMessage: 'Primary Product Qty' },
      { stringId: 'status', defaultMessage: 'Status' },
    ].map(({ stringId, defaultMessage }) => {
      return translateMessage({ stringId, defaultMessage });
    });
  }, [translateMessage]);

  return (
    <div className={cn(className)}>
      <DataLine type={'order'} header columns={columnHeaders.map(value => ({ value }))} />
      {orderList.map((order, key) => {
        const columns = columnFields.map((field, key) => {
          let value = get(order, field);
          const primaryLineItem = order?.orders[0]?.lineItems?.find(li => li.isPrimary);
          if (field === 'customer.id') {
            const customerName = get(order, 'customer.name');
            if (customerName) {
              value = `${value} (${customerName})`;
            }
          }
          if (field === 'startTime') {
            value = getShortDateTimeString({
              date: get(order, 'startTimeIsoString', ''),
              hideTimeZone: true,
              showYear: false,
            });
          }
          if (field === 'project.id') {
            const projectName = get(order, 'project.name');
            if (projectName) {
              value = `${value} (${projectName})`;
            }
          }
          if (field === 'status') {
            value = translateMessage({
              stringId: value,
              defaultMessage: value,
            });
          }
          if (primaryLineItem) {
            if (field === 'primaryProduct.id') {
              value = `${primaryLineItem?.item?.id} ${primaryLineItem?.item?.name}`;
            }
            if (field === 'primaryProduct.qty') {
              value = `${primaryLineItem.orderedQuantity.value}`;
            }
          }

          return {
            configuration: {
              type: key === 9 && 'timer',
              countingDown: true,
            },
            value,
            background: order?.isInRange ? undefined : '#85d715',
          };
        });

        return (
          <ContextMenuTrigger key={order.crn} type={'orderRequest'} item={order}>
            <DataLine type={'order'} columns={columns} />
          </ContextMenuTrigger>
        );
      })}
    </div>
  );
};

export default styled(OrderRquests)`
  ${style}
`;
