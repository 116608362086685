const inTransit = load => {
  return (
    !load.ticket ||
    ['PRINTED', 'TO_JOB', 'ARRIVE_JOB', 'UNLOADING', 'LOADING_STARTED', 'LOADING_COMPLETE'].includes(
      load.ticket?.latestEventStatus
    )
  );
};

export default inTransit;
