/* global google */

import React from 'react';
import { useMapStateContext } from '../context/MapStateContext';

const _fitBounds = (map, ...args) => {
  if (!map) return;

  const bounds = [].concat(...args);
  if (bounds?.length) {
    const latLngBounds = new google.maps.LatLngBounds();
    bounds.forEach(point => {
      if (point?.lat && point?.lng) {
        latLngBounds.extend({ lat: point.lat, lng: point.lng });
      }
    });
    map.fitBounds(latLngBounds);
  }
};

export const useFitBounds = () => {
  const { map } = useMapStateContext();

  const fitBounds = React.useCallback(
    (...args) => {
      if (!map) return;

      const bounds = [].concat(...args);
      if (bounds?.length) {
        const latLngBounds = new google.maps.LatLngBounds();
        bounds.forEach(point => {
          if (point?.lat && point?.lng) {
            latLngBounds.extend({ lat: point.lat, lng: point.lng });
          }
        });
        map.fitBounds(latLngBounds);
      }
    },
    [map]
  );

  return { fitBounds };
};
