import { find, pick } from 'lodash';
import React from 'react';
import Select from '../../../components/form/Select';
import Margin from '../components/Margin';
import { useScaleTicketingContext } from '../Context';

const { Option } = Select;

const Project = () => {
  const { projects, form, handleChange } = useScaleTicketingContext();

  const handleProjectChange = React.useCallback(
    ref => {
      const targetProject = find(projects, { crn: ref });
      if (targetProject) {
        handleChange('vehicle', s => ({
          ...s,
          configuration: {
            ...s.configuration,
            project: {
              ...pick(targetProject, ['id', 'name']),
              projectRef: targetProject.crn,
            },
          },
        }));
      }
    },
    [handleChange, projects]
  );

  return (
    <Margin top={10}>
      <div className="field-label">Project</div>
      <Select
        value={form?.vehicle?.configuration?.project?.projectRef || ''}
        showSearch
        filterOption={(input, option) => option?.children?.toLowerCase?.().indexOf(input.toLowerCase?.()) >= 0}
        disabled={false}
        onChange={handleProjectChange}
      >
        {projects?.map(project => (
          <Option key={project?.crn} value={project.crn}>
            {`${project.id} / ${project.name}`}
          </Option>
        ))}
      </Select>
    </Margin>
  );
};

export default Project;
