/* global google */
import { find, pick, isEmpty } from 'lodash';
import React from 'react';
import http from '../../../../../../http';
import { prepareRoute } from '../util';
import * as util from '../util';

export default async ({
  setInitializing,
  setGeocodingAddress,
  setRoute,
  reverseGeocode,
  setEffectiveCoordinates,
  setEffectiveAddress,
  setTrucks,
  setOrigin,
  setDestination,
  setWaypoints,
  setConfig,
  fitBounds,
  form,
  lists,
  map,
}) => {
  setInitializing(true);

  const origin = util.getInitialConfig(
    form?.crn,
    form?.projectRef,
    find(lists?.locations?.list, { crn: form?.locationRef }),
    form?.address
  ).origin;

  const destination = isEmpty(form?.address)
    ? {}
    : util.getInitialConfig(
        form?.crn,
        form?.projectRef,
        find(lists?.locations?.list, { crn: form?.locationRef }),
        form?.address,
        true
      ).destination;

  let cfg;

  let coordinates = {
    lat: destination?.lat,
    lng: destination?.lng,
  };

  const handleMapClick = async e => {
    const coordinates = e.latLng.toJSON();
    setConfig(s => ({
      ...s,
      destination: {
        ...(s.destination || {}),
        ...coordinates,
      },
    }));

    const initialDestinationConfig = util.getInitialConfig(
      form?.crn,
      form?.projectRef,
      find(lists?.locations?.list, { crn: form?.locationRef }),
      form?.address,
      true
    ).destination;

    setDestination({
      ...initialDestinationConfig,
      ...coordinates,
    });

    setEffectiveCoordinates(coordinates);

    const address = await reverseGeocode(coordinates);

    if (address) setEffectiveAddress(address);

    google.maps.event.clearListeners(map, 'click');
  };

  if (!isEmpty(destination)) {
    if (!coordinates?.lat || !coordinates.lng) {
      setGeocodingAddress(true);

      const response = await http.post('/connex/services/orders/geo/geocodeAddress', form?.address);

      coordinates = {
        lat: response?.coordinates?.latitude,
        lng: response?.coordinates?.longitude,
      };

      setEffectiveCoordinates(s => {
        if (s) return s;
        return coordinates;
      });

      if(!response?.coordinates?.latitude || !response?.coordinates?.longitude){
        map.addListener('click', handleMapClick);
      }

      setGeocodingAddress(false);
    }

    cfg = {
      _meta: {
        initDisplay: () => {
          map.setCenter(coordinates);
          map.setZoom(17);
        },
      },
      markers: [origin, { ...destination, updateLocation: true, ...coordinates }, ...(form?._waypoints || [])],
    };

    if (form._destination) {
      destination.route = form._destination;

      setRoute(prepareRoute(form._destination?.route?.Legs));
    }
  } else {
    ['click'].forEach(eventName => google.maps.event.clearListeners(map, eventName));
    map.addListener('click', handleMapClick);

    cfg = {
      _meta: {
        initDisplay: () => {
          setTimeout(() => {
            map.setCenter(origin);
            map.setZoom(10);
          }, 100);
        },
      },
      markers: [origin],
    };
  }

  const originMarkerDef = find(cfg?.markers, { type: 'plant' });
  const destinationMarkerDef = find(cfg?.markers, { type: 'destination' });
  const waypointsMarkerDef = cfg?.markers?.filter?.(m => m.type === 'waypoint') || [];
  const trucksMarkerDef = cfg?.markers?.filter?.(m => m.type === 'truck') || [];
  const route = util.prepareRoute(destinationMarkerDef?.route?.Legs);

  if (cfg._meta?.initDisplay) {
    cfg._meta?.initDisplay();
  } else {
    fitBounds(originMarkerDef, destinationMarkerDef, waypointsMarkerDef, trucksMarkerDef, route);
  }

  setOrigin(originMarkerDef);
  setDestination(destinationMarkerDef);
  setWaypoints(waypointsMarkerDef);
  setTrucks(trucksMarkerDef);
  setConfig({
    configRef: cfg.crn,
    origin: originMarkerDef,
    destination: destinationMarkerDef,
    waypoints: waypointsMarkerDef,
    trucks: trucksMarkerDef,
    orderRef: form?.crn,
    ...pick(cfg, ['polling', 'pollingTime']),
  });
  setInitializing(false);
};
