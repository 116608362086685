import { css } from 'styled-components';

export default css`
  width: 100%;
  .formula-card {
    border: 1px solid #333;
    cursor: pointer;
    padding: 1rem;
    margin-bottom: 0.5rem;
  }
`;
