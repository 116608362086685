import React, { useEffect } from 'react';
import http from '../../../http';
import BaseMarker from '../markers/BaseMarker';
import OriginInfoWindow from './OriginInfoWindow';

const getIcon = async (icon, color, cb) => {
  if (icon) {
    const result = await http.getIcon(`/assets/map-icons/${icon}-${color}.png`);

    const reader = new FileReader();
    reader.onload = function () {
      cb(this.result);
    };
    reader.readAsDataURL(result);
  }
};

const Origin = origin => {
  const [dataUrl, setDataUrl] = React.useState(undefined);

  useEffect(() => {
    getIcon(origin?.icon, origin?.color, setDataUrl);
  }, [origin?.color, origin?.icon]);

  const originOptions = React.useMemo(() => {
    return {
      key: 'origin',
      position: {
        lat: origin.lat,
        lng: origin.lng,
      },
      icon: dataUrl ? { url: dataUrl, scaledSize: { width: 50, height: 69 } } : undefined,
    };
  }, [dataUrl, origin.lat, origin.lng]);

  return (
    <BaseMarker {...originOptions}>
      <OriginInfoWindow origin={origin} />
    </BaseMarker>
  );
};

export default Origin;
