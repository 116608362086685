import {css} from 'styled-components';

export default css`
  width: 100%;
  
  .table-container {
    height: 480px;
    overflow-y: auto;  
  }
  
  .field-container {
    margin: 0;
  }
  
  table {
    width: 100%;
  }
  
  thead th {
    background-color: #0e4980;
    padding: 5px;
    border: solid 1px #3a3a3a;
  }
  
  tbody td {
    padding: 3px;
    border: solid 1px #333;
  }
  
  .total {
    text-align: right;  
  }
`;
