import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import FieldContainer from '../../field-container/FieldContainer';
import Input from '../../form/Input';
import style from './style';

const Description = ({ className, value }) => {
  return (
    <FieldContainer className={cn('description', className)}>
      <div className="label-spinner">
        <div className="label">Description</div>
        <div className={cn('spinner', { spinning: false })}>
          <span className="fas fa-sync fa-spin" />
        </div>
      </div>
      <Input type="text" value={value || ''} disabled data-testid="description_input" />
    </FieldContainer>
  );
};

export default styled(Description)`
    ${style}
`;
