import style from './style';
import styled from 'styled-components';
import {Radio} from 'antd';
import React from "react";

const Styled = styled.div`
  ${style}
`;

const RadioGroup = ({className, label, ...props}) => {
  return <Styled>
    {label && <div>{label}</div>}
    <Radio.Group {...props} />
  </Styled>;
}

export default RadioGroup;