import { css } from 'styled-components';

export default css`
  padding: 5px;
  position: relative;

  .view-selection {
    position: absolute;
    left: 15px;
    top: 10px;
  }
  .title {
    width: 100%;
    text-align: center;
    font-weight: bold;
    font-size: 1.08rem;
  }
  .preview-selection {
    position: absolute;
    right: 15px;
    top: 10px;
  }
`;
