import React, {useMemo} from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import style from './style';
import {Checkbox} from 'antd';
import {useDashboardContext} from "../../../context/useDashboardContext";

const TrackingConfigurationForm = ({className, saveTrackingConfiguration, trackingConfiguration}) => {
  const {locations} = useDashboardContext();

  const locationList = useMemo(() => {
    const result = locations || [];
    result.sort((a,b) => a.id.localeCompare(b.id, undefined, {numeric: true, sensitivity: 'base'}));
    return result.filter(l => !l.isDispatchLocation);
  }, [locations])

  return <div className={cn(className)}>
    {locationList?.map((location, key) => {
      return <div key={key}>
        <Checkbox
          checked={
            trackingConfiguration?.selectedLocations?.[location.crn || location.id] === true
          }
          onChange={
            e => saveTrackingConfiguration(`selectedLocations.${location.crn || location.id}`, e.target.checked)}>{location.id} / {location.Name || location.description}</Checkbox>
      </div>
    })}
  </div>
}

export default styled(TrackingConfigurationForm)`
  ${style}
`;