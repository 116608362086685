import React, { useCallback } from 'react';
import cn from 'classnames';
import { DatePicker } from 'antd';
import styled from 'styled-components';
import { useIntlContext } from '../../../../i18n';
import moment from 'moment';
import { useDateFormat } from '../../../../hooks/useDateFormat';

const Date = ({ className, value, onChange, disabled, innerRef, onFocus }) => {
  const { userLocale } = useIntlContext();
  const { dateFormat, antLocales } = useDateFormat(userLocale);

  const handleDateChange = useCallback(
    e => {
      onChange(e.toISOString().split('T')[0]);
    },
    [onChange]
  );

  const handleKeyDown = useCallback(
    e => {
      if (e.keyCode === 13) {
        e.preventDefault();

        const inputValue = e.target.value.trim();
        const parsedDate = moment(inputValue, dateFormat);

        if (parsedDate.isValid()) {
          onChange(parsedDate.toISOString().split('T')[0]);
        }
      }
    },
    [dateFormat, onChange]
  );

  return (
    <div className={cn('date', className)}>
      <div className="date-part">
        <DatePicker
          ref={innerRef}
          onChange={handleDateChange}
          value={value ? moment(value) : null}
          disabled={disabled}
          onFocus={onFocus}
          format={dateFormat}
          locale={antLocales[userLocale]}
          placeholder={`${dateFormat.toLocaleLowerCase()}`}
          onKeyDown={handleKeyDown}
        />
      </div>
    </div>
  );
};

export default styled(Date)``;
