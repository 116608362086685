import React from 'react';
import { useMapStateContext } from '../context/MapStateContext';
import { useCalculateRoute } from './routing/useCalculateRoute';
import { useMap } from './useMap';

export const useHandleAddressChange = () => {
  const { fitBounds } = useMap();
  const { calculateRoute } = useCalculateRoute();
  const {
    origin,
    route,
    waypoints,
    setChosenAddress,
    setEffectiveAddress,
    setEffectiveCoordinates,
    destination,
    setDestination,
    allowRouting,
    canUpdate,
  } = useMapStateContext();

  const handleAddressChange = React.useCallback(
    async address => {
      const parsedAddress = JSON.parse(address);
      setChosenAddress(parsedAddress);
      setEffectiveAddress(parsedAddress);

      const newEffectiveCoordinates = {
        lat: parsedAddress.coordinates?.latitude,
        lng: parsedAddress.coordinates?.longitude,
      };

      setEffectiveCoordinates(newEffectiveCoordinates);
      fitBounds(route, waypoints, origin, newEffectiveCoordinates);

      const newDestination = {
        icon: 'default',
        color: 'blue',
        id: 'destination',
        type: 'destination',
        ...newEffectiveCoordinates,
        info: [
          {
            name: 'Destination',
            desc: parsedAddress?.address1,
          },
        ],
        ...(destination || {}),
        draggable: true,
        allowRouting: true,
        updateLocation: true,
      };

      setDestination(newDestination);

      const newRoute = await calculateRoute({ waypoints: waypoints, coordinates: newEffectiveCoordinates });

      fitBounds(newRoute, waypoints, origin, newEffectiveCoordinates);
    },
    [
      setChosenAddress,
      setEffectiveAddress,
      setEffectiveCoordinates,
      fitBounds,
      route,
      waypoints,
      origin,
      allowRouting,
      canUpdate,
      destination,
      setDestination,
      calculateRoute,
    ]
  );

  return { handleAddressChange };
};
