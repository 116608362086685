import SelectSetupItem from '../select-setup-item';
import React from 'react';

const SelectCustomer = ({ item, onChange, disabled, customers, busy }) => {
  const changeCustomer = (id, value) => {
    const selectedCustomer = customers?.get(value?.entityRef);
    if (selectedCustomer) {
      onChange('salesPersonRef', selectedCustomer.salesPersonRef);
      onChange('taxStatus', selectedCustomer.taxStatus);
      if (selectedCustomer.taxExemptReason) onChange('taxExemptReason', selectedCustomer.taxExemptReason);
    }

    onChange('customerParty', value);
  };

  const hasPreselectedCustomer = item?.customerParty?.entityRef && !item.customerParty.id ? true : false;

  return (
    <SelectSetupItem
      id="entityRef"
      label="Customer"
      typeId="customer"
      item={item}
      setupItemRef={item?.customerParty?.entityRef}
      showIdWithLabel={true}
      onChange={changeCustomer}
      hasDefaultItem={hasPreselectedCustomer}
      readOnly={disabled || busy}
    />
  );
};

export default SelectCustomer;
