import style from '../style';
import React from 'react';
import styled from 'styled-components';
import InputText from '../../components/input-text';
import InputSelect from '../../components/input-select';
import { status, yesNo, uom } from './options';
import InputCheckbox from '../../components/input-checkbox';
import ColorPicker from '../../../order/components/color-picker';
import InputQuantity from '../../components/input-quantity';

const View = styled.div`
  ${style}
`;

const tdStyle = {
  padding: '3px',
  width: '250px',
};

const OrderStatusGeneral = ({ item, onChange }) => {
  return (
    <View>
      <InputText id="id" label="ID" value={item?.id} onChange={onChange} />
      <InputText id="name" label="Name" value={item?.name} onChange={onChange} />
      <InputSelect
        id="displayInPlanningGraphs"
        label="Display In Planning Graph"
        value={item?.displayInPlanningGraphs ?? 'YES'}
        onChange={onChange}
        options={yesNo}
        required
      />
      <InputSelect id="status" label="Status" value={item?.status} onChange={onChange} options={status} required />
      <table>
        <tbody>
          <tr>
            <td>
              <InputQuantity
                id="defaultLoadSize"
                label="Default Load Size"
                min={0}
                value={item?.defaultLoadSize}
                onChange={onChange}
              />
            </td>
            <td style={tdStyle}>
              <InputSelect
                id="defaultLoadSizeUOM"
                label="Unit of Measure"
                value={item?.defaultLoadSizeUOM}
                onChange={onChange}
                options={uom}
              />
            </td>
          </tr>
          <tr>
            <td>
              <InputQuantity
                id="maxLoadSize"
                label="Max Load Size"
                min={0}
                value={item?.maxLoadSize}
                onChange={onChange}
              />
            </td>
            <td style={tdStyle}>
              <InputSelect
                id="maxLoadSizeUOM"
                label="Unit of Measure"
                value={item?.maxLoadSizeUOM}
                onChange={onChange}
                options={uom}
              />
            </td>
          </tr>
        </tbody>
      </table>
      <ColorPicker
        fieldLabel="Tracking Color"
        fieldName="trackingColor"
        onChange={onChange}
        value={item?.trackingColor || '#FFFF00'}
      />
      <InputCheckbox id="defaultValue" label="Default" value={item?.defaultValue ?? false} onChange={onChange} />
    </View>
  );
};

export default OrderStatusGeneral;
