export default (invoices, openDrawerHandler) => {
  const tickets = [];
  invoices.forEach(invoice => {
    invoice?.orderRefs?.forEach?.(order => {
      order?.billedRefs?.forEach?.(ticket => {
        tickets.push({
          invoiceRef: invoice?.crn,
          invoiceNumber: invoice.invoiceNumber,
          invoiceDate: invoice.invoiceDate,
          invoiceDueDate: invoice.dueDate,
          customerName: invoice.customerName,
          customerId: invoice.customerId,
          destination: invoice.destination,
          orderId: order.orderId,
          salesPerson: order.salesPerson,
          startDate: order.startDateTime,
          ticketId: ticket.ticketId,
          dispatchDate: ticket.dispatchDateTime,
          subtotalPrice: ticket?.lineItems?.reduce?.((acc, value) => acc + value.totalPrice - value.salesTax, 0) || 0,
          cityTax: ticket?.lineItems?.reduce?.((acc, value) => acc + value.cityTax, 0) || 0,
          countyTax: ticket?.lineItems?.reduce?.((acc, value) => acc + value.countyTax, 0) || 0,
          districtTax: ticket?.lineItems?.reduce?.((acc, value) => acc + value.districtTax, 0) || 0,
          stateTax: ticket?.lineItems?.reduce?.((acc, value) => acc + value.stateTax, 0) || 0,
          salesTax: ticket?.lineItems?.reduce?.((acc, value) => acc + value.salesTax, 0) || 0,
          totalPrice: ticket?.lineItems?.reduce?.((acc, value) => acc + value.totalPrice, 0) || 0,
          documentRef: invoice.documentRef,
          recipients: invoice.recipients,
          exported: invoice.exported,
          exportedDate: invoice.exportedDate,
          openDrawerHandler: () => openDrawerHandler({ invoiceRef: invoice?.crn }),
        });
      });
    });
  });

  return tickets;
};
