import { css } from 'styled-components';

export default css`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;

  .fill-grid {
    --border-color: #444;
    background-color: transparent;
    border-color: var(--border-color);
    border: none;

    height: 100%;

    .rdg-header-row {
      background-color: #222;
      font-weight: normal;

      .rdg-cell {
        font-size: 14px;
        border: 1px solid var(--border-color);
      }

      color: #eee;
    }

    .rdg-row {
      background-color: transparent;
      color: #eee;

      .rdg-cell {
        border-color: var(--border-color);
        &:first-child {
          border-left: 1px solid var(--border-color);
        }
      }

      &:hover {
        background-color: #333;
      }
    }

    .grid-row {
      cursor: pointer;
    }

    .rdg-cell-selected {
      /* disable selection border color */
      box-shadow: none !important;
    }
  }
`;
