import objectHash from 'object-hash';
import { useCallback, useState } from 'react';
import canonicalJson from '../../../../util/canonicalJson';

export const useHash = () => {
  const [initialHash, setInitialHash] = useState(null);

  const initialize = useCallback(form => {
    setInitialHash(objectHash(canonicalJson(form)));
  }, []);

  const hasChanged = useCallback(
    form => {
      return objectHash(canonicalJson(form)) !== initialHash;
    },
    [initialHash]
  );

  return { initialize, hasChanged };
};
