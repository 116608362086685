import Logic from 'json-logic-js';

export const calcLoadCountWithTimeTrigger = (triggerLogic, order, productRef) => {
  let loadCount = 0;
  const logic = JSON.parse(triggerLogic);

  if (order?.linkedOrders?.length > 0) {
    order.linkedOrders.forEach(o => {
      loadCount += calcLoadCountWithTimeTrigger(triggerLogic, o, productRef);
    });
    return loadCount;
  }
  if (order?.deliverySchedule) {
    // Ticketed
    order.deliverySchedule.tickets?.forEach(t => {
      if (t?.ticket?.isVoided) return;
      const lineItem = t.ticket?.lineItems?.find(li => li.item?.productRef === productRef);
      if (lineItem) {
        loadCount++;
      }
    });

    // Scheduled
    order.deliverySchedule.schedule?.forEach(s => {
      const loadTime = new Date(s.ticketEvents?.LOADING_STARTED);
      const context = {
        dispatchTime: loadTime.getHours() * 3600 + loadTime.getMinutes() * 60 + loadTime.getSeconds(),
      };
      if (Logic.apply(logic, context)) {
        loadCount++;
      }
    });
  }
  return loadCount;
};
