import { Button, DatePicker, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useDashboardContext } from '../../context/useDashboardContext';

const callResync = async (entityRef, since, typeId = null) => {
  return null;
  // return API.post('API',  `/connex/services/mobile-ticket/${entityRef}/connex-sync`, {body: {since, typeId}, headers: {
  //     Accept: 'application/json',
  //     'Content-Type': 'application/json',
  //     'x-api-key': '4hdT6llav1fttQYhBtFQuWMNu4wXwi1HNnEM4TD5',
  //     'x-connex-id': getCachedItem('x-connex-id'),
  //   }});
};

const ResyncData = ({ setTitle }) => {
  const { entityRef } = useDashboardContext();
  const [since, setSince] = useState(moment().subtract(1, 'day'));
  const [loading, setLoading] = useState(false);
  const [typeId, setTypeId] = useState();
  const [itemCount, setItemCount] = useState({});

  useEffect(() => {
    setTitle('Resync Data');
  }, []);

  return (
    <div style={{ flexDirection: 'column', display: 'flex', flex: 1, margin: '20px' }}>
      <DatePicker value={since} showTime onChange={setSince}></DatePicker>
      <Input placeholder={'Item TypeId'} onChange={e => setTypeId(e.target.value)} />

      <br />
      <Button
        onClick={async () => {
          setLoading(true);
          const sinceValue = since;
          // setSince(moment());
          const result = await callResync(entityRef, moment(sinceValue).toISOString(), typeId).catch(() =>
            setLoading(false)
          );

          setItemCount(result);
          setLoading(false);
        }}
      >
        {loading ? 'Loading...' : 'Resync Data'}
      </Button>
      <div>Item Count V2: {itemCount.v2 || 0}</div>
      <div>Item Count V3: {itemCount.v3 || 0}</div>
    </div>
  );
};

export default ResyncData;
