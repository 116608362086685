import http from '../../../http';
import queryParams from '../../invoice/api/query-params';

const orders = '/connex/services/orders';

export const api = {
  searchProducts: async (entityRef, params) => {
    const items = [];
    let pageToken = null;
    do {
      const result = await http.post(`${orders}/${entityRef}/master-data/products/search`, { pageToken }, params);
      items.push(...result.items);
      pageToken = result.pageToken;
    } while (pageToken);

    return items;
  },
  getProducts: (entityRef, params) => http.get(`${orders}/${entityRef}/master-data/products${queryParams(params)}`),
  getProduct: (entityRef, productRef) => http.get(`${orders}/${entityRef}/master-data/products/${productRef}`),
  getProductCosts: (entityRef, productRef) =>
    http.get(`${orders}/${entityRef}/master-data/products/${productRef}/costs`),
  updateProduct: (entityRef, productRef, product) =>
    http.patch(`${orders}/${entityRef}/master-data/products/${productRef}`, product),
  createProduct: (entityRef, product) => http.post(`${orders}/${entityRef}/master-data/products`, product),

  getSalesPersons: entityRef => http.get(`${orders}/${entityRef}/master-data/sales-persons`),

  getLocations: (entityRef, params) => http.get(`${orders}/${entityRef}/master-data/locations`, params),
  getLocation: (entityRef, locationRef) => http.get(`${orders}/${entityRef}/master-data/locations/${locationRef}`),
  updateLocation: (entityRef, locationRef, location) =>
    http.patch(`${orders}/${entityRef}/master-data/locations/${locationRef}`, location),
  createLocation: (entityRef, location) => http.post(`${orders}/${entityRef}/master-data/locations`, location),

  getCustomers: (entityRef, params) => http.get(`${orders}/${entityRef}/master-data/customers`, params),
  getCustomer: (entityRef, customerRef) => http.get(`${orders}/${entityRef}/master-data/customers/${customerRef}`),
  getCustomerProjects: (entityRef, customerRef) =>
    http.get(`${orders}/${entityRef}/master-data/customers/${customerRef}/projects`),
  updateCustomer: (entityRef, customerRef, customer) =>
    http.patch(`${orders}/${entityRef}/master-data/customers/${customerRef}`, customer),
  createCustomer: (entityRef, customer) => http.post(`${orders}/${entityRef}/master-data/customers`, customer),

  getProjects: (entityRef, params) => http.get(`${orders}/${entityRef}/master-data/projects`, params),
  searchProjects: (entityRef, params) => http.post(`${orders}/${entityRef}/master-data/projects/search`, params),
  getProject: (entityRef, projectRef) => http.get(`${orders}/${entityRef}/master-data/projects/${projectRef}`),
  createProject: (entityRef, project) => http.post(`${orders}/${entityRef}/master-data/projects`, project),
  updateProject: (entityRef, projectRef, project) =>
    http.patch(`${orders}/${entityRef}/master-data/projects/${projectRef}`, project),
  getDexas: entityRef => http.get(`${orders}/${entityRef}/master-data/dexas`),
  getDexaLocationPlants: (entityRef, dexaId) =>
    http.get(`${orders}/${entityRef}/dexas/${dexaId}/location-plants`),
  getFunctions: entityRef => http.get(`${orders}/${entityRef}/master-data/functions`),
  getPriceBooks: (entityRef, pageToken, params) =>
    http.post(`${orders}/${entityRef}/setup/cost-book/search`, {
      ...params,
      pageToken,
    }),
  getCompanyTimeZone: entityRef => http.get(`${orders}/${entityRef}/setup/company/time-zone/${entityRef}`),
  getCompanyLicenses: entityRef => http.get(`${orders}/${entityRef}/setup/company/licenses/${entityRef}`),
  getCompanyPDFTemplates: entityRef => http.get(`${orders}/${entityRef}/setup/company/pdf-templates/${entityRef}`),
};

export default api;
