import cn from 'classnames';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import Button from '../button/Button';
import { useModalContext } from '../modal-context/ModalContext';

const ErrorModal = ({ className, error, customHandler }) => {
  const { closeModal } = useModalContext();

  const onClickHandler = useMemo(
    () =>
      customHandler
        ? () => {
            closeModal();
            customHandler();
          }
        : closeModal,
    [customHandler, closeModal]
  );

  return (
    <div className={cn(className)}>
      <div className="error-section">
        <div className="error">{error?.error}</div>
        <div className="message">{error?.message}</div>
      </div>
      <div className="button-section">
        <Button type="primary" onClick={onClickHandler}>
          Okay
        </Button>
      </div>
    </div>
  );
};

export default styled(ErrorModal)`
  .error-section {
    .error {
      margin-bottom: 10px;
    }
  }
  .button-section {
    margin-top: 15px;
    display: flex;
    justify-content: flex-end;
  }
`;
