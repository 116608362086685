import { set } from 'lodash';
import Tabs from '../../../../components/tabs';
import Configuration from './configuration/Configuration';
import style from './style';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import Button from '../../../../components/button/Button';
import useSetup from '../../useSetup';
import Buttons from '../../../../components/layout/Buttons';
import Spinner from '../../../../components/spinner/Spinner';
import General from './general/General';
import Weights from './weights/Weights';
import OnVehicle from './on-vehicle/OnVehicle';
import FormError from '../../components/form-error';

const ButtonBar = ({ onCancel, onSave, busy }) => {
  return (
    <Buttons>
      <Button id="btn-cancel" disabled={busy} onClick={onCancel} metricId="core-setup-vehicle-editor-cancel">
        Cancel
      </Button>
      <Button id="btn-save" loading={busy} disabled={busy} onClick={onSave} metricId="core-setup-vehicle-editor-save">
        Save
      </Button>
    </Buttons>
  );
};

const Styled = styled.div`
  ${style}
`;

const VehicleEditor = ({ item, onSave, onCancel }) => {
  const { saveVehicle, vehicle, getVehicle, setVehicle, itemBusy, error } = useSetup();
  const [tab, setTab] = React.useState('general');

  useEffect(() => {
    if (item) {
      if (item.crn) {
        getVehicle(item.crn);
      } else {
        setVehicle(item);
      }
    }
  }, [getVehicle, item, setVehicle]);

  const onChange = React.useCallback(
    (path, value) => {
      setVehicle(s => {
        return set({ ...s }, path, value);
      });
    },
    [setVehicle]
  );

  const onSaveItem = () => {
    saveVehicle()
      .then(() => onSave(true))
      .catch(() => {});
  };

  return (
    <Styled>
      <FormError error={error} />
      <Spinner spin={!vehicle && itemBusy}>
        <Tabs type="card" activeKey={tab} onChange={setTab}>
          <Tabs.TabPane tab="General" key="general" disabled={false}>
            <General vehicle={vehicle} onChange={onChange} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Weights" key="weights" disabled={false}>
            <Weights vehicle={vehicle} onChange={onChange} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Current Configuration" key="configuration" disabled={false}>
            <Configuration configuration={vehicle?.configuration} onChange={onChange} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="On Vehicle" key="onVehicle" disabled={false}>
            <OnVehicle vehicle={vehicle} onChange={onChange} />
          </Tabs.TabPane>
        </Tabs>
        <ButtonBar onSave={onSaveItem} onCancel={onCancel} busy={itemBusy} />
      </Spinner>
    </Styled>
  );
};

export default VehicleEditor;
