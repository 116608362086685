import { find, set } from 'lodash';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ErrorModal from '../../../components/error-modal/ErrorModal';
import { useModalContext } from '../../../components/modal-context/ModalContext';
import { MODES } from '../components/order-form/OrderForm';
import { useCommonOrderBehavior } from '../components/useCommonOrderBehavior';
import generateCleanedUpFormData from '../util/generate-cleaned-up-form-data';

export const useCreateOrderBehavior = orderType => {
  const commonOrderBehavior = useCommonOrderBehavior(orderType.toUpperCase());

  const {
    order,
    form: { form, handleChange, setForm, auditNotes },
    masterData: { masterData },
    defaults: { defaults },
    start: { startOrder },
  } = commonOrderBehavior;

  // If customer changes, clear dependent fields.
  React.useEffect(() => {
    setForm(s => {
      if (s?.customerRef) {
        return { ...s, projectRef: null, locationRef: null, billingLocationRef: null };
      }
      return s;
    });
  }, [form?.customerRef, setForm]);

  // Apply defaults
  React.useEffect(() => {
    if (defaults) {
      setForm(
        s => ({
          ...s,
          address: defaults?.address,
          projectNote: defaults?.jobNotes,
          paymentMethodRef: defaults?.paymentMethods,
          salesPersonRef: defaults?.salesPersonRef,
          shippingMethodRef: defaults?.shippingMethods,
          spacing: defaults?.spacing?.value,
          spacingUnitRef: find(masterData?.spacingOptions, { crn: defaults?.spacing?.uomCode })?.crn,
          supplierStatusRef: defaults?.supplierStatusCodes,
          workTypeRef: defaults?.workTypes,
          taxRateRef: defaults?.taxRateRef,
          travelTime: defaults?.travelTime,
          unloadTime: defaults?.unloadTime,
          driverInstructions: defaults?.driverInstructions,
          vehicleTypeRef: defaults?.vehicleTypeRef,
          purchaseOrder: defaults?.purchaseOrder,
          excludeZoneCharges: defaults?.excludeZoneCharges,
        }),
        true
      );
    }
  }, [masterData, defaults, setForm]);

  const { entityRef } = useParams();
  const navigate = useNavigate();
  const { openModal } = useModalContext();

  const handleCancelClick = React.useCallback(() => {
    navigate(`/${entityRef}/cx`);
  }, [entityRef, navigate]);

  const handleSaveClick = React.useCallback(() => {
    const cleanedUpFormData = generateCleanedUpFormData(form, auditNotes);

    const navigateFunction = savedOrder => {
      navigate(`../../edit/${orderType.toLowerCase()}/${savedOrder?.crn}/general`);
    };

    return order
      .createOrder(cleanedUpFormData)
      .then(navigateFunction)
      .catch(e => {
        let handler;
        switch (e.message) {
          case 'Unable to determine tax rates for jurisdiction':
            handler = () => navigateFunction(e);
            break;
          default:
            break;
        }
        openModal('Error', <ErrorModal error={e} customHandler={handler} />);
      });
  }, [form, auditNotes, order, navigate, openModal, orderType]);

  React.useEffect(() => {
    if (form?.project) {
      handleChange('purchaseOrder', form?.project?.purchaseOrder || '');
    }
  }, [form?.project, handleChange]);

  React.useEffect(() => {
    startOrder();
  }, [startOrder]);

  const createOrderBehavior = {
    ...commonOrderBehavior,
    handleSaveClick,
    handleCancelClick,
    mode: MODES.CREATE,
    saveOrderBusy: order.orderBusy,
    readOnly: false,
    orderType,
  };

  set(createOrderBehavior, 'order.readOnly', false);

  return createOrderBehavior;
};
