import React from 'react';
import { Dropdown, Menu } from 'antd';
import { kebabCase } from 'lodash';
import styled from 'styled-components';
import cn from 'classnames';
import { buttonClick } from '../../util/analytics';
import { cacheItem } from '../../util/cache';
import MaxSize from '../../views/order/components/MaxSize';
import MinSize from '../../views/order/components/MinSize';
import style from './style';

const Styled = styled(Dropdown.Button)`
  ${style}
`;

const Multiple = ({ options, setCurrentButton, currentButton, onClick }) => {
  const buttonOptions = React.useMemo(
    () =>
      options?.reduce?.((acc, value) => {
        acc[value.id] = value;
        return acc;
      }, {}),
    [options]
  );

  const handleMenuClick = React.useCallback(
    e => {
      setCurrentButton(buttonOptions[e.key]);
      cacheItem('lastUsedCreateButton', buttonOptions[e.key].id);
      buttonClick(buttonOptions[e.key].onClick, buttonOptions[e.key].metricId);
    },
    [buttonOptions, setCurrentButton]
  );

  const menu = React.useMemo(() => {
    const menuItems = options?.filter?.(option => option.id !== currentButton?.id);
    return (
      <Menu onClick={handleMenuClick}>
        {menuItems.map(menuItem => (
          <Menu.Item key={menuItem.id}>{menuItem.label}</Menu.Item>
        ))}
      </Menu>
    );
  }, [currentButton?.id, handleMenuClick, options]);

  return (
    <Styled
      className={cn('multiple')}
      type="primary"
      size="small"
      onClick={onClick}
      overlay={menu}
      label={currentButton.label}
      data-testid={`button-${kebabCase(currentButton.label?.toLowerCase?.())}`}
    >
      <MaxSize breakpoint={781}>{currentButton?.id?.toUpperCase?.()?.substring?.(0, 1)}</MaxSize>

      <MinSize breakpoint={780}>{currentButton.label}</MinSize>
    </Styled>
  );
};

export default Multiple;
