import React from 'react';
import { useParams } from 'react-router-dom';
import { api } from '../api';

export const useInvoiceExportHistory = () => {
  const { entityRef } = useParams();
  const [busy, setBusy] = React.useState(false);
  const [invoices, setInvoices] = React.useState([]);

  const listHistory = React.useCallback(
    startDate => {
      setBusy(true);
      const params = Array.isArray(startDate) ? { startDate: startDate[0], endDate: startDate[1] } : { startDate };
      api
        .listInvoicesExported(entityRef, params)
        .then(({ items }) => {
          setInvoices(items);
        })
        .finally(() => {
          setBusy(false);
        });
    },
    [entityRef]
  );

  return { listHistory, invoices, busy };
};
