import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { api } from '../api';

const transformToFormData = order => {
  return {
    allowEdit: order?.allowEdit,
    crn: order?.crn,
    supplier:
      order?.supplier || { crn: order?.supplierParty?.entityRef, name: order?.supplierParty?.name } || undefined,
    discipline: { ...order?.orderDiscipline, crn: order?.orderDiscipline?.disciplineRef } || undefined,
    description: order?.description || '',
    number: order?.id,
    job: order?.job,
    pourDescription: order?.pourDescription,
    product: {
      ...order?.lineItems?.[0]?.item,
      uomCode: order?.lineItems?.[0]?.orderedQuantity?.uomCode,
    },
    quantity: order?.lineItems?.[0]?.orderedQuantity?.value || '',
    startDateTime: moment(order?.deliverySchedule?.startDateTime),
    slump: order?.lineItems?.[0]?.properties?.slump || '',
    spacing: order?.deliverySchedule?.deliverySpacing || '',
    spacingUnits: order?.deliverySchedule?.deliverySpacingUnits || '',
    pourType: order?.lineItems?.[0]?.properties?.pourType || '',
    notes: order?.notes?.map(note => note || ''),
    contact: order.contact || '',
    emailCopies: order.copies || [],
    directions: order?.directions || '',
    requestedBy: order?.requestedBy,
    pourCardNumber: order?.pourCardNumber || '',
    tickets: order?.tickets || [],
    ticketsMeta: {
      count: order?.ticketCount || 0,
      totalDeliveredQuantity: order?.totalDeliveredQty || 0,
      totalOrderedQuantity: order?.totalOrderedQty || 0,
    },
  };
};

export const useOrder = () => {
  const { entityRef } = useParams();
  const [order, setOrder] = useState(null);
  const [orderBusy, setOrderBusy] = useState(false);

  const getOrder = useCallback(
    orderCrn => {
      setOrder(null);
      setOrderBusy(true);

      return api
        .getOrderRequest(entityRef, orderCrn)
        .then(response => {
          setOrder(transformToFormData(response));
          return response;
        })
        .finally(() => {
          setOrderBusy(false);
        });
    },
    [entityRef]
  );

  return { getOrder, order, orderBusy };
};
