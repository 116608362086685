import cn from 'classnames';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import Button from '../button/Button';
import { useModalContext } from '../modal-context/ModalContext';
import { Translate } from "../../views/order/components/localization/Translate";

const ConfirmModal = ({ className, message, customHandler }) => {
  const { closeModal } = useModalContext();

  const onClickHandler = useMemo(
    () =>
      customHandler
        ? () => {
            closeModal();
            customHandler();
          }
        : closeModal,
    [customHandler, closeModal]
  );

  return (
    <div className={cn(className)}>
      <div className="error-section">
        <div className="confirm">
            {message}
        </div>
      </div>
      <div className="button-section">
        <Button type="primary" onClick={onClickHandler}>
          <Translate stringId="confirm" defaultMessage="Confirm"/>
        </Button>
        <Button type="secondary" onClick={closeModal}>
            <Translate stringId="cancel" defaultMessage="Cancel"/>
        </Button>
      </div>
    </div>
  );
};

export default styled(ConfirmModal)`
  .error-section {
    .error {
      margin-bottom: 10px;
    }
  }
  .button-section {
    margin-top: 15px;
    display: flex;
    justify-content: flex-end;
  }
`;
