import style from '../style';
import React from 'react';
import styled from 'styled-components';
import InputText from '../../../components/input-text';
import InputSelect from '../../../components/input-select';
import { status, direction, uoms } from './options';
import SelectLocation from '../../../components/select-location';

const View = styled.div`
  ${style}
`;

const ScaleGeneral = ({ scale, onChange }) => {
  return (
    <View>
      <InputText id="id" label="ID" value={scale?.id} onChange={onChange} />
      <InputText id="name" label="Name" value={scale?.name} onChange={onChange} />

      <InputSelect id="direction" label="Direction" value={scale?.direction} onChange={onChange} options={direction} />

      <InputSelect id="uom" label="Unit of Measure" value={scale?.uom} onChange={onChange} options={uoms} />

      <InputSelect id="status" label="Status" value={scale?.status} onChange={onChange} options={status} required />

      <InputText id="cameraUrl" label="Camera URL" value={scale?.cameraUrl} onChange={onChange} />

      <InputText id="printerName" label="Printer Name" value={scale?.printerName} onChange={onChange} />

      <SelectLocation id={'location'} onChange={onChange} label={'Location'} item={scale} />
    </View>
  );
};

export default ScaleGeneral;
