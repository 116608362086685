import React from 'react';
import { isEqual, values, isFunction, set, cloneDeep, omit } from 'lodash';
import { lineItemsValid, linkedOrdersValid } from './util/lineItemValidation';
import { useTaxExemptReasons } from '../../hooks/useTaxExemptReasons';

export const useForm = (initialState = {}, noCloning) => {
  const [form, _setForm] = React.useState(initialState);
  const [validatedFields, setValidatedFields] = React.useState();
  const [auditNotes, setAuditNotes] = React.useState([]);
  const [purchaseOrderRequired, setPurchaseOrderRequired] = React.useState(false);
  const { taxExemptReasonRequired } = useTaxExemptReasons();

  const checkpoint = React.useRef(initialState);

  const handleChange = React.useCallback(
    (...args) => {
      const callback = args?.[2];

      if (isFunction(args?.[1])) {
        const [field, fn] = args;
        _setForm(s => {
          const newState = noCloning ? { ...s } : cloneDeep(s);
          set(newState, field, fn(s[field]));
          callback?.(field, newState[field]);
          return newState;
        });
      } else {
        const [field, value] = args;
        _setForm(s => {
          const newState = noCloning ? { ...s } : cloneDeep(s);
          set(newState, field, value);
          callback?.(field, value);
          return newState;
        });
      }
    },
    [noCloning]
  );

  const isPOValid = React.useCallback(
    purchaseOrder => {
      if (purchaseOrderRequired) {
        if (!purchaseOrder) {
          return false;
        }
        return purchaseOrder?.length > 0;
      }

      return true;
    },
    [purchaseOrderRequired]
  );
  const resetForm = React.useCallback(() => {
    _setForm(cloneDeep(initialState));
  }, [initialState]);
  const setForm = React.useCallback((f, updateCheckpoint) => {
    _setForm(s => {
      const newState = isFunction(f) ? f(s) : f;
      if (updateCheckpoint) {
        checkpoint.current = newState;
      }
      return newState;
    });
  }, []);

  const omitPaths = React.useCallback(paths => {
    const pathsArray = [].concat(paths);

    pathsArray.forEach(path => {
      _setForm(s => omit(s, path));
    });
  }, []);

  React.useEffect(() => {
    // customerRef
    setValidatedFields(s => {
      const newState = { ...s };
      return set(newState, 'customerRef', !!form?.customerRef);
    });
  }, [form]);

  const isDirty = React.useMemo(() => !isEqual(checkpoint.current, form), [checkpoint, form]);

  const isValid = React.useMemo(() => {
    return (
      !form?.hasHold &&
      form?.customerRef &&
      form?.projectRef &&
      form?.locationRef &&
      form?.supplierStatusRef &&
      form.startDateTime &&
      form?.paymentMethodRef &&
      (form?.taxRateRef !== 'address' ||
        (form.taxRateRef === 'address' &&
          form?.address?.address1 &&
          form?.address?.city &&
          form?.address?.countrySubDivision &&
          form?.address?.postalCode)) &&
      !(form?.taxRateRef === 'exempt' && !form?.taxExemptReasonRef && taxExemptReasonRequired) &&
      !(form?.taxRateRef === 'dispatchLocation' && !form?.dispatchLocationRef) &&
      linkedOrdersValid(form?.linkedOrders || []) &&
      isPOValid(form?.purchaseOrder) &&
      values(auditNotes || {}).every(v => !!v.message)
    );
  }, [auditNotes, form, isPOValid, taxExemptReasonRequired]);

  const commit = React.useCallback(() => {
    checkpoint.current = form;
  }, [form]);

  return {
    form,
    setForm,
    handleChange,
    resetForm,
    omitPaths,
    isValid,
    validatedFields,
    auditNotes,
    setAuditNotes,
    isDirty,
    checkpoint,
    commit,
    purchaseOrderRequired,
    setPurchaseOrderRequired,
  };
};
