import _ from 'lodash';
import uomCodes from "./uom-codes";

// fieldLimit adds a fallback to isoCode to conserve space
const getUomAbbreviationOrDescription = (isoCode, fieldLimit = false) => {
  if (!isoCode) {
    return '';
  }

  const uom = _.find(uomCodes, x => x.isoCode === isoCode);

  let retUom = uom ? uom.display || uom.description : 'Each';

  if(fieldLimit){
    retUom = retUom.length <= fieldLimit ? retUom : uom.isoCode;
  }

  return retUom;
}

export default getUomAbbreviationOrDescription;
