import { Translate } from '../../../../../order/components/localization/Translate';
import ColorPicker from '../../../components/color-picker';
import Button from '../../../../../../components/button/Button';
import { kebabCase } from 'lodash';

export const vehicleStatuses = {
  PRINTED: {
    label: 'Printed',
    defaultColor: '#0000FF',
  },
  LOADING_STARTED: {
    label: 'Loading',
    defaultColor: '#00FF00',
  },
  LOADING_COMPLETE: {
    label: 'Loaded',
    defaultColor: '#00FF00',
  },
  TO_JOB: {
    label: 'To Job',
    defaultColor: '#00FF00',
  },
  ARRIVE_JOB: {
    label: 'On Job',
    defaultColor: '#FF9900',
  },
  UNLOADING: {
    label: 'Unloading',
    defaultColor: '#FF9900',
  },
  END_UNLOAD: {
    label: 'Unloaded',
    defaultColor: '#FF9900',
  },
  WASHING: {
    label: 'Washing',
    defaultColor: '#FF0000',
  },
  LEAVE_JOB: {
    label: 'Leave Job',
    defaultColor: '#FF0000',
  },
};

const ColorSelectors = ({ saveTrackingConfiguration, trackingConfiguration }) => {
  const orderLineColor = trackingConfiguration?.colors?.orderLine || '#FFFF00';
  const locationLineColor = trackingConfiguration?.colors?.locationLine || '#32CD32';
  const vehicleLineColor = trackingConfiguration?.colors?.vehicleLine || '#40E0D0';

  return (
    <div style={{ paddingLeft: '20px' }}>
      <table>
        <tr>
          <td>
            <Translate stringId="orders" defaultMessage="Orders" />:
          </td>
          <td>
            <ColorPicker
              color={orderLineColor}
              onChange={color => saveTrackingConfiguration('colors.orderLine', color.hex)}
            />
          </td>
          <td>
            <Button
              onClick={() => saveTrackingConfiguration('colors.orderLine', null)}
              metricId="core-tracking-tracking-configuration-colors-orders-reset"
            >
              <Translate stringId="reset" defaultMessage="Reset" />
            </Button>
          </td>
        </tr>
        <tr>
          <td>
            <Translate stringId="locations" defaultMessage="Locations" />:
          </td>
          <td>
            <ColorPicker
              color={locationLineColor}
              onChange={color => saveTrackingConfiguration('colors.locationLine', color.hex)}
            />
          </td>
          <td>
            <Button
              onClick={() => saveTrackingConfiguration('colors.locationLine', null)}
              metricId="core-tracking-tracking-configuration-colors-locations-reset"
            >
              <Translate stringId="reset" defaultMessage="Reset" />
            </Button>
          </td>
        </tr>
        <tr>
          <td>
            <Translate stringId="vehicles" defaultMessage="Vehicles" />:
          </td>
          <td>
            <ColorPicker
              color={vehicleLineColor}
              onChange={color => saveTrackingConfiguration('colors.vehicleLine', color.hex)}
            />
          </td>
          <td>
            <Button
              onClick={() => saveTrackingConfiguration('colors.vehicleLine', null)}
              metricId="core-tracking-tracking-configuration-colors-vehicles-reset"
            >
              <Translate stringId="reset" defaultMessage="Reset" />
            </Button>
          </td>
        </tr>
      </table>
      <div style={{ margin: '20px 0' }}>
        <strong>
          <Translate stringId="mappedVehicleStatusColors" defaultMessage="Mapped Vehicle Status Colors" />
        </strong>
      </div>
      <table>
        {Object.keys(vehicleStatuses).map(statusCode => {
          const { label, defaultColor } = vehicleStatuses[statusCode];

          const color = trackingConfiguration?.colors?.statuses?.[statusCode] || defaultColor;

          return (
            <tr key={statusCode}>
              <td>
                <Translate stringId={statusCode} defaultMessage={label} />:
              </td>
              <td>
                <ColorPicker
                  color={color}
                  onChange={color => saveTrackingConfiguration(`colors.statuses.${statusCode}`, color.hex)}
                />
              </td>
              <td>
                <Button
                  onClick={() => saveTrackingConfiguration(`colors.statuses.${statusCode}`, null)}
                  metricId={`core-tracking-tracking-configuration-colors-${kebabCase(label)}-reset`}
                >
                  <Translate stringId="reset" defaultMessage="Reset" />
                </Button>
              </td>
            </tr>
          );
        })}
      </table>
    </div>
  );
};

export default ColorSelectors;
