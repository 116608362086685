import { DateTime } from 'luxon';
import React from 'react';
import { useShortDate } from '../../../order/components/localization/ShortDate';
import { useShortDateTimeString } from '../../../order/components/localization/ShortDateTime';

export const useColumns = () => {
  const { getShortDateTimeString } = useShortDateTimeString();

  return React.useMemo(() => {
    return [
      {
        key: 'exportedDate',
        name: 'Exported Date',
        width: 200,
        formatter: props => {
          try {
            return getShortDateTimeString({ date: props?.row?.exportedDateTime });
          } catch (e) {
            // ignore
          }
          return props?.row?.exportedDate || '';
        },
      },
      {
        key: 'user.name',
        name: 'User',
        width: 200,
      },
      {
        key: 'documentRef',
        name: 'Export File',
        filterable: false,
        width: 125,
        headerClassName: 'centered',
        formatter: props => (
          <div style={{ textAlign: 'center' }}>
            {props?.row?.documentRef && (
              <a target="_blank" href={props.row.documentRef}>
                <i className="fa fa-file-excel" />
              </a>
            )}
          </div>
        ),
      },
    ];
  }, []);
};
