import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import FlexColumn from '../../order/components/FlexColumn';
import CostBookSetupContextProvider from './costBookSetupContext';
import style from './style';
import SetupItemList from '../setup-item-list';
import { columns, prototype } from './setup';
import CostBookGeneral from './cost-book-general';
import CostBookPrices from './cost-book-prices';
import SetupItemEditor from '../setup-item-editor';
import { Drawer } from 'antd';
import Button from '../../../components/button/Button';

const Styled = styled(FlexColumn)`
  ${style}
`;

export const tabs = [
  {
    label: 'General',
    component: CostBookGeneral,
  },
  {
    label: 'Prices',
    component: CostBookPrices,
  },
];

export const SetupCostBooksEditor = ({ item, onSave, onCancel }) => {
  return (
    <CostBookSetupContextProvider>
      <SetupItemEditor
        item={item}
        onSave={onSave}
        onCancel={onCancel}
        params={{
          tabs,
          typeId: 'cost-book',
          editorType: 'tabs',
        }}
        columns={columns}
      />
    </CostBookSetupContextProvider>
  );
};

const SetupCostBooks = () => {
  const [drawerOpen, setDrawerOpen] = useState(true);

  return (
    <Styled className="setup-cost-books">
      <CostBookSetupContextProvider>
        <SetupItemList
          typeId="cost-book"
          type="Price Book"
          columns={columns}
          prototype={prototype}
          editorType="tabs"
          tabs={tabs}
        />
      </CostBookSetupContextProvider>
    </Styled>
  );
};

export default SetupCostBooks;
