import http from '../../../../http';
import listMixDesigns from './list-mix-designs';

const orders = '/connex/services/orders';

export const api = {
  getSetupItems: async (entityRef, typeId, params) => {
    if (typeId === 'mix') {
      //this is a special case because the mix/formula data-types need to be combined.
      return listMixDesigns(entityRef, params);
    }

    return http.get(`${orders}/${entityRef}/setup/${typeId}`, params);
  },
  getSetupItem: (entityRef, typeId, itemRef) => http.get(`${orders}/${entityRef}/setup/${typeId}/${itemRef}`),
  updateSetupItem: (entityRef, typeId, item) => http.patch(`${orders}/${entityRef}/setup/${typeId}/${item.crn}`, item),
  createSetupItem: (entityRef, typeId, item) => http.post(`${orders}/${entityRef}/setup/${typeId}`, item),
  getDownloadLink: (entityRef, typeId, itemRef) =>
    http.get(`${orders}/${entityRef}/setup/download/${typeId}/${itemRef}`),
  getUploadLink: (entityRef, typeId, itemRef) => http.get(`${orders}/${entityRef}/setup/upload/${typeId}/${itemRef}`),
  uploadFileDevelop: (entityRef, typeId, itemRef, file) =>
    http.post(`${orders}/${entityRef}/develop/setup/upload/${typeId}/${itemRef}`, file),
};
