import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useIntlContext } from '../../../../../i18n';
import InputDateRange from '../../../../setup/components/input-date-range';
import InputText from '../../../../setup/components/input-text';
import SelectSetupItem from '../../../../setup/components/select-setup-item';
import { useTranslateMessage } from '../../../components/localization/useTranslateMessage';
import { useOrderListContext } from '../../../components/OrderListContext';
import SearchButton from './search-button';
import style from './style';

const Styled = styled.div`
  ${style}
`;

const searchCache = {};

const putSearchCache = (entityRef, value) => {
  searchCache[entityRef] = value;
};

const getSearchCache = entityRef => {
  if (searchCache[entityRef]) {
    return searchCache[entityRef];
  }

  return {
    startDate: moment().startOf('day'),
    endDate: moment().endOf('day'),
  };
};

const AdvancedSearchBar = () => {
  const { entityRef } = useParams();
  const { showAdvancedSearch } = useOrderListContext();
  const [formValue, setFormValue] = useState(getSearchCache(entityRef));

  const translateMessage = useTranslateMessage();

  useEffect(() => {
    putSearchCache(entityRef, formValue);
  }, [formValue, entityRef]);

  const changeValue = useCallback((id, value) => {
    setFormValue(existingFormValue => ({
      ...existingFormValue,
      [id]: value,
    }));
  }, []);

  const labels = React.useMemo(() => {
    return {
      dateRange: translateMessage({ stringId: 'dateRange', defaultMessage: 'Date Range' }),
      orderIds: translateMessage({ stringId: 'orderIds', defaultMessage: 'Order IDs' }),
      customer: translateMessage({ stringId: 'customer', defaultMessage: 'Customer' }),
      project: translateMessage({ stringId: 'project', defaultMessage: 'Project' }),
      product: translateMessage({ stringId: 'product', defaultMessage: 'Product' }),
      orderStatus: translateMessage({ stringId: 'orderStatus', defaultMessage: 'Order Status' }),
      purchaseOrder: translateMessage({ stringId: 'purchaseOrder', defaultMessage: 'Purchase Order' }),
    };
  }, [translateMessage]);

  if (!showAdvancedSearch) {
    return null;
  }

  return (
    <Styled>
      <InputDateRange
        onChange={changeValue}
        startId={'startDate'}
        endId={'endDate'}
        startValue={formValue.startDate}
        endValue={formValue.endDate}
        label={labels.dateRange}
      />
      <InputText id={'orderId'} onChange={changeValue} label={labels.orderIds} value={formValue.orderId} />
      <SelectSetupItem
        id="customerRef"
        label={labels.customer}
        typeId="customer"
        item={formValue}
        setupItemRef={formValue.customerRef}
        onChange={changeValue}
        useActualTypeName={true}
        onlyReturnRef={true}
        showIdWithLabel={true}
      />
      <SelectSetupItem
        id="projectRef"
        label={labels.project}
        typeId="project"
        item={formValue}
        setupItemRef={formValue.projectRef}
        onChange={changeValue}
        onlyReturnRef={true}
        showIdWithLabel={true}
      />
      <SelectSetupItem
        id="productRef"
        label={labels.product}
        typeId="product"
        item={formValue}
        setupItemRef={formValue.productRef}
        onChange={changeValue}
        onlyReturnRef={true}
        showIdWithLabel={true}
      />
      <SelectSetupItem
        id="supplierStatusRef"
        label={labels.orderStatus}
        typeId="status-code"
        item={formValue}
        setupItemRef={formValue.supplierStatusRef}
        onChange={changeValue}
        onlyReturnRef={true}
      />
      <InputText
        id={'purchaseOrder'}
        onChange={changeValue}
        label={labels.purchaseOrder}
        value={formValue.purchaseOrder}
      />
      <SearchButton formValue={formValue} />
    </Styled>
  );
};

export default AdvancedSearchBar;
