import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import API from '../../api';
import { api } from '../../../../api';
import { useOrderContext } from '../../../useOrderContext';

export const useRTMap = () => {
  const { entityRef, orderId: orderRef } = useParams();

  const { order } = useOrderContext();

  const getConfig = useCallback(configRef => API.getConfig({ entityRef, configRef }), [entityRef]);

  // The geo endpoints do not support order group crns
  // So for multiple primary product orders we are going to pass the crn of
  // one of the sub orders, this is completely fine since those sub orders
  // should have the same address data of the order group
  const parsedOrderRef = useMemo(() => {
    if (order?.order?.linkedOrders?.length > 1) {
      return order?.order?.linkedOrders?.[0]?.orderRef || orderRef;
    }
    return order?.order?.crn || orderRef;
  }, [order?.order, orderRef]);

  const initializeEntryMap = useCallback(async () => {
    const body = {
      getMarkers: [
        {
          orderRef: parsedOrderRef,
          query: 'orderEntry',
        },
      ],
    };

    const init = await api.getGeoMap(entityRef, body);
    return await getConfig(init.crn);
  }, [entityRef, getConfig, parsedOrderRef]);

  const initializeTrackingMap = useCallback(async () => {
    const body = {
      polling: true,
      pollingTime: 20000,
      getMarkers: [
        {
          orderRef: parsedOrderRef,
          query: 'trucksByOrderRef',
        },
      ],
    };

    const init = await api.getGeoMap(entityRef, body);
    const config = await getConfig(init.crn);
    return {
      ...config,
      polling: true,
      pollingTime: 20000,
    };
  }, [entityRef, getConfig, parsedOrderRef]);

  return {
    getConfig,
    initializeEntryMap,
    initializeTrackingMap,
  };
};

export default useRTMap;
