import React from 'react';
import cx from 'classnames';
import styled from 'styled-components';
import { Checkbox as AntCheckbox, Tooltip } from 'antd';
import style from './style';
import propTypes from 'prop-types';
import { callWithMetrics } from '../../util/analytics';
import { useParams } from 'react-router-dom';
import { kebabCase } from 'lodash';

//TODO Checkbox.Group

export const Checkbox = ({
  className,
  onChange,
  disabled,
  checked,
  label,
  metricId,
  id,
  name,
  dataTestId,
  tooltip,
}) => {
  let clickHandler = onChange;

  const { entityRef } = useParams();

  if (metricId) {
    const metric = { type: 'user-interaction', data: { type: 'checkbox-clicked', id: metricId, checked: checked } };
    clickHandler = callWithMetrics(onChange, metric, entityRef);
  }

  return (
    <Tooltip title={tooltip} placement="top">
      <AntCheckbox
        checked={checked}
        className={cx(className, 'cx-checkbox', { 'cx-checkbox-disabled': disabled })}
        onChange={clickHandler}
        disabled={disabled}
        id={id}
        name={name}
        data-testid={dataTestId || `check-${kebabCase(label?.toLowerCase?.())}`}
      >
        {label}
      </AntCheckbox>
    </Tooltip>
  );
};

Checkbox.propTypes = {
  className: propTypes.string,
  onChange: propTypes.func,
  disabled: propTypes.bool,
  checked: propTypes.bool,
  label: propTypes.oneOfType([propTypes.string, propTypes.object]),
  metricId: propTypes.string,
  tooltip: propTypes.string,
};

export default styled(Checkbox)`
  ${style}
`;
