export const INTERFACE_METHODS = [
  {
    label: 'Ethernet',
    value: 'ETHERNET',
  },
  {
    label: 'Serial',
    value: 'SERIAL',
  }
];

export const INTERFACE_READ_TYPE = [{
  label: 'Demand',
  value: 'DEMAND',
},{
  label: 'Continuous',
  value: 'CONTINUOUS',
}];

export default {
  INTERFACE_METHODS,
  INTERFACE_READ_TYPE,
}
