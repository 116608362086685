import { Button } from 'antd';
import { Translate } from '../../views/order/components/localization/Translate';
import Select from '../form/Select';
import React, { useCallback, useRef } from 'react';

const { Option } = Select;

const SelectPlant = ({ selectedLocations, locationList, onChange, storeSelectedLocations }) => {
  const buttonRef = useRef(null);

  const options = React.useMemo(
    () =>
      locationList?.map?.(l => ({
        value: l.crn,
        label: `${l.id} / ${l.name}`,
      })),
    [locationList]
  );

  const onSelect = React.useCallback(
    val => {
      if (val === 'ALL') {
        onChange(['ALL']);
      } else {
        const newState = [...selectedLocations.filter(location => location !== 'ALL'), val];
        onChange(newState);
      }
    },
    [onChange, selectedLocations]
  );

  const onDeselect = React.useCallback(
    val => {
      if (val === 'ALL') return;
      let filtered = selectedLocations.filter(location => location !== val);
      if (filtered.length === 0) {
        filtered = ['ALL'];
      }
      onChange(filtered);
    },
    [onChange, selectedLocations]
  );

  const handleSearch = useCallback((input, option) => {
    const optionLabel = option.props.children;
    return optionLabel.toLowerCase().includes(input.toLowerCase());
  }, []);

  const handleSaveLocations = useCallback(() => {
    storeSelectedLocations && storeSelectedLocations();
    buttonRef.current.blur();
  }, [storeSelectedLocations]);

  return (
    <div>
      <div>Select Location</div>
      <div style={{ display: 'flex', width: '100%', gap: '5px' }}>
        <Select
          value={selectedLocations}
          mode="multiple"
          onSelect={onSelect}
          onDeselect={onDeselect}
          filterOption={handleSearch}
        >
          <Option value="ALL">
            <span style={{ textTransform: 'uppercase' }}>
              <Translate stringId="all" defaultMessage="All" />
            </span>
          </Option>
          {options?.map(option => (
            <Option key={option.value} value={option.value}>
              {option.label}
            </Option>
          ))}
        </Select>
        <Button ref={buttonRef} onClick={handleSaveLocations}>
          <Translate stringId="save" defaultMessage="Save" />
        </Button>
      </div>
    </div>
  );
};

export default SelectPlant;
