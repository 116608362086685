import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';

const Styled = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 20001;
  text-align: center;
  .fa-spin {
    margin-top: 20%;
    font-size: 3rem;
    opacity: 0.3;
  }
`;
const LoadingIndicator = props => {
  return (
    <Styled className={cn('loading-indicator')} {...props}>
      <i className="fas fa-spin fa-circle-notch " />
    </Styled>
  );
};

export default LoadingIndicator;
