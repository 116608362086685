import { find } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import FieldContainer from '../../field-container/FieldContainer';
import Select from '../../form/Select';
import style from './style';

const disciplineSearchFilter = (input, option) => {
  const searchText = input.toLowerCase?.();
  return Array.isArray(option?.children) ? option.children.join().toLowerCase().indexOf(searchText) >= 0 : false;
};

const Discipline = ({ className, disciplines, onChange, value, disabled, required }) => {
  const handleChange = val => {
    const targetDiscipline = find(disciplines, s => s.crn === val);

    const { crn, ...crnRemoved } = targetDiscipline ?? {};
    onChange('discipline', { ...crnRemoved, disciplineRef: crn });
  };

  const { Option } = Select;

  return (
    <FieldContainer className={cn('discipline', className)}>
      <div className="label-spinner">
        <div className="label">{`Discipline ${required ? '*' : ''}`}</div>
        <div className={cn('spinner', { spinning: false })}>
          <span className="fas fa-sync fa-spin" />
        </div>
      </div>

      <Select
        value={value?.disciplineRef || ''}
        onChange={handleChange}
        disabled={disabled}
        showSearch
        filterOption={disciplineSearchFilter}
      >
        <option value="">Select a discipline...</option>
        {disciplines?.map(c => (
          <Option value={c.crn} key={c.crn}>
            {c.id} / {c.description}
          </Option>
        ))}
      </Select>
    </FieldContainer>
  );
};

export default styled(Discipline)`
  ${style}
`;
