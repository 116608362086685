import { useMemo } from 'react';
import esLocale from 'antd/es/date-picker/locale/es_ES';

export const useDateFormat = locale => {
  const antLocales = {
    es: esLocale,
  };

  const getDateFormat = locale => {
    const date = new Date();

    const dateFormat = Intl.DateTimeFormat(locale, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }).formatToParts(date);

    let formattedDate = '';
    for (const part of dateFormat) {
      switch (part.type) {
        case 'day':
          formattedDate += 'DD';
          break;
        case 'month':
          formattedDate += 'MM';
          break;
        case 'year':
          formattedDate += 'YYYY';
          break;
        default:
          formattedDate += part.value;
      }
    }

    return formattedDate;
  };

  const dateFormat = useMemo(() => {
    return getDateFormat(locale);
  }, [locale]);

  return {
    antLocales,
    dateFormat,
  };
};
