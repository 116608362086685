import React from 'react';
import http from '../../../http';
import getSvgMarker from '../get-svg-marker';
import BaseMarker from '../markers/BaseMarker';

const Truck = props => {
  const { vehicleID, ticket, lat, lng } = props;
  const [dataUrl, setDataUrl] = React.useState();

  const fetchIcon = React.useCallback(async () => {
    const result = await http.getIcon(`/assets/map-icons/rear-white.png`);

    const reader = new FileReader();
    reader.onload = function () {
      setDataUrl(this.result); // <--- `this.result` contains a base64 data URI
    };
    reader.readAsDataURL(result);
  }, []);

  React.useEffect(() => {
    fetchIcon();
  }, [fetchIcon]);

  const url = React.useMemo(() => {
    if (dataUrl) {
      return getSvgMarker(dataUrl, '#0000FF');
    }
  }, [dataUrl]);

  const truckProps = {
    key: `${vehicleID}::${ticket}`,
    innerKey: `${vehicleID}::${ticket}`,
    draggable: false,
    position: { lat: lat, lng: lng },
    icon: { url, scaledSize: { width: 50, height: 69 } },
    type: 'truck',
  };

  if (!url) return null;

  return (
    <BaseMarker {...truckProps}>
      <>
        <div>
          <b>Vehicle:</b> {props.vehicleID}
        </div>
        {!props.ticketCrn && (
          <div>
            <b>Ticket:</b> {props.ticket}
          </div>
        )}
        {props.ticketCrn && (
          <div>
            <b>Ticket:</b>{' '}
            <a href={props.ticketUrl} target="_blank">
              {props.ticket}
            </a>
          </div>
        )}
      </>
    </BaseMarker>
  );
};

export default Truck;
