import React from 'react';
import { FILTER_STRATEGY } from '../../../components/connex-data-grid/util';
import moment from 'moment';

export const prototype = {
  id: '',
  adjustType: 'PERCENT',
  status: 'ACTIVE',
  allPriceBooks: true,
  allProductTypes: true,
  effectiveDate: moment().startOf('day').toISOString(),
};

export const columns = [
  {
    key: 'id',
    name: 'ID',
    required: true,
  },
  {
    key: 'adjustValue',
    name: 'Adjust Value',
    required: true,
    value: item => <span>{item.adjustValue}</span>,
  },
  {
    key: 'adjustType',
    name: 'Adjust Type',
    required: false,
    filterStrategy: FILTER_STRATEGY.EQUALS,
    filterComponent: ({ innerRef, ...props }) => {
      return (
        <select ref={innerRef} {...props}>
          <option value="" />
          <option value="FLAT">Flat</option>
          <option value="PERCENT">Percent</option>
        </select>
      );
    },
  },
  {
    key: 'customerParty.name',
    name: 'Customer',
  },
  {
    key: 'project.name',
    name: 'Project',
  },
  {
    key: 'effectiveDate',
    name: 'Effective Date',
    getValue: item => moment(item?.effectiveDate).format('ll'),
  },
  {
    key: 'status',
    name: 'Status',
    filterStrategy: FILTER_STRATEGY.EQUALS,
    filterComponent: ({ innerRef, ...props }) => {
      return (
        <select ref={innerRef} {...props}>
          <option value="" />
          <option value="ACTIVE">Active</option>
          <option value="INACTIVE">Inactive</option>
        </select>
      );
    },
  },
];
