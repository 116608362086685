import style from '../style';
import React from 'react';
import styled from 'styled-components';
import InputText from '../../components/input-text';
import InputSelect from '../../components/input-select';
import { status } from './options';
import MaxBatchSize from '../../components/max-batch-size';
import SyncStatusBadge from "../SyncStatusBadge";

const Styled = styled.div`
  ${style}
`;


const MixGeneral = ({ item, onChange }) => {
  return (
    <Styled>
      <InputText id="id" label="ID" value={item?.id} onChange={onChange} />
      <InputText id="name" label="Name" value={item?.name} onChange={onChange} />
      <InputSelect id="status" label="Status" value={item?.status} onChange={onChange} options={status} required />
      <MaxBatchSize item={item} onChange={onChange}/>
      <InputText id="jobMixFormulaId" label="Job Mix Formula Id" value={item?.jobMixFormulaId} onChange={onChange} />
      < SyncStatusBadge ticket={item}></SyncStatusBadge>
    </Styled>
  );
};

export default MixGeneral;
