import uomCodes from "../../../../util/uom/uom-codes";

export const status = [
  { value: 'ACTIVE' },
  { value: 'INACTIVE' },
];

export const taxableStatus = [
  { value: 'YES' },
  { value: 'NO' },
];

export const uom = uomCodes
  .map(uom => {
    return {
      value: uom.isoCode,
      label: `${uom.description} (${uom.display || uom.isoCode})`,
    }
  })
  .sort((a, b) => a.label < b.label ? -1 : 1);
