import cn from 'classnames';
import { motion } from 'framer-motion';
import { orderBy } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import Button from '../../../../../../components/button/Button';
import IfSeller from '../../../../components/IfSeller';
import { Number } from '../../../../components/localization/Number';
import { Translate } from '../../../../components/localization/Translate';
import { Uom } from '../../../../components/localization/Uom';
import MinSize from '../../../../components/MinSize';
import { useOrderListContext } from '../../../../components/OrderListContext';
import Tabs from '../../../../../../components/tabs';
import Loads from '../loads/Loads';
import OrderSummary from '../order-summary';
import OrderTracker from '../order-tracker';
import CopyOrderButton from '../../../../components/CopyOrderButton';
import style from './style';
import OrderProgressIcon from '../order-progress-icon';
import { useOrder } from '../../../../view-or-edit-order/useOrder';

const ExpandedOrderCard = ({ className, order: incomingOrder, onClick, onClose, timeZone = 'America/Chicago' }) => {
  const { persona } = useOrderListContext();
  const { tab } = useState('loads');

  const { rawOrder, getOrder } = useOrder();

  useEffect(() => {
    if (incomingOrder?.isPartial === true) {
      if (rawOrder?.crn !== incomingOrder?.crn) {
        getOrder(incomingOrder?.crn);
      }
    }
  }, [incomingOrder, rawOrder, getOrder]);

  const order = useMemo(() => {
    if (incomingOrder?.isPartial === true && rawOrder) {
      return rawOrder;
    }

    return incomingOrder;
  }, [incomingOrder, rawOrder]);

  const lineWidth = 50;

  const product = {
    ...order?.lineItems[0]?.item,
    ...order?.lineItems[0]?.orderedQuantity,
    deliveredQuantity: order?.deliverySchedule?.deliveryProgress?.deliveredQuantity,
  };

  const a = order?.destination?.address;
  const destination = `${a?.name ? `${a.name} / ` : ''}${a?.address1 || `(none)`}${a?.city ? ' ' : ''}${a?.city || ``}${a?.countrySubDivision || a?.postalCode ? ', ' : ``}${a?.countrySubDivision || ``} ${a?.postalCode || ``}`;
  const tickets = React.useMemo(
    () => orderBy(order?.deliverySchedule?.tickets, ['loadNumber'], ['asc']),
    [order?.deliverySchedule]
  );

  const toggle = React.useCallback(
    e => {
      if (e?.ctrlKey || e?.metaKey) {
        persona === 'seller' && onClick(order?.crn);
      } else {
        onClose();
      }
    },
    [onClick, onClose, order?.crn, persona]
  );

  const handleEsc = React.useCallback(
    e => {
      if (e.key === 'Escape') onClose();
    },
    [onClose]
  );

  const handleClick = React.useCallback(
    (...args) => {
      onClick(...args);
    },
    [onClick]
  );

  const cardRef = React.useRef();

  React.useEffect(() => {
    cardRef.current?.focus?.();
  }, []);

  const formattedCustomerParty = useMemo(() => {
    if (!order?.customerParty) return '(none)';
    if (order?.customerParty?.name) {
      return order?.customerParty?.name;
    }
    return order?.customerParty?.id;
  }, [order]);

  const formattedProject = useMemo(() => {
    if (order?.project?.name) {
      return order?.project?.name;
    }
    return order?.project?.id;
  }, [order]);

  const contactName = useMemo(() => {
    const orderContact = order?.contacts?.[1];

    if (!orderContact?.firstName && !orderContact?.lastName) return '';

    return `${orderContact?.firstName || ''}${orderContact?.firstName && orderContact?.lastName ? ' ' : ''}${
      orderContact?.lastName || ''
    } `;
  }, [order?.contacts]);

  return (
    <motion.div
      id={`order-card-${order.crn}`}
      layoutId={`order-card-${order.crn}`}
      className={cn('order-card-detail', className)}
      onKeyDownCapture={handleEsc}
      tabIndex={1}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, transition: { duration: 0.2 } }}
      transition={{ duration: 0.2, delay: 0.05 }}
      ref={cardRef}
    >
      <motion.div className={cn('wrapper flex-column')} layoutId={`card-wrapper-${order.crn}`}>
        <motion.div className="left back-action">
          <Button
            type="primary"
            size="small"
            onClick={toggle}
            metricId="core-order-preview-back-to-orders"
            icon={<i className="icon fa fa-chevron-left" />}
            data-testid="button-back-to-orders"
          >
            <Translate stringId="backToOrders" defaultMessage="Back to Orders" />
          </Button>
        </motion.div>
        <motion.div className={cn('layout')} layoutId={`card-layout-${order.crn}`}>
          <motion.div
            className="left"
            onClick={e => toggle(e, order?.crn)}
            style={{ cursor: 'pointer' }}
            layoutId={`card-layout-left-${order.crn}`}
          >
            <OrderProgressIcon order={order} />
          </motion.div>
          <motion.div className="right" layoutId={`card-layout-right-${order.crn}`}>
            <div className="summary-info" onClick={toggle}>
              <div>{`${formattedCustomerParty}`.substring(0, lineWidth)}</div>
              <div>{`${formattedProject}`.substring(0, lineWidth)}</div>
              <div>{`${destination}`.substring(0, lineWidth)}</div>
              <div>
                <Number value={product.deliveredQuantity} decimalPlaces={2} /> /{' '}
                <Uom uom={{ value: product.value, uomCode: product.uomCode }} />{' '}
                {`${product.id} / ${product.name}`.substring(0, lineWidth)}
              </div>
              <div>{`${contactName}${order?.contacts?.[0]?.phoneNumber || ''}`.substring(0, 30)}</div>
            </div>
            <div className="buttons">
              <IfSeller>
                <CopyOrderButton order={order} metricId="core-order-card-copy-order" data-testid="button-copy-order" />
                <Button
                  onClick={() => handleClick(order?.crn)}
                  size="small"
                  type="primary"
                  metricId="core-order-card-details"
                  data-testid="button-details"
                >
                  <i className="fal fa-edit" />
                  <MinSize breakpoint={490}>
                    <span className="btn-label">
                      {order?.typeId === 'order-request-meta-data' ? (
                        <Translate stringId="reviewOrderRequest" defaultMessage="Review Order Request" />
                      ) : (
                        <Translate stringId="details" defaultMessage="Details" />
                      )}
                    </span>
                  </MinSize>
                </Button>
              </IfSeller>
            </div>
          </motion.div>
        </motion.div>
        <motion.div className="detail flex-column" layoutId={`card-detail-${order.crn}`}>
          <OrderSummary order={order} />
          <Tabs type="card" activeKey={tab}>
            <Tabs.TabPane tab={<Translate stringId="loads" defaultMessage="Loads" />} key="loads" disabled={false}>
              <div className="loads">
                {(!!order?.deliverySchedule?.schedule?.length || !!tickets?.length) && (
                  <Loads
                    orderId={order?.id}
                    scheduled={order?.deliverySchedule?.schedule}
                    ticketed={tickets}
                    plant={order.origin}
                    timeZone={timeZone}
                    ticketEvents={order?.deliverySchedule?.schedule?.[0]?.ticketEvents}
                    orderRef={order.crn}
                    supplierRef={order?.supplierParty?.entityRef}
                  />
                )}
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane tab={<Translate stringId="map" defaultMessage="Map" />} key="map" disabled={false}>
              <OrderTracker order={order} tickets={tickets} />
            </Tabs.TabPane>
          </Tabs>
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

export default styled(ExpandedOrderCard)`
  ${style}
`;
