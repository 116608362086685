import style from '../style';
import React from 'react';
import styled from 'styled-components';
import InputNumber from '../../../components/input-number';

const View = styled.div`
  ${style}
`;

const LocationSchedule = ({ location, onChange }) => {
  const onChangeTime = (id, value) => {
    const scheduleTimes = {
      ...location?.scheduleTimes,
      [id]: parseInt(value),
    };
    onChange('scheduleTimes', scheduleTimes);
  };

  const onChangeShift = (id, value) => {
    const scheduleTimes = {
      ...location?.scheduleTimes,
      [id]: parseFloat(value),
    };
    onChange('scheduleTimes', scheduleTimes);
  };

  return (
    <View>
      <h3>Order Entry Scheduling Parameters</h3>
      <InputNumber
        id="waitToLoad"
        label="Wait To Load"
        value={location?.scheduleTimes?.waitToLoad ?? 10}
        onChange={onChangeTime}
      />
      <InputNumber id="load" label="Load" value={location?.scheduleTimes?.load ?? 10} onChange={onChangeTime} />
      <InputNumber
        id="waitToLeave"
        label="WaitToLeave"
        value={location?.scheduleTimes?.waitToLeave ?? 10}
        onChange={onChangeTime}
      />
      <h3 style={{ marginTop: '5px' }}>Driver Call-in Parameters</h3>
      <InputNumber
        id="shiftLength"
        label="Shift length (hours)"
        value={location?.scheduleTimes?.shiftLength ?? 8}
        onChange={onChangeShift}
      />
      <InputNumber
        id="leadTimeBeforeFirstLoad"
        label="Lead time before first load (minutes)"
        value={location?.scheduleTimes?.leadTimeBeforeFirstLoad ?? 20}
        onChange={onChangeTime}
      />
    </View>
  );
};

export default LocationSchedule;
