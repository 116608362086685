import React, { useEffect } from 'react';
import styled from 'styled-components';
import FlexColumn from '../../order/components/FlexColumn';
import useSetup from '../useSetup';
import { useParams } from 'react-router';
import style from './style';
import SetupTable from '../components/setup-table';
import LocationEditor from './location-editor';
import { columns, prototype } from './setup';

const Styled = styled(FlexColumn)`
  ${style}
`;

const params = { activeOnly: false };

const SetupLocations = () => {
  const { entityRef } = useParams();
  const { getLocations, locations, busy } = useSetup();

  useEffect(() => {
    entityRef && getLocations(entityRef, params);
  }, [entityRef, getLocations]);

  return (
    <Styled>
      <SetupTable
        busy={busy}
        type="Location"
        columns={columns}
        items={locations}
        Editor={LocationEditor}
        prototype={prototype}
        onRefresh={() => getLocations(entityRef, params)}
      />
    </Styled>
  );
};

export default SetupLocations;
