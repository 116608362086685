import React, { useContext } from 'react';
import { useScaleTicketing } from './useScaleTicketing';

const ScaleTicketingContext = React.createContext();

export const useScaleTicketingContext = () => {
  const context = useContext(ScaleTicketingContext);
  if (!context) {
    throw new Error(
      `useScaleTicketingContext cannot be rendered outside of the ScaleTicketingContext context provider`
    );
  }
  return context;
};

const ScaleTicketingContextProvider = ({ children }) => {
  const value = useScaleTicketing();

  return <ScaleTicketingContext.Provider value={value}>{children}</ScaleTicketingContext.Provider>;
};

export default ScaleTicketingContextProvider;
