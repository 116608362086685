import React, { useCallback, useEffect, useState } from 'react';
import Select from '../../../../components/form/Select';
import styled from 'styled-components';
import useSetup from '../../useSetup';
import PriceBookProducts from './price-book-products';
import SelectField from '../../../order/components/SelectField';
import AddItem from '../../../order/components/order-form/general/AddItem';
import { SetupCostBooksEditor } from '../../setup-cost-books';

const { Option } = Select;

const Title = styled.div`
  margin-top: 13px;
  margin-bottom: 3px;
`;

const Styled = styled.div`
  .restrict {
    padding-top: 10px;
    padding-bottom: 20px;
  }
`;

const SelectPriceBook = ({ label = 'Assigned Price Book', item, onChange, allowEmpty = false }) => {
  const { getSetupItems, setupItems } = useSetup('cost-book');
  const [loading, setLoading] = useState(false);

  const value = item?.costBookRef || '';

  const getPriceBook = useCallback(async () => {
    try {
      setLoading(true);
      await getSetupItems();
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, [getSetupItems]);

  useEffect(() => {
    getPriceBook();
  }, [getPriceBook]);

  useEffect(() => {
    if (item?.costBookRef && item?.costBookRef !== '') {
      console.log(item.costBookRef);
    }
  }, [item?.costBookRef]);

  const priceBookChanged = (_field, value) => {
    if (!value && allowEmpty && onChange) {
      onChange('costBookRef', null);
      return;
    }

    const item = setupItems.find(s => s.crn === value);
    onChange && item && onChange('costBookRef', item.crn);
  };

  const filteredSetupItems = React.useMemo(() => {
    const isNew = !item?.crn;
    if (isNew) {
      return setupItems.filter(item => item.status === 'ACTIVE');
    }
    return setupItems;
  }, [item?.crn, setupItems]);

  const [editorIsOpen, setEditorIsOpen] = useState(false);

  const toggleEditor = useCallback(() => {
    setEditorIsOpen(s => !s);
  }, []);

  const onSave = useCallback(
    result => {
      onChange?.('costBookRef', result.crn);
      getSetupItems().then();
    },
    [onChange, getSetupItems]
  );

  const onRestrictionChange = value => {
    onChange('restrictToPriceBook', value !== 'off' ? value : false);
  };

  return (
    <Styled>
      {label && <Title>{label}</Title>}
      <SelectField
        fieldName="costBookRef"
        datatestid="select-price-book"
        displayPath={['name']}
        value={loading ? 'Loading...' : value}
        valuePath={'crn'}
        onChange={priceBookChanged}
        interimValue={value}
        list={filteredSetupItems}
        toggleEditor={toggleEditor}
        disabled={loading}
        addItem={
          <AddItem
            type="Price Book"
            Editor={SetupCostBooksEditor}
            onSave={onSave}
            editorIsOpen={editorIsOpen}
            toggleEditor={toggleEditor}
            disabled={loading}
          />
        }
      />
      {value !== '' && (
        <div className="restrict">
          <div>Restrict Orders To Products In Price Book</div>
          <Select
            id="restrictionValue"
            value={
              item?.restrictToPriceBook ? (item.restrictToPriceBook === true ? 'all' : item.restrictToPriceBook) : 'off'
            }
            onChange={onRestrictionChange}
          >
            <Option value="off">Off</Option>
            <Option value="all">On for all products</Option>
            <Option value="primary">On for just primary products</Option>
          </Select>
        </div>
      )}
      {/*typeId and itemRef props are optional. When used the price adjustments will be applyed to the Price Book*/}
      {value !== '' && <PriceBookProducts priceBookRef={value} typeId={item?.typeId} itemRef={item?.crn} />}
    </Styled>
  );
};

export default SelectPriceBook;
