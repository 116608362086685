import React from 'react';
import { useOrderContext } from '../../useOrderContext';
import MapBehaviorContextProvider from '../context/MapBehaviorContext';
import { useNewOrderBehavior } from './useNewOrderBehavior';

const NewOrderBehaviorContextProvider = ({ children }) => {
  const {
    form: { form },
  } = useOrderContext();

  const value = useNewOrderBehavior(form);

  return <MapBehaviorContextProvider value={value}>{children}</MapBehaviorContextProvider>;
};

export default NewOrderBehaviorContextProvider;
