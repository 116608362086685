import React, {useCallback, useMemo, useState} from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import style from './style';
import VehicleToken from '../vehicle-token';
import VehicleDragToken from '../vehicle-drag-token';
import {useDashboardContext} from "../../context/useDashboardContext";
import {get} from "lodash";

const toPlantStatuses = [
  'ARRIVE_PLANT',
  'WASHING',
  'LEAVE_JOB',
  'IN_YARD',
  'DEADHEAD',
]

const VehicleList = ({className, drop=null, draggable = true, sortById=true, sortByTime = false, sortByLocation = false, showTimer = true, vehicles = []}) => {
  const {licenses, locations} = useDashboardContext();

  const vehicleList = useMemo(() => {
    const result = vehicles.slice();

    for(const vehicle of result) {
      let locationId = get(vehicle, 'fromLocation.id', get(vehicle, 'homeLocation.id'));
      if(licenses.orders) {
        if(toPlantStatuses.indexOf(vehicle?.vehicleStatus?.statusCode) >= 0) {
          locationId = vehicle?.toLocation?.id;
        } else {
          locationId = vehicle?.fromLocation?.id;
        }
        if(!locationId) {
          locationId = vehicle?.homeLocation?.id;
        }
      }
      vehicle.locationId = locationId;
      vehicle.locationColor = locations?.find(l => l.id === locationId)?.defaultTruckColor;
    }

    const keys = {};
    if(sortByLocation) {
      keys.locationId = 'asc';
    }

    if(sortByTime) {
      keys['latestEvent.eventDateTime']= 'asc';
    }

    if(sortById) {
      keys['id']= 'asc';
    }

    result.keySort(keys)
    return result;
  }, [sortByLocation, sortByTime, vehicles, licenses, locations])

  return <div ref={drop} className={cn(className, 'vehicle-list')}>
    {vehicleList.map((vehicle, key) => {
      if(draggable && licenses?.orders) {
        return <VehicleDragToken showTimer={showTimer} vehicle={vehicle} key={vehicle.crn} />
      }

      return <VehicleToken showTimer={showTimer} vehicle={vehicle} key={vehicle.crn} />
    })}
  </div>
}

export default styled(VehicleList)`
  ${style}
`;