import React from 'react';
import Dashboard from './dashboard';
import cn from 'classnames';
import styled from 'styled-components';
import './dashboard/utils/key-sort';

const Tracking = ({ className }) => {
  return (
    <div className={cn(className)}>
      <Dashboard />
    </div>
  );
};

export default styled(Tracking)`
  overflow: hidden;
`;
