import React from 'react';
import { FILTER_STRATEGY } from "../../../components/connex-data-grid/util";
import { integratedSystem } from './location-editor/options';
import { find } from 'lodash';

export const prototype = {
  id: '',
  name: '',
  status: 'ACTIVE',
};

export const columns = [
  {
    key: 'id',
    name: 'ID',
  },
  {
    key: 'name',
    name: 'Name',
  },
  {
    key: 'address',
    name: 'Address',
  },
  {
    key: 'availableVehicles',
    name: 'Vehicles',
  },
  {
    key: 'mapped',
    name: 'Mapped',
    getValue: row => (row.hasCoordinates ? 'Yes' : 'No'),
    filterComponent: ({ innerRef, ...props }) => {
      return (
        <select ref={innerRef} {...props}>
          <option value="" />
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </select>
      );
    },
  },
  {
    key: 'pricing',
    name: 'Pricing',
  },
  {
    key: 'status',
    name: 'Status',
    filterStrategy: FILTER_STRATEGY.EQUALS,
    filterComponent: ({ innerRef, ...props }) => {
      return (
        <select ref={innerRef} {...props}>
          <option value="" />
          <option value="ACTIVE">Active</option>
          <option value="INACTIVE">Inactive</option>
        </select>
      );
    },
  },
  {
    key: 'integratedSystem',
    name: 'Integrated System',
    formatter: props =>
      find(integratedSystem, s => s.value === props.row.integratedSystem)?.label || props.row.integratedSystem || '',
  },
  {
    key: 'defaultValue',
    name: 'Default',
    getValue: item => (item?.defaultValue === true ? 'true' : ''),
  },
];
