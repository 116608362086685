import React, { useContext } from 'react';
import { useOrderListState } from '../order-list/useOrderListState';

const OrderListContext = React.createContext();

export const useOrderListContext = () => {
  const context = useContext(OrderListContext);
  if (!context) {
    throw new Error(`useOrderListContext cannot be rendered outside of the OrderListContext context provider`);
  }
  return context;
};

const OrderListContextProvider = ({ children }) => {
  const orderListState = useOrderListState();

  return <OrderListContext.Provider value={orderListState}>{children}</OrderListContext.Provider>;
};

export default OrderListContextProvider;
