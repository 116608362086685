import {css} from 'styled-components';

export default css`
  display: flex;
  height: calc(100% - 50px);
  margin-bottom: 50px;
  flex-direction: column;
  width: 100%;
  overflow: auto;
  color: white;
  .ant-checkbox-wrapper {
    color: white;
  }
`
