import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { ShortDate } from '../../../../order/components/localization/ShortDate';
import { ShortDateTime } from '../../../../order/components/localization/ShortDateTime';
import { useTranslateMessage } from '../../../../order/components/localization/useTranslateMessage';
import style from './style';
import cn from 'classnames';
import VehicleList from '../../components/vehicle-list';
import { Table } from 'antd';
import { get } from 'lodash';
import { useContextMenuContext } from '../../dashboard-menu/context-menu/useContextMenuContext';
import { useDashboardContext } from '../../context/useDashboardContext';

const VehiclesInYard = ({ className, setTitle }) => {
  const { showMenu } = useContextMenuContext();
  const { alerts = [] } = useDashboardContext();
  const translateMessage = useTranslateMessage();

  const columns = useMemo(() => {
    return [
      {
        title: translateMessage({ stringId: 'orderDate', defaultMessage: 'Order Date' }),
        dataIndex: 'order.startDateTime',
        key: 'order.startDateTime',
        render: (_value, item) => {
          console.log(item);
          return <ShortDate date={get(item, 'order.startDateTime')} />;
        },
      },
      {
        title: translateMessage({ stringId: 'orderId', defaultMessage: 'Order ID' }),
        dataIndex: 'order.id',
        key: 'order.id',
        render: (_value, item) => {
          return get(item, 'order.id');
        },
      },
      {
        title: translateMessage({ stringId: 'ticketNumberLabel', defaultMessage: 'Ticket Number' }),
        dataIndex: 'ticket.id',
        key: 'ticket.id',
        render: (_value, item) => {
          return get(item, 'ticket.id');
        },
      },
      {
        title: translateMessage({ stringId: 'vehicleId', defaultMessage: 'Vehicle ID' }),
        dataIndex: 'vehicle.id',
        key: 'vehicle.id',
        render: (_value, item) => {
          return get(item, 'vehicle.id');
        },
      },
      {
        title: translateMessage({ stringId: 'mixId', defaultMessage: 'Mix ID' }),
        dataIndex: 'mix.id',
        key: 'mix.id',
        render: (_value, item) => {
          return get(item, 'mix.id');
        },
      },
      {
        title: translateMessage({ stringId: 'tableHeading_location', defaultMessage: 'Location' }),
        dataIndex: 'ticket.origin.id',
        key: 'ticket.origin.id',
        render: (_value, item) => {
          return get(item, 'ticket.origin.id');
        },
      },
      {
        title: translateMessage({ stringId: 'createTime', defaultMessage: 'Create Time' }),
        dataIndex: 'eventDateTime',
        key: 'eventDateTime',
        render: (_value, item) => {
          return <ShortDateTime date={get(item, 'eventDateTime')} />;
        },
      },
      {
        title: translateMessage({ stringId: 'alertMessage', defaultMessage: 'Alert Message' }),
        dataIndex: 'alert.message',
        key: 'alert.message',
        render: (_value, item) => {
          return get(item, 'alert.message');
        },
      },
    ];
  }, [translateMessage]);

  const alertCount = useMemo(() => {
    return alerts?.filter(a => a.active === true)?.length || 0;
  }, [alerts]);

  useEffect(() => {
    setTitle(
      translateMessage({
        stringId: 'alertCountAlerts',
        defaultMessage: '({alertCount}) Alerts',
        values: { alertCount },
      })
    );
  }, [alertCount, setTitle, translateMessage]);

  return (
    <div className={cn(className)}>
      <Table
        dataSource={alerts.filter(a => a.active === true)}
        columns={columns}
        onRow={(record, _rowIndex) => {
          return {
            onClick: e => {
              showMenu(record, 'alert', e.pageX, e.pageY);
            },
          };
        }}
      />
    </div>
  );
};

export default styled(VehiclesInYard)`
  ${style}
`;
