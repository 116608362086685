import React from 'react';
import styled from 'styled-components';
import FlexColumn from '../../order/components/FlexColumn';
import style from './style';
import SetupItemList from '../setup-item-list';
import {columns, prototype} from './setup';
import RuleGeneral from "./rule-general";
import RuleUsage from "./rule-usage";

const Styled = styled(FlexColumn)`
  ${style}
`;

const tabs = [
  {
    label: 'General',
    component: RuleGeneral,
  },
  {
    label: 'Usage',
    component: RuleUsage,
  },
];

const SetupRules = () => {
  return (
    <Styled className="setup-mix">
      <SetupItemList
        typeId="rule"
        type="Rule"
        columns={columns}
        prototype={prototype}
        editorType="tabs"
        tabs={tabs}
        drawerWidth={800}
      />
    </Styled>
  );
};

export default SetupRules;
