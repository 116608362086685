import { isEmpty, kebabCase } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { Translate } from '../../../../views/order/components/localization/Translate';
import { useGridContext } from '../../context/context';
import Button from '../../../button/Button';

const Styled = styled(Button)``;

const ClearFiltersButton = () => {
  const { filters, filterEnabled, clearFilters, gridName } = useGridContext();
  return !isEmpty(filters) && filterEnabled ? (
    <Button size="small" onClick={clearFilters} metricId={gridName ? `${kebabCase(gridName)}-clear-filters` : undefined}>
      <Translate stringId="grid.clearFilters" defaultMessage="Clear Filters" />
    </Button>
  ) : null;
};

export default ClearFiltersButton;
