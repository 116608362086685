import React from 'react';
import styled from 'styled-components';
import { ShortDateTime } from '../../../../../../order/components/localization/ShortDateTime';
import { Translate } from '../../../../../../order/components/localization/Translate';
import { useTranslateMessage } from '../../../../../../order/components/localization/useTranslateMessage';
import style from './style';

const Styled = styled.div`
  ${style}
`;

const ProjectAttachment = ({ link, contentType, index, onDelete, attachmentCrn, createDate, badge, badgeColor }) => {
  const translateMessage = useTranslateMessage();

  const altLoadingText = React.useMemo(() => {
    return translateMessage({ stringId: 'loading', defaultMessage: 'Loading...' });
  }, [translateMessage]);

  return (
    <Styled>
      <div className="card-header">
        <div className="header-data">
          {badge && (
            <div className="badge" style={{ backgroundColor: badgeColor, textTransform: 'uppercase' }}>
              <Translate stringId={`attachmentMetaType_${badge}`} defaultMessage={badge} />
            </div>
          )}
          <div>
            <Translate stringId="uploaded" defaultMessage="Uploaded" />: <ShortDateTime date={createDate} />
          </div>
        </div>
        {onDelete && (
          <i
            className="upload-header-item delete-button font-lg fa fa-solid fa-trash"
            onClick={() => {
              onDelete(attachmentCrn);
            }}
          />
        )}
      </div>

      {contentType === 'application/pdf' ? (
        <iframe title={`project-attachment-preview-${index}`} src={link} style={{ width: '100%', height: '600px' }} />
      ) : (
        <img style={{ width: '100%' }} src={link} alt={altLoadingText} />
      )}
    </Styled>
  );
};

export default ProjectAttachment;
