import { find, pick } from 'lodash';
import * as util from '../util';

export default async ({
  setInitializing,
  setTrucks,
  setOrigin,
  setDestination,
  setWaypoints,
  setConfig,
  fitBounds,
  startPolling,
  form,
  initializeTrackingMap,
  initializeEntryMap,
  orderId,
  readOnly,
}) => {
  setInitializing(true);

  const promise =
    form?.tickets?.length || form?.linkedOrders?.some(lo => lo?.tickets?.length)
      ? initializeTrackingMap(orderId)
      : initializeEntryMap(orderId);

  const cfg = await promise;

  if (!cfg?.pk) {
    setInitializing(false);
    return false;
  }

  if (cfg.polling) {
    startPolling(cfg.crn, cfg?.pollingTime);
  }

  if (readOnly) {
    cfg.markers = cfg.markers?.map?.(marker => {
      return {
        ...marker,
        draggable: false,
        canUpdate: false,
        disabled: true,
      };
    });
  }

  const origin = find(cfg?.markers, { type: 'plant' });
  const destination = find(cfg?.markers, { type: 'destination' });
  const waypoints = cfg?.markers?.filter?.(m => m.type === 'waypoint') || [];
  const trucks = cfg?.markers?.filter?.(m => m.type === 'truck') || [];
  const route = util.prepareRoute(destination?.route?.Legs);

  if (cfg._meta?.initDisplay) {
    cfg._meta?.initDisplay();
  } else {
    fitBounds(origin, destination, route, waypoints, trucks);
  }

  setOrigin(origin);
  setDestination(destination);
  setWaypoints(waypoints);
  setTrucks(trucks);
  setConfig({
    configRef: cfg.crn,
    origin,
    destination,
    waypoints,
    trucks,
    orderRef: form?.crn,
    ...pick(cfg, ['polling', 'pollingTime']),
  });

  setInitializing(false);
};
