import { useCallback } from 'react';
import { useState } from 'react';
import { api } from '../../../../../api/unInvoice';

const useUnInvoice = () => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const executeUnInvoice = useCallback(async (entityRef, invoiceRef) => {
    try {
      setLoading(true);
      const response = await api.unInvoice(entityRef, { invoices: [{ crn: invoiceRef }] });
      setResponse(response);
    } catch (err) {
      setError(err?.message);
    } finally {
      setLoading(false);
    }
  }, []);

  return { response, error, loading, executeUnInvoice };
};

export default useUnInvoice;
