import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';

const Message = ({ className, message }) => {
  return <div className={cn('note-message', className)}>{message}</div>;
};

export default styled(Message)`
  .note-type {
    font-size: 0.75rem;
    opacity: 0.5;
  }
  margin-top: 0.75rem;
`;
