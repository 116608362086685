import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { useOrderContext } from '../../useOrderContext';
import AttachmentsList from './components/attachments-list';

const Attachments = ({ className }) => {
  const {
    form: { form, handleChange },
  } = useOrderContext();

  return (
    <div className={cn('attachments', className)}>
      <AttachmentsList initialAttachments={form?.attachments} orderRef={form?.crn} onChange={handleChange} />
    </div>
  );
};

export default styled(Attachments)``;
