import { find } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import FieldContainer from '../../field-container/FieldContainer';
import Select from '../../form/Select';
import style from './style';

const jobSearchFilter = (input, option) => {
  const searchText = input.toLowerCase?.();
  return Array.isArray(option?.children) ? option.children.join().toLowerCase().indexOf(searchText) >= 0 : false;
};

const Job = ({ className, jobs, onChange, value, disabled }) => {
  const handleChange = val => {
    const targetJob = find(jobs, s => s.jobRef === val);

    const { crn, ...crnRemoved } = targetJob ?? {};
    onChange('job', { ...crnRemoved, jobRef: crn });
  };

  const { Option } = Select;

  return (
    <FieldContainer className={cn('job', className)}>
      <div className="label-spinner">
        <div className="label">Job</div>
        <div className={cn('spinner', { spinning: false })}>
          <span className="fas fa-sync fa-spin" />
        </div>
      </div>

      <Select
        value={value?.jobRef || ''}
        onChange={handleChange}
        disabled={disabled}
        showSearch
        filterOption={jobSearchFilter}
      >
        <option value="">Select a job...</option>
        {jobs?.map(c => (
          <Option value={c.crn} key={c.crn}>
            {c.id} / {c.description}
          </Option>
        ))}
      </Select>
    </FieldContainer>
  );
};

export default styled(Job)`
  ${style}
`;
