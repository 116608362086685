import { createContext, useContext, useCallback } from 'react';
import { useOrderContext } from '../../../useOrderContext';
import getDefaultOrderGroupLineItem from './get-default-order-group-line-item';

const OrderLineContext = createContext({});

export const useOrderLinesContext = () => {
  const result = useContext(OrderLineContext);
  if (!result) {
    throw new Error('');
  }
  return result;
};

export const OrderLinesContextProvider = ({ children }) => {
  const {
    form: { form, handleChange },
  } = useOrderContext();

  const addOrderLine = useCallback(() => {
    const value = form?.linkedOrders || [];

    value.push(getDefaultOrderGroupLineItem());
    handleChange('linkedOrders', value);
  }, [form, handleChange]);

  const removeOrderLine = useCallback(
    ind => {
      let value = form?.linkedOrders || [];
      value = value.filter((_item, index) => index !== ind);
      handleChange('linkedOrders', value);
    },
    [form, handleChange]
  );

  const value = {
    addOrderLine,
    removeOrderLine,
  };

  return <OrderLineContext.Provider value={value}>{children}</OrderLineContext.Provider>;
};

export default {
  useOrderLinesContext,
  OrderLinesContextProvider,
};
