import SelectSetupItem from '../select-setup-item';
import React, { useCallback } from 'react';

const SelectLocation = ({ item, readOnly, id, onChange, label, includeDispatchLocations }) => {
  const changeLocation = useCallback(
    (_id, value) => {
      onChange(id, value);
    },
    [id, onChange]
  );

  const params = { includeDispatchLocations };

  return (
    <SelectSetupItem
      id="locationRef"
      label={label}
      typeId="location"
      setupItemRef={item?.[id]?.locationRef}
      onChange={changeLocation}
      readOnly={readOnly}
      showIdWithLabel
      params={params}
    />
  );
};

export default SelectLocation;
