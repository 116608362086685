import { useInitialize } from '../hooks/initialize/useInitialize';
import { useGeofence } from '../hooks/routing/useGeofence';
import { useRouting } from '../hooks/routing/useRouting';
import { useAcceptance } from '../hooks/useAcceptance';
import { useGeoStuff } from '../hooks/useGeoStuff';
import { useHandleAddressChange } from '../hooks/useHandleAddressChange';
import { useHandleDestinationMarkerDrag } from '../hooks/useHandleDestinationMarkerDrag';
import { useMap } from '../hooks/useMap';
import { useUpdateDestinationMarker } from '../hooks/useUpdateDestinationMarker';
import { useWaypoints } from '../hooks/useWaypoints';

export const useNewOrderBehavior = initialForm => {
  useInitialize({ initialForm });
  const map = useMap();
  const geoStuff = useGeoStuff();
  const waypoints = useWaypoints();
  const acceptance = useAcceptance();
  const calculateRoute = useRouting();
  const onGeofenceChange = useGeofence();
  const handleAddressChange = useHandleAddressChange();
  const updateDestinationMarker = useUpdateDestinationMarker();
  const handleDestinationMarkerDrag = useHandleDestinationMarkerDrag();

  return {
    ...map,
    ...geoStuff,
    ...waypoints,
    ...acceptance,
    ...calculateRoute,
    ...onGeofenceChange,
    ...handleAddressChange,
    ...updateDestinationMarker,
    ...handleDestinationMarkerDrag,
  };
};
