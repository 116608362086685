import { DropDown, Menu } from 'antd';
import React, { useCallback } from 'react';
import { useTranslateObjects } from '../../../order/components/localization/translate-objects/useTranslateObjects';
import { useMenuContext } from './useMenuContext';
import styled from 'styled-components';
import cn from 'classnames';
import MenuDropdown from './menuDropdown';
import { useDashboardContext } from '../context/useDashboardContext';
import { usePermissions } from '../../../order/components/usePermissions';

const MenuCollection = ({ className, menus }) => {
    if (!menus)
        menus = [require('./menus/dispatch'), require('./menus/windows'), require('./menus/configuration')];
  const { translateObjects } = useTranslateObjects();
  const { licenses } = useDashboardContext();
  const { has } = usePermissions();

  const translatedMenus = React.useMemo(
    () =>
      translateObjects(menus, { getPath: 'titleStringId', setPath: 'translatedTitle' }).map(menuItem => {
        if (menuItem.items) {
          menuItem.items = translateObjects(menuItem.items, [
            { getPath: 'titleStringId', setPath: 'translatedTitle', defaultMessagePath: 'title' },
            { getPath: 'nameStringId', setPath: 'name', defaultMessagePath: 'name' },
          ]);
        }
        return menuItem;
      }),
    [translateObjects]
  );

  return (
    <div className={cn(className)}>
      {translatedMenus.map((menu, key) => {
        if (menu.license && !licenses[menu.license]) {
          return null;
        }

        if (menu.permission && !has(menu.permission, true)) {
          return null;
        }

        return <MenuDropdown menu={menu} key={key} />;
      })}
    </div>
  );
};

export default styled(MenuCollection)`
  display: flex;
  flex: 1;
  flex-direction: row;
  float: left;
`;
