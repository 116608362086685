import {css} from 'styled-components';

export default css`
  height: calc(100% - 20px);
  width: 100%;
  .vehicle-token {
    width: 200px;
    max-width: 200px;
  }  
`
