import style from '../style';
import React from 'react';
import styled from 'styled-components';
import { status, yesNo } from './options';
import InputText from '../../components/input-text';
import InputSelect from '../../components/input-select';
import ManageFees from './manage-fees';
import InputCheckbox from '../../components/input-checkbox';

const Styled = styled.div`
  ${style}
`;

const PaymentMethodGeneral = ({ item: paymentMethod, onChange }) => {
  const handleInvoiceableChange = (id, value) => {
    if (value !== 'NO') {
      onChange('hasFees', 'NO'); // Fees is only supported when not invoiceable, currently
    }
    onChange(id, value);
  };

  return (
    <Styled>
      <InputText id="id" label="ID" value={paymentMethod?.id} onChange={onChange} />
      <InputText id="name" label="Name" value={paymentMethod?.name} onChange={onChange} />
      <InputSelect
        id="invoiceable"
        label="Invoiceable"
        value={paymentMethod?.['invoiceable'] ?? 'NO'}
        onChange={handleInvoiceableChange}
        options={yesNo}
        required
      />
      {(!paymentMethod || paymentMethod.invoiceable !== 'YES') && (
        <InputSelect
          id="hasFees"
          label="Include Fees"
          value={paymentMethod?.hasFees ?? 'NO'}
          options={yesNo}
          onChange={onChange}
        />
      )}
      {paymentMethod?.hasFees === 'YES' && <ManageFees item={paymentMethod} onChange={onChange} />}
      <InputSelect
        id="status"
        label="Status"
        value={paymentMethod?.status}
        onChange={onChange}
        options={status}
        required
      />
      <InputCheckbox id="isCOD" label="COD Payment" onChange={onChange} value={paymentMethod?.isCOD ?? false} />
    </Styled>
  );
};

export default PaymentMethodGeneral;
