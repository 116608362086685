import { FILTER_STRATEGY } from '../../../components/connex-data-grid/util';
import React from 'react';

export const prototype = {
  id: '',
  name: '',
  status: 'ACTIVE',
  formulas: [],
  components: [],
};

export const columns = [
  {
    key: 'id',
    name: 'ID',
  },
  {
    key: 'name',
    name: 'Name',
  },
  {
    name: 'Has Formulas',
    key: 'formulas',
    formatter: ({ row }) => {
      return row?.formulas?.length > 0 ? 'YES' : 'NO';
    },
    export: row => {
      return row?.formulas?.length > 0 ? 'YES' : 'NO';
    },
    filterFn: (formulas, filterStrings) => {
      const filterValue = filterStrings?.[0];
      if (!filterValue) return true;
      if (filterValue === 'YES') return formulas?.length > 0;
      if (filterValue === 'NO') return formulas?.length <= 0;
    },
    filterComponent: ({ innerRef, ...props }) => {
      return (
        <select ref={innerRef} {...props}>
          <option value="" />
          <option value="YES">Yes</option>
          <option value="NO">No</option>
        </select>
      );
    },
  },
  {
    key: 'status',
    name: 'Status',
    filterStrategy: FILTER_STRATEGY.EQUALS,
    filterComponent: ({ innerRef, ...props }) => {
      return (
        <select ref={innerRef} {...props}>
          <option value="" />
          <option value="ACTIVE">Active</option>
          <option value="INACTIVE">Inactive</option>
        </select>
      );
    },
  },
];
