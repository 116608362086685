import React, { useMemo } from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import FieldContainer from '../../field-container/FieldContainer';
import Input from '../../form/Input';
import style from './style';

const Discipline = ({ className, value }) => {
  const text = useMemo(() => {
    const slash = value?.id && value?.description ? ' / ' : '';
    return `${value?.id || ''}${slash}${value?.description || ''}`;
  }, [value]);

  return (
    <FieldContainer className={cn('discipline', className)}>
      <div className="label-spinner">
        <div className="label">Discipline</div>
        <div className={cn('spinner', { spinning: false })}>
          <span className="fas fa-sync fa-spin" />
        </div>
      </div>
      <Input type="text" value={text} disabled data-testid="discipline_input" />
    </FieldContainer>
  );
};

export default styled(Discipline)`
  ${style}
`;
