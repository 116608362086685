import React, { useCallback } from 'react';
import cn from 'classnames';
import styled from 'styled-components';
import FieldContainer from '../../field-container/FieldContainer';
import Input from '../../form/Input';

const PourCardNumber = ({ className, disabled, value, onChange }) => {
  const handleChange = useCallback(
    e => {
      onChange('pourCardNumber', e.target.value);
    },
    [onChange]
  );

  return (
    <FieldContainer className={cn('pour-card-number', className)}>
      <div className="label-spinner">
        <div className="label">Pour Card Number</div>
        <div className={cn('spinner', { spinning: false })}>
          <span className="fas fa-sync fa-spin" />
        </div>
      </div>
      <Input
        type="text"
        value={value || ''}
        onChange={handleChange}
        disabled={disabled}
        data-testid="pourCardNumber_input"
      />
    </FieldContainer>
  );
};

export default styled(PourCardNumber)``;
