import { useCallback, useState } from 'react';
import { find, isEmpty, omit } from 'lodash';

const useValidateField = list => {
  const [validationErrors, setValidationErrors] = useState(null);

  const updateValidationErrors = useCallback((id, condition, errorMessage) => {
    if (condition) {
      setValidationErrors(s => {
        return {
          ...s,
          [id]: errorMessage,
        };
      });
    } else {
      setValidationErrors(s => {
        const newState = omit(s, id);
        return isEmpty(newState) ? null : newState;
      });
    }
  }, []);

  const validateField = useCallback(
    (id, value) => {
      const newValue = value?.trim?.() || value;

      if (id === 'id' && list?.length) {
        const existingCustomerWithId = find(list, { id: newValue });
        updateValidationErrors(id, !!existingCustomerWithId, `ID already in use by ${existingCustomerWithId?.name}`);
      }

      if (id === 'contacts') {
        const noContactType = value.some(contact => !contact.contactType);
        updateValidationErrors(id, noContactType, 'Contact Type is required');
      }
    },
    [list, updateValidationErrors]
  );

  return { validateField, validationErrors };
};

export default useValidateField;
