import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
import FadeInDiv from '../../../order-list/components/FadeInDiv';
import { useOrderContext } from '../../useOrderContext';
import FieldsAggregate from './aggregate/FieldsAggregate';
import FieldsConcrete from './concrete/FieldsConcrete';
import style from './style';
import OrderLinesProductEntry from './order-lines-product-entry';
import { OrderLinesContextProvider } from './order-lines-product-entry/useOrderLinesContext';
import { ORDER_TYPE } from '../../../../../constants';
import FormError from '../../../../setup/components/form-error';

const General = ({ className }) => {
  const {
    form: { isValid },
    saveOrderBusy,
    handleSaveClick,
    orderType,
    invalidProductsMessage,
    masterData: { masterDataBusy },
  } = useOrderContext();

  const fields = React.useMemo(
    () => ({
      [ORDER_TYPE.AGGREGATE]: <FieldsAggregate />,
      [ORDER_TYPE.CONCRETE]: <FieldsConcrete />,
    }),
    []
  );

  const handleKeyCapture = React.useCallback(
    e => {
      if (e.key === 'Enter' && isValid && !saveOrderBusy && (e.ctrlKey || e.metaKey)) {
        e.preventDefault();
        e.stopPropagation();
        handleSaveClick();
      }
    },
    [handleSaveClick, isValid, saveOrderBusy]
  );

  return (
    <FadeInDiv className={cn('General', className)} onKeyDownCapture={handleKeyCapture}>
      {fields[orderType]}
      {!masterDataBusy && invalidProductsMessage && (
        <div style={{ marginBottom: '20px' }}>
          <FormError error={invalidProductsMessage} />
        </div>
      )}
      <OrderLinesContextProvider>
        <OrderLinesProductEntry />
      </OrderLinesContextProvider>
    </FadeInDiv>
  );
};

export default styled(General)`
  ${style}
`;
