import FormError from '../components/form-error';
import Spinner from '../../../components/spinner/Spinner';
import SetupGeneral from './setup-general';
import SetupTabs from './setup-tabs';
import CommandBar from './command-bar';
import React from 'react';
import styled from 'styled-components';
import style from './style';

const Styled = styled.div`
  ${style}
`;
const SetupItemForm = ({
  setupItem,
  itemBusy,
  saving,
  onChange,
  onCancel,
  typeId,
  validated,
  onSaveItem,
  editorType,
  tabs,
  error,
  removeButtons,
  disableButtons,
  usedLocations,
  locationWarningMessage,
  saveWarningMessage,
  disableSaving,
}) => {
  return (
    <Styled className="setup-item-editor">
      <FormError error={error} />
      <Spinner spin={!setupItem || itemBusy}>
        {editorType === 'default' && <SetupGeneral item={setupItem} onChange={onChange} typeId={typeId} />}
        {editorType === 'tabs' && tabs?.length > 0 && (
          <SetupTabs
            item={setupItem}
            tabs={tabs}
            onChange={onChange}
            onCancel={onCancel}
            disableButtons={disableButtons}
            usedLocations={usedLocations}
            locationWarningMessage={locationWarningMessage}
            saveWarningMessage={saveWarningMessage}
          />
        )}
        {!removeButtons && (
          <CommandBar
            onSave={onSaveItem}
            onCancel={onCancel}
            busy={itemBusy}
            saving={saving}
            typeId={typeId}
            disabled={!validated}
            disableSaving={disableSaving}
          />
        )}
      </Spinner>
    </Styled>
  );
};

export default SetupItemForm;
