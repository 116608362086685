import { orderBy } from 'lodash';
import * as util from '../util';
import { getRoute } from './getRoute';

const _calculateRoute = async ({
  waypoints,
  coordinates,
  origin,
  departureTime,
  entityRef,
  setCalculatingRoute,
  setDisabled,
  setRoute,
  setDestination,
}) => {

  setCalculatingRoute(true);
  setDisabled(true);

  const confirmedWaypoints = waypoints?.filter?.(w => w.status !== 'pending');

  const points = [];

  if (confirmedWaypoints?.length) {
    const newWaypoints = orderBy(confirmedWaypoints, ['sequence'], ['asc']);
    for (let marker of newWaypoints) {
      if (marker.type === 'waypoint') {
        points.push([marker.lng, marker.lat]);
      }
    }
  }
  const newRoute = await getRoute({
    destinationCoordinates: coordinates,
    waypoints: points,
    origin,
    departureTime,
    entityRef,
  });

  const preparedRoute = util.prepareRoute(newRoute?.Legs);

  setRoute(preparedRoute);
  setDestination(s => ({ ...s, route: newRoute }));
  setDisabled(false);
  setCalculatingRoute(false);

  return preparedRoute;
};
export default _calculateRoute;
