import { css } from 'styled-components';

export default css`
  width: 100%;
  height: 100%;
  padding: 25px;
  text-align: center;

  .input-search {
    margin-bottom: 50px;
    margin-left: 10px;
    width: 90%;
  }

  .types {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  }

  .type {
    margin: 10px;

    &:hover {
      cursor: pointer;
      color: #0e4980;
    }
  }

  .icon {
    font-size: 1.8em;
  }

  .label {
    font-size: 1.1em;
  }
`;
