import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';

const Styled = styled.div`
  padding: 10px 20px;
  font-weight: bold;
  font-size: 1.2rem;
`
const Header = props => {
  return (
    <Styled className={cn('header')} >
      Scale Ticketing
    </Styled>
  );
};

export default Header;
