import React from 'react';
import { useMediaQuery } from 'react-responsive';

const MaxSize = ({ children, breakpoint }) => {
  const smallScreen = useMediaQuery({
    query: `(min-width: ${breakpoint}px)`,
  });

  return smallScreen ? null : children;
};

export default MaxSize;
