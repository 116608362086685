import { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import Button from '../../../../components/button/Button';
import InputSelect from '../../components/input-select';
import InputSelectMultiple from '../../components/input-select-multiple';
import useSetup from '../../useSetup';
import style from './style';
import { useParams } from 'react-router-dom';
import useCopyLocationMixes from './hooks/useCopyLocationMixes';

const Styled = styled.div`
  ${style}
`;

const MixLocationCopy = ({ onCancel }) => {
  const { entityRef } = useParams();

  const { locations, getLocations, busy: loadingLocations } = useSetup();
  const { loading, copyLocationMixes, error } = useCopyLocationMixes();

  const [selectedSourceLocation, setSelectedSourceLocation] = useState(null);
  const [selectedCopyLocations, setSelectedCopyLocations] = useState([]);

  useEffect(() => {
    getLocations(entityRef, { activeOnly: false });
  }, [getLocations, entityRef]);

  const handleChangeSourceLocations = useCallback(
    (_, value) => {
      setSelectedSourceLocation(value);
      setSelectedCopyLocations([]);
    },
    [setSelectedSourceLocation]
  );

  const handleChangeCopyLocations = useCallback(
    (_, value) => {
      setSelectedCopyLocations(value);
    },
    [setSelectedCopyLocations]
  );

  const handleSave = useCallback(async () => {
    copyLocationMixes(entityRef, selectedSourceLocation, selectedCopyLocations, onCancel);
  }, [selectedSourceLocation, selectedCopyLocations, onCancel, copyLocationMixes, entityRef]);

  const sourceLocationOptions = useMemo(() => {
    if (!locations?.length || loadingLocations) return [];

    return locations.map(location => ({
      value: location.crn,
      label: `${location.id} / ${location.name}`,
    }));
  }, [locations, loadingLocations]);

  const copyLocationOptions = useMemo(() => {
    if (!locations?.length || loadingLocations) return [];

    return locations.reduce((result, location) => {
      if (location.crn === selectedSourceLocation) return result;

      result.push({
        value: location.crn,
        label: `${location.id} / ${location.name}`,
      });
      return result;
    }, []);
  }, [locations, loadingLocations, selectedSourceLocation]);

  return (
    <Styled>
      {error && <div className="error">{error}</div>}
      <InputSelect
        className="spacing"
        required
        label="Select Source Location:"
        id="SourceLocationSelect"
        value={selectedSourceLocation}
        disabled={loadingLocations}
        options={sourceLocationOptions}
        onChange={handleChangeSourceLocations}
      />
      <InputSelectMultiple
        className="spacing"
        required
        label="Select Copy Locations:"
        id="CopyLocationsSelect"
        value={selectedCopyLocations}
        disabled={loadingLocations || !selectedSourceLocation}
        options={copyLocationOptions}
        onChange={handleChangeCopyLocations}
      />
      <div className="footer spacing">
        <Button
          disabled={!selectedCopyLocations.length || loading}
          loading={loadingLocations || loading}
          onClick={handleSave}
        >
          Save
        </Button>
      </div>
    </Styled>
  );
};

export default MixLocationCopy;
