import cn from 'classnames';
import { find } from 'lodash';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import FieldContainer from '../../field-container/FieldContainer';
import Input from '../../form/Input';
import Select from '../../form/Select';
import style from './style';

const contactSearchFilter = (input, option) => {
  const searchText = input.toLowerCase?.();
  return option?.children?.toLowerCase?.().indexOf(searchText) >= 0;
};

const EmailCopies = ({ className, contacts = [], onChange, value = [], disabled }) => {
  const handleChange = val => {
    const foundContacts = val.map(contactRef => {
      const targetContact = find(contacts, c => c.crn === contactRef);

      return {
        contactRef: targetContact.crn,
        name: targetContact.name,
        email: targetContact.email,
      };
    });
    onChange('emailCopies', foundContacts);
  };

  const { Option } = Select;

  const valueContactRefs = useMemo(() => {
    return value.map(c => c.contactRef);
  }, [value]);

  return (
    <FieldContainer className={cn('email-copies', className)}>
      <div className="label-spinner">
        <div className="label">Email Copies To</div>
        <div className={cn('spinner', { spinning: false })}>
          <span className="fas fa-sync fa-spin" />
        </div>
      </div>

      <Select
        mode="multiple"
        value={valueContactRefs || []}
        onChange={handleChange}
        disabled={disabled}
        showSearch
        filterOption={contactSearchFilter}
      >
        {contacts?.map(e => (
          <Option value={e.crn} key={e.crn}>
            {e.name}
          </Option>
        ))}
      </Select>
    </FieldContainer>
  );
};

export default styled(EmailCopies)`
  ${style}
`;
