import SelectField from '../../../order/components/SelectField';
import AddItem from '../../../order/components/order-form/general/AddItem';
import { SetupCostBooksEditor } from '../../setup-cost-books';
import InputCheckbox from '../input-checkbox';
import PriceBookProducts from '../select-price-book/price-book-products';
import styled from 'styled-components';
import InputSelectMultiple from '../input-select-multiple';
import useSetup from '../../useSetup';
import { useEffect, useState } from 'react';

const Title = styled.div`
  margin-top: 13px;
  margin-bottom: 3px;
`;

const Styled = styled.div`
  .restrict {
    padding-top: 20px;
    padding-bottom: 20px;
  }
`;
const SelectMultiplePriceBooks = ({ onChange, id, value, label = 'Price Books', disabled, onBlur = null }) => {
  const { getPriceBooks, priceBooks } = useSetup('cost-book');

  useEffect(() => {
    getPriceBooks().then();
  }, [getPriceBooks]);

  return (
    <Styled>
      {label && <Title>{label}</Title>}
      <InputSelectMultiple
        id={id}
        disabled={disabled}
        datatestid="select-price-book"
        displayPath={['id', 'name']}
        value={value}
        valuePath={'crn'}
        onChange={onChange}
        interimValue={value}
        options={priceBooks}
        onBlur={onBlur}
      />
    </Styled>
  );
};

export default SelectMultiplePriceBooks;
