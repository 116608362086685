import FlexLayout from 'flexlayout-react';
import moment from 'moment';
import { cloneDeep } from "lodash";
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { ThemeProvider } from 'styled-components';
import { nonce } from '../../../../util';
import { getCachedItem } from '../../../../util/cache';
import TicketEntry from '../../../order/components/tickets/TicketEntry';
import { useUserContext } from '../../../order/components/user-context/UserContextProvider';
import API from '../../api';
import { useDrawer } from '../components/drawer/useDrawer';
import useRecursiveTimeout from '../hooks/useRecursiveTimeout';
import json from './models/default-layout-model';
import useTrackingConfigurations from './useTrackingConfigurations';

const DashboardContext = createContext();

export const useDashboardContext = () => {
  const value = useContext(DashboardContext);
  if (!value) {
    throw new Error('Dashboard context must be used in ContextProvider');
  }

  return value || {};
};

export const getContrastYIQ = hexcolor => {
  hexcolor = hexcolor.replace('#', '');
  const r = parseInt(hexcolor.substr(0, 2), 16);
  const g = parseInt(hexcolor.substr(2, 2), 16);
  const b = parseInt(hexcolor.substr(4, 2), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? 'black' : 'white';
};

export const DashboardContextProvider = ({ children }) => {
  const { entityRef } = useParams();
  const user = useUserContext();
  const { currentTrackingConfiguration, setLocationsSelected, setCurrentTrackingConfiguration } =
    useTrackingConfigurations();

  const [layoutModel, setLayoutModel] = useState(json);
  const [entity, setEntity] = useState(null);
  const [licenses, setLicenses] = useState({});
  const [loading, setLoading] = useState(false);

  const [alerts, setAlerts] = useState([]);

  const [windowLayoutRef, setWindowLayoutRef] = useState(null);

  const [locations, setLocations] = useState([]);

  const [refreshNonce, setRefreshNonce] = useState(nonce());

  useEffect(() => {
    const run = async () => {
      let savedModel = await API.getScreenLayout(entityRef);
      if (savedModel && !(savedModel?.config?.layout?.children?.length === 1 && savedModel?.config?.layout?.children?.[0]?.weight === 0)) {
        setLayoutModel(savedModel.config);
        return;
      }

      let cachedModel = getCachedItem(`DASHBOARD_LAYOUT#${entityRef}`);
      if (cachedModel) {
        cachedModel = JSON.parse(cachedModel);
        await API.saveScreenLayout(entityRef, { config: cachedModel });
        setLayoutModel(cachedModel);
      }
    };
    if (entityRef) {
      run().then();
    }
  }, [entityRef]);

  const refreshDashboard = useCallback(() => {
    setRefreshNonce(nonce());
  }, [setRefreshNonce]);

  const [currentVehicles, setCurrentVehicles] = useState(null);

  useEffect(() => {
    const run = async () => {
      setLoading(true);
      const { entity, locations, licenses } = await API.initialize(entityRef, user);

      setLocations(locations);
      setEntity(entity);
      setLicenses(licenses);
      setLoading(false);
    };

    run().then();
  }, [entityRef, user]);

  const { Drawer, toggle: toggleDrawer, setContent, setOpen } = useDrawer();

  const [meta, setMeta] = useState({
    startDate: moment().startOf('day').toISOString(),
    endDate: moment().endOf('day').toISOString(),
  });

  const getActiveId = useCallback(() => {
    if (windowLayoutRef) {
      const modelObject = windowLayoutRef?.props?.model;

      if (modelObject?._idMap) {
        const activeId = modelObject?._activeTabSet?._attributes?.id;

        if (!modelObject?._idMap?.[activeId]) {
          const firstActiveTabset = Object.values(modelObject?._idMap).filter(
            o => o._attributes?.type === 'tabset'
          )?.[0];

          if (firstActiveTabset) {
            return firstActiveTabset._attributes?.id;
          }
        } else {
          return activeId;
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layoutModel, windowLayoutRef]);

  const resetLayout = useCallback(
      () => {
        setLayoutModel(cloneDeep(json));
      }, [setLayoutModel]
  )

  const openDashboardWindow = useCallback(
    (windowType, windowName, allowDuplicates = true) => {
      if (windowLayoutRef) {
        if (!allowDuplicates) {
          let nodeFound;
          windowLayoutRef?.props?.model?.visitNodes(node => {
            if (node?._attributes?.type === 'tab' && node?._attributes?.name === windowName) {
              nodeFound = node;
              return true;
            }
          });

          if (nodeFound?._attributes?.id) {
            windowLayoutRef?.props?.model?.doAction(FlexLayout.Actions.selectTab(nodeFound?._attributes?.id));
            return;
          }
        }

        const windowToAdd = {
          type: 'tab',
          name: windowName,
          component: windowType,
        };
        const activeId = getActiveId();
        if (activeId) {
          windowLayoutRef.addTabToTabSet(activeId, windowToAdd);
        }
      }
    },
    [windowLayoutRef, getActiveId]
  );

  const setDateRange = useCallback(
    ({ startDate, endDate }) => {
      setMeta(existingValue => ({ ...existingValue, startDate, endDate }));
    },
    [setMeta]
  );

  useEffect(() => {
    refreshDashboard();
  }, [meta, refreshDashboard]);

  const openTicketDrawer = useCallback(
    (orderRef, carrierRef, vehicleRef, driverRef) => {
      setContent(
        <TicketEntry
          open={true}
          orderRef={orderRef}
          carrierRef={carrierRef}
          vehicleRef={vehicleRef}
          driverRef={driverRef}
          onTicketed={() => {
            setOpen(false);
            refreshDashboard();
          }}
        />
      );
      setOpen(true);
    },
    [setContent, setOpen, refreshDashboard]
  );

  const retrieveAlerts = useCallback(async () => {
    const result = await API.searchVehicleAlerts(
      entityRef,
      moment().subtract(18, 'hours').toISOString(),
      moment().toISOString()
    );

    setAlerts(result.items);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityRef, licenses.assurance]);

  useEffect(() => {
    retrieveAlerts().then();
  }, [retrieveAlerts]);

  useRecursiveTimeout(retrieveAlerts, 30000);

  const theme = {
    orderLineBackground: currentTrackingConfiguration?.colors?.orderLine || '#FFFF00',
    orderLineForeground: getContrastYIQ(currentTrackingConfiguration?.colors?.orderLine || '#FFFF00'),

    locationLineBackground: currentTrackingConfiguration?.colors?.locationLine || '#32CD32',
    locationLineForeground: getContrastYIQ(currentTrackingConfiguration?.colors?.locationLine || '#32CD32'),

    vehicleLineBackground: currentTrackingConfiguration?.colors?.vehicleLine || '#40E0D0',
    vehicleLineForeground: getContrastYIQ(currentTrackingConfiguration?.colors?.vehicleLine || '#40E0D0'),
  };

  return (
    <DashboardContext.Provider
      value={{
        alerts,
        setAlerts,
        openTicketDrawer,
        setLocationsSelected,
        entityRef,
        refreshNonce,
        meta,
        refreshDashboard,
        setDateRange,
        setWindowLayoutRef,
        setLayoutModel,
        layoutModel,
        openDashboardWindow,
        resetLayout,
        currentVehicles,
        setCurrentVehicles,
        entity,
        licenses,
        drawer: { toggleDrawer, setContent, setOpen },
        saveCurrentTrackingConfiguration: setCurrentTrackingConfiguration,
        currentTrackingConfiguration,
        retrieveAlerts,
        locations,
      }}
    >
      <ThemeProvider theme={theme}>
        {loading && <div>Loading...</div>}
        {!loading && children}
        {Drawer}
      </ThemeProvider>
    </DashboardContext.Provider>
  );
};

export default {
  useDashboardContext,
  DashboardContextProvider,
};
