import React from 'react';
import Input from '../../../components/form/Input';
import Margin from '../components/Margin';
import { useScaleTicketingContext } from '../Context';

const PurchaseOrder = () => {
  const { form, handleChange } = useScaleTicketingContext();

  const handleFieldChange = e => {
    handleChange('vehicle', s => ({
      ...s,
      configuration: {
        ...s.configuration,
        purchaseOrder: e.target.value,
      },
    }));
  };

  return (
    <Margin top={10}>
      <div className="field-label">PurchaseOrder</div>
      <Input value={form?.vehicle?.configuration?.purchaseOrder || ''} onChange={handleFieldChange} />
    </Margin>
  );
};

export default PurchaseOrder;
