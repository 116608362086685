import React, { useEffect } from 'react';
import styled from 'styled-components';
import ColorPicker from '../../../../tracking/dashboard/components/color-picker';
import getUomAbbreviationOrDescription from '../../../../../util/uom';
import { useOrder } from '../../../view-or-edit-order/useOrder';
import { useParams } from 'react-router-dom';
import { ShortDateTime } from '../../localization/ShortDateTime';
import { Translate } from '../../localization/Translate';
import { useTranslateMessage } from '../../localization/useTranslateMessage';

const TH = styled.th`
  padding: 5px;
  background-color: #0e4980;
  border: solid 1px #666;
  vertical-align: top;
`;

const TD = styled.td`
  padding: 5px;
  border: solid 1px #666;
  vertical-align: top;
`;

const Table = styled.table`
  margin-bottom: 10px;
`;

const OrderNumber = styled.div`
  font-size: 1.2em;
  font-weight: bold;
`;
const description = type => {
  const labels = {
    A: Translate({stringId: 'auditTypes.A', defaultMessage: 'Created'}),
    M: Translate({stringId: 'auditTypes.M', defaultMessage: 'Modified'}),
  };

  return labels[type] || type;
};



const ValueOf = ({ fieldId, value, reason = false }) => {
  if (value && fieldId === 'products') {
    const items = JSON.parse(value);
    return items.map((item, key) => (
      <div key={`${key}-item`}>{`${item.id} ${item.slump ? `(Slump ${item.slump})` : ''}`}</div>
    ));
  }

  if (value && fieldId.endsWith('_product')) {
    const item = JSON.parse(value);
    return !item.id ? (
      <div>&nbsp;</div>
    ) : (
      <div>{`${item.orderedQuantity} ${getUomAbbreviationOrDescription(item.uomCode)} / ${item.id}${
        item.slump ? ` / Slump ${item.slump}` : ''
      }${item.holdQuantity ? ` / HQ:${item.holdQuantity}` : ''}${item.cleanup ? ' w/cleanup' : ''} `}</div>
    );
  }

  if (fieldId === 'tracking') {
    if (reason) {
      return null;
    }
    if (value) {
      return (
        <div style={{ display: 'inline-block' }}>
          <ColorPicker readOnly color={value.backgroundColor} />
        </div>
      );
    }
    return (
      <div style={{ display: 'inline-block' }}>
        <ColorPicker readOnly color={'#FFFF00'} />
      </div>
    );
  }

  if (fieldId === 'ticket_supplier_status') {
    if (reason) {
      return (
        <div style={{ display: 'inline-block' }}>
          {value?.reasonCode}
          {value?.reason && ` -- ${value.reason}`}{' '}
        </div>
      );
    }

    return (
      <div style={{ display: 'inline-block' }}>
        Ticket #{value?.ticketNumber}
        {value?.statusCode && ` -- ${value.statusCode}`}{' '}
      </div>
    );
  }

  if ((fieldId === 'taxRate' || fieldId === 'taxRateRef') && value) {
    let taxRateKey;
    console.log(value)
    switch(value)
    {
      case 'Customer Address':
        taxRateKey = 'taxRate__customer';
        break;
      case 'Delivery Address':
        taxRateKey = 'taxRate__address';
        break;
      case 'Project Address':
        taxRateKey = 'taxRate__project';
        break;
      case 'Plant Address':
        taxRateKey = 'taxRate__location';
        break;
      case 'Tax Exempt':
        taxRateKey = 'taxRate__exempt';
        break;
    }
    return taxRateKey ? (
        <div style={{ display: 'inline-block' }}>{Translate({stringId: taxRateKey, defaultMessage: value})} </div>
    ) : value;
  }

  if (fieldId === 're_activated_ticket') {
    if (reason) {
      return <div style={{ display: 'inline-block' }}>{value.reason}</div>;
    }

    return (
      <div style={{ display: 'inline-block' }}>
        Ticket #{value?.ticketNumber}
        {value?.statusCode && ` -- ${value.statusCode}`}{' '}
      </div>
    );
  }

  if (fieldId === 'startDateTime') {
    return (
      <div style={{ display: 'inline-block' }}>
        <ShortDateTime date={value} />
      </div>
    );
  }

  if(fieldId === 'excludeZoneCharges' && !reason) {
    return value ? (<div style={{ display: 'inline-block' }}>{Translate({stringId: 'True', defaultMessage: 'True'})} </div>) :
        (<div style={{ display: 'inline-block' }}>{Translate({stringId: 'False', defaultMessage: 'False'})} </div>)
  }

  return <div style={{ display: 'inline-block' }}>{value}</div>;
};
const OrderAudit = ({ orderRef, index }) => {
  const [history, setHistory] = React.useState([]);
  const { tab } = useParams();
  const { getOrderHistory, order } = useOrder();
  const translateMessage = useTranslateMessage();
  const fieldName = (fieldId, field) => {
    if (fieldId === 'ticketed_quantity') {
      let label = translateMessage({stringId: 'auditActions.ticketed_quantity', defaultMessage: 'Ticket #% Quantity'});
      return label.replace('%', field.ticketId);
    }

    if(fieldId.includes('Created from order'))
    {
      let copyOrderMessage = translateMessage({stringId: 'auditActions.createdFromOrder', defaultMessage: 'Created from order'});
      let orderNumber = fieldId.split('#')[1]
      return copyOrderMessage + ' #' + orderNumber;
    }

    if (fieldId === 'productsNotOnProject') {
      return translateMessage({
        stringId: 'productsNotOnProject',
        defaultMessage: 'The following products are not defined or priced in the Project: '
        }) + field.products;
    }

    if(fieldId === 'Ticket Created')
    {
      return Translate({stringId: 'auditActions.ticketCreated', defaultMessage: 'Ticket Created'});
    }

    const labels = {
      schedule: Translate({stringId: 'auditActions.schedule', defaultMessage: 'Delivery Schedule'}),
      supplierStatus: Translate({stringId: 'auditActions.supplierStatus', defaultMessage: 'Order Status'}),
      paymentMethodRef: Translate({stringId: 'auditActions.paymentMethodRef', defaultMessage: 'Payment Method'}),
      salesPerson: Translate({stringId: 'auditActions.salesPerson', defaultMessage: 'Sales Person'}),
      salesPersonRef: Translate({stringId: 'auditActions.salesPerson', defaultMessage: 'Sales Person'}),
      shippingMethodRef: Translate({stringId: 'auditActions.shippingMethodRef', defaultMessage: 'Shipping Method'}),
      workTypeRef: Translate({stringId: 'auditActions.workTypeRef', defaultMessage: 'Work Type'}),
      purchaseOrder: Translate({stringId: 'auditActions.purchaseOrder', defaultMessage: 'Purchase Order'}),
      taxRate: Translate({stringId: 'auditActions.taxRate', defaultMessage: 'Tax Rate'}),
      taxRateRef: Translate({stringId: 'auditActions.taxRate', defaultMessage: 'Tax Rate'}),
      vehicleType: Translate({stringId: 'auditActions.vehicleType', defaultMessage: 'Vehicle Type'}),
      startDateTime: Translate({stringId: 'auditActions.startDateTime', defaultMessage: 'Start Date/Time'}),
      phoneNumber: Translate({stringId: 'auditActions.phoneNumber', defaultMessage: 'Phone Number'}),
      address: Translate({stringId: 'auditActions.address', defaultMessage: 'Delivery Address'}),
      added_product: Translate({stringId: 'auditActions.added_product', defaultMessage: 'Added Product'}),
      deleted_product: Translate({stringId: 'auditActions.deleted_product', defaultMessage: 'Deleted Product'}),
      modified_product: Translate({stringId: 'auditActions.modified_product', defaultMessage: 'Modified Product'}),
      products: Translate({stringId: 'auditActions.products', defaultMessage: 'Products'}),
      'product-quantity': Translate({stringId: 'auditActions.product-quantity', defaultMessage: 'Product Quantity'}),
      'product-hold-quantity': Translate({stringId: 'auditActions.product-hold-quantity', defaultMessage: 'Hold Quantity'}),
      location: Translate({stringId: 'auditActions.location', defaultMessage: 'Scheduled Location'}),
      tracking: Translate({stringId: 'auditActions.tracking', defaultMessage: 'Tracking Color'}),
      ticket_supplier_status: Translate({stringId: 'auditActions.ticket_supplier_status', defaultMessage: 'Ticket Supplier Status'}),
      re_activated_ticket: Translate({stringId: 'auditActions.re_activated_ticket', defaultMessage: 'Ticket Re-Activated'}),
      driverInstructions: Translate({stringId: 'auditActions.driverInstructions', defaultMessage: 'Driver Instructions'}),
      costBookRef: Translate({stringId: 'auditActions.costBookRef', defaultMessage: 'Override Price Book'}),
      resolvedCostBookRef: Translate({stringId: 'auditActions.resolvedCostBookRef', defaultMessage: 'Resolved Price Book'}),
      costBookOverride: Translate({stringId: 'auditActions.costBookOverride', defaultMessage: 'Price Book Override'}),
      manualOverride: Translate({stringId: 'auditActions.manualOverride', defaultMessage: 'Manual Price Override'}),
      ticketCreated: Translate({stringId: 'auditActions.ticketCreated', defaultMessage: 'Ticket Created'}),
      excludeZoneCharges: Translate({stringId: 'auditActions.excludeZoneCharges', defaultMessage: 'Exclude Zone Charges'}),
      distance: Translate({stringId: 'auditActions.distance', defaultMessage: 'Distance'}),
    };

    return labels[fieldId] || fieldId;
  };

  useEffect(() => {
    tab === 'audit' && orderRef && getOrderHistory(orderRef).catch(alert);
  }, [tab, orderRef, getOrderHistory]);

  useEffect(() => {
    const byDateDesc = (a, b) => {
      return a.changeDate < b.changeDate ? 1 : -1;
    };

    order && setHistory(order.history?.sort(byDateDesc));
  }, [order]);

  return (
    history.length > 0 && (
      <>
        <OrderNumber>#{index + 1}</OrderNumber>
        <Table>
          <thead>
            <tr>
              <TH width={150}>
                <Translate stringId="date" defaultMessage="Date" />
              </TH>
              <TH>
                <Translate stringId="change" defaultMessage="Change" />
              </TH>
              <TH width={150}>
                <Translate stringId="userName" defaultMessage="User Name" />
              </TH>
              <TH width={200}>
                <Translate stringId="fields" defaultMessage="Fields" />
              </TH>
              <TH>
                <Translate stringId="oldValue" defaultMessage="Old Value" />
              </TH>
              <TH>
                <Translate stringId="newValue" defaultMessage="New Value" />
              </TH>
              <TH>
                <Translate stringId="reason" defaultMessage="Reason" />
              </TH>
            </tr>
          </thead>
          <tbody>
            {history?.map((h, index) => {
              const validFields = h?.fields?.filter(f => f.fieldId);
              return (
                <tr key={`${index}-history`}>
                  <TD>
                    <ShortDateTime date={h.changeDate} />
                  </TD>
                  <TD>{description(h.type)}</TD>
                  <TD>
                    <div title={h.changeBy?.email}>{h.changeBy?.name}</div>
                  </TD>
                  <TD>
                    {validFields?.map((f, key) => (
                      <div key={`${key}-${index}-fieldId`}>{fieldName(f.fieldId, f)}</div>
                    ))}
                  </TD>
                  <TD>
                    {validFields?.map((f, key) => (
                      <div key={`${key}-${index}-oldValue`}>
                        <ValueOf fieldId={f.fieldId} value={f.oldValue} />
                      </div>
                    ))}
                  </TD>
                  <TD>
                    {validFields?.map((f, key) => (
                      <div key={`${key}-${index}-newValue`}>
                        <ValueOf fieldId={f.fieldId} value={f.newValue} />
                      </div>
                    ))}
                  </TD>
                  <TD>
                    {validFields?.map((f, key) => (
                      <div key={`${key}-${index}-reason`}>
                        <ValueOf fieldId={f.fieldId} value={f.reason} reason />
                      </div>
                    ))}
                  </TD>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </>
    )
  );
};

export default OrderAudit;
