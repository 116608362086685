import { css } from 'styled-components';

export default css`
  padding: 0.5px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 8px;
  border-radius: 5px;
  background-color: rgba(128, 128, 128, 0.8);
`;
