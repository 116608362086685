import React from "react";

import styled from 'styled-components';

const SlumpWarning = ({ className, message }) => {
  return (
    <div className={className}>
      <div className={'warning-icon'}>
        <i className="fas fa-exclamation-triangle"></i>
      </div>
      <div className={'warning-text'}>
        {message}
      </div>
    </div>
  )
}

export default styled(SlumpWarning)`
  margin-top: 10px;
  flex-direction: row;
  display: flex;

  .warning-icon {
    flex-direction: column;
    color: #EED202;
    font-size: 1.5rem;
  }
  .warning-text {
    margin-left: 20px;
    flex-direction: column;
  }
  
`;