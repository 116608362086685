import style from './style';
import React from "react";
import {Alert} from "antd";
import styled from 'styled-components';

const Styled = styled.div`
  ${style}
`

const FormError = ({error}) => {
  if(!error) {
    return null;
  }
  return <Styled>
    <Alert showIcon message={error} type="error" />
  </Styled>;
}

export default FormError;