import cn from 'classnames';
import { DateTime } from 'luxon';
import React from 'react';
import styled from 'styled-components';
import Bar from './Bar';
import style from './style';
import nonce from "../../../util/nonce";

const Styled = styled.div`
  ${style}
`;

const GraphBody = ({
  innerRef,
  loads,
  intervals,
  redLine,
  date,
  timeZone,
  setHighlightedOrders,
  ordersDetail,
  showNow,
}) => {
  const [now, setNow] = React.useState(0);

  const maxDemand = intervals[intervals.length - 1];

  React.useEffect(() => {
    const calculateNow = () => {
      const nowDateTime = DateTime.now().setZone(timeZone);
      const hour = nowDateTime.hour;
      const minute = Math.floor(nowDateTime.minute / 5) * 5;

      const newNow = hour * 12 + minute / 5;

      setNow(s => (s === newNow ? s : newNow));
    };

    const interval = setInterval(() => {
      calculateNow();
    }, 5000);

    calculateNow();

    return () => {
      clearInterval(interval);
    };
  }, [timeZone]);

  return (
    <Styled className={cn('graph-body')}>
      {redLine <= maxDemand && (
        <div
          className="red-line"
          style={{
            borderTop: '2px solid #eb5b5b',
            position: 'absolute',
            bottom: `${(redLine / intervals[intervals.length - 1]) * 100}%`,
            height: '2px',
            left: `0`,
            right: `-20px`,
            zIndex: '99',
          }}
        />
      )}

      <div className="interval-line" style={{ height: '1px' }} />
      {intervals.map(interval => {
        return (
          <div className="interval-line" style={{ height: `${(interval / intervals[intervals.length - 1]) * 100}%` }} />
        );
      })}
      <div className="content-and-x-axis">
        <div className="graph-content-wrapper">
          <div className="graph-content" ref={innerRef}>
            {Array.apply(null, Array(288)).map((value, idx) => {
              return (
                <Bar
                  key={nonce()}
                  load={loads.loads[idx]}
                  isNow={showNow && idx === now}
                  redLine={redLine}
                  slot={idx}
                  date={date}
                  maxValue={intervals[intervals.length - 1]}
                  setHighlightedOrders={setHighlightedOrders}
                  onClick={ordersDetail}
                />
              );
            })}
          </div>
        </div>
      </div>
    </Styled>
  );
};

export default GraphBody;
