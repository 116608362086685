import React from 'react';
import http from '../../../../../http';
import { useMapStateContext } from '../context/MapStateContext';
import { useCalculateRoute } from './routing/useCalculateRoute';
import { useFitBounds } from './useFitBounds';
import handleError from "../../handleError";

export const useHandleDestinationMarkerDrag = () => {
  const { waypoints, origin, setEffectiveAddress, setReverseGeocoding, setEffectiveCoordinates } = useMapStateContext();
  const { calculateRoute } = useCalculateRoute();
  const { fitBounds } = useFitBounds();

  const handleDestinationMarkerDrag = React.useCallback(
    async e => {
      const destinationCoordinates = e.latLng.toJSON();

      setEffectiveCoordinates(destinationCoordinates);
      setReverseGeocoding(true);

      // fetch the address for the given destinationCoordinates.
      await http
        .get(
          `/connex/services/orders/geo/address?latitude=${destinationCoordinates.lat}&longitude=${destinationCoordinates.lng}`
        )
        .then(response => {
          setEffectiveAddress(response[0]);
        })
        .catch(e => handleError(e))
        .finally(() => {
          setReverseGeocoding(false);
        });

      const newRoute = await calculateRoute({ waypoints, coordinates: destinationCoordinates });
      fitBounds(origin, destinationCoordinates, newRoute, waypoints);
    },
    [calculateRoute, fitBounds, origin, setEffectiveAddress, setEffectiveCoordinates, setReverseGeocoding, waypoints]
  );

  return { handleDestinationMarkerDrag };
};
