import React, { useContext } from 'react';

const OrderContext = React.createContext();

export const useOrderContext = () => {
  const context = useContext(OrderContext);

  return context;
};

const OrderContextProvider = ({ children, value }) => {
  return <OrderContext.Provider value={value}>{children}</OrderContext.Provider>;
};

export default OrderContextProvider;
