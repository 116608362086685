import React, {useEffect, useState} from 'react';
import { InfoWindow, Marker } from '@react-google-maps/api';
import moment from 'moment';
import markerImg from './map-icons/blue-marker.png';

const CurrentLocationMarker = ({ point }) => {
  const { lat, lng, title } = point;
  const [showInfo, setShowInfo] = useState(false);
  const href = `https://www.google.com/maps/place/${lat},${lng}`;

  useEffect(() => {
    setTimeout(() => {
      setShowInfo(true)
    }, 100);
  },[])

  return (
    <Marker
      icon={markerImg}
      key={`${lat}-${lng}`}
      onClick={() => setShowInfo(!showInfo)}
      position={{
        lat,
        lng,
      }}
    >
      {showInfo && (
        <InfoWindow options={{ disableAutoPan: true }} onCloseClick={() => setShowInfo(!showInfo)}>
          <div style={{ color: 'black' }}>
            <div>{title}</div>
            <div style={{ fontSize: '0.8em', fontStyle: 'italic' }}>Last Reported Location</div>
            <div>
              <a target={'_blank'} href={href}>
                View on Google Maps
              </a>
            </div>
          </div>
        </InfoWindow>
      )}
    </Marker>
  );
};

export default CurrentLocationMarker;
