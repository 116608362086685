import { useCallback, useState } from 'react';

import { api } from '../api/attachments';

const useAttachments = type => {
  const [itemBusy, setItemBusy] = useState(false);
  const [loading, setLoading] = useState(false);

  const [attachments, setAttachments] = useState(null);

  const getAttachments = useCallback(
    async (entityRef, itemRef) => {
      try {
        setLoading(true);
        const response = await api.getAttachments(entityRef, type, itemRef);
        setAttachments(response);
        return response;
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    },
    [type]
  );

  const deleteAttachment = useCallback(
    async (entityRef, itemRef, attachmetRef) => {
      try {
        setItemBusy(true);
        const response = await api.deleteAttachment(entityRef, type, itemRef, attachmetRef);
        return response;
      } catch (error) {
        console.log(error);
      } finally {
        setItemBusy(false);
      }
    },
    [type]
  );

  const uploadAttachment = useCallback(
    async (entityRef, itemRef, body, file) => {
      try {
        setItemBusy(true);
        const response = await api.getAttachmentUploadLink(entityRef, type, itemRef, body);

        const data = {
          method: 'PUT',
          body: file,
          headers: {
            'Content-Type': body.contentType,
          },
          mode: 'cors',
          cache: 'no-cache',
          processData: false,
        };

        await fetch(response.link, data);

        return response;
      } catch (error) {
        console.log(error);
      } finally {
        setItemBusy(false);
      }
    },
    [type]
  );

  return {
    loading,
    itemBusy,
    uploadAttachment,
    attachments,
    getAttachments,
    deleteAttachment,
  };
};

export default useAttachments;
