import cn from 'classnames';
import React, { useState } from 'react';
import { InfoWindow, Marker as GoogleMarker } from '@react-google-maps/api';
import { pick } from 'lodash';
import styled from 'styled-components';
import Geofence from '../default-components/Geofence';

const Styled = styled.div`
  color: #000;
`;

const googleMarkerOptions = [
  'anchorPoint',
  'animation',
  'clickable',
  'crossOnDrag',
  'cursor',
  'draggable',
  'icon',
  'label',
  'map',
  'opacity',
  'optimized',
  'position',
  'shape',
  'title',
  'visible',
  'zIndex',
  'onDragEnd',
  'onDblClick',
];

const BaseMarker = ({ children, onReady, ...props }) => {
  const [showInfo, setShowInfo] = useState(false);

  const toggleInfoWindow = React.useCallback(() => {
    setShowInfo(s => !s);
  }, []);

  const markerOptions = React.useMemo(() => pick(props, googleMarkerOptions), [props]);

  const showInfoWindow = React.useCallback(() => {
    setShowInfo(true);
  }, []);

  const hideInfoWindow = React.useCallback(() => {
    setShowInfo(false);
  }, []);

  React.useEffect(() => {
    onReady?.({ showInfo: showInfoWindow, hideInfo: hideInfoWindow });
  }, [hideInfoWindow, onReady, showInfoWindow]);

  const geofenceProps = React.useMemo(() => {
    const { position, geofenceRadius, onGeofenceChange } = props;

    if (position && geofenceRadius && onGeofenceChange)
      return {
        editable: props.geofenceEditable || true,
        center: position,
        radius: geofenceRadius,
        onGeofenceChange,
      };

    return null;
  }, [props]);

  return (
    <>
      <GoogleMarker {...markerOptions} onClick={children ? toggleInfoWindow : undefined}>
        {showInfo && (
          <InfoWindow position={props.position} options={{ disableAutoPan: true }} onCloseClick={toggleInfoWindow}>
            <Styled className={cn('marker-info')}>{children}</Styled>
          </InfoWindow>
        )}
      </GoogleMarker>
      {geofenceProps && <Geofence {...geofenceProps} />}
    </>
  );
};

export default BaseMarker;
