module.exports = {
  title: 'Dispatch',
  titleStringId: 'dispatch',
  permission: 'dispatchOrders',
  license: 'orders',
  items: [
    {
      id: 'open_drawer',
      type: 'iframe',
      getSrc: entityRef => {
        return `${window.location.origin}/${entityRef}/cx?embedded=true`;
      },
      title: 'List Orders',
      titleStringId: 'listOrders',
    },
    {
      id: 'open_drawer',
      type: 'iframe',
      getSrc: entityRef => {
        return `${window.location.origin}/${entityRef}/cx/new/concrete?embedded=true`;
      },
      title: 'Create Order',
      titleStringId: 'createOrder',
      permission: 'createOrder',
    },
  ],
};
