import { css } from 'styled-components';

export default css`
  .order-source-OTHER {
    border-top: solid 3px #8C8686;
  }

  .order-source-CONNEX {
    border-top: solid 3px #177DDC;
  }
  
  .flex-column {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 0;
    min-width: 0;
  }
  &.order-cards {
    background-color: #000;
    height: 100vh;
    overflow: auto;
    margin-top: 10px;

    .chart-section {
      height: 325px;
      margin: 0 10px;
      background-color: #434343;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 3rem;
      color: #555;
    }
    .group-name {
      margin: 10px 10px 0 10px;
      font-weight: bold;
      background-color: #0e68c2;
      padding-left: 5px;
      border-radius: 3px;
    }
    .card-group {
      //margin-left: 10px;
    }
    .order-card {
      cursor: pointer;
      width: 100%;
      .complete {
        font-size: 3em;
        margin-left: 8px;
        margin-right: 6px;
      }

      :focus-visible {
        outline: none;
      }
      .wrapper {
        background-color: #504848;
        //height: 68px;
        //transition: height 0.2s;
        border-radius: 4px;
        margin: 10px;
        overflow: hidden;
      }
      
      .layout {
        display: flex;
        line-height: 0;
        padding: 10px;
        align-items: center;

        .right {
          line-height: 1rem;
          display: flex;
          padding-left: 0;
          font-size: 0.875rem;
          flex: 1;
          .summary-info {
            flex: 1;
          }
          .buttons {
            display: flex;
            grid-gap: 10px;
            .btn-label {
              margin-left: 10px;
            }
          }
          .status-badge {
            text-align: right;
            padding: 0;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            position: relative;
            .ticket-number {
              margin-bottom: 3px;
            }
            .toggle {
              flex: 1;
              //height: 100%;
              display: flex;
              align-items: flex-end;
              justify-content: flex-end;
              //opacity: 0.5;
              position: absolute;
              bottom: -6px;
              font-size: 1.1rem;
              //color: white;
              width: 50px;
            }
          }
          & > * {
            padding: 0 10px;
          }
          .heading {
            font-weight: bold;
          }
        }
      }
      .detail {
        overflow-y: auto;
      }
    }
  }

  .order-list-collapse {
    margin: 20px 0px 0px 10px;
    //border: 0;
    .order-list-panel-content {
      .card-info {
        margin-left: 20px;
        margin-bottom: 5px;
        .name {
          font-weight: 700;
          font-size: 0.9rem;
        }
        .volume {
          font-weight: 700;
          font-size: 1.1rem;
        }
        .number-of-orders {
          font-weight: 700;
          font-size: 0.9rem;
        }
      }
      .progress-bar {
        width: 100%;
        border: 1px solid #4a4a4a;
        height: 8px;
        border-radius: 4px;
        margin-left: 20px;
        .percentage {
          height: 8px;
          border-radius: 4px;
          &.success {
            background-color: #40947e;
          }

          &.warning {
            background-color: #dfc867;
          }

          &.danger {
            background-color: #c26868;
          }
        }
      }
    }
    .order-list-collapse-panel {
      margin-bottom: 10px;
      overflow: hidden;
      background: #333; //#0e68c2;
      border: 0;
      border-left: 4px solid transparent;

      &.success {
        border-left-color: #40947e;
      }

      &.warning {
        border-left-color: #dfc867;
      }

      &.danger {
        border-left-color: #c26868;
      }

      border-radius: 2px;
      .ant-collapse-content-box {
        padding: 1px;
        background-color: #222;
        border: 1px solid #333; //#0e68c2;
        border-radius: 4px;
        border-top: 0;
      }
    }
  }
`;
