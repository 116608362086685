import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';

const Styled = styled.div`
  cursor: pointer;
  .icon {
    &.not-geocoded {
      color: #fff;
    }
    &.geocoded {
      color: #c16868;
    }
    font-size: 1rem;
  }
`;

const geocodedClassName = 'geocoded fas fa-map-marker-alt';
const notGeocodedClassName = 'not-geocoded fal fa-map';

const Geocoded = ({ onClick }) => (
  <Styled onClick={onClick}>
    <i className={`${geocodedClassName} icon`} />
  </Styled>
);
const NotGeocoded = ({ onClick }) => (
  <Styled onClick={onClick}>
    <i className={`${notGeocodedClassName} icon`} />
  </Styled>
);

const GeoCodeMarker = ({ onClick, geocoded, disabled }) => {
  if (disabled) return null;
  return geocoded ? <Geocoded onClick={onClick} /> : <NotGeocoded onClick={onClick} />;
};

export default GeoCodeMarker;
