import { useState, useRef, useCallback } from 'react';

import { useParams } from 'react-router';
import { Modal } from 'antd';
import styled from 'styled-components';
import { Translate } from '../../../../order/components/localization/Translate';

import style from './style';
import ProjectAttachment from './components/project-attachment';
import Spinner from '../../../../../components/spinner/Spinner';
import useAttachments from '../../../../../hooks/useAttachments';
import { cloneDeep } from 'lodash';
import Button from '../../../../../components/button/Button';

const ACCEPT_TYPE = 'image/x-png,image/jpeg,application/pdf';

const Styled = styled.div`
  ${style}
`;

const ProjectAttachments = ({ project, onChange, isNew, setError }) => {
  const { entityRef } = useParams();

  const [attachments, setAttachments] = useState(project?.attachments);

  const [preview, setPreview] = useState(null);
  const [file, setFile] = useState(null);
  const [selectedAttachment, setSelectedAttachment] = useState(null);

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { uploadAttachment, loading, itemBusy, getAttachments, deleteAttachment } = useAttachments('project');

  const fileInputRef = useRef();
  const uploadButtonRef = useRef();

  const handleUpload = useCallback(() => {
    fileInputRef?.current?.click?.();
    uploadButtonRef?.current?.blur();
  }, []);

  const handleInitiateAttachmentUpload = useCallback(
    event => {
      setError(null);

      const file = event?.target?.files?.[0];
      if (!file) return;

      if (!file.type.startsWith('image/') && !file.type.startsWith('application/pdf')) {
        setError('Incorrect File Type');
        return;
      }

      const reader = new FileReader();
      reader.onload = () => {
        setPreview(reader.result);
        setFile(file);
        event.target.value = null;
      };
      reader.readAsDataURL(file);
    },
    [setError]
  );

  const handleUploadFile = useCallback(async () => {
    await uploadAttachment(
      entityRef,
      project.crn,
      {
        duration: 10,
        name: file.name.split('.')[0],
        contentType: file.type,
      },
      file
    );

    setPreview(null);

    const response = await getAttachments(entityRef, project.crn);

    setAttachments(response);
    onChange('attachments', response);
  }, [entityRef, project.crn, uploadAttachment, file, getAttachments, onChange]);

  const handleInitiateAttachmentDelete = useCallback(attachmentRef => {
    setSelectedAttachment(attachmentRef);
    setShowDeleteModal(true);
  }, []);

  const handleDeleteAttachment = useCallback(async () => {
    await deleteAttachment(entityRef, project.crn, selectedAttachment);

    setShowDeleteModal(false);

    const clonedAttachments = cloneDeep(attachments);

    clonedAttachments.items = clonedAttachments?.items?.filter(a => a?.meta?.crn !== selectedAttachment);

    clonedAttachments.itemCount = clonedAttachments?.items?.length ?? '';

    setAttachments(clonedAttachments);
    onChange('attachments', clonedAttachments);
  }, [selectedAttachment, project.crn, deleteAttachment, entityRef, onChange, attachments]);

  const handleClosePreviewModal = useCallback(() => {
    setPreview(null);
    uploadButtonRef?.current?.blur();
  }, []);

  const handleCloseDeleteModal = useCallback(() => {
    setShowDeleteModal(null);
  }, [setShowDeleteModal]);

  if (isNew)
    return (
      <Styled>
        <div className="new-project-message">Please save the record to view on a Map</div>
      </Styled>
    );

  return (
    <>
      <Modal
        visible={preview !== null}
        closable={false}
        width={1000}
        destroyOnClose={true}
        footer={[
          <Button key="back" onClick={handleClosePreviewModal} disabled={itemBusy}>
            <Translate stringId="cancel" defaultMessage="Cancel" />
          </Button>,
          <Button key="submit" type="primary" onClick={handleUploadFile} disabled={itemBusy} loading={itemBusy}>
            <Translate stringId="upload" defaultMessage="Upload" />
          </Button>,
        ]}
      >
        {(preview || '').startsWith('data:application/pdf') ? (
          <iframe
            title="project-attachment-preview"
            src={preview}
            className="file-preview"
            style={{ width: '100%', height: '600px' }}
          />
        ) : (
          <img src={preview} alt="Preview" style={{ width: '100%' }} />
        )}
      </Modal>

      <Modal
        visible={showDeleteModal}
        closable={false}
        destroyOnClose={true}
        footer={[
          <Button key="back" onClick={handleCloseDeleteModal} disabled={itemBusy}>
            <Translate stringId="cancel" defaultMessage="Cancel" />
          </Button>,
          <Button key="delete" type="primary" onClick={handleDeleteAttachment} disabled={itemBusy} loading={itemBusy}>
            <Translate stringId="delete" defaultMessage="Delete" />
          </Button>,
        ]}
      >
        <p>
          <Translate
            stringId="confirmDeleteAttachment"
            defaultMessage="Are you sure you want to delete the attachment?"
          />
        </p>
      </Modal>

      <Styled>
        <Spinner spin={loading}>
          <div className="upload-button-container ">
            <Button innerRef={uploadButtonRef} onClick={handleUpload}>
              <i className="upload-icon font-lg fa fa-upload" />
              <Translate stringId="clickToUpload" defaultMessage="Click to Upload" />
            </Button>
          </div>
          <input
            hidden
            accept={ACCEPT_TYPE}
            autoComplete="off"
            type="file"
            multiple={false}
            ref={fileInputRef}
            onChange={handleInitiateAttachmentUpload}
          />
          {attachments &&
            attachments?.items?.map((attachment, index) => (
              <ProjectAttachment
                key={attachment?.meta?.crn}
                attachmentCrn={attachment?.meta?.crn}
                contentType={attachment?.meta?.contentType}
                createDate={attachment?.meta?.createDate}
                link={attachment.link}
                index={index}
                onDelete={handleInitiateAttachmentDelete}
              />
            ))}
        </Spinner>
      </Styled>
    </>
  );
};

export default ProjectAttachments;
