import React from 'react';
import { DateTime } from 'luxon';
import { useParams } from 'react-router-dom';
import { useMapStateContext } from '../../context/MapStateContext';
import _calculateRoute from './calculateRoute';

export const useCalculateRoute = () => {
  const { entityRef } = useParams();
  const { origin, departureTime, effectiveCoordinates, setCalculatingRoute, setDisabled, setRoute, setDestination } =
    useMapStateContext();

  const calculateRoute = React.useCallback(
    ({ waypoints, coordinates = effectiveCoordinates }) => {
      return _calculateRoute({
        origin,
        departureTime: departureTime || DateTime.now().plus({ minutes: 5 }).toUTC(),
        setCalculatingRoute,
        setDisabled,
        setRoute,
        setDestination,
        waypoints,
        coordinates,
        entityRef,
      });
    },
    [departureTime, effectiveCoordinates, entityRef, origin, setCalculatingRoute, setDestination, setDisabled, setRoute]
  );

  return { calculateRoute };
};
