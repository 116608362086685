import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';

const Styled = styled.div`
  ${({ $all, $right, $left, $top, $bottom }) => {
    if ($all) {
      return `margin: ${$all}px;`;
    }
    return `
      ${$right ? `margin-right: ${$right}px;` : ``} 
      ${$left ? `margin-left: ${$left}px;` : ``} 
      ${$top ? `margin-top: ${$top}px;` : ``} 
      ${$bottom ? `margin-bottom: ${$bottom}px;` : ``}       
    `;
  }}
`;

const Margin = ({ top, bottom, right, left, all, children, ...props }) => {
  return (
    <Styled className={cn('margin')} $all={all} $top={top} $bottom={bottom} $right={right} $left={left} {...props}>
      {children}
    </Styled>
  );
};

export default Margin;
