import { DateTime } from 'luxon';
import getUomAbbreviationOrDescription from '../../../../../util/uom';

export default (order, lineItem, timeZone) => {
  const {
    vehicleType = {
      id: 'R',
    },
  } = order;
  const { id: vehicleTypeId, name: vehicleTypeName } = vehicleType;

  const {
    item: { id: productId, name: productName },
    orderedQuantity: { value: orderQuantity, uomCode: orderUomCode },
    properties,
  } = lineItem;

  const result = {
    status: order?.supplierStatus?.name,
    orderQuantity,
    orderUomCode: getUomAbbreviationOrDescription(orderUomCode),
    productId,
    productName,
    vehicleTypeId,
    vehicleTypeName,
    slump: properties?.slump,
    startDateTime: DateTime.fromISO(order?.deliverySchedule?.startDateTime)
      .setZone(timeZone)
      .toFormat('MM/dd hh:mm a (ZZZZ)'),
  };

  return result;
};
