import { find, pick } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import Select from '../../../components/form/Select';
import Margin from '../components/Margin';
import { useScaleTicketingContext } from '../Context';

const { Option } = Select;

const Styled = styled(Margin)``;

const Product = () => {
  const { bulkProducts, form, handleChange } = useScaleTicketingContext();

  const handleProductChange = React.useCallback(
    ref => {
      const targetProduct = find(bulkProducts, { productRef: ref });
      if (targetProduct) {
        handleChange('vehicle', s => ({
          ...s,
          configuration: {
            ...s.configuration,
            product: {
              ...pick(targetProduct, ['id', 'name']),
              productRef: targetProduct.productRef,
            },
          },
        }));
      }
    },
    [handleChange, bulkProducts]
  );

  return (
    <Margin top={10}>
      <div className="field-label">Product</div>
      <Select
        value={form?.vehicle?.configuration?.product?.productRef || ''}
        showSearch
        filterOption={(input, option) => option?.children?.toLowerCase?.().indexOf(input.toLowerCase?.()) >= 0}
        disabled={false}
        onChange={handleProductChange}
      >
        {bulkProducts?.map(product => (
          <Option key={product?.productRef} value={product.productRef}>
            {`${product.id} / ${product.name}`}
          </Option>
        ))}
      </Select>
    </Margin>
  );
};

export default Product;
