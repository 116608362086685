import {css} from 'styled-components';

export default css`
width: 100%;
margin-top: 25px;

table { width: 100%; }
td { width: 50% }

.checkbox-container { margin-left: 5px; }
`;
