import { Drawer } from 'antd';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { useViewport } from '../../../../../hooks/useViewport';
import { prototype } from '../../../../setup/setup-customers/setup';

const Styled = styled.div`
  display: flex;
  flex: 1;
  .add-new {
    background-color: transparent;
    display: flex;
    align-items: center;
    border: 1px solid #444;
    border-left-width: 0;
    padding: 5px;
    flex: 1;
    &:focus-visible {
      border-left-width: 1px;
      outline: 1px solid #177ddc;
    }
    &.disabled {
      background-color: #141414;
      .icon {
        color: #777;
      }
    }

    .icon {
      margin: 0 5px;
      font-size: 1rem;
      color: rgba(255, 255, 255, 0.85);
    }
    &:hover:not(.disabled) {
      background-color: #313131;
      cursor: pointer;
    }
  }
`;

const AddItem = ({
  type,
  Editor,
  onSave,
  list,
  selfRef,
  editorIsOpen,
  toggleEditor,
  nextRef,
  disabled,
  dependencyRef = null,
  itemCrn,
}) => {
  const handleSave = React.useCallback(
    (ignore, response) => {
      onSave(response);
      toggleEditor();
      nextRef?.current?.focus?.();
    },
    [nextRef, onSave, toggleEditor]
  );

  const viewport = useViewport();

  const drawerWidth = useMemo(() => {
    return Math.min(600, viewport.width);
  }, [viewport]);

  const handleCancel = React.useCallback(() => {
    toggleEditor();
    selfRef?.current?.focus?.();
  }, [selfRef, toggleEditor]);

  const handleAddClick = React.useCallback(() => {
    if (!disabled) toggleEditor();
  }, [disabled, toggleEditor]);

  const item = type === 'Project' ? { ...prototype, customerParty: { entityRef: dependencyRef } } : { ...prototype };

  return (
    <Styled className={cn(`add-${type}`)}>
      <button className={cn('add-new', { disabled })} onClick={handleAddClick}>
        <i className="icon fal fa-plus" />
      </button>
      <Drawer
        width={drawerWidth}
        title={`${type} Editor`}
        placement="right"
        closable
        onClose={handleCancel}
        visible={editorIsOpen}
        destroyOnClose
      >
        <Editor item={item} itemCrn={itemCrn} onSave={handleSave} onCancel={handleCancel} list={list} saveOnEnter />
      </Drawer>
    </Styled>
  );
};

export default AddItem;
