import style from "./style";
import React, {useEffect} from "react";
import styled from 'styled-components';
import useSetup from "../../../useSetup";
import {useParams} from "react-router";
import Spinner from "../../../../../components/spinner/Spinner";

const View = styled.div`${style}`;

const CustomerProjects = ({ customer, onChange }) => {
  const { entityRef } = useParams();
  const { getCustomerProjects, projects, busy } = useSetup();

  useEffect(() => {
    entityRef && customer?.crn && getCustomerProjects(entityRef, customer.crn)
  }, [customer, entityRef])

  return (
    <View>
      <Spinner spin={busy}>
        <table>
          <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>P.O. Number</th>
            <th>Status</th>
          </tr>
          </thead>
          <tbody>
          {projects?.map(p => (
            <tr key={p.crn}>
              <td>{p.id}</td>
              <td>{p.name}</td>
              <td>{p.purchaseOrder || ''}</td>
              <td>{p.status}</td>
            </tr>
          ))}
          </tbody>
        </table>
      </Spinner>
    </View>
  )
}

export default CustomerProjects;
