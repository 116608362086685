import React from 'react';
import {useDrag} from 'react-dnd';
import VehicleToken from '../vehicle-token';

const VehicleDragToken = (props) => {
  const [collected, drag, dragPreview] = useDrag(() => ({
    type: 'vehicle',
    item: props.vehicle,
  }))

  return collected.isDragging ? <div ref={dragPreview} /> :
    <VehicleToken innerRef={drag} {...collected} {...props} />;

}

export default VehicleDragToken;