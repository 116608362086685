import { Button as AntButton } from 'antd';
import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { callWithMetrics } from '../../util/analytics';
import style from './style';
import isReactElement from '../../util/other/is-react-element';

const Button = ({ metricId, onClick, innerRef, children, ...props }) => {
  let clickHandler = onClick;

  const { entityRef } = useParams();

  if (metricId) {
    const metric = { type: 'user-interaction', data: { type: 'button-click', id: metricId } };
    clickHandler = callWithMetrics(onClick, metric, entityRef);
  }

  const dataTestId = React.useMemo(() => {
    if (props['data-testid']) {
      return props['data-testid'];
    }

    return metricId?.startsWith('core-') ? metricId.slice(5) : metricId;
  }, [props, metricId]);

  return (
    <AntButton
      {...props}
      onClick={clickHandler}
      ref={innerRef}
      data-testid={dataTestId}
      children={isReactElement ? <span>{children}</span> : children} //We add the span to fix a antd issue when children is a react component
    />
  );
};

export default styled(Button)`
  ${style}
`;
