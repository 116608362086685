import React, { useEffect } from 'react';
import styled from 'styled-components';
import { FILTER_STRATEGY } from '../../../components/connex-data-grid/util';
import FlexColumn from '../../order/components/FlexColumn';
import useSetup from '../useSetup';
import { useParams } from 'react-router';
import style from './style';
import SetupTable from '../components/setup-table';
import ScaleEditor from './scale-editor';

const prototype = {
  id: '',
  name: '',
  status: 'ACTIVE',
};

const columns = [
  {
    key: 'id',
    name: 'ID',
  },
  {
    key: 'name',
    name: 'Name',
  },
  {
    key: 'location.name',
    name: 'Location',
  },
  {
    key: 'direction',
    name: 'Direction',
    filterStrategy: FILTER_STRATEGY.EQUALS,
    filterComponent: ({ innerRef, ...props }) => {
      return (
        <select ref={innerRef} {...props}>
          <option value="" />
          <option value="BOTH">Both</option>
          <option value="INBOUND">Inbound</option>
          <option value="OUTBOUND">Outbound</option>
        </select>
      );
    },
  },
  {
    key: 'status',
    name: 'Status',
    filterStrategy: FILTER_STRATEGY.EQUALS,
    filterComponent: ({ innerRef, ...props }) => {
      return (
        <select ref={innerRef} {...props}>
          <option value="" />
          <option value="ACTIVE">Active</option>
          <option value="INACTIVE">Inactive</option>
        </select>
      );
    },
  },
];

const Styled = styled(FlexColumn)`
  ${style}
`;

const SetupScales = () => {
  const { entityRef } = useParams();
  const { getSetupItems, setupItems, busy } = useSetup('scale');
  const params = { activeOnly: false };

  useEffect(() => {
    entityRef && getSetupItems(params);
  }, [entityRef, getSetupItems]);

  return (
    <Styled>
      <SetupTable
        busy={busy}
        type="Scale"
        columns={columns}
        items={setupItems}
        Editor={ScaleEditor}
        prototype={prototype}
        onRefresh={() => getSetupItems(params)}
      />
    </Styled>
  );
};

export default SetupScales;
