import React from 'react';
import styled from 'styled-components';
import Button from '../../../../components/button/Button';
import { useVersionContext } from '../../version/VersionCheck';

const StyledButton = styled(Button)`
  background-color: #DFC867;
  color: #434343;
  border: none;
  &:focus,
  &:active,
  &:hover {
    background-color: #BFA867;
    color: #000;
  }
`;

const NewVersionButton = () => {
  const {
    version: { newVersionAvailable, versionModalVisible, unhideVersionModal },
  } = useVersionContext();

  return !newVersionAvailable || versionModalVisible ? null : (
    <StyledButton title="New version available" size="small" onClick={unhideVersionModal}>
      <i className="fal fa-sparkles" />
    </StyledButton>
  );
};

export default NewVersionButton;
