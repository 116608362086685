import { useCallback, useMemo, useRef } from 'react';
import InputNumberBlur from '../../../components/input-number-blur';
import styled from 'styled-components';
import style from './style';
import ChargeRow from './components/charge-row/index.js';
import Button from '../../../../../components/button/Button';
import { isNil } from 'lodash';
import ChargeWarning from './components/charge-warning';
import checkIfProducthasLoadCharges from '../utils/check-if-product-has-load-charges';

const Styled = styled.div`
  ${style}
`;

const ProductMinimumLoadCharge = ({ minimumLoadChargeData, onChange }) => {
  const clearAndSortChargesBtnRef = useRef(null);
  const addChargeRowBtnRef = useRef(null);

  const updateMinimumLoadChargeData = useCallback(
    (id, value) => {
      const tempMinimumLoadChargeData = minimumLoadChargeData ? { ...minimumLoadChargeData } : {};

      tempMinimumLoadChargeData[id] = value;

      onChange('minimumLoadChargeData', tempMinimumLoadChargeData);
    },
    [minimumLoadChargeData, onChange]
  );

  const addChargeRow = useCallback(() => {
    const tempCharges = minimumLoadChargeData?.charges || [];
    tempCharges.push({ loadSize: null, charge: null });
    updateMinimumLoadChargeData('charges', [...tempCharges]);
    addChargeRowBtnRef.current.blur();
  }, [minimumLoadChargeData, updateMinimumLoadChargeData]);

  const deleteChargeRow = useCallback(
    index => {
      const tempCharges = minimumLoadChargeData?.charges;
      tempCharges.splice(index, 1);
      updateMinimumLoadChargeData('charges', [...tempCharges]);
    },
    [minimumLoadChargeData, updateMinimumLoadChargeData]
  );

  const updateChargeRow = useCallback(
    (index, key, value) => {
      const tempCharges = minimumLoadChargeData?.charges;
      tempCharges[index][key] = value;
      updateMinimumLoadChargeData('charges', [...tempCharges]);
    },
    [minimumLoadChargeData, updateMinimumLoadChargeData]
  );

  const clearAndSortCharges = useCallback(() => {
    if (!minimumLoadChargeData?.charges) return;

    const loadSizeSet = new Set();

    const tempCharges = [];

    minimumLoadChargeData?.charges.forEach(c => {
      if (isNil(c.loadSize) || isNil(c.charge) || loadSizeSet.has(c?.loadSize)) return;

      loadSizeSet.add(c?.loadSize);
      tempCharges.push(c);
    });

    updateMinimumLoadChargeData(
      'charges',
      tempCharges.sort((a, b) => a.loadSize - b.loadSize)
    );

    clearAndSortChargesBtnRef.current.blur();
  }, [minimumLoadChargeData, updateMinimumLoadChargeData]);

  const chargeWarning = useMemo(() => {
    let showWarning = false;

    const loadSizeSet = new Set();
    minimumLoadChargeData?.charges?.forEach(c => {
      if (isNil(c?.loadSize)) return;
      if (loadSizeSet.has(c?.loadSize)) {
        showWarning = true;
      } else {
        loadSizeSet.add(c?.loadSize);
      }
    });

    if (!!minimumLoadChargeData?.charges?.find(c => isNil(c?.loadSize) || isNil(c?.charge))) {
      showWarning = true;
    }

    return showWarning
      ? 'Charge rows with empty values or duplicated load size rows are going to be deleted when saving'
      : null;
  }, [minimumLoadChargeData]);

  const emptyWarning = useMemo(() => {
    return checkIfProducthasLoadCharges(true, minimumLoadChargeData) ? null : 'Charges table must have a valid row';
  }, [minimumLoadChargeData]);

  return (
    <Styled>
      <h4 className="section-text">Exempt Loads</h4>
      <InputNumberBlur
        id="minimumQuantity"
        label="Minimum Quantity"
        value={minimumLoadChargeData?.minimumQuantity ?? ''}
        onChange={updateMinimumLoadChargeData}
        useDefaultOnChangeHandler={true}
      />
      <InputNumberBlur
        id="priceExemptLoads"
        label="Price Exempt Loads"
        value={minimumLoadChargeData?.priceExemptLoads ?? ''}
        onChange={updateMinimumLoadChargeData}
        useDefaultOnChangeHandler={true}
      />
      <h4 className="section-text">Custom Rate Schedule</h4>
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th style={{ width: '45%' }}>Load Size</th>
              <th style={{ width: '45%' }}>Charge</th>
              <th style={{ width: '10%' }}></th>
            </tr>
          </thead>
          <tbody>
            {minimumLoadChargeData?.charges?.map((charge, index) => (
              <ChargeRow
                key={index}
                index={index}
                chargeValues={charge}
                onChange={updateChargeRow}
                onDelete={deleteChargeRow}
              />
            ))}
          </tbody>
        </table>
      </div>
      <div className="buttons-container">
        <Button innerRef={clearAndSortChargesBtnRef} onClick={clearAndSortCharges}>
          Clear and Sort
        </Button>
        <Button innerRef={addChargeRowBtnRef} onClick={addChargeRow}>
          Add Charge Row
        </Button>
      </div>
      {chargeWarning && <ChargeWarning message={chargeWarning} />}
      {emptyWarning && <ChargeWarning message={emptyWarning} />}
    </Styled>
  );
};

export default ProductMinimumLoadCharge;
