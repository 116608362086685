import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
import Button from '../../../components/button/Button';

const Styled = styled.div``;
const CheckOut = props => {
  return (
    <Styled className={cn('check-out')}>
      <Button size="large">Check Out</Button>
    </Styled>
  );
};

export default CheckOut;
