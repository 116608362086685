import { DateTime } from 'luxon';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { BarGraph } from '../../components/bar-graph';
import useCalculateLoads from '../../components/bar-graph/use-calculate-loads';
import Controls from '../../components/bar-graph/Controls';
import { usePlanning } from '../../components/bar-graph/usePlanning';
import Spinner from '../../components/spinner/Spinner';
import { cacheItem, getCachedItem, clearCachedItem } from '../../util/cache';
import style from './style';
import { useParams } from 'react-router';
import { message } from 'antd';

const Styled = styled.div`
  ${style}
`;

const Planning = () => {
  const { entityRef } = useParams();

  const key = useMemo(() => {
    return `planning-locations-${entityRef || ''}`;
  }, [entityRef]);

  const getStoredLocations = useCallback(() => {
    const option = localStorage.getItem(key);
    if (option) {
      return JSON.parse(option);
    }
    return ['ALL'];
  }, [key]);

  const { getDemand, demand, loading } = usePlanning();
  const [startTime, setStartTime] = React.useState();
  const [startDate, setStartDate] = useState(DateTime.now().toFormat('yyyy-MM-dd'));
  const [location, setLocation] = useState(getStoredLocations());

  const storeSelectedLocations = useCallback(() => {
    localStorage.setItem(key, JSON.stringify(location));
    message.success({
      content: 'Locations Saved',
      style: { marginTop: '20px' },
      duration: 1.5,
    });
  }, [key, location]);

  const [planningStatuses, setPlanningStatuses] = useState({
    startStatus: 'PRINTED',
    endStatus: 'IN_YARD',
  });

  const calculatedLoads = useCalculateLoads(
    demand,
    startTime,
    null,
    null,
    planningStatuses.startStatus,
    planningStatuses.endStatus
  );

  useEffect(() => {
    startDate && location && getDemand(startDate, location);
  }, [startDate, getDemand, location]);

  /*
  const handleDateChange = React.useCallback(
    date => {
      getDemand(date, location);
      setStartDate(date);
    },
    [getDemand, location]
  );
  */

  useEffect(() => {
    if (demand) {
      const time = DateTime.fromFormat(startDate, 'yyyy-MM-dd')
        .toUTC()
        .startOf('day')
        .setZone(demand.timeZone, { keepLocalTime: true });

      setStartTime(time);
    }
  }, [demand, startDate]);

  useEffect(() => {
    if (calculatedLoads) {
      const loads = calculatedLoads?.reduce?.(
        (acc, load) => {
          acc.loads[load.offset / 5] = load;
          acc.max = Math.max(acc.max, load.trucks);
          return acc;
        },
        { max: 0, loads: {} }
      );
      setLoads(loads);
    }
  }, [calculatedLoads]);

  const [loads, setLoads] = React.useState([]);

  const getInitialValue = () => {
    const value = getCachedItem('maxTrucksOverride');
    if (value) {
      return JSON.parse(value);
    }
    return 0;
  };

  const [maxTrucksOverride, setMaxTrucksOverride] = React.useState(getInitialValue());

  const handleResetMaxTrucksOverride = React.useCallback(() => {
    setMaxTrucksOverride('');
    clearCachedItem('maxTrucksOverride');
  }, []);

  const handleMaxTrucksOverride = React.useCallback(value => {
    if (value) {
      cacheItem('maxTrucksOverride', value);
      setMaxTrucksOverride(value);
    }
  }, []);

  const handleLocationsChange = React.useCallback(
    val => {
      setLocation(val);
      handleMaxTrucksOverride();
    },
    [handleMaxTrucksOverride, setLocation]
  );

  const onChangeGraphType = useCallback(
    value => {
      if (value === 'loading') {
        setPlanningStatuses({
          startStatus: 'LOADING_STARTED',
          endStatus: 'TO_JOB',
        });
      } else {
        setPlanningStatuses({
          startStatus: 'PRINTED',
          endStatus: 'IN_YARD',
        });
      }
    },
    [setPlanningStatuses]
  );

  return (
    <Styled className={cn('planning')}>
      <Spinner spin={!loads?.loads}>
        <BarGraph
          demand={demand}
          handleMaxTrucksOverride={handleMaxTrucksOverride}
          handleResetMaxTrucksOverride={handleResetMaxTrucksOverride}
          loading={loading}
          loads={loads}
          maxTrucksOverride={maxTrucksOverride}
          startTime={startTime}
        />
        <Controls
          onChangeGraphType={onChangeGraphType}
          onChangeDate={setStartDate}
          onRefresh={getDemand}
          date={startDate}
          onChangeLocation={handleLocationsChange}
          location={location}
          locations={demand?.locations}
          loading={loading}
          storeSelectedLocations={storeSelectedLocations}
        />
      </Spinner>
    </Styled>
  );
};

export default Planning;
