import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
import FieldContainer from '../../../../components/field-container/FieldContainer';
import ColorPicker from '../../../tracking/dashboard/components/color-picker';

const FormColorPicker = ({ className, onChange, fieldName, fieldLabel, disabled, required, value, readOnly }) => {
  const handleChange = React.useCallback(
    val => {
      onChange(fieldName, val.hex);
    },
    [fieldName, onChange]
  );

  return (
    <FieldContainer className={cn(fieldName, 'field-container', className, { disabled })}>
      <div className="field-section">
        <div className="field-wrapper">
          <ColorPicker onChange={handleChange} color={value} readOnly={readOnly}/>
        </div>
      </div>

      <div className="label-spinner">
        <div className="label">
          {fieldLabel}
          {required && <span className="required-indicator">*</span>}
        </div>
      </div>
    </FieldContainer>
  );
};

export default styled(FormColorPicker)`
  display: flex;
  align-items: center;
  .field-section {
    display: flex;
    grid-gap: 1px;

    .field-wrapper {
      flex: 1;
    }
  }

  .color-swatch {
    cursor: pointer;
  }

  &.disabled {
    .color-swatch {
      cursor: not-allowed;
    }
    .label-spinner {
      .label {
        color: #757575;
      }
    }
  }
`;
