import { Button, Dropdown, Menu } from 'antd';
import React, { useCallback, useMemo } from 'react';
import { useMenuContext } from './useMenuContext';
import styled from 'styled-components';
import cn from 'classnames';
import { useDashboardContext } from '../context/useDashboardContext';
import { usePermissions } from '../../../order/components/usePermissions';
import { buttonClick } from '../../../../util/analytics';
import { kebabCase } from 'lodash';

const { SubMenu } = Menu;

const MenuDropdown = ({ className, menu }) => {
  const { onAction } = useMenuContext();
  const { licenses } = useDashboardContext();
  const { has } = usePermissions();
  const menuOverlay = useMemo(() => {
    return (
      <Menu>
        {menu?.items?.map((value, key) => {
          if (value.license && !licenses[value.license]) {
            return null;
          }

          if (value.permission && !has(value.permission, true)) {
            return null;
          }

          if (value?.items) {
            return (
              <SubMenu title={value.title} key={key}>
                {value?.items?.map((subMenuItem, itemKey) => {
                  const handleSubMenuClick = () => {
                    if (subMenuItem) {
                      onAction(subMenuItem);
                    }
                  };
                  return (
                    <Menu.Item
                      key={itemKey}
                      onClick={() => {
                        buttonClick(handleSubMenuClick, `core-tracking-${kebabCase(subMenuItem.title)}`);
                      }}
                    >
                      {subMenuItem.translatedTitle}
                    </Menu.Item>
                  );
                })}
              </SubMenu>
            );
          }
          const handleMenuClick = () => {
            if (value) {
              onAction(value);
            }
          };
          return (
            <Menu.Item
              key={key}
              onClick={() => {
                buttonClick(handleMenuClick, `core-tracking-${kebabCase(value.title)}`);
              }}
            >
              {value.translatedTitle}
            </Menu.Item>
          );
        })}
      </Menu>
    );
  }, [menu, onAction]);

  if (menu?.items) {
    return (
      <div className={cn(className)}>
        <Dropdown overlay={menuOverlay}>
          <Button>
            {menu.translatedTitle} <span className={'fa fa-chevron-down'} style={{ marginLeft: '0.5rem' }} />
          </Button>
        </Dropdown>
      </div>
    );
  }

  const handleClick = () => {
    onAction(menu);
  };
  return (
    <div className={cn(className)}>
      <Button
        onClick={() => {
          buttonClick(handleClick, `core-tracking-${kebabCase(menu.title)}`);
        }}
      >
        {menu.translatedTitle}
      </Button>
    </div>
  );
};

export default styled(MenuDropdown)`
  margin-right: 0.5rem;
  .ant-btn {
    color: white;
    background: #111;
    height: 100%;
    margin-bottom: 2px;
  }
`;
