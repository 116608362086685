import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
import { Translate } from '../../views/order/components/localization/Translate';

const Styled = styled.div`
  margin-left: 60px;
  height: 20px;
  font-family: monospace;
`;

const HighlightedOrders = ({ highlightedOrders }) => {
  return (
    <Styled className={cn('highlighted-orders')}>
      {highlightedOrders?.length ? (
        <Translate
          stringId="orderList"
          defaultMessage="Orders: {orderList}"
          values={{ orderList: highlightedOrders?.sort?.()?.join?.(', ') }}
        />
      ) : (
        ''
      )}
    </Styled>
  );
};

export default HighlightedOrders;
