import React, { useRef } from 'react';
import cn from 'classnames';
import styled from 'styled-components';

const Time = ({ className, value, onChange, disabled, onFocus }) => {
  const dateRef = useRef();

  React.useEffect(() => {
    dateRef?.current?.focus?.();
  }, []);

  const handleTimeChange = React.useCallback(
    e => {
      onChange(e.target.value);
    },
    [onChange]
  );

  return (
    <div className={cn('time', className)}>
      <div className="time-part">
        <input type="time" value={value} onChange={handleTimeChange} disabled={disabled} onFocus={onFocus} />
      </div>
    </div>
  );
};

export default styled(Time)`
  input {
    &:focus {
      border-color: #217fd9;
    }
    &:disabled {
      color: #4c4c4c;
    }
  }
`;
