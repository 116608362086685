import React, { useCallback, useEffect } from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';
import Button from '../../../../../components/button/Button';
import Buttons from '../../../../../components/layout/Buttons';
import Spinner from '../../../../../components/spinner/Spinner';
import useSetup from '../../../useSetup';
import style from './style';
import ContactListEditor from '../../../components/contact-list-editor/ContactListEditor';
import { v4 as uuid } from 'uuid';

const View = styled.div`
  ${style}
`;

const ButtonBar = ({ onCancel, onSave, busy, validationErrors }) => {
  return (
    <Buttons>
      <Button
        id="btn-cancel"
        disabled={busy || validationErrors}
        onClick={onCancel}
        metricId="core-setup-customer-editor-cancel"
      >
        Cancel
      </Button>
      <Button
        id="btn-save"
        loading={busy}
        disabled={busy || validationErrors}
        onClick={onSave}
        metricId="core-setup-customer-editor-save"
      >
        Save
      </Button>
    </Buttons>
  );
};

const CustomerContactsEditor = ({ itemCrn, onSave, onCancel, saveOnEnter }) => {
  const { getCustomer, updateCustomer, customer, setCustomer, busy } = useSetup();
  const { entityRef } = useParams();

  useEffect(() => {
    if (itemCrn) {
      getCustomer(entityRef, itemCrn);
    }
  }, [getCustomer, entityRef, itemCrn]);

  const onChange = useCallback(
    (id, value) => {
      setCustomer(existingCustomer => ({
        ...existingCustomer,
        [id]: value,
      }));
    },
    [setCustomer]
  );

  const onSaveCustomerContacts = React.useCallback(() => {
    if (customer.crn) {
      const fixedCustomer = { ...customer };

      fixedCustomer?.contacts?.forEach(contact => {
        if (!contact.id) contact.id = uuid();
      });

      updateCustomer(entityRef, fixedCustomer.crn, fixedCustomer).then(() => {
        onSave(true, fixedCustomer.contacts);
      });
    }
  }, [customer, entityRef, onSave, updateCustomer]);

  const handleEnterKey = React.useCallback(
    e => {
      if (saveOnEnter && customer?.id && customer?.name && e.key === 'Enter') {
        onSaveCustomerContacts();
      }
    },
    [customer?.id, customer?.name, onSaveCustomerContacts, saveOnEnter]
  );

  return (
    <View onKeyDownCapture={handleEnterKey}>
      <Spinner spin={!customer && busy}>
        {customer && <ContactListEditor item={customer} onChange={onChange} typeId="customer-editor" />}
        <ButtonBar onSave={onSaveCustomerContacts} onCancel={onCancel} busy={busy} />
      </Spinner>
    </View>
  );
};

export default CustomerContactsEditor;
