import cn from 'classnames';
import { DateTime } from 'luxon';
import React from 'react';
import styled from 'styled-components';
import Button from '../../../../../../components/button/Button';
import { Translate } from '../../../../components/localization/Translate';

const Styled = styled(Button)``;

const getFuture = () => {
  const tomorrow = DateTime.now().setZone('America/Chicago', { keepLocalTime: true }).plus({ days: 1 });
  return {
    firstStartDateTime: tomorrow.startOf('day').toUTC().toISO(),
    lastStartDateTime: tomorrow.plus({ days: 30 }).startOf('day').toUTC().toISO(),
  };
};

const FutureButton = ({ onChange, currentDate }) => {
  // TODO: get time zone
  const timezone = 'America/Chicago';

  const handleClick = React.useCallback(() => {
    const futureRange = getFuture(timezone);
    onChange(futureRange);
  }, [onChange]);

  const isFuture = React.useMemo(() => {
    if (!currentDate) {
      return false;
    }
    const firstDate = DateTime.fromISO(currentDate.firstStartDateTime).plus({ days: 30 });
    const lastDate = DateTime.fromISO(currentDate.lastStartDateTime);
    return firstDate.day === lastDate.day && firstDate.month === lastDate.month && firstDate.year === lastDate.year;
  }, [currentDate]);

  return (
    <Styled
      className={cn('future-button')}
      onClick={handleClick}
      type={isFuture ? 'primary' : undefined}
      metricId="core-order-list-filter-future"
      data-testid="button-future"
    >
      <Translate stringId="future" defaultMessage="Future" />
    </Styled>
  );
};

export default FutureButton;
