import style from './style';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import Button from '../../../../components/button/Button';
import useSetup from '../../useSetup';
import Buttons from '../../../../components/layout/Buttons';
import Spinner from '../../../../components/spinner/Spinner';
import InputText from '../../components/input-text';
import InputSelect from '../../components/input-select';
import FormError from '../../components/form-error';
import { cancelReasons, status } from './options';

const ButtonBar = ({ onCancel, onSave, busy }) => {
  return (
    <Buttons>
      <Button id="btn-cancel" disabled={busy} onClick={onCancel} metricId="core-setup-vehicle-editor-cancel">
        Cancel
      </Button>
      <Button id="btn-save" loading={busy} disabled={busy} onClick={onSave} metricId="core-setup-vehicle-editor-save">
        Save
      </Button>
    </Buttons>
  );
};

const View = styled.div`
  ${style}
`;

const CancelReasonEditor = ({ item, onSave, onCancel }) => {
  const { saveCancelReason, setupItem, getSetupItem, setSetupItem, itemBusy, error } = useSetup('cancel-reason-code');

  useEffect(() => {
    if (item) {
      if (item.crn) {
        getSetupItem(item.crn);
      } else {
        setSetupItem(item);
      }
    }
  }, [getSetupItem, item, setSetupItem]);

  const onChange = React.useCallback(
    (id, value) => {
      setSetupItem(s => ({
        ...s,
        [id]: value,
      }));
    },
    [setSetupItem]
  );

  const onSaveItem = () => {
    saveCancelReason()
      .then(() => onSave(true))
      .catch(() => {});
  };
  return (
    <View>
      <FormError error={error} />
      <Spinner spin={!setupItem && itemBusy}>
        <View>
          <InputText required id="id" label="ID" value={setupItem?.id} onChange={onChange} />
          <InputText required id="name" label="Name" value={setupItem?.name} onChange={onChange} />
          <InputSelect
            id="reasonType"
            label="Reason Type"
            value={setupItem?.crn && !setupItem?.reasonType ? 'TICKET' : setupItem?.reasonType || ''}
            onChange={onChange}
            options={cancelReasons}
            required
          />
          <InputSelect
            id="status"
            label="Status"
            value={setupItem?.status}
            onChange={onChange}
            options={status}
            required
          />
        </View>
        <ButtonBar onSave={onSaveItem} onCancel={onCancel} busy={itemBusy} />
      </Spinner>
    </View>
  );
};

export default CancelReasonEditor;
