import React, { useCallback, useEffect, useMemo } from 'react';
import Select from '../../../../components/form/Select';
import styled from 'styled-components';
import useSetup from '../../useSetup';
import { kebabCase } from 'lodash';

const { Option } = Select;

const Title = styled.div`
  margin-bottom: 3px;
`;

const itemName = typeId => {
  if (typeId) {
    const p = typeId.split('-');
    let ref = p[0];

    for (let i = 1; i < p.length; i++) {
      ref += p[i].substr(0, 1).toUpperCase();

      if (p[i].length > 0) {
        ref += p[i].substr(1);
      }
    }

    return ref;
  }
};

const itemRef = (typeId, useActualTypeName = false) => {
  return itemName(typeId === 'customer' && !useActualTypeName ? 'entity' : typeId) + 'Ref';
};

const SelectSetupItem = ({
  label,
  id = null,
  typeId,
  setupItemRef,
  item,
  onChange,
  readOnly,
  showIdWithLabel,
  required,
  useActualTypeName = false,
  hasDefaultItem = false,
  onlyReturnRef = false,
  mode = null,
  filter = null,
  params = undefined,
}) => {
  const { getSetupItems, setupItems } = useSetup(typeId);
  const setupItemName = itemName(typeId);
  const setupItemRefName = itemRef(typeId, useActualTypeName);
  const value = item?.[setupItemName]?.[setupItemRefName] || setupItemRef || (mode === null ? null : []);

  useEffect(() => {
    getSetupItems(params).catch(console.error);
  }, []);

  const itemRefChanged = useCallback(
    value => {
      if (onChange) {
        let valueObject = null;
        const fieldId = id || itemName(typeId);
        if (mode !== null) {
          valueObject = value;
        } else {
          const item = setupItems.find(s => s.crn === value);

          if (item) {
            if (onlyReturnRef) {
              valueObject = value;
            } else {
              valueObject = {
                [setupItemRefName]: value,
                id: item.id,
                name: item.name,
              };
            }
          }
        }

        onChange(fieldId, valueObject);
      }
    },
    [onChange, id, typeId, setupItemRefName, setupItems]
  );

  useEffect(() => {
    if (hasDefaultItem && setupItems?.length) {
      itemRefChanged(value);
    }
  }, [hasDefaultItem, itemRefChanged, setupItems, value]);

  const filteredSetupItems = useMemo(() => {
    if (filter) {
      return setupItems.filter(filter);
    }

    return setupItems;
  }, [setupItems, filter]);

  return (
    <div>
      {label && <Title>{label + (required ? ' *' : '')}</Title>}
      <Select
        data-testid={`select-${kebabCase(label?.toLowerCase?.())}`}
        id={setupItemRefName}
        value={value}
        disabled={readOnly}
        onChange={itemRefChanged}
        showSearch
        mode={mode}
        optionFilterProp="children"
        filterOption={(input, option) => option?.children?.toLowerCase?.().indexOf?.(input?.toLowerCase?.()) >= 0}
        filterSort={(optionA, optionB) => {
          if (optionA?.children === 'Select...') {
            return -1;
          }
          if (optionB?.children === 'Select...') {
            return 1;
          }
          return optionA?.children?.toLowerCase?.()?.localeCompare?.(optionB?.children?.toLowerCase?.());
        }}
      >
        {mode === null && <Option value="">Select...</Option>}
        {filteredSetupItems?.map(item => {
          let label = item.name;
          if (showIdWithLabel) {
            const values = [];
            item.id && values.push(item.id);
            item.name && values.push(item.name);
            label = values.join(' / ');
          } else if (!label) {
            label = item.id;
          }
          return (
            <Option key={item.crn} value={item.crn}>
              {label}
            </Option>
          );
        })}
      </Select>
    </div>
  );
};

export default SelectSetupItem;
