import { useCallback, useMemo, useState, useRef } from 'react';
import cn from 'classnames';
import { Translate } from '../../../../localization/Translate';

import { useUserContext } from '../../../../user-context/UserContextProvider';
import User from './components/User';
import Message from './components/Message';
import Timestamp from './components/Timestamp';
import Input from '../../../../../../../components/form/Input';
import { Button } from 'antd';

const { TextArea } = Input;

const Note = ({ className, note, disableUpdate, updateNote }) => {
  const user = useUserContext();

  const cancelButtonRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(note?.message || '');

  const isSameUser = useMemo(() => {
    return user?.userRef === note?.user?.userRef;
  }, [note?.user?.userRef, user?.userRef]);

  const handleChangeMessage = useCallback(e => {
    setMessage(e.target.value);
  }, []);

  const handleCancelChamge = useCallback(() => {
    cancelButtonRef.current?.blur();
    setMessage(note?.message || '');
  }, [note?.message]);

  const handleUpdateNote = useCallback(async () => {
    setLoading(true);
    await updateNote(note.crn, { message });
    setLoading(false);
  }, [message, note.crn, updateNote]);

  return (
    <div className={cn('note', className)}>
      <User user={note.user} />
      {isSameUser ? (
        <TextArea
          className="note-text"
          value={message}
          data-testid="create_note"
          onChange={handleChangeMessage}
          disabled={loading}
        />
      ) : (
        <Message message={note.message} />
      )}
      <div className="note-footer-container ">
        <Timestamp timestamp={note?.timestamp || note?.modifyDate} />
        {isSameUser && (
          <div className="note-actions-container">
            <Button
              size="small"
              ref={cancelButtonRef}
              onClick={handleCancelChamge}
              disabled={note?.message === message || loading}
            >
              <Translate stringId="cancel" defaultMessage="Cancel" />
            </Button>
            <Button
              size="small"
              loading={loading}
              disabled={note?.message === message || loading || disableUpdate}
              onClick={handleUpdateNote}
            >
              <Translate stringId="update" defaultMessage="Update" />
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Note;
