import cn from 'classnames';
import { DateTime } from 'luxon';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import styled from 'styled-components';
import { Translate } from '../../localization/Translate';
import { useTranslateMessage } from '../../localization/useTranslateMessage';
import TripProgress from './TripProgress';
import ShowMoreLoad from './ShowMoreLoad';
import TicketDetails from '../../../order-list/components/order-cards/ticket-details';
import { Drawer } from 'antd';
import { useParams } from 'react-router-dom';
import useSetup from '../../../../setup/useSetup';
import { useViewport } from '../../../../../hooks/useViewport';
import {useSchedule} from "../../../view-or-edit-order/useSchedule";

const Title = styled.div`
  font-size: 1.2em;
`;

const StyledDrawer = styled(Drawer)`
  .ant-drawer-body {
    display: flex;
    flex-direction: column;
  }
`;

const DeliveryProgress = ({ order, className, timeZone }) => {
  const { getOrder } = useSchedule();

  const { entityRef } = useParams();
  const [drawerTicketRef, setDrawerTicketRef] = useState();
  const [showMoreData, setShowMoreData] = useState({});
  const viewport = useViewport();
  const { getSetupItems, setupItems } = useSetup('cancel-reason-code');

  const drawerWidth = useMemo(() => {
    return Math.min(480, viewport.width);
  }, [viewport]);


  const onCancel = useCallback(() => {
    if (order && (order?.orderGroupRef || order?.orderRef)) getOrder(order?.orderGroupRef || order?.orderRef, { expand: 'tickets' }).catch(alert);
    setDrawerTicketRef(null);
  }, [order, getOrder]);

  useEffect(() => {
    getSetupItems().then();
  }, [getSetupItems]);

  const onToggleShowMore = loadNumber => {
    const data = { ...showMoreData };
    data[loadNumber] = { show: !showMoreData[loadNumber]?.show };
    setShowMoreData(data);
  };

  const formattedTimeZone = React.useMemo(() => {
    return DateTime.fromISO(order?.deliverySchedule?.startDateTime).setZone(timeZone).toFormat('ZZZZ');
  }, [order?.deliverySchedule?.startDateTime, timeZone]);

  return (
    <div className={cn('delivery-progress', className)}>
      <Title>
        <Translate
          stringId="deliveryProgress"
          defaultMessage="Delivery Progress ({timeZone})"
          values={{ timeZone: formattedTimeZone }}
        />
      </Title>
      <table>
        <thead>
          <tr>
            <th></th>
            <th colSpan={5}>
              <Translate stringId="order1" defaultMessage="Order" />
            </th>
            <th colSpan={3}>
              <Translate stringId="batch" defaultMessage="Batch" />
            </th>
            <th colSpan={4}>
              <Translate stringId="deliver" defaultMessage="Deliver" />
            </th>
            <th colSpan={2}>
              <Translate stringId="return" defaultMessage="Return" />
            </th>
            <th colSpan={1}></th>
          </tr>
          <tr>
            <th>
              <Translate stringId="more" defaultMessage="More" />
            </th>
            <th>
              <Translate stringId="tableHeading_loadNumber" defaultMessage="Load #" />
            </th>
            <th>
              <Translate stringId="tableHeading_quantity" defaultMessage="Quantity" />
            </th>
            <th>
              <Translate stringId="tableHeading_ticket" defaultMessage="Ticket" />
            </th>
            <th>
              <Translate stringId="tableHeading_vehicle" defaultMessage="Vehicle" />
            </th>
            <th>
              <Translate stringId="tableHeading_created" defaultMessage="Created" />
            </th>
            <th>
              <Translate stringId="tableHeading_printed" defaultMessage="Printed" />
            </th>
            <th>
              <Translate stringId="tableHeading_loading" defaultMessage="Loading" />
            </th>
            <th>
              <Translate stringId="tableHeading_loaded" defaultMessage="Loaded" />
            </th>
            <th>
              <Translate stringId="tableHeading_toJob" defaultMessage="To Job" />
            </th>
            <th>
              <Translate stringId="tableHeading_arriveJob" defaultMessage="Arrive Job" />
            </th>
            <th>
              <Translate stringId="tableHeading_beginPour" defaultMessage="Begin Pour" />
            </th>
            <th>
              <Translate stringId="tableHeading_endPour" defaultMessage="End Pour" />
            </th>
            <th>
              <Translate stringId="tableHeading_leaveJob" defaultMessage="Leave Job" />
            </th>
            <th>
              <Translate stringId="tableHeading_inPlant" defaultMessage="In Plant" />
            </th>
            <th>
              <Translate stringId="tableHeading_duration" defaultMessage="Duration" />
            </th>
          </tr>
        </thead>
        <tbody>
          {order?.deliverySchedule?.tickets?.map(ticket => (
            <>
              <TripProgress
                key={`${ticket?.loadNumber}-load`}
                load={ticket}
                timeZone={timeZone}
                openTicketDrawer={ticketRef => setDrawerTicketRef(ticketRef)}
              />
            </>
          ))}
        </tbody>
      </table>
      <StyledDrawer
        title={'Ticket Details'}
        placement="right"
        width={drawerWidth}
        closable
        onClose={() => setDrawerTicketRef(null)}
        visible={drawerTicketRef}
        destroyOnClose
      >
        <TicketDetails
          parameters={{ ticketRef: drawerTicketRef }}
          onCancelVoid={onCancel}
          setupItems={setupItems}
          supplierRef={entityRef}
          orderRef={order?.orderRef}
        />
      </StyledDrawer>
    </div>
  );
};

export default DeliveryProgress;
