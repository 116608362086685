import { DatePicker } from 'antd';
import esLocale from 'antd/es/locale/es_ES';
import React, { useCallback, useEffect, useState } from 'react';
import { useIntlContext } from '../../../../i18n';
import { useMenuContext } from './useMenuContext';
import moment from 'moment';
import styled from 'styled-components';
import cn from 'classnames';
import Button from '../../../../components/button/Button';

const antLocales = {
  es: esLocale,
};

const getDateFormat = locale => {
  const date = new Date();

  const dateFormat = Intl.DateTimeFormat(locale, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }).formatToParts(date);

  let formattedDate = '';
  for (const part of dateFormat) {
    switch (part.type) {
      case 'day':
        formattedDate += 'DD';
        break;
      case 'month':
        formattedDate += 'MM';
        break;
      case 'year':
        formattedDate += 'YYYY';
        break;
      default:
        formattedDate += part.value;
    }
  }

  return formattedDate;
};

const TrackingDatePicker = ({ className }) => {
  const { currentDate, setDate } = useMenuContext();

  const [currentDateValue, setCurrentDateValue] = useState(currentDate);

  const { userLocale } = useIntlContext();

  const dateFormat = React.useMemo(() => {
    return getDateFormat(userLocale);
  }, [userLocale]);

  useEffect(() => {
    setCurrentDateValue(existingValue => {
      if (existingValue === currentDate) {
        return existingValue;
      } else {
        return currentDate;
      }
    });
  }, [setCurrentDateValue, currentDate]);

  const changeDay = useCallback(
    amount => {
      const result = moment(currentDate).add(amount, 'day').toISOString();
      setDate(result);
    },
    [currentDate, setDate]
  );

  return (
    <div className={cn(className)}>
      <Button onClick={() => changeDay(-1)} metricId="core-tracking-date-back">
        <span className={'fa fa-chevron-left'} />
      </Button>
      <DatePicker
        onChange={m => {
          setDate((m || moment()).toISOString());
        }}
        value={moment(currentDate)}
        format={dateFormat}
        locale={antLocales[userLocale]}
      />
      <Button onClick={() => changeDay(1)} metricId="core-tracking-date-forward">
        <span className={'fa fa-chevron-right'} />
      </Button>
    </div>
  );
};

export default styled(TrackingDatePicker)`
  flex: 1;
  flex-direction: column;
`;
