import styled from 'styled-components';
import cn from 'classnames';
import SelectSetupItem from '../select-setup-item';
import React, { useCallback } from 'react';
import { set } from 'lodash';
import InputText from '../input-text';
import InputSelect from '../input-select';
import Button from '../../../../components/button/Button';
import CustomPhoneInput from '../../../order/components/phone-input/PhoneInput';

const Styled = styled.div`
  border: #333 1px solid;
  padding: 5px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 5px;
  .delete {
    grid-column: 3 / span 1;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: 0;
  }
  .react-tel-input {
    .form-control {
      width: 100% !important;
      margin-top: 3px !important;
    }

    .flag-dropdown {
      margin-top: 3px !important;
    }
  }
`;

const ticketNotificationOpts = [
  { value: 'FIRST_TICKET', label: 'First ticket' },
  { value: 'EVERY_TICKET', label: 'Every ticket' },
  { value: 'OFF', label: 'Off' },
];

const ContactLine = ({ className, item, typeId, onChange, onAction }) => {
  const itemChanged = useCallback(
    (id, value) => {
      const newItem = set({ ...item }, id, value);
      onChange(newItem);
    },
    [onChange, item]
  );

  return (
    <Styled className={cn(className)}>
      <SelectSetupItem
        showIdWithLabel
        typeId="contact-type"
        label={'Contact Type'}
        setupItemRef={item?.contactType?.contactTypeRef}
        onChange={itemChanged}
        required
      />
      <InputText id={'firstName'} label={'First Name'} onChange={itemChanged} value={item?.firstName} />
      <InputText id={'lastName'} label={'Last Name'} onChange={itemChanged} value={item?.lastName} />
      <InputText id={'email'} label={'Email'} onChange={itemChanged} value={item?.email} />
      <CustomPhoneInput fieldName={'phone'} fieldLabel={'Phone'} onChange={itemChanged} value={item?.phone} />
      <InputSelect
        id={'ticketNotification'}
        label={'Send email notifications'}
        options={ticketNotificationOpts}
        value={item.ticketNotification}
        onChange={itemChanged}
        visible={false}
      />
      <div className="delete">
        <Button
          className={'remove-btn'}
          type={'danger'}
          onClick={() => onAction('remove_item')}
          metricId={`core-setup-${typeId}-remove-contact`}
        >
          Remove
        </Button>
      </div>
    </Styled>
  );

  // return <tr className={cn(className, 'contact-line')}>
  //   <td>
  //     <SelectSetupItem
  //       showIdWithLabel
  //       typeId='contact-type'
  //       setupItemRef={item?.contactType?.contactTypeRef}
  //       onChange={itemChanged}/>
  //   </td>
  //   <td>
  //     <InputText id={'name'} onChange={itemChanged} value={item?.firstName}/>
  //   </td>
  //   <td>
  //     <InputText id={'email'} onChange={itemChanged} value={item?.email}/>
  //   </td>
  //   <td>
  //     <InputText id={'phone'} onChange={itemChanged} value={item?.phone}/>
  //   </td>
  //   <td>
  //     <div className="row-actions">
  //       <div className="delete" onClick={() => onAction('remove_item')}>
  //         <i className="icon fa fa-trash"/>
  //       </div>
  //     </div>
  //   </td>
  // </tr>;
};

export default ContactLine;
