import style from "./style";
import React, {useEffect, useState} from "react";
import styled from 'styled-components';
import Button from "../../../../components/button/Button";
import useSetup from "../../useSetup";
import Buttons from "../../../../components/layout/Buttons";
import Spinner from "../../../../components/spinner/Spinner";
import ScaleGeneral from "./scale-general";
import Tabs from '../../../../components/tabs';
import ScaleInterface from "./scale-interface";
import {api} from '../../api';
import {useParams} from "react-router-dom";

const ButtonBar = ({ onCancel, onSave, busy }) => {
  return (
    <Buttons>
      <Button id='btn-cancel' disabled={busy} onClick={onCancel}>Cancel</Button>
      <Button id='btn-save' loading={busy} disabled={busy} onClick={onSave}>Save</Button>
    </Buttons>
  )
}

const Styled = styled.div`${style}`;

const ScaleEditor = ({ item, onSave, onCancel }) => {
  const { entityRef } = useParams();

  const { getSetupItem, setupItem, setSetupItem, saveSetupItem, itemBusy } = useSetup('scale');

  const {tab} = useState('general')

  const [dexaList, setDexaList] = useState([]);
  const [functionList, setFunctionList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (item) {
      if (item.crn) {
        getSetupItem(item.crn).then();
      } else {
        setSetupItem(item);
      }
    }
  }, [item]);

  useEffect(() => {
    setLoading(true);
    const run = async () => {
      const promises = [];
      promises.push(api.getDexas(entityRef).then(result => {
        setDexaList(result.items);
      }));
      promises.push(api.getFunctions(entityRef).then(result => {
        setFunctionList(result.items);
      }));
      await Promise.all(promises);
      setLoading(false);
    }
    run().then();
  }, [entityRef])

  const onChange = (id, value) => {
    setSetupItem({
      ...setupItem,
      [id]: value,
    });
  };

  const onSaveItem = () => {
    saveSetupItem().then(() => onSave(true));
  };

  return (
    <Styled>
      <Spinner spin={loading || (!setupItem && itemBusy)}>
        <Tabs type="card" activeKey={tab}>
          <Tabs.TabPane tab="General" key="general" disabled={false}>
            <ScaleGeneral scale={setupItem} onChange={onChange} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Interface" key="interface" disabled={false}>
            <ScaleInterface dexaList={dexaList} functions={functionList} scale={setupItem} onChange={onChange} />
          </Tabs.TabPane>
        </Tabs>

        <ButtonBar onSave={onSaveItem} onCancel={onCancel} busy={itemBusy} />
      </Spinner>
    </Styled>
  );
};

export default ScaleEditor;
