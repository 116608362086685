import { v4 as uuid } from 'uuid';
import { kebabCase, startCase } from 'lodash';

import { getCachedItem } from '../cache';
import getEnvironment from './getEnvironment';

const prepareMetrics = metrics => {
  const metricArray = [].concat(metrics);

  return metricArray.map(metric => {
    const kebab = kebabCase(metric?.data?.id);

    const isCore = kebab.startsWith('core-');

    return {
      ...metric,
      data: {
        ...metric.data,
        id: isCore ? startCase(kebab.slice(5)) : kebab,
      },
      isCore,
    };
  });
};

export const RecordMetricsRq = async metrics => {
  // ensure an array
  const metricsArray = prepareMetrics(metrics);

  const xConnexId = getCachedItem('x-connex-id');

  const myHeaders = new Headers();
  myHeaders.append('x-api-key', 'NiBlhF9TMXaIFXFt6rNdk2cQsWM9Oty89oJJ2EHP');
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('Accept', 'application/json');
  myHeaders.append('x-connex-id', xConnexId);

  const env = getEnvironment() || 'unknown';
  const sessionId = getCachedItem('sessionId');
  const routeId = getCachedItem('routeId');

  const rpc = JSON.stringify({
    jsonrpc: '2.0',
    id: uuid(),
    method: 'RecordMetricsRq',
    params: {
      Metrics: metricsArray.map(({ values, isCore, ...m }) => ({
        EventType: 'ui-metric',
        App: `connex-order-service${isCore ? ':core' : ''}`,
        Environment: env,
        Metric: { ...m, sessionId, routeId },
        Values: values,
      })),
    },
  });

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: rpc,
    redirect: 'follow',
  };

  return fetch('https://analytics.commandalkon.io/v1/metrics', requestOptions);
};
