import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Translate } from '../../../../order/components/localization/Translate';
import style from './style';
import { cloneDeep } from 'lodash';
import styled from 'styled-components';
import LineItem from './line-item';
import { Divider } from 'antd';
import Button from '../../../../../components/button/Button';
import { find } from 'lodash';

const Styled = styled.div`
  ${style}
`;

const LineItems = ({ billable, products, onChange }) => {
  const [otherProducts, setOtherProducts] = useState(null);
  const [primaryProduct, setPrimaryProduct] = useState(null);

  useEffect(() => {
    if (billable) {
      setPrimaryProduct(billable?.lineItems?.[0] ?? null);
      setOtherProducts(billable?.lineItems?.length > 1 ? billable?.lineItems?.slice(1) : []);
    }
  }, [billable]);

  const addProduct = useCallback(() => {
    setOtherProducts(existing => {
      const newbie = cloneDeep(existing);

      newbie.push({
        isAdded: true,
        productId: '',
        productName: '',
        quantity: 0,
        uomCode: 'EA',
      });

      return newbie;
    });
  }, [setOtherProducts]);

  const lineItemChanged = useCallback(
    item => {
      const { productRef } = item;

      let updatedOtherProducts = otherProducts.slice();
      if (!productRef) {
        if (item.isRemoved === true) {
          updatedOtherProducts = updatedOtherProducts.filter(p => p.productRef);
          setOtherProducts(updatedOtherProducts);
          onChange('lineItems', {
            primaryProduct,
            otherProducts: updatedOtherProducts,
          });
        }
        return;
      }

      let updatedPrimaryProduct = { ...primaryProduct };

      let updatedPrimary = false;
      if (item.isRemoved === true) {
        updatedOtherProducts = updatedOtherProducts.filter(p => p.productRef !== productRef);
      } else {
        if (updatedPrimaryProduct?.productRef === productRef) {
          updatedPrimary = true;
          updatedPrimaryProduct = { ...primaryProduct, ...item };
        } else {
          let found = false;
          updatedOtherProducts = updatedOtherProducts.map(p => {
            if (p.productRef === productRef) {
              found = true;
              return { ...p, ...item };
            }
            return p;
          });
          if (!found) {
            updatedOtherProducts = updatedOtherProducts.map(p => {
              if (!p.productRef && p.isAdded === true) {
                const product = find(products, p => p.crn === productRef);

                found = true;
                return { ...p, ...item, productId: product.id, productName: product.name };
              }
              return p;
            });
            if (!found) {
              updatedOtherProducts.push(item);
            }
          }
        }
      }

      if (updatedPrimary) {
        setPrimaryProduct(updatedPrimaryProduct);
      } else {
        setOtherProducts(updatedOtherProducts);
      }

      onChange('lineItems', {
        primaryProduct: updatedPrimaryProduct,
        otherProducts: updatedOtherProducts,
      });
    },
    [primaryProduct, otherProducts, products, onChange]
  );

  const availableProducts = useMemo(() => {
    return (
      products?.filter(
        p => primaryProduct?.productRef !== p.crn && !find(otherProducts, p2 => p2.productRef === p.crn)
      ) || []
    );
  }, [products, primaryProduct, otherProducts]);

  return (
    <Styled>
      <div>
        <Button
          className="add-product"
          size="small"
          onClick={addProduct}
          metricId="core-unbilled-deliveries-billable-details-add-product"
        >
          <i className="fas fa-plus" />
          <Translate stringId="invoices.addProduct" defaultMessage="Add Product" />
        </Button>
      </div>
      <Divider>
        <Translate stringId="invoices.productsAndFees" defaultMessage="Products and Fees" />
      </Divider>

      {primaryProduct && (
        <LineItem
          lineItem={primaryProduct}
          products={availableProducts}
          onChange={lineItemChanged}
          index={0}
          taxRate={billable.taxRate}
        />
      )}

      {otherProducts
        ?.filter(l => l.isRemoved !== true)
        .map((lineItem, index) => (
          <>
            <Divider />
            <LineItem
              key={`lineItem-${index}`}
              lineItem={lineItem}
              products={availableProducts}
              onChange={lineItemChanged}
              index={index + 1}
              canRemove
              taxRate={billable.taxRate}
            />
          </>
        ))}
    </Styled>
  );
};

export default LineItems;
