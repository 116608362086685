/*global google*/

import { GoogleMap, LoadScriptNext } from '@react-google-maps/api';
import React, { useState } from 'react';
import styled from 'styled-components';
import FlexColumn from '../../views/order/components/FlexColumn';
import DefaultComponent from './default-components';
import Overlay from './Overlay';
import getEnviromentConfig from '../../util/getEnviromentConfig';

const Styled = styled(FlexColumn)`
  height: 100%;
  flex: 1;
  position: relative;
`;

/**
 *
 * @type {{SATELLITE: string, TERRAIN: string, ROADMAP: string, HYBRID: string}}
 */
export const MAP_TYPE = {
  ROADMAP: 'roadmap',
  SATELLITE: 'satellite',
  HYBRID: 'hybrid',
  TERRAIN: 'terrain',
};

/**
 * @param children - (Optional)  If present, will be rendered as the InfoWindow upon clicking the marker.
 * @param center
 * @param height
 * @param width
 * @param zoom
 * @param onZoomChanged
 * @param bounds
 * @param mapTypeId
 * @param onLoad
 * @param options - An array of marker definition objects (which will render the default marker for that type) AND/OR Marker components which will override the default.
 * @param busy
 * @returns {JSX.Element}
 */
const BaseMap = ({
  center = { lat: 41.850033, lng: -87.6500523 },
  height = '100%',
  width = '100%',
  zoom = 4,
  onZoomChanged = null,
  children,
  bounds,
  mapTypeId = MAP_TYPE.ROADMAP,
  onLoad,
  options = [],
  busy,
}) => {
  const [libraries] = useState(['places']);
  const [map, setMap] = useState(null);

  const fitBounds = React.useCallback(
    map => {
      if (map && bounds?.length) {
        const latLngBounds = new google.maps.LatLngBounds();
        bounds.forEach(point => {
          if (point?.lat && point?.lng) {
            latLngBounds.extend({ lat: point.lat, lng: point.lng });
          }
        });
        map.fitBounds(latLngBounds);
      }
    },
    [bounds]
  );

  const loadScriptDependencies = {
    language: 'eng',
    // region: 'us',
    libraries,
    googleMapsApiKey: getEnviromentConfig('googleMapApiKey'),
  };

  const handleZoomChange = () => {
    onZoomChanged?.(map);
  };

  const defaultMapOptions = {
    styles: [
      {
        featureType: 'poi',
        elementType: 'labels.icon',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
    ],
    gestureHandling: 'greedy',
  };

  const _onLoad = React.useCallback(
    map => {
      onLoad?.(map);
      fitBounds(map);
      map.setMapTypeId(mapTypeId);
      setMap(map);
    },
    [fitBounds, mapTypeId, onLoad]
  );

  const mapCenter = React.useMemo(() => {
    return bounds?.length ? undefined : center;
  }, [bounds?.length, center]);

  // TODO: infer bounds from options.
  // TODO: Support an option (prop) being an array of components (i.e. waypoints, trucks, etc)

  return (
    <LoadScriptNext {...loadScriptDependencies}>
      <Styled>
        <Overlay $busy={busy}>
          <div className="overlay" />
          <div className="spinner">
            <i className="far fa-circle-notch fa-spin" />
          </div>
        </Overlay>
        <GoogleMap
          onLoad={_onLoad}
          options={defaultMapOptions}
          zoom={zoom}
          center={mapCenter}
          mapTypeId={mapTypeId}
          mapContainerStyle={{ width, height }}
          onZoomChanged={handleZoomChange}
          tilt={0}
        >
          {options?.map?.(option => {
            if (React.isValidElement(option)) {
              return option;
            }
            const Component = DefaultComponent[option.type];
            return Component ? <Component key={option.key} {...option} /> : null;
          })}
          {children}
        </GoogleMap>
      </Styled>
    </LoadScriptNext>
  );
};

export default BaseMap;
