import React, { useMemo, useCallback } from 'react';
import cn from 'classnames';
import { find, get, sortBy } from 'lodash';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useOrderListContext } from '../../../components/OrderListContext';
import FadeInDiv from '../FadeInDiv';
import { getGroups } from '../group-by/GroupBy';
import OrdersGraph from '../orders-graph/OrdersGraph';
import OrderCardsGrouped from './OrderCardsGrouped';
import style from './style';
import OrderCardContainer from '../order-card-container';

const OrderCards = ({ className, orders, columns, groupBy, onOpen }) => {
  const navigate = useNavigate();
  const { persona, showAdvancedSearch } = useOrderListContext();

  const handleClick = useCallback(
    order => {
      const primaryLineItem = find(order?.lineItems, { isPrimary: true });
      const orderType = primaryLineItem?.item?.type;

      persona === 'seller' && navigate(`edit/${orderType?.toLowerCase?.()}/${order?.crn}/general`);
    },
    [navigate, persona]
  );

  const groupedOrders = useMemo(() => {
    if (groupBy === '') return orders;

    const targetGroup = find(getGroups(persona), { id: groupBy });

    const sorted = sortBy(orders, targetGroup.path);

    const split = sorted.reduce((acc, value) => {
      const path = groupBy === 'date' ? get(value, targetGroup.path).substring(0, 10) : get(value, targetGroup.path);

      acc[path] = (acc[path] || []).concat(value);
      return acc;
    }, {});

    return split;
  }, [groupBy, orders, persona]);

  return (
    <FadeInDiv className={cn('order-cards', className)}>
      {!showAdvancedSearch && orders?.some?.(o => o?.deliverySchedule?.schedule?.length) && (
        <OrdersGraph className="chart-section" orders={orders} />
      )}

      {groupBy ? (
        <OrderCardsGrouped
          groupedOrders={groupedOrders}
          groupBy={groupBy}
          handleClick={handleClick}
          columns={columns}
          onOpen={onOpen}
        />
      ) : (
        <OrderCardContainer orders={orders} handleClick={handleClick} columns={columns} onOpen={onOpen} />
      )}
    </FadeInDiv>
  );
};

export default styled(OrderCards)`
  ${style}
`;
