import QuantityToAdd from './QuantityToAdd';
import style from './style';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import InputSelect from '../../components/input-select';
import { ruleTypes, triggerPoints, status, recalcOptions } from './options';
import useRules from '../useRules';
import createConfig from '../../components/rule-editor/create-config';
import RuleEditor from '../../components/rule-editor';
import FieldContainer from '../../../../components/field-container/FieldContainer';
import InputText from '../../components/input-text';
import InputNumber from '../../components/input-number';

const Styled = styled.div`
  ${style}
`;

const RuleGeneral = ({ item, onChange }) => {
  const [config, setConfig] = useState(null);
  const { getFields, fields, getTriggerPoints, getPricedProducts, pricedProducts, pricedProductsBusy } = useRules();

  useEffect(() => {
    getTriggerPoints();
    getPricedProducts();
  }, [getTriggerPoints, getPricedProducts]);

  useEffect(() => {
    item?.ruleType && getFields(item?.ruleType);
  }, [item?.ruleType, getFields]);

  useEffect(() => {
    if (fields) {
      setConfig(createConfig(fields));
    }
  }, [fields]);

  const onChangeRule = (id, value) => {
    if (value) {
      const { trigger, logic } = value;

      onChange(`${id}`, trigger);
      onChange(`${id}Logic`, logic);
    }
  };

  const selectProducts = useMemo(() => {
    if (!pricedProducts) return [];
    return pricedProducts.map(p => {
      return {
        value: p.value,
        label: p.title,
      };
    });
  }, [pricedProducts]);

  const onChangeProduct = (id, value) => {
    const product = selectProducts.find(p => p.value === value);

    if (product) {
      onChange('name', product.label);
      const completeProduct = pricedProducts.find(p => p.value === value);

      //If a product with minimumLoadCharge is selected the quantity should always be FIXED->1
      if (completeProduct?.minimumLoadCharge) {
        onChange('quantity', { type: 'FIXED', value: '1' });
      }
    }
    onChange(id, value);
  };

  const isMinimumLoadChargeProduct = useMemo(() => {
    if (!item?.productRef || !pricedProducts) return false;
    const product = pricedProducts.find(p => p.value === item?.productRef);
    return product?.minimumLoadCharge;
  }, [item?.productRef, pricedProducts]);

  return (
    <Styled>
      <InputText id="id" label="ID" value={item?.id || ""} onChange={onChange} required/>
      <InputSelect
        id="ruleType"
        label="Rule Type"
        value={item?.ruleType}
        onChange={onChange}
        options={ruleTypes}
        required
      />

      <InputSelect
        id="triggerPoint"
        label="Apply Rule When"
        value={item?.triggerPoint}
        onChange={onChange}
        options={item?.ruleType === 'UNLOADING_CHARGE' ? triggerPoints.filter(p => p.value === 'END_UNLOADING') : triggerPoints.filter(p => p.value !== 'END_UNLOADING')}
        required
      />

        {item?.ruleType !== 'UNLOADING_CHARGE' && <InputSelect
        id="recalc"
        label="Recalculation"
        value={item?.recalc ?? 'never'}
        onChange={onChange}
        options={recalcOptions}
      />}

      <InputSelect
        id="productRef"
        label="Automatically Add Product (Priced Products Only)"
        value={item?.productRef}
        onChange={onChangeProduct}
        options={selectProducts}
        disabled={pricedProductsBusy}
        required
      />

      <InputText id="name" label="Rule Description" value={item?.name} onChange={onChange} />

      <QuantityToAdd
        value={item?.quantity}
        onChange={onChange}
        disabled={pricedProductsBusy || isMinimumLoadChargeProduct || item?.ruleType === 'UNLOADING_CHARGE'}
      />
        {item?.ruleType === 'UNLOADING_CHARGE' && <FieldContainer>
            <InputNumber id="timePerUnit" label="On Job Allowance (minutes)" value={item?.timePerUnit} onChange={onChange}/>
            <InputNumber id="gracePeriod" label="Grace Period (minutes)" value={item?.gracePeriod} onChange={onChange}/>
        </FieldContainer>}

      <FieldContainer>
        <div>When The Following Rules Are Met</div>
        {config && <RuleEditor id="trigger" config={config} trigger={item?.trigger} onChange={onChangeRule} />}
      </FieldContainer>

      <InputSelect id="status" label="Status" value={item?.status} onChange={onChange} options={status} required />
    </Styled>
  );
};

export default RuleGeneral;
