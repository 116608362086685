import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { isEmpty } from 'lodash';

const Styled = styled.div`
  text-overflow: ellipsis;
`;
const ValidationMessage = ({ validationErrors, id }) => {
  return isEmpty(validationErrors?.[id]) ? null : <Styled className={cn('validation-message')}>
    {validationErrors.id}
  </Styled>;
};

export default ValidationMessage;
