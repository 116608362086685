import { css } from 'styled-components';

export default css`
  font-size: 1em;

  .title {
    margin-top: 10px;
    font-size: 1.1em;
    font-weight: bold;
    text-align: left;
  }

  .select-tax-rate {
    margin-top: 5px;
    margin-bottom: 25px;
    width: 360px;
    margin-right: 5px;
  }

  .btn-reprice {
    margin-top: 25px;
  }

  .form-items {
    align-items: flex-end;
  }

  .action-buttons {
    margin-bottom: 25px;
  }

  table {
    border: solid 1px #2d2d2d;

    th {
      padding: 5px;
      background-color: #222222;
      border: solid 1px #2d2d2d;
    }

    td {
      padding: 5px;
    }

    .totals {
      background-color: #222222;
      font-weight: bold;
      text-align: right;
    }

    .center {
      text-align: center;
    }

    .currency-column {
      text-align: right;
    }
  }
`;
