import { find, pick } from 'lodash';
import React from 'react';
import Select from '../../../components/form/Select';
import { cacheItem } from '../../../util/cache';
import Margin from "../components/Margin";
import { useScaleTicketingContext } from '../Context';

const { Option } = Select;

const Location = () => {
  const { locations, form, handleChange } = useScaleTicketingContext();

  const handleLocationChange = React.useCallback(
    ref => {
      const targetLocation = find(locations, { crn: ref });
      if (targetLocation) {
        const location = {
          ...pick(targetLocation, ['id', 'name']),
          locationRef: targetLocation.crn,
        };
        cacheItem('st::location', location);
        handleChange('location', location);
      }
    },
    [handleChange, locations]
  );

  return (
    <Margin top={0}>
      <div className="field-label">Location</div>
      <Select
        value={form?.location?.locationRef || ''}
        showSearch
        filterOption={(input, option) => option?.children?.toLowerCase?.().indexOf(input.toLowerCase?.()) >= 0}
        disabled={false}
        onChange={handleLocationChange}
      >
        {locations?.map(location => (
          <Option key={location?.crn} value={location.crn}>
            {`${location.id} / ${location.name}`}
          </Option>
        ))}
      </Select>
    </Margin>
  );
};

export default Location;
