import { Button } from 'antd';
import { useCallback, useRef, useState } from 'react';
import { Translate } from '../../../../views/order/components/localization/Translate';

const ExpandRows = ({ handleExpandAll, handleHideAll }) => {
  const [expanded, setExpanded] = useState(false);
  const buttonRef = useRef(null);

  const handleChange = useCallback(() => {
    if (expanded) {
      handleHideAll && handleHideAll();
      setExpanded(false);
    } else {
      handleExpandAll && handleExpandAll();
      setExpanded(true);
    }
    buttonRef.current.blur();
  }, [expanded, handleExpandAll, handleHideAll]);

  return (
    <Button size="small" onClick={handleChange} ref={buttonRef} style={{ width: '100px' }}>
      {expanded ? (
        <Translate stringId="grid.collapseAll" defaultMessage="Collapse All" />
      ) : (
        <Translate stringId="grid.expandAll" defaultMessage="Expand All" />
      )}
    </Button>
  );
};

export default ExpandRows;
