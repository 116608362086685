import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Button from '../../../../../components/button/Button';
import { Translate } from '../../../../order/components/localization/Translate';

const Styled = styled.div`
  width: 100%;
  display: flex;
  justify-content: right;

  .save-btn {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

const ChangeSummary = ({ changes, onSave }) => {
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    // console.log(changes)

    setHasChanges(Object.keys(changes).length > 0);
  }, [changes]);

  return (
    <Styled>
      {hasChanges && (
        <div>
          {/*<Divider>Change Summary</Divider>*/}
          <Button onClick={onSave} type="primary" id="save-btn">
            <Translate stringId="save" defaultMessage="Save" />
          </Button>
        </div>
      )}
    </Styled>
  );
};

export default ChangeSummary;
