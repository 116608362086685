import React from 'react';
import { useParams } from 'react-router-dom';
import OrderFormHeading from '../components/order-form/OrderFormHeading';
import SimpleOrderForm from '../components/order-form/SimpleOrderForm';
import OrderContextProvider from '../components/useOrderContext';
import { useOrderRequestBehavior } from './useOrderRequestBehavior';

const OrderRequest = () => {
  const { orderRef, orderType } = useParams();

  const orderRequestBehavior = useOrderRequestBehavior(orderRef, orderType?.toUpperCase?.(), true);

  return (
    <OrderContextProvider value={{ ...orderRequestBehavior, orderRef }}>
      <OrderFormHeading titleStringId="reviewOrderRequest" defaultTitle="Review Order Request" />
      <SimpleOrderForm />
    </OrderContextProvider>
  );
};

export default OrderRequest;
