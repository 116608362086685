import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';

const Styled = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Column = ({ className, children }) => {
  return <Styled className={cn(className, 'column')}>{children}</Styled>;
};

export default Column;
