import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
import DropdownButton from '../../../../components/dropdown-button/DropdownButton';
import { Translate } from '../localization/Translate';
import { useOrderListContext } from '../OrderListContext';
import { useUserContext } from '../user-context/UserContextProvider';
import style from './style';

const Styled = styled.div`
  ${style}
`;
const CreateOrderButton = ({ metricIdPrefix }) => {
  const { onCreateClick, hasConnexLocation, hasLocationWithProductType } = useOrderListContext();
  const { hasPermissions } = useUserContext();

  const buttons = React.useMemo(
    () => [
      {
        id: 'concrete',
        label: <Translate stringId="createConcreteOrder" defaultMessage="Create Concrete Order" />,
        onClick: onCreateClick('concrete'),
        metricId: `${metricIdPrefix}-create-order-concrete`,
        icon: <i className="icon fas fa-plus" />,
        permission: 'createOrder.concrete',
      },
      {
        id: 'aggregate',
        label: <Translate stringId="createAggregateOrder" defaultMessage="Create Aggregate Order" />,
        onClick: onCreateClick('aggregate'),
        metricId: `${metricIdPrefix}-create-order-aggregate`,
        icon: <i className="icon fas fa-plus" />,
        default: true, // get from company and/or user settings.
        permission: 'createOrder.aggregate',
      },
    ],
    [metricIdPrefix, onCreateClick]
  );

  const permittedButtons = React.useMemo(() => {
    return buttons
      .filter(option => hasPermissions(option.permission))
      .filter(option => hasLocationWithProductType(option.id));
  }, [buttons, hasLocationWithProductType, hasPermissions]);

  if (!hasConnexLocation || !permittedButtons.length) {
    return null;
  }

  return (
    <Styled className={cn('create-order-button')}>
      <DropdownButton options={permittedButtons} />
    </Styled>
  );
};

export default CreateOrderButton;
