import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Translate } from '../../../order/components/localization/Translate';
import { useTranslateObjects } from '../../../order/components/localization/translate-objects/useTranslateObjects';
import style from './style';
import styled from 'styled-components';
import { Drawer } from 'antd';
import { useViewport } from '../../../../hooks/useViewport';
import LineItems from './line-items';
import useBillables from '../../use-billables';
import Spinner from '../../../../components/spinner/Spinner';
import BillableProgress from './billable-header';
import General from './general';
import ChangeSummary from './change-summary';
import BillingNote from './billing-note';
import { omit, isNil } from 'lodash';
import BillingUnInvoiced from './billing-un-invoiced';

const Styled = styled.div`
  ${style}
`;

const BillableDetails = ({ billableRef, onClose, refreshList }) => {
  const [changes, setChanges] = useState({});

  const { getBillable, changeBillable, billable, summary, customers, projects, products, costBooks, loading } =
    useBillables();

  useEffect(() => {
    billableRef && getBillable(billableRef);
  }, [billableRef, getBillable]);

  // Clear the changes of the note if the values are not changed
  useEffect(() => {
    if (
      !isNil(changes?.billingNote) &&
      (billable?.billingNote || '') === changes?.billingNote &&
      !billable?.billingNoteUpdated
    ) {
      setChanges(omit(changes, 'billingNote'));
    }
  }, [billable?.billingNote, changes, billable?.billingNoteUpdated]);

  useEffect(() => {
    if (billable?.billingNoteUpdated) {
      setChanges(prevChanges => ({
        ...prevChanges,
        billingNote: billable?.billingNote,
      }));
    }
  }, [billable?.billingNoteUpdated, billable?.billingNote]);

  const viewport = useViewport();

  const drawerWidth = useMemo(() => {
    return Math.min(885, viewport.width);
  }, [viewport]);

  const onChange = useCallback(
    (id, value) => {
      setChanges({
        ...changes,
        [id]: value,
      });
    },
    [changes]
  );

  const handleOnClose = useCallback(() => {
    setChanges({});
    onClose();
  }, [setChanges, onClose]);

  const saveChanges = useCallback(async () => {
    try {
      await changeBillable(billableRef, changes);
      setChanges({});
      await refreshList();
    } catch (error) {
      alert(error.message);
    }
  }, [billableRef, changes, changeBillable, refreshList]);

  const { translateObjects } = useTranslateObjects();

  const translatedProgress = React.useMemo(() => {
    if (!summary?.progress) return;

    return translateObjects(summary?.progress, [
      {
        getPath: 'eventId',
        setPath: 'translatedTitle',
        defaultMessagePath: 'title',
        getStringId: value => `invoices.${value}`,
        valuesFrom: ['eventNumber'],
      },
    ]);
  }, [summary?.progress, translateObjects]);

  return (
    <Styled>
      <Drawer
        width={drawerWidth}
        title={<Translate stringId="invoices.billableDetails" defaultMessage="Billable Details" />}
        placement="right"
        closable
        onClose={handleOnClose}
        visible={billableRef}
        destroyOnClose
      >
        <Spinner spin={loading}>
          <BillableProgress progress={translatedProgress} />
          {billable?.unInvoiceData && <BillingUnInvoiced unInvoiceData={billable?.unInvoiceData} />}
          {billable?.billingNoteUpdated && (
            <div style={{ marginTop: '30px', color: 'red' }}>
              Billing Note Updated, please save record before exiting.
            </div>
          )}
          <BillingNote note={billable?.billingNote} onChange={onChange} />
          <General
            billable={billable}
            projects={projects}
            customers={customers}
            costBooks={costBooks}
            onChange={onChange}
          />
          <LineItems billable={billable} products={products} onChange={onChange} />
          <ChangeSummary changes={changes} onSave={saveChanges} />
        </Spinner>
      </Drawer>
    </Styled>
  );
};

export default BillableDetails;
