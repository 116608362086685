import { css } from 'styled-components';

export default css`
  width: 100%;
  //margin-top: 10px;

  .label {
    margin-bottom: 3px;
  }
`;
