import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
import style from './style';

const Styled = styled.div`
  ${style}
`;

const Yaxis = ({ intervals, namedOffset }) => {

  return (
    <Styled className={cn('yaxis')}>
      <div className="yaxis-labels">
        <div className="time-zone">{namedOffset ? `(${namedOffset})` : ''}</div>
        <div className="interval" style={{ height: '1px' }}>
          0
        </div>
        {intervals.map(interval => {
          return (
            <div className="interval" style={{ height: `${(interval / intervals[intervals.length - 1]) * 100}%` }}>
              {interval}
            </div>
          );
        })}
      </div>
    </Styled>
  );
};

export default Yaxis;
