import { FILTER_STRATEGY } from '../../../components/connex-data-grid/util';
import React from 'react';

export const prototype = {
  id: '',
  name: '',
  status: 'ACTIVE',
};

export const columns = [
  { key: 'id', name: 'ID' },
  { key: 'name', name: 'Name' },
  {
    key: 'status',
    name: 'Status',
    filterStrategy: FILTER_STRATEGY.EQUALS,
    filterComponent: ({ innerRef, ...props }) => {
      return (
        <select ref={innerRef} {...props}>
          <option value="" />
          <option value="ACTIVE">Active</option>
          <option value="INACTIVE">Inactive</option>
        </select>
      );
    },
  },
];
