import { useParams } from 'react-router';
import { useCallback, useState } from 'react';
import { api as API } from './api';

const useRules = () => {
  const { entityRef } = useParams();

  const [fields, setFields] = useState(null);
  const [pricedProducts, setPricedProducts] = useState(null);
  const [triggerPoints, setTriggerPoints] = useState(null);

  const [triggerPointsBusy, setTriggerPointsBusy] = useState(false);
  const [fieldsBusy, setFieldsBusy] = useState(false);
  const [pricedProductsBusy, setPricedProductsBusy] = useState(false);

  const getTriggerPoints = useCallback(() => {
    setTriggerPointsBusy(true);

    API.getFields(entityRef, 'TRIGGER_POINTS')
      .then(setTriggerPoints)
      .catch(alert)
      .finally(() => setTriggerPointsBusy(false));
  }, [entityRef]);

  const getFields = useCallback(
    ruleType => {
      setFieldsBusy(true);

      API.getFields(entityRef, ruleType)
        .then(setFields)
        .catch(alert)
        .finally(() => setFieldsBusy(false));
    },
    [entityRef]
  );

  const getPricedProducts = useCallback(() => {
    setPricedProductsBusy(true);

    API.getPricedProducts(entityRef)
      .then(setPricedProducts)
      .catch(alert)
      .finally(() => setPricedProductsBusy(false));
  }, [entityRef]);

  return {
    getFields,
    fields,
    fieldsBusy,

    getTriggerPoints,
    triggerPoints,
    triggerPointsBusy,

    getPricedProducts,
    pricedProducts,
    pricedProductsBusy,
  };
};

export default useRules;
