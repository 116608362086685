
export const status = [
  { value: 'ACTIVE' },
  { value: 'INACTIVE' },
];

export const taxStatus = [
  { value: 'TAXABLE' },
  { value: 'EXEMPT' },
];
