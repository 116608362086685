import styled from 'styled-components';

import style from './style';

import { Translate } from '../../../../order/components/localization/Translate';
import { ShortDateTime } from '../../../../order/components/localization/ShortDateTime';

const Styled = styled.div`
  ${style}
`;

const BillingUnInvoiced = ({ unInvoiceData }) => {
  return (
    <Styled>
      <Translate stringId="invoices.un-invoiced-by" defaultMessage="Ticket was un-invoiced by" />
      {` ${unInvoiceData?.user?.name || ''} `}
      <Translate stringId="on" defaultMessage="on" /> <ShortDateTime date={unInvoiceData?.date} showYear={true} />
    </Styled>
  );
};

export default BillingUnInvoiced;
