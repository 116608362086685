import React from 'react';
import { useParams } from 'react-router-dom';
import { api } from '../api';

export const useMasterData = () => {
  const { entityRef } = useParams();
  const [masterData, setMasterData] = React.useState({});
  const [products, setProducts] = React.useState({});
  const [defaults, setDefaults] = React.useState({});
  const [strictDefaults, setStrictDefaults] = React.useState([]);
  const [initialized, setInitialized] = React.useState(null);

  const [masterDataBusy, setMasterDataBusy] = React.useState(false);
  const [productsBusy, setProductsBusy] = React.useState(false);
  const [defaultsBusy, setDefaultsBusy] = React.useState(false);
  const [initializeBusy, setInitializeBusy] = React.useState(false);

  const getMasterData = React.useCallback(
    (customerRef, locationRef, projectRef) => {
      setMasterDataBusy(true);
      setDefaultsBusy(true);
      return api
        .getMasterData(entityRef, customerRef, locationRef, projectRef)
        .then(response => {
          const { defaults, strictDefaults, ...masterData } = response;
          setDefaults(defaults);
          setStrictDefaults(strictDefaults || []);
          setMasterData(masterData);
        })
        .finally(() => {
          setMasterDataBusy(false);
          setDefaultsBusy(false);
        });
    },
    [entityRef]
  );

    const getOrderProducts = React.useCallback(
        (customerRef, locationRef, projectRef) => {
            setProductsBusy(true);
            setDefaultsBusy(true);
            return api
                .getOrderProducts(entityRef, customerRef, locationRef, projectRef)
                .then(response => {
                    const { availableProducts } = response;
                    setProducts(availableProducts);
                })
                .finally(() => {
                    setProductsBusy(false);
                    setDefaultsBusy(false);
                });
        },
        [entityRef]
    );

  const initialize = React.useCallback(
    () => {
      setInitializeBusy(true);
      return api
        .initialize(entityRef)
        .then(setInitialized)
        .finally(() => {
          setInitializeBusy(false);
        });
    },
    [entityRef]
  );

  return {
    initialize,
    initializeBusy,
    initialized,

    getMasterData,
    getOrderProducts,
    masterData: { masterData, masterDataBusy },
    products: { availableProducts: products, productsBusy },
    defaults: { defaults, defaultsBusy },
    strictDefaults,
  };
};
