import React, { useCallback, useState } from 'react';
import cn from 'classnames';
import { useIntlContext } from '../../../../i18n';
import style from './style';
import styled from 'styled-components';
import FieldContainer from '../../../../components/field-container/FieldContainer';
import { DateRangePicker } from '../../../../components/date-range-picker';
import { useDateFormat } from '../../../../hooks/useDateFormat';

const View = styled(FieldContainer)`
  ${style}
`;

const InputDateRange = ({ className, maxRange = 30, startId, endId, label, startValue, endValue, onChange }) => {
  const [dates, setDates] = useState(null);
  const { userLocale } = useIntlContext();
  const { dateFormat, antLocales } = useDateFormat(userLocale);

  const disabledDate = useCallback(
    (...args) => {
      const current = args[0];

      if (!dates) {
        return false;
      }

      const tooLate = dates[0] && current.diff(dates[0], 'days') > maxRange;
      const tooEarly = dates[1] && dates[1].diff(current, 'days') > maxRange;
      return !!tooEarly || !!tooLate;
    },
    [dates, maxRange]
  );

  const onOpenChange = open => {
    if (open) {
      setDates([null, null]);
    } else {
      setDates(null);
    }
  };

  return (
    <View className={cn(`${startId}-${endId}`, className)}>
      <div className="label-spinner">
        <div className="label">{label}</div>
      </div>
      <DateRangePicker
        disabledDate={disabledDate}
        onChange={value => {
          onChange(startId, value[0]);
          onChange(endId, value[1]);
        }}
        onOpenChange={onOpenChange}
        onCalendarChange={setDates}
        value={dates ?? [startValue, endValue]}
        format={dateFormat}
        locale={antLocales[userLocale]}
      />
    </View>
  );
};

export default InputDateRange;
