import Button from '../../../../components/button/Button';
import { Drawer } from 'antd';
import { useState } from 'react';
import { useCallback } from 'react';
import DateTimePicker from '../date-time-picker/DateTimePicker';
import styled from 'styled-components';
import style from './style';
import { Translate } from '../localization/Translate';
import TicketWarning from '../tickets/components/TicketWarning';

const Styled = styled.div`
  ${style}
`;

const MultiDateTimePiker = ({
  id,
  className,
  disabled,
  showOrderStartDateTime = false,
  orderStartDateTime,
  onChangeStartDateTime,
  value = [],
  onChange,
  timeZone,
}) => {
  const [showDrawer, setShowDrawer] = useState(false);

  const openDrawer = useCallback(() => {
    setShowDrawer(true);
  }, []);
  const closeDrawer = useCallback(() => {
    setShowDrawer(false);
  }, []);

  const handleAddItemToList = useCallback(() => {
    const copy = [...(value || []), null];
    onChange(id, copy);
  }, [id, value, onChange]);

  const handleUpdateListItem = useCallback(
    (index, updatedDate) => {
      const copy = [...value];
      copy[index] = updatedDate;
      onChange(id, copy);
    },
    [id, value, onChange]
  );

  const handleDeleteListItem = useCallback(
    index => {
      const copy = [...value];
      copy.splice(index, 1);
      onChange(id, copy);
    },
    [id, value, onChange]
  );

  return (
    <div className={className}>
      <Button onClick={openDrawer} disabled={disabled}>
        <i className="fa fa-calendar-plus"></i>
      </Button>
      <Drawer visible={showDrawer} onClose={closeDrawer} destroyOnClose>
        <Styled>
          <table>
            <thead>
              <tr>
                <th>
                  <Translate stringId="selectMultipleDays" defaultMessage="Select Multiple Days" />
                </th>
              </tr>
            </thead>
            <tbody>
              {showOrderStartDateTime && (
                <tr>
                  <td>
                    <DateTimePicker
                      id="startDateTime"
                      value={orderStartDateTime}
                      timeZone={timeZone}
                      onChange={onChangeStartDateTime}
                      required
                    />
                  </td>
                </tr>
              )}
              {value?.map((date, index) => (
                <tr key={`multi-date-${index}`}>
                  <td className="row-container">
                    <DateTimePicker
                      id={index}
                      value={date}
                      timeZone={timeZone}
                      required
                      onChange={handleUpdateListItem}
                    />

                    <i onClick={() => handleDeleteListItem(index)} className="icon fa fa-trash icon-extra-padding" />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <TicketWarning
            message={<Translate stringId="maximunDatesLengh" defaultMessage="Limit 10 Dates per Copy Order" />}
          />
          <div className="button-container">
            <Button type="primary" onClick={handleAddItemToList} disabled={value?.length >= 9}>
              <i className="icon fa fa-plus" />
              <Translate stringId="addNewDate" defaultMessage="Add New Date" />
            </Button>
          </div>
        </Styled>
      </Drawer>
    </div>
  );
};

export default MultiDateTimePiker;
