import { find, pick } from 'lodash';
import { useState, useEffect, useMemo, useCallback } from 'react';
import { useLocations } from '../create-order/useLocations';
import { useMasterData } from '../create-order/useMasterData';
import { useStartOrder } from '../create-order/useStartOrder';
import { useForm } from '../useForm';
import { useProjects } from '../useProjects';
import { useOrder } from '../view-or-edit-order/useOrder';
import { v4 as uuid } from 'uuid';
import { useTaxExemptReasons } from '../../../hooks/useTaxExemptReasons';
import { useUserContext } from './user-context/UserContextProvider';

export const useCommonOrderBehavior = (orderType, orderRequestRef) => {
  const order = useOrder({ showToast: true, orderType, expand: 'order-group', orderRequestRef });
  const [supplierParty, setSupplierParty] = useState(null);
  const {
    form,
    handleChange,
    setForm,
    isValid,
    validatedFields,
    auditNotes,
    setAuditNotes,
    checkpoint,
    purchaseOrderRequired,
    setPurchaseOrderRequired,
  } = useForm({
    linkedOrders: [
      {
        primaryLineItem: { _id: uuid() },
        otherLineItems: [{ _id: uuid() }, { _id: uuid() }],
      },
    ],
  });
  const { startOrder, startedOrder, startOrderBusy } = useStartOrder();
  const { projects, projectsBusy, getProjects } = useProjects();
  const { locations, locationsBusy, getLocations } = useLocations();
  const { taxExemptReasons, taxExemptReasonsBusy, taxExemptReasonRequired } = useTaxExemptReasons();
  const {
    getMasterData,
    getOrderProducts,
    masterData: { masterData, masterDataBusy },
    products: { availableProducts, productsBusy },
      products,
    defaults: { defaults, defaultsBusy },
    strictDefaults,
  } = useMasterData();
  const { hasPermissions } = useUserContext();

  const lists = useMemo(
    () => ({
      creditCodes: { list: startedOrder?.creditCodes, busy: startOrderBusy },
      customers: { list: startedOrder?.customers, busy: startOrderBusy },
      projects: { list: projects, busy: projectsBusy },
      locations: { list: locations, busy: locationsBusy },
      taxExemptReasons: { list: taxExemptReasons, busy: taxExemptReasonsBusy, required: taxExemptReasonRequired },
      anyBusy: startOrderBusy || projectsBusy || locationsBusy || taxExemptReasonsBusy,
    }),
    [
      locations,
      locationsBusy,
      projects,
      projectsBusy,
      startOrderBusy,
      startedOrder,
      taxExemptReasons,
      taxExemptReasonsBusy,
      taxExemptReasonRequired,
    ]
  );

  useEffect(() => {
    setSupplierParty(startedOrder?.supplierParty);
  }, [startedOrder]);

  useEffect(() => {
    if (form?.customerRef) {
      getLocations({ activeOnly: true, integratedSystems: 'CONNEX', productType: orderType, orderRequestRef, filterDispatchLocations: true });
      getProjects(form?.customerRef, { activeOnly: true });
    }
  }, [form?.customerRef, getLocations, getProjects, orderType]);

  // fetch master data
  useEffect(() => {
    console.log(order)
    if (order?.readOnly) return;

    if (form?.locationRef && form?.customerRef && form?.projectRef) {
      console.log("It's all coming together")
      getMasterData(form?.customerRef, form?.locationRef, form?.projectRef);
    }
  }, [form?.locationRef, form?.customerRef, form?.projectRef, getMasterData, order?.readOnly]);

  useEffect(() => {
    //prevents the effect from firing when an order is opened.
    if(order?.order?.crn && !order?.productsTouched || order?.readOnly) return;

    if (form?.locationRef && form?.customerRef && form?.projectRef) {
      getOrderProducts(form?.customerRef, form?.locationRef, form?.projectRef);
    }
  }, [form?.locationRef, form?.customerRef, form?.projectRef, getOrderProducts])

  useEffect(() => {
    if (!form?.locationRef) {
      const defaultLocation = find(locations, l => l.defaultValue === true);
      if (defaultLocation) {
        handleChange('locationRef', defaultLocation.crn);
      }
    }
  }, [locations, handleChange, form]);

  const [highlightInvalidFields, setHighlightInvalidFields] = useState(false);

  const enableHighlightedFields = useCallback(() => {
    setHighlightInvalidFields(true);
  }, []);

  const disableHighlightedFields = useCallback(() => {
    setHighlightInvalidFields(false);
  }, []);

  const strictDefaultsOverridden = useMemo(() => {
    const overrides = strictDefaults.reduce((acc, strictDefault) => {
      if (
        defaults?.[strictDefault.id] &&
        defaults?.[strictDefault.id] !== 'NONE' &&
        checkpoint?.current?.[strictDefault.id] !== form?.[strictDefault.id] &&
        form?.[strictDefault.id] !== defaults?.[strictDefault.id]
      ) {
        acc.push(strictDefault);
      }
      return acc;
    }, []);

    return overrides;
  }, [checkpoint, defaults, form, strictDefaults]);

  useEffect(() => {
    setAuditNotes(auditNotes => {
      const newState = { ...auditNotes };
      strictDefaultsOverridden.forEach(o => {
        const oldValueRef = checkpoint?.current?.[o.id];
        const newValueRef = form[o.id];

        const oldValue = find(masterData[o.id], { crn: oldValueRef })?.name || oldValueRef;
        const newValue = find(masterData[o.id], { crn: newValueRef })?.name || newValueRef;

        newState[o.id] = newState[o.id] || { ...o, oldValue, newValue, message: '' };
      });
      return pick(
        newState,
        strictDefaultsOverridden.map(s => s.id)
      );
    });
  }, [checkpoint, form, masterData, setAuditNotes, strictDefaultsOverridden]);

  useEffect(() => {
    if (form?.linkedOrders?.length) {
      for (let lo of form.linkedOrders) {
        if (lo.otherLineItems?.length) {
          const countWithItems = lo.otherLineItems.filter(li => !!li.item).length;
          if (lo.otherLineItems.length <= countWithItems) {
            // Makes sure there is always a blank line item ready to add another product
            lo.otherLineItems.push({ _id: uuid() });
          }
        } else {
          //If there are no otherLineItems we need to add a blank line item
          lo.otherLineItems.push({ _id: uuid() });
        }
      }
    }
  }, [form?.linkedOrders]);

  useEffect(() => {
    setForm(prevForm => ({
      ...prevForm,
      purchaseOrder: defaults?.purchaseOrder,
    }));
  }, [form?.project]);

  const invalidProductsMessage = useMemo(() => {
    const elementsChanged = [];

    if (order?.rawOrder) {
      if (form?.customerRef !== order?.rawOrder?.customerParty?.entityRef) {
        elementsChanged.push('Customer');
      }
      if (form?.projectRef !== order?.rawOrder?.project?.projectRef) {
        elementsChanged.push('Project');
      }
      if (form?.locationRef !== order?.rawOrder?.origin?.locationRef) {
        elementsChanged.push('Location');
      }
    } else {
      elementsChanged.push('Customer', 'Project', 'Location');
    }

    if (!availableProducts?.length || !elementsChanged.length) return null;

    const availableProductMap = new Map(availableProducts?.map(p => [p.productRef, p]));

    let hasInvalidProducts = false;

    form?.linkedOrders?.forEach(lo => {
      const primary = lo?.primaryLineItem;
      if (!availableProductMap.get(primary?.item?.productRef) && primary?.item?.productRef) {
        hasInvalidProducts = true;
      }

      lo?.otherLineItems?.forEach(li => {
        if (!availableProductMap.get(li?.item?.productRef) && li?.item?.productRef) {
          hasInvalidProducts = true;
        }
      });
    });

    return hasInvalidProducts ? `Order has products that are not available in the ${elementsChanged.join(', ')}` : null;
  }, [availableProducts, form, order?.rawOrder]);

  return {
    order,
    form: {
      form,
      handleChange,
      setForm,
      isValid,
      validatedFields,
      auditNotes,
      setAuditNotes,
      checkpoint,
      purchaseOrderRequired,
      setPurchaseOrderRequired,
    },
    start: { startOrder, startedOrder, startOrderBusy },
    projects: { projects, projectsBusy, getProjects },
    locations: { locations, locationsBusy, getLocations },
    masterData: { masterData, masterDataBusy },
    products: { availableProducts, productsBusy},
    defaults: { defaults, defaultsBusy },
    strictDefaults,
    lists,
    enableHighlightedFields,
    disableHighlightedFields,
    highlightInvalidFields,
    supplierParty,
    strictDefaultsOverridden,
    invalidProductsMessage,
    getOrderProducts,
    userCanEditOrders: hasPermissions('authorities.enable_rmx-dispatch_orders_edit-order'),
  };
};
