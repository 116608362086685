/**
 * Calcualtes the over shipped quantity of an order
 *
 * @param {Object} order
 * @param {number} quantity
 * @returns {number}
 */
const calculateAboveShippedAmount = (order, quantity) => {
  const primaryProduct = order?.lineItems?.find(p => p.isPrimary);

  if (!primaryProduct || !quantity) return 0;

  let currentShippedQty = 0;

  order?.deliverySchedule?.tickets?.forEach(t => {
    if (t?.ticket?.isVoided === true) return;
    currentShippedQty += t?.quantity ?? 0;
  });

  const result = Math.max(currentShippedQty - primaryProduct.orderedQuantity.value + quantity, 0);
  return result;
};

export default calculateAboveShippedAmount;
