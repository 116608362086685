import styled from 'styled-components';
import style from './style';
import { Translate } from '../../../localization/Translate';
import { ShortDateTime } from '../../../localization/ShortDateTime';

const Styled = styled.div`
  ${style}
`;

const BatchList = ({ orderList = [], displayKey, messageId, defaultMessage }) => {
  return (
    <Styled>
      <div>
        <Translate stringId={messageId} defaultMessage={defaultMessage} />
      </div>
      <ul>
        {orderList?.map(o => {
          if (displayKey === 'startDateTime') {
            return (
              <li key={`batch-list-${o?.[displayKey]}`}>
                <ShortDateTime date={o?.[displayKey]} />
              </li>
            );
          }
          return <li>{o?.[displayKey]}</li>;
        })}
      </ul>
    </Styled>
  );
};

export default BatchList;
