import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import CopyOrderButton from '../../components/CopyOrderButton';
import CreateOrderButton from '../../components/create-order-button/CreateOrderButton';
import { Translate } from '../../components/localization/Translate';
import { useTranslateMessage } from '../../components/localization/useTranslateMessage';
import OrderForm from '../../components/order-form/OrderForm';
import OrderFormHeading from '../../components/order-form/OrderFormHeading';
import OrderContextProvider, { useOrderContext } from '../../components/useOrderContext';
import style from '../style';
import { useEditOrderBehavior } from './useEditOrderBehavior';

const EditOrder = () => {
  const {
    order: { rawOrder, orderBusy, readOnly },
  } = useOrderContext();


  const headingProps = React.useMemo(() => {
    if (!rawOrder || orderBusy) return '';
    const mode = readOnly ? 'View' : 'Edit';
    return { titleStringId: `${mode.toLowerCase()}Order`, defaultTitle: `${mode} Order` }
  }, [rawOrder, orderBusy, readOnly]);

  const actionButtons = React.useMemo(() => {
    return (
      <>
        <CreateOrderButton metricIdPrefix="core-od" />
        <CopyOrderButton order={rawOrder} metricId="core-order-detail-copy-order" data-testid="button-copy-order" />
      </>
    );
  }, [rawOrder]);

  return (
    <>
      <OrderFormHeading {...headingProps} actionButtons={actionButtons} />
      <OrderForm />
      <iframe id="orderTicketIFrame" height="0" />
    </>
  );
};

const StyledEditOrder = styled(EditOrder)`
  ${style}
`;

export default props => {
  const { orderType } = useParams();
  const editOrderBehavior = useEditOrderBehavior(orderType?.toUpperCase?.());

  return (
    <OrderContextProvider value={{ ...editOrderBehavior }}>
      <StyledEditOrder {...props} />
    </OrderContextProvider>
  );
};
