import style from '../style';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';
import InputText from '../../../components/input-text';
import InputCheckbox from '../../../components/input-checkbox';
import Select from '../../../../../components/select';
import useSetup from '../../../useSetup';
import FormError from '../../../components/form-error';

const View = styled.div`
  ${style}
`;

const CreditCodeGeneral = ({ item, onChange, error }) => {
  const { entityRef } = useParams();
  const [pmOptions, setPmOptions] = useState([]);
  const { getSetupItems, setupItems, busy } = useSetup('payment-method');
  const params = { activeOnly: false };

  useEffect(() => {
    entityRef && getSetupItems(params);
  }, [entityRef]);

  useEffect(() => {
    if (setupItems) {
      setPmOptions(setupItems);
    }
  }, [setupItems]);

  const handlePaymentMethodsChange = val => {
    if (onChange) {
      onChange('paymentMethods', val);
    }
  };

  return (
    <View>
      <FormError error={error} />
      <InputText id="id" label="ID" value={item?.id} onChange={onChange} />
      <InputText id="name" label="Name" value={item?.name} onChange={onChange} />
      {/* <InputCheckbox
        id="hasCreditChecking"
        label="Credit Checking"
        value={item?.hasCreditChecking ?? false}
        onChange={onChange}
      /> */}
      <InputCheckbox
        id="hasCreditHold"
        label="Credit Hold / Do not Sell"
        value={item?.hasCreditHold ?? false}
        onChange={onChange}
      />
      {item?.hasCreditHold && (
        <InputCheckbox
          id="isOverrideAllowed"
          className="ml-2"
          label="Allow Override"
          value={item?.isOverrideAllowed ?? false}
          onChange={onChange}
        />
      )}
      <InputCheckbox
        id="limitPaymentMethods"
        label="Limit Payment Methods"
        value={item?.limitPaymentMethods ?? false}
        onChange={onChange}
      />
      {item?.limitPaymentMethods && (
        <Select
          id="paymentMethods"
          name="Payment Methods"
          loadingPlaceholder="Loading"
          placeholder="Payment Methods"
          loading={busy}
          options={pmOptions}
          defaultValue={item?.paymentMethods}
          displayPath="name"
          valuePath="id"
          mode="multiple"
          onChange={handlePaymentMethodsChange}
        />
      )}
    </View>
  );
};

export default CreditCodeGeneral;
