import { css } from 'styled-components';

export default css`
  width: 100%;
  margin-top: 25px;

  td {
    padding: 3px;
  }

  .ant-input {
    width: 100px;
  }

  .product-description .ant-input {
    width: 100%;
  }

  .button-container {
    display: flex;
    justify-content: right;
    margin-bottom: 10px;
  }

  .remove-product {
    .fa-plus,
    .fa-minus {
      margin-right: 10px;
    }
  }

  .add-product {
    float: right;

    .fa-plus,
    .fa-minus {
      margin-right: 10px;
    }

    margin-bottom: 20px;
  }
`;
