import {css} from 'styled-components';

export default css`
  .vehicle-token {
    width: 200px;
    max-width: 200px;
  }
  height: calc(100% - 20px);
  min-width: 100%;
`
