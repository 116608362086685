import React, { useMemo } from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';
import cn from 'classnames';
import FlexColumn from '../../order/components/FlexColumn';
import SetupTable from '../components/setup-table';
import useSetup from '../useSetup';
import style from './style';
import { columns, prototype } from './setup';
import DriverEditor from './driver-editor/DriverEditor';
import ImportExport from '../components/import-export/ImportExport';

const Styled = styled(FlexColumn)`
  ${style}
`;

const ImportExportWrapped = props => {
  const { downloadFile, uploadFile } = useSetup();

  return (
    <ImportExport
      {...props}
      downloadFile={downloadFile}
      uploadFile={uploadFile}
      item={{ typeId: 'driver', crn: 'all', name: 'drivers-list' }}
    />
  );
};

const SetupDrivers = () => {
  const { entityRef } = useParams();
  const { getDrivers, drivers, busy } = useSetup();
  const params = useMemo(() => ({ activeOnly: false }), []);

  React.useEffect(() => {
    if (entityRef) getDrivers(params);
  }, [entityRef, getDrivers, params]);

  return (
    <Styled className={cn('drivers')}>
      <SetupTable
        busy={busy}
        type="Driver"
        columns={columns}
        items={drivers}
        Editor={DriverEditor}
        prototype={prototype}
        onRefresh={() => getDrivers(params)}
        actions={ImportExportWrapped}
      />
    </Styled>
  );
};

export default SetupDrivers;
