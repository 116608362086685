import { useMapBehaviorContext } from '../../context/MapBehaviorContext';
import { useMapStateContext } from '../../context/MapStateContext';

export const useDestination = () => {
  const {
    effectiveDestination,
    effectiveAddress,
    configBusy,
    canUpdate,
    departureTime,
    effectiveCoordinates,
    effectiveRadius,
  } = useMapStateContext();

  const { onGeofenceChange, handleDestinationMarkerDrag, zoomTo } = useMapBehaviorContext();

  return {
    handleDestinationMarkerDrag,
    zoomTo,
    canUpdate,
    departureTime,
    effectiveDestination,
    effectiveAddress,
    configBusy,
    effectiveCoordinates,
    effectiveRadius,
    onGeofenceChange,
    type: 'destination',
    id: 'destination',
  };
};
