import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
import FieldContainer from '../../../components/field-container/FieldContainer';
import Input from './Input';
import MaskedInput from 'antd-mask-input';
import { kebabCase } from 'lodash';
import { useTranslateMessage } from './localization/useTranslateMessage';

const { TextArea } = Input;

const Styled = styled(FieldContainer)`
  &.hasAddonAfter {
    input.ant-input {
      border-right-width: 0;
    }
  }

  .label-spinner {
    display: flex;
    .label {
      flex: 1;
    }
  }
  &.invalid {
    input {
      border: 1px solid red;
    }
  }
  &.disabled {
    .label-spinner {
      .label {
        color: #757575;
      }
    }
  }
`;

const TextAreaField = ({
  className,
  onChange,
  value,
  fieldName,
  fieldLabel,
  disabled,
  required,
  mask,
  addonAfter,
  readOnly,
  placeholder,
  rows,
  showCount,
  maxLength,
  fieldLabelStringId,
  values,
  style,
}) => {
  const translateMessage = useTranslateMessage();

  const label = React.useMemo(() => {
    if (fieldLabelStringId) {
      return translateMessage({ stringId: fieldLabelStringId, values, defaultMessage: fieldLabel });
    }
    return fieldLabel;
  }, [fieldLabel, fieldLabelStringId, translateMessage, values]);

  const handleChange = React.useCallback(
    e => {
      onChange(fieldName, e.target.value);
    },
    [fieldName, onChange]
  );

  const props = React.useMemo(
    () => ({
      value: value || '',
      onChange: handleChange,
      disabled,
      mask,
      addonAfter,
      placeholder,
      rows,
      showCount,
      maxLength,
      style,
    }),
    [addonAfter, disabled, handleChange, mask, placeholder, value, rows, showCount, maxLength, style]
  );

  const editableComponent = React.useMemo(
    () => (mask ? <MaskedInput {...props} /> : <TextArea {...props} />),
    [mask, props]
  );

  const readOnlyComponent = React.useMemo(
    () => <TextArea readOnly disabled addonAfter={addonAfter} value={value} />,
    [addonAfter, value]
  );

  return (
    <Styled
      className={cn(fieldName.replace(/\./gi, '_'), 'field-container', className, {
        disabled,
        hasAddonAfter: !!addonAfter,
      })}
      data-testid={`input-${kebabCase(fieldLabel?.toLowerCase?.())}`}
    >
      <div className="label-spinner">
        <div className="label">
          {label}
          {required && <span className="required-indicator">*</span>}
        </div>
      </div>

      {readOnly ? readOnlyComponent : editableComponent}
    </Styled>
  );
};

export default TextAreaField;
