import { Drawer } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useViewport } from '../../../hooks/useViewport';
import FieldContainer from '../../field-container/FieldContainer';
import MostRecentNote from './MostRecentNote';
import NoteList from './note-list/NoteList';

const Notes = ({ className, value = [], onChange, disabled, orderRef, hideMostRecent }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const closeDrawer = useCallback(() => {
    setDrawerOpen(false);
  }, []);

  const openDrawer = useCallback(() => {
    setDrawerOpen(true);
  }, []);

  const viewport = useViewport();

  const drawerWidth = useMemo(() => {
    return Math.min(600, viewport.width);
  }, [viewport]);

  return (
    <div className={className}>
      {!hideMostRecent && <MostRecentNote notes={value} />}

      <FieldContainer className="notes">
        <div className="label-container" onClick={openDrawer}>
          <div className="label">Notes ({value?.length || 0})</div>
          <div className="icon">
            <i className="fa fa-chevron-right" />
          </div>
        </div>
      </FieldContainer>

      <StyledDrawer
        width={drawerWidth}
        title="Notes"
        placement="right"
        closable
        onClose={closeDrawer}
        visible={drawerOpen}
      >
        <NoteList notes={value} onDone={closeDrawer} orderRef={orderRef} onChange={onChange} />
      </StyledDrawer>
    </div>
  );
};

const StyledDrawer = styled(Drawer)`
  .ant-drawer-body {
    display: flex;
    flex-direction: column;
  }
`;

export default styled(Notes)`
  & > *:not(:first-child) {
    margin-top: 20px;
  }
  .field-container {
    border: 1px solid #434343;
    padding: 10px;
    cursor: pointer;
    .label-container {
      display: flex;
      .label {
        flex: 1;
      }
    }
  }
`;
