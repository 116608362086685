import { Tooltip } from 'antd';
import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import SupplierStatusTooltip from './SupplierStatusTooltip';

const Styled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 750px) {
    display: none;
  }

  .voided,
  .cancelled {
    color: #f77e7e;
    position: absolute;
    right: 5px;
  }

  .accepted {
    position: absolute;
    left: 5px;
    color: #8ce695;
  }

  .rejected {
    position: absolute;
    left: 5px;
    color: #f77e7e;
  }
`;

const StatusIndicator = ({ load }) => {
  if (!load) return null;

  const { supplierStatus, customerStatus } = load?.ticket || {};

  return (
    <Styled className={cn('status-indicator')}>
      {(supplierStatus?.statusCode === 'CANCELED' || supplierStatus?.statusCode === 'CANCELLED' || supplierStatus?.statusCode === 'VOIDED') && (
        <Tooltip placement="top" title={<SupplierStatusTooltip status={supplierStatus} />}>
          <i className={cn('fas fa-ban', supplierStatus?.statusCode?.toLowerCase?.())} />
        </Tooltip>
      )}

      {customerStatus?.statusCode === 'ACCEPTED' && (
        <Tooltip placement="top" title={`Customer Accepted`}>
          <i className={cn('fal fa-check-circle', customerStatus?.statusCode?.toLowerCase?.())} />
        </Tooltip>
      )}

      {customerStatus?.statusCode === 'REJECTED' && (
        <Tooltip placement="top" title={`Customer Rejected`}>
          <i className={cn('fal fa-times-circle', customerStatus?.statusCode?.toLowerCase?.())} />
        </Tooltip>
      )}
    </Styled>
  );
};

export default StatusIndicator;
