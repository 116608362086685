import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { pick } from 'lodash';
import Button from '../../../components/button/Button';
import Spinner from '../../../components/spinner/Spinner';
import { useForm } from '../../order/useForm';
import Plants from './plants/Plants';
import useRoles from './useRoles';

const View = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  //width: 100%;
  background-color: #58595b;

  .header-section {
    display: flex;
    .title {
      margin-bottom: 20px;
      font-weight: bold;
      flex: 1;
    }
    .actions {
    }
  }

  .plants-section {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1;
    overflow: auto;
    margin-bottom: 10px;
    .plants-header {
      display: flex;
      align-items: flex-end;
      .label {
        flex: 1;
      }
    }
    .plants {
      overflow: auto;
      flex: 1;
      border: 1px solid #777;
      padding: 0 10px;
      table {
        margin-top: 10px;
        td {
          padding: 5px;
        }
      }
    }

    .save-section {
    }
  }
`;

const initialState = { plant: {}, createOrder: {} };

const postMessage = (fieldName, value) => {
  window?.parent?.postMessage?.(
    {
      id: 'rmx-dispatch-change',
      fieldName,
      value,
    },
    '*'
  );
};

const SetupRoles = ({}) => {
  const { roleRef } = useParams();
  const { updateRole, role, locations, busy, locationBusy } = useRoles(roleRef);

  const { form, setForm, handleChange, isDirty } = useForm(initialState);

  React.useEffect(() => {
    setForm(pick(role, ['plants', 'createOrder']), true);
  }, [role, setForm]);

  const onPlantChange = React.useCallback(
    (id, checked) => {
      if (id === 'all' && checked) {
        handleChange('plants', { all: true }, postMessage);
      } else {
        handleChange('plants', s => ({ ...s, [id]: checked, all: false }), postMessage);
      }
    },
    [handleChange]
  );

  return (
    <View>
      <div className="header-section">
        <div className="title">{role?.name} Role Permissions</div>
      </div>

      <Plants locations={locations} plants={form.plants} onChange={onPlantChange} locationBusy={locationBusy} />
    </View>
  );
};

export default SetupRoles;
