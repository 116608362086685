import React, { useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import style from './style';
import styled from 'styled-components';
import Input from '../../../../components/form/InputNumber';
import FieldContainer from '../../../../components/field-container/FieldContainer';
import { kebabCase } from 'lodash';
import roundedToCents from '../../../../util/other/rounded-to-cents';
import trimDecimals from "../../../../util/other/trim-decimals";

const View = styled(FieldContainer)`
  ${style}
`;

const InputNumberBlur = ({
  className,
  id,
  label,
  value,
  min,
  defaultValue = '',
  disabled = false,
  onChange,
  addonAfter = null,
  addonBefore = null,
  useDefaultOnChangeHandler = false,
  trim = false,
}) => {
  const [localValue, setLocalValue] = useState(defaultValue);

  useEffect(() => {
    if (trim) {
      let fValue = parseFloat(`${value || localValue || defaultValue}`);
      if (!isNaN(fValue)) {
        fValue = min ? Math.max(min, fValue) : fValue;
        fValue = trim ? trimDecimals(fValue) : fValue;
      }
      setLocalValue(fValue);
    } else
      setLocalValue(() => value ?? defaultValue);
  }, [value, defaultValue]);

  const defaultOnChangeHandler = useCallback(
    (id, value) => {
      let formatted = parseFloat(value);

      if (!isNaN(min) && min !== '') {
        formatted = isNaN(formatted)
          ? !isNaN(defaultValue) && defaultValue !== ''
            ? defaultValue
            : min
          : Math.max(min, formatted);
        setLocalValue(() => formatted);
      }

      onChange(id, isNaN(formatted) ? null : roundedToCents(formatted));
    },
    [onChange, min, defaultValue]
  );

  const handleChange = useCallback(() => {
    if (!onChange) {
      console.log(id, localValue);
    } else if (useDefaultOnChangeHandler) {
      defaultOnChangeHandler(id, localValue);
    } else if (trim) {
      let fValue = parseFloat(`${localValue || value || defaultValue}`);
      if (!isNaN(fValue)) {
        fValue = min ? Math.max(min, fValue) : fValue;
        fValue = trim ? trimDecimals(fValue) : fValue;
      }
      onChange(id, fValue);
      setLocalValue(fValue);
    } else {
      onChange(id, localValue);
    }
  }, [id, localValue, onChange, useDefaultOnChangeHandler, defaultOnChangeHandler]);

  return (
    <View className={cn(id, className)}>
      <div className="label-spinner">
        <div className="label">{label}</div>
      </div>
      <Input
        type="number"
        min={min}
        value={localValue ?? ''}
        disabled={disabled}
        onBlur={handleChange}
        onChange={e => setLocalValue(e.target?.value)}
        data-testid={`input-${kebabCase(label?.toLowerCase())}`}
        addonAfter={addonAfter}
        addonBefore={addonBefore}
      />
    </View>
  );
};

export default InputNumberBlur;
