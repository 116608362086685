import { useCallback, useState } from 'react';
import http from '../../../../../http';

const orders = '/connex/services/orders';

const useSynchronizeMixes = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const synchronizeMixes = useCallback(async (entityRef, locations, mixes, onFinish) => {
    try {
      setError(null);
      setLoading(true);
      await http.post(`${orders}/${entityRef}/setup/mix/synchronize`, { locations, mixes });
      onFinish();
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, []);

  return { loading, synchronizeMixes, error };
};

export default useSynchronizeMixes;
