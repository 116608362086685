import React from 'react';
import http from '../../../../../../http';
import { useMapStateContext } from "../../context/MapStateContext";

export const useReverseGeocode = () => {
  const { setReverseGeocoding } = useMapStateContext();

  const reverseGeoCode = React.useCallback(
    async coordinates => {
      setReverseGeocoding(true);

      // fetch the address for the given coordinates.
      const response = await http.get(
        `/connex/services/orders/geo/address?latitude=${coordinates.lat}&longitude=${coordinates.lng}`
      );
      setReverseGeocoding(false);

      return response?.[0];
    },
    [setReverseGeocoding]
  );

  return reverseGeoCode;
};
