import React from 'react';
import styled from 'styled-components';
import getUomAbbreviationOrDescription from '../../../../util/uom';
import { Translate } from '../localization/Translate';
import style from './style';
import cn from 'classnames';
import { PER_QTY_UOM_CODES, PER_100_WEIGHT_UOM_CODES } from './calcTicketQuantities';
import ProductInputNumber from '../multiple-products/ProductInputNumber';

const LineItemQuantity = ({
  lineItem,
  className,
  onChangeQuantity,
  slump,
  onChangeSlump,
  quantity,
  disabled,
  orderType,
}) => {
  return (
    <div className={cn(className, 'line-item')} key={lineItem._id}>
      <div className={'product-line'}>
        <div className="product">
          {lineItem?.item?.id} / {lineItem?.item?.name}
        </div>
      </div>

      <div className="other-info">
        <div data-testid="input-slump" className="line-item-field slump">
          {lineItem?.isPrimary === true && (
            <>
              <div className="heading">
                <Translate stringId="slump" defaultMessage="Slump" />:{orderType === 'CONCRETE' && '*'}
              </div>
              <ProductInputNumber
                value={slump}
                onChange={(_id, quantity) => {
                  onChangeSlump(quantity);
                }}
                disabled={disabled}
              />
            </>
          )}
        </div>
        <div data-testid="input-quantity" className="line-item-field quantity">
          <div className="heading">
            <Translate stringId="quantity" defaultMessage="Quantity" />:{lineItem?.isPrimary === true && '*'}
          </div>
          <ProductInputNumber
            value={quantity}
            addonAfter={
              !PER_QTY_UOM_CODES.includes(lineItem?.item?.uomCode) &&
              !PER_100_WEIGHT_UOM_CODES.includes(lineItem?.item?.uomCode) &&
              getUomAbbreviationOrDescription(lineItem?.item?.uomCode, 5)
            }
            onChange={(_id, quantity) => {
              onChangeQuantity(quantity);
            }}
            trim={true}
            disabled={disabled}
          />
        </div>
      </div>
    </div>
  );
};

export default styled(LineItemQuantity)`
  ${style}
`;
