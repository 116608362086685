import http from '../http';

const orders = '/connex/services/orders';

export const api = {
  getAttachmentUploadLink: (entityRef, type, itemRef, body) =>
    http.post(`${orders}/${entityRef}/attachments/${itemRef}/${type}`, body),
  getAttachments: (entityRef, type, itemRef) => http.get(`${orders}/${entityRef}/attachments/${itemRef}/${type}`),
  deleteAttachment: (entityRef, type, itemRef, attachmetRef) =>
    http.post(`${orders}/${entityRef}/attachments/${itemRef}/${type}/${attachmetRef}`),
};
