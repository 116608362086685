const iFrameId = 'orderTicketIFrame';

const removeListeners = req => {
  req.removeEventListener('error', () => {});
  req.removeEventListener('load', () => {});
};

const cleanupIFrame = () => {
  try {
    const iFrame = document.getElementById(iFrameId);
    if (iFrame) {
      const frameDoc = iFrame.contentDocument || iFrame.contentWindow.document;
      frameDoc.removeChild(frameDoc.documentElement);
    }
  } catch {}
};

export const printPDFDocument = url => {
  const req = new window.XMLHttpRequest();
  req.responseType = 'arraybuffer';
  req.addEventListener('error', () => {
    console.error(req.statusText);
    removeListeners(req);
  });
  req.addEventListener('load', () => {
    if ([200, 201].indexOf(req.status) === -1) {
      console.error(req.statusText);
      removeListeners(req);
      return;
    }

    let localPdf = new window.Blob([req.response], { type: 'application/pdf' });
    localPdf = window.URL.createObjectURL(localPdf);
    if (localPdf) {
      cleanupIFrame();

      const iFrameElement = document.getElementById(iFrameId);
      if (iFrameElement) {
        iFrameElement.setAttribute('src', localPdf);
        iFrameElement.onload = () => {
          try {
            iFrameElement.focus();
            iFrameElement.contentWindow.print();
          } catch (err) {
            console.error(err);
          }
        };
      }
    }
    removeListeners(req);
  });

  req.open('GET', url);
  req.send();
};
