import { useState } from 'react';
import http from '../../../http';
import { useParams } from 'react-router';
import { useCallback } from 'react';

const orders = '/connex/services/orders';

const useOrderRequestMetaData = () => {
  const { entityRef } = useParams();

  const [orderRequestMetaData, setOrderRequestMetaData] = useState(null);
  const [loading, setLoading] = useState(false);

  const getOrderRequestMetaData = useCallback(
    async orderRequestRef => {
      try {
        setLoading(true);
        const response = await http.get(`${orders}/${entityRef}/dispatch-order-requests/${orderRequestRef}/meta-data`);
        setOrderRequestMetaData(response);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    },
    [entityRef]
  );

  return {
    orderRequestMetaData,
    getOrderRequestMetaData,
    loading,
  };
};

export default useOrderRequestMetaData;
