import style from "../style";
import React from "react";
import styled from 'styled-components';
import InputText from "../../../components/input-text";
import InputSelect from "../../../components/input-select";
import {status} from '../options';
import InputCheckbox from "../../../components/input-checkbox";

const View = styled.div`${style}`;

const CarrierGeneral = ({ carrier, onChange }) => {
  return (
    <View>
      <InputText id='id' label='ID' value={carrier?.id} onChange={onChange}/>
      <InputText id='name' label='Name' value={carrier?.name} onChange={onChange}/>
      <InputSelect id='status'
                   label='Status'
                   value={carrier?.status}
                   onChange={onChange}
                   options={status}
                   required
      />
      <InputCheckbox id='companyFleet'
                     label='Company Fleet'
                     value={carrier?.companyFleet}
                     onChange={onChange}
                     required
      />
    </View>
  )
}

export default CarrierGeneral;
