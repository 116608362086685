import { css } from 'styled-components';

export default css`
  margin-top: 20px;

  .billing-text {
    margin-top: 5px;
    padding: 5px;
    border: 1px solid #434343;
  }
`;
