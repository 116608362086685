import { css } from 'styled-components';

export default css`
  width: 100%;

  .table-container {
    height: 480px;
    overflow-y: auto;
    border: solid 1px #333;
  }

  table {
    width: 100%;
  }

  thead th {
    background-color: #0e4980;
    padding: 5px;
    border: solid 1px #3a3a3a;
  }

  tbody td {
    padding: 3px;
    border: solid 1px #333;
  }

  .buttons-container {
    display: flex;
    justify-content: right;
    align-items: center;
    gap: 5px;
    margin-bottom: 15px;
  }

  .section-text {
    margin-top: 10px;
  }
`;
