import React from 'react';
import PaymentMethodGeneral from './payment-method-general';

export const prototype = {
  id: '',
  name: '',
  status: 'ACTIVE',
};

export const columns = [
  {
    key: 'id',
    name: 'ID',
  },
  {
    key: 'name',
    name: 'Name',
  },
  {
    key: 'invoiceable',
    name: 'Invoiceable',
    getValue: row => row.invoiceable ?? 'NO',
    filterComponent: ({ innerRef, ...props }) => {
      return (
        <select ref={innerRef} {...props}>
          <option value="" />
          <option value="YES">Yes</option>
          <option value="NO">No</option>
        </select>
      );
    },
  },
  {
    key: 'status',
    name: 'Status',
    filterFn: (item, filterStrings) => item === filterStrings[0],
    filterComponent: ({ innerRef, ...props }) => {
      return (
        <select ref={innerRef} {...props}>
          <option value="" />
          <option value="ACTIVE">Active</option>
          <option value="INACTIVE">Inactive</option>
        </select>
      );
    },
  },
];

export const tabs = [
  {
    label: 'General',
    component: PaymentMethodGeneral,
  },
];
