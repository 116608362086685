import http from '../../../../../http';
import defaultErrorHandler from "../../handleError";

const _handle = async (apiCall, errorHandler) => {
    try{
      return await apiCall;
    }catch(e){
      return errorHandler(e);
    }
};

export default {
  getConfig: ({ entityRef, configRef }, errorHandler = defaultErrorHandler) => _handle(http.get(`/v2/geo/${entityRef}/embedded-map/${configRef}`), errorHandler),
  setProjectRoute: ({ entityRef, orderRef, body }, errorHandler = defaultErrorHandler) => _handle(
    http.post(`/v2/geo/${entityRef}/embedded-map/order/${orderRef}/route/project-default`, body), errorHandler),
  updateRoute: ({ entityRef, orderRef, body }, errorHandler = defaultErrorHandler) => _handle(
    http.post(`/v2/geo/${entityRef}/embedded-map/order/${orderRef}/route`, body), errorHandler = defaultErrorHandler),
  getRoute: ({ entityRef, body }, errorHandler = defaultErrorHandler) => _handle(http.post(`/v2/geo/${entityRef}/route`, body), errorHandler),
  getWidgetData: ({ entityRef, queryRef, body }) =>
    http.post(`/connex/services/analytics/${entityRef}/query/${queryRef}`, body),
  updateDestination: ({ entityRef, orderRef, body }, errorHandler = defaultErrorHandler) => _handle(
    http.post(`/v2/geo/${entityRef}/embedded-map/order/${orderRef}`, body), errorHandler),
};
