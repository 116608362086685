import { find, omit, isEmpty } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';
import Button from '../../../../components/button/Button';
import Buttons from '../../../../components/layout/Buttons';
import Spinner from '../../../../components/spinner/Spinner';
import Tabs from '../../../../components/tabs';
import SelectPriceBook from '../../components/select-price-book';
import SelectPricingOptions from '../../components/select-pricing-options';
import SelectTicketOptions from '../../components/select-ticket-options';
import useSetup from '../../useSetup';
import CustomerGeneral from './customer-general';
import CustomerProjects from './customer-projects';
import style from './style';
import ContactListEditor from '../../components/contact-list-editor/ContactListEditor';
import { v4 as uuid } from 'uuid';
import useValidateField from '../../../../hooks/useValidateField';

const View = styled.div`
  ${style}
`;

const ButtonBar = ({ onCancel, onSave, busy, validationErrors }) => {
  return (
    <Buttons>
      <Button
        id="btn-cancel"
        disabled={busy || validationErrors}
        onClick={onCancel}
        metricId="core-setup-customer-editor-cancel"
      >
        Cancel
      </Button>
      <Button
        id="btn-save"
        loading={busy}
        disabled={busy || validationErrors}
        onClick={onSave}
        metricId="core-setup-customer-editor-save"
      >
        Save
      </Button>
    </Buttons>
  );
};

const CustomerEditor = ({ item, onSave, onCancel, list = [], saveOnEnter }) => {
  const { getCustomer, createCustomer, updateCustomer, customer, setCustomer, busy } = useSetup();
  const { entityRef } = useParams();
  const { tab } = useState('general');
  const { validateField, validationErrors } = useValidateField(list);

  useEffect(() => {
    if (item) {
      if (item.crn) {
        getCustomer(entityRef, item.crn);
      } else {
        setCustomer(item);
      }
    }
  }, [entityRef, getCustomer, item, setCustomer]);

  const onChange = useCallback(
    (id, value) => {
      validateField(id, value);
      setCustomer(existingCustomer => ({
        ...existingCustomer,
        [id]: value,
      }));
    },
    [validateField, setCustomer]
  );

  const onSaveCustomer = React.useCallback(() => {
    if (validationErrors) return;

    const fixedCustomer = { ...customer };

    fixedCustomer?.contacts?.forEach(contact => {
      if (!contact.id) contact.id = uuid();
    });

    if (fixedCustomer.crn) {
      updateCustomer(entityRef, fixedCustomer.crn, fixedCustomer).then(response => onSave(true, response));
    } else {
      createCustomer(entityRef, fixedCustomer).then(response => onSave(true, response));
    }
  }, [createCustomer, customer, entityRef, onSave, updateCustomer, validationErrors]);

  const handleEnterKey = React.useCallback(
    e => {
      if (saveOnEnter && customer?.id && customer?.name && e.key === 'Enter') {
        onSaveCustomer();
      }
    },
    [customer?.id, customer?.name, onSaveCustomer, saveOnEnter]
  );

  return (
    <View onKeyDownCapture={handleEnterKey}>
      <Spinner spin={!customer && busy}>
        <Tabs type="card" activeKey={tab}>
          <Tabs.TabPane tab="General" key="general" disabled={false}>
            <CustomerGeneral customer={customer} onChange={onChange} validationErrors={validationErrors} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Projects" key="projects" disabled={false}>
            <CustomerProjects customer={customer} onChange={onChange} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Pricing" key="pricing" disabled={false}>
            {customer && <SelectPricingOptions item={customer} view="customer" onChange={onChange} />}
            {customer && <SelectPriceBook item={customer} onChange={onChange} allowEmpty={true} />}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Contacts" key="contacts" disabled={false}>
            {customer && (
              <ContactListEditor
                item={customer}
                onChange={onChange}
                typeId="customer-editor"
                validationErrors={validationErrors}
              />
            )}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Settings" key="settings" disabled={false}>
            {customer && <SelectTicketOptions item={customer} view="customer" onChange={onChange} />}
          </Tabs.TabPane>
        </Tabs>
        <ButtonBar onSave={onSaveCustomer} onCancel={onCancel} busy={busy} validationErrors={validationErrors} />
      </Spinner>
    </View>
  );
};

export default CustomerEditor;
