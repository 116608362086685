import { max, merge, isString, toNumber } from 'lodash';
import { DateTime } from 'luxon';
import canonicalJson from '../../../../../util/canonicalJson';
import { SEVERITY } from '../../../components/pie-chart/PieChart';
import calculateIdealFactor from './calculateIdealFactor';
import { isDelivered, loadStatus } from './statuses';
import DataIntervalTree from 'node-interval-tree';

const map = (orderId, supplierStatus, orderRef) => {
  return load => {
    return {
      orderId,
      orderRef,
      load: load.loadNumber,
      quantity: toNumber(load?.quantity) || 0,
      arrive_job: load?.ticketEvents?.ARRIVE_JOB,
      ticket: load?.ticket,
      isDelivered: load?.ticket && isDelivered(load?.ticketEvents),
      status: loadStatus(load, supplierStatus),
      supplierStatus,
      rawLoad: load,
    };
  };
};

const getLoadTimeRange = (load, trucksGraph) => {
  const { startTimeISO, endTimeISO } = {};

  if (!trucksGraph) {
    let itemValue = load?.arrive_job;

    if (itemValue?.eventDateTime) {
      itemValue = itemValue?.eventDateTime;
    }

    if (itemValue) {
      const date = DateTime.fromISO(itemValue);
      return {
        startTime: date,
        endTime: date,
      };
    }
    return { startTimeISO, endTimeISO };
  }

  if (!load?.rawLoad?.ticketEvents) {
    return { startTimeISO, endTimeISO };
  }

  const { maxTime, minTime } = Object.entries(load.rawLoad.ticketEvents).reduce(
    ({ maxTime, minTime }, [key, value]) => {
      let itemValue = value;

      if (itemValue?.eventDateTime) {
        itemValue = itemValue?.eventDateTime;
      }

      if (!maxTime || !minTime) {
        return {
          maxTime: itemValue,
          minTime: itemValue,
        };
      }

      if (itemValue < minTime) {
        return {
          minTime: itemValue,
          maxTime,
        };
      } else if (itemValue > maxTime) {
        return {
          maxTime: itemValue,
          minTime,
        };
      }
      return { maxTime, minTime };
    },
    {}
  );

  if (!maxTime || !minTime) {
    return {};
  }

  return {
    startTime: DateTime.fromISO(minTime),
    endTime: DateTime.fromISO(maxTime),
  };
};

const roundTime = dtString => {
  let dt = DateTime.fromISO(dtString).setZone('America/Chicago');

  dt = dt.minute > 30 ? dt.set({ minutes: 30 }) : dt.set({ minutes: 0 });

  return dt.toISO();
};
export default (orders, startDate, endDate, trucksGraph) => {
  if (!orders?.length) {
    return {
      timeSlots: {},
      max: 0,
    };
  }

  const ordersWithASchedule = orders.filter(order => order?.deliverySchedule?.schedule);
  const ordersWithTickets = orders.filter(order => order?.deliverySchedule?.tickets);

  const scheduled = ordersWithASchedule.reduce(
    (acc, order) =>
      acc.concat(order.deliverySchedule.schedule?.map(map(order.id, order.supplierStatus?.name, order.crn))),
    []
  );
  const ticketed = ordersWithTickets.reduce(
    (acc, order) =>
      acc.concat(
        order.deliverySchedule.tickets
          ?.filter(t => !t?.ticket?.isVoided)
          ?.map(map(order.id, order.supplierStatus?.name, order.crn))
      ),
    []
  );

  let loads = [...scheduled, ...ticketed];
  let timeSlots = {};
  let totalsPerDate = {};

  let maxTime = null;
  let minTime = null;
  const intervalTree = new DataIntervalTree();

  const quantities = {
    cancelled: 0,
    delivered: 0,
    success: 0,
    warning: 0,
    danger: 0,
    total: 0,
  };

  for (const load of loads) {
    const { startTime, endTime } = getLoadTimeRange(load, trucksGraph);
    if (startTime) {
      const dt = startTime.toISO().substring(0, 10);
      if (Object.keys(totalsPerDate).includes(dt)) {
        totalsPerDate[dt] += load.quantity;
      } else {
        totalsPerDate[dt] = load.quantity;
      }
    }

    if (!startTime || !endTime) {
      continue;
    }

    const status = loadStatus(load);
    const quantity = toNumber(load?.quantity) || 0;
    quantities[status] += quantity;
    quantities.total += quantity;

    maxTime = (maxTime && DateTime.max(maxTime, endTime)) || endTime;
    minTime = (minTime && DateTime.min(minTime, startTime)) || startTime;

    intervalTree.insert(startTime.toUnixInteger(), endTime.toUnixInteger(), load);
  }

  if (!maxTime || !minTime) {
    return {
      timeSlots: {},
      max: 0,
    };
  }

  maxTime = maxTime.set({
    seconds: 0,
    milliseconds: 0,
  });

  minTime = minTime.set({
    seconds: 0,
    milliseconds: 0,
  });

  const bucketSize = 30;

  const maxMinuteMod = maxTime.minute % bucketSize;
  const minMinuteMod = minTime.minute % bucketSize;

  maxTime = maxTime.minus({
    minutes: maxMinuteMod,
  });

  minTime = minTime.minus({
    minutes: minMinuteMod,
  });

  let maxLoads = 0;
  for (let date = minTime; date <= maxTime; date = date.plus({ minutes: bucketSize })) {
    // if(date.toISO() < startDate) {
    //   continue;
    // } else if (date.toISO() > endDate) {
    //   continue;
    // }
    // Do something with each date in the loop
    const format = 'yyyy-LL-dd_HH_mm_ha';
    const bucketName = date.toFormat(format).toLowerCase();

    // rawBuckets[bucketName] = [];
    const bucketLoads = intervalTree.search(date.toUnixInteger(), date.plus({ minutes: bucketSize }).toUnixInteger());

    const bucketCounts = bucketLoads.reduce(
      (acc, load) => {
        const status = loadStatus(load);
        const quantity = trucksGraph ? 1 : toNumber(load?.quantity) || 0;

        return {
          ...acc,
          total: acc.total + quantity,
          [status]: acc[status] + quantity,
        };
      },
      {
        total: 0,
        cancelled: 0,
        delivered: 0,
        success: 0,
        warning: 0,
        danger: 0,
      }
    );

    // rawBuckets[bucketName].push(...bucketLoads);
    timeSlots[bucketName] = bucketCounts;
    maxLoads = Math.max(maxLoads, bucketCounts.total);
  }

  const maxRoundedUp = Math.ceil(maxLoads / 10) * 10;
  const interval = calculateIdealFactor(maxRoundedUp);

  const intervals = [];
  for (let x = 0; x <= maxRoundedUp; x += interval) {
    if (x === 0) {
      intervals.push({ value: 0, height: 1 });
      continue;
    }
    intervals.push({ value: x, height: (x / maxRoundedUp) * 290 });
  }

  return {
    timeSlots: timeSlots,
    max: maxRoundedUp,
    interval,
    intervals,
    quantities,
    totalsPerDate: totalsPerDate,
  };
};
