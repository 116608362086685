import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
import FieldContainer from '../../../../components/field-container/FieldContainer';
import Input from '../../../../components/form/Input';
import { Tooltip } from 'antd';

const ProductInput = ({ fieldName, value, onChange, disabled, onKeyDown, tooltip = undefined }) => {
  const handleChange = e => {
    onChange(fieldName, e.target.value);
  };

  return (
    <FieldContainer className={cn(fieldName)}>
      <Tooltip
        placement={'top'}
        title={(tooltip && !disabled) ?? 'Leave blank for all loads or specify loads e.g. 1-5,8,11-13'}
      >
        <div>
          <Input value={value || ''} onChange={handleChange} disabled={disabled} onKeyDown={onKeyDown} />
        </div>
      </Tooltip>
    </FieldContainer>
  );
};

export default styled(ProductInput)``;
