import React, { useMemo } from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import CurrencyFormat from 'react-currency-format';

const Styled = styled.div`
  text-align: right;
  padding-right: 5px;
`;

const MoneyRenderer = ({ value }) => {
  const money = useMemo(() => {
    const c = parseFloat(`${value ?? '0'}`);

    return isNaN(c) ? 0 : c;
  }, [value]);

  return (
    <Styled className={cn('cell')}>
      {money && (
        <CurrencyFormat
          value={money ?? 0.0}
          displayType={'text'}
          thousandSeparator={true}
          prefix={''}
          decimalScale={2}
          fixedDecimalScale={true}
        />
      )}
    </Styled>
  );
};

export default MoneyRenderer;
