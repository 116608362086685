import React from 'react';
import { find } from 'lodash';
import styled from 'styled-components';
import cn from 'classnames';
import FieldContainer from '../../field-container/FieldContainer';
import Input from '../../form/Input';
import Select from '../../form/Select';
import style from '../../../views/order-request/create-order/style';

const Supplier = ({ className, suppliers, suppliersBusy, onChange, value, disabled, required }) => {
  const handleChange = val => {
    const targetSupplier = find(suppliers, s => s.crn === val);
    onChange('supplier', targetSupplier);
  };

  const { Option } = Select;

  return (
    <FieldContainer className={cn('suppliers field-container', className)}>
      <div className="label-spinner">
        <div className="label">
          Supplier
          {required && <span className="required-indicator">*</span>}
        </div>
        <div className={cn('spinner', { spinning: suppliersBusy })}>
          <span className="fas fa-sync fa-spin" />
        </div>
      </div>

      <Select value={value?.crn || ''} onChange={handleChange} disabled={disabled}>
        <Option value="">Select a supplier...</Option>
        {suppliers?.map(c => (
          <Option value={c.crn} key={c.crn}>
            {c.name}
          </Option>
        ))}
      </Select>

    </FieldContainer>
  );
};

export default styled(Supplier)`
  ${style}
`;
