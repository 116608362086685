import { css } from 'styled-components';

export default css`
  .footer {
    text-align: right;
  }

  .spacing {
    margin-top: 20px;
  }

  .error {
    margin-top: 5px;
    text-align: center;
    color: red;
  }
`;
