import {useDashboardContext} from "../../context/useDashboardContext";
import {TYPE, useApiPaths} from "../../../../../hooks/useApiPaths";
import {useCallback, useEffect, useRef, useState} from "react";
import {get, some} from "lodash";
import API from "../../../../../http";
import moment from "moment";
import {v4 as uuid} from 'uuid';
import {useWindowContext} from "../window-wrapper";
import {useParams} from "react-router";
import {useUserContext} from "../../../../order/components/user-context/UserContextProvider";
import useRecursiveTimeout from "../../hooks/useRecursiveTimeout";
import nonce from "../../../../../util/nonce";

export const withViewBuilder = (WrappedComponent) => {
  return (props) => {
    const {entityRef} = useParams();
    const {windowType} = useWindowContext();
    const buildViewRef = useRef(null);
    const user = useUserContext();
    const {currentTrackingConfiguration, refreshNonce, locations, licenses, entity, meta: {
      startDate, endDate
    }} = useDashboardContext();

    const {getOrderPath} = useApiPaths(TYPE.TRACKING);

    const [windowId] = useState(uuid());

    const windowTypeRef = useRef(0);
    const windowIdRef = useRef(0);
    const startDateRef = useRef(startDate);
    const endDateRef = useRef(startDate);

    const displayedBuildViewRequestTime = useRef(moment().toISOString());

    const [windowLoading, setWindowLoading] = useState(true);
    const [view, setView] = useState({});
    const [dataSource, setDataSource] = useState('TICKETS');

    useEffect(() => {
      const dataSourceValue = get(entity, 'tracking.dataSource');
      setDataSource(dataSourceValue);
    }, [entity]);

    useEffect(() => {
      windowIdRef.current = windowId;
    }, [windowId]);

    useEffect(() => {
      windowTypeRef.current = windowType;
    }, [windowType]);

    useEffect(() => {
      startDateRef.current = startDate;
    }, [startDate]);

    useEffect(() => {
      endDateRef.current = endDate;
    }, [endDate]);

    const buildView = useCallback(async () => {
      const configurationSelectedLocations = currentTrackingConfiguration?.selectedLocations || {};

      const allSelected = !some(Object.keys(configurationSelectedLocations), key => {
        return configurationSelectedLocations[key] === true;
      });

      const selectedLocations = {};

      for(const location of locations) {
        selectedLocations[location.crn] = allSelected || configurationSelectedLocations[location.crn] === true;
      }

      const buildViewRequestTime = moment().toISOString();

      const result = await API.post(`${getOrderPath(entityRef)}/view/${windowTypeRef.current}`,{
        trackingConfiguration: {
          ...currentTrackingConfiguration,
          selectedLocations,
        },
        windowType: windowTypeRef.current,
        lookupKey: windowTypeRef.current,
        licenses,
        dataSource,
        meta: {
          startDate: startDateRef.current,
          endDate: endDateRef.current,
          startOfToday: moment().startOf('day').toISOString(),
          endOfToday: moment().endOf('day').toISOString()
        },
      }).catch();

      //check to see if we made two requests to build the view at the same time and only apply the latest one.
      if(displayedBuildViewRequestTime.current < buildViewRequestTime) {
        displayedBuildViewRequestTime.current = buildViewRequestTime;
        setWindowLoading(false);

        setView(result || {});
      }
    }, [entityRef, windowType, dataSource, licenses, setView, user, locations, currentTrackingConfiguration])

    useEffect(() => {
      buildViewRef.current = buildView;
    }, [buildView])

    useEffect(() => {
      if(buildViewRef.current) {
        buildViewRef.current();
      }
    }, [refreshNonce, currentTrackingConfiguration])

    useRecursiveTimeout(buildView, 20000);

    if(windowLoading) {
      return 'Loading...';
    }

    return <WrappedComponent view={view} {...props} />
  }
}

export default withViewBuilder;