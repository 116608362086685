import api from '../../../http';

const basePath = entityRef => {
  return `/connex/services/orders/${entityRef}`
}

export const getSettings = entityRef => api.get(`${basePath(entityRef)}/settings`)

export const getDemand = (entityRef, date, locationRef) => api.post(`${basePath(entityRef)}/schedules`, {
  date,
  locationRef,
})
