import React from 'react';
import styled from 'styled-components';
import { Modal, Button } from 'antd';
// import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

const VersionModal = ({ className, version, onRefresh }) => {
  return (
    <Modal
      title="New Version Available"
      visible={version.versionModalVisible}
      onCancel={version.hideVersionModal}
      footer={[
        <Button color="secondary" onClick={version.hideVersionModal}>
          Later
        </Button>,
        <Button color="secondary" onClick={onRefresh}>
          Refresh
        </Button>,
      ]}
    >
      <>
        <div>A new version is available. Please refresh.</div>
        <div className="version-info">
          <div className="current-version">Your Version: {version.clientVersion}</div>
          <div className="new-version">Latest Version: {version.latestVersion}</div>
        </div>
      </>
    </Modal>
  );
};

export default styled(VersionModal)`
  .version-info {
    margin-top: 10px;
    margin-left: 20px;
  }
`;
