import { isUndefined } from 'lodash';
import React from 'react';
import { FormattedNumber } from 'react-intl';

export const Number = ({ value, decimalPlaces = 2, ifFalsy, ...props }) => {
  if(!isUndefined(ifFalsy) && !value) {
    return ifFalsy;
  }

  return <FormattedNumber value={value} maximumFractionDigits={decimalPlaces} {...props} />;
};
