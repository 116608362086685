import React, { useEffect } from 'react';
import moment from 'moment';
import style from '../style';
import styled from 'styled-components';
import InputText from '../../../components/input-text';
import InputSelect from '../../../components/input-select';
import { status, taxStatus } from './options';
import InputAddress from '../../../components/input-address';
import InputCheckbox from '../../../components/input-checkbox';
import ValidationMessage from './ValidationMessage';
import SelectSalesPerson from '../../../components/select-sales-person';
import SelectCreditCode from '../../../components/select-credit-code';
import InputTextArea from '../../../components/input-textarea';
import InputDateRange from '../../../components/input-date-range';
import SelectCustomer from '../../../components/select-customer';
import { useTaxExemptReasons } from '../../../../../hooks/useTaxExemptReasons';

const View = styled.div`
  ${style}
`;

const ProjectGeneral = ({ project, onChange, validationErrors, customers, busy }) => {
  const idFieldRef = React.useRef();
  const purchaseOrderRef = React.useRef();
  const customer = customers?.get(project?.customerParty?.entityRef);
  const [isRequiredPO, setIsRequiredPO] = React.useState(false);
  const [isPODirty, setIsPODirty] = React.useState(false);
  const { taxExemptReasons, taxExemptReasonRequired, taxExemptReasonsBusy } = useTaxExemptReasons();

  useEffect(() => {
    setTimeout(() => {
      idFieldRef?.current?.focus?.();
    }, 300);
  }, []);

  useEffect(() => {
    if (purchaseOrderRef.current) {
      const { id, value } = purchaseOrderRef.current.input;

      if (id) {
        if (!project || !customer) return;
        if (typeof project?.requirePurchaseOrder === 'undefined') {
          const customerPO = customer?.requirePurchaseOrder || false;
          project.requirePurchaseOrder = customerPO;
          setIsRequiredPO(customerPO);
        } else {
          setIsRequiredPO(project.requirePurchaseOrder);
        }

        if (typeof value === 'undefined') {
          setIsPODirty(false);
        } else if (value !== '') {
          setIsPODirty(true);
        } else {
          setIsPODirty(false);
        }

        if (isPODirty) {
          onChange(id, value, isRequiredPO);
        }
      }
    }
  }, [customer?.requirePurchaseOrder, project?.requirePurchaseOrder, purchaseOrderRef.current]);

  return (
    <View>
      <ValidationMessage validationErrors={validationErrors} id="id" />
      <InputText id="id" label="ID" value={project?.id} onChange={onChange} innerRef={idFieldRef} />
      <InputText id="name" label="Name" value={project?.name} onChange={onChange} />
      <SelectCustomer item={project} onChange={onChange} customers={customers} busy={busy} />
      <InputAddress id="address" item={project} onChange={onChange} />
      <div className="po-container">
        <InputCheckbox
          id="requirePurchaseOrder"
          label="Require Purchase Order"
          value={project?.requirePurchaseOrder}
          onChange={onChange}
          busy={busy}
        />
      </div>
      <InputText
        id="purchaseOrder"
        label="Purchase Order"
        innerRef={purchaseOrderRef}
        value={project?.purchaseOrder}
        onChange={onChange}
        required={isRequiredPO}
      />
      <InputText id="contractNumber" label="DOT Contract Number" value={project?.contractNumber} onChange={onChange} />
      <SelectSalesPerson
        id="salesPersonRef"
        item={project}
        onChange={onChange}
        salesPersonRef={customer?.salesPersonRef}
      />
      <InputCheckbox
        id="projLevelCredit"
        label="Maintain Project Level Credit"
        item={project}
        onChange={onChange}
        value={project?.projLevelCredit ?? false}
      />
      {project?.projLevelCredit && <SelectCreditCode id="creditCodeRef" item={project} onChange={onChange} />}
      <InputSelect
        id="taxStatus"
        label="Tax Status"
        value={project?.taxStatus || customer?.taxStatus || 'TAXABLE'}
        onChange={onChange}
        options={taxStatus}
        required
      />
      {project?.taxStatus && project.taxStatus !== 'TAXABLE' && taxExemptReasonRequired && (
        <InputSelect
          id="taxExemptReasonRef"
          label="Tax-Exempt Reason"
          value={project?.taxExemptReasonRef || customer?.taxExemptReasonRef}
          options={taxExemptReasons}
          onChange={onChange}
          required
          busy={taxExemptReasonsBusy}
        />
      )}
      <InputTextArea
        id="driverInstructions"
        label="Driver Instructions"
        value={project?.driverInstructions}
        onChange={onChange}
      />
      <InputSelect id="status" label="Status" value={project?.status} onChange={onChange} options={status} required />
      <div className="two-rows-container">
        <div className="left">
          <InputDateRange
            onChange={onChange}
            startId={'startDate'}
            endId={'endDate'}
            startValue={project?.startDate ? moment(project?.startDate).utc().startOf('day') : null}
            endValue={project?.endDate ? moment(project?.endDate).utc().endOf('day') : null}
            maxRange={Number.MAX_SAFE_INTEGER}
            label={'Project Duration'}
          />
        </div>
        <div className="right">
          <InputCheckbox
            id="isPrevailingWage"
            label="Prevailing Wage"
            value={project?.isPrevailingWage}
            onChange={onChange}
            busy={busy}
          />
        </div>
      </div>
    </View>
  );
};

export default ProjectGeneral;
