import React, { useCallback } from 'react';
import cn from 'classnames';
import styled from 'styled-components';
import FieldContainer from '../../field-container/FieldContainer';
import Input from '../../form/Input';

const Description = ({ className, disabled, value, onChange }) => {
  const handleChange = useCallback(
    e => {
      onChange('description', e.target.value);
    },
    [onChange]
  );

  return (
    <FieldContainer className={cn('description', className)}>
      <div className="label-spinner">
        <div className="label">Description</div>
        <div className={cn('spinner', { spinning: false })}>
          <span className="fas fa-sync fa-spin" />
        </div>
      </div>
      <Input type="text" value={value || ''} onChange={handleChange} disabled={disabled} data-testid="description_input" />
    </FieldContainer>
  );
};

export default styled(Description)``;
