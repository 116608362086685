import React from 'react';
import { useOrderListContext } from './OrderListContext';
import usePersona from "../order-list/usePersona";

const IfSeller = ({ children }) => {
  const persona = usePersona();

  return persona?.toLowerCase() !== 'buyer' ? children : null;
};

export default IfSeller;
