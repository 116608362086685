import React from 'react';
import Select from '../../../../components/form/Select';
import styled from 'styled-components';
import { exportTypes } from './options';

const { Option } = Select;

const Title = styled.div`
  padding: 5px;
`;

const SelectExportType = ({ id, label, value, onChange }) => {
  return (
    <div>
      <Title>{label}</Title>
      <Select id={id} value={value || ''} onChange={v => onChange(id, v)}>
        <Option value="">Select...</Option>
        {exportTypes?.map((exportType, idx) => (
          <Option key={`${exportType.value}${idx}`} value={exportType.value}>
            {exportType.label}
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default SelectExportType;
