import React from 'react';
import cn from 'classnames';

const User = ({ className, user }) => {
  return (
    <div className={cn('note-user', className)}>
      <div className="name">
        {user?.firstName} {user?.lastName}
      </div>
    </div>
  );
};

export default User;
