import { Input as AntInput } from 'antd';
import React from 'react';
import styled from 'styled-components';

const Input = ({ children, innerRef, ...props }) => {
  return <AntInput ref={innerRef} {...props} />;
};

Input.TextArea = AntInput.TextArea;

export default styled(Input)`
  &&& {
    &:disabled {
      color: hsla(0, 0%, 0%, 0.85) !important;
      background-color: hsla(0, 100%, 100%, 0.35) !important;
    }
  }
`;
