import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import Button from '../../../../../../components/button/Button';
import { useForm } from '../../../../../../hooks/useForm';
import InvoiceButton from '../invoice-button/InvoiceButton';
import SelectInvoiceTemplate from '../select-invoice-template';
import style from './style';

const Styled = styled.div`
  ${style}
`;

const initialState = {
  sendEmail: false,
  preview: true,
};

const PreviewHeader = ({
  selectAll,
  creatingInvoices,
  createInvoicesAsync,
  templateRef,
  customers,
  items,
  filters,
  setTemplateRef,
  progress,
  exportInvoiceData,
}) => {
  const { form, handleChange } = useForm(initialState);

  const handleCreateAsync = () => {
    const selectedCustomers = customers.filter(s => s._isSelected);

    !creatingInvoices &&
      createInvoicesAsync({
        templateRef,
        customers: selectedCustomers,
        items: items.map(item => ({ billableRef: item.crn })),
        filters,
        ...form,
      }).catch(e => alert(e.message));
  };

  const handleExportData = React.useCallback(() => {
    const selectedCustomers = customers.filter(s => s._isSelected);
    exportInvoiceData({
      templateRef,
      customers: selectedCustomers,
      items: items.map(item => ({ billableRef: item.crn })),
      filters,
      ...form,
    }).catch(e => alert(e.message));
  }, [customers, exportInvoiceData, templateRef, items, filters, form]);

  const selectedCount = React.useMemo(() => {
    return customers?.filter?.(c => c._isSelected)?.length || 0;
  }, [customers]);

  const handleSelectAll = React.useCallback(() => {
    if (selectedCount === customers?.length) {
      selectAll({ deselectAll: true });
    } else {
      selectAll();
    }
  }, [customers?.length, selectAll, selectedCount]);

  return (
    <Styled className={cn('preview-header')}>
      <SelectInvoiceTemplate onChange={setTemplateRef} />
      <div className="buttons">
        <Button onClick={handleSelectAll} metricId="core-unbilled-deliveries-review-select-deselect-all">{`${selectedCount === customers?.length ? 'Deselect' : 'Select'}`} All</Button>
        <InvoiceButton
          selectedCount={selectedCount}
          onInvoice={handleCreateAsync}
          onExport={handleExportData}
          handleChange={handleChange}
          form={form}
          busy={creatingInvoices}
          progress={progress}
        />
      </div>
    </Styled>
  );
};

export default PreviewHeader;
