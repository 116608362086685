import React from "react";
import cn from 'classnames';
import style from './style';
import styled from 'styled-components';
import { kebabCase } from 'lodash';
import Input from "../../../../components/form/Input";
import FieldContainer from "../../../../components/field-container/FieldContainer";

const View = styled(FieldContainer)`${style}`;

const {TextArea} = Input;

const InputTextArea = ({ className, id, label, value, addonAfter, onChange, disabled = false, innerRef, required }) => {
  return (
    <View className={cn(id, className)}>
      <div className="label-spinner">
        <div className="label">{`${label}${required ? ' *' : ''}`}</div>
      </div>
      <TextArea
        value={value || ''}
        disabled={disabled}
        innerRef={innerRef}
        addonAfter={addonAfter}
        onChange={e => (onChange ? onChange(id, e.target?.value) : console.log(id, e.target?.value))}
        data-testid={`input-${kebabCase(label?.toLowerCase?.())}`}
      />
    </View>
  );
};

export default InputTextArea;
