import { uomValues, uomSlump, uomTemp } from '../../../../../util/uom/uom-codes-assurance';

export const status = [{ value: 'ACTIVE' }, { value: 'INACTIVE' }];

export const classType = [
  { value: 'air', label: 'Air' },
  { value: 'consistence', label: 'Consistence' },
  { value: 'slumpLossRate', label: 'Slump Loss Rate' },
  { value: 'temperature', label: 'Temperature' },
];

export const classSubType = {
  consistence: [
    { value: 'slump', label: 'Slump' },
    { value: 'flow', label: 'Flow' },
    { value: 'spread', label: 'Spread' },
  ],
};

export const classTypeValueLabels = [
  { value: 'air', label: '%' },
  { value: 'consistence', label: '' },
  { value: 'slumpLossRate', label: '' },
  { value: 'temperature', label: '' },
];

export const consistenceUomOptions = () => uomValues(uomSlump);

export const tempUomOptions = () => uomValues(uomTemp);
