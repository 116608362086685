import { useMapBehaviorContext } from '../../context/MapBehaviorContext';
import { useMapStateContext } from '../../context/MapStateContext';

export const useWaypoints = () => {
  const { allowRouting, canUpdate, disabled, waypoints } = useMapStateContext();
  const { updateWaypoint, deleteWaypoint } = useMapBehaviorContext();

  return waypoints?.map?.(waypoint => {
    return {
      canUpdate: canUpdate && !disabled && allowRouting,
      updateWaypointMarker: updateWaypoint,
      key: waypoint.id,
      ID: waypoint.id,
      data: waypoint,
      deleteWaypoint,
      type: 'waypoint',
    };
  });
};
