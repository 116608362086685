import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { Translate } from '../../localization/Translate';
import style from './style';
import { useOrderContext } from '../../useOrderContext';
import OrderAudit from './OrderAudit';

const Header = styled.div`
  font-size: 1.2em;
  margin-top: 25px;
  margin-bottom: 10px;
  cursor: pointer;
`;

const Audit = ({ className }) => {
  const {
    form: { form },
  } = useOrderContext();

  return (
    <div className={cn('other', className)}>
      <Header>
        <Translate stringId="changesToOrder" defaultMessage="Additions and Changes to Order" />
      </Header>
      {form?.linkedOrders?.map((lo, index) => {
        return <OrderAudit index={index} orderRef={lo.orderRef || lo.crn} />;
      })}
    </div>
  );
};

export default styled(Audit)`
  ${style}
`;
