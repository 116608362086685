import React from 'react';
import { permissionsMapping } from './Has';
import { useUserContext } from './user-context/UserContextProvider';

export const usePermissions = () => {
  const {
    permissions: { authorities, groups, assignedRoles },
  } = useUserContext();

  const isPaPs = React.useMemo(() => {
    return groups.includes('admin') || groups.includes('support');
  }, [groups]);

  const isCompanyAdmin = React.useMemo(() => {
    return !!assignedRoles?.['company-admin'];
  }, [assignedRoles]);

  const has = React.useCallback(
    (permission, paPSOverride = true, caOverride = true) => {
      const hasPermission = !!authorities[permissionsMapping[permission]] || (paPSOverride && isPaPs) || (caOverride && isCompanyAdmin);
      return !!hasPermission;
    },
    [authorities, isCompanyAdmin, isPaPs]
  );

  const hasNot = React.useCallback(
    (permission, paPSOverride = true, caOverride = true) => {
      const hasPermission = !!authorities[permissionsMapping[permission]] || (paPSOverride && isPaPs) || (caOverride && isCompanyAdmin);
      return !hasPermission;
    },
    [authorities, isCompanyAdmin, isPaPs]
  );

  return { has, hasNot };
};
