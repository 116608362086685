const getFactors = number => {
  let factors = [];
  for (let x = 1; x <= number; x++) {
    if (number % x === 0) {
      factors.push(x);
    }
  }
  return factors;
};

const factorsDivisibleByFive = factors => {
  return factors.filter(factor => factor % 5 === 0);
};

export default number => {
  const allFactors = getFactors(number);

  const preferredFactors = factorsDivisibleByFive(allFactors);

  return preferredFactors.reduce((acc, factor) => {
    const step = number / factor;

    if ([4, 3, 2].includes(step) && acc === 0) {
      acc = factor;
    }
    return acc;
  }, 0);
};
