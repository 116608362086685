import React from 'react';
import styled from 'styled-components';
import FlexColumn from '../../order/components/FlexColumn';
import style from './style';
import SetupItemList from '../setup-item-list';
import { columns, prototype } from './setup';
import WorkTypeGeneral from './work-type-general';

const tabs = [
  {
    label: 'General',
    component: WorkTypeGeneral,
  },
];

const Styled = styled(FlexColumn)`
  ${style}
`;

const SetupWorkTypes = () => {
  return (
    <Styled>
      <SetupItemList
        typeId="work-type"
        type="Work Type"
        editorType="tabs"
        tabs={tabs}
        columns={columns}
        prototype={prototype}
      />
    </Styled>
  );
};

export default SetupWorkTypes;
