import React from 'react';
import { Badge } from 'reactstrap';
import moment from 'moment';

const LATE_MINUTES = 5;

const SyncStatusBadge = ({ ticket, className, showIssue = false }) => {
    // TODO: I18N: Obtain all known statuses so that we can setup/use translations.
    const syncStatuses = {
        apex: ticket?.syncStatus?.APEX || {}, // { status: 'SYNCED', timestamp: new Date().toISOString() };
        cb: ticket?.syncStatus?.COMMAND_BATCH || {}, // { status: 'RESEND', timestamp: new Date().toISOString() };
        int: ticket?.syncStatus?.INTEGRA || {},
        cs:
            ticket?.syncStatus?.COMMAND_SERIES ||
            ticket?.syncStatus?.COMMAND_SERIES_V2 ||
            ticket?.syncStatus?.COMMAND_SERIES_V3 ||
            {},
        ulink: ticket?.syncStatus?.ULINK || {},
    };

    const isLate = status => {
        if (status?.isLate !== undefined) {
            return status.isLate;
        }
        return (
            status?.timestamp &&
            status?.status?.endsWith('QUEUED') &&
            moment().subtract(moment(status.timestamp)).minutes() > LATE_MINUTES
        );
    };

    const getColor = status => {
        if (status?.issue || isLate(status)) {
            return 'danger';
        }
        switch (status?.status) {
            case 'QUEUED':
            default:
                return 'warning';
            case 'DEQUEUED':
                return 'info';
            case 'RESEND':
                return 'action';
            case 'SYNCED':
                return 'success';
        }
    };

    const badges = Object.keys(syncStatuses).map(key => {
        const syncStatus = syncStatuses[key];
        syncStatus.isLate = isLate(syncStatus);
        const statusText = syncStatus.issue ? 'ISSUE' : syncStatus.status;
        const issue =
            showIssue && syncStatus.issue ? (
                <Badge
                    id={`${key}-status-badge-issue`}
                    className={className}
                    style={{ border: '1px solid #EF4136', backgroundColor: 'transparent', color: '#EF4136' }}
                    pill
                >
                    {syncStatus.issue}
                </Badge>
            ) : null;
        return (
            syncStatus.status && (
                <>
                    {issue}
                    <Badge
                        id={`${key}-status-badge`}
                        className={className}
                        pill
                        style={{ border: '1px solid #EF4136', backgroundColor: 'transparent', color: '#EF4136' }}
                        title={syncStatus.issue || null}
                    >
                        {key.toUpperCase()}: {statusText}
                    </Badge>
                </>
            )
        );
    });

    return badges;
};

export default SyncStatusBadge;
