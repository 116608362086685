import style from '../style';
import React from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';
import InputText from '../../../components/input-text';
import InputSelect from '../../../components/input-select';
import InputNumber from '../../../components/input-number';

import { status, taxBasis, primaryProductType } from '../options';
import InputAddress from '../../../components/input-address';
import InputCheckbox from '../../../components/input-checkbox';
import InputColor from '../../../components/input-color';
import useSetup from '../../../useSetup';
import SelectDispatchLocation from '../../../components/select-dispatch-location';
import SelectPDFTemplate from '../../../components/select-pdf-template';

const View = styled.div`
  ${style}
`;

const LocationGeneral = ({ location, onChange }) => {
  const { entityRef } = useParams();
  const { companyLicenses, getCompanyLicenses } = useSetup();

  React.useEffect(() => {
    getCompanyLicenses(entityRef).then();
  }, [entityRef, getCompanyLicenses]);

  const isMultiCompanyEnabled = React.useMemo(
    () => companyLicenses?.find(l => l.id === 'multi-company'),
    [companyLicenses]
  );

  return (
    <View>
      <InputText id="id" label="ID" value={location?.id} onChange={onChange} />
      <InputText id="name" label="Name" value={location?.name} onChange={onChange} />
      {isMultiCompanyEnabled && (
        <InputText id="companyId" label="Company" value={location?.companyId} onChange={onChange} />
      )}
      <InputAddress id="address" item={location} onChange={onChange} />

      <InputSelect
        id="primaryProductType"
        label="Primary Product Type"
        value={location?.primaryProductType ?? 'CONCRETE'}
        onChange={onChange}
        options={primaryProductType}
        required
      />

      <InputSelect
        id="taxBasis"
        label="Default Tax Basis"
        value={location?.taxBasis ?? 'location'}
        onChange={onChange}
        options={taxBasis}
        required
      />

      {location?.taxBasis === 'dispatchLocation' && (
        <SelectDispatchLocation
          id="dispatchLocationRef"
          label="Dispatch Location"
          value={location?.dispatchLocationRef}
          onChange={onChange}
          disabled={location?.isDispatchLocation}
        />
      )}

      <InputNumber
        id="availableVehicles"
        label="Number of Available Vehicles"
        min={1}
        max={10}
        value={location?.availableVehicles || 0}
        onChange={onChange}
      />
      <InputNumber
        id="overMixingTime"
        label="Allowed Over Mix Time (seconds)"
        value={location?.overMixingTime}
        onChange={onChange}
      />
      <InputText
        id="dotCertification"
        label="DOT Certification"
        value={location?.dotCertification}
        onChange={onChange}
      />
      <InputSelect id="status" label="Status" value={location?.status} onChange={onChange} options={status} required />

      <div class="control-row">
        <div class="control-col-6">
          <InputColor
            id="defaultLocationColor"
            label="Default Location Color"
            value={location?.defaultLocationColor}
            defaultValue={'#32CD32'}
            onChange={onChange}
          />
        </div>

        <div class="control-col-6">
          <InputColor
            id="defaultTruckColor"
            label="Default Truck Color"
            value={location?.defaultTruckColor}
            defaultValue={'#40E0D0'}
            onChange={onChange}
          />
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
        <div style={{ marginRight: '20px' }}>
          <InputCheckbox
            id="defaultValue"
            label="Default"
            value={location?.defaultValue ?? false}
            onChange={onChange}
          />
        </div>
        <div>
          <InputCheckbox
            id="isDispatchLocation"
            label="Is Dispatch Location"
            value={location?.isDispatchLocation ?? false}
            onChange={onChange}
            disabled={location?.dispatchLocationRef}
          />
        </div>
      </div>
      <br />
      <SelectPDFTemplate
        id="ticketTemplate"
        label="Location Ticket Template"
        value={location?.ticketTemplate}
        onChange={onChange}
      />
    </View>
  );
};

export default LocationGeneral;
