import { css } from 'styled-components';

export default css`
  width: 100%;

  .label {
    margin-bottom: 3px;
  }

  .label-order-screen {
    margin-bottom: -3px;
  }
`;
