import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Translate } from '../../../../../order/components/localization/Translate';
import { useTranslateTokens } from '../../../../../order/components/localization/useTranslateTokens';
import style from './style';
import styled from 'styled-components';
import { find, pick } from 'lodash';
import InputCurrency from '../../../../../setup/components/input-currency';
import InputText from '../../../../../setup/components/input-text';
import InputQuantity from '../../../../../setup/components/input-quantity';
import InputSelect from '../../../../../setup/components/input-select';
import InputCheckbox from '../../../../../setup/components/input-checkbox';
import FieldContainer from '../../../../../../components/field-container/FieldContainer';
import Button from '../../../../../../components/button/Button';

const Styled = styled.tbody`
  ${style}
`;

const labelDef = {
  fields: {
    quantity: 'Quantity',
    productIdName: 'Product ID/Name',
    pricingSource: 'Pricing Source',
    manualOverride: 'Manually Override',
    price: 'Price',
    taxes: 'Taxes',
  },
  namespace: 'invoices',
};

const ProductEntry = ({ id, item, products, onChange }) => {
  const [productList, setProductList] = useState(null);

  useEffect(() => {
    if (products) {
      const mappedProducts = products.map(p => {
        return {
          value: p.crn,
          label: `${p.id} / ${p.name}`,
        };
      });
      if (item.productRef) {
        mappedProducts.push({
          value: item.productRef,
          label: `${item.productId} / ${item.productName}`,
        });
      }
      setProductList(mappedProducts);
    }
  }, [products]);

  const productDescription = useMemo(() => {
    return `${item?.productId} / ${item?.productName}`;
  }, [item]);

  const labels = useTranslateTokens(labelDef);

  return (
    <tr key={`${id}-product-entry`}>
      <td>
        <InputQuantity
          id="quantity"
          label={labels.quantity}
          value={item?.quantity}
          uomCode={item?.uomCode}
          onChange={onChange}
          defaultValue={0}
          // disabled={!item.isAdded}
        />
      </td>
      <td colSpan={3}>
        {!item?.isAdded && (
          <InputText label={labels.productIdName} disabled className="product-description" value={productDescription} />
        )}

        {item?.isAdded && (
          <InputSelect
            id="productRef"
            label={labels.productIdName}
            value={item?.productRef}
            onChange={onChange}
            options={productList}
            required
          />
        )}
      </td>
      {item?.priceSource && (
        <td colSpan={2}>
          <InputText label={labels.pricingSource} disabled className="product-description" value={item?.priceSource} />
        </td>
      )}
      <td colSpan={2}>
        <FieldContainer>
          <div className="manual-label">{labels.manualOverride}</div>
          <table>
            <tbody>
              <tr>
                <td>
                  <InputCheckbox
                    id="manualPrice"
                    label={labels.price}
                    value={item?.['manualPrice'] === true}
                    onChange={onChange}
                    metricId="core-unbilled-deliveries-billable-details-manually-override-price"
                  />
                </td>
                <td>
                  <InputCheckbox
                    id="manualTax"
                    label={labels.taxes}
                    value={item?.['manualTax'] === true}
                    onChange={onChange}
                    metricId="core-unbilled-deliveries-billable-details-manually-override-taxes"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </FieldContainer>
      </td>
    </tr>
  );
};

const PricingHeader = ({ id, taxRate }) => {
  return (
    <tr key={`${id}-pricing-header`}>
      <td>
        <Translate stringId="invoices.unitPrice" defaultMessage="Unit Price" />
      </td>
      <td>
        <Translate stringId="invoices.subtotal" defaultMessage="Subtotal" />
      </td>
      {taxRate?.taxPercentage ? (
        <td>
          <Translate
            stringId="invoices.salesTaxPct"
            values={{ rate: taxRate?.taxPercentage?.sales }}
            defaultMessage="Sales Tax ({rate, number, ::percent .00})"
          />
        </td>
      ) : (
        <>
          <td>
            <Translate
              stringId="invoices.stateTaxPct"
              values={{ rate: taxRate?.state?.sales }}
              defaultMessage="State Tax ({rate, number, ::percent .00})"
            />
          </td>
          <td>
            <Translate
              stringId="invoices.countyTaxPct"
              values={{ rate: taxRate?.county?.sales }}
              defaultMessage="County Tax ({rate, number, ::percent .00})"
            />
          </td>
          <td>
            <Translate
              stringId="invoices.cityTaxPct"
              values={{ rate: taxRate?.city?.sales }}
              defaultMessage="City Tax ({rate, number, ::percent .00})"
            />
          </td>
          <td>
            <Translate
              stringId="invoices.districtTaxPct"
              values={{ rate: taxRate?.district?.sales }}
              defaultMessage="District Tax ({rate, number, ::percent .00})"
            />
          </td>
        </>
      )}
      <td>Total</td>
    </tr>
  );
};

const PriceEntry = ({ id, item, onChange, taxRate }) => {
  return (
    <tr key={`${id}-price-entry`}>
      <td>
        <InputCurrency
          id="unitPrice"
          disabled={!item['manualPrice']}
          addOn={'USD'}
          value={item?.unitPrice}
          onChange={onChange}
        />
      </td>
      <td>
        <InputCurrency id="extendedPrice" value={item?.extendedPrice} disabled />
      </td>
      {taxRate?.taxPercentage ? (
        <td>
          <InputCurrency id="salesTax" disabled={!item['manualTax']} value={item?.salesTax} onChange={onChange} />
        </td>
      ) : (
        <>
          <td>
            <InputCurrency id="stateTax" disabled={!item['manualTax']} value={item?.stateTax} onChange={onChange} />
          </td>
          <td>
            <InputCurrency id="countyTax" disabled={!item['manualTax']} value={item?.countyTax} onChange={onChange} />
          </td>
          <td>
            <InputCurrency id="cityTax" disabled={!item['manualTax']} value={item?.cityTax} onChange={onChange} />
          </td>
          <td>
            <InputCurrency
              id="districtTax"
              disabled={!item['manualTax']}
              value={item?.districtTax}
              onChange={onChange}
            />
          </td>
        </>
      )}
      <td>
        <InputCurrency id="totalPrice" value={item?.totalPrice} disabled />
      </td>
    </tr>
  );
};

const fields = [
  'quantity',
  'uomCode',
  'unitPrice',
  'stateTax',
  'salesTax',
  'countyTax',
  'cityTax',
  'districtTax',
  'extendedPrice',
  'totalPrice',
  'productId',
  'productName',
  'isAdded',
  'manualPrice',
  'manualTax',
  'productRef',
  'priceSource',
];

const LineItem = ({ lineItem, products, index, onChange, canRemove = false, taxRate }) => {
  const [item, setItem] = useState(null);

  useEffect(() => {
    lineItem &&
      setItem({
        index,
        ...pick(lineItem, fields),
      });
  }, [lineItem]);

  const itemChanged = useCallback(
    (itemId, value) => {
      const changes = {
        ...item,
        [itemId]: value,
      };

      if (itemId !== 'productRef') {
        changes.productRef = item.productRef || lineItem.productRef;
      } else {
        const existingProduct = find(products, p => p.crn === value);
        if (existingProduct) {
          changes.productName = existingProduct.name;
          changes.productId = existingProduct.id;
        }
      }

      setItem(changes);

      onChange && onChange(changes);
    },
    [item, index, products]
  );

  const rows = [];

  if (item !== null) {
    const id = `lineItem[${index}]`;

    rows.push(
      <ProductEntry
        key={'product-entry'}
        item={item}
        products={products}
        id={id}
        onChange={itemChanged}
        canRemove={canRemove}
      />
    );

    rows.push(<PricingHeader onChange={onChange} id={id} taxRate={taxRate} />);

    rows.push(<PriceEntry item={item} id={`lineItem[${index}]`} onChange={itemChanged} taxRate={taxRate} />);
  }

  return (
    <>
      {canRemove && (
        <div className="button-container">
          <Button
            className="remove-product"
            size="small"
            onClick={() => itemChanged('isRemoved', true)}
            metricId="core-unbilled-deliveries-billable-details-remove-product"
          >
            <i className="fas fa-minus" />
            <Translate stringId="invoices.removeProduct" defaultMessage="Remove Product" />
          </Button>
        </div>
      )}
      <table>
        <Styled>{rows}</Styled>
      </table>
    </>
  );
};

export default LineItem;
