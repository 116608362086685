import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import BaseMap from '../../../../../../components/sdk-map';
import { useOrderListContext } from '../../../../components/OrderListContext';
import { useOrder } from '../../../../view-or-edit-order/useOrder';
import DestinationMarker from './destination-marker';
import style from './style';
import TicketMarker from './ticket-marker';

const Styled = styled.div`
  ${style}
`;

const NoMapInfo = () => {
  return (
    <div className="no-map">
      <span>Map displays when loads are in transit or when the delivery address has been geo-coded.</span>
    </div>
  );
};

const OrderTracker = ({ order, tickets }) => {
  const { listOrderTicketGeoLocations, geoLocations } = useOrder();
  const { persona } = useOrderListContext();
  const [markers, setMarkers] = useState(null);
  const [showMap, setShowMap] = useState(false);
  const [destination, setDestination] = useState(null);
  const [map, setMap] = useState(null);
  const autoRefresh = 30000;

  const timeoutRef = React.useRef(null);
  const initiateAutoRefresh = React.useCallback(() => {
    timeoutRef.current = setTimeout(() => {
      if (tickets && order?.crn) {
        listOrderTicketGeoLocations(order.crn, persona === 'buyer' ? order?.supplierParty?.entityRef : undefined).then(() => { initiateAutoRefresh()}).catch(
          console.error
        );
      }
    }, autoRefresh);
  }, [autoRefresh, listOrderTicketGeoLocations]);

  React.useEffect(() => {
    return () => {
      clearTimeout(timeoutRef?.current);
    };
  }, []);

  React.useEffect(() => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = null;
    initiateAutoRefresh();
  }, [initiateAutoRefresh]);

  useEffect(() => {
    if (tickets && order?.crn) {
      listOrderTicketGeoLocations(order.crn, persona === 'buyer' ? order?.supplierParty?.entityRef : undefined).catch(
        console.error
      );
    }
  }, [listOrderTicketGeoLocations, order?.crn, order?.supplierParty, tickets]);

  useEffect(() => {
    const { latitude: lat, longitude: lng } = order?.destination?.address?.coordinates || {};

    if (lat && lng) {
      setDestination({
        lat,
        lng,
      });
    } else {
      setMarkers(geoLocations);
    }
  }, [geoLocations, order?.destination?.address?.coordinates]);

  useEffect(() => {
    destination && setMarkers(geoLocations);
  }, [destination, geoLocations]);

  useEffect(() => {
    setShowMap(destination || markers?.length > 0);
  }, [destination, markers]);

  return (
    <Styled className="order-tracker">
      {(showMap && Array.isArray(markers) && (
        <BaseMap onLoad={setMap} bounds={[].concat(destination, markers)} zoom={15}>
          {destination && <DestinationMarker order={order} point={destination} />}
          {markers?.map &&
            markers?.map(ticket => <TicketMarker key={`${ticket.id}-ticket`} order={order} ticket={ticket} />)}
        </BaseMap>
      )) || <NoMapInfo />}
    </Styled>
  );
};

export default OrderTracker;
