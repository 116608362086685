import SelectSetupItem from "../select-setup-item";
import React from "react";

const SelectMix = ({item, onChange}) => {
  const changeMix = (id, value) => {
    onChange('mix', value)
  }

  const params = { filter: 'unassignedOnly', productRef: item?.crn };

  return (
    <SelectSetupItem
      id='mixRef'
      label='Mix Design'
      typeId='mix'
      showIdWithLabel
      setupItemRef={item?.mix?.mixRef}
      params={params}
      onChange={changeMix} />
  )
}

export default SelectMix;
