import React, {useMemo} from 'react';
import { Table } from 'reactstrap';
import styled from 'styled-components';
import { Translate } from '../../../../components/localization/Translate';
const _ = require('lodash');

const getSortValue = itemType => {
  let value = 4;
  switch (itemType) {
    case 'AGGREGATE':
      value = 0;
      break;
    case 'CEMENTITIOUS':
      value = 1;
      break;
    case 'WATER':
      value = 2;
      break;
    case 'ADMIXTURE':
      value = 3;
      break;
    default:
      value = 4;
      break;
  }
  return value;
};

const BatchData = ({ className = '', consumptions, primaryProduct, primaryLineItem }) => {

  const mappedConsumptions = useMemo(() => {
    let result = consumptions.sort(function (a, b) {
      let aValue = getSortValue(a.item.itemType);
      let bValue = getSortValue(b.item.itemType);
      return aValue - bValue;
    });

    result = result.map(v => {
      const varianceResult = { ...v };
      if (isNaN(parseFloat(v?.targetQuantity?.value)) || isNaN(parseFloat(v?.actualQuantity?.value))) {
        varianceResult.percentVariance = '';
      } else if (v.targetQuantity?.value === v.actualQuantity?.value) {
        varianceResult.percentVariance = '0';
      } else {
        varianceResult.percentVariance = Number(
          (((v.actualQuantity?.value - v.targetQuantity?.value) * 100) / parseFloat(v.targetQuantity?.value)).toFixed(2)
        );
        if (varianceResult.percentVariance === Infinity) {
          varianceResult.percentVariance = '0';
        }
      }
      return varianceResult;
    });

    return result;
  }, [consumptions])

  const maxWatCemRatio = useMemo(() => {
      return _.get(primaryProduct, 'maxWatCemRatio');
  }, [primaryProduct])

  return (
    <div className={className}>
      <Table striped responsive style={{width: '100%'}}>
        {primaryLineItem && primaryLineItem?.item?.itemType === 'CONCRETE' && (
          <>
            <thead>
            <tr>
              <th id="maxWaterToAdd_header" style={{ textAlign: 'center' }}>
                <Translate stringId="maxWaterAdd" defaultMessage="Max Water/Add"/>
              </th>
              <th id="actualWatCemRatio_header" style={{ textAlign: 'center' }}>
                <Translate stringId="actualWC" defaultMessage="Actual W/C"/>
              </th>
              <th id="maxWatCemRatio_header" style={{ textAlign: 'center' }}>
                <Translate stringId="maxWC" defaultMessage="Max W/C"/>
              </th>
            </tr>
            </thead>
            <tbody>
            <tr className="batch-data-row">
              <td className={(className = 'batch-data-cell')} style={{ textAlign: 'center' }}>
                {_.get(primaryProduct, 'maxWaterToAdd.value', '')}{' '}
                {_.get(primaryProduct, 'maxWaterToAdd.uomDisplay', '')}
              </td>
              <td className={(className = 'batch-data-cell')} style={{ textAlign: 'center' }}>
                {_.get(primaryProduct, 'actualWatCemRatio', '')}
              </td>
              <td className={(className = 'batch-data-cell')} style={{ textAlign: 'center' }}>
                {maxWatCemRatio ?? ''}
              </td>
            </tr>
            </tbody>
          </>
        )}
        <thead>
        <tr>
          <th id="material-header" style={{ width: '40%' }}>
            <Translate stringId="material" defaultMessage="Material"/>
          </th>
          <th id="batched-header" style={{ textAlign: 'left' }}>
            <Translate stringId="batched" defaultMessage="Batched"/>
          </th>
          <th id="variance-header" style={{ textAlign: 'right' }}>
            <Translate stringId="variance" defaultMessage="Variance"/>
          </th>
          <th id="moisture-header" style={{ textAlign: 'right' }}>
            <Translate stringId="moisturePercentage" defaultMessage="Moisture %"/>
          </th>
        </tr>
        </thead>
        <tbody>
        {mappedConsumptions.map((consumption, key) => {
          return (
            <tr className="batch-data-row" key={key}>
              <td className={(className = 'batch-data-cell')} style={{ width: '40%' }}>
                {(consumption.item || {}).description}
              </td>
              <td className={(className = 'batch-data-cell')} style={{ textAlign: 'left' }}>
                {(consumption.actualQuantity || {}).value} {(consumption.actualQuantity || {}).uomDisplay}
              </td>
              <td className={(className = 'batch-data-cell')} style={{ textAlign: 'right' }}>
                {consumption.percentVariance}
              </td>
              <td className={(className = 'batch-data-cell')} style={{ textAlign: 'right' }}>
                {(consumption.moistureSSDPercent && consumption.moistureSSDPercent > 0) ? `${consumption.moistureSSDPercent} %` : ''}
              </td>
            </tr>
          );
        })}
        </tbody>
      </Table>
    </div>
  );
};

const StyledBatchData = styled(BatchData)`
  display: grid;

  .batch-data-cell {
    border-bottom: 1px solid #5e5e60;
    padding: 2px 2px 10px 2px;
  }
`;

export default StyledBatchData;
