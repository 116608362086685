import React, { useEffect } from 'react';
import styled from 'styled-components';
import FlexColumn from '../../order/components/FlexColumn';
import useSetup from '../useSetup';
import { useParams } from 'react-router';
import style from './style';
import SetupTable from '../components/setup-table';
import Editor from './credit-code-editor';
import { columns, prototype } from './setup';

const Styled = styled(FlexColumn)`
  ${style}
`;

const SetupCreditCode = () => {
  const { entityRef } = useParams();
  const { getSetupItems, setupItems, busy } = useSetup('credit-code');
  const params = { activeOnly: false };

  useEffect(() => {
    entityRef && getSetupItems(params);
  }, [entityRef, getSetupItems]);

  return (
    <Styled>
      <SetupTable
        busy={busy}
        type="Credit Code"
        columns={columns}
        items={setupItems}
        Editor={Editor}
        prototype={prototype}
        onRefresh={() => getSetupItems(params)}
      />
    </Styled>
  );
};

export default SetupCreditCode;
