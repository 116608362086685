import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
import FieldContainer from '../../field-container/FieldContainer';
import Input from '../../form/Input';
import style from '../../../views/order-request/view-or-edit-order/style';

const Supplier = ({ className, value }) => {
  return (
    <FieldContainer className={cn('suppliers', className)}>
      <div className="label-spinner">
        <div className="label">Supplier</div>
        <div className={cn('spinner', { spinning: false })}>
          <span className="fas fa-sync fa-spin" />
        </div>
      </div>

      <Input type="text" value={value || 'Unknown'} disabled />
    </FieldContainer>
  );
};

export default styled(Supplier)`
  ${style}
`;
