import { isUndefined } from 'lodash';
import React from 'react';
import { FormattedNumber } from 'react-intl';

export const Currency = ({ value, ifFalsy, currency }) => {
  if (!isUndefined(ifFalsy) && !value) {
    return ifFalsy;
  }

  return <FormattedNumber value={value} style="currency" currency={currency} />;
};
