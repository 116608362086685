import React from "react";
import styled from 'styled-components';
import FlexColumn from "../../order/components/FlexColumn";
import style from './style';
import SetupItemList from "../setup-item-list";
import {columns, prototype} from "./setup";
import OrderStatusGeneral from "./order-status-general";

const Styled = styled(FlexColumn)`${style}`;

const tabs = [
  {
    label: 'General',
    component: OrderStatusGeneral,
  },
]

const SetupOrderStatuses = () => {
  return (
    <Styled>
      <SetupItemList
        typeId='status-code'
        type='Order Status'
        columns={columns}
        prototype={prototype}
        editorType='tabs'
        tabs={tabs}
      />
    </Styled>
  )
}

export default SetupOrderStatuses;
