import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { api } from '../api';

const initialState = { jobs: null, products: null, quantity: '' };

export const useStartOrderRequest = () => {
  const { entityRef } = useParams();
  const [response, setResponse] = useState(initialState);
  const [startOrderBusy, setStartOrderBusy] = useState(false);

  const startOrder = useCallback(
    supplierRef => {
      setResponse(initialState);
      setStartOrderBusy(true);

      return api
        .startOrderRequest(entityRef, supplierRef, {
          productType: 'CONCRETE',
        })

        .then(resp => {
          setResponse(resp);
          return resp;
        })
        .finally(() => {
          setStartOrderBusy(false);
        });
    },
    [entityRef]
  );

  return { startOrder, response, startOrderBusy };
};
