import React, {useEffect, useState} from "react";
import moment from "moment";
import cn from "classnames";
import {useDashboardContext} from "../../context/useDashboardContext";

const TimerColumn = ({column}) => {
  const [timerValue, setTimerValue] = useState(0);
  const {licenses} = useDashboardContext();

  const { } = column;

  useEffect(() => {
    setTimerValue(moment.duration(moment().diff(column.value)).asMinutes())
    const interval = setInterval(() => {
      setTimerValue(moment.duration(moment().diff(column.value)).asMinutes())
    }, 60000)
    return () => {
      clearInterval(interval);
    }
  }, [column]);

  let timerLate = false;
  if(licenses?.orders) {
    timerLate = moment().isAfter(column.value);
  }

  return <div className={cn({'timer-late': timerLate})}>
    {Math.abs(Math.round(timerValue))}
  </div>;
}

export default TimerColumn;