import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import FlexColumn from '../order/components/FlexColumn';
import SetupDrivers from './setup-drivers/DriverTrailers';
import SetupProducts from './setup-products';
import SetupTrailers from './setup-trailers/SetupTrailers';
import style from './style';
import { IntlProvider } from 'react-intl';
import { IntlContext } from '../../i18n';
import SetupLocations from './setup-locations';
import SetupCustomers from './setup-customers';
import SetupProjects from './setup-projects';
import SetupVehicles from './setup-vehicles';
import SetupCarriers from './setup-carriers';
import SetupWorkTypes from './setup-work-types';
import { columns, prototype } from './setup-types/defaults';
import SetupItemList from './setup-item-list';
import SetupCostBookAdjustments from './setup-cost-book-adjustments';
import SetupCostBooks from './setup-cost-books';
import SetupCompany from './setup-company';
import SetupComponents from './setup-components';
import SetupMix from './setup-mix';
import SetupPaymentMethods from './setup-payment-methods';
import SetupRules from './setup-rules';
import SetupPourDescriptions from './setup-pour-descriptions';
import JobColumns from './setup-projects/job-columns';
import SetupOrderStatuses from './setup-order-statuses';
import SetupInitialization from './setup-initialization';
import SetupTrackables from './setup-trackables';
import SetupTrackableTypes from './setup-trackable-types';
import SetupProductTypes from './setup-product-types';
import { useUserContext } from '../order/components/user-context/UserContextProvider';
import SetupScale from './setup-scale';
import SetupConcreteClass from './setup-concrete-class';
import SetupCreditCode from './setup-credit-code';
import SetupVehicleTypes from './setup-vehicle-types';
import SetupCancelReasons from './setup-cancel-reasons';

const setups = {
  unauthorized: <div>Not Authorized</div>,
  initialize: <SetupInitialization />,
  products: <SetupProducts />,
  locations: <SetupLocations />,
  customers: <SetupCustomers />,
  projects: <SetupProjects />,
  jobs: <SetupProjects label="Job" suppliedColumns={JobColumns} ed showPricing={false} />,
  carriers: <SetupCarriers />,
  trackable: <SetupTrackables />,
  trailer: <SetupTrailers />,
  driver: <SetupDrivers />,
  vehicles: <SetupVehicles />,
  supplier: <SetupCompany />,
  component: <SetupComponents />,
  mix: <SetupMix />,
  rule: <SetupRules />,
  'cost-book-adjustment': <SetupCostBookAdjustments />,
  'payment-methods': <SetupPaymentMethods />,
  'cost-book': <SetupCostBooks />,
  'work-types': <SetupWorkTypes columns={columns} prototype={prototype} typeId="work-type" type="Work Type" />,
  'status-code': <SetupOrderStatuses />,
  'contact-reason': (
    <SetupItemList columns={columns} prototype={prototype} type="Contact Reason" typeId="contact-reason" />
  ),
  'contact-type': <SetupItemList columns={columns} prototype={prototype} type="Contact Type" typeId="contact-type" />,
  'shipping-method': (
    <SetupItemList columns={columns} prototype={prototype} type="Shipping Method" typeId="shipping-method" />
  ),
  'spacing-option': (
    <SetupItemList columns={columns} prototype={prototype} type="Spacing Option" typeId="spacing-option" />
  ),
  'cancel-reason-code': <SetupCancelReasons />,
  'vehicle-type': <SetupVehicleTypes />,
  'trackable-type': <SetupTrackableTypes />,
  'product-type': <SetupProductTypes />,
  'component-type': (
    <SetupItemList columns={columns} prototype={prototype} type="Component Type" typeId="component-type" />
  ),
  behavior: <SetupItemList columns={columns} prototype={prototype} type="Behavior" typeId="behavior" />,
  disciplines: <SetupItemList columns={columns} prototype={prototype} type="Discipline" typeId="discipline" />,
  'pour-descriptions': (
    <SetupPourDescriptions columns={columns} prototype={prototype} typeId="pour-description" type="Pour Descriptions" />
  ),
  scale: <SetupScale />,
  'concrete-class': <SetupConcreteClass />,
  'credit-code': <SetupCreditCode />,
};

const Styled = styled(FlexColumn)`
  ${style}
`;

const Setup = ({}) => {
  const [view, setView] = useState(null);

  const { setupType } = useParams();

  const user = useUserContext();

  useEffect(() => {
    if (setupType) {
      if (setupType === 'initialize') {
        const canInitialize = user?.permissions?.groups?.some(g => ['admin', 'support'].includes(g));

        setView(canInitialize ? 'initialize' : 'unauthorized');
      } else {
        setView(setupType);
      }
    }
  }, [setupType, user?.permissions?.groups]);

  return (
    <Styled className="setup">
      <IntlContext.Provider value={{ userLocale: 'en-US' }}>
        <IntlProvider locale={'en-US'}>{view && (setups[view] || <div>Setup {view}</div>)}</IntlProvider>
      </IntlContext.Provider>
    </Styled>
  );
};

export default Setup;
