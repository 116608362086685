import React from "react";
import Select from "../../../../components/form/Select";
import styled from 'styled-components';
import uomCodes from '../../../../util/uom/uom-codes';
import {kebabCase} from 'lodash';

const { Option } = Select;

const Title = styled.div`
  padding: 5px;
`
const byDescription = (a,b) => {
  return a.description < b.description ? -1 : 1
}

const SelectUOM = ({ id = 'uomCode', title, uomCode, onChange }) => {
  return (
    <div>
      <Title>{title || `Unit of Measure`}</Title>
      <Select id={id}
              showSearch
              filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
              className='select-uom'
              value={uomCode || ''}
              onChange={value => onChange(id, value)}
              data-testid={`select-${kebabCase((title || 'Unit of Measure')?.toLowerCase?.())}`}>
        <Option value="">Select...</Option>
        {uomCodes?.sort(byDescription).map((code, idx) => (
          <Option label={code.description} key={code.isoCode + `${idx}`} value={code.isoCode}>{code.description} ({code.display ?? code.isoCode})</Option>
        ))}
      </Select>
    </div>
  )
}

export default SelectUOM;
