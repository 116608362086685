import React from 'react';
import MapStateContextProvider from '../context/MapStateContext';
import ExistingOrderBehaviorContextProvider from './ExistingOrderBehaviorContextProvider';
import { useExistingOrderMapState } from './useExistingOrderMapState';

const ExistingOrderMapProvider = ({ readOnly, onDone, children }) => {
  const state = useExistingOrderMapState(readOnly, onDone);

  return (
    <MapStateContextProvider value={state}>
      <ExistingOrderBehaviorContextProvider>{children}</ExistingOrderBehaviorContextProvider>
    </MapStateContextProvider>
  );
};

export default ExistingOrderMapProvider;
