import React from 'react';
import { useOrderContext } from '../../useOrderContext';
import MapBehaviorContextProvider from '../context/MapBehaviorContext';
import { useExistingOrderBehavior } from './useExistingOrderBehavior';

const ExistingOrderBehaviorContextProvider = ({ children }) => {
  const {
    form: { form },
  } = useOrderContext();

  const value = useExistingOrderBehavior(form);

  return <MapBehaviorContextProvider value={value}>{children}</MapBehaviorContextProvider>;
};

export default ExistingOrderBehaviorContextProvider;
