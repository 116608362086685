import React from 'react';
import cn from 'classnames';
import style from './style';
import styled from 'styled-components';

import FieldContainer from '../../../../components/field-container/FieldContainer';
import ColorPicker from '../../../../components/form/ColorPicker';

const View = styled(FieldContainer)`
  ${style}
`;

const InputColor = ({
  className,
  id,
  label,
  value,
  defaultValue = '#000000',
  options = [],
  onChange,
  disabled = false,
  required = true,
}) => {
  const handleChange = e => {
    if (onChange) {
      onChange(id, e.target.value);
    }
  };

  return (
    <View className={cn(id, className)}>
      <div className="label-spinner">
        <div className="label">{label}</div>
      </div>
      <ColorPicker value={value || defaultValue} disabled={disabled} onChange={handleChange} />
    </View>
  );
};

export default InputColor;
