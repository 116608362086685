import { css } from 'styled-components';

export default css`
  margin-top: 5px;
  display: flex;
  flex-shrink: 0;
  overflow-wrap: break-word;

  .title {
    font-weight: bold;
    margin-right: 3px;
  }

  .text-value {
    margin-right: 6px;
  }
`;
