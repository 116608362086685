import { css } from 'styled-components';

export default css`
  padding: 10px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  flex: 1;

  .header {
    position: relative;
    display: flex;
    justify-content: center;
    margin: 10px 0 15px 0;
    .title {
      text-align: center;
      font-weight: bold;
      font-size: 1.2em;
    }
    .additional-actions {
      position: absolute;
      right: 0;
      top: 0;
      button {
      }
    }
  }

  
`;
