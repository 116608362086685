import React from 'react';
import { find } from 'lodash';
import { DateTime } from 'luxon';
import { useParams } from 'react-router-dom';
import { getCachedItem } from '../../util/cache';
import { useMasterData } from '../order/create-order/useMasterData';
import { useForm } from '../order/useForm';
import { useProjects } from '../order/useProjects';
import { api as setup } from '../setup/api/setup-items';
import useSetup from '../setup/useSetup';

export const useScaleTicketing = () => {
  const {
    getVehicles,
    vehicles,
    saveVehicle,
    getDrivers,
    drivers,
    getCarriers,
    carriers,
    customers,
    getCustomers,
    locations,
    getLocations,
    busy,
    itemBusy,
    saving,
  } = useSetup();

  const storedLocation = getCachedItem('st::location');

  const { form, handleChange } = useForm(
    {
      vehicle: null,
      location: storedLocation,
      liveWeight: false,
    },
    true
  );
  const { entityRef } = useParams();

  const { projects, projectsBusy, getProjects } = useProjects();

  React.useEffect(() => {
    getLocations(entityRef, { activeOnly: true });
    getVehicles();
    getDrivers();
    getCarriers();
    getCustomers();
  }, [entityRef, getCarriers, getCustomers, getDrivers, getLocations, getVehicles]);

  // Get customer projects
  React.useEffect(() => {
    if (form?.vehicle?.configuration?.customer) {
      getProjects(form?.vehicle?.configuration?.customer?.customerRef, { activeOnly: true });
    }
  }, [form?.vehicle?.configuration?.customer, getProjects]);

  const {
    masterData: { masterData, masterDataBusy },
    getMasterData,
  } = useMasterData();

  React.useEffect(() => {
    if (form?.vehicle?.configuration?.customer && form?.location && form?.vehicle?.configuration?.project) {
      getMasterData(
        form?.vehicle?.configuration?.customer?.customerRef,
        form?.location?.locationRef,
        form?.vehicle?.configuration?.project?.projectRef
      );
    }
  }, [
    form?.vehicle?.configuration?.customer,
    form?.location,
    form?.vehicle?.configuration?.project,
    getMasterData,
    form?.vehicle?.configuration?.project,
  ]);

  const bulkProducts = React.useMemo(() => {
    return masterData?.availableProducts?.filter?.(product => product.type === 'AGGREGATE');
  }, [masterData?.availableProducts]);

  const concreteProducts = React.useMemo(() => {
    return masterData?.availableProducts?.filter?.(product => product.type === 'CONCRETE');
  }, [masterData?.availableProducts]);

  const updateVehicleConfiguration = React.useCallback(
    async vehicle => {
      const newVehicle = {
        ...vehicle,
        configuration: {
          ...vehicle?.configuration,
          modifiedDateTime: DateTime.now().toUTC().toISO(),
        },
      };
      const response = await setup.updateSetupItem(entityRef, 'vehicle', newVehicle);
      handleChange('vehicle', response);
    },
    [entityRef, handleChange]
  );

  const weightStatus = React.useMemo(() => {
    const capturedWeight = form?.capturedWeight?.weight?.value;
    const vehicleGvw = form?.vehicle?.maxGrossVehicleWeight;

    if (!capturedWeight || !vehicleGvw) {
      return 'UNDETERMINED';
    }

    try {
      const capturedWeightInt = parseInt(capturedWeight);
      const vehicleGvwInt = parseInt(vehicleGvw);

      return capturedWeightInt <= vehicleGvwInt ? 'OK' : 'OVERWEIGHT';
    } catch (e) {
      return 'UNDETERMINED';
    }
  }, [form?.capturedWeight, form?.vehicle?.maxGrossVehicleWeight]);

  return {
    entityRef,
    vehicles,
    drivers,
    carriers,
    customers,
    projects,
    locations,
    updateVehicleConfiguration,
    bulkProducts,
    weightStatus,

    busy,
    form,
    handleChange,
    projectsBusy,
  };
};
