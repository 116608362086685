import style from './style';
import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import useSetup from '../useSetup';
import Spinner from '../../../components/spinner/Spinner';
import SetupGeneral from './setup-general';
import CommandBar from './command-bar';
import SetupTabs from './setup-tabs';
import FormError from '../components/form-error';
import SetupItemForm from './form';

const SetupItemEditor = ({
  item,
  onSave,
  onCancel,
  skipRetrievingSetupItem = false,
  removeButtons,
  disableButtons,
  params = {},
  columns = [],
}) => {
  const { typeId, editorType = 'default', tabs } = params;

  const { saveSetupItem, setupItem, getSetupItem, setSetupItem, itemBusy, saving, error } = useSetup(typeId);
  useEffect(() => {
    if (item) {
      if (item.crn && !skipRetrievingSetupItem) {
        getSetupItem(item.crn);
      } else {
        setSetupItem(item);
      }
    }
  }, [item, skipRetrievingSetupItem]);

  const onChange = React.useCallback(
    (id, value) => {
      setSetupItem(s => ({
        ...s,
        [id]: value,
      }));
    },
    [setSetupItem]
  );

  const onSaveItem = useCallback(() => {
    saveSetupItem()
      .then(result => onSave(true, result))
      .catch(() => {});
  }, [saveSetupItem, onSave]);

  const validated = React.useMemo(() => {
    const requiredFields = columns?.filter?.(c => c.required);
    if (requiredFields?.length) {
      return requiredFields.every(c => setupItem?.[c.key]);
    }
    return true;
  }, [columns, setupItem]);

  return (
    <SetupItemForm
      setupItem={setupItem}
      itemBusy={itemBusy}
      onChange={onChange}
      onCancel={onCancel}
      typeId={typeId}
      validated={validated}
      onSaveItem={onSaveItem}
      editorType={editorType}
      tabs={tabs}
      error={error}
      saving={saving}
      removeButtons={removeButtons}
      disableButtons={disableButtons}
    />
  );
};

export default SetupItemEditor;
