import { css } from 'styled-components';

export default css`
  .product-card {
    width: 100%;
    padding-top: 20px;
  }

  table {
    width: 100%;
    margin-bottom: 25px;
  }

  .schedule-input-number {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    place-content: center;
    justify-content: center;

    max-width: 100px;
  }

  .schedule-reset-button {
    grid-column: 2;
    width: 100%;
    height: 100%;
    padding: 0;
  }

  thead {
    width: 100%;
  }

  th {
    background-color: #0e4980;
    padding: 5px;
    border: solid 1px #666;
  }

  td {
    padding: 5px;
    border: solid 1px #666;
  }

  .ant-input-number {
    width: 60px;
  }

  .planning-times th {
    text-align: center;
  }

  .planning-times td {
    text-align: center;
  }

  .input-time {
    background-color: #333;
  }
`;
