import { css } from 'styled-components';

export default css`
  flex: 1;
  position: relative;
  margin-top: 30px;
  width: 2880px;
  .interval-line {
    position: absolute;
    bottom: 0;
    left: 0;
    right: -25px;
    border-top: 1px solid #444;
    z-index: -1;
  }

  .content-and-x-axis {
    display: flex;
    justify-content: center;
    position: relative;
    margin-left: 28px;

    .graph-content-wrapper {
      height: 400px;
      display: flex;
      .graph-content {
        display: flex;
        flex: 1;
      }
    }
  }
`;
