import { useOrderContext } from '../../../useOrderContext';
import React, { useCallback } from 'react';
import ProductEntryAggregate from '../aggregate/product-entry/ProductEntryAggregate';
import ProductEntryConcrete from '../concrete/product-entry/ProductEntryConcrete';
import { ORDER_TYPE } from '../../../../../../constants';
import { set } from 'lodash';
import getDefaultOrderGroupLineItem from './get-default-order-group-line-item';

const productEntry = {
  [ORDER_TYPE.AGGREGATE]: ProductEntryAggregate,
  [ORDER_TYPE.CONCRETE]: ProductEntryConcrete,
};

const OrderLineProductEntry = ({ index, linkedOrder, orderRef, primaryLineItem, otherLineItems, ...props }) => {
  const {
    orderType,
    form: { form, handleChange: orderHandleChange },
  } = useOrderContext();

  const Component = React.useMemo(() => {
    return productEntry[orderType];
  }, [orderType]);

  const handleChange = useCallback(
    (field, value) => {
      let linkedOrders = form?.linkedOrders || [getDefaultOrderGroupLineItem()];
      linkedOrders = linkedOrders.map((item, ind) => {
        if (ind === index) {
          return set(item, field, value);
        }

        return item;
      });

      orderHandleChange('linkedOrders', linkedOrders);
    },
    [index, form, orderHandleChange]
  );

  return (
    <Component
      {...props}
      handleChange={handleChange}
      index={index}
      orderRef={orderRef}
      linkedOrder={linkedOrder}
      primaryLineItem={primaryLineItem}
      otherLineItems={otherLineItems}
    />
  );
};

export default OrderLineProductEntry;
