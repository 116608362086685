import React from 'react';
import getUomAbbreviationOrDescription from '../../../util/uom';

export const columns = [
  {
    key: 'type',
    name: 'Type',
    getValue: item => {
      if (!item?.typeName) return item?.type;
      return item?.typeName !== item?.type ? `${item.type} / ${item.typeName}` : item.type;
    },
  },
  {
    key: 'id',
    name: 'ID',
  },
  {
    key: 'name',
    name: 'Name',
  },
  {
    key: 'uomCode',
    name: 'UOM Code',
    getValue: item => getUomAbbreviationOrDescription(item.uomCode),
  },
  {
    key: 'locations',
    name: 'Locations',
    getValue: item => {
      if (!item.locations) return 'None';
      if (item.locations?.all) return 'All';
      return Object.keys(item.locations).find(crn => item.locations[crn] === true) ? 'Selected' : 'None';
    },
    filterComponent: ({ innerRef, ...props }) => {
      return (
        <select ref={innerRef} {...props}>
          <option value="" />
          <option value="All">All</option>
          <option value="Selected">Selected</option>
          <option value="None">None</option>
        </select>
      );
    },
  },
  {
    key: 'taxable',
    name: 'Taxable',
    getValue: row => (row.taxable === 'NO' ? 'No' : 'Yes'),
    filterComponent: ({ innerRef, ...props }) => {
      return (
        <select ref={innerRef} {...props}>
          <option value="" />
          <option value="Yes">Yes</option>
          <option value="NO">No</option>
        </select>
      );
    },
  },
  {
    key: 'status',
    name: 'Status',
    filterFn: (item, filterStrings) => item === filterStrings[0],
    filterComponent: ({ innerRef, ...props }) => {
      return (
        <select ref={innerRef} {...props}>
          <option value="" />
          <option value="ACTIVE">Active</option>
          <option value="INACTIVE">Inactive</option>
        </select>
      );
    },
  },
];

export const prototype = {
  typeId: 'product',
  id: '',
  name: '',
  locations: [],
  type: '',
  unitPrice: 0,
  uomCode: '',
  status: 'ACTIVE',
};
