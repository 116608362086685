import React from "react";
import { getCachedItem } from '../../../util/cache';

const usePersona = () => {
  const persona = React.useMemo(() => getCachedItem('persona')?.toLowerCase?.() || 'seller', []);

  return persona;
}

export default usePersona;

