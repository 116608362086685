import styled from 'styled-components';
import { Input } from 'antd';
import cn from 'classnames';
import { useState } from 'react';

import style from './style';

import { Translate } from '../../../../../components/localization/Translate';
import FlexColumn from '../../../../../components/FlexColumn';
import Button from '../../../../../../../components/button/Button';
import { useCallback } from 'react';
import { api } from '../../../../../api';
import { useParams } from 'react-router';

const { TextArea } = Input;

const Styled = styled.div`
  ${style}
`;

const ReActivateTicket = ({ ticket, onReactivateTicket, disabled }) => {
  const { entityRef } = useParams();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [reason, setReason] = useState('');

  const handleIsOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleIsNotOpen = useCallback(() => {
    setOpen(false);
  }, []);

  const handleReasonChange = useCallback(e => {
    setReason(e.target.value);
  }, []);

  const executeReActivateTicket = useCallback(async () => {
    try {
      setLoading(true);
      await api.reActivateTicket(entityRef, ticket?.crn, { reason });
      onReactivateTicket();
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, [entityRef, ticket?.crn, reason, onReactivateTicket]);

  return (
    <Styled className={cn('cancel-void')}>
      <div className={cn('button-section', { visible: !open })}>
        <button type="button" onClick={handleIsOpen} disabled={open || disabled}>
          <Translate stringId={'reActivateTicket'} defaultMessage={'Re-Activate Ticket'} />
        </button>
      </div>
      <div className={cn('details-section-wrapper', { visible: open })}>
        <FlexColumn className="details-section">
          <FlexColumn className="note">
            <div className="label">
              <Translate stringId="reason" defaultMessage="Reason" />
            </div>
            <TextArea disabled={loading} onChange={handleReasonChange} />
          </FlexColumn>

          <div className="void-cancel-actions">
            <Button type="primary" onClick={handleIsNotOpen} disabled={loading}>
              <Translate stringId="cancel" defaultMessage={`Cancel`} />
            </Button>
            <Button type="danger" loading={loading} disabled={!reason} onClick={executeReActivateTicket}>
              <Translate stringId={'reActivateTicket'} defaultMessage={'Re-Activate Ticket'} />
            </Button>
          </div>
        </FlexColumn>
      </div>
    </Styled>
  );
};

export default ReActivateTicket;
