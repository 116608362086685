import http from '../../../http';
import queryParams from './query-params';

const orders = '/connex/services/orders';

export const api = {
  listInvoices: (supplierRef, params = '') => http.get(`${orders}/${supplierRef}/invoices${queryParams(params)}`),
  createInvoices: (supplierRef, data) => http.post(`${orders}/${supplierRef}/invoices`, { ...data, preview: false }),
  previewInvoices: (supplierRef, data) => http.post(`${orders}/${supplierRef}/invoices`, { ...data, preview: true }),
  getBilledItem: (supplierRef, billedRef) =>
    http.get(`${orders}/${supplierRef}/orders/${billedRef}`, { expand: 'billed' }),
  listInvoicesExported: (supplierRef, params = '') => http.get(`${orders}/${supplierRef}/invoices/export/history${queryParams(params)}`),
};
