import cn from 'classnames';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import FieldContainer from '../../field-container/FieldContainer';
import Input from '../../form/Input';

const StartDateTime = ({ className, disabled, value }) => {
  const friendlyDateTime = useMemo(() => {
    return value.format('LLL');
  }, [value]);

  return (
    <FieldContainer className={cn('Start Date/Time', className)}>
      <div className="label-spinner">
        <div className="label">Start Date/Time</div>
        <div className={cn('spinner', { spinning: false })}>
          <span className="fas fa-sync fa-spin" />
        </div>
      </div>
      <Input value={friendlyDateTime} disabled />
    </FieldContainer>
  );
};

export default styled(StartDateTime)``;
