
export const status = [
  { value: 'ACTIVE' },
  { value: 'INACTIVE' },
];

export const yesNo = [
  { value: 'NO' },
  { value: 'YES' },
];
