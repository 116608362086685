import React, { useEffect, useState } from 'react';
import { useTranslateObjects } from '../../../../order/components/localization/translate-objects/useTranslateObjects';
import { useTranslateMessage } from '../../../../order/components/localization/useTranslateMessage';
import { useTranslateTokens } from '../../../../order/components/localization/useTranslateTokens';
import style from './style';
import styled from 'styled-components';
import InputSelect from '../../../../setup/components/input-select';
import InputCheckbox from '../../../../setup/components/input-checkbox';
import useBillables from '../../../use-billables';

const Styled = styled.div`
  ${style}
`;
const NONE_OPTION = { value: '', label: 'NONE / None Selected' };
const LOAD_MORE_OPTION = { value: '--load-more--', label: 'Load More...' };
const DEFAULT_CPC_FLAGS = { applyAllTickets: false, reprice: true };

const labelDef = {
  fields: {
    customer: 'Customer',
    project: 'Project',
    priceBookOverride: 'Price Book Override',
    reprice: 'Reprice',
    applyToAll: 'Apply Change to All Tickets in Order',
  },
  namespace: 'invoices',
};

const General = ({ billable, projects, customers, costBooks, onChange }) => {
  const { loadCostBooks, loadMoreCustomers, loadCustomerProjects } = useBillables();
  const [fullCustomerList, setFullCustomerList] = useState(null);
  const [fullCostBooksList, setFullCostBooksList] = useState(null);
  const [customerProjectList, setCustomerProjectList] = useState(null);
  const [projectRef, setProjectRef] = useState(null);
  const [customerRef, setCustomerRef] = useState(null);
  const [costBookRef, setCostBookRef] = useState(null);
  const [cpcChange, setCpcChange] = useState(null); // cpcChange = customer, project, costbook change

  useEffect(() => {
    billable?.projectRef && setProjectRef(billable.projectRef);

    if (billable?.customerRef) {
      setCustomerRef(billable.customerRef);
      if (customers && !customers.find(c => c.value === billable.customerRef)) {
        loadActiveCustomers();
      }
    }

    if (billable?.costBookOverride) {
      setCostBookRef(billable.costBookOverride);
      loadActiveCostBooks();
    }
  }, [billable, costBooks]);

  useEffect(() => {
    if (cpcChange?.costBook?.to !== undefined) {
      cpcChange.reprice = true;
    }

    if (cpcChange?.project || cpcChange?.customer || cpcChange?.costBook) {
      onChange('cpcChange', cpcChange);
    } else {
      onChange('cpcChange', undefined);
    }
  }, [cpcChange]);

  const loadActiveCostBooks = async () => {
    const allCostBooks = await loadCostBooks(billable.orderRef);
    setFullCostBooksList([NONE_OPTION].concat(allCostBooks));
  };

  const loadActiveCustomers = async () => {
    const allCustomers = await loadMoreCustomers();
    setFullCustomerList(allCustomers);
  };

  const loadProjectList = async customerRef => {
    setCustomerProjectList(null);
    const projectList = await loadCustomerProjects(customerRef);
    if (projectList?.length) {
      setCustomerProjectList(projectList);
    }
  };

  const changeProject = (id, value) => {
    const from = cpcChange?.project?.from ?? projectRef;
    setCpcChange({
      ...DEFAULT_CPC_FLAGS,
      ...cpcChange,
      project: from !== value ? { from, to: value } : undefined,
    });
    setProjectRef(value);
  };

  const changeCostBook = (id, value) => {
    if (!value) {
      value = null;
    }

    if (value === LOAD_MORE_OPTION.value) {
      loadActiveCostBooks();
      return;
    }

    const from = cpcChange?.costBook?.from === undefined ? costBookRef : cpcChange.costBook.from;
    setCpcChange({
      ...DEFAULT_CPC_FLAGS,
      ...cpcChange,
      costBook: from !== value ? { from, to: value } : undefined,
    });
    setCostBookRef(value);
  };

  const changeCustomer = (id, value) => {
    if (value === LOAD_MORE_OPTION.value) {
      loadActiveCustomers();
      return;
    }

    loadProjectList(value);
    const fromCustomer = cpcChange?.customer?.from ?? customerRef;
    const fromProject = cpcChange?.project?.from ?? projectRef;
    setCpcChange({
      ...DEFAULT_CPC_FLAGS,
      ...cpcChange,
      customer: fromCustomer !== value ? { from: fromCustomer, to: value } : undefined,
      project: fromProject !== 'NONE' ? { from: fromProject, to: 'NONE' } : undefined,
    });
    setProjectRef('NONE');
    setCustomerRef(value);
  };

  const changeCpcFlag = (id, value) => {
    setCpcChange({
      ...cpcChange,
      [id]: value,
    });
  };

  const labels = useTranslateTokens(labelDef);

  return (
    <Styled>
      <table>
        <tbody>
          <tr>
            <td>
              <InputSelect
                id="customerRef"
                label={labels.customer}
                value={customerRef}
                onChange={changeCustomer}
                options={fullCustomerList ?? (customers?.length ? [...customers, LOAD_MORE_OPTION] : [])}
                required
              />
            </td>
            <td>
              <InputSelect
                id="projectRef"
                label={labels.project}
                value={projectRef}
                onChange={changeProject}
                options={customerProjectList ?? projects}
                required
              />
            </td>
          </tr>
          <tr>
            <td>
              <InputSelect
                id="costBookRef"
                label={labels.priceBookOverride}
                value={costBookRef}
                onChange={changeCostBook}
                options={
                  fullCostBooksList ??
                  [NONE_OPTION].concat(costBooks?.length ? [...costBooks, LOAD_MORE_OPTION] : [LOAD_MORE_OPTION])
                }
              />
            </td>
            <td>
              {cpcChange && (
                <div className="checkbox-container">
                  <InputCheckbox
                    id={'reprice'}
                    label={labels.reprice}
                    value={cpcChange.costBook?.to !== undefined || cpcChange.reprice}
                    disabled={cpcChange.costBook?.to !== undefined}
                    onChange={changeCpcFlag}
                  />
                  <InputCheckbox
                    id={'applyAllTickets'}
                    label={labels.applyToAll}
                    value={cpcChange.applyAllTickets}
                    onChange={changeCpcFlag}
                  />
                </div>
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </Styled>
  );
};

export default General;
