// Function filters array of scheduled loads to remove duplicate loads found in ticketed array
const filterScheduledLoads = (scheduled, ticketed) => {
  const loadNumbers = [];
  const ticketRefs = [];
  if (ticketed?.length) {
    ticketed.forEach(load => {
      loadNumbers.push(load.loadNumber);
      if (load.ticket?.ticketRef) {
        ticketRefs.push(load.ticket.ticketRef);
      }
    });
  }

  return scheduled?.length
    ? scheduled.filter(
        load =>
          (load.ticket?.ticketRef && !ticketRefs.includes(load.ticket.ticketRef)) ||
          !loadNumbers.includes(load.loadNumber)
      )
    : [];
};

export default filterScheduledLoads;
