import { isEmpty } from 'lodash';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useMapStateContext } from '../context/MapStateContext';

const _finalizeAcceptance = async ({
  address,
  onAcceptance,
  coordinates,
  radius,
  setIsModalVisible,
  updateProjectAddress,
}) => {
  setIsModalVisible(null);

  //TODO: Dirty check

  await onAcceptance(
    {
      ...address,
      postalCode: address.postalCode?.substr(0, 5),
      coordinates: {
        latitude: coordinates?.lat,
        longitude: coordinates?.lng,
        radius: radius,
      },
    },
    updateProjectAddress
  );
};

const _initiateAcceptance = ({
  initialAddress,
  chosenAddress,
  effectiveAddress,
  setIsModalVisible,
  finalizeAcceptance,
}) => {
  if (
    !isEmpty(initialAddress) &&
    effectiveAddress &&
    initialAddress.address1?.trim?.()?.toLowerCase?.() !== effectiveAddress.address1?.trim?.()?.toLowerCase?.()
  ) {
    setIsModalVisible([initialAddress, effectiveAddress]);
  } else if (
    chosenAddress &&
    effectiveAddress &&
    chosenAddress.address1?.trim?.()?.toLowerCase?.() !== effectiveAddress.address1?.trim?.()?.toLowerCase?.()
  ) {
    setIsModalVisible([chosenAddress, effectiveAddress]);
  } else {
    finalizeAcceptance(effectiveAddress);
  }
};

export const useAcceptance = () => {
  const { entityRef, orderId } = useParams();
  const {
    onAcceptance,
    initialAddress,
    chosenAddress,
    effectiveAddress,
    setIsModalVisible,
    effectiveCoordinates,
    effectiveRadius,
    waypoints,
    setAccepting,
  } = useMapStateContext();

  const finalizeAcceptance = React.useCallback(
    async (address, updateProjectAddress = false) => {
      setAccepting(true);
      await _finalizeAcceptance({
        address,
        onAcceptance,
        coordinates: effectiveCoordinates,
        radius: effectiveRadius,
        setIsModalVisible,
        updateProjectAddress,
      });
      setAccepting(false);
    },
    [
      effectiveCoordinates,
      effectiveRadius,
      entityRef,
      onAcceptance,
      orderId,
      setAccepting,
      setIsModalVisible,
      waypoints,
    ]
  );

  const initiateAcceptance = React.useCallback(() => {
    _initiateAcceptance({
      initialAddress,
      chosenAddress,
      effectiveAddress,
      finalizeAcceptance,
      setIsModalVisible,
    });
  }, [chosenAddress, effectiveAddress, finalizeAcceptance, initialAddress, setIsModalVisible]);

  const cancelAcceptance = React.useCallback(() => {
    setIsModalVisible(null);
  }, [setIsModalVisible]);

  return { initiateAcceptance, cancelAcceptance, finalizeAcceptance };
};
