import { v4 as uuid } from 'uuid';

const getDefaultOrderGroupLineItem = (supplierStatusRef, locationRef) => {
  return {
    supplierStatusRef,
    locationRef,
    primaryLineItem: { _id: uuid() },
    otherLineItems: [{ _id: uuid() }, { _id: uuid() }],
  };
};

export default getDefaultOrderGroupLineItem;
