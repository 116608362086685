import FlexColumn from '../../../order/components/FlexColumn';
import HoursOfOperation from './hours-of-operation/HoursOfOperation';
import style from './style';
import React, {useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';
import Button from '../../../../components/button/Button';
import useSetup from '../../useSetup';
import { useParams } from 'react-router';
import Buttons from '../../../../components/layout/Buttons';
import LocationGeneral from './location-general';
import Tabs from '../../../../components/tabs';
import Spinner from '../../../../components/spinner/Spinner';
import SelectPriceBook from '../../components/select-price-book';
import AddressMap from '../address-map';
import LocationSchedule from './location-schedule';
import LocationTaxRates from './location-tax-rates';
import LocationIntegration from './location-integration';
import FormError from '../../components/form-error';
import LocationZoneCharge from '../zone-charges';

const View = styled(FlexColumn)`
  ${style}
`;

const ButtonBar = ({ onCancel, onSave, busy }) => {
  return (
    <Buttons>
      <Button id="btn-cancel" disabled={busy} onClick={onCancel} metricId="core-setup-location-editor-cancel">
        Cancel
      </Button>
      <Button id="btn-save" loading={busy} disabled={busy} onClick={onSave} metricId="core-setup-location-editor-save">
        Save
      </Button>
    </Buttons>
  );
};

const LocationEditor = ({ item, onSave, onCancel }) => {
  const { createLocation, updateLocation, location, getLocation, setLocation, busy, error, companyLicenses, getCompanyLicenses, } = useSetup();
  const { entityRef } = useParams();
  const [tab, setTab] = useState('general');

  useEffect(() => {
    if (item) {
      if (item.crn) {
        getLocation(entityRef, item.crn).catch(alert);
      } else {
        setLocation(item);
      }
    }
  }, [entityRef, getLocation, item, setLocation]);

  const onChange = React.useCallback(
    (id, value) => {
      setLocation(s => ({
        ...s,
        [id]: value,
      }));
    },
    [setLocation]
  );

  useEffect(() => {
    getCompanyLicenses(entityRef).then();
  }, [getCompanyLicenses, entityRef]);

  const hasLocationZonesLicense = useMemo(() => {
    return !!companyLicenses.find(l => l.id === 'rmx-location-zones');
  }, [companyLicenses]);

  const onSaveLocation = React.useCallback(() => {
    if (location.crn) {
      updateLocation(entityRef, location.crn, location)
        .then(() => onSave(true))
        .catch(() => {});
    } else {
      createLocation(entityRef, location)
        .then(response => {
          getLocation(entityRef, response?.crn);
          if (tab === 'map') {
            onSave(true, true);
          } else {
            onSave(true);
          }
        })
        .catch(() => {});
    }
  }, [createLocation, entityRef, getLocation, location, onSave, tab, updateLocation]);

  const onResetTaxRates = React.useCallback(() => {
    const request = { ...location, resetTaxRates: true };
    updateLocation(entityRef, location.crn, request).then(() => {
      getLocation(entityRef, location.crn).catch(alert);
    });
  }, [location, updateLocation, entityRef, getLocation]);

  return (
    <View>
      <FormError error={error} />
      <Spinner spin={!location && busy}>
        <Tabs type="card" activeKey={tab} onChange={setTab}>
          <Tabs.TabPane tab="General" key="general" disabled={false}>
            <LocationGeneral location={location} onChange={onChange} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Hours" key="hours" disabled={false}>
            <HoursOfOperation hours={location?.hours} onChange={onChange} timeZone={location?.timeZone} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Map" key="map" disabled={false}>
            <AddressMap item={location} onChange={onChange} isNew={!location?.crn} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Scheduling" key="schedule" disabled={false}>
            <LocationSchedule location={location} onChange={onChange} />
          </Tabs.TabPane>

          <Tabs.TabPane tab="Pricing" key="pricing" disabled={false}>
            {location && <SelectPriceBook item={location} onChange={onChange} allowEmpty={true} />}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Tax Rates" key="taxrates" disabled={false}>
            <LocationTaxRates location={location} onReset={onResetTaxRates} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Integration" key="integration" disabled={false}>
            <LocationIntegration location={location} onChange={onChange} />
          </Tabs.TabPane>
          {hasLocationZonesLicense && <Tabs.TabPane tab="Zone Rates" key="zonerates" disabled={false}>
            <LocationZoneCharge zoneChargeData={location?.zoneChargeData} onChange={onChange}/>
          </Tabs.TabPane>}
        </Tabs>
        <ButtonBar onSave={onSaveLocation} onCancel={onCancel} busy={busy} />
      </Spinner>
    </View>
  );
};

export default LocationEditor;
