import React, { useEffect, useState } from 'react';
import { uniqBy } from 'lodash';
import Map from './Map';
import DevicePath from './DevicePath';
import RendezvousMarker from './RendezvousMarker';
import StartTripMarker from './StartTripMarker';
import CurrentLocationMarker from './CurrentLocationMarker';

const TicketEventsMap = ({ markers, locationEvents, statusEvents }) => {
  const [devices, setDevices] = useState(null);

  const getData = deviceId => {
    return locationEvents
      .filter(e => e.device_id === deviceId)
      .map(e => {
        return { lat: e.latitude, lng: e.longitude, seq: e.sequence };
      });
  };

  useEffect(() => {
    if (locationEvents && statusEvents) {
      setDevices(uniqBy(locationEvents, 'device_id') || []);
    }
  }, [locationEvents, statusEvents]);

  // If no positions have been reported then do not show a map
  if (!devices || devices.length < 1) {
    return <div />;
  }

  const devicePath = getData(devices[0].device_id);

  const start = (markers && markers.start) || null;
  const rendezvous = (markers && markers.rendezvous) || null;
  const center = {
    lat: (rendezvous && rendezvous.Latitude) || devicePath[devicePath.length - 1].lat,
    lng: (rendezvous && rendezvous.Longitude) || devicePath[devicePath.length - 1].lng,
  };

  const currentLocation = {
    ...devices[0],
    lat: devicePath[devicePath.length - 1].lat,
    lng: devicePath[devicePath.length - 1].lng,
    title: `Vehicle ${devices[0].vehicle}`,
  };
  console.log(locationEvents, statusEvents, currentLocation);
  return (
    <Map zoom={18} center={center} width="100%" height="60vh">
      {devices.map((device, index) => {
        const path = getData(device.device_id);

        return <DevicePath key={device.device_id} path={path} color={index} />;
      })}
      {currentLocation && <CurrentLocationMarker point={currentLocation} />}
      {rendezvous && <RendezvousMarker point={rendezvous} />}
      {start && <StartTripMarker point={start} />}
    </Map>
  );
};

export default TicketEventsMap;
