import React from 'react';
import styled from 'styled-components';
import Graph from '../new-planning-graph/PlanningGraph';
import LoadingIndicator from './LoadingIndicator';
import style from './style';

const Styled = styled.div`
  ${style}
`;

export const BarGraph = ({
  demand,
  handleMaxTrucksOverride,
  handleResetMaxTrucksOverride,
  loading,
  loads,
  maxTrucksOverride,
  startTime,
}) => {

  return (
    <Styled>
      {loading && <LoadingIndicator />}
      {loads?.loads ? (
        <Graph
          demand={demand}
          handleMaxTrucksOverride={handleMaxTrucksOverride}
          handleResetMaxTrucksOverride={handleResetMaxTrucksOverride}
          loads={loads}
          maxTrucks={demand?.locationVehicles}
          date={startTime}
          maxTrucksOverride={maxTrucksOverride}
        />
      ) : null}
    </Styled>
  );
};
