import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import InputText from '../../../components/input-text';
import style from './style';
import FormError from '../../../components/form-error';

const Styled = styled.div`
  ${style}
`;
const General = ({ trailer, onChange, error }) => {
  return (
    <Styled className={cn('general')}>
      <FormError error={error} />
      <InputText id="id" label="ID" value={trailer?.id} onChange={onChange} />
      <InputText id="name" label="Name" value={trailer?.name} onChange={onChange} />
      <InputText
        id="maximumGrossVehicleWeight"
        label="Max GVW"
        value={trailer?.maximumGrossVehicleWeight}
        onChange={onChange}
      />
      <InputText
        id="maximumNetVehicleWeight"
        label="Max Net"
        value={trailer?.maximumNetVehicleWeight}
        onChange={onChange}
      />
      <InputText
        id="targetGrossVehicleWeight"
        label="Target Gross"
        value={trailer?.targetGrossVehicleWeight}
        onChange={onChange}
      />
      <InputText
        id="targetNetVehicleWeight"
        label="Target Net"
        value={trailer?.targetNetVehicleWeight}
        onChange={onChange}
      />
    </Styled>
  );
};

export default General;
