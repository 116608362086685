import React from 'react';
import { useParams } from 'react-router-dom';

export const TYPE = {
  ORDER_REQUESTS: 'order-requests',
  ORDERS: 'orders',
  TRACKING: 'tracking',
};

const ORDERS = '/connex/services/orders';

export const useApiPaths = type => {
  const { entityRef } = useParams();

  const getOrderPath = React.useCallback(
    ref => {
      return `${ORDERS}/${ref || entityRef}/${type}`;
    },
    [entityRef, type]
  );

  const getSupplierPath = React.useCallback(
    supplierRef => {
      return supplierRef
        ? `${ORDERS}/${entityRef}/suppliers/${supplierRef}/${type}`
        : `${ORDERS}/${entityRef}/suppliers`;
    },
    [entityRef, type]
  );

  const getBasePath = React.useCallback(() => {
    return `${ORDERS}/${entityRef}`;
  }, [entityRef]);

  return {
    getBasePath,
    getOrderPath,
    getSupplierPath,
  };
};
