import React from 'react';
import Select from '../../../../components/form/Select';
import styled from 'styled-components';
import ShowPricingOptions from './show-pricing-options';
import { Checkbox } from '../../../../components/checkbox';

const { Option } = Select;

const Title = styled.div`
  margin: 0;
  font-size: 12px;
  font-weight: bold;
`;

const Label = styled.div`
  margin-top: 12px;
  margin-bottom: 3px;
`;

const Styled = styled.div`
  margin-bottom: 15px;
`;

const ZoneCharges = styled.div`
  margin-top: 15px;
`;

const SelectPricingOptions = ({ view = '', item, onChange, hasLocationZonesLicense }) => {
  let showPricing = item?.pricingOptions?.showPricing || '';

  const showPricingOptions = ShowPricingOptions.filter(
    option => option.view === undefined || option.view === 'all' || option.view === view
  );

  const showPricingChanged = value => {
    showPricing = value;
    onChange && onChange('pricingOptions', getPricingOptions());
  };

  const getPricingOptions = () => {
    return { showPricing: showPricing };
  };

  const handleZoneChargesChange = e => {
    const { id, checked } = e.target;
    onChange(id, checked);
  };

  return (
    <Styled>
      <Title>Pricing Options</Title>
      <ZoneCharges>
        {hasLocationZonesLicense && (
          <Checkbox
            id="excludeZoneCharges"
            label="Exclude Zone Charges"
            checked={item?.excludeZoneCharges}
            onChange={handleZoneChargesChange}
          />
        )}
      </ZoneCharges>
      <Label>Print/Send Prices to Batch System</Label>
      <Select id="showPricing" value={showPricing} onChange={showPricingChanged}>
        <Option value="">Select...</Option>
        {showPricingOptions?.map((item, idx) => (
          <Option key={item.value + idx} value={item.value}>
            {item.label}
          </Option>
        ))}
      </Select>
    </Styled>
  );
};

export default SelectPricingOptions;
