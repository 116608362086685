import { css } from 'styled-components';

export default css`
  width: 100%;
  //margin-bottom: 20px;

  table,
  tbody,
  tr {
    width: 100%;
  }

  td {
    width: 20%;
    padding: 5px;
  }

  .ant-btn {
    width: 100%;
  }

  .selector i {
    margin-right: 10px;
  }

  &&& {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .range-picker {
    width: 100%;
    display: flex;
    align-items: center;
    .spinner {
      text-align: right;
      padding-left: 10px;
      .spinner-icon {
        opacity: 0;
      }
    }
    .ant-picker-range {
      width: 100%;
      border: 0;
    }
  }
  &.custom-selected {
    .range-picker {
      background-color: #177ddc;
      .ant-picker-disabled {
        background-color: #095db6;
      }
      &.loading {
        background-color: #095db6;
        .spinner-icon {
          opacity: 1;
        }
      }
    }
  }
`;
