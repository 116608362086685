import React from 'react';
import { ShortDate, useShortDate } from './ShortDate';
import { Time, useTime } from './Time';

export const ShortDateTime = ({ date, hideTimeZone = false, withDayOfWeek = false, timeZone, showYear }) => {
  if (!date) return '';
  return (
    <>
      <ShortDate date={date} withDayOfWeek={withDayOfWeek} timeZone={timeZone} showYear={showYear} />{' '}
      <Time date={date} hideTimeZone={hideTimeZone} timeZone={timeZone} />
    </>
  );
};

export const useShortDateTimeString = () => {
  const { getShortDateString } = useShortDate();
  const { getTimeString } = useTime();

  const getShortDateTimeString = React.useCallback(
    ({ date, hideTimeZone = false, withDayOfWeek = false, timeZone, showYear }) => {
      if (!date) return '';

      return `${getShortDateString({ date, withDayOfWeek, timeZone, showYear })} ${getTimeString({
        date,
        hideTimeZone,
        timeZone,
      })}`;
    },
    [getShortDateString, getTimeString]
  );
  return { getShortDateTimeString, ShortDateTime };
};
