import cn from 'classnames';
import { AnimatePresence, AnimateSharedLayout } from 'framer-motion';
import { find } from 'lodash';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useAppContext } from '../../../AppContext';
import Spinner from '../../../components/spinner/Spinner';
import CreateOrderButton from '../components/create-order-button/CreateOrderButton';
import FlexColumn from '../components/FlexColumn';
import Header from '../components/Header';
import IfSeller from '../components/IfSeller';
import { Translate } from '../components/localization/Translate';
import { useOrderListContext } from '../components/OrderListContext';
import columns from './columnDef';
import FilterStatus from './components/filter-status/FilterStatus';
import GroupBy from './components/group-by/GroupBy';
import NewVersionButton from './components/NewVersionButton';
import ExpandedOrderCard from './components/order-cards/expanded-order-card/ExpandedOrderCard';
import OrderCards from './components/order-cards/OrderCards';
import QuickDates from './components/date-filter-bar/QuickDates';
import RefreshButton from './components/refresh-button/RefreshButton';
import Search from './components/search/Search';
import style from './style';
import { useGroupBy } from './useGroupBy';
import AdvancedSearchBar from './components/advanced-search-bar';
import AdvancedSearchResults from './components/advanced-search-results';
import OrdersDatePicker from './components/date-picker/ordersDatePicker';

const OrderList = ({ className }) => {
  // TODO: get time zone from entity or order (??)
  const {
    RowRenderer,
    filteredOrders,
    ordersBusy,
    showAdvancedSearch,
    handleQuickDateChange,
    onOkay,
    filterStringRef,
    filterString,
    onFilterStringChange,
    onStatusChange,
    showStatuses,
    persona,
  } = useOrderListContext();

  const appContext = useAppContext();
  const { groupBy, handleGroupByChange } = useGroupBy();

  const [activeOrder, setActiveOrder] = React.useState(null);
  const [orderList, setOrderList] = React.useState(filteredOrders);

  const timeZone = React.useMemo(
    () => activeOrder?.supplierParty?.timeZone || appContext.timeZone,
    [activeOrder?.supplierParty?.timeZone, appContext.timeZone]
  );
  const navigate = useNavigate();

  const handleCopy = React.useCallback(
    orderCrn => {
      if (persona === 'seller') {
        const order = find(filteredOrders, { crn: orderCrn });
        const lineItem = find(order?.lineItems, { isPrimary: true });
        const orderType = (lineItem?.item?.type || 'CONCRETE').toLowerCase();

        navigate(`./copy/${orderType}/${orderCrn}/general`);
      }
    },
    [filteredOrders, navigate, persona]
  );

  const handleClick = React.useCallback(
    orderCrn => {
      if (persona === 'seller') {
        const order = find(filteredOrders, { crn: orderCrn });

        const orderRefToUse = order?.orderGroupRef ? order?.orderGroupRef : orderCrn;
        const lineItem = find(order?.lineItems, { isPrimary: true });
        const orderType = (lineItem?.item?.type || 'CONCRETE').toLowerCase();

        if (order?.typeId === 'order-request-meta-data') {
          navigate(`./order-request/${orderType}/${orderRefToUse}`);
          return;
        }

        navigate(`edit/${orderType}/${orderRefToUse}/general`);
      }
    },
    [filteredOrders, navigate, persona]
  );

  const onClose = React.useCallback(() => {
    setActiveOrder(null);
  }, []);

  const onOpen = React.useCallback(
    crn => {
      setActiveOrder(find(filteredOrders, { crn }));
    },
    [filteredOrders]
  );

  useEffect(() => {
    if (!showStatuses || showStatuses.length === 0) {
      setOrderList(filteredOrders);
    } else {
      let ordersBySelectedStatus = filteredOrders.filter(o =>
        showStatuses.includes(o?.supplierStatus?.name?.toUpperCase())
      );
      setOrderList(ordersBySelectedStatus);
    }
  }, [filteredOrders, showStatuses]);

  return (
    <div className={cn('order-list', className)}>
      <AnimateSharedLayout type="crossfade">
        <Header
          title={<Translate stringId="orders" defaultMessage="Orders" />}
          leftSide={
            <div className="horizontal">
              <RefreshButton />
              <NewVersionButton />
            </div>
          }
          rightSide={
            <div className="additional-actions">
              <IfSeller>
                <CreateOrderButton metricIdPrefix="core-ol" />
              </IfSeller>
            </div>
          }
        />

        <FlexColumn className="order-list-body">
          <div style={{ margin: '0 auto' }}>
            <OrdersDatePicker timeZone={timeZone} />
          </div>
          <QuickDates onChange={handleQuickDateChange} onOkay={onOkay} timeZone={timeZone} />
          {!showAdvancedSearch && (
            <div className="search-group-by">
              <Search
                dataTestId="input-order-search"
                onChange={onFilterStringChange}
                value={filterString}
                innerRef={filterStringRef}
              />
              <FilterStatus onChange={onStatusChange} value={showStatuses} />
              <GroupBy onChange={handleGroupByChange} value={groupBy} />
            </div>
          )}
          <AdvancedSearchBar />
          <Spinner spin={ordersBusy}>
            {showAdvancedSearch ? (
              <AdvancedSearchResults />
            ) : (
              <OrderCards
                columns={columns(handleCopy, timeZone)}
                orders={orderList}
                rowRenderer={RowRenderer}
                groupBy={groupBy}
                onOpen={onOpen}
              />
            )}
          </Spinner>
          <AnimatePresence>
            {activeOrder && (
              <ExpandedOrderCard
                onClose={onClose}
                onClick={handleClick}
                order={activeOrder}
                columns={columns}
                timeZone={timeZone}
              />
            )}
          </AnimatePresence>
        </FlexColumn>
      </AnimateSharedLayout>
    </div>
  );
};

export default styled(OrderList)`
  ${style}
`;
