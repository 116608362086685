import style from '../style';
import React from 'react';
import styled from 'styled-components';
import InputSelect from '../../components/input-select';
import { uomValues, uomTemp, uomConcreteVolume, uomWeight, uomWaterVolume, uomSlump } from '../../../../util/uom/uom-codes-assurance';

const View = styled.div`
  ${style}
`;

const TOP_NODE = 'uom_settings';

const CompanyUomSettings = ({ item, onChange }) => {
  const handleChange = (id, value) => {
    onChange(TOP_NODE, {
      ...item?.[TOP_NODE],
      [id]: value,
    });
  };

  return (
    <View>
      <InputSelect
        id="uom_temp"
        label="Temperature UOM"
        value={item?.[TOP_NODE]?.['uom_temp']}
        onChange={handleChange}
        options={uomValues(uomTemp)}
        required
      />
      <InputSelect
        id="uom_concrete_volume"
        label="Concrete Volume UOM"
        value={item?.[TOP_NODE]?.['uom_concrete_volume']}
        onChange={handleChange}
        options={uomValues(uomConcreteVolume)}
        required
      />
      <InputSelect
        id="uom_water_volume"
        label="Water Volume UOM"
        value={item?.[TOP_NODE]?.['uom_water_volume']}
        onChange={handleChange}
        options={uomValues(uomWaterVolume)}
        required
      />
      <InputSelect
        id="uom_weight"
        label="Weight UOM"
        value={item?.[TOP_NODE]?.['uom_weight']}
        onChange={handleChange}
        options={uomValues(uomWeight)}
        required
      />
      <InputSelect
        id="uom_slump"
        label="Slump UOM"
        value={item?.[TOP_NODE]?.['uom_slump']}
        onChange={handleChange}
        options={uomValues(uomSlump)}
        required
      />
    </View>
  );
};

export default CompanyUomSettings;
