import { useEffect, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GridRow } from '../../../components/data-grid/DataGrid';
import { QUICK_DATES } from '../../../components/quick-dates/QuickDates';
import { useOrders } from './useOrders';

export const useOrderListState = () => {
  const { getOrders, ordersBusy, orders, quickDate, handleQuickDateChange, datePickerProps, onOkay } = useOrders();
  const navigate = useNavigate();

  useEffect(() => {
    getOrders(quickDate);
    if (!quickDate.date) {
      delete QUICK_DATES.CUSTOM.date;
    }
  }, [quickDate, quickDate.date]);

  const onCreateClick = useCallback(() => {
    navigate('new');
  }, [navigate]);

  const handleRowClick = useCallback(
    orderCrn => {
      navigate(`${orderCrn}`);
    },
    [navigate]
  );

  const RowRenderer = useMemo(
    () => props => {
      const { row } = props;
      return (
        <div className="grid-row" onClick={() => handleRowClick(row.crn)}>
          <GridRow {...props} />
        </div>
      );
    },
    [handleRowClick]
  );

  return { RowRenderer, onCreateClick, orders, ordersBusy, handleQuickDateChange, quickDate, datePickerProps, onOkay };
};
