import { Checkbox } from 'antd';
import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { useForm } from '../../../../../../useForm';
import { useOrderContext } from '../../../../../useOrderContext';
import Currency from '../../../../../Currency';
import Has from '../../../../../Has';
import ProductInputNumber from '../../../../../multiple-products/ProductInputNumber';
import Products from '../../../../../multiple-products/Products';
import ProductQuantity from '../../../../../ProductQuantity';
import style from './style';

const EditableRow = ({
  className,
  lineItem,
  availableProducts,
  disabled,
  onSave,
  onFocus,
  onDelete,
  allowDelete,
  onAddRow,
  fieldName,
  readOnly,
  isTicketed,
  showFees = false,
    productTouched,
    loading,
}) => {
  const {
    order: { order },
  } = useOrderContext();
  const { form: productEntryForm, handleChange, omitPaths } = useForm({ ...lineItem }, fieldName);
  const [minimumConcreteQuantity, setMinimumConcreteQuantity] = useState(0);

  React.useEffect(() => {
    handleChange('priceSummary', lineItem?.priceSummary);
  }, [handleChange, lineItem?.priceSummary]);

  useEffect(() => {
    setMinimumConcreteQuantity(order?.ticketsMeta?.totalDeliveredQuantity || 0);
  }, [order]);

  useDeepCompareEffect(() => {
    onSave(productEntryForm);
  }, [productEntryForm, productEntryForm?.properties?.holdQuantity]);

  const handleProductChange = React.useCallback(
    (field, value) => {
      handleChange(field, value);
      handleChange('orderedQuantity.uomCode', value?.uomCode);
      handleChange('priceSummary', undefined);
      if (value?.item?.type !== 'CONCRETE') {
        omitPaths(['properties.slump', 'properties.cleanup']);
      }
    },
    [handleChange, omitPaths]
  );

  const handleFocus = React.useCallback(
    e => {
      onFocus?.(e);
    },
    [onFocus]
  );

  const handleKeyDown = React.useCallback(
    e => {
      if (e.key === '+') {
        onAddRow();
        e.stopPropagation();
        e.preventDefault();
      }
    },
    [onAddRow]
  );

  const handleDelete = React.useCallback(
    id => {
      if (disabled) return;
      onDelete(id);
    },
    [disabled, onDelete]
  );

  return (
    <tr className={cn('editable-row', className, { disabled })} onFocus={handleFocus}>
      <td>
        <Products
          required
          value={productEntryForm?.item}
          onChange={handleProductChange}
          products={availableProducts}
          productsBusy={false}
          disabled={disabled}
          className={cn({ 'validation-failed': false })}
          onKeyDown={handleKeyDown}
          readOnly={readOnly || isTicketed}
          onFocus={productTouched}
          loading={loading}
        />
      </td>
      <td>
        <ProductQuantity
          value={productEntryForm?.orderedQuantity}
          onChange={handleChange}
          uom={productEntryForm?.item?.uomCode}
          onKeyDown={handleKeyDown}
          disabled={disabled}
          readOnly={readOnly}
          minValue={productEntryForm?.item?.type === 'CONCRETE' ? minimumConcreteQuantity?.value : 0}
        />
      </td>
      <td>
        {productEntryForm?.item?.type === 'CONCRETE' && (
          <ProductInputNumber
            value={productEntryForm?.properties?.holdQuantity || ''}
            fieldName="properties.holdQuantity"
            onChange={handleChange}
            disabled={disabled}
            className={cn({ 'validation-failed': false })}
            onKeyDown={handleKeyDown}
            readOnly={readOnly}
          />
        )}
      </td>
      <Has permission="showPricing">
        <td className="right-align">
          <Currency value={productEntryForm?.priceSummary?.extendedPrice} />
        </td>
        <td className="right-align">
          <Currency value={productEntryForm?.priceSummary?.salesTax} />
        </td>
        <td className="right-align" title={`From ${productEntryForm?.priceSummary?.priceSource} Price Book`}>
          <Currency value={productEntryForm?.priceSummary?.totalPrice} />
        </td>
      </Has>
      <Has not permission="showPricing">
        <td />
        <td />
        <td />
      </Has>

      {showFees && <td></td>}

      <td>
        <div className="row-actions">
          {productEntryForm._id && allowDelete && !readOnly && !isTicketed && (
            <div className="delete" onClick={() => handleDelete(productEntryForm?._id)}>
              <i className="icon fa fa-trash" />
            </div>
          )}
        </div>
      </td>
    </tr>
  );
};

export default styled(EditableRow)`
  ${style}
`;
