import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { set } from 'lodash';
import Button from '../../../../../components/button/Button';
import TimeZone from "../../../../../components/time-zone";
import style from './style';

const Styled = styled.div`
  ${style}
`;
const HoursOfOperation = ({ hours, timeZone, onChange }) => {
  const handleChange = React.useCallback(
    e => {
      const [dow, period] = e.target.name.split(':');
      const newHours = { ...hours };
      set(newHours, [dow, period], e.target.value);
      onChange('hours', newHours);
    },
    [hours, onChange]
  );

  const handleCopy = React.useCallback(
    period => {
      const sundayPeriodTime = hours?.sunday?.[period];

      const newHours = {
        ...hours,
        monday: { ...hours.monday, [period]: sundayPeriodTime },
        tuesday: { ...hours.tuesday, [period]: sundayPeriodTime },
        wednesday: { ...hours.wednesday, [period]: sundayPeriodTime },
        thursday: { ...hours.thursday, [period]: sundayPeriodTime },
        friday: { ...hours.friday, [period]: sundayPeriodTime },
        saturday: { ...hours.saturday, [period]: sundayPeriodTime },
      };
      onChange('hours', newHours);
    },
    [hours, onChange]
  );

  const handleTimeZoneChange = React.useCallback(
    timeZone => {
      onChange('timeZone', timeZone);
    },
    [onChange]
  );


  return (
    <Styled className={cn('hours-of-operation')}>
      <table>
        <thead>
          <tr>
            <th>Day of Week</th>
            <th>Open</th>
            <th>Close</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Sunday</td>
            <td className="open">
              <div className="wrapper">
                <div className="time">
                  <input type="time" name="sunday:open" onChange={handleChange} value={hours?.sunday?.open} />
                </div>
                <i
                  class="fal fa-clone"
                  onClick={() => {
                    handleCopy('open');
                  }}
                />
              </div>
            </td>
            <td className="close">
              <div className="wrapper">
                <div className="time">
                  <input type="time" name="sunday:close" onChange={handleChange} value={hours?.sunday?.close} />
                </div>
                <i
                  className="fal fa-clone"
                  onClick={() => {
                    handleCopy('close');
                  }}
                />
              </div>
            </td>
          </tr>
          <tr>
            <td>Monday</td>
            <td className="open">
              <input type="time" name="monday:open" onChange={handleChange} value={hours?.monday?.open} />
            </td>
            <td className="close">
              <input type="time" name="monday:close" onChange={handleChange} value={hours?.monday?.close} />
            </td>
          </tr>
          <tr>
            <td>Tuesday</td>
            <td className="open">
              <input type="time" name="tuesday:open" onChange={handleChange} value={hours?.tuesday?.open} />
            </td>
            <td className="close">
              <input type="time" name="tuesday:close" onChange={handleChange} value={hours?.tuesday?.close} />
            </td>
          </tr>
          <tr>
            <td>Wednesday</td>
            <td className="open">
              <input type="time" name="wednesday:open" onChange={handleChange} value={hours?.wednesday?.open} />
            </td>
            <td className="close">
              <input type="time" name="wednesday:close" onChange={handleChange} value={hours?.wednesday?.close} />
            </td>
          </tr>
          <tr>
            <td>Thursday</td>
            <td className="open">
              <input type="time" name="thursday:open" onChange={handleChange} value={hours?.thursday?.open} />
            </td>
            <td className="close">
              <input type="time" name="thursday:close" onChange={handleChange} value={hours?.thursday?.close} />
            </td>
          </tr>
          <tr>
            <td>Friday</td>
            <td className="open">
              <input type="time" name="friday:open" onChange={handleChange} value={hours?.friday?.open} />
            </td>
            <td className="close">
              <input type="time" name="friday:close" onChange={handleChange} value={hours?.friday?.close} />
            </td>
          </tr>
          <tr>
            <td>Saturday</td>
            <td className="open">
              <input type="time" name="saturday:open" onChange={handleChange} value={hours?.saturday?.open} />
            </td>
            <td className="close">
              <input type="time" name="saturday:close" onChange={handleChange} value={hours?.saturday?.close} />
            </td>
          </tr>
        </tbody>
      </table>

      <div style={{ marginTop: '10px' }}>
        <label htmlFor="timeZone">Time Zone</label>
        <TimeZone id="timeZone" onChange={handleTimeZoneChange} value={timeZone} />
      </div>

    </Styled>
  );
};

export default HoursOfOperation;
