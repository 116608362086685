import React from 'react';
import {Utils as QbUtils} from 'react-awesome-query-builder';

const RenderResult = ({ tree, config }) => (
  <div className="query-builder-result">
    <div>JsonLogic</div>
    {tree && <div>
      <pre>{JSON.stringify(QbUtils.jsonLogicFormat(tree, config), null, 2)}</pre>
    </div>}
  </div>
)
export default RenderResult;
