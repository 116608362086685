import cn from 'classnames';
import { motion } from 'framer-motion';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Number } from '../../../components/localization/Number';
import { Time } from '../../../components/localization/Time';
import { Uom } from '../../../components/localization/Uom';
import StatusBadge from '../../../components/status-badge/StatusBadge';
import style from './style';
import OrderProgressIcon from './order-progress-icon';
import OrderDateTime from './DateTime';

const source = order => {
  if (order?.originatorSystemType === 'CONNEX' || parseInt(order?.id) >= 50000) {
    return 'CONNEX';
  }

  return 'OTHER';
};

const lineWidth = 50;

const OrderCard = ({ className, order, onClick, onOpen, timeZone = 'America/Chicago' }) => {
  const orderSource = source(order);

  const product = {
    ...order?.lineItems?.[0]?.item,
    ...order?.lineItems?.[0]?.orderedQuantity,
    deliveryProgress: order?.deliverySchedule?.deliveryProgress,
  };

  const a = order?.destination?.address;
  const destination = `${a?.name ? `${a.name} / ` : ''}${a?.address1 || `(none)`}${a?.city ? ' ' : ''}${a?.city || ``}${
    a?.countrySubDivision || a?.postalCode ? ', ' : ``
  }${a?.countrySubDivision || ``} ${a?.postalCode || ``}`;

  const toggle = React.useCallback(
    e => {
      if (e?.ctrlKey || e?.metaKey) {
        onClick(order);
      } else {
        onOpen();
      }
    },
    [onClick, onOpen, order]
  );

  const handleEsc = React.useCallback(
    e => {
      if (e.key === 'Escape') toggle();
    },
    [toggle]
  );

  const formattedCustomerParty = useMemo(() => {
    if (!order?.customerParty) return '(none)';
    if (order?.customerParty?.name) {
      return order?.customerParty?.name;
    }
    return order?.customerParty?.id;
  }, [order]);

  const formattedProject = useMemo(() => {
    if (order?.project?.name) {
      return order?.project?.name;
    }
    return order?.project?.id;
  }, [order]);

  const contactName = useMemo(() => {
    const orderContact = order?.contacts?.[1];

    if (!orderContact?.firstName && !orderContact?.lastName) return '';

    return `${orderContact?.firstName || ''}${orderContact?.firstName && orderContact?.lastName ? ' ' : ''}${
      orderContact?.lastName || ''
    } `;
  }, [order?.contacts]);

  return (
    <motion.div
      data-testid={`order-${order.id}`}
      id={`order-card-${order.crn}`}
      layoutId={`order-card-${order.crn}`}
      className={cn('order-card', className)}
      onKeyDownCapture={handleEsc}
      tabIndex={1}
      style={{ width: '100%' }}
    >
      <motion.div
        className={cn(`wrapper flex-column order-source-${orderSource}`)}
        layoutId={`card-wrapper-${order.crn}`}
      >
        <motion.div className={cn('layout')} layoutId={`card-layout-${order.crn}`}>
          <motion.div
            className="left"
            onClick={e => toggle(e, order)}
            style={{ cursor: 'pointer' }}
            layoutId={`card-layout-left-${order.crn}`}
          >
            <OrderProgressIcon order={order} />
          </motion.div>
          <motion.div className="right" layoutId={`card-layout-right-${order.crn}`}>
            <div data-testid={`order-${order.id}-summary`} className="summary-info" onClick={toggle}>
              <div>{`${formattedCustomerParty}`.substring(0, lineWidth)}</div>
              <div>{`${formattedProject}`.substring(0, lineWidth)}</div>
              <div>{`${destination}`.substring(0, lineWidth)}</div>
              <div>
                <Number value={product?.deliveryProgress?.deliveredQuantity} decimalPlaces={2} /> /{' '}
                <Uom uom={{ uomCode: product.uomCode, value: product.value }} short />{' '}
                {`${product.id} / ${product.name}`.substring(0, lineWidth)}
              </div>
              <div>{`${contactName}${order?.contacts?.[0]?.phoneNumber || ''}`.substring(0, 30)}</div>
            </div>

            <div>
              <StatusBadge dataTestId={`order-${order.id}-status`} status={order.supplierStatus?.name} />
              {order.id && (
                <div data-testid={`order-${order.id}-number`} className={`ticket-number`}>
                  #{order.id}
                </div>
              )}
              <div>
                <OrderDateTime
                  isoDateTime={order?.deliverySchedule?.startDateTime}
                  timeZone={order?.supplierParty?.timeZone ?? timeZone}
                  dataTestId={`order-${order.id}-start-date-time`}
                  hideTimeZone={true}
                />
              </div>
            </div>
          </motion.div>
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

export default styled(OrderCard)`
  ${style}
`;
