import React from 'react';
import styled from 'styled-components';
import style from '../style';
import ReturnedOnVehicle from './ReturnedOnVehicle';
import { uomConcreteVolume, uomWaterVolume } from '../../../../../util/uom/uom-codes-assurance';

const View = styled.div`
  ${style}
`;

const OnVehicle = ({ vehicle, onChange }) => {
  return (
    <View>
      <ReturnedOnVehicle
        label="Returned Product"
        field="remainingQuantity"
        item={vehicle?.remainingQuantity}
        onChange={onChange}
        uomCodes={uomConcreteVolume}
      />
      <ReturnedOnVehicle
        label="Returned Water"
        field="waterOnTruck"
        item={vehicle?.waterOnTruck}
        onChange={onChange}
        uomCodes={uomWaterVolume}
      />
    </View>
  );
};

export default OnVehicle;
