import API from "../../api";

export const getRoute = async ({ destinationCoordinates, waypoints, origin, departureTime, entityRef }) => {
  const body = {
    origin: { coordinates: { latitude: origin?.lat, longitude: origin?.lng } },
    destination: { coordinates: { latitude: destinationCoordinates?.lat, longitude: destinationCoordinates?.lng } },
    departureTime: departureTime,
    waypoints,
  };

  const getRoute = API.getRoute({ entityRef, body });
  return await getRoute;
};
