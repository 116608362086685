import http from '../../../http';

const orders = '/connex/services/orders';

export const API = {
  getOrderRequest: (ref, crn) => http.get(`${orders}/${ref}/dispatch-order-requests/${crn}`),
  getOrderRequests: (ref, startDate, endDate) => http.get(`${orders}/${ref}/dispatch-order-requests/startDate=${startDate}&endDate=${endDate}`),
  createOrderFromOrderRequest: (ref, crn, order) => http.post(`${orders}/${ref}/dispatch-order-requests/${crn}`, order), 
  updateOrderRequestStatus: (ref, crn, status) => http.patch(`${orders}/${ref}/dispatch-order-requests/${crn}`, status)
}

export default API;