import { get } from 'lodash';
import moment from 'moment';
import getUomAbbreviationOrDescription from '../../../util/uom';

export default [
  {
    key: 'id',
    name: 'Number',
    width: '10%',
    minWidth: 80,
    resizable: true,
    formatter: props => get(props, 'row.id'),
  },
  {
    key: 'startDateTime',
    name: 'Start Date/Time',
    width: '20%',
    minWidth: 200,
    resizable: true,
    formatter: props => {
      const isoString = get(props, 'row.deliverySchedule.startDateTime');
      const dateTime = moment(isoString);
      return isoString ? `${dateTime.local().format('L')} ${dateTime.local().format('LT')}` : '';
    },
  },
  {
    key: 'job.description',
    name: 'Job',
    width: '25%',
    minWidth: 250,
    resizable: true,
    formatter: props => get(props, 'row.job.description', ''),
  },
  {
    key: 'product',
    name: 'Product',
    width: '25%',
    minWidth: 250,
    resizable: true,
    formatter: props => {
      const product = get(props, 'row.lineItems[0].item', '');
      return `${product.id} / ${product.description}`;
    },
  },
  {
    key: 'quantity',
    name: 'Quantity',
    width: '20%',
    minWidth: 100,
    resizable: true,
    formatter: props => {
      const quantity = get(props, 'row.lineItems[0].orderedQuantity.value', '');
      const uom = get(props, 'row.lineItems[0].orderedQuantity.uomCode', '');

      return `${quantity} ${getUomAbbreviationOrDescription(uom)}`;
    },
  },
];
