import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
import InputField from '../../InputField';
import { useOrderContext } from '../../useOrderContext';
import { Translate } from '../../localization/Translate';
import { useTranslateMessage } from '../../localization/useTranslateMessage';

const Styled = styled.div`
  border: 1px solid #434343;
  padding: 15px;
  .instruction {
    font-size: 0.8rem;
    margin-bottom: 10px;
  }
  .overridden-fields {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 15px;
  }
`;

const OverriddenDefaultsExplanations = props => {
  const translateMessage = useTranslateMessage();
  const {
    form: { auditNotes, setAuditNotes },
    saveOrderBusy,
    strictDefaultsOverridden,
    masterData: { strictDefaults },
  } = useOrderContext();

  const handleFieldChange = React.useCallback(
    (fieldName, value) => {
      setAuditNotes(s => ({ ...s, [fieldName]: { ...s[fieldName], message: value } }));
    },
    [setAuditNotes]
  );

  return !strictDefaultsOverridden?.length ? null : (
    <Styled className={cn('overridden-defaults-explanations')}>
      <div className="instruction">
        <Translate defaultMessage="Please provide a reason for overriding the following default values:" stringId="defaultOverridePrompt" />
      </div>
      <div className="overridden-fields">
        {strictDefaultsOverridden.map(field => {
          return (
            <InputField
              key={field.id}
              onChange={handleFieldChange}
              value={auditNotes?.[field.id]?.message}
              fieldName={field.id}
              fieldLabel={translateMessage({stringId: field.stringId, defaultMessage: field.label || field.id})}
              disabled={saveOrderBusy}
              placeholder={translateMessage({stringId: 'enterReason', defaultMessage: 'Enter a reason...'})}
            />
          );
        })}
      </div>
    </Styled>
  );
};

export default OverriddenDefaultsExplanations;
