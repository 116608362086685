import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
import { DateTime } from 'luxon';
import Input from '../../../components/form/Input';
import Margin from '../components/Margin';
import { useScaleTicketingContext } from '../Context';

const Styled = styled.div``;

const ManualWeight = () => {
  const { form, handleChange } = useScaleTicketingContext();

  const onChange = React.useCallback(
    e => {
      const data = {
        type: 'MANUAL',
        weight: { uom: 'LB', value: e?.target?.value || '' },
        acquiredDateTime: DateTime.now().toUTC().toISO(),
      };
      handleChange('capturedWeight', data);
    },
    [handleChange]
  );

  const inputRef = React.useRef();

  React.useEffect(() => {
    inputRef?.current?.focus?.();
  }, []);

  return (
    <Styled className={cn('manual-weight')}>
      <Margin top={20}>
        <Input
          value={form?.capturedWeight?.weight?.value || ''}
          onChange={onChange}
          placeholder="Enter weight..."
          innerRef={inputRef}
        />
      </Margin>
    </Styled>
  );
};

export default ManualWeight;
