import cn from 'classnames';
import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import FieldContainer from '../../../components/field-container/FieldContainer';
import getUomAbbreviationOrDescription from '../../../util/uom';
import ProductInputNumber from '../../../views/order/components/multiple-products/ProductInputNumber';
import style from './style';
import { isNil } from 'lodash';

const ProductQuantity = ({
  className,
  value: orderedQuantity,
  onChange,
  uom = '',
  required,
  disabled,
  hideLabel = false,
  showUnits = true,
  onKeyDown,
  readOnly,
  minValue = 0,
  autoRefreshValue = false,
}) => {
  const handleChange = useCallback(
    (field, val) => {
      if (orderedQuantity?.value === val) return;
      const value = parseFloat(val);
      onChange('orderedQuantity', { value, uomCode: uom });
    },
    [onChange, uom, orderedQuantity]
  );

  const uomAbbreviation = useMemo(() => {
    return getUomAbbreviationOrDescription(uom).toUpperCase();
  }, [uom]);

  return (
    <FieldContainer className={cn('quantity', className, { disabled })}>
      <ProductInputNumber
        minValue={minValue}
        fieldName="value"
        value={isNil(orderedQuantity?.value) ? '' : parseFloat(orderedQuantity?.value)}
        onChange={handleChange}
        data-testid="quantity_input"
        addonAfter={showUnits ? uomAbbreviation : undefined}
        disabled={disabled}
        onKeyDown={onKeyDown}
        readOnly={readOnly}
        autoRefreshValue={autoRefreshValue}
        trim={true}
      />
    </FieldContainer>
  );
};

export default styled(ProductQuantity)`
  &&& {
    .addonAfter {
      border-width: 0;
    }
  }
  ${style}
`;
