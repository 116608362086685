import api from '../../../http';
import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import summarizeBillables from './summarizeBillables';
import queryParams from '../api/query-params';

const basePath = entityRef => {
  return `/connex/services/orders/${entityRef}`;
};

const useBillables = () => {
  const [billables, setBillables] = useState(null);
  const [billable, setBillable] = useState(null);
  const [summary, setSummary] = useState(null);
  const [preview, setPreview] = useState(null);
  const [projects, setProjects] = useState(null);
  const [customers, setCustomers] = useState(null);
  const [costBooks, setCostBooks] = useState(null);
  const [products, setProducts] = useState(null);
  const [invoices, setInvoices] = useState(null);
  const [loading, setLoading] = useState(false);
  const [creatingInvoices, setCreatingInvoices] = useState(false);
  const [links, setLinks] = useState({});
  const [exported, setExported] = useState(null);

  const { entityRef } = useParams();

  const searchBillables = useCallback(async params => {
    setLoading(true);
    let result = null;

    do {
      const apiResult = await api
        .post(`${basePath(entityRef)}/billables/search`, { ...params, pageToken: result?.pageToken || null })
        .catch(() => {});
      if (!apiResult) {
        setLoading(false);
        return;
      }
      if (!result) {
        result = apiResult;
      } else {
        result.pageToken = apiResult.pageToken;
        result.billables.push(...(apiResult?.billables || []));
        for (const [key, value] of Object.entries(apiResult.summary)) {
          result.summary[key] += value;
        }
      }
    } while (result?.pageToken);

    setBillables(result?.billables ?? []);
    setSummary(result?.summary ?? {});

    setLoading(false);
  }, []);

  const getBillable = useCallback(billableRef => {
    setLoading(true);

    return api
      .get(`${basePath(entityRef)}/billables/${billableRef}`)
      .then(({ billable, summary, customers, projects, products, costBooks, orderBillingNote }) => {
        setBillable(billable);
        setSummary(summary);
        setCustomers(customers);
        setProjects(projects);
        setProducts(products);
        setCostBooks(costBooks);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const changeBillable = useCallback((billableRef, changes) => {
    setLoading(true);

    return api
      .patch(`${basePath(entityRef)}/billables/${billableRef}`, changes)
      .then(({ billable, summary, projects, products }) => {
        setBillable(billable);
        setSummary(summary);
        setProjects(projects);
        setProducts(products);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const createInvoices = useCallback(params => {
    setLoading(true);

    return api
      .post(`${basePath(entityRef)}/invoices`, params)
      .then(setInvoices)
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const exportInvoiceData = useCallback(
    async params => {
      setLoading(true);

      const { customers } = params;

      params.items = [];

      if (customers) {
        params.items = invoices;

        return api
          .post(`${basePath(entityRef)}/billables/export`, params)
          .then(setExported)
          .finally(() => {
            setLoading(false);
          });
      }
    },
    [invoices]
  );

  const [progress, setProgress] = useState({});

  const createInvoicesAsync = useCallback(
    async params => {
      const { customers } = params;

      setInvoices([]);
      setExported(null);

      if (customers) {
        setCreatingInvoices(true);
        const refs = {};
        setLinks(refs);

        const groups = customers.map(c => {
          return {
            itemRef: c.customerRef,
            billables: c.billables.map(b => ({ billableRef: b.crn })),
            customerName: c.name,
            customerRef: c.customerRef,
          };
        });

        setProgress(s =>
          customers.reduce((acc, value) => {
            acc[value.customerRef] = null;
            return acc;
          }, {})
        );

        for (const group of groups) {
          await api
            .post(`${basePath(entityRef)}/invoices`, {
              ...params,
              items: group.billables,
              invoiceNumber: group?.invoiceNumber,
            })
            .then(rs => {
              const { invoiceRef } = rs[0] ?? {};

              // Always keep at top prior to calling setProgress
              if (invoiceRef) {
                setInvoices(s => {
                  return [
                    ...(s ?? []),
                    {
                      invoiceRef,
                    },
                  ];
                });
              }

              return rs;
            })
            .then(rs => {
              const { documentRef, invoiceRef } = rs[0] ?? {};

              refs[group.itemRef] = documentRef;

              if (group.customerRef && documentRef) {
                setProgress(s => ({ ...s, [group.customerRef]: { success: documentRef } }));
                setLinks({
                  ...refs,
                });
              } else if (group.customerRef && invoiceRef) {
                setProgress(s => ({ ...s, [group.customerRef]: { success: invoiceRef } }));
              } else if (group.customerRef && !documentRef) {
                setProgress(s => ({ ...s, [group.customerRef]: { error: group.customerName } }));
              }
            })
            .catch(() => {
              setProgress(s => ({ ...s, [group.customerRef]: { error: group.customerName } }));
            });
        }

        setCreatingInvoices(false);
      }
    },
    [entityRef]
  );

  const createPreview = useCallback((billables, grouping) => {
    setPreview(summarizeBillables(billables, grouping));
  }, []);

  const loadCostBooks = useCallback(
    orderRef => {
      setLoading(true);

      return api
        .get(`${basePath(entityRef)}/orders/${orderRef}/cost-books`)
        .then(costbooks => {
          return costbooks.map(cb => {
            return { value: cb.crn, label: `${cb.id} / ${cb.name}` };
          });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [entityRef]
  );

  const loadMoreCustomers = useCallback(() => {
    setLoading(true);

    return api
      .get(`${basePath(entityRef)}/master-data/customers?activeOnly=true`)
      .then(customers => {
        return customers.map(c => {
          return { value: c.crn, label: `${c.id} / ${c.name}` };
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [entityRef]);

  const loadCustomerProjects = useCallback(
    customerRef => {
      setLoading(true);

      return api
        .get(`${basePath(entityRef)}/master-data/customers/${customerRef}/projects?activeOnly=true`)
        .then(projects => {
          return projects.map(p => {
            return { value: p.crn, label: `${p.id} / ${p.name}` };
          });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [entityRef]
  );

  return {
    searchBillables,
    billables,
    projects,
    customers,
    costBooks,
    loadCostBooks,
    loadMoreCustomers,
    loadCustomerProjects,
    products,
    getBillable,
    changeBillable,
    billable,
    summary,
    createInvoices,
    invoices,
    createPreview,
    preview,
    loading,
    createInvoicesAsync,
    creatingInvoices,
    links,
    progress,
    exported,
    exportInvoiceData,
  };
};

export default useBillables;
