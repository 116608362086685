import React, { useEffect } from 'react';
import useSetup from '../../useSetup';
import InputSelect from '../input-select';

const SelectSalesPerson = ({ id, label = 'Assigned Sales Person', item, onChange, salesPersonRef }) => {
  const { getSalesPersons, salesPersons } = useSetup();
  const value = item?.salesPersonRef || salesPersonRef || '';

  useEffect(() => {
    getSalesPersons();
  }, [getSalesPersons]);

  const salesPersonChanged = (id, value) => {
    onChange && item && onChange(id, value);
  };

  const options = React.useMemo(() => {
    return salesPersons?.map?.(salesPerson => ({
      value: salesPerson.crn,
      label: salesPerson.name,
    }));
  }, [salesPersons]);

  return <InputSelect id={id} value={value} label={label} onChange={salesPersonChanged} options={options} />;
};

export default SelectSalesPerson;
