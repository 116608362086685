import React, { useCallback } from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { useNotes } from '../../../../views/order-request/view-or-edit-order/edit-order/useNotes';
import Button from '../../../button/Button';
import Input from '../../../form/Input';

const { TextArea } = Input;

const CreateNote = ({ className, onChange, value, onSaveNote, busy }) => {
  const handleChange = useCallback(
    e => {
      onChange(e.target.value);
    },
    [onChange]
  );

  const handleCancelClick = useCallback(() => {
    onChange('');
  }, [onChange]);

  return (
    <div className={cn('create-note', className)}>
      <div className="label">Add a Note</div>
      <TextArea className="note-text" value={value} onChange={handleChange} disabled={busy} data-testid="create_note" />
      <div className="buttons">
        <Button disabled={!value?.length || busy} onClick={handleCancelClick}>
          Cancel
        </Button>
        <Button
          className={cn('btn', { 'btn-success': value?.length })}
          onClick={onSaveNote}
          disabled={!value || busy}
          loading={busy}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default styled(CreateNote)``;
