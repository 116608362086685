import React from 'react';
import styled from 'styled-components';
import FlexColumn from '../../order/components/FlexColumn';
import style from './style';
import SetupItemList from '../setup-item-list';
import { columns, prototype } from './setup';
import PourDescriptionGeneral from './pour-description-general';
import ImportExport from '../components/import-export/ImportExport';
import PourDescriptionSetupContextProvider, { usePourDescriptionSetupContext } from './pourDescriptionSetupContext';

const tabs = [
  {
    label: 'General',
    component: PourDescriptionGeneral,
  },
];

const Styled = styled(FlexColumn)`
  ${style}
`;

const importExport = props => {
  const { downloadFile, uploadFile } = usePourDescriptionSetupContext();

  return (
    <ImportExport
      {...props}
      downloadFile={downloadFile}
      uploadFile={uploadFile}
      item={{ typeId: 'pour-description', crn: 'all', name: 'pour-description-list' }}
    />
  );
};

const SetupPourDescriptions = () => {
  return (
    <Styled>
      <PourDescriptionSetupContextProvider>
        <SetupItemList
          typeId="pour-description"
          type="Pour Description"
          editorType="tabs"
          tabs={tabs}
          columns={columns}
          prototype={prototype}
          actions={importExport}
        />
      </PourDescriptionSetupContextProvider>
    </Styled>
  );
};

export default SetupPourDescriptions;
