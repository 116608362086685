import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
import FieldContainer from '../../field-container/FieldContainer';
import Input from '../../form/Input';
import style from './style';

const Product = ({ className, value, disabled }) => {
  return (
    <FieldContainer className={cn('product', className)}>
      <div className="label-spinner">
        <div className="label">Product</div>
        <div className={cn('spinner', { spinning: false })}>
          <span className="fas fa-sync fa-spin" />
        </div>
      </div>

      <Input type="text" value={`${value?.id} / ${value?.description || ''}`} disabled />
    </FieldContainer>
  );
};

export default styled(Product)`
  ${style}
`;
