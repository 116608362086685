import moment from 'moment';
import { isString } from 'lodash';

import { SEVERITY } from '../../../components/pie-chart/PieChart';

export const TICKET_STATUS = {
  CREATED: { sequence: 0 },
  PRINTED: { sequence: 10 },
  LOADING_STARTED: { sequence: 20 },
  END_LOADING: { sequence: 30 },
  TO_JOB: { sequence: 40 },
  ARRIVE_JOB: { sequence: 50 },
  UNLOADING: { sequence: 60 },
  END_UNLOADING: { sequence: 70 },
  LEAVE_JOB: { sequence: 80 },
  IN_YARD: { sequence: 90 },
};

export const isDelivered = ticketEvents => {
  const deliveredStatuses = ['UNLOADING', 'END_UNLOADING', 'LEAVE_JOB', 'IN_YARD', 'ARRIVE_PLANT'];
  return deliveredStatuses.some(status => ticketEvents?.[status]?.eventDateTime);
};

export const loadStatus = (load, supplierStatus) => {
  supplierStatus = supplierStatus || load.supplierStatus;
  const now = moment();
  const nextScheduledLoad = moment(isString(load?.arrive_job) ? load?.arrive_job : load?.arrive_job?.eventDateTime);
  const minutesToArrive = nextScheduledLoad?.diff?.(now, 'm');

  if(supplierStatus === 'CANCELLED') {
    return SEVERITY.CANCELLED;
  }

  if (load.isDelivered) {
    return SEVERITY.DELIVERED;
  }

  if (minutesToArrive > 0) {
    return SEVERITY.SUCCESS;
  }

  return Math.abs(minutesToArrive) > 60 ? SEVERITY.DANGER : SEVERITY.WARNING;
};
