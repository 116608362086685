import React from 'react';
import cn from 'classnames';
import style from './style';
import styled from 'styled-components';
import Input from '../../../../components/form/InputNumber';
import FieldContainer from '../../../../components/field-container/FieldContainer';
import { kebabCase } from 'lodash';

const View = styled(FieldContainer)`
  ${style}
`;

const InputPercentage = ({ className, id, label, value, disabled = false, onChange }) => {
  const handleChange = e => {
    let { value, min, max } = e.target;
    if (!onChange) {
      console.log(id, value);
    }
    if (value === '') {
      onChange(id, null);
      return;
    }
    value = Math.max(Number(min), Math.min(Number(max), Number(value)));
    onChange(id, value);
  };

  return (
    <View className={cn(id, className)}>
      <div className="label-spinner">
        <div className="label">{`${label} (%)`}</div>
      </div>
      <Input
        type="number"
        min="0"
        max="100"
        value={value ?? ''}
        disabled={disabled}
        onChange={handleChange}
        data-testid={`input-${kebabCase(label?.toLowerCase())}`}
      />
    </View>
  );
};

export default InputPercentage;
