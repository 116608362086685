import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import MoneyRenderer from '../../../column-definitions/MoneyRenderer';
import useBillables from '../../../../use-billables';

const Customer = styled.tr`
  width: 100%;
  cursor: pointer;

  &:hover {
    //color: #0e4980;
    //background-color: #444;
  }

  &.selected {
    background-color: #555;
    &:hover {
      //background-color: #666;
    }
    .fa-file-check {
      color: #00ff00;
    }
  }

  td {
    padding: 5px 5px 5px 0;
    text-align: right;
    .fa-file-check {
      cursor: pointer;
    }
  }

  td:last-child {
    text-align: left;
    width: 80px;

    .fa-file-import {
      margin-left: 10px;
    }

    &:hover {
      color: green;
    }
  }

  td:first-child {
    text-align: left;
  }

  .fal,
  .fa,
  .fad {
    margin-right: 7px;
  }

  .fa-times {
    color: red;
  }

  .fa-check {
    color: green;
  }

  .fa {
    font-size: 1.1em;
  }
`;

const Project = styled.tr`
  width: 100%;

  td {
    padding: 5px 5px 5px 0;
    text-align: right;
  }

  td:first-child {
    text-align: left;
    padding-left: 25px;
    border-left: solid 1px #3a3a3a;
  }

  //.fal {
  //  margin-right: 7px;
  //}

  .fa-times {
    color: red;
  }

  .fa-check {
    color: green;
  }
`;

const CustomerRow = ({ creatingInvoices, invoices, customer, toggleCustomerSelection, progress }) => {
  const [open, setOpen] = useState(false);
  const [documentRef, setDocumentRef] = useState(null);

  const { links } = useBillables();

  const { isBillable, customerRef, summary, totalItems = 0, projects = [] } = customer;
  const rows = [];

  const creatingInvoice = React.useMemo(() => progress[customerRef] === null, [customerRef, progress]);

  useEffect(() => {
    invoices && setDocumentRef(invoices[customerRef]);
    links && links[customerRef] && setDocumentRef(links[customerRef]);
  }, [links, invoices, customerRef]);

  const handleRowSelection = React.useCallback(() => {
    toggleCustomerSelection(customer);
  }, [customer, toggleCustomerSelection]);

  rows.push(
    <Customer key={`${customerRef}-row-1`} className={cn({ selected: customer._isSelected })}>
      <td onClick={() => setOpen(!open)}>
        <i className={`fal fa-${isBillable ? 'check' : 'times'}`} />
        {customer.id ?? 'NONE'} / {customer.name}
      </td>
      <td onClick={() => setOpen(!open)}>{projects?.length ?? 0}</td>
      <td onClick={() => setOpen(!open)}>{totalItems}</td>
      <td onClick={() => setOpen(!open)}>
        <MoneyRenderer value={summary?.extendedPrice} />
      </td>
      <td onClick={() => setOpen(!open)}>
        <MoneyRenderer value={summary?.salesTax} />
      </td>
      <td onClick={() => setOpen(!open)}>
        <MoneyRenderer value={summary?.totalPrice} />
      </td>
      <td>
        <span onClick={handleRowSelection}>
          {customer._isSelected ? <i class="fad fa-file-check" /> : <i className="fal fa-file-check" />}
        </span>
        {documentRef && (
          <a target="_blank" href={documentRef}>
            <i className="fa fa-file-pdf" />
          </a>
        )}
        {customer._isSelected && creatingInvoice && <i className="fal fa-sync fa-spin" style={{ fontSize: '.8em' }} />}
      </td>
    </Customer>
  );

  open &&
    projects?.forEach((project, row) => {
      const { isBillable, projectRef, totalItems, summary } = project;

      rows.push(
        <Project key={`${projectRef}-row-${row}`}>
          <td colSpan={2}>
            <i className={`fal fa-${isBillable ? 'check' : 'times'}`} />
            {`${project.id ?? 'NONE'} / ${project.name}`}
          </td>
          <td>{totalItems}</td>
          <td>
            <MoneyRenderer value={summary?.extendedPrice} />
          </td>
          <td>
            <MoneyRenderer value={summary?.salesTax} />
          </td>
          <td>
            <MoneyRenderer value={summary?.totalPrice} />
          </td>
          <td>&nbsp;</td>
        </Project>
      );
    });

  return rows;
};

export default CustomerRow;
