import { cloneDeep, isString } from 'lodash';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import useSetup from '../../../../setup/useSetup';
import { useSchedule } from '../../../view-or-edit-order/useSchedule';
import { calcTicketQuantities } from '../../tickets/calcTicketQuantities';
import { useDrawer } from '../general/useDrawer';

const ScheduleContext = React.createContext();

export const useScheduleContext = () => {
  const context = useContext(ScheduleContext);
  if (!context) {
    throw new Error(`useScheduleContext cannot be rendered outside of the ScheduleContext context provider`);
  }
  return context;
};

const ScheduleContextProvider = ({ children, linkedOrder }) => {
  const { createOrderSchedule, scheduleBusy, getOrder } = useSchedule();

  const { getSetupItems: getStatusCodes, setupItems: statusCodes, busy: statusCodeBusy } = useSetup('status-code');

  const scheduleTimesRef = useRef(null);
  const [scheduleTimes, setScheduleTimes] = useState(linkedOrder?.deliverySchedule?.scheduleTimes);
  const [schedule, setSchedule] = useState(null);
  const [dirty, setDirty] = useState(false);
  const [overrides, setOverrides] = useState(null);
  const [strategy, setStrategy] = useState(null);
  const [availableTrucks, setAvailableTrucks] = useState(null);
  const { drawerOpen, openDrawer, closeDrawer, drawerWidth } = useDrawer();

  const reset = useCallback(
    orderRef => {
      if (orderRef) {
        setOverrides(null);
        setScheduleTimes(null);
        setSchedule(null);
        setDirty(false);

        getOrder(orderRef, { expand: 'tickets' }).catch(alert);
      }
    },
    [getOrder]
  );

  const handleGetSetupItems = useCallback(async () => {
    try {
      await getStatusCodes();
    } catch (error) {
      console.log(error);
    }
  }, [getStatusCodes]);

  useEffect(() => {
    handleGetSetupItems();
  }, [handleGetSetupItems]);

  useEffect(() => {
    if (linkedOrder) {
      setOverrides(linkedOrder?.deliverySchedule?.overrides);
      setScheduleTimes(linkedOrder?.deliverySchedule?.scheduleTimes);
      scheduleTimesRef.current = linkedOrder?.deliverySchedule?.scheduleTimes;
      setSchedule(linkedOrder?.deliverySchedule?.schedule);
    }
  }, [linkedOrder]);

  useEffect(() => {
    if (strategy && strategy !== linkedOrder?.deliverySchedule?.deliverySpacing?.uomCode) {
      setSchedule(null);
      setOverrides(null);
      setDirty(true);
    }
  }, [linkedOrder?.deliverySchedule?.deliverySpacing?.uomCode, strategy]);

  const changeTimes = useCallback((id, value) => {
    const times = {
      ...(scheduleTimesRef.current || {}),
    };

    if (id === 'loadSize') {
      // Load Size supports up to 2 decimal places
      times[id] = !isNaN(+value) ? Math.round(+value * 100) / 100 : 10;
    } else {
      // Time (minutes) use integer value
      times[id] = parseInt(`${value}`);
    }
    scheduleTimesRef.current[id] = times[id];
    setScheduleTimes(times);

    setSchedule(null);
    setOverrides(null);
    setDirty(true);
  }, []);

  const changeStrategy = useCallback((id, value) => {
    setStrategy(value);
  }, []);

  const changeAvailableTrucks = useCallback((id, value) => {
    setAvailableTrucks(value);
  }, []);

  const changeScheduleLine = useCallback(
    (loadNumber, id, value) => {
      setOverrides(overridesValue => {
        const overridesCopy = cloneDeep(overridesValue || {});
        const overrideLoad = overridesCopy[loadNumber] || {};
        overrideLoad[id] = value;
        overridesCopy[loadNumber] = overrideLoad;
        return overridesCopy;
      });
      setDirty(true);
    },
    [setOverrides, setDirty]
  );

  const createSchedule = useCallback(
    arg => {
      const deliverySchedule = {
        ...linkedOrder?.deliverySchedule,
        schedule,
        scheduleTimes,
        overrides,
        strategy,
        availableTrucks,
      };

      const associatedProductQuantities = {};

      // deliverySchedule?.schedule?.forEach(load => {
      //   associatedProductQuantities[load.loadNumber] = calcTicketQuantities(
      //     linkedOrder,
      //     load.loadNumber
      //   )?.associatedProductQuantities;
      // });

      const allLoadQuantities = {};

      if (deliverySchedule?.schedule?.length) {
        const smallestLoad = Math.min(...deliverySchedule.schedule.map(load => load.loadNumber));

        for (let i = smallestLoad; i < smallestLoad + deliverySchedule.schedule.length; i++) {
          const load = deliverySchedule.schedule.find(l => l.loadNumber === i);
          const loadQuantities = calcTicketQuantities(linkedOrder, load.loadNumber, true, allLoadQuantities);
          allLoadQuantities[i] = loadQuantities;
        }

        deliverySchedule.schedule.forEach(load => {
          associatedProductQuantities[load.loadNumber] =
            allLoadQuantities[load.loadNumber]?.associatedProductQuantities;
        });
      }

      // arg will be either a click event (which we'll ignore) or an iso date string which we'll use as the new startDateTime
      if (isString(arg)) {
        deliverySchedule.startDateTime = arg;
      }

      createOrderSchedule(linkedOrder.crn || linkedOrder.orderRef, {
        deliverySchedule,
        associatedProductQuantities,
      }).catch(alert);
    },
    [linkedOrder, schedule, scheduleTimes, overrides, strategy, availableTrucks, createOrderSchedule]
  );

  const onTicketed = React.useCallback(() => {
    if (linkedOrder) reset(linkedOrder?.orderGroupRef || linkedOrder?.orderRef);
  }, [linkedOrder, reset]);

  // Old orders were created without the quantityRate value
  // So this useEffect is to add the missing quantityRate for those orders
  useEffect(() => {
    if (scheduleTimes && scheduleTimes.quantityRate === undefined) {
      const newquantityRate = Math.round((60 / scheduleTimes?.spacing) * scheduleTimes?.loadSize);
      changeTimes('quantityRate', newquantityRate);
    }
  }, [scheduleTimes, changeTimes]);

  const updateLoadSize = useCallback(
    value => {
      changeTimes('loadSize', value);
      if (strategy === 'YDQ') {
        const newSpacing = Math.round(60 / (scheduleTimes?.quantityRate / value));
        changeTimes('spacing', newSpacing);
      } else {
        const newquantityRate = Math.round((60 / scheduleTimes?.spacing) * value);
        changeTimes('quantityRate', newquantityRate);
      }
    },
    [changeTimes, scheduleTimes, strategy]
  );

  const updateSpacing = useCallback(
    value => {
      const newquantityRate = Math.round((60 / value) * scheduleTimes?.loadSize);
      changeTimes('spacing', value);
      changeTimes('quantityRate', newquantityRate);
    },
    [changeTimes, scheduleTimes?.loadSize]
  );

  const updateQuantityRate = useCallback(
    value => {
      const newSpacing = Math.round(60 / (value / scheduleTimes?.loadSize));
      changeTimes('quantityRate', value);
      changeTimes('spacing', newSpacing);
    },
    [changeTimes, scheduleTimes?.loadSize]
  );

  return (
    <ScheduleContext.Provider
      value={{
        createOrderSchedule,
        scheduleBusy,
        schedule,
        scheduleTimes,
        getOrder,
        order: linkedOrder,
        linkedOrder,
        reset,
        overrides,
        dirty,
        setDirty,
        changeTimes,
        changeStrategy,
        changeAvailableTrucks,
        changeScheduleLine,
        createSchedule,
        onTicketed,
        updateLoadSize,
        updateSpacing,
        updateQuantityRate,
        drawerOpen,
        openDrawer,
        closeDrawer,
        drawerWidth,
        statusCodes,
        statusCodeBusy,
      }}
    >
      {children}
    </ScheduleContext.Provider>
  );
};

export default ScheduleContextProvider;
