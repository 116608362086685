import { Modal, Tooltip } from 'antd';
import React, { useMemo, useCallback, useState } from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import InputText from '../../../../setup/components/input-text';
import Button from '../../../../../components/button/Button';

const Styled = styled.div`
  display: flex;
  flex: 1;
  .edit {
    background-color: transparent;
    display: flex;
    align-items: center;
    border: 1px solid #444;
    border-left-width: 0;
    padding: 5px;
    flex: 1;
    &:focus-visible {
      border-left-width: 1px;
      outline: 1px solid #177ddc;
    }

    .icon {
      margin: 0 5px;
      font-size: 0.7rem;
      color: rgba(255, 255, 255, 0.85);
    }
    &:hover {
      background-color: #313131;
      cursor: pointer;
    }
  }
  .container {
    display: grid;
    grid-template-columns: 4fr 1fr 1fr;
  }
  .button {
    margin-top: 25px;
    margin-left: 10px;
  }
`;

const EditItem = ({ onSave, list, selfRef, editorIsOpen, toggleEditor, disabled, itemCrn }) => {
  const [updatedCustomer, setUpdatedCustomer] = useState(null);

  const handleCancel = useCallback(() => {
    toggleEditor();
    setUpdatedCustomer(null);
  }, [selfRef, toggleEditor]);

  const handleEditClick = useCallback(() => {
    if (!disabled) toggleEditor();
  }, [disabled, toggleEditor]);

  const handleChange = (id, value) => {
    setUpdatedCustomer({
      ...getCustomer,
      [id]: value,
    });
  };

  const handleSave = useCallback(() => {
    onSave(updatedCustomer);
    setUpdatedCustomer(null);
    toggleEditor();
  }, [onSave, updatedCustomer, toggleEditor]);

  const getCustomer = useMemo(() => {
    return list?.find(c => c.crn === itemCrn);
  }, [list, itemCrn]);

  return (
    <Styled className="edit">
      <Tooltip title="Override Customer Name">
        <button className="edit" onClick={handleEditClick}>
          <i className="icon fas fa-pencil-alt" />
        </button>
      </Tooltip>
      <Modal visible={editorIsOpen} closable={false} destroyOnClose={true} footer={null}>
        <Styled className="container" style={{ marginTop: '10px' }}>
          <InputText
            id="name"
            label="Enter override Customer Name"
            value={updatedCustomer?.name ?? ''}
            onChange={handleChange}
          />
          <Styled className="button">
            <Button type="primary" onClick={handleSave}>
              Save
            </Button>
          </Styled>
          <Styled className="button">
            <Button onClick={handleCancel}>Cancel</Button>
          </Styled>
        </Styled>
      </Modal>
    </Styled>
  );
};

export default EditItem;
