import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
import Button from '../../../components/button/Button';
import Margin from '../components/Margin';
import { useScaleTicketingContext } from '../Context';

const Styled = styled.div`
  width: 100%;
  button {
    width: 100%;
  }
`;

const Ticket = () => {
  const { weightStatus } = useScaleTicketingContext();

  return (
    <Styled className={cn('ticket')}>
      <Margin bottom={20}>
        <Button type="primary" size="large" disabled={!(weightStatus === 'OK')}>
          Ticket
        </Button>
      </Margin>
    </Styled>
  );
};

export default Ticket;
