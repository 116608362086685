import styled from 'styled-components';

import style from './style';

const Styled = styled.div`
  ${style}
`;

const TicketWarning = ({ message, danger = false }) => {
  return (
    <Styled>
      <div className={'warning-icon'}>
        <i
          className={`fas ${danger ? 'fa-exclamation' : 'fa-exclamation-triangle'}`}
          style={{ color: danger ? 'red' : '#eed202' }}
        ></i>
      </div>
      <div className={'warning-text'}>{message}</div>
    </Styled>
  );
};

export default TicketWarning;
