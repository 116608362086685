import { find } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { GridRow } from '../../../components/data-grid/DataGrid';
import useSetup from '../../setup/useSetup';
import orderFilter from './orderFilter';
import { useOrders } from './useOrders';
import { useUserContext } from '../components/user-context/UserContextProvider';

export const useOrderListState = () => {
  const {
    getOrders,
    getStatuses,
    ordersBusy,
    ordersBackgroundBusy,
    orders,
    quickDate,
    setQuickDate,
    handleQuickDateChange,
    datePickerProps,
    persona,
    getAdvancedOrderSearch,
  } = useOrders();
  const navigate = useNavigate();
  const { entityRef } = useParams();
  const user = useUserContext();

  const fetchOrders = React.useCallback(
    options => {
      const promise = getOrders(options?.quickDate || quickDate, options?.background);
      // if (!quickDate.date) {
      //   delete QUICK_DATES.CUSTOM.date;
      // }
      return promise;
    },
    [getOrders, quickDate]
  );

  const fetchStatuses = React.useCallback(() => {
    getStatuses().then(statuses => {
      const statusNames = ['ORDER REQUEST (PENDING)', 'ORDER REQUEST (REJECTED)'];
      for (let s of statuses) {
        if (s?.status === 'ACTIVE') {
          statusNames.push(s?.name.toUpperCase());
        }
      }
      setOrderStatusNames(statusNames);
    });
  }, [getStatuses]);

  useEffect(() => {
    fetchOrders();
    fetchStatuses();
  }, [fetchOrders, fetchStatuses]);

  const onCreateClick = useCallback(
    orderType => () => {
      navigate(`/${entityRef}/cx/new/${orderType}`);
    },
    [entityRef, navigate]
  );

  const handleRowClick = useCallback(
    (event, orderCrn) => {
      if (event.shiftKey) {
        navigate(`copy/${orderCrn}`);
      } else {
        navigate(`edit/${orderCrn}/general`);
      }
    },
    [navigate]
  );

  const RowRenderer = useMemo(
    () => props => {
      const { row } = props;
      return (
        <div className="grid-row" onClick={e => handleRowClick(e, row.crn)}>
          <GridRow {...props} />
        </div>
      );
    },
    [handleRowClick]
  );

  const filterStringRef = useRef();
  const [orderStatusNames, setOrderStatusNames] = React.useState([]);
  const [filterString, setFilterString] = React.useState();

  const key = useMemo(() => {
    return `status-filters-${entityRef || ''}-${user?.userRef || ''}`;
  }, [entityRef, user?.userRef]);

  const getStoredStatusFilters = useCallback(() => {
    const option = localStorage.getItem(key);
    if (option) {
      return JSON.parse(option);
    }
    return [];
  }, [key]);

  const [showStatuses, setShowStatuses] = React.useState(getStoredStatusFilters);

  const handleShowStatusesChange = React.useCallback(
    val => {
      setShowStatuses(val);
      localStorage.setItem(key, JSON.stringify(val));
    },
    [key]
  );

  const filteredOrders = React.useMemo(() => {
    if (!filterString) return orders;
    return orders.filter(o => orderFilter(o, filterString));
  }, [filterString, orders]);

  React.useEffect(() => {
    filterStringRef?.current?.focus();
  }, []);

  const navigateToOrderList = React.useCallback(() => {
    fetchOrders(true);
    navigate(`/${entityRef}/cx`);
  }, [fetchOrders, navigate, entityRef]);

  const navigateToCopyOrder = React.useCallback(
    orderCrn => {
      if (persona === 'seller') {
        const order = find(filteredOrders, { crn: orderCrn });
        const lineItem = find(order?.lineItems, { isPrimary: true });
        const orderType = (lineItem?.item?.type || 'CONCRETE').toLowerCase();

        navigate(`/${entityRef}/cx/copy/${orderType}/${orderCrn}`);
      }
    },
    [persona, filteredOrders, navigate, entityRef]
  );

  const quickDateRef = useRef(quickDate);
  useEffect(() => {
    quickDateRef.current = quickDate;
  }, [quickDate]);

  const refreshOrders = React.useCallback(() => {
    return fetchOrders({ background: true, quickDate });
  }, [fetchOrders, quickDate]);

  const { getLocations, locations } = useSetup();
  React.useEffect(() => {
    const params = { activeOnly: true, integratedSystems: 'CONNEX' };
    getLocations(entityRef, params);
  }, [entityRef, getLocations]);

  const hasConnexLocation = React.useMemo(() => {
    return locations?.length && locations.some(l => l.integratedSystem === 'CONNEX');
  }, [locations]);

  const hasLocationWithProductType = React.useCallback(
    productType => {
      return (
        locations?.length &&
        locations.some(l => (l.primaryProductType || 'CONCRETE').toLowerCase?.() === productType?.toLowerCase?.())
      );
    },
    [locations]
  );

  const showAdvancedSearch = React.useMemo(() => {
    return !quickDate;
  }, [quickDate]);

  return {
    getAdvancedOrderSearch,
    showAdvancedSearch,
    RowRenderer,
    onCreateClick,
    orders,
    filteredOrders,
    ordersBusy,
    ordersBackgroundBusy,
    orderStatusNames,
    handleQuickDateChange,
    quickDate,
    setQuickDate,
    datePickerProps,
    onFilterStringChange: setFilterString,
    onStatusChange: handleShowStatusesChange,
    showStatuses,
    filterStringRef,
    filterString,
    navigateToOrderList,
    navigateToCopyOrder,
    refreshOrders,
    persona,
    hasConnexLocation,
    hasLocationWithProductType,
  };
};
