const { REACT_APP_ENDPOINT = 'https://api.commandalkon.io' } = process.env;

console.log(`Using API ${REACT_APP_ENDPOINT}`);

const pathOverride = {
  // Uncomment for testing geo when running locally. -Mason
  //'/v2/geo': 'http://localhost:8004',
  // '/v2/': 'http://localhost:8000'
};

const hostname = (path = '') => {
  const { hostname } = window.location;

  if (path.startsWith('/assets')) {
    return '';
  }

  if (hostname.includes('localhost')) {
    for (const key of Object.keys(pathOverride)) {
      if (path.startsWith(key)) {
        return pathOverride[key];
      }
    }
  }

  if (hostname.includes('connex-orders-develop')) {
    return 'https://api-stage.commandalkon.io';
  }

  if (hostname.includes('connex-model-core')) {
    return 'https://connex-model-api.commandalkon.io';
  }

  return REACT_APP_ENDPOINT;
};

export default hostname;
