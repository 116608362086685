import { css } from 'styled-components';

export default css`
  //border: 1px solid #333;
  min-width: 50px;
  margin: 2px;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  position: relative;
  .yaxis-labels {
    flex: 1;
    margin-top: 15px;
    margin-bottom: 100px;
    position: relative;
    font-family: monospace;
    text-align: right;
    .interval {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
    }
    .time-zone {
      position: absolute;
      left: 0;
      right: 0;
      bottom: -47px;
      opacity: 0.8;
    }
  }
`;
