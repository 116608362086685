import styled from 'styled-components';
import OrderCard from '../../../order-cards/OrderCard';
import style from './style';

const Styled = styled.div`
  ${style}
`;

const OrderCardGroup = ({ orders, handleClick, columns, onOpen }) => {
  return (
    <Styled>
      {orders?.map?.(order => (
        <OrderCard
          key={order?.crn}
          onClick={handleClick}
          order={order}
          columns={columns}
          onOpen={() => onOpen(order.crn)}
        />
      ))}
    </Styled>
  );
};

export default OrderCardGroup;
