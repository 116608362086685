import React, { useEffect, useState } from 'react';
import useBillables from '../../../use-billables';
import styled from 'styled-components';
import Spinner from '../../../../../components/spinner/Spinner';
import PreviewHeader from './preview-header/PreviewHeader';
import CustomerPreview from './invoice-preview';
import { message } from 'antd';

const Styled = styled.div`
  width: 100%;

  #create-btn {
    margin-left: 10px;

    i {
      margin-left: 10px;
    }
  }

  .preview-header {
    .buttons {
      display: flex;
      grid-gap: 10px;
    }
  }
`;

const CreateInvoice = ({ items, filters }) => {
  const [templateRef, setTemplateRef] = useState('InvoicePerCustomer');
  const [customers, setCustomers] = useState(null);
  const [summary, setSummary] = useState(null);

  const {
    createPreview,
    preview,
    loading,
    createInvoicesAsync,
    creatingInvoices,
    links,
    progress,
    exportInvoiceData,
    exported,
  } = useBillables();

  useEffect(() => {
    items && createPreview(items);
  }, [createPreview, items]);

  useEffect(() => {
    if (preview) {
      setCustomers(preview.customers ?? []);
      setSummary(preview.summary ?? {});
    }
  }, [preview]);

  useEffect(() => {
    if (exported) {
      message.success('Export Data completed successfully.');
    }
  }, [exported]);

  const toggleCustomerSelection = React.useCallback(customer => {
    setCustomers(s =>
      s.map(c => {
        if (customer.customerRef === c.customerRef) {
          return { ...c, _isSelected: !c._isSelected };
        }
        return c;
      })
    );
  }, []);

  const selectAll = React.useCallback(options => {
    const { deselectAll } = options || {};
    setCustomers(customers => customers.map(customer => ({ ...customer, _isSelected: !deselectAll })));
  }, []);

  return (
    <Styled>
      <PreviewHeader
        selectAll={selectAll}
        creatingInvoices={creatingInvoices}
        createInvoicesAsync={createInvoicesAsync}
        templateRef={templateRef}
        customers={customers}
        items={items}
        filters={filters}
        setTemplateRef={setTemplateRef}
        progress={progress}
        exportInvoiceData={exportInvoiceData}
      />
      <Spinner spin={loading}>
        <div>
          {customers && (
            <CustomerPreview
              summary={summary}
              customers={customers}
              invoices={links}
              creatingInvoices={creatingInvoices}
              toggleCustomerSelection={toggleCustomerSelection}
              progress={progress}
              exported={exported}
            />
          )}
        </div>
      </Spinner>
    </Styled>
  );
};

export default CreateInvoice;
