import cn from 'classnames';
import styled from 'styled-components';
import {useState} from "react";
import {ChromePicker as ReactColorPicker} from 'react-color';
import {Tooltip} from "antd";


const ColorPicker = ({className, onChange, color, readOnly, disableAlpha = true}) => {
  if(readOnly) {
    return <div className={cn(className, {readOnly})}>
      <div className={'color-swatch readOnly'}>
        <div className={'color-preview'} style={{background: color}}></div>
      </div>
    </div>
  }

  return <div className={cn(className)}>
    <Tooltip trigger={'click'} overlayInnerStyle={{padding: 0}} title={ <ReactColorPicker disableAlpha={disableAlpha} color={color} onChange={(color) => {
      onChange && onChange(color);
    }} />}>
      <div className={'color-swatch'}>
        <div className={'color-preview'} style={{background: color}}></div>
      </div>
    </Tooltip>
  </div>
}

export default styled(ColorPicker)`
  .color-swatch {
    &.readOnly {
      cursor: not-allowed;
    }
    width: 45px;
    height: 30px;
    background: transparent;
    padding: 5px;
  }
  .color-preview {
    width: 30px;
    height: 15px;
    border-radius: 2px;
  }
  .chrome-picker {
    position: relative;
    left: -25%;
  }
`;
