import { css } from 'styled-components';

export default css`
  
  .title {
    text-align: center;
    font-weight: bold;
    font-size: 1.0em;
    position: relative;
  }
  
`;
