import { css } from 'styled-components';

export default css`
  display: flex;
  height: 100%;
  flex-direction: column;
  overflow: hidden;

  @media (max-width: 475px) {
    .back-action {
      button {
        width: inherit;
        .icon {
          margin-right: 0;
        }

        & > *:nth-child(2) {
          display: none;
        }
      }
    }
  }

  .ticket-header {
    margin-bottom: 12px;
    display: flex;
    padding-right: 10px;

    .back-action {
      float: left;
      margin-right: 12px;
      margin-bottom: 12px;
    }
  }

  .ticket-form {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 10px;
  }

  .ticket-form-footer {
    flex-shrink: 0;
    margin-bottom: 20px;
  }

  .buttons {
    position: sticky;
    bottom: 0;
    background-color: #1f1f1f;
    padding-top: 20px;
    button {
      width: 100%;
      font-weight: 400;
      font-size: 1rem;
    }
  }

  .text-area {
    margin-top: 30px;
  }

  .buttons-container {
    margin-top: 10px;
    display: flex;
    gap: 10px;
    justify-content: space-between;
    button {
      width: 100%;
    }
  }
`;
