import { cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Translate } from '../../../localization/Translate';
import style from './style';
import InputNumberBlur from '../../../../../setup/components/input-number-blur';
import CommandBar from '../../../../../setup/setup-item-editor/command-bar';
import roundedToCents from '../../../../../../util/other/rounded-to-cents';

const Price = ({ product, price, onChange }) => {
  return (
    <tr>
      <td>
        {product.id} / {product.name}
      </td>
      <td>{product.type}</td>
      <td>
        <InputNumberBlur id={product.productRef} value={price ?? ''} onChange={onChange} />
      </td>
      <td>{product.uomCode}</td>
    </tr>
  );
};

const Styled = styled.div`
  ${style}
`;

const OrderCostBookPrices = ({ item, products, onChange, onCancel, onSave }) => {
  const [list, setList] = useState(null);
  useEffect(() => {
    if (products) {
      setList(products);
    }
  }, [products]);

  const getPrice = React.useCallback(
    productRef => {
      const { price } = item?.prices?.find(p => p.productRef === productRef) || { price: { value: '' } };
      return price.value ?? '';
    },
    [item?.prices]
  );

  const changePrice = React.useCallback(
    (id, value) => {
      const prices = cloneDeep(item?.prices || []).filter(p => p.productRef !== id);
      const product = products.find(p => p.productRef === id);
      if (product) {
        const fValue = parseFloat(`${value}`);
        if (!isNaN(fValue)) {
          const price = {
            id: product.id,
            productRef: id,
            price: {
              currencyCode: 'USD',
              uomCode: product.uomCode,
              value: roundedToCents(fValue),
            },
          };

          prices.push(price);
        }

        onChange && onChange('prices', prices);
      }
    },
    [onChange, products, item?.prices]
  );

  console.log(item);

  return (
    <Styled className="cost-book-prices">
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>
                <Translate stringId="product" defaultMessage="Product" />
              </th>
              <th>
                <Translate stringId="type" defaultMessage="Type" />
              </th>
              <th>
                <Translate stringId="price" defaultMessage="Price" />
              </th>
              <th>
                <Translate stringId="uom" defaultMessage="UOM" />
              </th>
            </tr>
          </thead>
          <tbody>
            {list?.map(product => (
              <Price
                key={product.productRef}
                product={product}
                price={getPrice(product.productRef)}
                onChange={changePrice}
              />
            ))}
          </tbody>
        </table>
        <CommandBar onSave={onSave} onCancel={onCancel} typeId="order-cost-book" disabled={false} />
      </div>
    </Styled>
  );
};

export default OrderCostBookPrices;
