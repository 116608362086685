import { Collapse } from 'antd';
import cn from 'classnames';
import moment from 'moment';
import React from 'react';
import { isNil } from 'lodash';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Number } from '../../../components/localization/Number';
import { SEVERITY } from '../../../components/pie-chart/PieChart';
import style from './style';
import trimDecimals from '../../../../../util/other/trim-decimals';
import { isCancelledOrder } from './util';
import OrderCardContainer from '../order-card-container';
import { Uom } from '../../../components/localization/Uom';

const Styled = styled(Collapse)`
  ${style}
`;

const { Panel } = Collapse;

const calculateMaxSeverity = progress => {
  const { success, warning, danger } = progress;

  if (danger) return SEVERITY.DANGER;
  if (warning) return SEVERITY.WARNING;
  if (success) return SEVERITY.SUCCESS;
  return '';
};

const OrderCardsGrouped = ({ groupedOrders, groupBy, handleClick, columns, onOpen, className }) => {
  const summarizedOrders = React.useMemo(() => {
    const now = moment();
    const summarized = Object.keys(groupedOrders).reduce((grouped, key) => {
      const stats = groupedOrders[key].reduce(
        (acc, order) => {
          const numberOfScheduledTickets = !isCancelledOrder(order?.supplierStatus?.name)
            ? order?.deliverySchedule?.schedule?.length || 0
            : 0;
          const numberOfTickets =
            order?.deliverySchedule?.tickets?.reduce((acc, ticket) => (ticket?.ticket?.isVoided ? acc : acc + 1), 0) ??
            0;

          acc.stats.orderedQuantity =
            acc?.stats?.orderedQuantity +
            (!isCancelledOrder(order?.supplierStatus?.name)
              ? order?.deliverySchedule?.deliveryProgress?.orderedQuantity ?? 0
              : order?.deliverySchedule?.deliveryProgress?.deliveredQuantity ?? 0);
          acc.stats.deliveredQuantity =
            acc?.stats?.deliveredQuantity + (order?.deliverySchedule?.deliveryProgress?.deliveredQuantity ?? 0);

          acc.stats.numberOfScheduledTickets = acc?.stats?.numberOfScheduledTickets + numberOfScheduledTickets;

          acc.stats.numberOfTickets = acc?.stats?.numberOfTickets + numberOfTickets;

          if (numberOfScheduledTickets < 1) {
            acc.stats.progress.success = acc?.stats?.progress?.success + 1;
          } else {
            const nextScheduledLoad = moment(order?.deliverySchedule?.schedule[0].ticketEvents?.ARRIVE_JOB);
            const minutesToArrive = nextScheduledLoad.diff(now, 'm');

            if (minutesToArrive > 0) {
              acc.stats.progress.success = acc?.stats?.progress?.success + 1;
            } else if (Math.abs(minutesToArrive) > 60) {
              acc.stats.progress.danger = acc?.stats?.progress?.danger + 1;
            } else if (Math.abs(minutesToArrive) <= 60) {
              acc.stats.progress.warning = acc?.stats?.progress?.warning + 1;
            }
          }

          acc.orders = [...acc.orders, order];

          acc.stats.minimumProgress = calculateMaxSeverity(acc.stats.progress);

          if (!isNil(order?.lineItems[0]?.item?.uomCode)) {
            acc.stats.uomDisplay = order?.lineItems[0]?.item?.uomCode;
          }

          return acc;
        },

        {
          stats: {
            orderedQuantity: 0,
            deliveredQuantity: 0,
            numberOfScheduledTickets: 0,
            numberOfTickets: 0,
            progress: { success: 0, danger: 0, warning: 0 },
            minimumProgress: null,
          },
          orders: [],
        }
      );

      grouped[key] = stats;
      return grouped;
    }, {});
    return summarized;
  }, [groupedOrders]);

  return (
    <Styled accordion className={cn('order-list-collapse', className)} bordered={false}>
      {Object.keys(summarizedOrders)?.map((key, index) => {
        const group = summarizedOrders[key];

        const percentage =
          (group.stats.numberOfTickets / (group.stats.numberOfTickets + group.stats.numberOfScheduledTickets)) * 100;

        return (
          <Panel
            header={
              groupBy && (
                <div className="order-list-panel-content">
                  <div className="card-info">
                    <div className="name">{key !== 'undefined' ? key : 'None Available'}</div>
                    <div className="volume">
                      {trimDecimals(group?.stats?.deliveredQuantity)} /{' '}
                      <Number value={group.stats?.orderedQuantity} decimalPlaces={1} />{' '}
                      <Uom uom={{ uomCode: group.stats?.uomDisplay }} />
                    </div>
                    <div className="number-of-orders">
                      <FormattedMessage
                        id="numberOfOrders"
                        values={{ orderCount: group?.orders?.length || 0 }}
                        defaultMessage="{orderCount, plural, =0 {0 Orders} one {1 Order} other {{orderCount} Orders}}"
                      />
                    </div>
                  </div>
                  <div className={cn('progress-bar', group?.stats?.minimumProgress)}>
                    <div
                      className={cn('percentage', group?.stats?.minimumProgress)}
                      style={{ width: `${percentage}%` }}
                    />
                  </div>
                </div>
              )
            }
            className={cn('order-list-collapse-panel', group?.stats?.minimumProgress)}
            key={index}
          >
            <div className="card-group">
              <OrderCardContainer orders={group?.orders} onClick={handleClick} columns={columns} onOpen={onOpen} />
            </div>
          </Panel>
        );
      })}
    </Styled>
  );
};

export default OrderCardsGrouped;
