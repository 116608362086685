import { PRIMARY_PRODUCT_TYPES } from "../../../order/constants";

export const status = [
  { value: 'ACTIVE' },
  { value: 'INACTIVE' },
];

export const taxBasis = [
  { value: 'address', label: 'Delivery Address' },
  { value: 'location', label: 'Plant Address' },
  { value: 'dispatchLocation', label: 'Dispatch Location Address' },
];

export const primaryProductType = PRIMARY_PRODUCT_TYPES.map(type => ({ value: type, label: type }));

export const integratedSystem = [
  { value: 'CONNEX' },
  {
    value: 'INTEGRA',
    label: 'Integra'
  },
  {
    value: 'COMMAND_SERIES_V2',
    label: 'COMMANDseries V2'
  },
  {
    value: 'COMMAND_SERIES_V3',
    label: 'COMMANDseries V3'
  },
  {
    value: 'COMMAND_BATCH',
    label: 'COMMANDbatch'
  },
  {
    value: 'APEX',
    label: 'Apex'
  },
]

