const isReactElement = variable => {
  if (typeof variable === 'object' && variable !== null) {
    if (variable.$$typeof === Symbol.for('react.element') || typeof variable.type === 'function') {
      return true;
    }
  }
  return false;
};

export default isReactElement;
