export default (order, filterString = '') => {
  const upperCaseFilterString = filterString.toUpperCase();

  return (
    order?.id?.toUpperCase()?.includes(upperCaseFilterString) ||
    order?.id?.toUpperCase()?.includes(upperCaseFilterString) ||
    order?.supplierStatus?.name?.toUpperCase?.()?.includes(upperCaseFilterString) ||
    order?.customerParty?.id?.toUpperCase?.()?.includes(upperCaseFilterString) ||
    order?.customerParty?.name?.toUpperCase?.()?.includes(upperCaseFilterString) ||
    order?.project?.id?.toUpperCase?.()?.includes(upperCaseFilterString) ||
    order?.project?.name?.toUpperCase?.()?.includes(upperCaseFilterString) ||
    order?.origin?.id?.toUpperCase?.()?.includes(upperCaseFilterString) ||
    order?.origin?.name?.toUpperCase?.()?.includes(upperCaseFilterString) ||
    order?.lineItems?.filter?.(
      i =>
        i.item?.id?.toUpperCase().includes(upperCaseFilterString) ||
        i.item?.name?.toUpperCase().includes(upperCaseFilterString)
    ).length >= 1 ||
    order?.deliverySchedule?.tickets?.filter?.(t => t?.ticket?.id?.toUpperCase?.()?.includes?.(upperCaseFilterString))
      ?.length ||
    order?.contacts?.filter?.(
      c =>
        c?.phoneNumber?.toUpperCase?.()?.replace?.(/-/g, '')?.includes?.(upperCaseFilterString) ||
        `${c?.firstName || ''}${c?.firstName && c?.lastName ? ' ' : ''}${c?.lastName || ''}`
          ?.toUpperCase()
          .includes(upperCaseFilterString)
    )?.length ||
    order?.destination?.address?.address1?.toUpperCase?.()?.includes(upperCaseFilterString)
  );
};
