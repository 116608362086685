import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Translate } from '../localization/Translate';
import { useUom } from '../localization/Uom';
import { useTranslateMessage } from '../localization/useTranslateMessage';
import SelectCarrier from './SelectCarrier';
import SelectVehicle from './SelectVehicle';
import SelectLocation from './SelectLocation';
import { find } from 'lodash';
import LineItemQuantity from './LineItemQuantity';
import Spinner from '../../../../components/spinner/Spinner';
import { useTicket } from './useTicket';
import { api } from './api';
import { calcTicketQuantities } from './calcTicketQuantities';
import TextAreaField from '../TextAreaField';
import SelectDriver from './SelectDriver';
import FlexItem from './components/FlexItem';
import FlexContainer from './components/FlexContainer';
import SaveTicketButton from './components/SaveTicketButton';
import useSetup from '../../../setup/useSetup';
import TicketWarning from './components/TicketWarning';

const TicketEntry = ({
  orderRef,
  carrierRef: carrierRefProp = '',
  vehicleRef: vehicleRefProp = '',
  locationRef: locationRefProp = '',
  driverRef: driverRefProp = '',
  open = false,
  onTicketed,
}) => {
  const [locationRef, setLocationRef] = useState(locationRefProp);
  const [carrierRef, setCarrierRef] = useState(carrierRefProp);
  const [vehicleRef, setVehicleRef] = useState(vehicleRefProp);
  const [driverRef, setDriverRef] = useState(driverRefProp);
  const [slumps, setSlumps] = useState({});
  const [load, setLoad] = useState(null);
  const [params, setParams] = useState({});
  const [vehicles, setVehicles] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [driverInstructions, setDriverInstructions] = useState('');
  const [billingNote, setBillingNote] = useState('');

  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [, setSelectedDriver] = useState(null);
  const [primaryProduct, setPrimaryProduct] = useState(null);
  const [previousTicket, setPreviousTicket] = useState(null);
  const [slumpWarning, setSlumpWarning] = useState(null);
  const [productLimits, setProductLimits] = useState({});
  const [isCleanup, setIsCleanup] = useState({});

  const {
    entityRef,
    getOrder,
    order,
    orderBusy,
    listOrderVehicles,
    listDrivers,
    getRules,
    applyRules,
    quantities,
    setQuantities,
    associatedItems,
    createOrderTicket,
    ticketBusy,
    aboveShippedAmount,
  } = useTicket();

  const translateMessage = useTranslateMessage();
  const { getUom } = useUom();

  const { getSetupItems, setupItems, busy } = useSetup('vehicle-type');

  const { getSetupItems: getCompanies, setupItems: companies, busy: loadingCompanies } = useSetup('entity-setup');

  const { getProduct, product: completePrimaryProduct, busy: loadingCompletePrimaryProduct } = useSetup();

  const handleGetPrimaryProduct = useCallback(
    async primaryProductRef => {
      try {
        await getProduct(entityRef, primaryProductRef);
      } catch (error) {
        console.log(error);
      }
    },
    [entityRef, getProduct]
  );

  useEffect(() => {
    if (orderRef && open) {
      getOrder(orderRef, { expand: 'productLimits', revalidateDeliverySchedule: true }).catch(alert);
      getRules('TICKETING').catch(alert);
      getCompanies();
      getSetupItems();
    }
  }, [orderRef, open, getOrder, getRules, getCompanies, getSetupItems]);

  useEffect(() => {
    const existsPrimaryProduct = order?.lineItems?.find(p => p.isPrimary);
    if (!existsPrimaryProduct?.item?.productRef) return;
    handleGetPrimaryProduct(existsPrimaryProduct.item.productRef);
  }, [order?.lineItems, handleGetPrimaryProduct]);

  useEffect(() => {
    if (order) {
      const ticketQuantities = calcTicketQuantities(order, undefined, false);

      setLoad(ticketQuantities.nextLoad);
      setIsCleanup(!!(order?.deliverySchedule?.schedule?.find(sched => sched.loadNumber === ticketQuantities?.nextLoad?.loadNumber)?.isExtra));
      setLocationRef(ticketQuantities?.nextLoad?.overrides?.location?.locationRef || order?.location?.locationRef);
      setDriverInstructions(order.driverInstructions || '');
      setBillingNote(order.billingNote || '');
      if (ticketQuantities.nextLoad) {
        const primaryLineItem = order.lineItems?.[0];
        setPrimaryProduct(primaryLineItem);
        setProductLimits(primaryLineItem?.limits ?? {});

        const productRef = primaryLineItem?.item?.productRef;
        if (productRef) {
          setQuantities(existingQuantities => {
            return {
              ...existingQuantities,
              ...ticketQuantities.associatedProductQuantities,
              [productRef]: ticketQuantities.loadQuantity,
            };
          });
          setSlumps(existingSlumps => {
            return {
              ...existingSlumps,
              [productRef]: ticketQuantities.nextLoad?.slump ?? primaryLineItem?.properties?.slump,
            };
          });

          slumpLimitValidation(ticketQuantities.nextLoad?.slump ?? primaryLineItem?.properties?.slump);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, setQuantities]);

  const createTicket = useCallback(
    currentTicket => {
      createOrderTicket(orderRef, currentTicket)
        .then(order => {
          onTicketed && onTicketed(order);
          setVehicleRef('');
          setDriverRef('');
          setSelectedVehicle(null);
          setSelectedDriver(null);
        })
        .catch(alert);
    },
    [orderRef, createOrderTicket, setVehicleRef, setDriverRef, setSelectedVehicle, setSelectedDriver, onTicketed]
  );

  useEffect(() => {
    setParams({
      carrierRef,
      vehicleRef,
      locationRef,
      driverRef,
      quantities,
      slumps,
      driverInstructions,
      billingNote,
    });
  }, [quantities, carrierRef, vehicleRef, driverRef, locationRef, slumps, driverInstructions, billingNote]);

  // If they open the drawer reload the vehicles to get the latest data to display the correct warnings
  useEffect(() => {
    if (carrierRef && orderRef && open) {
      listOrderVehicles(orderRef, { carrierRef, activeOnly: true })
        .then(v => {
          v.sort((a, b) => {
            return a.id < b.id ? -1 : 1;
          });
          setVehicles(v);
        })
        .catch(alert);
    }
  }, [orderRef, carrierRef, carrierRefProp, vehicleRefProp, listOrderVehicles, open]);

  useEffect(() => {
    const vehicle = vehicles.find(v => v.crn === vehicleRefProp);
    if (vehicle?.configuration?.driver?.driverRef) {
      setDriverRef(vehicle?.configuration?.driver?.driverRef);
    }
  }, [vehicleRefProp, vehicles]);

  useEffect(() => {
    setSelectedVehicle(find(vehicles, v => v.crn === vehicleRef));
  }, [setSelectedVehicle, vehicles, vehicleRef, vehicleRefProp]);

  const quantityChanged = (lineItem, quantity) => {
    if (primaryProduct?.item?.productRef === lineItem.item?.productRef) {
      applyRules();
    }

    setQuantities(existingQuantities => {
      return {
        ...existingQuantities,
        [lineItem?.item?.productRef]: quantity,
      };
    });
  };

  const slumpLimitValidation = useCallback(
    slump => {
      if (slump && productLimits) {
        const min = Number(productLimits?.consistence?.minimumValue ?? 0);
        const max = Number(productLimits?.consistence?.maximumValue ?? 1000);

        if (Number(slump) < min || Number(slump) > max) {
          setSlumpWarning(
            translateMessage({
              stringId: 'slumpWarning',
              defaultMessage: `Slump outside of range: min {min} and max {max}`,
              values: { min, max },
            })
          );
        } else {
          setSlumpWarning(null);
        }
      } else {
        setSlumpWarning(null);
      }
    },
    [setSlumpWarning, productLimits]
  );

  const slumpChanged = (lineItem, slump) => {
    setSlumps(existingSlumps => {
      return {
        ...existingSlumps,
        [lineItem?.item?.productRef]: slump,
      };
    });

    slumpLimitValidation(slump);
  };

  const remainingQuantity = useMemo(() => {
    return selectedVehicle?.remainingQuantity?.value || 0;
  }, [selectedVehicle]);

  const preBatchWater = useMemo(() => {
    return selectedVehicle?.waterOnTruck?.value || 0;
  }, [selectedVehicle]);

  useEffect(() => {
    listDrivers().then(result => {
      result.sort((a, b) => {
        return a.id < b.id ? -1 : 1;
      });
      setDrivers(result);
    });
  }, [listDrivers]);

  useEffect(() => {
    const previousTicketRef = selectedVehicle?.ticket?.ticketRef;
    if (remainingQuantity > 0 && previousTicketRef) {
      api.getTicket(entityRef, previousTicketRef).then(setPreviousTicket);
    }
  }, [selectedVehicle, remainingQuantity, entityRef]);

  const mixIdValues = useMemo(() => {
    if (order && previousTicket && remainingQuantity > 0) {
      const orderPrimaryItemId = order.lineItems?.[0]?.item?.id;
      const previousTicketPrimaryItemId = previousTicket.lineItems?.[0]?.item?.id;

      if (orderPrimaryItemId && previousTicketPrimaryItemId && orderPrimaryItemId !== previousTicketPrimaryItemId) {
        return {
          matches: false,
          ticketItemId: previousTicketPrimaryItemId,
          orderItemId: orderPrimaryItemId,
        };
      }
    }
    return { matches: true };
  }, [order, previousTicket, remainingQuantity]);

  const locationWarning = useMemo(() => {
    if (selectedVehicle) {
      let vehicleLocationRef = selectedVehicle.toLocation?.locationRef;

      if (!vehicleLocationRef) {
        vehicleLocationRef = selectedVehicle.fromLocation?.locationRef;
      }

      if (!vehicleLocationRef) {
        vehicleLocationRef = selectedVehicle.homeLocation?.locationRef;
      }

      if (vehicleLocationRef !== locationRef) {
        return translateMessage({
          stringId: 'rogueVehicle',
          defaultMessage:
            "Vehicle's current location does not match selected location. Are you sure you would like to ticket this vehicle?",
        });
      }
    } else {
      return '';
    }
  }, [selectedVehicle, locationRef]);

  const loadWarning = useMemo(() => {
    if (!selectedVehicle || !selectedVehicle.vehicleType || !order?.lineItems?.length || !setupItems?.length) {
      return null;
    }

    const primaryProduct = order?.lineItems.find(product => product.isPrimary);
    const vehicleType = setupItems.find(vehicleType => vehicleType.crn === selectedVehicle.vehicleType.vehicleTypeRef);

    if (!primaryProduct?.item?.productRef || !vehicleType?.defaultLoadSize) {
      return null;
    }

    if (parseFloat(quantities[primaryProduct.item.productRef]) > parseFloat(vehicleType.maxLoadSize)) {
      if (vehicleType.maxLoadSize) {
        const capacity = getUom({ value: vehicleType.maxLoadSize, uomCode: vehicleType?.maxLoadSizeUOM || 'CY' });
        return translateMessage({
          stringId: 'maxOverloaded',
          defaultMessage: 'The vehicle is being overloaded, since its max load size is {capacity}',
          values: { capacity },
        });
      } else if (vehicleType.defaultLoadSize) {
        const capacity = getUom({
          value: vehicleType.defaultLoadSize,
          uomCode: vehicleType?.defaultLoadSizeUOM || 'CY',
        });
        return translateMessage({
          stringId: 'defaultOverloaded',
          defaultMessage: 'The vehicle is being overloaded, since its default load size is {capacity}',
          values: { capacity },
        });
      }
    }

    return null;
  }, [selectedVehicle, order?.lineItems, setupItems, quantities]);

  const productNotInPlantWarning = useMemo(() => {
    if (!completePrimaryProduct?.locations || completePrimaryProduct.locations.all || !locationRef) return null;

    return !completePrimaryProduct.locations[locationRef]
      ? translateMessage({
          stringId: 'primaryProductNotAvailable',
          defaultMessage: 'Primary Product is not available at this plant!',
        })
      : null;
  }, [completePrimaryProduct, locationRef]);

  const vehicleInAnotherLoadWarning = useMemo(() => {
    if (!selectedVehicle?.ticket) return null;

    const vehicleStatusEventDate = selectedVehicle?.vehicleStatus?.eventDateTime;
    const vehicleTicketEventDate = selectedVehicle?.ticket?.latestEventStatusTime;

    let statusCode = null;
    if (vehicleStatusEventDate > vehicleTicketEventDate) {
      statusCode = selectedVehicle?.vehicleStatus?.statusCode;
    } else {
      statusCode = selectedVehicle?.ticket?.latestEventStatus;
    }

    if (!statusCode) return null;

    return statusCode !== 'IN_YARD' && statusCode !== 'ARRIVE_PLANT'
      ? translateMessage({
          stringId: 'vehicleInAnotherLoadWarning',
          defaultMessage: 'Vehicle is currently on another load.  Continuing will complete the current delivery.',
        })
      : null;
  }, [selectedVehicle]);

  const primaryProductMinimunLoadChargeOverride = useMemo(() => {
    const existsPrimaryProduct = order?.lineItems?.find(li => li.isPrimary);
    if (!existsPrimaryProduct?.item?.productRef) return null;

    const hasMinimumLoadChargeProducts = order?.lineItems?.some(li => li?.item?.minimumLoadCharge);
    if (!hasMinimumLoadChargeProducts) return null;

    const defaultLoadQuantity = quantities?.[existsPrimaryProduct?.item?.productRef];

    return defaultLoadQuantity !== load?.quantity
      ? translateMessage({
          stringId: 'primaryProductMinimumLoadChargeOverride',
          defaultMessage: 'Changing the primary product qty can add or remove a Minimum load charge',
        })
      : null;
  }, [order?.lineItems, quantities, load?.quantity]);

  const invalidQuantities = useMemo(() => {
    const quantitiesGreaterThanZero = order?.lineItems?.every(lineItem => {
      if (lineItem?.isPrimary) {
        return quantities[lineItem?.item?.productRef] > 0;
      }
      return true;
    });
    return !quantitiesGreaterThanZero;
  }, [order, quantities]);

  const invalidSlumps = useMemo(() => {
    const slumpsGreaterThanZero = order?.lineItems?.every(lineItem => {
      if (lineItem?.isPrimary && order?.orderType === 'CONCRETE') {
        return slumps[lineItem?.item?.productRef] > 0;
      }
      return true; // Non primary are considered valid
    });

    return !slumpsGreaterThanZero;
  }, [order, slumps]);

  const disableSave = useMemo(() => {
    if (loadingCompanies || invalidQuantities || invalidSlumps) return true;

    const company = companies?.[0];
    if (!company) return false;

    return aboveShippedAmount > 0 && company.avoidOvershippingInTicketing && !isCleanup;
  }, [aboveShippedAmount, companies, loadingCompanies, invalidQuantities, invalidSlumps, isCleanup]);

  const availableLoad = useMemo(() => {
    return !!(order && load);
  }, [order, load]);

  return (
    <Spinner spin={orderBusy || busy || loadingCompanies || loadingCompletePrimaryProduct}>
      <FlexContainer>
        <FlexItem
          title={`${translateMessage({ stringId: 'ticketingOrder', defaultMessage: 'Ticketing Order' })}:`}
          text={`#${order?.id}`}
        />
        <FlexItem
          title={`${translateMessage({ stringId: 'load', defaultMessage: 'Load' })}:`}
          text={`#${load?.loadNumber}`}
        />
      </FlexContainer>

      <FlexItem
        title={`${translateMessage({ stringId: 'customer', defaultMessage: 'Customer' })}:`}
        text={`${order?.customerParty?.id} / ${order?.customerParty?.name}`}
      />

      {order?.project?.id !== 'NONE' && order?.project?.name !== 'None Selected' && (
        <FlexItem
          title={`${translateMessage({ stringId: 'project', defaultMessage: 'Project' })}:`}
          text={`${order?.project?.id} / ${order?.project?.name}`}
        />
      )}

      {(order?.destination?.address?.address1 || order?.destination?.address?.city) && (
        <FlexItem
          title={`${translateMessage({ stringId: 'address', defaultMessage: 'Address' })}:`}
          text={`${order?.destination?.address?.address1} ${order?.destination?.address?.city}`}
        />
      )}

      <br />

      <SelectLocation orderRef={orderRef} locationRef={locationRef} onChange={setLocationRef} disabled={ticketBusy} />

      <SelectCarrier orderRef={orderRef} carrierRef={carrierRef} onChange={setCarrierRef} disabled={ticketBusy} />

      <SelectVehicle
        vehicleRef={vehicleRef}
        vehicles={vehicles}
        disabled={ticketBusy}
        onChange={newVehicleRef => {
          setVehicleRef(newVehicleRef);
          const vehicle = find(vehicles, v => v.crn === newVehicleRef);
          if (vehicle) {
            const newDriverRef = vehicle?.configuration?.driver?.driverRef || null;
            if (newDriverRef) {
              setDriverRef(newDriverRef);
              setSelectedDriver(find(drivers, d => d.crn === newDriverRef));
            } else {
              setDriverRef(null);
              setSelectedDriver(null);
            }
          }
          setSelectedVehicle(vehicle);
        }}
      />

      <SelectDriver
        driverRef={driverRef}
        drivers={drivers}
        disabled={ticketBusy}
        onChange={newDriverRef => {
          setDriverRef(newDriverRef);
          setSelectedDriver(find(drivers, v => v.crn === newDriverRef));
        }}
      />

      {order?.lineItems
        ?.filter(lineItem => !lineItem?.item?.minimumLoadCharge || quantities[lineItem?.item?.productRef] > 0)
        .map((lineItem, index) => {
          return (
            <LineItemQuantity
              quantity={quantities[lineItem?.item?.productRef]}
              slump={slumps[lineItem?.item?.productRef]}
              aboveShippedAmount={aboveShippedAmount}
              lineItem={lineItem}
              key={lineItem?.item?.productRef + index}
              onChangeQuantity={quantity => quantityChanged(lineItem, quantity)}
              onChangeSlump={slump => slumpChanged(lineItem, slump)}
              disabled={lineItem?.item?.minimumLoadCharge || ticketBusy}
              orderType={order?.orderType}
            />
          );
        })}

      {associatedItems?.map((lineItem, index) => {
        return (
          <LineItemQuantity
            quantity={quantities[lineItem?.item?.productRef]}
            lineItem={lineItem}
            key={lineItem?.item?.productRef + index}
            onChangeQuantity={quantity => quantityChanged(lineItem, quantity)}
            disabled={lineItem?.item?.minimumLoadCharge || ticketBusy}
          />
        );
      })}

      <TextAreaField
        fieldName={'driverInstructions'}
        value={driverInstructions}
        onChange={(_fieldName, newValue) => setDriverInstructions(newValue)}
        disabled={ticketBusy}
        fieldLabel="Driver Instructions"
        fieldLabelStringId="driverInstructions"
      />

      <TextAreaField
        fieldName={'billingNote'}
        value={billingNote}
        onChange={(_fieldName, newValue) => setBillingNote(newValue)}
        fieldLabel="Billing Note"
        fieldLabelStringId="billingNote"
        disabled={ticketBusy}
      />

      {locationWarning && <TicketWarning message={locationWarning} />}

      {slumpWarning && <TicketWarning message={slumpWarning} />}

      {loadWarning && <TicketWarning message={loadWarning} />}

      {productNotInPlantWarning && <TicketWarning message={productNotInPlantWarning} />}

      {vehicleInAnotherLoadWarning && <TicketWarning message={vehicleInAnotherLoadWarning} />}

      {primaryProductMinimunLoadChargeOverride && <TicketWarning message={primaryProductMinimunLoadChargeOverride} />}
      
      {aboveShippedAmount > 0 && !isCleanup && (
        <TicketWarning
          message={
            <Translate
              stringId="aboveShippedAmount"
              defaultMessage="Shipping {aboveShippedAmount} more than ordered."
              values={{ aboveShippedAmount }}
            />
          }
          danger={disableSave}
        />
      )}

      {!availableLoad && (
        <TicketWarning
          message={<Translate stringId="noNextLoadAvailable" defaultMessage="No Next Load Available" />}
          danger={true}
        />
      )}
      {invalidQuantities && (
        <TicketWarning
          message={<Translate stringId="noProductQuantity" defaultMessage="Product quantity is required" />}
          danger={true}
        />
      )}
      {invalidSlumps && (
        <TicketWarning
          message={<Translate stringId="noSlumpQuantity" defaultMessage="Slump quantity is required" />}
          danger={true}
        />
      )}

      <SaveTicketButton
        onSave={createTicket}
        disabled={ticketBusy || disableSave || !availableLoad || carrierRef?.length < 1 || vehicleRef?.length < 1}
        remainingQuantity={remainingQuantity}
        preBatchWater={preBatchWater}
        selectedVehicle={selectedVehicle}
        params={params}
        setParams={setParams}
        loading={ticketBusy}
        mixIdValues={mixIdValues}
        metricId="core-order-detail-create-ticket-save"
        data-testid="button-save-ticket"
      />
    </Spinner>
  );
};

export default TicketEntry;
