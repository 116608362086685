import { Tag } from 'antd';
import _ from 'lodash';
import React, { useCallback } from 'react';
import Select from '../../../../../components/select';
import { useTranslateMessage } from '../../../components/localization/useTranslateMessage';
import { useOrderListContext } from '../../../components/OrderListContext';

const FilterStatus = ({ onChange, value }) => {
  const { filteredOrders, orderStatusNames } = useOrderListContext();
  const translateMessage = useTranslateMessage();

  const statusOptions = React.useMemo(() => {
    return orderStatusNames.map(s => {
      const orderCount = _.countBy(filteredOrders, 'supplierStatus.name');
      const description = translateMessage({
        stringId: `statusCounts.${s}`,
        values: { count: orderCount[s] || 0 },
        defaultMessage: `${s}: ${orderCount[s] || 0}`,
      });
      return { id: s, description };
    });
  }, [filteredOrders, orderStatusNames, translateMessage]);

  const tagRender = useCallback(props => {
    const { value, closable, onClose } = props;
    const onPreventMouseDown = event => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag onMouseDown={onPreventMouseDown} closable={closable} onClose={onClose} color={'#217Fd9'}>
        {value}
      </Tag>
    );
  }, []);

  return (
    <Select
      defaultValue={[]}
      testid="filter-status"
      mode="multiple"
      placeholder={'filterByStatus'}
      options={statusOptions}
      value={value}
      onChange={onChange}
      tagRender={tagRender}
    />
  );
};

export default FilterStatus;
