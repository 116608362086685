import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { api } from './api';
import { api as setup } from './api/setup-items';
import { toBase64 } from './utils/to-base-64';
import _ from 'lodash';
import hostname from '../../hostname';
import isValid from './is-valid';

const useSetup = setupItemItemId => {
  const { entityRef } = useParams();

  const [error, setError] = useState(null);

  const [projects, setProjects] = useState([]);
  const [project, setProject] = useState(null);

  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState(null);

  const [customers, setCustomers] = useState([]);
  const [customer, setCustomer] = useState(null);

  const [locations, setLocations] = useState([]);
  const [location, setLocation] = useState(null);

  const [trackables, setTrackables] = useState([]);
  const [trackable, setTrackable] = useState(null);

  const [trackableTypes, setTrackableTypes] = useState([]);
  const [trackableType, setTrackableType] = useState(null);

  const [vehicles, setVehicles] = useState([]);
  const [vehicle, setVehicle] = useState(null);

  const [drivers, setDrivers] = useState([]);
  const [driver, setDriver] = useState(null);

  const [carriers, setCarriers] = useState([]);
  const [carrier, setCarrier] = useState(null);

  const [trailers, setTrailers] = useState([]);
  const [trailer, setTrailer] = useState(null);

  const [priceBooks, setPriceBooks] = useState([]);

  const [salesPersons, setSalesPersons] = useState([]);

  const [setupItems, setSetupItems] = useState([]);
  const [setupItem, setSetupItem] = useState(null);

  const [busy, setBusy] = useState(false);
  const [timeZoneBusy, setTimeZoneBusy] = useState(false);
  const [itemBusy, setItemBusy] = useState(false);
  const [saving, setSaving] = useState(false);

  const [productCosts, setProductCosts] = useState(null);

  const [companyTimeZone, setCompanyTimeZone] = useState(null);
  const [companyLicenses, setCompanyLicenses] = useState([]);

  const [pdfTemplates, setPDFTemplates] = useState([]);

  const getSetupItems = useCallback(
    async (params = {}) => {
      setBusy(true);
      await setup
        .getSetupItems(entityRef, setupItemItemId, params)
        .then(setSetupItems)
        .finally(() => setBusy(false));
    },
    [entityRef, setupItemItemId]
  );

  const getSetupItem = useCallback(
    async item => {
      setBusy(true);

      await setup
        .getSetupItem(entityRef, setupItemItemId, item)
        .then(setSetupItem)
        .finally(() => setBusy(false));
    },
    [entityRef, setupItemItemId]
  );

  const saveSetupItem = useCallback(
    async item => {
      setSaving(true);
      setError(null);
      const itemToSave = item ?? setupItem;

      if (itemToSave.crn) {
        return setup
          .updateSetupItem(entityRef, setupItemItemId, itemToSave)
          .catch(error => {
            setError(error.message);
            console.error(error);
            throw error;
          })
          .finally(() => setSaving(false));
      }
      return setup
        .createSetupItem(entityRef, setupItemItemId, itemToSave)
        .catch(error => {
          setError(error.message);
          console.error(error);
          throw error;
        })
        .finally(() => setSaving(false));
    },
    [entityRef, setupItem, setupItemItemId]
  );

  const getCarriers = useCallback(
    async params => {
      setBusy(true);

      await setup
        .getSetupItems(entityRef, 'carrier', params)
        .then(setCarriers)
        .finally(() => setBusy(false));
    },
    [entityRef]
  );

  const getCarrier = useCallback(
    async carrier => {
      setItemBusy(true);

      await setup
        .getSetupItem(entityRef, 'carrier', carrier)
        .then(setCarrier)
        .finally(() => setItemBusy(false));
    },
    [entityRef]
  );

  const saveCarrier = useCallback(async () => {
    setItemBusy(true);

    if (carrier.crn) {
      return setup
        .updateSetupItem(entityRef, 'carrier', carrier)
        .catch(error => {
          setError(error.message);
          throw error;
        })
        .finally(() => setItemBusy(false));
    }

    return setup
      .createSetupItem(entityRef, 'carrier', carrier)
      .catch(error => {
        setError(error.message);
        throw error;
      })
      .finally(() => setItemBusy(false));
  }, [carrier, entityRef]);

  const getVehicles = useCallback(
    async params => {
      setBusy(true);

      await setup
        .getSetupItems(entityRef, 'vehicle', params)
        .then(setVehicles)
        .finally(() => setBusy(false));
    },
    [entityRef]
  );

  const getVehicle = useCallback(
    async vehicleRef => {
      setItemBusy(true);

      await setup
        .getSetupItem(entityRef, 'vehicle', vehicleRef)
        .then(setVehicle)
        .finally(() => setItemBusy(false));
    },
    [entityRef]
  );

  const saveVehicle = useCallback(async () => {
    const errorMessage = isValid(vehicle, 'vehicle');
    if (errorMessage) {
      setError(errorMessage);
      throw error;
    }

    setItemBusy(true);

    if (vehicle.crn) {
      return setup
        .updateSetupItem(entityRef, 'vehicle', vehicle)
        .catch(error => {
          setError(error.message);
          throw error;
        })
        .finally(() => setItemBusy(false));
    }

    return setup
      .createSetupItem(entityRef, 'vehicle', vehicle)
      .catch(error => {
        setError(error.message);
        throw error;
      })
      .finally(() => setItemBusy(false));
  }, [entityRef, vehicle]);

  const getDrivers = useCallback(
    async params => {
      setBusy(true);

      await setup
        .getSetupItems(entityRef, 'driver', params)
        .then(setDrivers)
        .finally(() => setBusy(false));
    },
    [entityRef]
  );

  const getDriver = useCallback(
    async driverRef => {
      setItemBusy(true);

      await setup
        .getSetupItem(entityRef, 'driver', driverRef)
        .then(setDriver)
        .finally(() => setItemBusy(false));
    },
    [entityRef]
  );

  const saveDriver = useCallback(async () => {
    setItemBusy(true);

    if (driver.crn) {
      return setup.updateSetupItem(entityRef, 'driver', driver).finally(() => setItemBusy(false));
    }

    return setup.createSetupItem(entityRef, 'driver', driver).finally(() => setItemBusy(false));
  }, [entityRef, driver]);

  const updateDriver = useCallback(
    async driver => {
      setItemBusy(true);

      await setup
        .updateSetupItem(entityRef, 'driver', driver)
        .then(setDriver)
        .catch(error => {
          setError(error.message);
          throw error;
        })
        .finally(() => setItemBusy(false));
    },
    [entityRef]
  );

  const createDriver = useCallback(
    async driver => {
      setItemBusy(true);

      await setup
        .createSetupItem(entityRef, 'driver', driver)
        .then(setDriver)
        .catch(error => {
          setError(error.message);
          throw error;
        })
        .finally(() => setItemBusy(false));
    },
    [entityRef]
  );

  const getTrackables = useCallback(
    async params => {
      setBusy(true);

      await setup
        .getSetupItems(entityRef, 'trackable', params)
        .then(res => {
          const retTrackables = res.map(t => {
            if (t?.status) {
              return t;
            }
            t.status = 'ACTIVE';
            return t;
          });
          setTrackables(retTrackables);
        })
        .finally(() => setBusy(false));
    },
    [entityRef]
  );

  const getTrackable = useCallback(
    async trackableRef => {
      setItemBusy(true);

      await setup
        .getSetupItem(entityRef, 'trackable', trackableRef)
        .then(item => {
          if (!item.entityRef) {
            item.entityRef = entityRef;
          }
          return setTrackable(item);
        })
        .finally(() => setItemBusy(false));
    },
    [entityRef]
  );

  const saveTrackable = useCallback(async () => {
    setItemBusy(true);

    const retTrackable = _.cloneDeep(trackable);
    retTrackable.latitude = +retTrackable.latitude;
    retTrackable.longitude = +retTrackable.longitude;

    if (trackable.crn) {
      return setup.updateSetupItem(entityRef, 'trackable', retTrackable).finally(() => setItemBusy(false));
    }

    return setup.createSetupItem(entityRef, 'trackable', retTrackable).finally(() => setItemBusy(false));
  }, [entityRef, trackable]);

  const getTrackableTypes = useCallback(
    async params => {
      setBusy(true);

      await setup
        .getSetupItems(entityRef, 'trackable-type', params)
        .then(setTrackableTypes)
        .finally(() => setBusy(false));
    },
    [entityRef]
  );

  const getTrackableType = useCallback(
    async trackableTypeRef => {
      setItemBusy(true);

      await setup
        .getSetupItem(entityRef, 'trackable-type', trackableTypeRef)
        .then(setTrackableType)
        .finally(() => setItemBusy(false));
    },
    [entityRef]
  );

  const saveTrackableType = useCallback(async () => {
    setItemBusy(true);

    if (trackableType.crn) {
      return setup
        .updateSetupItem(entityRef, 'trackable-type', trackableType)
        .catch(error => {
          setError(error.message);
          throw error;
        })
        .finally(() => setItemBusy(false));
    }
    return setup
      .createSetupItem(entityRef, 'trackable-type', trackableType)
      .catch(error => {
        setError(error.message);
        throw error;
      })
      .finally(() => setItemBusy(false));
  }, [entityRef, trackableType]);

  const getLocations = useCallback(
    async (supplierRef, params) => {
      setBusy(true);
      !supplierRef && (supplierRef = entityRef);

      await api
        .getLocations(supplierRef, params)
        .then(setLocations)
        .finally(() => setBusy(false));
    },
    [entityRef]
  );

  const getPriceBooks = useCallback(
    async (supplierRef, activeOnly = false) => {
      !supplierRef && (supplierRef = entityRef);

      let pageToken = null;
      let items = [];
      do {
        const result = await api.getPriceBooks(supplierRef, pageToken, { activeOnly });

        items.push(...result.items);
        pageToken = result.pageToken;
      } while (pageToken);

      setPriceBooks(items);
    },
    [entityRef]
  );

  const getSalesPersons = useCallback(
    async supplierRef => {
      setBusy(true);
      !supplierRef && (supplierRef = entityRef);

      await api
        .getSalesPersons(supplierRef)
        .then(rs => {
          setSalesPersons([{ crn: 'NONE', name: 'None Selected' }].concat(...rs));
        })
        .catch(e => {
          console.error(e.message);
          setSalesPersons([{ crn: 'NONE', name: 'None Selected' }]);
        })
        .finally(() => setBusy(false));
    },
    [entityRef]
  );

  const updateLocation = useCallback(async (entityRef, locationRef, location) => {
    setBusy(true);

    await api
      .updateLocation(entityRef, locationRef, location)
      .then(setLocation)
      .catch(error => {
        setError(error.message);
        throw error;
      })
      .finally(() => setBusy(false));
  }, []);

  const createLocation = useCallback((entityRef, location) => {
    setBusy(true);

    return api
      .createLocation(entityRef, location)
      .then(response => {
        setLocation(response);
        return response;
      })
      .catch(error => {
        setError(error.message);
        throw error;
      })
      .finally(() => setBusy(false));
  }, []);

  const getLocation = useCallback(async (entityRef, locationRef) => {
    setBusy(true);

    await api
      .getLocation(entityRef, locationRef)
      .then(setLocation)
      .catch(console.error)
      .finally(() => setBusy(false));
  }, []);

  const getCustomers = useCallback(
    async (ref, params) => {
      setBusy(true);

      await api
        .getCustomers(ref || entityRef, params)
        .then(setCustomers)
        .finally(() => setBusy(false));
    },
    [entityRef]
  );

  const getCustomer = useCallback(async (entityRef, customerRef) => {
    setBusy(true);

    await api
      .getCustomer(entityRef, customerRef)
      .then(customer => {
        setCustomer({ ...customer, taxStatus: customer.taxStatus ?? 'TAXABLE' });
      })
      .finally(() => setBusy(false));
  }, []);

  const updateCustomer = useCallback(async (entityRef, customerRef, customer) => {
    setBusy(true);

    await api
      .updateCustomer(entityRef, customerRef, customer)
      .then(setCustomer)
      .catch(console.error)
      .finally(() => setBusy(false));
  }, []);

  const createCustomer = useCallback(async (entityRef, customer) => {
    setBusy(true);

    return api
      .createCustomer(entityRef, customer)
      .then(response => {
        setCustomer(response);
        return response;
      })
      .catch(console.error)
      .finally(() => setBusy(false));
  }, []);

  const getCustomerProjects = useCallback(async (entityRef, customerRef) => {
    setBusy(true);

    await api
      .getCustomerProjects(entityRef, customerRef)
      .then(setProjects)
      .finally(() => setBusy(false));
  }, []);

  const getProjects = useCallback(async (entityRef, params) => {
    setBusy(true);
    let projects = [];
    let pageToken = null;

    do {
      const result = await api.searchProjects(entityRef, { ...params, pageToken });
      pageToken = result.pageToken;
      projects.push(...result.items);
    } while (pageToken);

    setProjects(projects);
    setBusy(false);
  }, []);

  const getProject = useCallback(async (entityRef, projectRef) => {
    setBusy(true);

    await api
      .getProject(entityRef, projectRef)
      .then(project => {
        setProject({ ...project, taxStatus: project.taxStatus ?? 'TAXABLE' });
      })
      .finally(() => setBusy(false));
  }, []);

  const updateProject = useCallback(async (entityRef, projectRef, project) => {
    setBusy(true);
    setError(null);

    await api
      .updateProject(entityRef, projectRef, { ...project, attachments: undefined })
      .then(setProject)
      .catch(error => {
        setError(error.message);
        throw error;
      })
      .finally(() => setBusy(false));
  }, []);

  const createProject = useCallback(async (entityRef, project) => {
    setBusy(true);
    setError(null);

    return api
      .createProject(entityRef, project)
      .then(response => {
        setProject(response);
        return response;
      })
      .catch(error => {
        setError(error.message);
        throw error;
      })
      .finally(() => setBusy(false));
  }, []);

  const getProducts = useCallback(
    async params => {
      setBusy(true);
      await api
        .getProducts(entityRef, params)
        .then(setProducts)
        .finally(() => setBusy(false));
    },
    [entityRef]
  );

  const searchProducts = useCallback(
    async params => {
      setBusy(true);
      await api
        .searchProducts(entityRef, params)
        .then(setProducts)
        .finally(() => setBusy(false));
    },
    [entityRef]
  );

  const getProduct = useCallback(async (entityRef, productRef) => {
    setBusy(true);

    await api
      .getProduct(entityRef, productRef)
      .then(setProduct)
      .finally(() => setBusy(false));
  }, []);

  const getProductCosts = useCallback(
    async productRef => {
      setBusy(true);

      try {
        const responses = await api.getProductCosts(entityRef, productRef);
        setProductCosts(responses);
      } catch (e) {
        console.log(e);
      } finally {
        setBusy(false);
      }
    },
    [entityRef]
  );

  const updateProduct = useCallback(async (entityRef, productRef, product) => {
    setBusy(true);
    setError(null);

    await api
      .updateProduct(entityRef, productRef, product)
      .then(setProduct)
      .catch(error => {
        setError(error.message);
        console.error(error);
        throw error;
      })
      .finally(() => setBusy(false));
  }, []);

  const createProduct = useCallback(async (entityRef, product) => {
    setBusy(true);
    setError(null);

    return await api
      .createProduct(entityRef, product)
      .then(response => {
        setProduct(response);
        return response;
      })
      .catch(error => {
        setError(error.message);
        console.error(error);
        throw error;
      })
      .finally(() => setBusy(false));
  }, []);

  const downloadFile = useCallback(
    (typeId, itemRef, name) => {
      setItemBusy(true);

      return setup
        .getDownloadLink(entityRef, typeId, itemRef)
        .then(({ link }) => {
          return fetch(link, {
            method: 'GET',
          });
        })
        .then(response => response.blob())
        .then(blob => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${typeId}-${name}.xlsx`);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        })
        .finally(() => {
          setItemBusy(false);
        });
    },
    [entityRef]
  );

  const uploadFile = useCallback(
    async (typeId, itemRef, file) => {
      setItemBusy(true);
      try {
        if (!hostname().includes('localhost')) {
          const { link } = await setup.getUploadLink(entityRef, typeId, itemRef);
          const response = await fetch(link, {
            method: 'PUT',
            body: file,
            headers: {
              'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            },
            mode: 'cors',
            cache: 'no-cache',
            processData: false,
          });

          return response;
        }

        const base64 = await toBase64(file);
        const res = await setup.uploadFileDevelop(entityRef, typeId, itemRef, { base64 });
        console.log(res);
      } catch (error) {
        console.log(error);
      } finally {
        setItemBusy(false);
      }
    },
    [entityRef]
  );

  const getTrailers = useCallback(
    async params => {
      setBusy(true);

      await setup
        .getSetupItems(entityRef, 'trailer', params)
        .then(setTrailers)
        .finally(() => setBusy(false));
    },
    [entityRef]
  );

  const getTrailer = useCallback(async (entityRef, trailerRef) => {
    setItemBusy(true);
    await setup
      .getSetupItem(entityRef, 'trailer', trailerRef)
      .then(setTrailer)
      .finally(() => setItemBusy(false));
  }, []);

  const updateTrailer = useCallback(
    async trailer => {
      setBusy(true);

      await setup
        .updateSetupItem(entityRef, 'trailer', trailer)
        .then(setTrailer)
        .catch(error => {
          setError(error.message);
          console.error(error);
          throw error;
        })
        .finally(() => setBusy(false));
    },
    [entityRef]
  );

  const createTrailer = useCallback(
    async trailer => {
      setBusy(true);

      await setup
        .createSetupItem(entityRef, 'trailer', trailer)
        .then(setTrailer)
        .catch(error => {
          setError(error.message);
          console.error(error);
          throw error;
        })
        .finally(() => setBusy(false));
    },
    [entityRef]
  );

  const [dexas, setDexas] = useState([]);

  const getDexas = useCallback(async () => {
    await api
      .getDexas(entityRef)
      .then(setDexas)
      .catch(console.error)
      .finally(() => setBusy(false));
  }, [entityRef]);

  const saveCancelReason = useCallback(async () => {
    const errorMessage = isValid(setupItem, 'cancelReason');
    if (errorMessage) {
      setError(errorMessage);
      throw error;
    }
    setItemBusy(true);

    if (setupItem.crn) {
      return setup
        .updateSetupItem(entityRef, 'cancel-reason-code', setupItem)
        .catch(error => {
          setError(error.message);
          console.error(error);
          throw error;
        })
        .finally(() => setSaving(false));
    }
    return setup
      .createSetupItem(entityRef, 'cancel-reason-code', setupItem)
      .catch(error => {
        setError(error.message);
        console.error(error);
        throw error;
      })
      .finally(() => setSaving(false));
  }, [entityRef, setupItem, error]);

  const getCompanyTimeZone = useCallback(async entityRef => {
    setBusy(true);
    setTimeZoneBusy(true);
    await api
      .getCompanyTimeZone(entityRef)
      .then(result => {
        setCompanyTimeZone(result);
      })
      .finally(() => {
        setBusy(false);
        setTimeZoneBusy(false);
      });
  }, []);

  const getCompanyLicenses = useCallback(async entityRef => {
    setBusy(true);
    await api
      .getCompanyLicenses(entityRef)
      .then(result => {
        setCompanyLicenses(result);
      })
      .finally(() => setBusy(false));
  }, []);

  const getPDFTemplates = useCallback(async () => {
    setBusy(true);
    await api
      .getCompanyPDFTemplates(entityRef)
      .then(result => {
        setPDFTemplates(result);
      })
      .finally(() => setBusy(false));
  }, [entityRef]);

  return {
    projects,
    project,
    getProjects,
    getProject,
    getCustomerProjects,
    setProject,
    updateProject,
    createProject,

    customers,
    customer,
    getCustomers,
    getCustomer,
    setCustomer,
    updateCustomer,
    createCustomer,

    locations,
    location,
    getLocations,
    getLocation,
    setLocation,
    updateLocation,
    createLocation,

    products,
    product,
    productCosts,
    getProducts,
    searchProducts,
    getProduct,
    getProductCosts,
    updateProduct,
    setProduct,
    createProduct,

    getVehicles,
    vehicles,
    getVehicle,
    vehicle,
    setVehicle,
    saveVehicle,

    getTrackables,
    trackables,
    getTrackable,
    trackable,
    setTrackable,
    saveTrackable,

    getTrackableTypes,
    trackableTypes,
    getTrackableType,
    trackableType,
    setTrackableType,
    saveTrackableType,

    getCarriers,
    carriers,
    getCarrier,
    carrier,
    setCarrier,
    saveCarrier,

    getTrailers,
    trailers,
    getTrailer,
    trailer,
    setTrailer,
    createTrailer,
    updateTrailer,

    getDrivers,
    drivers,
    getDriver,
    driver,
    setDriver,
    saveDriver,
    createDriver,
    updateDriver,

    getDexas,
    dexas,

    getSetupItems,
    setupItems,
    getSetupItem,
    setupItem,
    setSetupItem,
    saveSetupItem,

    getPriceBooks,
    priceBooks,

    getSalesPersons,
    salesPersons,

    downloadFile,
    uploadFile,
    saveCancelReason,

    companyTimeZone,
    getCompanyTimeZone,

    companyLicenses,
    getCompanyLicenses,

    pdfTemplates,
    getPDFTemplates,

    busy,
    itemBusy,
    saving,
    error,
    setError,
    timeZoneBusy,
  };
};

export default useSetup;
