import cn from 'classnames';
import React, { useCallback } from 'react';
import styled from 'styled-components';
import FieldContainer from '../../field-container/FieldContainer';
import DateTimePicker from '../../form/DateTimePicker';

const StartDateTime = ({ className, disabled, value, onChange, required }) => {
  const handleChange = useCallback(
    val => {
      onChange('startDateTime', val);
    },
    [onChange]
  );

  return (
    <FieldContainer className={cn('note', className)}>
      <div className="label-spinner">
        <div className="label">
          Start Date/Time
          {required && <span className="required-indicator">*</span>}
        </div>
        <div className={cn('spinner', { spinning: false })}>
          <span className="fas fa-sync fa-spin" />
        </div>
      </div>
      <DateTimePicker value={value} onChange={handleChange} disabled={disabled} />
    </FieldContainer>
  );
};

export default styled(StartDateTime)``;
