import uom from '../en-US/uom';

export default {
  ...uom,

  numberOfOrders: '{orderCount, plural,=0 {0 Pedidos} one {# Pedido} other {# Pedidos}}',
  filterByStatus: 'Filtrar por estado',
  CY: 'yd³',
  today: 'Hoy',
  yesterday: 'Ayer',
  thisMonth: 'Este Mes',
  tomorrow: 'Mañana',
  future: 'Futuro',
  custom: 'Personalizado',
  cancelled: 'Cancelado',
  success: 'Programado',
  delivered: 'Entregado',
  warning: 'Tarde',
  danger: '1+ Hr de Retraso',
  total: 'Total',
  advancedSearch: 'Búsqueda Avanzada',
  enterReason: 'Introduce un motivo...',

  date: 'Fecha',
  change: 'Cambio',
  userName: 'Nombre de Usuario',
  fields: 'Campos',
  oldValue: 'Valor Antiguo',
  newValue: 'Valor Nuevo',
  reason: 'Motivo',
  changesToOrder: 'Adiciones y cambios al Pedido',
  audit: 'Auditoría',
  defaultOverridePrompt: 'Proporcione un motivo para anular los siguientes valores predeterminados:',
  auditActions: {
    createdFromOrder: 'Creado desde pedido',
    schedule: 'Programación',
    supplierStatus: 'Estado del Pedido',
    paymentMethodRef: 'Método de Pago',
    salesPerson: 'Vendedor',
    shippingMethodRef: 'Método de Envío',
    workTypeRef: 'Tipo de Obra',
    purchaseOrder: 'Órden de Compra',
    taxRate: 'Tasa de Impuesto',
    vehicleType: 'Tipo de Vehículo',
    startDateTime: 'Fecha/Hora de Inicio',
    phoneNumber: 'Número de Teléfono',
    address: 'Dirección de Destino',
    added_product: 'Producto Agregado',
    deleted_product: 'Producto Eliminado',
    modified_product: 'Producto Modificado',
    products: 'Productos',
    'product-quantity': 'Cantidad de Producto',
    'product-hold-quantity': 'Cantidad en Espera',
    location: 'Ubicación Programada',
    tracking: 'Color de Seguimiento',
    ticket_supplier_status: 'Estado del Proveedor de Ticket',
    re_activated_ticket: 'Ticket Reactivado',
    driverInstructions: 'Instrucciones al Conductor',
    costBookRef: 'Libro de Precios Anulado',
    resolvedCostBookRef: 'Libro de Precios Resuelto',
    costBookOverride: 'Anulación de Libro de Precios',
    manualOverride: 'Anulación Manual de Precio',
    ticketed_quantity: 'Cantidad de Ticket #%',
    ticketCreated: 'Ticket Creado',
  },
  auditTypes: {
    A: 'Creado',
    M: 'Modificado',
  },

  trucksGraph: 'Gráfico de camiones',
  noGrouping: 'Sin agrupar',
  customer: 'Cliente',
  project: 'Proyecto',
  plant: 'Planta',

  backToOrders: 'Volver a Pedidos',
  details: 'Detalles',
  copyOrder: 'Copiar Pedido',
  showAll: 'Mostrar Todos',
  load: 'Carga',
  arriveJob: 'Llegar a la Obra',
  vehicle: 'Vehículo',
  quantity: 'Cantidad',
  status: 'Estado',
  scheduled: 'Programado',

  CREATED: 'Ticket Creado',
  PRINTED: 'Impreso',
  LOADING: 'Cargando',
  LOADING_STARTED: 'Empezó a cargar',
  LOADING_COMPLETE: 'Carga completada',
  TO_JOB: 'En tránsito',
  ARRIVE_JOB: 'En Obra',
  UNLOADING: 'Empezó a Descargar',
  END_UNLOAD: 'Terminó de Descargar',
  END_UNLOADING: 'Descargado',
  WASHING: 'Lavando',
  LEAVE_JOB: 'Volver',
  ARRIVE_PLANT: 'En planta',
  IN_YARD: 'En patio',
  DEADHEAD: 'Sin carga',

  orderNumber: 'Número de Pedido',
  startDateTime: 'Fecha/Hora de Inicio',
  productType: 'Tipo de Producto',
  spacing: 'Intervalo',
  quantityPerHour: 'Cantidad/Hora',
  description: 'Descripción',
  extraItems: 'Elementos Adicionales',

  loads: 'Cargas',
  map: 'Mapa',

  refresh: 'Actualizar',
  orders: 'Pedidos',
  createConcreteOrder: 'Crear Pedido de Concreto',
  createAggregateOrder: 'Crear Pedido de Agregado',
  copyConcreteOrder: 'Copiar Pedido de Concreto',
  copyAggregateOrder: 'Copiar Pedido de Agregado',
  reviewOrderRequest: 'Revisar Solicitud de Pedido',

  PENDING: 'PENDIENTE',
  'ORDER REQUEST (PENDING)': 'SOLICITUD DE PEDIDO (PENDIENTE)',
  'ORDER REQUEST (REJECTED)': 'SOLICITUD DE PEDIDO (RECHAZADA)',
  CONFIRMED: 'CONFIRMADA',
  REJECTED: 'RECHAZADA',
  VOIDED: 'ANULADA',
  CANCELLED: 'CANCELADA',
  HOLD: 'ESPERA',
  'ON HOLD': 'EN ESPERA',
  'CREDIT HOLD': 'RETENIDO POR CRÉDITO',

  statusCounts: {
    'ORDER REQUEST (PENDING)': 'SOLICITUD DE PEDIDO (PENDIENTE): {count}',
    'ORDER REQUEST (REJECTED)': 'SOLICITUD DE ÓRDEN (RECHAZADA): {count}',
    CANCELLED: 'CANCELADO: {count}',
    CONFIRMED: 'CONFIRMADO: {count}',
    'CREDIT HOLD': 'RETENIDO POR CRÉDITO: {count}',
    COMPLETED: 'COMPLETADO: {count}',
    HOLD: 'ESPERA: {count}',
    'NO TICKETING': 'SIN EMISIÓN DE TICKETS: {count}',
    NORMAL: 'NORMAL: {count}',
    PENDING: 'PENDIENTE: {count}',
    PROCESSING: 'PROCESANDO: {count}',
    'WEATHER PERMIT': 'DEPENDIENTE DEL CLIMA: {count}',
    'WILL CALL': 'POR CONFIRMAR: {count}',
  },

  originPlant: 'Planta de Origen',
  carrier: 'Transportadora',
  driver: 'Conductor',
  destinationAddress: 'Dirección de Destino',
  supplier: 'Proveedor',
  invoiceCode: 'Código de Factura',
  statusChangeTime: 'Hora de Cambio de Estado',
  customerComments: 'Comentarios del Cliente',
  contractNumber: 'Número de Contrato',

  products: 'Productos',
  waterAdded: 'Agua Agregada: {waterAdded}',
  ticketEvents: 'Eventos del Ticket',
  notAssessed: 'No Evaluado',
  ticketNumber: 'Ticket #{value}',
  batchWeights: 'Pesos de Cargue',
  backToOrder: 'Volver al Pedido',
  accumulatedLoad: 'Carga',

  maxWaterAdd: 'Máx. Agua/Agregada',
  actualWC: 'A/C Actual',
  maxWC: 'A/C Máxima',
  material: 'Material',
  batched: 'Pesadp',
  variance: 'Variación',
  moisturePercentage: '% de Humedad',

  voidTicket: 'Anular Ticket',
  cancelTicket: 'Cancelar Ticket',
  doNotVoidTicket: 'No Anular Ticket',
  doNotCancelTicket: 'No Cancelar Ticket',
  note: 'Nota',
  billingNote: 'Nota de Facturación',
  cancel: 'Cancelar',
  save: 'Guardar',
  delete: 'Eliminar',
  confirm: 'Confirmar',

  mixInfo: 'Info. de Mezcla',
  targetSlump: 'Asentamiento Objetivo',
  minSlump: 'Asentamiento Mínimo',
  maxSlump: 'Asentamiento Máximo',
  maxBatchSlump: 'Asentamiento Máx. de Lote',
  slumpLossRate: 'Tasa de Pérdida de Asentamiento',
  slumpType: 'Tipo de Asentamiento',
  overMixingTime: 'Tiempo de Sobre Mezcla',
  preBatchedWater: 'Agua Precarga',
  minTemp: 'Temp. Mínima',
  maxTemp: 'Temp. Máxima',
  targetAirContent: 'Contenido de Aire Objetivo',
  minAirContent: 'Contenido de Aire Mínimo',
  maxAirContent: 'Contenido de Aire Máximo',

  slumpType_00: 'Asentamiento',
  slumpType_01: 'Flujo',
  slumpType_02: 'Esparcimiento',

  editOrder: 'Editar Pedido',

  general: 'General',
  schedule: 'Programación',
  notes: 'Notas {noteCount}',
  pricing: 'Precios',
  attachments: 'Adjuntos',
  attachmentCount: `Archivos Adjuntos {attachmentCount, plural,
    =0 {}
    other {(#)}
  }`,
  scheduledLocation: 'Ubicación Programada',
  shippingMethod: 'Método de Envío',
  addressName: 'Dirección',
  address1: 'Dirección 1',
  address2: 'Dirección 2',
  country: 'País',
  startDateTimeWithTimeZone: 'Fecha/Hora de Inicio{timeZone}',
  dispatchColor: 'Color de Despacho',
  city: 'Ciudad',
  vehicleType: 'Tipo de Vehículo',
  salesTaxBasis: 'Base de Impuestos de Venta',
  taxExemptReason: 'Motivo de Exención de Impuestos',
  dispatchLocation: 'Ubicación de Despacho',
  locationTicketTemplate: 'Plantilla de Ticket para Ubicación',
  paymentMethod: 'Método de Pago',
  state: 'Estado',
  postalCode: 'Código Postal',
  driverInstructions: 'Instrucciones al Conductor',
  workType: 'Tipo de Obra',
  phoneNumber: 'Número de Teléfono',
  contact: 'Contacto',
  salesPerson: 'Vendedor',
  purchaseOrder: 'Órden de Compra',
  product: 'Producto',
  slump: 'Asentamiento',
  nextHoldQuantity: 'Cantidad de Espera Sig.',
  cleanup: 'Limpieza',
  loadNumbers: 'Números de Carga',
  extendedPrice: 'Precio Extendido',
  salesTax: 'Impuesto de Venta',
  fees: 'Tarifas',
  totalPrice: 'Precio Total',
  totals: 'Totales',
  addPrimaryProduct: 'Agregar Producto Primario',
  done: 'Hecho',
  removeOrderLine: 'Quitar Fila de Pedido',

  projectHardCreditHold:
    'RETENIDO POR CRÉDITO: Este proyecto está RETENIDO POR CRÉDITO. No se permiten nuevos pedidos.',
  customerHardCreditHold:
    'RETENIDO POR CRÉDITO: Este cliente está RETENIDO POR CRÉDITO. No se permiten nuevos pedidos.',
  projectSoftCreditHold:
    'Advertencia de Crédito: Este proyecto está RETENIDO POR CRÉDITO, pero aún se permiten nuevos pedidos.',
  customerSoftCreditHold:
    'Advertencia de Crédito: Este cliente está RETENIDO POR CRÉDITO, pero aún se permiten nuevos pedidos.',

  slumpWarning: 'Asentamiento fuera del rango: mín {min, number} y máx {max, number}',
  acceptOrderRequest: 'Aceptar Solicitud de Pedido',
  rejectOrderRequest: 'Rechazar Solicitud de Pedido',

  reset: 'Restablecer',
  createSchedule: 'Crear Programación',
  updateSchedule: 'Actualizar Programación',
  orderLineNumber: 'Línea de Pedido #{num, number}',

  startTime: 'Hora de Inicio',

  rogueVehicle:
    'La ubicación actual del vehículo no coincide con la ubicación seleccionada. ¿Está seguro de que desea emitir el ticket de este vehículo?',

  maxOverloaded: 'El vehículo se está sobrecargando, ya que su tamaño de carga máxima es {capacity}',
  defaultOverloaded: 'El vehículo se está sobrecargando, ya que su tamaño de carga predeterminado es {capacity}',
  primaryProductNotAvailable: '¡El Producto Primario no está disponible en esta planta!',
  vehicleInAnotherLoadWarning: 'El vehículo está actualmente en otra carga. Continuar completará la entrega actual.',
  primaryProductMinimumLoadChargeOverride:
    'Cambiar la cantidad del producto Primario puede agregar o eliminar un cargo por carga mínima',
  ticketingOrder: 'Emisión del Ticket',
  address: 'Dirección',
  shippingLocation: 'Ubicación de Envío',
  assignedCarrier: 'Transportador Asignado',
  assignedVehicle: 'Vehículo Asignado',
  assignedDriver: 'Conductor Asignado',

  dexaNotFound:
    'No se pudo encontrar la configuración DEXA especificada. El ticket no se enviará a la automatización de planta.',
  dexaOffline: 'La conexión DEXA no está en línea. El ticket no se enviará a la automatización de planta.',
  connectionNotFound:
    'No se pudo encontrar la conexión DEXA especificada. El ticket no se enviará a la automatización de planta.',

  productIdMismatch:
    'El Código del producto del ticket anterior no coincide con el Código del producto pedido. {ticketProductId} vs. {orderProductId}',

  yes: 'Sí',
  no: 'No',

  seRemainingQuantity: '¿Usar la cantidad restante del pedido anterior?',
  aboveShippedAmount: 'Se están enviando {aboveShippedAmount} más de lo ordenado.',
  noProductQuantity: 'La cantidad de producto primario es requerida',
  noSlumpQuantity: 'La consistencia de producto primario es requerida',

  addANote: 'Agregar una Nota',
  noNotesOnOrder: 'No se encontraron notas en este pedido.',
  update: 'Actualizar',

  taxRate__address: 'Dirección de Entrega',
  taxRate__customer: 'Dirección del Cliente',
  taxRate__project: 'Dirección del Proyecto',
  taxRate__location: 'Dirección de la Planta',
  taxRate__exempt: 'Exento de impuestos',
  taxRate__dispatchLocation: 'Dirección de Envío',

  taxBasedOn: 'Impuesto de Venta Basado en',
  taxJurisdiction: 'Jurisdicción de Impuestos',
  resolvedPriceBook: 'Lista de Precios Resuelta',
  overridePriceBook: 'Sobrescribir Lista de Precios',
  overridePricing: 'Sobrescribir Precios',
  resetPriceBook: 'Restablecer Lista de Precios',
  repriceAndSave: 'Sobre escribir precios y Guardar Usando Jurisdicción y Lista de Precios Seleccionadas',
  idDescription: 'Código de / Descripción',
  unitPrice: 'Precio Unitario',
  priceSource: 'Fuente de Precio',
  orderedQuantity: 'Cantidad Pedida',
  extPrice: 'Precio Extendido',

  stateTax: 'Impuesto Estatal',
  stateTaxWithPercentage: 'Impuesto Estatal ({rate, number, ::percent .00})',

  countyTax: 'Impuesto del Condado',
  countyTaxWithPercentage: 'Impuesto del Condado ({rate, number, ::percent .00})',

  cityTax: 'Impuesto Municipal',
  cityTaxWithPercentage: 'Impuesto Municipal ({rate, number, ::percent .00})',

  districtTax: 'Impuesto Distrital',
  districtTaxWithPercentage: 'Impuesto Distrital ({rate, number, ::percent .00})',

  salesTaxWithPercentage: 'Impuesto de Venta ({rate, number, ::percent .00})',

  loading: 'Cargando...',
  none: 'NINGUNO',

  numberOfLoads: '({loads, number} cargas)',

  priceBookEditorDrawerTitle: 'Editor de Lista de Precios Personalizada',

  type: 'Tipo',
  price: 'Precio',
  uom: 'UDM',

  truckSpacing: 'Intervalo de Camión',
  roundTrip: 'Viaje de Ida y Vuelta',

  schedulingStrategy: 'Estrategia de Programación',
  trucksNeeded: 'Camiones Necesarios',
  order1: 'Pedido',

  locationTimesMinutes: 'Tiempos de {name} (minutos)',
  destinationTimesMinutes: 'Tiempos de Destino (minutos)',

  loadSize: 'Tamaño de Carga',
  spacingMin: 'Intervalo (min)',
  travel: 'Viaje',

  waitToLoad: 'Esperar para Cargar',
  waitToLeave: 'Esperar para Salir',
  unload: 'Descargar',
  wash: 'Lavar',

  deliveryProgress: 'Progreso de la Entrega ({timeZone})',

  batch: 'Automatización',
  deliver: 'Entregar',
  return: 'Devolver',
  more: 'Más',

  tableHeading_loadNumber: 'Carga #',
  tableHeading_location: 'Ubicación',
  tableHeading_quantity: 'Cantidad',
  tableHeading_total: 'Total',
  tableHeading_slump: 'Asentamiento',
  tableHeading_ticket: 'Ticket',
  tableHeading_vehicle: 'Vehículo',
  tableHeading_created: 'Creado',
  tableHeading_print: 'Imprimir',
  tableHeading_printed: 'Impreso',
  tableHeading_load: 'Carga',
  tableHeading_qc: 'Control de Calidad',
  tableHeading_loading: 'Cargando',
  tableHeading_loaded: 'Cargado',
  tableHeading_toJob: 'En Viaje',
  tableHeading_arriveJob: 'Llegar la Obra',
  tableHeading_beginPour: 'Comenzar Verter',
  tableHeading_endPour: 'Finalizar Verter',
  tableHeading_leaveJob: 'Dejar Obra',
  tableHeading_inPlant: 'En Planta',
  tableHeading_duration: 'Duración',
  tableHeading_return: 'Regreso',
  tableHeading_arrivePlant: 'Llegar a Planta',
  tableHeading_trip: 'Viaje',

  scheduledLoads: 'Cargas Programadas ({timeZone})',
  modeling: 'Modelado',

  estimatedTravelTimeIs: 'El tiempo de viaje estimado es {duration}',

  createTicket: 'Crear Ticket',
  clickToUpload: 'Haga clic para Cargar',
  uploaded: 'Cargado',
  upload: 'Cargar',

  attachmentMetaType_order: 'Pedido',
  attachmentMetaType_project: 'Proyecto',

  confirmDeleteAttachment: '¿Está seguro de que desea eliminar el archivo adjunto?',

  submit: 'Enviar',

  title_orderModeling: 'Modelado de Pedido - {orderId} - {locationName}',
  trucksAvailableOverridden: '{numberOfTrucks, number} Camiones Disponibles (Anulados)',
  trucksAvailable: '{numberOfTrucks, number} Camiones Disponibles',

  resetWithValue: 'Reiniciar ({valor})',
  ok: 'Aceptar',
  override: 'Sobrescribir',
  orderList: 'Pedidos: {orderList}',

  numberOfTrucks: '{numberOfTrucks} Camiones',

  location: 'Ubicación',
  qty: 'Cantidad',

  original: 'Original',
  adjusted: 'Ajustado',

  dispatch: 'Despacho',
  windows: 'Ventanas',
  trackingConfiguration: 'Configuración de Seguimiento',
  resetTrackingLayout: 'Restablecer Ventanas de Seguimiento',
  confirmTrackingLayoutReset: '¿Estás seguro de que quieres restablecer tus ventanas de seguimiento?',

  listOrders: 'Listar Pedidos',
  createOrder: 'Crear Pedido',

  tracking: 'Seguimiento',
  trackingWindow: 'Ventana de Seguimiento',
  listAllVehicles: 'Listar Todos los Vehículos',
  ordersToLoad: 'Pedidos para Cargar',
  vehiclesInYard: 'Vehículos en el Patio',
  deadheadVehicles: 'Vehículos en Viaje Vacío',
  returningVehicles: 'Vehículos en Regreso',
  vehiclesOutOfService: 'Vehículos Fuera de Servicio',
  alerts: 'Alertas',
  orderRequests: 'Solicitud de Pedidos',

  orderDetails: 'Detalles del Pedido',
  ticketOrder: 'Detalles del Ticket',
  vehicleDetails: 'Detalles del Vehículo',
  ticketDetails: 'Detalles del Ticket',
  dismissAlert: 'Descartar Alerta',

  tracking_location_id: 'Código de Ubicación',
  tracking_location_name: 'Nombre de Ubicación',
  tracking_location_integratedSystem: 'Sistema Integrado',
  tracking_location_ticketCount: 'Cargas Con Ticket',
  tracking_location_loadCount: 'Cantidad de Cargas',
  tracking_location_shippedQuantity: 'Cantidad Enviada',
  tracking_location_orderedQuantity: 'Cantidad Pedida',

  tracking_order_orderId: 'Código del Pedido',
  tracking_order_customerParty: 'Cliente',
  tracking_order_address: 'Dirección',
  tracking_order_product: 'Producto Primario',
  tracking_order_supplierStatus: 'Estado',
  'tracking_order_deliverySchedule.startDateTime': 'Hora de Inicio Programada',
  tracking_order_vehicleSpacing: 'Intervalo de Vehículos',
  tracking_order_ticketCount: 'Cargas con Ticket',
  tracking_order_loadCount: 'Cantidad de Cargas',
  tracking_order_shippedQuantity: 'Cantidad Enviada',
  tracking_order_orderedQuantity: 'Cantidad Pedida',

  tracking_window_name_trackingWindow: 'Ventana de Seguimiento',
  tracking_window_name_listAllVehicles: 'Listar Todos los Vehículos',
  tracking_window_name_ordersToLoad: 'Pedidos para Cargar',
  tracking_window_name_map: 'Mapa',
  tracking_window_name_vehiclesInYard: 'Vehículos en el Patio',
  tracking_window_name_deadheadVehicles: 'Vehículos en Viaje Vacío',
  tracking_window_name_returningVehicles: 'Vehículos en Regreso',
  tracking_window_name_vehiclesOutOfService: 'Vehículos Fuera de Servicio',
  tracking_window_name_alerts: 'Alertas',
  tracking_window_name_orderRequests: 'Solicitud de Pedidos',

  vehicleId: 'Código de Vehículo',
  orderDate: 'Fecha del Pedido',
  orderId: 'Código del Pedido',
  ticketNumberLabel: 'Número de Ticket',
  fromLocation: 'Desde Ubicación',
  toLocation: 'Hacia Ubicación',
  homeLocation: 'Ubicación de Origen',
  returnedWater: 'Agua Devuelta',
  returnedConcrete: 'Concreto Devuelto',

  timer: 'Temporizador',

  vehicleCountInYard: '({vehicleCount}) en el Patio',
  vehicleCountDeadheadVehicles: '({vehicleCount}) Vehículos en Viaje Vacío',
  vehicleCountReturningVehicles: '({vehicleCount}) Vehículos en Regreso',
  alertCountAlerts: '({alertCount}) Alertas',

  vehicles: 'Vehículos',
  locations: 'Ubicaciones',
  orderLeadTime: 'Tiempo de Entrega del Pedido',
  mappedVehicleStatusColors: 'Colores Mapeados del Estado del Vehículo',

  createTime: 'Hora de Creación',
  customerIdName: 'Código de/Nombre del Cliente',
  projectIdName: 'Código de/Nombre del Proyecto',
  deliveryStreetAddress: 'Dirección de Entrega',
  scheduledStartTime: 'Hora de Inicio Programada',
  primaryProductID: 'Código del Producto Primario',
  primaryProductQty: 'Cantidad del Producto Primario',

  selectDate: 'Seleccionar Fecha',
  selectLocation: 'Seleccionar Ubicación',
  graphType: 'Tipo de Gráfico',
  overall: 'Total',
  loadingButtonLabel: 'Cargando',
  all: 'Todos',

  invoices: {
    option_unbilled: 'Entregas no Facturadas',
    option_invoiced: 'Entregas Facturadas',
    'option_invoice-export': 'Historial de Exportación de Facturas',
    heading_unbilled: 'Registro de Entregas no Facturadas',
    heading_invoiced: 'Registro de Entregas Facturadas',
    'heading_invoice-export': 'Historial de Exportación de Facturas',

    today: 'Hoy',
    yesterday: 'Ayer',
    thisMonth: 'Este Mes',
    all: 'Todos',
    processAndDataFilters: 'Filtros de Proceso y Datos',

    default_isDelivered: 'Entregado',
    primary_isDelivered: 'Esta Entregado',
    danger_isDelivered: 'No Entregado',

    default_isBillable: 'Facturable',
    primary_isBillable: 'Es Facturable',
    danger_isBillable: 'No Facturable',

    default_isPriced: 'Precio',
    primary_isPriced: 'Precio Establecido',
    danger_isPriced: 'Precio No Establecido',

    default_isTaxed: 'Gravado',
    primary_isTaxed: 'Gravado',
    danger_isTaxed: 'No Gravado',

    default_hasNonTaxable: 'No Gravable',
    primary_hasNonTaxable: 'Es No Gravable',
    danger_hasNonTaxable: 'No tiene Gravables',

    default_hasVehicle: 'Vehículo',
    primary_hasVehicle: 'Tiene Vehículo',
    danger_hasVehicle: 'Sin Vehículo',

    default_hasPartialAddress: 'Dirección Parcial',
    primary_hasPartialAddress: 'Tiene Dirección Parcial',
    danger_hasPartialAddress: 'No Tiene Dirección Completa',

    default_hasCustomer: 'Cliente',
    primary_hasCustomer: 'Tiene Cliente',
    danger_hasCustomer: 'Sin Cliente',

    default_hasProject: 'Proyecto',
    primary_hasProject: 'Tiene Proyecto',
    danger_hasProject: 'Sin Proyecto',

    default_isMultiLine: 'Múltiples Productos',
    primary_isMultiLine: 'Tiene Múltiples Productos',
    danger_isMultiLine: 'No Tiene Múltiples Productos',

    open: 'Abrir',

    columnHeaders: {
      invoiceNumber: 'Factura',
      ticketId: 'Ticket',
      date: 'Fecha',
      invoiceDueDate: 'Fecha de Vencimiento',
      billableDetailsButton: 'Detalles',
      isBillable: 'Es Facturable',
      dispatchDateTime: 'Fecha',
      dispatchTime: 'Hora',
      orderId: 'Pedido',
      project: 'Proyecto',
      projectId: 'Código de Proyecto',
      customer: 'Cliente',
      customerId: 'Código de Cliente',
      location: 'Ubicación',
      locationId: 'Código de Ubicación',
      vehicleTypeId: 'Código de Tipo de Vehículo',
      vehicleTypeName: 'Tipo de Vehículo',
      statusCode: 'Código de Estado',
      product: 'Producto',
      productId: 'Código de Producto',
      destination: 'Destino',
      extendedPrice: 'Subtotal',
      salesTax: 'Impuesto de Venta',
      totalPrice: 'Total',
      orderedQuantity: 'Cantidad Pedida',
      quantity: 'Cantidad',
      totalQuantity: 'Cantidad Acumulada Entregada',
      priceSource: 'Fuente de Precio',
      billingNote: 'Nota de Facturación',
      recipients: 'Enviar a',
      documentRef: 'Factura (PDF)',
      exported: 'Exportado',
      exportedDate: 'Fecha de Exportación',
      salesPerson: 'Vendedor',
      subtotalPrice: 'Subtotal',
      cityTax: 'Impuesto Municipal',
      countyTax: 'Impuesto del Condado',
      districtTax: 'Impuesto Distrital',
      stateTax: 'Impuesto Estatal',
      'user.name': 'Usuario',
    },

    review: 'Revisión',
    downloadCsv: 'Descargar .CSV',

    illableDetails: 'Detalles Facturables',
    ORDERED: 'Ordenado {eventNumber}',
    CREATED: 'Ticket Creado {eventNumber}',
    TO_JOB: 'Enviado',
    END_UNLOADING: 'Entregado',
    billingNote: 'Nota de Facturación',
    customer: 'Cliente',
    project: 'Proyecto',
    priceBookOverride: 'Anulación de Lista de Precios',
    reprice: 'Cambiar precio',
    applyToAll: 'Aplicar Cambio a Todos los Tickets el Pedido',
    addProduct: 'Agregar Producto',
    productsAndFees: 'Productos y Tarifas',

    quantity: 'Cantidad',
    productIdName: 'Código de/Nombre del Producto',
    pricingSource: 'Fuente de Precios',
    manualOverride: 'Anulación Manual',
    price: 'Precio',
    taxes: 'Impuestos',

    removeProduct: 'Eliminar Producto',

    unitPrice: 'Precio Unitario',
    subtotal: 'Subtotal',
    salesTaxPct: 'Impuesto de Venta ({rate, number, ::percent .00})',
    stateTaxPct: 'Impuesto Estatal ({rate, number, ::percent .00})',
    countyTaxPct: 'Impuesto del Condado ({rate, number, ::percent .00})',
    cityTaxPct: 'Impuesto Municipal ({rate, number, ::percent .00})',
    districtTaxPct: 'Impuesto Distrital ({rate, number, ::percent .00})',
    total: 'Total',
    goToCommandCloudBilling: 'Ir a Facturación de Command Cloud',
    goToCommandCloudBillingBanner:
      'A partir de {date} las actividades de facturación futuras ocurrirán en la Facturación de Command Cloud. No se generarán nuevas facturas en esta plataforma.',
  },

  grid: {
    displayingXofYRows: 'Mostrando {rowCount} de {totalRowCount, plural, =0 {# Filas} one {# Fila} other {# Filas}}',
    displayingNumRows: 'Mostrando {totalRowCount, plural,=0 {# Filas} one {# Fila} other {# Filas}}',
    expandAll: 'Expandir Todo',
    collapseAll: 'Contraer Todo',
    columnFilters: 'Filtros de Columna',
    clearFilters: 'Limpiar Filtros',
    showAll: 'Mostrar Todo',
    hideAll: 'Ocultar Todo',
    visible: 'Visible',
    hidden: 'Oculto',
    save: 'Guardar',
    undoChanges: 'Deshacer Cambios',
    resetColumns: 'Restablecer Columnas',
  },

  true: 'Verdadero',
  false: 'Falso',

  dateRange: 'Rango de fechas',
  orderIds: 'Ids de Pedidos',
  orderStatus: 'Estado de Pedidos',
  search: 'Buscar',

  setupItems: {
    company: 'Compañía',
    customers: 'Clientes',
    projects: 'Proyectos',
    locations: 'Ubicaciones',
    products: 'Productos',
    productTypes: 'Tipos de Producto',
    priceAdjustments: 'Ajustes de Libros de Precios',
    priceBooks: 'Libros de Precio',
    carriers: 'Transportadoras',
    vehicles: 'Vehículos',
    workTypes: 'Tipos de Obra',
    paymentMethods: 'Métodos de Pago',
    contactReasons: 'Motivos de Contacto',
    contactTypes: 'Tipos de Contacto',
    shippingMethods: 'Métodos de Envío',
    orderStatuses: 'Estados de Pedido',
    cancelReasons: 'Motivos de Cancelación',
    vehicleTypes: 'Tipos de Vehículo',
    mixComponents: 'Componentes de Mezcla',
    mixDesigns: 'Diseños de Mezcla',
    rules: 'Reglas',
    trackables: 'Rastreables',
    trackableTypes: 'Tipos de Rastreable',
    trailers: 'Remolques',
    drivers: 'Conductores',
    concreteClasses: 'Clases de Concreto',
    creditCodes: 'Códigos de Crédito',
    initialize: 'Inicializar',
  },
  productsNotOnProject: 'Los siguientes productos no están definidos ni cotizados en el Proyecto: ',
};
