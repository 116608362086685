import {css} from 'styled-components';

export default css`
  .window-header {
    border-bottom: 2px solid #ccc;
    margin-bottom: 2px;
  }
  .window-content {
    height: 100%;
  }
  height: 100%;
  width: 100%;

`;