import cn from 'classnames';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import styled from 'styled-components';
import { Internationalization } from '../../i18n';
import SelectView from './billable-list/select-view';
import FlexColumn from './components/FlexColumn';
import BillableList from './billable-list';
import InvoicedList from './invoiced-list/InvoicedList';
import InvoicedExportHistoryList from './invoiced-export-history/InvoicedExportHistoryList';

const Invoicing = ({ className }) => {
  return (
    <FlexColumn className={cn('invoicing', className)}>
      <Internationalization>
        <SelectView />
        <Routes>
          <Route path="/unbilled" element={<BillableList />} />
          <Route path="/invoiced" element={<InvoicedList />} />
          <Route path="/invoice-export" element={<InvoicedExportHistoryList />} />
        </Routes>
      </Internationalization>
    </FlexColumn>
  );
};

export default styled(Invoicing)`
  height: 100%;
`;
