import { Select as AntSelect } from 'antd';
import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { Translate } from '../../../components/localization/Translate';
import { useOrderListContext } from '../../../components/OrderListContext';

const Select = styled(AntSelect)``;

export const getGroups = persona =>
  persona === 'buyer'
    ? [
        { id: 'supplier', label: 'Supplier', path: 'supplierParty.name' },
        { id: 'project', label: 'Project', path: 'project.name' },
        { id: 'plant', label: 'Plant', path: 'origin.name' },
      ]
    : [
        { id: 'customer', label: 'Customer', path: 'customerParty.name' },
        { id: 'project', label: 'Project', path: 'project.name' },
        { id: 'plant', label: 'Plant', path: 'origin.name' },
        { id: 'date', label: 'Date', path: 'deliverySchedule.startDateTime' },
      ];

const { Option } = Select;

const GroupBy = ({ onChange, value }) => {
  const { persona } = useOrderListContext();
  const groups = React.useMemo(() => {
    return getGroups(persona);
  }, [persona]);

  React.useEffect(() => {
    if (!value && persona === 'buyer') {
      onChange('supplier');
    }
  }, [onChange, persona, value]);

  return (
    <Select value={value} onChange={onChange} className={cn('group-by')} data-testid="select-grouping">
      <Option value="">
        <Translate stringId="noGrouping" defaultMessage="No Grouping" />
      </Option>
      {groups.map(group => (
        <Option key={group.id} value={group.id}>
          <Translate stringId={group.id} defaultMessage={group.label} />
        </Option>
      ))}
    </Select>
  );
};

export default GroupBy;
