import tinycolor from "../../../../util/tiny-color";

const gradientLookup = {};

const getGradient = (background) => {
  if(gradientLookup[background]) {
    return gradientLookup[background];
  }
  const rgbColor = tinycolor(background).toRgb();

  const r = Math.max(rgbColor.r - 75, 35);
  const g = Math.max(rgbColor.g - 75, 35);
  const b = Math.max(rgbColor.b - 75, 35);
  const darkerColor = tinycolor(`rgb ${r} ${g} ${b}`).toRgbString();

  const result = `linear-gradient(180deg, ${tinycolor(background).toRgbString()} 0%, ${darkerColor} 100%)`;
  gradientLookup[background] = result;
  return result;
}

export default getGradient;