import React, { useState, useCallback } from "react";
import cn from 'classnames';
import style from './style';
import styled from 'styled-components';
import Input from "../../../../components/form/InputNumber";
import FieldContainer from "../../../../components/field-container/FieldContainer";
import {kebabCase, isNull} from "lodash";

const View = styled(FieldContainer)`${style}`;

const getMultiplier = (decimals = 2) => {
  let multiplier = 1;
  for (let i = 0; i < decimals; i++) {
    multiplier *= 10;
  }
  return multiplier;
};

const formatPrice = (price = 0, decimals = 2) => {
  if (!price || isNaN(+price)) {
    price = 0;
  }

  const negativeSign = price < 0 ? '-' : '';

  const multiplier = getMultiplier(decimals);

  let textValue = Math.round(Math.abs(+price) * multiplier).toString();
  while (textValue.length <= decimals) {
    textValue = `0${textValue}`;
  }

  const decimalPosition = textValue.length - decimals;
  return `${negativeSign}${textValue.substring(0, decimalPosition)}.${textValue.substring(decimalPosition)}`;
};

const textToNumber = (value = '', decimals = 2, min = 0, max = 0) => {
  if (!value || isNaN(+value)) {
    value = '0';
  }

  const multiplier = getMultiplier(decimals);
  const results = Math.round(+value * multiplier) / multiplier;
  return results < min ? min : max > 0 && results > max ? max : results;
};

const InputCurrency = ({ className, id, label, value, min = 0, max = 0, disabled = false, onChange, decimals = 2 }) => {
  const [price, setPrice] = useState(formatPrice(value, decimals));

  const onPriceChange = useCallback(
    _ => {
      const newValue = textToNumber(price, decimals, min, max);
      setPrice(formatPrice(newValue));
      if (onChange) {
        onChange(id, newValue);
      }
    },
    [price, decimals, onChange, id, min, max]
  );

  return (
    <View className={cn(id, className)}>
      <div className="label-spinner">
        <div className="label">{label}</div>
      </div>
      <Input
        type="text"
        min={min}
        value={price}
        disabled={disabled}
        onChange={e => setPrice(e.target?.value)}
        onBlur={() => onPriceChange()}
        data-testid={`input-${kebabCase(label?.toLowerCase())}`}
      />
    </View>
  );
};

export default InputCurrency;
