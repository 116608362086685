export const status = [
  { value: 'ACTIVE' },
  { value: 'INACTIVE' },
];

export const ruleTypes = [
  { value: 'ASSOCIATED_PRODUCT', label: 'Associated Product' },
  { value: 'TEST', label: 'Special Testing' },
  { value: 'UNLOADING_CHARGE', label: 'Unloading Charge' },
];

export const triggerPoints = [
  { value: 'ORDERING', label: 'Creating Order' },
  { value: 'END_UNLOADING', label: 'Ticket Unloading Complete'},
  // { value: 'TICKETING', label: 'Creating Ticket' },
];

export const recalcOptions = [
  { value: 'never', label: 'Do not recalculate' },
  { value: 'order-modified', label: 'Recalculate when order is modified' },
];
