import { css } from 'styled-components';

export default css`
  width: 100%;

  .setup-table {
    //flex: 1;
    padding: 0 10px;
  }

  table {
    width: 100%;
  }

  thead {
    tr {
      position: sticky;
      top: 0;
      th {
        background-color: #0e4980;
        padding: 5px;
      }
    }
  }

  tbody tr {
    cursor: pointer;

    &:hover {
      background-color: #3a3a3a;
    }
  }

  tbody td {
    padding: 5px;
    border: solid 1px #666;
  }

  .setup-table {
    width: 100%;
    height: 600px;
    overflow: auto;
  }
`;
