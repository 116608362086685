import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './app.css';
import '../node_modules/antd/dist/antd.dark.min.css';
import '../node_modules/react-toastify/dist/ReactToastify.css';

import queryString from 'query-string';
import { AppContextProvider } from './AppContext';
import { cacheItem } from './util/cache';

const queryParams = queryString.parse(window.location.search);

const {
  source,
  'x-connex-id': xConnexId,
  persona,
  locale,
  timeZone,
  hideButtons,
  hideRightSideButtons,
  lt,
  aet,
} = queryParams;

source && cacheItem('source', source);
xConnexId && cacheItem('x-connex-id', xConnexId);
persona && cacheItem('persona', persona || 'seller');
locale && cacheItem('locale', locale);
timeZone && cacheItem('timeZone', timeZone);

ReactDOM.render(
  <AppContextProvider
    data={{ source, xConnexId, persona, locale, timeZone, hideButtons, hideRightSideButtons, v4Tokens: { lt, aet } }}
  >
    <App />
  </AppContextProvider>,
  document.getElementById('root')
);
