import cn from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import FieldContainer from '../../../../components/field-container/FieldContainer';
import InputNumber from '../../../../components/form/InputNumber';
import trimDecimals from '../../../../util/other/trim-decimals';

const ProductInputNumber = ({
  fieldName,
  value,
  onChange,
  disabled,
  onKeyDown,
  addonAfter,
  className,
  readOnly,
  minValue = null,
  autoRefreshValue = false,
  defaultValue = '',
  trim = false,
}) => {
  const [localValue, setLocalValue] = useState(value);

  useEffect(() => {
    if (autoRefreshValue) {
      setLocalValue(value);
    }
  }, [autoRefreshValue, value]);

  const onBlur = useCallback(() => {
    let fValue = parseFloat(`${localValue || defaultValue}`);
    if (!isNaN(fValue)) {
      fValue = minValue ? Math.max(minValue, fValue) : fValue;
      fValue = trim ? trimDecimals(fValue) : fValue;
    }
    onChange(fieldName, fValue);
    setLocalValue(fValue);
  }, [minValue, onChange, localValue, fieldName, defaultValue, trim]);


  const handleChange = useCallback(
    e => {
      onChange(fieldName, e?.target?.value);
      setLocalValue(e?.target?.value || defaultValue);
    },
    [onChange, fieldName, defaultValue]
  );

  return (
    <FieldContainer className={cn(fieldName, className, { disabled })}>
      <div className={cn('wrapper', { readOnly })}>
        {readOnly ? (
          <div className="value-text">{value}</div>
        ) : (
          <InputNumber
            onBlur={onBlur}
            min={minValue || 0}
            value={localValue || defaultValue}
            onChange={handleChange}
            disabled={disabled}
            onKeyDown={onKeyDown}
          />
        )}
        {addonAfter && <div className={cn('addonAfter', { string: typeof addonAfter === 'string' })}>{addonAfter}</div>}
      </div>
    </FieldContainer>
  );
};

export default styled(ProductInputNumber)`
  &&& {
    input {
      border-width: 0;
    }
  }

  &.disabled {
    .addonAfter.string {
      color: #777;
    }
    .wrapper {
      height: 30px;
      .number-input {
        input {
          color: black !important;
        }
      }
    }
  }

  &.validation-failed {
    input {
      border-color: #b62121 !important;
    }
  }
  .wrapper {
    display: flex;
    align-items: center;
    &.readOnly {
      height: 30px;
      .addonAfter {
        height: 30px;
      }
    }

    .value-text {
      margin-left: 10px;
    }

    .ant-input-number {
      flex: 1;
    }
    .value-text,
    .number-input {
      flex: 1;
    }
  }
  .addonAfter.string {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    border: 1px solid #434343;
    border-left: 0;
    padding: 0 10px;
    font-size: 0.9rem;
  }
`;
