import {css} from 'styled-components';

export default css`
  width: 100%;
  
  .ant-btn {
    margin-top: 10px;
  }

  .form-group-label {
    margin-bottom: 5px;
  }

  .form-group {
    margin-bottom: 10px;
  }

  .two-columns-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    justify-content: center;
    align-items: flex-end;
 
    .left {
      grid-column: 1;
    }
 
    .right {
      grid-column: 2;
    }
  }
`;
