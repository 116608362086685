import './components/_stories/util/connex-v4-icons/css/connex-v4-icons.css';

import { ConfigProvider } from 'antd';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ModalContextProvider } from './components/modal-context/ModalContext';
import { useIntlContext } from './i18n';
import ConnexOrder from './views/order/ConnexOrder';
import Setup from './views/setup/Setup';
import SetupTypes from './views/setup/setup-types';
import SetupRoles from './views/setup/setup-roles/SetupRoles';
import Tracking from './views/tracking';
import CreateOrderRequest from './views/order-request/create-order/CreateOrder';
import OrderList from './views/order-request/order-list';
import ViewOrEditOrder from './views/order-request/view-or-edit-order';
import Invoicing from './views/invoice/Invoicing';
import Planning from './views/planning/Planning';
import SetupBuyerTypes from './views/setup/setup-buyer-types';
import ScaleTicketing from './views/scale-ticketing/ScaleTicketing';
import SetupMTTypes from './views/setup/setup-mt-types';

import esLocale from 'antd/es/locale/es_ES';
import enLocale from 'antd/es/locale/en_US';

const AuthenticatedRoutes = () => {
  const { userLocale } = useIntlContext();

  React.useEffect(() => {
    if (userLocale !== 'en-US') require(`moment/locale/${userLocale.toLowerCase()}`);
  }, [userLocale]);

  const locale = React.useMemo(() => {
    return {
      'en-US': enLocale,
      es: esLocale,
      'en-AU': enLocale,
    };
  }, []);

  return (
    <ModalContextProvider>
      <ConfigProvider locale={locale[userLocale]}>
        <Routes>
          <Route path="planning/*" element={<Planning />} />
          <Route path="setup/roles/:roleRef/*" element={<SetupRoles />} />
          <Route exact path="setup-buyer/*" element={<SetupBuyerTypes />} />
          <Route path="setup-buyer/:setupType/*" element={<Setup />} />
          <Route exact path="setup-mobile-ticket/*" element={<SetupMTTypes />} />
          <Route path="setup-mobile-ticket/:setupType/*" element={<Setup />} />
          <Route exact path="setup/*" element={<SetupTypes />} />
          <Route path="setup/:setupType/*" element={<Setup />} />
          <Route path="scale-ticketing/*" element={<ScaleTicketing />} />
          <Route path="cx/*" element={<ConnexOrder />} />
          <Route path="tracking/*" element={<Tracking />} />
          <Route path="invoicing/*" element={<Invoicing />} />
          <Route path="" element={<OrderList />} />
          <Route path="new" element={<CreateOrderRequest />} />
          <Route path=":orderId" element={<ViewOrEditOrder />} />
        </Routes>
      </ConfigProvider>
    </ModalContextProvider>
  );
};

export default AuthenticatedRoutes;
