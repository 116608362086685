import style from './style';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import Button from '../../../../components/button/Button';
import useSetup from '../../useSetup';
import { useParams } from 'react-router';
import Buttons from '../../../../components/layout/Buttons';
import ProductGeneral from './product-general';
import Tabs from '../../../../components/tabs';
import ProductLocations from './product-locations';
import ProductConcreteClasses from './product-concrete-classes';
import FormError from '../../components/form-error';
import { useMemo } from 'react';
import ProductMinimumLoadCharge from './product-minimum-load-charge';
import checkIfProducthasLoadCharges from './utils/check-if-product-has-load-charges';

const View = styled.div`
  ${style}
`;

const isValidAssociated = product => {
  const ap = product.associatedProducts || {};

  return !Object.keys(ap)
    .map(key => parseInt(ap[key]?.productRef ? ap[key]?.quantity || '0' : '1'))
    .some(q => q < 1);
};

const isValid = (product, hasMinimumLoadCharge) => {
  return (
    product?.type &&
    product?.id &&
    product?.name &&
    product?.uomCode &&
    isValidAssociated(product) &&
    checkIfProducthasLoadCharges(hasMinimumLoadCharge, product?.minimumLoadChargeData)
  );
};

const ButtonBar = ({ enabled = true, onCancel, onSave, busy }) => {
  return (
    <Buttons>
      <Button id="btn-cancel" disabled={busy} onClick={onCancel} metricId="core-setup-product-editor-cancel">
        Cancel
      </Button>
      <Button
        id="btn-save"
        loading={busy}
        disabled={busy || !enabled}
        onClick={onSave}
        metricId="core-setup-product-editor-save"
      >
        Save
      </Button>
    </Buttons>
  );
};

const ProductEditor = ({ item, onSave, onCancel }) => {
  const { updateProduct, getProduct, createProduct, product, setProduct, busy, error } = useSetup();

  const { getSetupItems: getProductTypes, setupItems: productTypes } = useSetup('product-type');

  const { entityRef } = useParams();
  const { tab } = useState('general');
  const [valid, setValid] = useState(true);

  useEffect(() => {
    getProductTypes().catch(console.error);
  }, [getProductTypes]);

  useEffect(() => {
    if (item) {
      if (item.crn) {
        getProduct(entityRef, item.crn).catch(alert);
      } else {
        setProduct(item);
      }
    }
  }, [item, entityRef, getProduct, setProduct]);

  const hasMinimumLoadCharge = useMemo(() => {
    if (!product?.type || !productTypes) return false;

    const productType = productTypes?.find(pt => pt.id === product?.type);
    if (!productType) return false;

    return productType?.minimumLoadCharge ? true : false;
  }, [product, productTypes]);

  useEffect(() => {
    setValid(isValid(product, hasMinimumLoadCharge));
  }, [product, hasMinimumLoadCharge]);

  const onChange = (id, value) => {
    setProduct(currentProduct => ({
      ...currentProduct,
      [id]: value,
    }));
  };

  const onSaveProduct = useCallback(async () => {
    try {
      let productRef = product?.crn;
      if (productRef) {
        await updateProduct(entityRef, productRef, product);
      } else {
        await createProduct(entityRef, product);
      }

      onSave(true);
    } catch (e) {
      console.log(e);
    }
  }, [product, updateProduct, createProduct, entityRef, onSave]);

  return (
    <View>
      <FormError error={error} />
      <Tabs type="card" activeKey={tab}>
        <Tabs.TabPane tab="General" key="general" disabled={false}>
          <ProductGeneral
            product={product}
            onChange={onChange}
            productTypes={productTypes}
            hasMinimumLoadCharge={hasMinimumLoadCharge}
          />
          <ButtonBar enabled={valid} onSave={onSaveProduct} onCancel={onCancel} busy={busy} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Locations" key="plants">
          <ProductLocations item={product} onChange={onChange} />
          <ButtonBar enabled={valid} onSave={onSaveProduct} onCancel={onCancel} busy={busy} />
        </Tabs.TabPane>
        {product?.type === 'CONCRETE' && (
          <Tabs.TabPane tab="Concrete Classes" key="concrete-classes">
            <ProductConcreteClasses item={product} onChange={onChange} busy={busy} />
            <ButtonBar enabled={valid} onSave={onSaveProduct} onCancel={onCancel} busy={busy} />
          </Tabs.TabPane>
        )}
        {hasMinimumLoadCharge && (
          <Tabs.TabPane tab="Variable Rate Table" key="variable-rate-table">
            <ProductMinimumLoadCharge minimumLoadChargeData={product?.minimumLoadChargeData} onChange={onChange} />
            <ButtonBar enabled={valid} onSave={onSaveProduct} onCancel={onCancel} busy={busy} />
          </Tabs.TabPane>
        )}
      </Tabs>
    </View>
  );
};

export default ProductEditor;
