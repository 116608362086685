import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
import Button from '../../../../../../components/button/Button';
import { Translate } from '../../../../components/localization/Translate';
import { getTomorrow, isTomorrow } from '../isTomorrow';

const Styled = styled(Button)``;

const TomorrowButton = ({ onChange, currentDate }) => {
  // TODO: get timezone
  const timezone = 'America/Chicago';

  const handleClick = React.useCallback(() => {
    const tomorrowRange = getTomorrow(timezone);
    onChange(tomorrowRange);
  }, [onChange]);

  const isCurrentDateTomorrow = React.useMemo(() => {
    return isTomorrow(currentDate);
  }, [currentDate]);

  return (
    <Styled
      className={cn('tomorrow-button')}
      onClick={handleClick}
      type={isCurrentDateTomorrow ? 'primary' : undefined}
      metricId="core-order-list-filter-tomorrow"
      data-testid='tomorrow-button'
    >
      <Translate stringId="tomorrow" defaultMessage="Tomorrow" />
    </Styled>
  );
};

export default TomorrowButton;
