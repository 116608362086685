import React from 'react';
import styled from 'styled-components';
import FlexColumn from '../../order/components/FlexColumn';
import style from './style';
import SetupItemList from '../setup-item-list';
import { columns, prototype } from './setup';
import CostBookGeneral from './cost-book-adjustment-general';
import moment from 'moment';
// import CostBookPrices from './cost-book-prices';

const Styled = styled(FlexColumn)`
  ${style}
`;

export const tabs = [
  {
    label: 'General',
    component: CostBookGeneral,
  },
];

const SetupCostBooks = () => {
  return (
    <Styled className="setup-cost-books">
      <SetupItemList
        typeId="cost-book-adjustment"
        type="Price Adjustment"
        columns={columns}
        prototype={prototype}
        editorType="tabs"
        tabs={tabs}
        removeEditorButtons={item => item?.effectiveDate && item.effectiveDate < moment().startOf('day').toISOString()}
      />
    </Styled>
  );
};

export default SetupCostBooks;
