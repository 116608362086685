import React from 'react';
import FormField from './form-field';
import styled from 'styled-components';
import { Row, Col, Divider, Form } from 'antd';
import { get } from 'lodash';

const DynamicForm = ({ item, className, form, defaultColumnNumber = 6 }) => {
  return (
    <Form layout={'vertical'} className={className}>
      {form.map((row, rowInd) => {
        if (row[0]?.type === 'space') {
          return <div style={{ height: `${row[0].height || 12}px` }} key={rowInd} />;
        }

        if (row[0]?.type === 'divider') {
          return <Divider key={rowInd} style={{ borderColor: '#424344' }} />;
        }

        return (
          <Row gutter={16} key={rowInd}>
            {row.map((field, fieldInd) => {
              let visible = true;
              let fieldValue = null;

              if (Array.isArray(field.value)) {
                for (const possibleField of field.value) {
                  const possibleValue = get(item, possibleField);
                  if (possibleValue) {
                    fieldValue = possibleValue;
                    break;
                  }
                }
              } else {
                fieldValue = get(item, field.value);
              }
              if (field.visible) {
                visible = field.visible(fieldValue, item);
              }

              if (!visible) {
                return null;
              }

              return (
                <Col key={fieldInd} span={field.span || defaultColumnNumber}>
                  <FormField item={item} fieldValue={fieldValue} field={field} />
                </Col>
              );
            })}
          </Row>
        );
      })}
    </Form>
  );
};

export default styled(DynamicForm)`
  &&& {
    .ant-form {
      color: white;
    }
    .ant-form-item-label > label {
      color: white;
    }
  }
`;
