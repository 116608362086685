import React, { useMemo } from 'react';
import cn from 'classnames';
import styled from 'styled-components';
import FieldContainer from '../../field-container/FieldContainer';
import Input from '../../form/Input';
import getUomAbbreviationOrDescription from '../../../util/uom';

const ProductQuantity = ({ className, value, uom = '' }) => {
  const uomAbbreviation = useMemo(() => {
    return getUomAbbreviationOrDescription(uom).toUpperCase();
  }, [uom]);

  return (
    <FieldContainer className={cn('quantity', className)}>
      <div className="label-spinner">
        <div className="label">Quantity</div>
        <div className={cn('spinner', { spinning: false })}>
          <span className="fas fa-sync fa-spin" />
        </div>
      </div>
      <div className="quantity-uom">
        <div className="quantity">
          <Input value={value || ''} data-testid="quantity_input" disabled />
        </div>
        <div className="uom">
          <Input value={uomAbbreviation || ''} data-testid="uom_input" disabled />
        </div>
      </div>
    </FieldContainer>
  );
};

export default styled(ProductQuantity)`
  input {
    border-radius: 0;
  }
  .quantity-uom {
    display: flex;
    .quantity {
      flex: 1;
    } 
    .uom {
      border-left: 0;
    }
  }
`;
