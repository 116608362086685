import { Divider } from 'antd';
import React from 'react';
import styled from 'styled-components';
import Button from '../../../../components/button/Button';
import { Translate } from '../../../order/components/localization/Translate';
import style from './style';

const Styled = styled.div`
  ${style}
`;

const Filter = ({ id, onClick, filters, metricId }) => {
  const type = React.useMemo(() => {
    if (filters && id) {
      if (filters?.[id] === 'true') {
        return 'primary';
      } else if (filters?.[id] === 'false') {
        return 'danger';
      }
    }
    return 'default';
  }, [filters, id]);

  const toggle = () => {
    if (filters?.[id] === 'true') {
      onClick(id, 'false');
    } else if (filters?.[id] === 'false') {
      onClick(id, 'off');
    } else {
      onClick(id, 'true');
    }
  };

  return (
    <Button id={id} type={type} onClick={toggle} metricId={metricId}>
      <div className="label">
        <Translate stringId={`invoices.${type}_${id}`} />
      </div>
    </Button>
  );
};

const BillableSummary = ({ summary = {}, onFilter, filters }) => {
  const {
    billableCount,
    deliveredCount,
    noPriceCount,
    noProjectCount,
    noVehicleCount,
    noCustomerCount,
    noTaxCount,
    partialAddressCount,
    multiLineCount,
  } = summary;

  return (
    <Styled>
      <Divider>
        <Translate stringId="invoices.processAndDataFilters" defaultMessage="Process and Data Filters" />
        <i onClick={() => onFilter('clear')} className="fa fa-times-circle" />
      </Divider>
      <table>
        <tbody>
          <tr>
            <td>
              <Filter
                filters={filters}
                id="isDelivered"
                count={deliveredCount}
                onClick={onFilter}
                metricId="core-unbilled-deliveries-filter-delivered"
              />
            </td>
            <td>
              <Filter
                filters={filters}
                id="isBillable"
                count={billableCount}
                onClick={onFilter}
                metricId="core-unbilled-deliveries-filter-billable"
              />
            </td>
            <td>
              <Filter
                filters={filters}
                id="isPriced"
                count={noPriceCount}
                onClick={onFilter}
                metricId="core-unbilled-deliveries-filter-priced"
              />
            </td>
            <td>
              <Filter
                filters={filters}
                id="isTaxed"
                count={noTaxCount}
                onClick={onFilter}
                metricId="core-unbilled-deliveries-filter-taxed"
              />
            </td>
            <td>
              <Filter
                filters={filters}
                id="hasNonTaxable"
                count={noTaxCount}
                onClick={onFilter}
                metricId="core-unbilled-deliveries-filter-non-taxable"
              />
            </td>
          </tr>
          <tr>
            <td>
              <Filter
                filters={filters}
                id="hasVehicle"
                count={noVehicleCount}
                onClick={onFilter}
                metricId="core-unbilled-deliveries-filter-vehicle"
              />
            </td>
            <td>
              <Filter
                filters={filters}
                id="hasPartialAddress"
                count={partialAddressCount}
                onClick={onFilter}
                metricId="core-unbilled-deliveries-filter-partial-address"
              />
            </td>
            <td>
              <Filter
                filters={filters}
                id="hasCustomer"
                count={noCustomerCount}
                onClick={onFilter}
                metricId="core-unbilled-deliveries-filter-customer"
              />
            </td>
            <td>
              <Filter
                filters={filters}
                id="hasProject"
                count={noProjectCount}
                onClick={onFilter}
                metricId="core-unbilled-deliveries-filter-project"
              />
            </td>
            <td>
              <Filter
                filters={filters}
                id="isMultiLine"
                count={multiLineCount}
                onClick={onFilter}
                metricId="core-unbilled-deliveries-filter-multi-project"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </Styled>
  );
};

export default BillableSummary;
