import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
import { ConnexDataGrid } from '../../../components/connex-data-grid/components';
import { useTranslateObjects } from '../../order/components/localization/translate-objects/useTranslateObjects';
import FlexColumn from '../components/FlexColumn';
import { useColumns } from './column-definition/column-def';
import QuickDates from './quick-dates/QuickDates';
import style from './style';
import { useInvoiceExportHistory } from './useInvoiceExportHistory';

const Styled = styled(FlexColumn)`
  ${style}
`;

const InvoicedExportHistoryList = props => {
  const { invoices, busy, listHistory } = useInvoiceExportHistory();

  const handleChange = React.useCallback(
    startDate => {
      listHistory(startDate);
    },
    [listHistory]
  );

  const { translateObjects } = useTranslateObjects();

  const columns = useColumns();

  const translatedColumns = React.useMemo(() => {
    return translateObjects(columns, {
      getStringId: value => `invoices.columnHeaders.${value}`,
      getPath: 'key',
      setPath: 'name',
      defaultMessagePath: 'name',
    });
  }, [translateObjects]);

  return (
    <Styled className={cn('invoice-export-history-list')} {...props}>
      <QuickDates onChange={handleChange} loading={busy} />
      <ConnexDataGrid
        showFiltersByDefault
        loading={!invoices}
        gridName="Invoice Exported List"
        columns={translatedColumns}
        rows={invoices}
        rowKeyPath="crn"
        filterable
        rowClickable
        autoSizeColumns
      />
    </Styled>
  );
};

export default InvoicedExportHistoryList;
