import React, {useState} from "react";
import style from './style';
import styled from 'styled-components';
import Select from "../../../../../../components/form/Select";

const Styled = styled.div`${style}`;

const SelectInvoiceTemplate = ({onChange}) => {
  const [value, setValue] = useState('InvoicePerCustomer');

  const handleChange = templateRef => {
    setValue(templateRef);

    onChange && onChange(templateRef)
  }

  return (
    <Styled>
      <Select
        label='Select Invoice Template'
        id='templateRef'
        onChange={handleChange}
        value={value}
        disabled
        options={[
          {value: 'InvoicePerDelivery', label: 'Invoice Per Delivery'},
          {value: 'InvoicePerOrder', label: 'Invoice Per Order'},
          {value: 'InvoicePerProject', label: 'Invoice Per Project'},
          {value: 'InvoicePerCustomer', label: 'Invoice Per Customer'},
        ]}
      />
    </Styled>
  )
}

export default SelectInvoiceTemplate;
