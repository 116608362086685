import {useCallback, useState} from "react";
import {useParams} from "react-router-dom";
import * as api from "./api";

export const usePlanning = () => {
  const { entityRef } = useParams();

  const [demand, setDemand] = useState(null);
  const [loading, setLoading] = useState(false);

  const getDemand = useCallback(
    (date, locationRef) => {
      setLoading(true);

      return api.getDemand(entityRef, date, locationRef)
        .then(setDemand)
        .finally(() => {
          setLoading(false);
        });
    },
    [entityRef]
  );

  return {
    getDemand,
    demand,
    loading,
  }
}
