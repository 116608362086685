import React from 'react';
import { useParams } from 'react-router-dom';
import { api } from '../api';

const initialState = { jobs: null, products: null, quantity: '' };

export const useStartOrder = () => {
  const { entityRef } = useParams();
  const [response, setResponse] = React.useState(initialState);
  const [startOrderBusy, setStartOrderBusy] = React.useState(false);

  const startOrder = React.useCallback(() => {
    setResponse(initialState);
    setStartOrderBusy(true);

    return api
      .startOrder(entityRef, { productType: 'CONCRETE' })
      .then(resp => {
        setResponse(resp);
        return resp;
      })
      .finally(() => {
        setStartOrderBusy(false);
      });
  }, [entityRef]);

  return { startOrder, startedOrder: response, startOrderBusy };
};
