import { ShortDateTime } from '../../../../components/localization/ShortDateTime';
import { Translate } from '../../../../components/localization/Translate';
import { Uom } from '../../../../components/localization/Uom';
import style from './style';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';
import OrderDateTime from '../DateTime';
import getUomAbbreviationOrDescription from '../../../../../../util/uom';

const Styled = styled.div`
  ${style}
`;

const OrderSummary = ({ order }) => {
  const defaultTimeZone = 'America/Chicago';

  const product = {
    ...order?.lineItems?.[0].item,
    ...order?.lineItems?.[0].orderedQuantity,
  };
  const hasExtra = order?.lineItems?.length > 1;

  const isAggregateOrder = order.orderType === 'AGGREGATE';

  // Ensure each lineItem has a unique key
  const lineItems = useMemo(() => {
    return order?.lineItems?.map(lineItem => (lineItem._id ? lineItem : { ...lineItem, _id: uuid() }));
  }, [order?.lineItems]);

  const formattedStatus = useMemo(() => {
    let result = order?.supplierStatus?.name;
    if (order?.supplierStatus?.reason) {
      result += ` -- ${order?.supplierStatus?.reason}`;
    }
    return result;
  }, [order?.supplierStatus]);

  return (
    <Styled>
      <table>
        <tbody>
          <tr>
            <td>
              <Translate stringId="plant" defaultMessage="Plant" />
            </td>
            <td>
              {order?.location ? `${order?.location?.id || ''} / ${order?.location?.name || ''}` : 'None Available'}
            </td>
          </tr>
          {order?.id && (
            <tr>
              <td>
                <Translate stringId="orderNumber" defaultMessage="Order Number" />
              </td>
              <td>{order?.id}</td>
            </tr>
          )}
          <tr>
            <td>
              <Translate stringId="status" defaultMessage="Status" />
            </td>
            <td>{formattedStatus}</td>
          </tr>
          <tr>
            <td>
              <Translate stringId="startDateTime" defaultMessage="Start Date/Time" />
            </td>
            <td>
              <OrderDateTime isoDateTime={order?.deliverySchedule?.startDateTime} showYear={false} />
            </td>
          </tr>
          <tr>
            <td>
              <Translate stringId="productType" defaultMessage="Product Type" />
            </td>
            <td>{product.type || '(none)'}</td>
          </tr>
          <tr>
            <td>
              <Translate stringId="quantity" defaultMessage="Quantity" />
            </td>
            <td>
              <Uom uom={{ uomCode: product.uomCode, value: product.value }} />
            </td>
          </tr>
          {!isAggregateOrder && (
            <>
              <tr>
                <td>
                  <Translate stringId="spacing" defaultMessage="Spacing" />
                </td>
                <td>
                  <Uom
                    uom={{
                      uomCode: order?.deliverySchedule?.deliverySpacing?.uomCode,
                      value: order?.deliverySchedule?.deliverySpacing?.value,
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <Translate stringId="quantityPerHour" defaultMessage="Qty / Hour" />
                </td>
                <td>
                  <Uom
                    uom={{
                      uomCode: product.uomCode,
                      value: order?.deliverySchedule?.scheduleTimes?.quantityRate,
                    }}
                  />{' '}
                  <Translate stringId="UOM_TEXT__/H/ + h" />
                </td>
              </tr>
            </>
          )}
          <tr>
            <td>
              <Translate stringId="description" defaultMessage="Description" />
            </td>
            <td>{product.name}</td>
          </tr>
          {hasExtra && (
            <tr>
              <td>
                <Translate stringId="extraItems" defaultMessage="Extra Items" />
              </td>
              <td>
                {lineItems
                  ?.filter(i => !i.isPrimary)
                  .map(i => (
                    <div key={i._id}>{i.item?.name}</div>
                  ))}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </Styled>
  );
};

export default OrderSummary;
