import React from 'react';
import styled from 'styled-components';

const COLORS = {
  successDark: '#274916',
  successLight: '#4AAB92',
  warningDark: '#967d01',
  warningLight: '#DEC867',
  dangerDark: '#f5222d',
  dangerLight: '#C06868',
};

export const SEVERITY = {
  SUCCESS: 'success',
  WARNING: 'warning',
  DANGER: 'danger',
  DELIVERED: 'delivered',
  CANCELLED: 'cancelled',
  TOTAL: 'total',
};

const PieChart = ({ className, percentage, size = '40', severity = SEVERITY.WARNING }) => {
  return (
    <svg height={size} width={size} viewBox="0 0 20 20" className={className}>
      <circle r="10" cx="10" cy="10" fill={COLORS[`${severity}Light`]} />
      <circle
        r="5"
        cx="10"
        cy="10"
        fill="transparent"
        stroke={COLORS[`${severity}Dark`]}
        strokeWidth="10"
        strokeDasharray={`calc(${percentage} * 31.4 / 100) 31.4`}
        transform="rotate(-90) translate(-20)"
      />
    </svg>
  );
};

export default styled(PieChart)``;
