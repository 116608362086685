import cn from 'classnames';
import React, { useEffect, useRef, useState } from "react";
import styled from 'styled-components';
import { useOnMount } from '../../../../../util/useOnMount';
import { Translate } from '../../../components/localization/Translate';
import { useOrderListContext } from '../../../components/OrderListContext';
import Progress from './Progress';
import { buttonClick } from '../../../../../util/analytics/index';

const Style = styled.div`
  padding: 0;
  cursor: pointer;
  .button-content {
    width: 100px;
    border-radius: 2px;
    background-color: #177ddc;
    display: flex;
    flex-direction: column;
    height: 24px;
    position: relative;

    .icon-label {
      display: flex;
      align-items: center;
      grid-gap: 10px;
      margin: 0 10px;
      z-index: 100;
    }
  }
`;

const RefreshButton = ({ autoRefresh = 30000 }) => {
  const timeoutRef = useRef(null);

  const { refreshOrders, ordersBackgroundBusy, ordersBusy, quickDate } = useOrderListContext();
  const quickDateRef = useRef(quickDate);
  useEffect(() => {
    quickDateRef.current = quickDate;
  }, [quickDate])

  const setTimeoutRef = (value) => {
    timeoutRef.current = value;
  }
  const clearTimeoutRef = () => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = null;
  }

  const initiateAutoRefresh = React.useCallback(() => {
    clearTimeoutRef();
    setTimeoutRef(
      setTimeout(() => {
        refreshOrders();
        initiateAutoRefreshRefFunction.current?.();
      }, autoRefresh)
    );
  }, [autoRefresh, refreshOrders]);

  const initiateAutoRefreshRefFunction = useRef(initiateAutoRefresh);
  useEffect(() => {
    initiateAutoRefreshRefFunction.current = initiateAutoRefresh;
  }, [initiateAutoRefresh])

  const handleClick = React.useCallback(() => {
    clearTimeoutRef();
    refreshOrders();
    initiateAutoRefresh();
  }, [initiateAutoRefresh, refreshOrders]);

  React.useEffect(() => {
    return () => {
      clearTimeoutRef()
    };
  }, []);

  React.useEffect(() => {
    clearTimeoutRef()
    initiateAutoRefresh();
    return () => {
      clearTimeoutRef()
    }
  }, [initiateAutoRefresh, quickDate]);

  return (
    <Style>
      <div data-testid='button-refresh' className="button-content" onClick={() => buttonClick(handleClick, 'core-order-list-refresh')}>
        {ordersBackgroundBusy || ordersBusy || !timeoutRef.current ? null : <Progress durationMs={autoRefresh} />}
        <div className="icon-label x">
          <i className={cn('icon fa fa-sync', { 'fa-spin': ordersBackgroundBusy })} />
          <div className="label">
            <Translate stringId="refresh" defaultMessage="Refresh" />
          </div>
        </div>
      </div>
    </Style>
  );
};

export default RefreshButton;
