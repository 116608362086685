import React from 'react';
import { Translate } from '../../localization/Translate';

const ScheduleHeader = () => {
  return (
    <thead>
      <tr>
        <th><Translate stringId="more" defaultMessage="More" /></th>
        <th><Translate stringId="tableHeading_loadNumber" defaultMessage="Load #" /></th>
        <th><Translate stringId="tableHeading_location" defaultMessage="Location" /></th>
        <th><Translate stringId="tableHeading_quantity" defaultMessage="Quantity" /></th>
        <th><Translate stringId="tableHeading_total" defaultMessage="Total" /></th>
        <th><Translate stringId="tableHeading_slump" defaultMessage="Slump" /></th>
        <th><Translate stringId="tableHeading_vehicle" defaultMessage="Vehicle" /></th>
        <th><Translate stringId="tableHeading_pring" defaultMessage="Print" /></th>
        <th><Translate stringId="tableHeading_load" defaultMessage="Load" /></th>
        <th><Translate stringId="tableHeading_qc" defaultMessage="QC" /></th>
        <th><Translate stringId="tableHeading_toJob" defaultMessage="To Job" /></th>
        <th><Translate stringId="tableHeading_arriveJob" defaultMessage="Arrive Job" /></th>
        <th><Translate stringId="tableHeading_beginPour" defaultMessage="Begin Pour" /></th>
        <th><Translate stringId="tableHeading_endPour" defaultMessage="End Pour" /></th>
        <th><Translate stringId="tableHeading_return" defaultMessage="Return" /></th>
        <th><Translate stringId="tableHeading_arrivePlant" defaultMessage="Arrive Plant" /></th>
        <th><Translate stringId="tableHeading_trip" defaultMessage="Trip" /></th>
      </tr>
    </thead>
  );
};

export default ScheduleHeader;
