import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';
import FlexColumn from '../../order/components/FlexColumn';
import SetupTable from '../components/setup-table';
import useSetup from '../useSetup';
import ProductEditor from './product-editor';
import { columns, prototype } from './setup';
import style from './style';
import ImportExport from '../components/import-export/ImportExport';

const Styled = styled(FlexColumn)`
  ${style}
`;

const importExport = props => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { downloadFile, uploadFile } = useSetup();

  return (
    <ImportExport
      {...props}
      downloadFile={downloadFile}
      uploadFile={uploadFile}
      item={{ typeId: 'product', crn: 'all', name: 'product-list' }}
    />
  );
};

const SetupProducts = () => {
  const { entityRef } = useParams();
  const { searchProducts, products, busy } = useSetup();
  const params = useMemo(() => ({ activeOnly: false, getProductTypeData: true }), []);

  useEffect(() => {
    entityRef && searchProducts(params);
  }, [entityRef, searchProducts, params]);

  return (
    <Styled>
      <SetupTable
        busy={busy}
        type="Product"
        columns={columns}
        items={products}
        Editor={ProductEditor}
        prototype={prototype}
        onRefresh={() => searchProducts(params)}
        actions={importExport}
      />
    </Styled>
  );
};

export default SetupProducts;
