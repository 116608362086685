import { css } from 'styled-components';

export default css`
  //background-color: #222;
  .checkbox {
    text-align: center;
  }

  height: 30px;

  &.disabled {
    td {
      .row-actions {
        .icon {
          color: #777;
        }
      }
    }
  }

  td {
    &.right-align {
      text-align: right;
      padding-right: 10px;
    }
    .row-actions {
      .save {
        color: #49b549;
        .disabled {
          color: white;
          opacity: 0.4;
        }
      }
    }
  }

  .ant-select {
    .ant-select-selector {
      height: 22px;
      border: none;
      .ant-select-selection-search {
        input {
          line-height: 1.3rem;
          height: 22px;
        }
      }
      .ant-select-selection-item {
        line-height: 1.3rem;
      }
    }
  }

  .field-container {
    .number-input {
      input {
        height: 22px;
      }
    }
    .addonAfter {
      height: 22px;
    }
  }

  .invalid-product-icon {
    color: #a61d24;
    margin-top: 3px;
    margin-left: 10px;
  }
`;
