import {css} from 'styled-components';

export default css`
  width: 100%;

  .description {
    margin: 5px 10px;
    opacity: 0.75;
  }
`;
