import {useDashboardContext} from "../../context/useDashboardContext";
import {useEffect, useRef, useState} from "react";
import styled from 'styled-components';
import cn from 'classnames';
import API from '../../../api';
import {vehicleStatuses} from '../../configuration/tracking-configuration-form/color-selectors';

const EmbeddedMap = ({className}) => {
  const {currentVehicles, entityRef, currentTrackingConfiguration} = useDashboardContext();
  const [iframeUrl, setIframeUrl] = useState();

  const currentConfig = useRef(null);

  useEffect(() => {

    const run = async () => {

      const trucks = currentVehicles.map(vehicle => {
        const statusCode = vehicle?.latestEvent?.statusCode;
        let color = 'red';

        if(statusCode) {
          color = currentTrackingConfiguration?.colors?.statuses?.[statusCode] || vehicleStatuses[statusCode]?.defaultColor || '#FF0000';
        }

        return {
          id: vehicle.id,
          color,
        }
      });

      if(currentConfig.current) {
        await API.updateMapConfig(entityRef, currentConfig.current.crn, {
          polling: true,
          pollingTime: 20000,
          trucks,
        });
      } else {
        const config = await API.createMapConfig(entityRef,{
          polling: true,
          pollingTime: 20000,
          trucks,
        });

        setIframeUrl(config.link);
        currentConfig.current = config;
      }
    }

    if(currentVehicles) {
      run().then();
    }
  }, [currentVehicles, setIframeUrl, currentTrackingConfiguration])

  if(!iframeUrl) {
    return <div>Loading...</div>
  }

  return <iframe src={iframeUrl} className={cn(className)} />
}

export default styled(EmbeddedMap)`
  width: 100%;
  height: 100%;
  border-width: 0;
`;