import React from 'react';
import styled from 'styled-components';
import MoneyRenderer from '../../../column-definitions/MoneyRenderer';
import CustomerRow from './customer-row';

const Styled = styled.table`
  width: 100%;
  margin-top: 20px;

  table,
  tbody,
  tr {
    width: 100%;
  }

  th,
  td {
    padding: 5px 5px 5px 0;
    text-align: right;
  }

  tr th:first-child,
  td:first-child {
    text-align: left;
  }

  .grand-title {
    padding-left: 25px;
  }
`;

const CustomerPreview = ({ creatingInvoices, invoices, summary, customers, toggleCustomerSelection, progress, exported }) => {
  return (
    <Styled className="customer-preview">
      <thead>
        <tr>
          <th>Customer</th>
          <th>#Projects</th>
          <th>#Items</th>
          <th>Sub-Total</th>
          <th>Sales Tax</th>
          <th>Total</th>
          <th>&nbsp;</th>
        </tr>
        <tr>
          <th>&nbsp;</th>
          <th>&nbsp;</th>
          <th>&nbsp;</th>
          <th>
            <MoneyRenderer value={summary?.extendedPrice} />
          </th>
          <th>
            <MoneyRenderer value={summary?.salesTax} />
          </th>
          <th>
            <MoneyRenderer value={summary?.totalPrice} />
          </th>
          <th style={{textAlign: 'left'}}>{exported && (
            <a target="_blank" href={exported?.link}>
              <i className="fa fa-file-excel" />
            </a>
          )}</th>
        </tr>
      </thead>
      <tbody>
        {customers?.map(customer => (
          <CustomerRow
            key={`${customer.customerRef}-customer`}
            creatingInvoices={creatingInvoices}
            invoices={invoices}
            customer={customer}
            toggleCustomerSelection={toggleCustomerSelection}
            progress={progress}
          />
        ))}
      </tbody>
    </Styled>
  );
};

export default CustomerPreview;
