import {css} from 'styled-components';

export default css`
  width: 100%;
  padding: 5px;
  
  .header-title {
    font-size: 1.2em;
    text-align: center;
  }

  .header-title span {
    margin-right: 10px;
    font-size: 1.1em;
    
    &:hover {
      cursor: pointer;
      color: #0e4980;
    }
  }
  
  .action {
    position: absolute;
    right: 10px;
    top: 10px;
  }
  
  .back {
    position: absolute;
    left: 10px;
    top: 10px;
  }
  
  .action .add {
    margin-right: 5px;
  }
`;
