import ContactListEditor from '../../components/contact-list-editor/ContactListEditor';
import style from './style';
import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import Button from '../../../../components/button/Button';
import useSetup from '../../useSetup';
import Buttons from '../../../../components/layout/Buttons';
import Spinner from '../../../../components/spinner/Spinner';
import CarrierGeneral from './carrier-general';
import CarrierIntegration from './carrier-integration';
import Tabs from '../../../../components/tabs';
import FormError from '../../components/form-error';
import useValidateField from '../../../../hooks/useValidateField';

const ButtonBar = ({ onCancel, onSave, busy, validationErrors }) => {
  return (
    <Buttons>
      <Button id="btn-cancel" disabled={busy} onClick={onCancel} metricId="core-setup-carrier-editor-cancel">
        Cancel
      </Button>
      <Button
        id="btn-save"
        loading={busy}
        disabled={busy || validationErrors}
        onClick={onSave}
        metricId="core-setup-carrier-editor-save"
      >
        Save
      </Button>
    </Buttons>
  );
};

const Styled = styled.div`
  ${style}
`;

const CarrierEditor = ({ item, onSave, onCancel }) => {
  const { saveCarrier, carrier, getCarrier, setCarrier, itemBusy, error } = useSetup();
  const { validateField, validationErrors } = useValidateField();
  const [tab, setTab] = useState('general');
  useEffect(() => {
    if (item) {
      if (item.crn) {
        getCarrier(item.crn);
      } else {
        setCarrier(item);
      }
    }
  }, [getCarrier, item, setCarrier]);

  const onChange = useCallback(
    (id, value) => {
      validateField(id, value);
      setCarrier(carrier => ({
        ...carrier,
        [id]: value,
      }));
    },
    [validateField, setCarrier]
  );

  const onSaveItem = () => {
    saveCarrier()
      .then(() => onSave(true))
      .catch(() => {});
  };

  return (
    <Styled>
      <FormError error={error} />
      <Spinner spin={!carrier && itemBusy}>
        <Tabs type="card" activeKey={tab} onChange={setTab}>
          <Tabs.TabPane tab="General" key="general" disabled={false}>
            <CarrierGeneral carrier={carrier} onChange={onChange} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Integration" key="integration" disabled={false}>
            <CarrierIntegration carrier={carrier} onChange={onChange} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Contacts" key="contacts" disabled={false}>
            {carrier && (
              <ContactListEditor
                item={carrier}
                onChange={onChange}
                typeId="carrier-editor"
                validationErrors={validationErrors}
              />
            )}
          </Tabs.TabPane>
        </Tabs>

        <ButtonBar onSave={onSaveItem} onCancel={onCancel} busy={itemBusy} validationErrors={validationErrors} />
      </Spinner>
    </Styled>
  );
};

export default CarrierEditor;
