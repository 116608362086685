import { Switch } from 'antd';
import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { useScaleTicketingContext } from '../../Context';

const Styled = styled.div``;
const ManualToggle = () => {
  const { form, handleChange } = useScaleTicketingContext();

  const onChange = React.useCallback(
    checked => {
      handleChange('liveWeight', checked);
      handleChange('capturedWeight', null);
    },
    [handleChange]
  );

  return <Switch checkedChildren="Live" unCheckedChildren="Manual" checked={form?.liveWeight} onChange={onChange} />;
};

export default ManualToggle;
