import React, {useState} from "react";
import {InfoWindow, Marker} from "@react-google-maps/api";
import moment from "moment";

const DestinationMarker = ({ order, point }) => {
  const [showInfo, setShowInfo] = useState(false);
  const a = order?.destination?.address;
  const date = order?.deliverySchedule?.startDateTime;

  return (
    <Marker
      key={`${point.lat}#${point.lng}`}
      onClick={() => setShowInfo(!showInfo)}
      position={point}>
      {showInfo && (
        <InfoWindow
          options={{ disableAutoPan: true }}
          onCloseClick={() => setShowInfo(!showInfo)}
        >
          <div style={{ color: "black" }}>
            <div><b>Delivery Address</b></div>
            <div>{`${a?.address1}`}</div>
            <div>{`${a?.city}, ${a?.countrySubDivision} ${a?.postalCode}`}</div>
            <div>{moment(date).format("MM/DD hh:mm A z")}</div>
          </div>
        </InfoWindow>
      )}
    </Marker>
  );
};

export default DestinationMarker;
