import React from 'react';
import { cacheItem, getCachedItem } from '../../../util/cache';
import { useOrderListContext } from '../components/OrderListContext';

export const useGroupBy = () => {
  const { persona } = useOrderListContext();

  const storedGroupBy = React.useMemo(() => {
    let stored = getCachedItem('group-by') || '';

    if (persona === 'buyer' && stored === 'customer') {
      stored = 'supplier';
    } else if (persona !== 'buyer' && stored === 'supplier') {
      stored = '';
    }
    return stored || '';
  }, [persona]);

  const [groupBy, setGroupBy] = React.useState(storedGroupBy);

  const handleGroupByChange = React.useCallback(val => {
    setGroupBy(val);
    cacheItem('group-by', val);
  }, []);

  return {
    groupBy,
    handleGroupByChange,
  };
};
