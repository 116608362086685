import styled from 'styled-components';
import InputNumberBlur from '../../../../components/input-number-blur';
import style from './style';

const Styled = styled.tr`
  ${style}
`;

const ChargeRow = ({ index, chargeValues, onChange, onDelete }) => {
  return (
    <Styled>
      <td>
        <InputNumberBlur
          id="distance"
          value={chargeValues?.distance ?? ''}
          onChange={(_, value) => onChange(index, 'distance', value)}
          useDefaultOnChangeHandler={true}
        />
      </td>
      <td>
        <InputNumberBlur
          id="charge"
          value={chargeValues?.charge ?? ''}
          onChange={(_, value) => onChange(index, 'charge', value)}
          useDefaultOnChangeHandler={true}
        />
      </td>
      <td
        className="delete"
        onClick={() => {
          onDelete(index);
        }}
      >
        <i className="icon fa fa-trash" />
      </td>
    </Styled>
  );
};

export default ChargeRow;
