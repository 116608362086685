import { css } from 'styled-components';

export default css`
  //border: 1px solid blue;
  position: relative;
  height: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 29px;
  .x-axis-ticks {
    position: absolute;
    display: flex;
    top: -4px;
  }
  
  .x-axis-labels {
    font-family: monospace;
    position: absolute;
    display: flex;
    top: 15px;
    .label {
      display: flex;
      justify-content: center;
    }
  }
`;
