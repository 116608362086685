import cn from 'classnames';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Button from '../../../components/button/Button';
import ErrorModal from '../../../components/error-modal/ErrorModal';
import Contact from '../../../components/field-components/editable/Contact';
import Description from '../../../components/field-components/editable/Description';
import Directions from '../../../components/field-components/editable/Directions';
import Discipline from '../../../components/field-components/editable/Discipline';
import EmailCopies from '../../../components/field-components/editable/EmailCopies';
import Job from '../../../components/field-components/editable/Job';
import Note from '../../../components/field-components/read-only/Note';
import PourCardNumber from '../../../components/field-components/editable/PourCardNumber';
import PourType from '../../../components/field-components/editable/PourType';
import Product from '../../../components/field-components/editable/Product';
import ProductQuantity from '../../../components/field-components/editable/ProductQuantity';
import Slump from '../../../components/field-components/editable/Slump';
import Spacing from '../../../components/field-components/editable/Spacing';
import StartDateTime from '../../../components/field-components/editable/StartDateTime';
import Supplier from '../../../components/field-components/editable/Supplier';
import Buttons from '../../../components/layout/Buttons';
import Column from '../../../components/layout/Column';
import Fields from '../../../components/layout/Fields';
import Layout from '../../../components/layout/Layout';
import { useModalContext } from '../../../components/modal-context/ModalContext';
import Spinner from '../../../components/spinner/Spinner';
import style from './style';
import { useCreateOrder } from './useCreateOrder';
import { useCreateOrderRequest } from './useCreateOrderRequest';
import PourDescription from '../../../components/field-components/editable/PourDescription';

const CreateOrderRequest = ({ className }) => {
  const {
    suppliers: { suppliersBusy, suppliers },
    form: { form = {}, handleChange },
    start: { response, startOrderBusy, pourDescriptions, disciplines },
    isValid,
  } = useCreateOrderRequest();

  const navigate = useNavigate();

  const { createOrder, createOrderBusy } = useCreateOrder();

  const { openModal } = useModalContext();

  const handleSaveClick = useCallback(() => {
    createOrder(form)
      .then(() => {
        navigate('..');
      })
      .catch(e => {
        openModal('Error', <ErrorModal error={e} />);
      });
  }, [createOrder, form, navigate]);

  const handleCancelClick = useCallback(() => {
    navigate('..');
  }, [navigate]);

  const useJobFilters = useCallback(() => {
    return response?.settings?.useJobFiltering === true;
  }, [response]);

  const useDescFilters = useCallback(() => {
    return response?.settings?.filterDescriptions === true;
  }, [response]);

  return (
    <div className={cn(className)} style={{ padding: '10px' }}>
      <div className="title">Create Order Request</div>
      <Supplier
        required
        suppliers={suppliers}
        suppliersBusy={suppliersBusy}
        onChange={handleChange}
        value={form.supplier}
      />
      <Spinner spin={!response || startOrderBusy}>
        <Layout>
          {form?.supplier?.crn && (
            <>
              <Fields>
                <Column>
                  <Product required products={response?.products} value={form.product} onChange={handleChange} />
                  <ProductQuantity
                    required
                    value={form?.quantity}
                    onChange={handleChange}
                    uom={form?.product?.uomCode}
                  />
                  <Job jobs={response?.jobs} value={form.job} onChange={handleChange} />
                  {disciplines && (
                    <Discipline
                      required={response?.settings?.useJobFiltering && form?.job?.jobRef}
                      disciplines={disciplines}
                      value={form.discipline}
                      onChange={handleChange}
                    />
                  )}
                  {useDescFilters() && pourDescriptions && (
                    <PourDescription
                      required={form?.job?.jobRef}
                      pourDescriptions={pourDescriptions}
                      value={form.pourDescription}
                      onChange={handleChange}
                    />
                  )}
                  {!useDescFilters() && <Description value={form?.description} onChange={handleChange} />}
                  <Contact value={form?.contact} onChange={handleChange} />
                </Column>
                <Column>
                  <StartDateTime required value={form?.startDateTime} onChange={handleChange} />
                  <Slump value={form?.slump} onChange={handleChange} />
                  <Spacing value={form?.spacing} onChange={handleChange} spacingUnits={form?.spacingUnits || ''} />
                  <PourType value={form?.pourType} onChange={handleChange} />
                  <PourCardNumber value={form?.pourCardNumber} onChange={handleChange} />
                  <Directions value={form?.directions} onChange={handleChange} />
                  <EmailCopies value={form?.emailCopies} onChange={handleChange} contacts={response?.contacts} />
                </Column>
              </Fields>
              <div style={{ gridArea: 'notes' }}>
                <Note value={form?.note} onChange={handleChange} />
              </div>
            </>
          )}
          <Buttons>
            <Button onClick={handleCancelClick}>Cancel</Button>
            <Button onClick={handleSaveClick} loading={createOrderBusy} disabled={!isValid}>
              Save
            </Button>
          </Buttons>
        </Layout>
      </Spinner>
    </div>
  );
};

export default styled(CreateOrderRequest)`
  ${style}
`;
