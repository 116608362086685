import React from 'react';
import styled from 'styled-components';

const Welcome = ({ className }) => {
  return (
    <div className={className}>
      <div className="message">CONNEX Order Service: Missing entityRef</div>
      <div className="usage">Please use URL: {window.location.href}:entityRef</div>
    </div>
  );
};

export default styled(Welcome)`
  background-color: #fff;
  height: 100vh;
  color: #000;
  padding: 10px;
  font-family: Serif;
  .message {
    font-size: 2em;
  }

  .usage {
  }
`;
