import style from './style';
import styled from 'styled-components';
import cn from 'classnames';
import React, {createContext, useContext, useState} from "react";
import {v4 as uuid} from 'uuid';

const WindowContext = createContext();

export const useWindowContext = () => {
  const value = useContext(WindowContext);
  if(!value) {
    throw new Error('cant use window context outside of provider');
  }
  return value;
}

const WindowContextProvider = ({children, windowType, windowId, view = {}}) => {
  return <WindowContext.Provider value={{windowId, windowType, view}}>
    {children}
  </WindowContext.Provider>
}

const WindowWrapper = ({className, title, children, windowType}) => {
  const [header, setHeader] = useState(null);
  const [windowId] = useState(uuid());

  return <WindowContextProvider windowId={windowId} windowType={windowType} setHeader={setHeader}>
    <div className={cn(className)}>
      {/*<div className={'window-header'}>*/}
      {/*  {title}*/}
      {/*  {header}*/}
      {/*</div>*/}
      <div className={'window-content'}>
        {children}
      </div>
    </div>
  </WindowContextProvider>
}

export default styled(WindowWrapper)`
  ${style}
`;