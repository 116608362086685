import SelectSetupItem from '../select-setup-item';
import React, { useCallback, useEffect } from 'react';
import InputSelect from '../input-select';
import { useProjects } from '../../../order/useProjects';
import { find } from 'lodash';
import Spinner from '../../../../components/spinner/Spinner';

const SelectProject = ({ item, onChange, customerRef, disabled }) => {
  const { getProjects, projects, projectsBusy } = useProjects();

  useEffect(() => {
    if (customerRef) {
      getProjects(customerRef, { activeOnly: true }).then();
    }
  }, [getProjects, customerRef]);

  const changeProject = useCallback(
    (id, value) => {
      const project = find(projects, p => p.crn === value);
      if (project) {
        onChange(id, {
          id: project.id,
          name: project.name,
          projectRef: project.crn,
        });
      }
    },
    [onChange, projects]
  );

  return (
    <InputSelect
      options={
        projects?.map(p => {
          return {
            label: `${p.id} / ${p.name}`,
            value: p.crn,
          };
        }) || []
      }
      busy={projectsBusy}
      id="project"
      label={'Project'}
      typeId="project"
      disabled={!customerRef || projectsBusy || disabled}
      value={item?.project?.projectRef}
      onChange={changeProject}
    />
  );
};

export default SelectProject;
