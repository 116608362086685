import { useOrderContext } from '../../useOrderContext';
import ProductSchedule from './ProductSchedule';
import React from 'react';
import ProductSummary from './ProductSummary';
import PlanningTimes from './PlanningTimes';
import DeliveryProgress from './DeliveryProgress';
import { useScheduleContext } from './ScheduleContext';
import filterScheduledLoads from '../../../util/filterScheduledLoads';

const ProductCard = ({ order, lineItem }) => {
  const { schedule, scheduleTimes } = useScheduleContext();

  const { start } = useOrderContext();

  const timeZone = start?.startedOrder?.supplierParty?.timeZone || 'America/Chicago';

  const scheduledLoads = filterScheduledLoads(schedule, order?.deliverySchedule?.tickets);

  return (
    <div className="product-card">
      {order && <ProductSummary order={order} lineItem={lineItem} />}

      {scheduleTimes && (
        <PlanningTimes
          order={order}
          scheduleTimes={scheduleTimes}
        />
      )}
      {order?.deliverySchedule?.tickets?.length > 0 && <DeliveryProgress order={order} timeZone={timeZone} />}
      {scheduledLoads?.length > 0 && (
        <ProductSchedule schedule={scheduledLoads} order={order} scheduleTimes={scheduleTimes} timeZone={timeZone} />
      )}
    </div>
  );
};

export default ProductCard;
