import React from 'react';
import cn from 'classnames';
import style from './style';
import styled from 'styled-components';
import Checkbox from '../../../../components/checkbox';
import FieldContainer from '../../../../components/field-container/FieldContainer';

const View = styled(FieldContainer)`
  ${style}
`;

const InputCheckbox = ({ className, id, label, value, onChange, metricId, disabled, busy, tooltip }) => {
  return (
    <View className={cn(id, className)}>
      <Checkbox
        label={label}
        checked={value || false}
        onChange={e => onChange(id, e?.target?.checked)}
        metricId={metricId}
        disabled={disabled || busy}
        tooltip={tooltip}
      />
    </View>
  );
};

export default InputCheckbox;
