export const status = [{ value: 'ACTIVE' }, { value: 'INACTIVE' }];

export const importedInvoiceEmailOptions = [
  { value: 'AUTOMATIC', label: 'Automatically Send' },
  { value: 'MANUAL', label: 'Manually Release' },
];

export const exportTypes = [
  { value: 'quickbooks-local', label: 'Excel .xlsx (QuickBooks)' },
  { value: 'viewpoint', label: 'CSV (Cyma, Vista, Keystone, etc)' },
  { value: 'spectrum', label: 'CSV (Spectrum)' },
];
