import React, { useRef, useCallback, useContext, useState } from 'react';
import { Modal } from 'antd';

export const ModalContext = React.createContext();

export const useModalContext = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error(`useModalContext cannot be rendered outside of the ModalContext context provider`);
  }
  return context;
};

export const ModalContextProvider = ({ children }) => {
  const Component = useRef(<div>Modal</div>);

  const [title, setTitle] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [width, setWidth] = useState();
  const [footer, setFooter] = useState(null);
  const [bodyStyle, setBodyStyle] = useState(null);
  const [centered, setCentered] = useState(false);
  const [modalKey, setModalKey] = useState(0);

  const closeModal = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const openModal = useCallback((currentTitle, component) => {
    Component.current = component;
    setTitle(currentTitle);
    setIsOpen(true);
  }, []);

  const openCustomSizeModal = useCallback(
    ({ title, component, width, bodyStyle, footer, centered = true }) => {
      setModalKey(prevKey => prevKey + 1); // This will force the modal to re-render. This line was generated by GitHub CoPilot.
      Component.current = React.cloneElement(component, { key: modalKey }); // This line was generated by GitHub CoPilot.
      setWidth(width);
      setIsOpen(true);
      setBodyStyle(bodyStyle);
      setFooter(footer);
      setCentered(centered);
    },
    [modalKey]
  );

  return (
    <ModalContext.Provider value={{ closeModal, openModal, openCustomSizeModal }}>
      <>
        {children}
        <Modal
          title={title}
          visible={isOpen}
          footer={footer}
          closable={false}
          width={width}
          bodyStyle={bodyStyle}
          centered={centered}
        >
          {Component.current}
        </Modal>
      </>
    </ModalContext.Provider>
  );
};
