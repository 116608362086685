import { css } from 'styled-components';

export default css`
  font-size: 1rem;
  margin-bottom: 25px;
  button {
    width: 100%;
  }
  .section-label {
    font-weight: bold;
  }
  .line-items {
    .line-item {
      display: table;
      table-layout: fixed;
      width: 100%;

      border: 1px solid #434343;
      margin: 10px 0;

      .product {
        padding: 5px;
        background-color: #333;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 100%;
        display: block;
        overflow: hidden;
      }

      .other-info {
        display: flex;
        grid-gap: 15px;
        padding: 0 5px;
        .line-item-field {
          display: flex;
          grid-gap: 10px;
          flex: 1;
        }

        .hold-quantity,
        .quantity {
          justify-content: flex-end;
          flex: 1;
        }
      }
    }
  }
`;
