export const uomTemp = [
  {
    isoCode: 'CEL',
    description: 'Degrees Celsius',
    display: 'C',
  },
  {
    isoCode: 'FAH',
    description: 'Degrees Fahrenheit',
    display: 'F',
  },
];

export const uomConcreteVolume = [
  {
    isoCode: 'YDQ',
    description: 'Cubic Yards',
    display: 'CY',
  },
  {
    isoCode: 'MTQ',
    description: 'Cubic Meters',
    display: 'CU MM',
  },
];

export const uomWaterVolume = [
  {
    isoCode: 'GLL',
    description: 'Gallons',
    display: 'GA'
  },
  {
    isoCode: 'LTR',
    description: 'Liters',
    display: "L"
  },
];

export const uomWeight = [
  {
    isoCode: 'LBR',
    description: 'Pounds',
    display: 'LB'
  },
  {
    isoCode: 'KGM',
    description: 'Kilograms',
    display: "KG"
  },
];

export const uomSlump = [
  {
    isoCode: 'INH',
    description: 'Inches',
    display: "IN"
  },
  {
    isoCode: 'MMT',
    description: 'Millimeters',
    display: "MM"
  },
];

export const uomValues = uomCodes =>
  uomCodes
    .map(uom => {
      return {
        value: uom.isoCode,
        label: `${uom.description} (${uom.display || uom.isoCode})`,
      };
    })
    .sort((a, b) => (a.label < b.label ? -1 : 1));
