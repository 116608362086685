import {css} from 'styled-components';
import getGradient from "../../utils/get-gradient";

export default css`
  display: flex;
  flex-direction: row;
  
  .vehicle-list {
    height: auto;
  }
  
  .timer-late {
    height: 100%;
    background: ${() => getGradient('red')};
    color: white;
  }
`
