import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useTranslateObjects } from '../../order/components/localization/translate-objects/useTranslateObjects';
import style from './style';
import styled from 'styled-components';
import useBillables from '../use-billables';
import BillableSummary from './billable-summary';
import SelectDates from './select-dates';
import { ConnexDataGrid } from '../../../components/connex-data-grid/ConnexDataGrid';
import { useColumns } from './column-definitions/column-def';
import BillableDetails from './billable-details';
import matchingBillables from './matching-billables';
import InvoiceFromSelected from './invoice-from-selected';
import { groupBy, isEmpty } from 'lodash';
import flattenBillables from './flattenBillables';

const Styled = styled.div`
  ${style}
`;

const BillableList = () => {
  const [filters, setFilters] = useState({ startDate: 'currentMonth' });
  const [filtered, setFiltered] = useState(null);
  const [filteredExport, setFilteredExport] = useState(null);
  const [selected, setSelected] = useState(null);
  const [startDate, setStartDate] = useState('currentMonth');
  const [customDateRange, setCustomDateRange] = useState([]);

  const [expandedGroupIds, setExpandedGroupIds] = React.useState(new Set([]));

  const { searchBillables, billables, summary, loading } = useBillables();

  const handleRowClick = useCallback(row => {
    setSelected(row.crn);
  }, []);

  const flattenBillablesList = useMemo(() => {
    if (!billables) return [];
    return flattenBillables(billables, handleRowClick);
  }, [billables, handleRowClick]);

  // This code fetches billables from the server and updates the table.
  // It is invoked when the component mounts only.
  useEffect(() => {
    searchBillables(filters).catch(alert);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // We have the filteredExport and the filtered states because in the grid
  // we display products groupped by ticketId but the InvoiceFromSelected was made to only support a regular billable list
  // so for the InvoiceFromSelected to work propertly we need to pass it a billable list not a product list

  useEffect(() => {
    flattenBillablesList && setFiltered(flattenBillablesList);
  }, [flattenBillablesList]);
  useEffect(() => {
    billables && setFilteredExport(billables);
  }, [billables]);

  useEffect(() => {
    filters && flattenBillablesList && setFiltered(flattenBillablesList.filter(b => matchingBillables(b, filters)));
  }, [filters, flattenBillablesList]);
  useEffect(() => {
    filters && billables && setFilteredExport(billables.filter(b => matchingBillables(b, filters)));
  }, [filters, billables]);

  const filterChanged = (id, on) => {
    if (id === 'clear') {
      setFilters({});
    } else {
      setFilters({
        ...(filters ?? {}),
        [id]: on,
      });
    }
  };

  const refreshList = useCallback(() => {
    searchBillables(filters).catch(alert);
  }, [searchBillables, filters]);

  const updateDate = useCallback(
    date => {
      setStartDate(date);
      searchBillables({
        startDate: date,
      }).catch(alert);
    },
    [searchBillables]
  );

  const updateCustomDateRange = useCallback(
    dateRange => {
      if (!isEmpty(dateRange)) {
        setStartDate(dateRange[0]);
        searchBillables({
          startDate: dateRange[0],
          endDate: dateRange[1],
        }).catch(alert);
      }
      setCustomDateRange(dateRange);
    },
    [searchBillables]
  );

  const refreshBillablesByDate = useCallback(() => {
    searchBillables({
      startDate,
    }).catch(alert);
  }, [startDate, searchBillables]);

  const handleExpandAll = useCallback(() => {
    if (!flattenBillablesList) return;
    const newSet = new Set(flattenBillablesList.map(billable => billable?.ticketId));
    setExpandedGroupIds(newSet);
  }, [flattenBillablesList]);

  const handleHideAll = useCallback(() => {
    setExpandedGroupIds(new Set());
  }, []);

  const { translateObjects } = useTranslateObjects();

  const columns = useColumns();

  const translatedColumns = React.useMemo(() => {
    return translateObjects(columns, {
      getStringId: value => `invoices.columnHeaders.${value}`,
      getPath: 'key',
      setPath: 'name',
      defaultMessagePath: 'name',
    });
  }, [columns, translateObjects]);

  return (
    <Styled>
      <SelectDates
        loading={loading}
        selected={startDate}
        setSelected={updateDate}
        customDateRange={customDateRange}
        updateCustomDateRange={updateCustomDateRange}
      />
      {summary && <BillableSummary summary={summary} onFilter={filterChanged} filters={filters} />}
      <ConnexDataGrid
        showFiltersByDefault
        loading={!filtered}
        gridName="Billable List"
        columns={translatedColumns}
        rows={filtered}
        rowKeyPath="localId"
        onRowClick={handleRowClick}
        filterable
        rowClickable
        actions={InvoiceFromSelected}
        autoSizeColumns
        refreshList={refreshList}
        rowGrouper={groupBy}
        groupBy={['ticketId']}
        expandedGroupIds={expandedGroupIds}
        onExpandedGroupIdsChange={setExpandedGroupIds}
        displayExpandAll={true}
        handleExpandAll={handleExpandAll}
        handleHideAll={handleHideAll}
        exportRows={filteredExport}
      />
      <BillableDetails billableRef={selected} onClose={() => setSelected(null)} refreshList={refreshBillablesByDate} />
    </Styled>
  );
};

export default BillableList;
