import cn from 'classnames';
import { omit } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import MaxSize from '../../views/order/components/MaxSize';
import MinSize from '../../views/order/components/MinSize';
import Button from '../button/Button';

const Styled = styled(Button)`
  &&& {
    @media (max-width: 780px) {
      width: 25px;
      min-width: 0;
      & > *:nth-child(2) {
        width: 0;
      }
    }
  }
`;

const Single = ({ className, children, label, ...props }) => {
  const buttonProps = omit(props, ['overlay']);
  return (
    <Styled className={cn('single', className)} {...buttonProps}>
      <MaxSize breakpoint={781}>
        <i className="fal fa-plus" />
      </MaxSize>
      <MinSize breakpoint={780}>{label}</MinSize>
    </Styled>
  );
};

export default Single;
