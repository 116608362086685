import { Input as AntInput, Form } from 'antd';
import React from 'react';
import styled from 'styled-components';

const FormItem = Form.Item;

const Input = ({ className, fieldValue, field }) => {
  const {label, value} = field;
  return <FormItem className={className} label={label}>
    <AntInput data-testid={value.substring(value.indexOf('.') + 1)} value={fieldValue} disabled={true}  />
  </FormItem>;
};

export default styled(Input)`
  &&& {
    .ant-input[disabled] {
      background-color: #424344;
      color: white;
      border: 1px solid #58595B !important;
    }
  }
`;
